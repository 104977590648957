import { useRetirementFileList } from "@/api/services/main/retirement-document";
import { TGetRetirementFileListDto } from "@/api/services/main/retirement-document/dtos/get-retirement-file-list";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { Table } from "@/components/elements/Table";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { useTable } from "@/hooks/useTable";
import { useVisible } from "@/hooks/useVisible";
import { DeleteRetirementFileModal } from "@/pages/retirement/retirement-files/DeleteRetirementFileModal";
import { dayjs } from "@/utils/dayjs";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo, useState } from "react";

type Props = {
  readonly?: boolean;
};

export const RetirementFilesTable: FC<Props> = ({ readonly }) => {
  const { data: retirementFileListResult, isLoading } = useRetirementFileList();
  const retirementFileListData = retirementFileListResult?.data;

  const [deleteFileId, setDeleteFileId] = useState<number>();

  const deleteFileModal = useVisible();

  const columns = useMemo<MRT_ColumnDef<TGetRetirementFileListDto>[]>(
    () =>
      (
        [
          {
            header: "ファイル名",
            Cell: ({ row }) => {
              return <FileItem filePath={row.original.filePath} downloadable hideIcon width={350} />;
            },
            muiTableBodyCellProps: {
              sx: { width: "49%" },
            },
          },
          {
            header: "アップロード日時",
            Cell: ({ row }) => <Typography variant="body14">{dayjs(row.original.createdAt).format(DATE_TIME_FORMAT)}</Typography>,
            muiTableBodyCellProps: {
              sx: { width: "49%" },
            },
          },
          {
            header: "削除",
            id: "action",
            Cell: ({ row }) =>
              readonly === false ? (
                <Button
                  size="sm"
                  onClick={() => {
                    deleteFileModal.open();
                    setDeleteFileId(row.original.id);
                  }}
                >
                  削除
                </Button>
              ) : (
                <></>
              ),
            size: 50,
            muiTableBodyCellProps: {
              sx: { width: "2%" },
            },
          },
        ] as MRT_ColumnDef<TGetRetirementFileListDto>[]
      ).filter((item) => {
        if (readonly && item.id === "action") return false;
        return true;
      }),
    [readonly],
  );

  const { table } = useTable({ columns, rows: retirementFileListData ?? [] });

  return (
    <>
      <Table table={table} isLoading={isLoading} />
      {deleteFileId && (
        <DeleteRetirementFileModal
          retirementFileId={deleteFileId}
          isOpen={deleteFileModal.visible}
          onClose={() => {
            deleteFileModal.close();
            setDeleteFileId(undefined);
          }}
        />
      )}
    </>
  );
};
