import { TMasterDocumentDto, TRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { AcceptanceActivityDocument } from "@/features/regular-document/components/documents/acceptance-activity";
import { LifeSupportDocument } from "@/features/regular-document/components/documents/life-support";
import { useScrollToElement } from "@/hooks/useScrollToElement";
import { DOCUMENT_KEYS } from "@/types/enum";
import { Box } from "@mui/material";
import { FC } from "react";

type TDocumentProps = {
  masterData: TMasterDocumentDto;
  document: TRegularDocumentDto;
  readonly?: boolean;
};

export const Document: FC<TDocumentProps> = ({ masterData, document, readonly = false }) => {
  const { elmId } = useScrollToElement("life_support_section");

  const isActivityDocument = (data: unknown): data is TRegularDocumentDto => {
    return masterData.documentKey === DOCUMENT_KEYS.ACCEPTANCE_ACTIVITY;
  };
  const isLiftSupportDocument = (data: unknown): data is TRegularDocumentDto => {
    return masterData.documentKey === DOCUMENT_KEYS.LIFE_SUPPORT;
  };

  if (isActivityDocument(document)) {
    return <AcceptanceActivityDocument document={document} readonly={readonly} />;
  }

  if (isLiftSupportDocument(document)) {
    return (
      <Box component="section" pt={5} id={elmId}>
        <LifeSupportDocument document={document} readonly={readonly} />
      </Box>
    );
  }

  return null;
};
