import { uploadFileApi } from "@/api/services/global/file";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem/FileItem";
import { useFormField } from "@/components/elements/FormField";
import { FILE_STATUS, FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { divider } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { TExtendFile, TExtendFileOption } from "@/types/file";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { getFileErrorMessage, getFileStatusWithRules } from "@/utils/file";
import { makeUuid } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { Stack, styled } from "@mui/material";
import { Key, useCallback, useEffect, useMemo, useState } from "react";

export type TUploadResidenceCardFileBtnProps = {
  nonControl?: boolean;
  readonly?: boolean;
  employeeFirstName: string | null;
  residenceExpiryDate: string | null;
};

export const UploadResidenceCardFileBtn: FCC<TUploadResidenceCardFileBtnProps> = ({
  nonControl = false,
  readonly,
  employeeFirstName,
  residenceExpiryDate,
}) => {
  const rules = { acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF].flat() };

  const { field } = useFormField(nonControl);
  const [files, setFiles] = useState<TExtendFile[]>([field?.value ?? []].flat());
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    if (field?.value) {
      const newValue: TExtendFile[] = [field?.value].flat();
      if (files.some((file) => !newValue.find(({ id }) => file.id === id))) {
        setFiles([field?.value].flat());
      }
    } else {
      setFiles([]);
    }
  }, [field]);

  const renameFile = (originalFile: File, newName: string) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const getExtensionOfMimeType = (mimeType: string): string => {
    switch (mimeType) {
      case "image/jpg":
        return ".jpg";
      case "image/jpeg":
        return ".jpeg";
      case "image/png":
        return ".png";
      case "application/pdf":
        return ".pdf";
      default:
        return "";
    }
  };

  const defaultFileName = useMemo(() => `${employeeFirstName}_${residenceExpiryDate}`, [residenceExpiryDate, employeeFirstName]);

  const handleUploadFile = useCallback(
    async (acceptedFiles: File[]) => {
      let newFiles = files;
      setUploading(true);
      const droppedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const fileOption: TExtendFileOption = {
            id: makeUuid(),
            status: getFileStatusWithRules(file, rules),
          };

          if (fileOption.status === FILE_STATUS.OK) {
            try {
              const rs = await uploadFileApi({
                file: renameFile(file, `${defaultFileName}${getExtensionOfMimeType(file.type)}`),
                uploadFor: FILE_UPLOAD_FOR.EMPLOYEE_RESIDENCE_CARD_FRONT_FILE,
              });
              return Object.assign(
                { ...file, name: `${defaultFileName}${getExtensionOfMimeType(file.type)}` },
                { ...fileOption, filePath: rs.data.filePath },
              );
            } catch (error) {
              showError(error);
              return Object.assign(
                { ...file, name: `${defaultFileName}${getExtensionOfMimeType(file.type)}` },
                { ...fileOption, status: FILE_STATUS.ERROR },
              );
            }
          }
          return Object.assign({ ...file, name: `${defaultFileName}${getExtensionOfMimeType(file.type)}` }, fileOption);
        }),
      );

      newFiles = [newFiles, droppedFiles].flat();

      setFiles(newFiles);
      triggerChangeFiles(newFiles);
      setUploading(false);
    },
    [files, defaultFileName],
  );

  const removeFile = (removedId: Key) => {
    const updatedFiles = files.filter(({ id }) => removedId !== id);
    triggerChangeFiles(updatedFiles);
    triggerChangeFiles(updatedFiles);
  };

  const triggerChangeFiles = (files: TExtendFile[]) => {
    field?.onChange(files);
    field?.onBlur();
  };

  const handleCheckExistFileName = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (!employeeFirstName || !residenceExpiryDate) {
      toast.error("データをアップロードする前に「名」を入力して、「在留期限（在留期間満了日）」を選択してください。");
      e.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Button component="label" size="md" fullWidth disabled={uploading || readonly}>
        {uploading ? "作成中…" : "データをアップロード"}
        <VisuallyHiddenInput
          type="file"
          accept={[MIME_TYPES.IMAGE, MIME_TYPES.PDF].flat().join(",")}
          onChange={(event) => event.target.files && handleUploadFile(Array.from(event.target.files))}
          onClick={(event) => {
            handleCheckExistFileName(event);
            event.currentTarget.value = "";
          }}
          multiple
        />
      </Button>
      <Stack flexDirection="column" gap={1} mt={files.length ? 1 : 0}>
        {files
          .sort((a, b) =>
            dayjs(a.name.substring(a.name.indexOf("_") + 1, a.name.indexOf("."))).isAfter(
              dayjs(b.name.substring(b.name.indexOf("_") + 1, b.name.indexOf("."))),
            )
              ? -1
              : 1,
          )
          .map((file) => (
            <FileItem
              key={file.id}
              filePath={file.filePath}
              downloadable={true}
              onRemove={!readonly ? () => removeFile(file.id) : undefined}
              maxWidth={336}
              errorMsg={getFileErrorMessage(file, rules)}
            />
          ))}
      </Stack>
    </Wrapper>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Wrapper = styled(Stack)`
  padding: 16px;
  border: 1px solid ${divider.middle};
  border-radius: ${rounded.sm};
  overflow: hidden;
`;
