import { useGetMainIndustryList } from "@/api/services/global/master";
import { MAX_INTEGER, MAXIMUM_NUMBER_VALIDATION } from "@/constants/common";
import { INVALID_NUMBER_MAX, INVALID_NUMBER_MIN } from "@/constants/invalids";
import { COMPANY_DIVISION } from "@/types/enum";
import { toNumber } from "@/utils/number";
import { replaceStr } from "@/utils/string";
import { validator } from "@/utils/validator";
import { useMemo } from "react";

export const useGetCompanySchema = () => {
  const { mainIndustryOtherIds } = useGetMainIndustryList();

  const supporterSchema = validator.object().shape({
    id: validator.number().decimal().emptyToNull().autoTransformToHalfWidth().integer().label("資本金").limit(MAX_INTEGER).optional().nullable(),
    supporterRole: validator.string().trim().emptyToNull().label("サポーター名").required(),
    supporterName: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).notHalfWidthCharacter().label("名前").required(),
    supporterFuriganaName: validator
      .string()
      .trim()
      .emptyToNull()
      .autoTransformToFullWidth()
      .fullWidthOnly()
      .notKanji()
      .optional()
      .label("ふりがな")
      .nullable(),
    companyPosition: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).notHalfWidthCharacter().label("役職").required(),
    telNumber: validator
      .string()
      .trim()
      .emptyToNull()
      .autoTransformToHalfWidth()
      .numbersOnly()
      .max(12, replaceStr(INVALID_NUMBER_MAX, { path: "電話番号（数字のみ）", max: 12 }))
      .optional()
      .label("電話番号（数字のみ）")
      .nullable(),
    email: validator.string().trim().emptyToNull().autoTransformToHalfWidth().optional().email().max(60).label("メールアドレス").nullable(),
  });

  const companySchema = useMemo(() => {
    const schema = validator.object().shape({
      companyName: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(60).label("会社・法人名").required(),
      companyFuriganaName: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToFullWidth()
        .fullWidthOnly()
        .notKanji()
        .label("会社・法人名（ふりがな）")
        .required(),
      representativeName: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("代表者名").required(),
      representativeJobTitle: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("代表者の役職名").required(),
      representativeFuriganaName: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToFullWidth()
        .fullWidthOnly()
        .notKanji()
        .label("代表者名（ふりがな）")
        .required(),
      division: validator.string().isSelection().trim().emptyToNull().optional().label("区分").required(),
      capital: validator
        .number()
        .decimal()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .integer()
        .min(0)
        .max(MAXIMUM_NUMBER_VALIDATION)
        .label("資本金")
        .required(),
      companyIdentificationNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .length(13)
        .label("法人番号（13桁）")
        .notRequired()
        .when("division", {
          is: COMPANY_DIVISION.CORPORATE,
          then: (schema) => schema.required(),
        }),
      businessInsuranceNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .length(11)
        .label("雇用保険適用事業所番号（11桁）")
        .required(),
      employmentInsuranceNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .min(11, replaceStr(INVALID_NUMBER_MIN, { path: "労働保険番号", min: 11 }))
        .max(18, replaceStr(INVALID_NUMBER_MAX, { path: "労働保険番号", max: 18 }))
        .label("労働保険番号")
        .required(),
      zipCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().zipCode().label("郵便番号").required(),
      province: validator.string().trim().isSelection().emptyToNull().label("都道府県").required(),
      address: validator.string().trim().emptyToNull().max(80).autoTransformToFullWidth().label("都道府県・市区町村以外の住所").required(),
      telNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .isTelNumber()
        .label("電話番号（ハイフン区切りの数字で入力）")
        .required(),
      annualRevenue: validator
        .number()
        .decimal()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .integer()
        .min(0)
        .max(999999999)
        .label("年間売上金額（直近年度）")
        .required(),
      yearsInBusiness: validator.number().decimal().emptyToNull().autoTransformToHalfWidth().integer().limit(MAX_INTEGER).label("年度").notRequired(),
      municipality: validator.string().isSelection().trim().emptyToNull().label("市区町村").required(),
      employeeCount: validator.number().decimal().emptyToNull().autoTransformToHalfWidth().integer().max(99999).label("常勤職員数").required(),
      specificIndustryId: validator.string().trim().isSelection().emptyToNull().label("特定産業分野 1").required(),
      specificBusinessClassificationIds: validator.array().label("業務区分 1").min(1, "「業務区分 1」を選択してください。").required(),
      specificIndustryTwoId: validator.string().isSelection().trim().emptyToNull().label("特定産業分野 2").notRequired(),
      specificBusinessClassificationTwoIds: validator
        .array()
        .label("業務区分 2")
        .notRequired()
        .transform((v) => (JSON.stringify(v) === "[]" ? null : v))
        .when("specificIndustryTwoId", {
          is: (value: string) => !!value,
          then: (schema) => schema.required("「業務区分 2」を選択してください。"),
        }),
      specificIndustryThreeId: validator.string().isSelection().trim().emptyToNull().label("特定産業分野 3").notRequired(),
      specificBusinessClassificationThreeIds: validator
        .array()
        .label("業務区分 3")
        .notRequired()
        .transform((v) => (JSON.stringify(v) === "[]" ? null : v))
        .when("specificIndustryThreeId", {
          is: (value: string) => !!value,
          then: (schema) => schema.required("「業務区分 3」を選択してください。"),
        }),
      mainIndustryId: validator.string().isSelection().emptyToNull().optional().label("主たる業種").required(),
      mainIndustryOther: validator
        .string()
        .trim()
        .emptyToNull()
        .label("その他を選択した場合の業種名")
        .max(40)
        .notRequired()
        .when("mainIndustryId", {
          is: (value: string) => mainIndustryOtherIds.includes(toNumber(value)),
          then: (schema) => schema.required(),
        }),
      immigrationJurisdiction: validator.string().isSelection().trim().emptyToNull().optional().label("入管管轄").required(),
      supporters: validator.array().of(supporterSchema).label("サポーター").required(),
      payrollDeadline: validator.string().isSelection().emptyToNull().optional().label("給与締日").notRequired(),
      paymentDate: validator
        .string()
        .isSelection()
        .emptyToNull()
        .optional()
        .label("給与支払日")
        .when("payrollDeadline", {
          is: "月末締め",
          then: (schema) => schema.required(),
        })
        .notRequired(),
      companyNote: validator.string().trim().emptyToNull().label("備考").notRequired(),
    });

    return schema;
  }, [mainIndustryOtherIds, supporterSchema]);

  return { companySchema };
};
