import { useEmployeeRetirementDocumentList } from "@/api/services/main/retirement-document";
import { Pagination } from "@/components/elements/Pagination";
import { Stack } from "@mui/material";
import { FC } from "react";

type TRetirementListTopProps = {};

export const RetirementListTop: FC<TRetirementListTopProps> = () => {
  const { data } = useEmployeeRetirementDocumentList();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={data?.pagination?.totalPage} />
    </Stack>
  );
};
