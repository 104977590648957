import { TApiSortsReq } from "@/types/api";
import { EMPLOYMENT_STATUS, RETIREMENT_STATUS, TEmploymentStatus, TRetirementStatus } from "@/types/enum";

export type TEmployeeListWithFilter = {
  employmentStatus?: TEmploymentStatus[];
  retirementStatus?: TRetirementStatus[];
  notRetirementStatus?: TRetirementStatus[];
  sorts?: TApiSortsReq[];
};

export const EMPLOYEE_FILTER: {
  OFFER: TEmployeeListWithFilter;
  WORKING_AND_LEFT: TEmployeeListWithFilter;
  PROCESSING_AND_RETIRE: TEmployeeListWithFilter;
} = {
  OFFER: {
    employmentStatus: [EMPLOYMENT_STATUS.OFFERED],
    notRetirementStatus: [RETIREMENT_STATUS.PROCESSING, RETIREMENT_STATUS.RETIRED],
    sorts: ["employee.createdAt|DESC"],
  },
  WORKING_AND_LEFT: {
    employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
    notRetirementStatus: [RETIREMENT_STATUS.PROCESSING, RETIREMENT_STATUS.RETIRED],
    sorts: ["employee.employmentStatus|ASC", "employee.createdAt|DESC"],
  },
  PROCESSING_AND_RETIRE: {
    retirementStatus: [RETIREMENT_STATUS.PROCESSING, RETIREMENT_STATUS.RETIRED],
    sorts: ["employee.retirementStatus|ASC", "employee.createdAt|DESC"],
  },
};
