import { FCC } from "@/types/common";
import { Stack, StackProps, Typography } from "@mui/material";

type TInfoRowProps = {
  title: string;
  titleBold?: boolean;
  contentText?: string;
  padding?: number;
} & StackProps;

export const InfoRow: FCC<TInfoRowProps> = ({ title, titleBold = true, contentText, padding = 1, children, ...rest }) => {
  return (
    <Stack {...rest}>
      <Typography variant={titleBold ? "body14Bold" : "body14"}>{title}</Typography>
      {contentText && (
        <Typography whiteSpace="break-spaces" padding={padding}>
          {contentText}
        </Typography>
      )}
      {children}
    </Stack>
  );
};
