import {
  useGetRenewVisaBasicInformationDocumentInfo,
  useGetRenewVisaDocumentInfo,
  useGetRenewVisaUploadFileDocumentInfo,
} from "@/api/services/main/renew-visa";
import { RENEW_VISA_UPLOAD_APPLICATION_FILE, TUploadFileWithCondition } from "@/features/renew-visa/constants/uploadApplicationFile";
import { ERenewVisaCondition } from "@/features/renew-visa/enums/renewVisaCondition";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";
import { findRenewVisaDocumentStep } from "@/features/renew-visa/utils/findDocumentStep";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { toNumber } from "@/utils/number";
import { useMemo } from "react";

export type FileWithFilterCondition = {
  label: string;
  subLabel: string;
  value: (TUploadFileWithCondition & { isUploaded: boolean })[];
};

type UseGetUploadFileListWithFilterConditionReturn = {
  uploadFileListWithFilterCondition: FileWithFilterCondition[];
  filterConditionArrayKeys: (keyof TRenewVisaUploadFileForm)[];
  conditions: string[];
  isLoading: boolean;
};

export const useGetUploadFileListWithFilterCondition = (): UseGetUploadFileListWithFilterConditionReturn => {
  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const basicInfoStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_BASIC_INFORMATION);
  const applicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_CREATE_DOCUMENT);

  const { data: basicInfoData, isLoading: isLoadingBasicInfo } = useGetRenewVisaBasicInformationDocumentInfo({
    documentStepId: basicInfoStepData?.id ?? "",
    enabled: !!basicInfoStepData?.updater && !!basicInfoStepData,
  });

  const { data: uploadFileData, isLoading: isLoadingUploadFile } = useGetRenewVisaUploadFileDocumentInfo({
    documentStepId: applicationStepData?.id ?? "",
    enabled: basicInfoStepData?.status === DOCUMENT_STATUS.COMPLETE && !!basicInfoStepData,
  });

  const conditions = (basicInfoData?.conditions ?? []) as ERenewVisaCondition[];

  const employeeCompanySpecificIndustryIds = useMemo(
    () => [
      toNumber(
        renewVisaDocument?.employee.contractSpecificBusinessClassificationOne?.companySpecificBusinessClassification.companySpecificIndustryId,
      ),
      toNumber(
        renewVisaDocument?.employee.contractSpecificBusinessClassificationTwo?.companySpecificBusinessClassification.companySpecificIndustryId,
      ),
      toNumber(
        renewVisaDocument?.employee.contractSpecificBusinessClassificationThree?.companySpecificBusinessClassification.companySpecificIndustryId,
      ),
    ],
    [renewVisaDocument],
  );

  const companyDivision = useMemo(() => renewVisaDocument?.employee.company.division ?? null, [renewVisaDocument]);

  const uploadFileListWithFilterCondition = useMemo(() => {
    const array = Object.values(RENEW_VISA_UPLOAD_APPLICATION_FILE).map((group) => {
      const returnItem = {
        label: group.label,
        subLabel: group.subLabel,
        value: group.value
          .filter((detailFile) => (Array.isArray(detailFile.condition) && detailFile.condition.length === 0 ? conditions.length === 0 : true))
          .filter((detailFile) =>
            Array.isArray(detailFile.condition) && detailFile.condition.length > 0
              ? detailFile.condition.some((condition) => conditions.includes(condition))
              : true,
          )
          .filter((detailFile) =>
            typeof detailFile.condition === "string" && detailFile.condition !== ERenewVisaCondition.ALL
              ? conditions.includes(detailFile.condition)
              : true,
          )
          .filter((detailFile) =>
            detailFile.companySpecificIndustryIdsCondition
              ? detailFile.companySpecificIndustryIdsCondition.some((item) => employeeCompanySpecificIndustryIds.includes(item))
              : true,
          )
          .filter((detailFile) => (companyDivision ? detailFile.companyDivisionCondition?.includes(companyDivision) : true))
          .map((item) => ({
            ...item,
            isUploaded: uploadFileData
              ? item.children
                ? item.children.some((childItem) => !!uploadFileData[childItem.key])
                : !!uploadFileData[item.key] || (!!item.isExistKey && !!uploadFileData[item.isExistKey])
              : false,
          })),
      };
      return returnItem;
    });
    return array;
  }, [conditions, uploadFileData, companyDivision, employeeCompanySpecificIndustryIds]);

  const filterConditionArrayKeys = useMemo(
    () =>
      uploadFileListWithFilterCondition.flatMap((item) =>
        item.value.flatMap((valueItem) => (!!valueItem.children ? valueItem.children.map((item) => item.key) : [valueItem.key])),
      ),
    [uploadFileListWithFilterCondition],
  );

  const isLoading = useMemo(
    () => isLoadingRenewVisaDocument || isLoadingBasicInfo || isLoadingUploadFile,
    [isLoadingRenewVisaDocument, isLoadingBasicInfo, isLoadingUploadFile],
  );

  return { isLoading, uploadFileListWithFilterCondition, filterConditionArrayKeys, conditions };
};
