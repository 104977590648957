import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { Stepper } from "@/components/elements/Stepper/Stepper";
import { CREATE_EMPLOYEE, ENTER_COMPANY_BRANCH_INFO, ENTER_COMPANY_INFO, INIT_COMPLETE } from "@/constants/guideStepper";
import { useHideElm } from "@/hooks/useHideElm";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { Box } from "@mui/material";
import { FC } from "react";

type TGuideStepperProps = {};

export const GuideStepper: FC<TGuideStepperProps> = () => {
  const { visible, onClose } = useHideElm("guide-stepper");
  const { currentStep, isLoading } = useInitSetup();

  if (!visible) return null;
  return (
    <InnerBox title="irohanaをご利用いただくためのステップバイステップ" maxWidth={760} mx="auto" loading={isLoading}>
      <Stepper items={[ENTER_COMPANY_INFO, ENTER_COMPANY_BRANCH_INFO, CREATE_EMPLOYEE, INIT_COMPLETE]} currentStep={currentStep} />
      <Box ml={7} mt={1}>
        <Button variant="outline" size="sm" onClick={onClose}>
          このチュートリアルを閉じる
        </Button>
      </Box>
    </InnerBox>
  );
};
