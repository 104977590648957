export const getYearMonthOfTransitionPeriod = (period: string) => {
  // Return default values for empty string input
  if (period.trim() === "") {
    return { year: 0, month: 0 };
  }

  // Default values for year and month
  let year = 0;
  let month = 0;

  // Regular expression to capture year and month
  const match = period.match(/(?:(\d+)年)?(?:(\d+)ヶ月)?/);

  // Check if we have valid captures for year and month
  if (match) {
    if (match[1]) {
      // match[1] corresponds to "year" if present
      year = parseInt(match[1], 10);
    }
    if (match[2]) {
      // match[2] corresponds to "month" if present
      month = parseInt(match[2], 10);
    }
  }

  return { year, month };
};
