import { TEmployeeRetirementDocumentStep } from "@/api/entities/employee-retirement-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import { Button } from "@/components/elements/Button";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { RetirementDocumentStatusTag } from "@/features/retirement/components/retirement-document/documents/RetirementDocumentStatusTag";
import { RetirementStepCard } from "@/features/retirement/components/retirement-document/documents/RetirementStepCard";
import { AppRoutes } from "@/routes/config";
import { EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TStepProps = {
  document: TEmployeeRetirementDocumentOutputDto;
  stepData: TEmployeeRetirementDocumentStep;
  readonly?: boolean;
};

export const EmploymentContractCreateDocumentStep: FC<TStepProps> = ({ document, stepData, readonly }) => {
  const { documentGroupId } = useParams();
  const { masterDocumentStep, updatedAt, updater, status } = stepData;
  const { stepOrder, stepName } = masterDocumentStep;
  const { data: employeeRetirementDocumentStepStatuses } = useEnum("EEmployeeRetirementDocumentStepStatus");

  return (
    <RetirementStepCard title={`ステップ${stepOrder}`} subTitle={stepName}>
      <Stack direction="row" justifyContent="space-between">
        <Stack flexDirection="row" flex={1} alignItems="flex-start" gap={10}>
          <Stack gap={1}>
            <Typography variant="body14Bold">更新日時</Typography>
            <Typography variant="body14">{updatedAt && updater ? dayjs(updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}</Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body14Bold">最終更新者</Typography>
            <Typography variant="body14">{updater?.fullName || EMPTY_STR.TEXT}</Typography>
          </Stack>
          {status && (
            <Stack gap={1}>
              <Typography variant="body14Bold">ステータス</Typography>
              <RetirementDocumentStatusTag
                status={status}
                title={findLabelByValue(employeeRetirementDocumentStepStatuses, status) || EMPTY_STR.TEXT}
              />
            </Stack>
          )}
        </Stack>
        <Button
          variant="secondary"
          size="sm"
          component={Link}
          to={
            readonly
              ? getDynamicRoute(AppRoutes.retirementEmploymentContractDetail, {
                  documentGroupId: documentGroupId,
                  documentId: document.id,
                  id: stepData?.id,
                })
              : getDynamicRoute(AppRoutes.retirementEmploymentContract, {
                  documentGroupId: documentGroupId,
                  documentId: document.id,
                  id: stepData?.id,
                })
          }
        >
          {status !== EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE ? "編集" : "確認"}
        </Button>
      </Stack>
    </RetirementStepCard>
  );
};
