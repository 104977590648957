import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

type TButtonGroupProps = {
  saveDraft: () => Promise<void>;
  disabled: boolean;
  readonly?: boolean;
  isCompleted?: boolean;
  isEditable: boolean;
  setEditable: (v: boolean) => void;
};

export const ButtonGroup: FC<TButtonGroupProps> = ({ saveDraft, disabled, readonly, isEditable, setEditable }) => {
  if (readonly) return null;

  return (
    <InnerBox bordered p={3} boxShadow={elevation.lv2}>
      <Stack gap={4} alignItems="center">
        <Typography variant="body14">必須項目を入力してください。</Typography>
        <Stack width="100%" direction="row" gap={3} justifyContent="center">
          {isEditable && (
            <>
              {/* <Button variant="outline" size="lg" component={Link} to={AppRoutes.regularPresent}>
                戻る
              </Button> */}

              <Button variant="outline" size="lg" onClick={saveDraft}>
                下書き保存する
              </Button>
              <Button type="submit" variant="primary" size="lg" disabled={disabled}>
                保存する
              </Button>
            </>
          )}
          {!isEditable && (
            <>
              <Button variant="outline" size="lg" disabled={isEditable} onClick={() => setEditable(true)}>
                再編集する
              </Button>
              <Button type="submit" variant="primary" size="lg" component={Link} to={AppRoutes.regularPresent}>
                届出トップに戻る
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </InnerBox>
  );
};
