import { deleteAnytimeDocumentApi, useAnytimeDocumentList } from "@/api/services/main/any-time";
import { ConfirmDeleteModal } from "@/components/commons/ConfirmDeleteModal";
import { InnerBox } from "@/components/elements/InnerBox";
import { Pagination } from "@/components/elements/Pagination";
import { Table } from "@/components/elements/Table";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { useDocumentListColumns } from "@/features/anytime/hooks/useDocumentListColumns";
import { useDialog } from "@/hooks/useDialog";
import { useTable } from "@/hooks/useTable";
import { DOCUMENT_STATUS } from "@/types/enum";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Stack } from "@mui/material";

export const DocumentList = () => {
  const { data: documentListResult, isLoading, refetch } = useAnytimeDocumentList();
  const documentListData = documentListResult?.data;
  const confirmDelete = useDialog();

  const handleDeleteDocument = async (anytimeDocumentId: number | string) => {
    try {
      const agree = await confirmDelete.show();
      if (agree) {
        const rs = await deleteAnytimeDocumentApi(anytimeDocumentId);
        await refetch();
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
    } catch (error) {
      showError(error);
    }
  };

  const columns = useDocumentListColumns({ handleDeleteDocument });

  const { table } = useTable({
    columns,
    rows: documentListData ?? [],
    failedRow: (row) => row.status === DOCUMENT_STATUS.IN_PROGRESS,
    pinningColumns: ["action"],
  });

  return (
    <InnerBox title="作成済みの書類一覧">
      <Stack gap={2}>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="outline" disabled={rowSelectionKeys.length === 0}>
            書類をダウンロード
          </Button>
        </Stack> */}
        <Pagination totalPage={documentListResult?.pagination.totalPage} />
        <Table table={table} isLoading={isLoading} />
        <ConfirmDeleteModal
          open={confirmDelete.open}
          onClose={confirmDelete.cancel}
          onOk={confirmDelete.confirm}
          message="ドキュメントを削除してもよろしいですか?"
        />
      </Stack>
    </InnerBox>
  );
};
