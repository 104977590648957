import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

type TAcceptActivityBtnActionsProps = {
  saveDraft: () => Promise<void>;
  readonly?: boolean;
  isEditable: boolean;
  setIsEditable: (v: boolean) => void;
};

export const AcceptActivityBtnActions: FC<TAcceptActivityBtnActionsProps> = ({ saveDraft, readonly, isEditable, setIsEditable }) => {
  const params = useParams();
  const { pathname } = useLocation();

  const isPresent = pathname.startsWith(AppRoutes.regularPresentAcceptActivity);

  return (
    <InnerBox bordered p={3} boxShadow={elevation.lv2}>
      <Stack direction="row" gap={2} justifyContent={"center"}>
        {!readonly && isEditable && (
          <>
            <Button variant="outline" size="lg" onClick={saveDraft}>
              下書き保存する
            </Button>
            <Button type="submit" variant="primary" size="lg">
              次へ
            </Button>
          </>
        )}

        {!readonly && !isEditable && (
          <>
            <Button variant="outline" size="lg" onClick={() => setIsEditable(true)}>
              再編集する
            </Button>
            <Button variant="primary" size="lg" component={Link} to={AppRoutes.regularPresentAcceptActivityPayCheck}>
              次へ
            </Button>
          </>
        )}

        {readonly && (
          <Button
            variant="primary"
            size="lg"
            component={Link}
            to={isPresent ? AppRoutes.regularPresentAcceptActivityPayCheck : getDynamicRoute(AppRoutes.regularHistoryAcceptActivityPayCheck, params)}
          >
            次へ
          </Button>
        )}
      </Stack>
    </InnerBox>
  );
};
