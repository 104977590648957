import { useRegularDocumentHistoryDetail } from "@/api/services/main/regular-document";
import { useAcceptanceActivityDocument, useEmployeePayrollList } from "@/api/services/main/regular-document/acceptance-activity-document";
import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { SalaryReflection } from "@/features/regular-document/components/accept-activity/paycheck/SalaryReflection";
import { PayCheckProvider } from "@/features/regular-document/providers/PayCheckProvider";
import { makePayrollRecords } from "@/features/regular-document/utils/convert";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";

export const RegularHistoryAcceptActivityPayCheckPage = () => {
  const { year, quarter } = useParams();
  const { data: presentDocumentListResult, isLoading: isDocumentLoading } = useRegularDocumentHistoryDetail(year, quarter);
  const documentData = presentDocumentListResult?.data;
  const stepData = findDocumentStep(documentData?.regularDocuments, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT);
  const stepId = stepData?.id;
  const { data: acceptanceActivityResult, isLoading: isActivityLoading } = useAcceptanceActivityDocument({
    documentStepId: stepId,
    enabled: !!stepData?.updater,
  });
  const acceptanceActivityData = acceptanceActivityResult?.data;
  const { data: payrollListResult, isLoading } = useEmployeePayrollList(stepId);
  const payrollListData = payrollListResult?.data;

  if (!documentData || !stepId || !acceptanceActivityData || isLoading || isDocumentLoading || isActivityLoading) return <LoadingPage />;
  return (
    <PayCheckProvider
      previousData={payrollListData?.length ? makePayrollRecords(payrollListData) : undefined}
      previousFilePath={acceptanceActivityData?.payrollFilePath}
      resourceData={{
        year: documentData.year,
        quarter: documentData.quarter,
        acceptanceActivityDocumentId: acceptanceActivityData.id,
        status: stepData.status,
      }}
      stepDocumentId={stepId}
      readonly
    >
      <Box maxWidth={COMPANY_CONTAINER}>
        <TopPage
          title="特定技能外国人の受入れ状況・報酬の支払状況"
          backUrl={getDynamicRoute(AppRoutes.regularHistoryAcceptActivity, { year, quarter })}
          isDraft={payrollListData?.some(({ isDraft }) => isDraft) || acceptanceActivityData.payrollAskIrohanaSupport === true}
        />
        <MenuNavigate
          contents={[{ title: "賃金台帳の反映", content: <SalaryReflection /> }]}
          contentAffix={
            <InnerBox bordered p={3} boxShadow={elevation.lv2}>
              <Stack direction="row" gap={3} justifyContent="space-between">
                <Button variant="outline" size="lg" component={Link} to={getDynamicRoute(AppRoutes.regularHistoryAcceptActivity, { year, quarter })}>
                  戻る
                </Button>
                <Button variant="primary" size="lg" component={Link} to={AppRoutes.regularPresent}>
                  本届出のトップに戻る
                </Button>
              </Stack>
            </InnerBox>
          }
        />
      </Box>
    </PayCheckProvider>
  );
};
