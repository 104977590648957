import { TASK_STATUS } from "@/features/dashboard/constants/status";

export const regular = {
  deadline: "2023/12/20",
  items: [
    {
      id: 1,
      targetQuarter: 1,
      range: "2024/1-2024/3",
      status: TASK_STATUS.COMPLETE,
      user: { name: "佐藤 一郎", avatar: "" },
    },
  ],
};

export const extension = {
  items: [
    {
      id: 1,
      deadline: "2024/03/01",
      status: TASK_STATUS.COMPLETE,
      foreigner: { name: "佐藤 一郎", avatar: "" },
      manager: { name: "佐藤 一郎", avatar: "" },
    },
    {
      id: 2,
      deadline: "2024/03/01",
      status: TASK_STATUS.COMPLETE,
      foreigner: { name: "佐藤 一郎", avatar: "" },
      manager: { name: "佐藤 一郎", avatar: "" },
    },
    {
      id: 3,
      deadline: "2024/03/01",
      status: TASK_STATUS.COMPLETE,
      foreigner: { name: "佐藤 一郎", avatar: "" },
      manager: { name: "佐藤 一郎", avatar: "" },
    },
  ],
};

export const scheduleJoin = {
  deadline: "2024/03/01",
  count: 2,
  items: [
    {
      id: 1,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      nation: "Empty",
    },
    {
      id: 2,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      nation: "Empty",
    },
    {
      id: 3,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      nation: "Empty",
    },
    {
      id: 4,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      nation: "Empty",
    },
  ],
};

export const planningRetire = {
  deadline: "2024/03/01",
  count: 2,
  items: [
    {
      id: 1,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      status: TASK_STATUS.COMPLETE,
    },
    {
      id: 2,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      status: TASK_STATUS.COMPLETE,
    },
    {
      id: 3,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      status: TASK_STATUS.COMPLETE,
    },
    {
      id: 4,
      start: "2024/03/01",
      end: "2024/00/00",
      foreigner: { name: "佐藤 一郎", avatar: "" },
      status: TASK_STATUS.COMPLETE,
    },
  ],
};

export const notifications = [
  {
    id: 1,
    time: "2024/00/00",
    type: "制度変更",
    title: "在留期間更新の入力項目に〜〜〜が追加されました。",
    description: "ダミーテキストが入りますダミーテキストが入りますダミーテキスト...",
  },
  {
    id: 2,
    time: "2024/00/00",
    type: "制度変更",
    title: "在留期間更新の入力項目に〜〜〜が追加されました。",
    description: "ダミーテキストが入りますダミーテキストが入りますダミーテキスト...",
  },
  {
    id: 3,
    time: "2024/00/00",
    type: "制度変更",
    title: "在留期間更新の入力項目に〜〜〜が追加されました。",
    description: "ダミーテキストが入りますダミーテキストが入りますダミーテキスト...",
  },
  {
    id: 4,
    time: "2024/00/00",
    type: "制度変更",
    title: "在留期間更新の入力項目に〜〜〜が追加されました。",
    description: "ダミーテキストが入りますダミーテキストが入りますダミーテキスト...",
  },
];
