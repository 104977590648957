import { InternetStatus } from "@/components/commons/InternetStatus";
import { Toast } from "@/components/elements/Toast";
import { queryClient, queryClientNoCache } from "@/constants/queryClient";
import { MaintenanceConsumer, MaintenanceProvider } from "@/providers/MaintenanceProvider";
import theme from "@/theme";
import { FCC } from "@/types/common";
import { MAINTENANCE_MODE } from "@/types/enum";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jaJP } from "@mui/x-date-pickers/locales";
import { QueryClientProvider } from "@tanstack/react-query";
import "dayjs/locale/ja";

const jpLocale = jaJP.components.MuiLocalizationProvider.defaultProps.localeText;

export const AppProvider: FCC = ({ children }) => {
  return (
    <MaintenanceProvider>
      <MaintenanceConsumer>
        {({ data }) => {
          return (
            <QueryClientProvider client={data?.maintenanceMode === MAINTENANCE_MODE.MAINTENANCE_READABLE ? queryClientNoCache : queryClient}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Toast />
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={jpLocale} adapterLocale="ja">
                  {children}
                </LocalizationProvider>
                <InternetStatus />
              </ThemeProvider>
            </QueryClientProvider>
          );
        }}
      </MaintenanceConsumer>
    </MaintenanceProvider>
  );
};
