import { setCompleteAnytimeDocumentApi, useAnytimeDocument } from "@/api/services/main/any-time";
import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { TypoLink } from "@/components/elements/TypoLink";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { AnytimeDocumentCreateStep } from "@/features/anytime/components/anytime-detail/document/AnytimeDocumentCreateStep";
import { AnytimeDocumentSubmitStep } from "@/features/anytime/components/anytime-detail/document/AnytimeDocumentSubmitStep";
import { AnytimeDocumentUploadStep } from "@/features/anytime/components/anytime-detail/document/AnytimeDocumentUploadStep";
import { AnyTimeDocumentTypeOptions, EAnyTimeDocumentType } from "@/features/anytime/constants/enums";
import { useCheckAnytimeDocument } from "@/features/anytime/pages/hooks/useCheckAnytimeDocument";
import { AppRoutes } from "@/routes/config";
import { background, button, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded, spacing } from "@/theme/variables";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { showError } from "@/utils/error";
import { findLabelByValue } from "@/utils/object";
import { css, Stack, styled, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const AnytimeDocumentPage = () => {
  const { data: documentResult, isLoading } = useAnytimeDocument();
  const { id } = useParams();
  const documentData = documentResult?.data;
  const createStepData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT);
  const uploadStepData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.ANY_TIME_UPLOAD_DOCUMENT);
  const submitStepData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.ANY_TIME_SUBMIT);
  const queryClient = useQueryClient();
  const { finished, completed } = useCheckAnytimeDocument();
  const navigate = useNavigate();
  const isCompleted = documentData?.status === DOCUMENT_STATUS.COMPLETE;

  const submitDocument = async () => {
    try {
      if (!id) return;
      await setCompleteAnytimeDocumentApi(id);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_LIST] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_EMPLOYMENT_CONTRACT, id] });
      navigate(AppRoutes.anytime);
    } catch (error) {
      showError(error);
    }
  };

  const documentLink = useMemo(() => {
    switch (documentData?.anyTimeDocumentType) {
      case EAnyTimeDocumentType.EMPLOYMENT_CONTRACT_MODIFICATION:
        return "https://www.iro-hana.com/92f3a50922394c85b543fd3d0001fff7?pvs=4";
      case EAnyTimeDocumentType.SUPPORT_PLAN_MODIFICATION:
        return "https://www.iro-hana.com/0a015f5a3a0844f38aa86d07de36aa14?pvs=4";
      case EAnyTimeDocumentType.SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION:
        return "https://www.iro-hana.com/561abefaf0114cdebbf39de54697717e?pvs=4";
      default:
        return "";
    }
  }, [documentData?.anyTimeDocumentType]);

  if (isLoading || !documentData) return <LoadingPage />;

  return (
    <>
      <TopPage title="その他の随時届出" description="届出書類のそれぞれのステップを進んで、「提出」を押してください" backUrl={AppRoutes.anytime} />
      <InnerBox>
        <Stack gap={3} position="relative">
          {isCompleted ? null : (
            <RightButton>
              <CompletedButton disabled={!finished} onClick={submitDocument}>
                随時届出を完了
              </CompletedButton>
              {!completed && (
                <Typography variant="body14Bold" color={state.error_1}>
                  全てのステップを完了してください。
                </Typography>
              )}
            </RightButton>
          )}
          <Stack gap={3}>
            <Typography variant="head20Bold" color={text.accent}>
              {findLabelByValue(AnyTimeDocumentTypeOptions, documentData?.anyTimeDocumentType)}
            </Typography>
            <Stack gap={1}>
              <Typography>添付書類は取得に時間がかかる場合があります。余裕をもって手続きを進めましょう。</Typography>
              <TypoLink to={documentLink} target="_blank">
                随時届出の添付書類についてはこちら
              </TypoLink>
            </Stack>
          </Stack>

          {createStepData && <AnytimeDocumentCreateStep document={documentData} stepData={createStepData} readonly={isCompleted} />}
          {uploadStepData && <AnytimeDocumentUploadStep document={documentData} stepData={uploadStepData} readonly={isCompleted} />}
          {submitStepData && <AnytimeDocumentSubmitStep document={documentData} stepData={submitStepData} readonly={isCompleted} />}
        </Stack>
      </InnerBox>
    </>
  );
};

const RightButton = styled(Stack)`
  gap: 8px;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
`;

const CompletedButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: ${button.secondary}!important;
    border-radius: ${rounded.sm};
    padding: ${`10px ${spacing.md}`};
    color: ${background.white}!important;
    border: none !important;
    ${css(typography.sub16Semi)}
    cursor: pointer;
    &:disabled {
      background-color: ${button.disable}!important;
    }
  }
`;
