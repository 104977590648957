import { TCreateOrUpdateRenewVisaEmployeeApplicationDocumentReqDto } from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-employee-application-document.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { isNumber, toNumber } from "@/utils/number";

export const convertEmployeeApplicationFormDataToReqDto = (
  data: TEmployeeApplicationForm,
  isDraft: boolean,
): TCreateOrUpdateRenewVisaEmployeeApplicationDocumentReqDto => {
  return {
    employeeId: toNumber(data.employeeId),
    employeeInfoFullName: data.employeeInfoFullName,
    employeeInfoBirthday: data.employeeInfoBirthday.format(ISO_FORMAT),
    employeeInfoNationalityId: data.employeeInfoNationalityId ? toNumber(data.employeeInfoNationalityId) : null,
    employeeInfoAddress: data ? data.employeeInfoAddress : null,
    employeeInfoGender: data ? data.employeeInfoGender : null,
    employeeInfoPassportExpiryDate: data.employeeInfoPassportExpiryDate ? data.employeeInfoPassportExpiryDate.format(ISO_FORMAT) : null,
    contractMainOccupationId: data.contractMainOccupationId ? toNumber(data.contractMainOccupationId) : null,
    employeeInfoTelNumber: data ? data.employeeInfoTelNumber : null,
    employeeInfoEmail: data ? data.employeeInfoEmail : null,
    employeeInfoPassportCode: data ? data.employeeInfoPassportCode : null,
    relatedActivityMajorId: data.relatedActivityMajorId ? toNumber(data.relatedActivityMajorId) : null,
    relatedActivityJobTypeId: data.relatedActivityJobTypeId ? toNumber(data.relatedActivityJobTypeId) : null,
    employeeInfoPassportPermanentAddress: data ? data.employeeInfoPassportPermanentAddress : null,
    relatedActivityCompletionProof: data ? data.relatedActivityCompletionProof : null,
    relatedActivitySkillExamOneId: data.relatedActivitySkillExamOneId ? toNumber(data.relatedActivitySkillExamOneId) : null,
    relatedActivityJapaneseExamTwoId: data.relatedActivityJapaneseExamTwoId ? toNumber(data.relatedActivityJapaneseExamTwoId) : null,
    relatedActivitySkillCertificationOne: data ? data.relatedActivitySkillCertificationOne : null,
    relatedActivitySkillExamLocationOne: data ? data.relatedActivitySkillExamLocationOne : null,
    relatedActivitySkillOtherEvaluation: data ? data.relatedActivitySkillOtherEvaluation : null,
    relatedActivitySkillExamOverseaOneNationalityId: data.relatedActivitySkillExamOverseaOneNationalityId
      ? toNumber(data.relatedActivitySkillExamOverseaOneNationalityId)
      : null,
    relatedActivityJapaneseCertificationTwo: data ? data.relatedActivityJapaneseCertificationTwo : null,
    relatedActivityJapaneseExamLocationTwo: data ? data.relatedActivityJapaneseExamLocationTwo : null,
    relatedActivityJapaneseExamThreeId: data.relatedActivityJapaneseExamThreeId ? toNumber(data.relatedActivityJapaneseExamThreeId) : null,
    relatedActivityJapaneseExamLocationThree: data ? data.relatedActivityJapaneseExamLocationThree : null,
    relatedActivityJapaneseCertificationThree: data ? data.relatedActivityJapaneseCertificationThree : null,
    relatedActivityJapaneseExamOverseaTwoNationalityId: data.relatedActivityJapaneseExamOverseaTwoNationalityId
      ? toNumber(data.relatedActivityJapaneseExamOverseaTwoNationalityId)
      : null,
    relatedActivityJapaneseExamOverseaThreeNationalityId: data.relatedActivityJapaneseExamOverseaThreeNationalityId
      ? toNumber(data.relatedActivityJapaneseExamOverseaThreeNationalityId)
      : null,
    employeeInfoTwoResidenceCode: data.employeeInfoTwoResidenceCode,
    employeeInfoTwoResidenceExpiryDate: data.employeeInfoTwoResidenceExpiryDate ? data.employeeInfoTwoResidenceExpiryDate.format(ISO_FORMAT) : null,
    employeeInfoTwoResidenceStatusId: data.employeeInfoTwoResidenceStatusId ? toNumber(data.employeeInfoTwoResidenceStatusId) : null,
    employeeInfoTwoResidencePeriodId: data.employeeInfoTwoResidencePeriodId ? toNumber(data.employeeInfoTwoResidencePeriodId) : null,
    contractMonthlySalary: data ? data.contractMonthlySalary : null,
    contractSpecificIndustryId: data.contractSpecificIndustryId ? toNumber(data.contractSpecificIndustryId) : null,
    contractSpecificBusinessClassificationId: data.contractSpecificBusinessClassificationId
      ? toNumber(data.contractSpecificBusinessClassificationId)
      : null,
    contractSpecificIndustryTwoId: data.contractSpecificIndustryTwoId ? toNumber(data.contractSpecificIndustryTwoId) : null,
    contractSpecificBusinessClassificationTwoId: data.contractSpecificBusinessClassificationTwoId
      ? toNumber(data.contractSpecificBusinessClassificationTwoId)
      : null,
    contractSpecificIndustryThreeId: data.contractSpecificIndustryThreeId ? toNumber(data.contractSpecificIndustryThreeId) : null,
    contractSpecificBusinessClassificationThreeId: data.contractSpecificBusinessClassificationThreeId
      ? toNumber(data.contractSpecificBusinessClassificationThreeId)
      : null,
    contractStartDate: data.contractStartDate ? data.contractStartDate.format(ISO_FORMAT) : null,
    contractEndDate: data.contractEndDate ? data.contractEndDate.format(ISO_FORMAT) : null,
    employeeInfoProvince: data ? data.employeeInfoProvince : null,
    employeeInfoMunicipality: data ? data.employeeInfoMunicipality : null,
    dispatchingOrganizations: data
      ? data.dispatchingOrganizations.map((item) => ({
          organizationName: item.organizationName,
          organizationIdentificationNumber: item.organizationIdentificationNumber,
          employmentInsuranceApplicableEstablishmentNumber: item.employmentInsuranceApplicableEstablishmentNumber,
          organizationProvince: item.organizationProvince?.toString(),
          organizationMunicipality: item.organizationMunicipality?.toString(),
          organizationAddress: item.organizationAddress,
          organizationTelNumber: item.organizationTelNumber,
          representativeName: item.representativeName,
          startDate: item.startDate ? item.startDate.format(ISO_FORMAT) : null,
          endDate: item.endDate ? item.endDate.format(ISO_FORMAT) : null,
          isDraft,
        }))
      : [],
    employeeFamilyMembers: data
      ? data.employeeFamilyMembers.map((item) => ({
          employeeRelationshipId: item.employeeRelationshipId ? toNumber(item.employeeRelationshipId) : null,
          fullName: item.fullName ?? "",
          birthday: item.birthday ? item.birthday.format(ISO_FORMAT) : null,
          nationalityId: item.nationalityId ? toNumber(item.nationalityId) : null,
          livingWithEmployee: item.livingWithEmployee ?? null,
          workingLocation: item.workingLocation ?? null,
          residenceCode: item.residenceCode ?? null,
          isDraft,
        }))
      : [],
    employeeInfoLivingWithDependentPerson: data.employeeInfoLivingWithDependentPerson,
    employeeInfoMaritalStatus: data.employeeInfoMaritalStatus,
    employeeInfoPunishmentDetailForCrime: data.employeeInfoPunishmentDetailForCrime ?? null,
    employeeInfoResidenceCardReceiveMethod: data.employeeInfoResidenceCardReceiveMethod,
    relatedActivitySpecificSkillTransitionPeriod: data ? data.relatedActivitySpecificSkillTransitionPeriod : null,
    relatedActivityHasCollectionForSpecificSkillOne: data ? data.relatedActivityHasCollectionForSpecificSkillOne : null,
    whetherEquivalentWorkingHoursToRegularEmployee: data ? data.whetherEquivalentWorkingHoursToRegularEmployee : null,
    employeeInfoHasReceivedPunishmentForCrime: data ? data.employeeInfoHasReceivedPunishmentForCrime : null,
    compensationEqualOrAboveJapaneseStandard: data ? data.compensationEqualOrAboveJapaneseStandard : null,
    contractIsDiscriminatedForBeingForeigner: data ? data.contractIsDiscriminatedForBeingForeigner : null,
    contractIsDiscriminatedForBeingForeignerDetail: data ? data.contractIsDiscriminatedForBeingForeignerDetail : null,
    contractHasPaidLeaveForReturningHomeTemporarily: data ? data.contractHasPaidLeaveForReturningHomeTemporarily : null,
    contractHasProvisionForTravelExpensesWhenLeave: data ? data.contractHasProvisionForTravelExpensesWhenLeave : null,
    complianceWithStandardsForProperResidenceInSpecificIndustry: data ? data.complianceWithStandardsForProperResidenceInSpecificIndustry : null,
    relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees: data ? data.relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees : null,
    relatedActivityHasComplianceWithProceduresInHomeCountry: data ? data.relatedActivityHasComplianceWithProceduresInHomeCountry : null,
    relatedActivityWhetherRegularPayments: data ? data.relatedActivityWhetherRegularPayments : null,
    relatedActivityHasEffortToTransferSkillsToHomeCountry: data ? data.relatedActivityHasEffortToTransferSkillsToHomeCountry : null,
    relatedActivityHasComplianceWithIndustrySpecificCriteria: data ? data.relatedActivityHasComplianceWithIndustrySpecificCriteria : null,
    contractHasComplianceWithEmploymentStandardsInSpecificIndustry: data ? data.contractHasComplianceWithEmploymentStandardsInSpecificIndustry : null,
    contractHasSupportForeignerHealthAndLivingConditions: data ? data.contractHasSupportForeignerHealthAndLivingConditions : null,
    relatedActivityForeignAgencyName: data ? data.relatedActivityForeignAgencyName : null,
    relatedActivityForeignAgencyFee: data ? data.relatedActivityForeignAgencyFee : null,
    relatedActivityCollectionOrManagementAgencyName: data ? data.relatedActivityCollectionOrManagementAgencyName : null,
    relatedActivityCollectionAmount: data ? data.relatedActivityCollectionAmount : null,
    employeeInfoResidenceCardReceiveGovernmentCounterId: data.employeeInfoResidenceCardReceiveGovernmentCounterId
      ? toNumber(data.employeeInfoResidenceCardReceiveGovernmentCounterId)
      : null,
    employeeInfoRecentResidenceTax: data ? data.employeeInfoRecentResidenceTax : null,
    employeeInfoTwoResidenceDesiredPeriodId: data.employeeInfoTwoResidenceDesiredPeriodId
      ? toNumber(data.employeeInfoTwoResidenceDesiredPeriodId)
      : null,
    employeeInfoTwoResidenceChangeReason: data.employeeInfoTwoResidenceChangeReason,
    employeeInfoTwoRasImmiFullName: data.employeeInfoTwoRasImmiFullName,
    contractHourlySalary: data ? toNumber(data.contractHourlySalary) : null,
    employeeInfoMobileNumber: data ? data.employeeInfoMobileNumber : null,
    contractWeeklyWorkingHours: data ? data.contractWeeklyWorkingHours : null,
    contractSalaryPaymentMethod: data ? data.contractSalaryPaymentMethod : null,
    employeeInfoCareer: data ? data.employeeInfoCareer : "",
    contractMonthlyWorkingHours: data ? data.contractMonthlyWorkingHours : null,
    contractMonthlySalaryForJapanesePerson: isNumber(data?.contractMonthlySalaryForJapanesePerson)
      ? toNumber(data.contractMonthlySalaryForJapanesePerson)
      : null,
    workingHistories: data
      ? data.workingHistories.map((item) => ({
          jobName: item.jobName,
          workingStartYear: item.workingStartYear?.year() ?? null,
          workingStartMonth: item.workingStartMonth?.month() ?? null,
          workingEndYear: item.workingEndYear?.year() ?? null,
          workingEndMonth: item.workingEndMonth?.month() ?? null,
          isDraft,
        }))
      : [],
    isDraft,
  };
};
