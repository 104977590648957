import { Checkbox } from "@/components/elements/Checkbox";
import { useFormField } from "@/components/elements/FormField";
import { Stack } from "@mui/material";
import { ChangeEvent, FC, Key, useEffect, useState } from "react";

export type TCheckboxOption = {
  value: Key;
  label: string;
  subLabel?: string;
  disabled?: boolean;
};

type TCheckboxGroupProps = {
  options: TCheckboxOption[];
  nonControl?: boolean;
  value?: Key[];
  enableOrderNumber?: boolean;
  rowGap?: number;
  onChange?: (value: Key[]) => void;
  disabled?: boolean;
};

export const CheckboxGroup: FC<TCheckboxGroupProps> = ({
  options,
  nonControl = false,
  value,
  enableOrderNumber = false,
  rowGap = 1,
  onChange,
  disabled,
}) => {
  const [checkedValues, setCheckedValues] = useState<Key[]>([]);
  const { field, fieldState } = useFormField(nonControl);

  useEffect(() => {
    setCheckedValues(field?.value ?? value ?? []);
  }, [field?.value, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let newValues = [...checkedValues];
    if (checked) {
      newValues = [...newValues, value];
    } else {
      newValues = newValues.filter((v) => v !== value);
    }
    setCheckedValues(newValues);
    onChange?.(newValues);
    field?.onChange(newValues);
    field?.onBlur();
  };

  return (
    <Stack gap={rowGap}>
      {options.map(({ label, subLabel, value }, index) => {
        return (
          <Checkbox
            name={field?.name}
            key={index}
            error={!!fieldState?.error}
            label={label}
            prefixLabel={enableOrderNumber ? `${index + 1}.` : null}
            subLabel={subLabel}
            value={value}
            checked={checkedValues.includes(value.toString())}
            onChange={handleChange}
            nonControl
            disabled={disabled}
          />
        );
      })}
    </Stack>
  );
};
