import { loginApi } from "@/api/services/main/auth";
import { Button } from "@/components/elements/Button";
import { Checkbox } from "@/components/elements/Checkbox";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { loginSchema } from "@/features/auth/schema/loginSchema";
import { TLoginForm } from "@/features/auth/types/loginForm";
import { useCacheLocal } from "@/hooks/useCacheLocal";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { pick } from "@/utils/object";
import { getQueryFromURL } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TQuery = {
  email: string;
  companyCode: string;
};

export const LoginForm = () => {
  const cacheLocal = useCacheLocal("login-form");
  const navigate = useNavigate();

  const defaultValues = useMemo(() => {
    const query = getQueryFromURL<TQuery>();
    if (query && query.companyCode && query.email) return { companyCode: query.companyCode, email: query.email };

    if (cacheLocal.getJSON()) return pick(cacheLocal.getJSON(), "companyCode", "email");
  }, [cacheLocal]);

  const methods = useForm<TLoginForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });
  const { control } = methods;
  const [cached, setCached] = useState(!!cacheLocal.getJSON()?.companyCode || !!cacheLocal.getJSON()?.email);

  const onSubmit = async (data: TLoginForm) => {
    try {
      await loginApi(data);
      navigate(AppRoutes.home);
      if (cached) {
        cacheLocal.setJSON(pick(data, "companyCode", "email"));
      } else {
        cacheLocal.remove();
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const handleToggleCache = (_: unknown, checked: boolean) => {
    setCached(checked);
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={loginSchema}>
      <Stack gap={3} width={400}>
        <FormField control={control} name="companyCode">
          <Input size="lg" />
        </FormField>
        <FormField control={control} name="email">
          <Input type="email" size="lg" />
        </FormField>
        <FormField control={control} name="password">
          <Input type="password" size="lg" />
        </FormField>
        <Checkbox onChange={handleToggleCache} checked={cached} label="企業IDとメールアドレスを保存する" />
        <Button type="submit" variant="primary" fullWidth>
          ログイン
        </Button>
      </Stack>
    </Form>
  );
};
