export enum EEmploymentContractModificationType {
  MODIFY_CONTRACT = "modify_contract",
  TERMINATE_CONTRACT = "terminate_contract",
  RENEW_CONTRACT = "renew_contract",
}

export enum ESupportOutsourcingContractModificationType {
  RENEW_CONTRACT = "renew_contract",
  MODIFY_CONTRACT = "modify_contract",
  TERMINATE_CONTRACT = "terminate_contract",
}

export enum EModifyContractTerminateReason {
  EMPLOYMENT_CONTRACT_TERMINATION = "end_contract",
  CONVENIENT_FOR_COMPANY = "convenient_for_company",
  CONVENIENT_FOR_EMPLOYEE = "convenient_for_employee",
}

export enum EModifyContractTerminateReasonForCompany {
  EMPLOYEE_DEATH = "business_convenience",
  ILLNESS_INJURY = "illness_injury",
  MISSING_EMPLOYEE = "missing_employee",
  SERIOUS_DUTY_DISMISSAL = "serious_duty_dismissal",
  VOLUNTARY_RESIGNATION = "voluntary_resignation",
  OTHER = "other",
}

export enum EModifyContractTerminateReasonForEmployee {
  BUSINESS_CONVENIENCE = "business_convenience",
  STANDARD_NON_COMPLIANCE = "standard_non_compliance",
  COMPANY_OWNER_DEATH = "company_owner_death",
  OTHER = "other",
}

export enum ESupportSpecifiedSkill {
  NAME_OR_TITLE = "name_or_title",
  ADDRESS = "address",
  CORPORATE_NUMBER = "corporate_number",
  OTHER = "other",
  SUPPORT_MANAGER_NAME_AND_POSITION = "support_manager_name_and_position",
  NUMBER_OF_FOREIGN_WORKERS = "number_of_foreign_workers",
  NUMBER_OF_SUPPORT_STAFF = "number_of_support_staff",
  OFFICE_LOCATION = "office_location",
}

export enum ESupportRegistered {
  NAME_OR_TITLE = "name_or_title",
  ADDRESS = "address",
  CORPORATE_NUMBER = "corporate_number",
  REPRESENTATIVE_NAME = "representative_name",
  OTHER = "other",
  SUPPORT_MANAGER_NAME_AND_POSITION = "support_manager_name_and_position",
  NUMBER_OF_FOREIGN_WORKERS = "number_of_foreign_workers",
  NUMBER_OF_SUPPORT_STAFF = "number_of_support_staff",
  OFFICE_LOCATION = "office_location",
  CHANGE_10_TO_18 = "change_10_to_18",
}

export enum EAnyTimeContentOfSupport {
  ADVANCE_GUIDANCE = "advance_guidance",
  TRANSPORTATION = "transportation",
  HOUSING_AND_CONTRACT_SUPPORT = "housing_and_contract_support",
  LIFE_ORIENTATION = "life_orientation",
  JAPANESE_LEARNING = "japanese_learning",
  CONSULTATION_AND_COMPLAINTS = "consultation_and_complaints",
  CULTURAL_EXCHANGE_SUPPORT = "cultural_exchange_support",
  INVOLUNTARY_JOB_SUPPORT = "involuntary_job_support",
  INTERVIEWS_AND_REPORTING = "interviews_and_reporting",
  OTHER = "other",
  CHANGE_19_TO_28 = "change_19_to_28",
}

export enum ETerminateContractSubcategoryReason {
  CONTRACT_END = "contract_end",
  BUSINESS_REASONS = "business_reasons",
  CONTRACT_VIOLATION = "contract_violation",
  REGISTRATION_REVOCATION = "registration_revocation",
  OTHER = "other",
}

export enum EAnyTimeDocumentType {
  EMPLOYMENT_CONTRACT_MODIFICATION = "employment_contract_modification",
  SUPPORT_PLAN_MODIFICATION = "support_plan_modification",
  SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION = "support_outsourcing_contract_modification",
}

export const AnyTimeDocumentTypeOptions = [
  {
    value: EAnyTimeDocumentType.EMPLOYMENT_CONTRACT_MODIFICATION,
    label: "雇用契約の変更に係る届出",
  },
  {
    value: EAnyTimeDocumentType.SUPPORT_PLAN_MODIFICATION,
    label: "支援計画変更に係る届出",
  },
  {
    value: EAnyTimeDocumentType.SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION,
    label: "支援委託契約に係る届出",
  },
];
