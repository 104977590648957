import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { useLocation, useParams } from "react-router-dom";

type UseCheckRenewVisaHistoryPageReturn = {
  isHistoryPage: boolean;
};

export const useCheckRenewVisaHistoryPage = (): UseCheckRenewVisaHistoryPageReturn => {
  const { renewVisaDocumentId } = useParams();
  const location = useLocation();

  const isHistoryPage = location.pathname.startsWith(getDynamicRoute(AppRoutes.renewVisaInfoHistory, { renewVisaDocumentId }));

  return { isHistoryPage };
};
