import { background, divider, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, CircularProgress, Stack, StackProps, Typography, styled } from "@mui/material";
import { ReactNode, useMemo } from "react";

export type TInnerBoxProps = {
  title?: ReactNode;
  bordered?: boolean;
  required?: boolean;
  loading?: boolean;
  rightContent?: ReactNode;
} & Omit<StackProps, "title">;

export const InnerBox: FCC<TInnerBoxProps> = ({
  title,
  children,
  bordered = true,
  padding = "16px",
  required = false,
  loading,
  rightContent,
  ...rest
}) => {
  const renderTitle = useMemo(() => {
    if (!title) return null;
    if (typeof title === "string") {
      return (
        <Typography variant="head20Bold" color={text.primary}>
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  return (
    <InnerBoxWrap border={bordered ? "1px solid" : "none"} padding={padding} {...rest}>
      {(renderTitle || required) && (
        <Stack direction="row" gap={1} alignItems="center">
          {renderTitle}
          {required && <RequiredTag variant="cap12">必須</RequiredTag>}
        </Stack>
      )}
      {rightContent && <RightContentWrap>{rightContent}</RightContentWrap>}
      <Box position="relative">
        {loading ? (
          <CenterBox>
            <CircularProgress sx={{ color: text.tertiary }} size="24px" />
          </CenterBox>
        ) : (
          children
        )}
      </Box>
    </InnerBoxWrap>
  );
};

const InnerBoxWrap = styled(Stack)`
  position: relative;
  border-color: ${divider.middle};
  border-radius: ${rounded.sm};
  gap: ${spacing.sm};
  background: ${background.white};
`;

const RightContentWrap = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
`;

const RequiredTag = styled(Typography)`
  height: 20px;
  width: 40px;
  text-align: center;
  border: 1px solid ${text.error};
  color: ${text.error};
  border-radius: ${rounded.xs};
`;

const CenterBox = styled(Box)`
  background-color: ${background.white};
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
