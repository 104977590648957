import {
  TCreateEmploymentContractModificationDocumentReqDto,
  TCreateEmploymentContractModificationDocumentResDto,
} from "@/api/services/main/any-time/employment-contract/dtos/create-employment-contract-modification-document.dto";
import { TGetEmploymentContractModificationDocumentResDto } from "@/api/services/main/any-time/employment-contract/dtos/get-employment-contract-modification-document.dto";
import {
  TUpdateEmploymentContractModificationDocumentReqDto,
  TUpdateEmploymentContractModificationDocumentResDto,
} from "@/api/services/main/any-time/employment-contract/dtos/update-employment-contract-modification-document.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createAnytimeEmploymentContractApi = (payload: TCreateEmploymentContractModificationDocumentReqDto) => {
  return clientApi.post<TCreateEmploymentContractModificationDocumentResDto>(
    "/any-time-documents/employment-contract-modification-documents",
    payload,
  );
};

export const updateAnytimeEmploymentContractApi = (stepId: number | string, payload: TUpdateEmploymentContractModificationDocumentReqDto) => {
  return clientApi.put<TUpdateEmploymentContractModificationDocumentResDto>(
    `/any-time-documents/employment-contract-modification-documents/steps/${stepId}`,
    payload,
  );
};

export const fetchAnytimeEmploymentContractApi = async (stepId: string | number) => {
  const rs = await clientApi.get<TGetEmploymentContractModificationDocumentResDto>(
    `/any-time-documents/employment-contract-modification-documents/steps/${stepId}`,
  );
  return rs.data;
};

export const useAnytimeEmploymentContract = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_EMPLOYMENT_CONTRACT, id],
    queryFn: () => (id ? fetchAnytimeEmploymentContractApi(id) : undefined),
    enabled: Boolean(id),
  });
};
