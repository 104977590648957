import { requestResetPasswordApi } from "@/api/services/main/auth";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { recoverSchema } from "@/features/auth/schema/recoverSchema";
import { TRecoverForm } from "@/features/auth/types/recoverForm";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

export const RecoverForm = () => {
  const methods = useForm<TRecoverForm>({
    mode: "onChange",
    resolver: yupResolver(recoverSchema),
  });
  const { control } = methods;

  const onSubmit = async (data: TRecoverForm) => {
    try {
      const rs = await requestResetPasswordApi(data);
      toast.success(rs.message ?? "パスワードをリセットするためのURLリンクがメールに送付されました。メールをチェックしてください");
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={recoverSchema}>
      <Stack gap={3} width={400}>
        <FormField control={control} name="companyCode">
          <Input size="lg" />
        </FormField>
        <FormField control={control} name="email">
          <Input size="lg" />
        </FormField>
        <Button type="submit" variant="primary" fullWidth>
          メールを送信
        </Button>
      </Stack>
    </Form>
  );
};
