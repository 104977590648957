import { TGetCompanySpecificIndustryListResDto } from "@/api/services/global/master/dtos/get-master-company-specific-industry-list.dto";
import { TGetLanguageTestListResDto } from "@/api/services/global/master/dtos/get-master-language-test-list.dto";
import { TGetLivingRelationshipListResDto } from "@/api/services/global/master/dtos/get-master-living-relationship-list.dto";
import { TGetLocalGovernmentCounterListResDto } from "@/api/services/global/master/dtos/get-master-local-government-counter-list.dto";
import { TGetMainIndustryListResDto } from "@/api/services/global/master/dtos/get-master-main-industry-list.dto";
import { TGetMainOccupationListResDto } from "@/api/services/global/master/dtos/get-master-main-occupation-list.dto";
import { TGetMajorListResDto } from "@/api/services/global/master/dtos/get-master-major-list.dto";
import { TGetRegionListResDto, TGetNationalityListResDto } from "@/api/services/global/master/dtos/get-master-region-list.dto";
import { TGetResidenceStatusListResDto } from "@/api/services/global/master/dtos/get-master-residence-status-list.dto";
import { TGetSkillTestListResDto } from "@/api/services/global/master/dtos/get-master-skill-test-list.dto";
import { TGetSpecificIndustryListResDto } from "@/api/services/global/master/dtos/get-master-specific-industry-list.dto";
import { CACHED_TIME } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { globalApi } from "@/utils/api";
import { toNumber } from "@/utils/number";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

//region
export const fetchRegionListApi = async (): Promise<TGetRegionListResDto> => {
  const rs = await globalApi.get<TGetRegionListResDto>(`/master/regions`);
  return rs.data;
};

export const useGetRegionList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_REGION],
    queryFn: () => fetchRegionListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const nationalityOptions = useMemo(
    () =>
      data
        ? data.data
            .flatMap((item) => item.nationalities)
            .filter((item) => !!item)
            .map((item) => ({ label: item.nationalityName, value: item.id, hidden: item.isHidden }))
        : [],
    [data],
  );

  return { data: data?.data ?? [], nationalityOptions, ...rest };
};

//nationalities
export const fetchNationalityListApi = async (): Promise<TGetNationalityListResDto> => {
  const rs = await globalApi.get<TGetNationalityListResDto>(`/master/regions/nationalities`);
  return rs.data;
};

export const useGetNationalityList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_REGION],
    queryFn: () => fetchNationalityListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  return { data: data?.data ?? [], ...rest };
};

//job-types
export const fetchMajorListApi = async (): Promise<TGetMajorListResDto> => {
  const rs = await globalApi.get<TGetMajorListResDto>(`/master/majors`);
  return rs.data;
};

export const useGetMajorList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR],
    queryFn: () => fetchMajorListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const majorOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.majorName, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  const getJobTypeListOptionByMajor = useCallback(
    (majorId: number | string) => {
      const foundMajor = data ? data.data.find((item) => toNumber(item.id) === toNumber(majorId)) : undefined;
      if (!foundMajor) return [];
      const options = foundMajor.jobTypes.map((item) => ({ label: item.jobName, value: item.id, hidden: item.isHidden }));
      return options;
    },
    [data],
  );

  return { data: data?.data ?? [], majorOptions, getJobTypeListOptionByMajor, ...rest };
};

//residence-periods
export const fetchResidenceStatusListApi = async (): Promise<TGetResidenceStatusListResDto> => {
  const rs = await globalApi.get<TGetResidenceStatusListResDto>(`/master/residence-statuses`);
  return rs.data;
};

export const useGetResidenceStatusList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS],
    queryFn: () => fetchResidenceStatusListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const residenceStatusOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.residenceStatus, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], residenceStatusOptions, ...rest };
};

//regular-renew-visa-specified-industry
export const fetchSpecificIndustryListApi = async (): Promise<TGetSpecificIndustryListResDto> => {
  const rs = await globalApi.get<TGetSpecificIndustryListResDto>(`/master/specific-industries`);
  return rs.data;
};

export const useGetSpecificIndustryList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY],
    queryFn: () => fetchSpecificIndustryListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  return { data: data?.data ?? [], ...rest };
};

//living-relationship
export const fetchLivingRelationshipListApi = async (): Promise<TGetLivingRelationshipListResDto> => {
  const rs = await globalApi.get<TGetLivingRelationshipListResDto>("/master/living-relationships");
  return rs.data;
};

export const useGetLivingRelationshipList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_LIVING_RELATIONSHIP],
    queryFn: () => fetchLivingRelationshipListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const livingRelationshipOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.relationship, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], livingRelationshipOptions, ...rest };
};

//main-occupation
export const fetchMainOccupationListApi = async (): Promise<TGetMainOccupationListResDto> => {
  const rs = await globalApi.get<TGetMainOccupationListResDto>("/master/main-occupations");
  return rs.data;
};

export const useGetMainOccupationList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_OCCUPATION],
    queryFn: () => fetchMainOccupationListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const contractMainOccupationOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.mainOccupation, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], contractMainOccupationOptions, ...rest };
};

//main-industry
export const fetchMainIndustryListApi = async (): Promise<TGetMainIndustryListResDto> => {
  const rs = await globalApi.get<TGetMainIndustryListResDto>("/master/main-industries");
  return rs.data;
};

export const useGetMainIndustryList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_INDUSTRY],
    queryFn: () => fetchMainIndustryListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const mainIndustryOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.mainIndustry, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  const mainIndustryOtherIds = useMemo(
    () => (data ? data.data.filter((item) => item.mainIndustry.includes("その他")).map((item) => Number(item.id)) : []),
    [data],
  );

  return { data: data?.data ?? [], mainIndustryOptions, mainIndustryOtherIds, ...rest };
};

//local-government-counter
export const fetchLocalGovernmentCounterListApi = async (): Promise<TGetLocalGovernmentCounterListResDto> => {
  const rs = await globalApi.get<TGetLocalGovernmentCounterListResDto>("/master/local-government-counters");
  return rs.data;
};

export const useGetLocalGovernmentCounterList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_LOCAL_GOVERNMENT_COUNTER],
    queryFn: () => fetchLocalGovernmentCounterListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const localGovernmentCounterOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.officeName, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  const localGovernmentCounterByProvinceOptions = useCallback(
    (province: string) => {
      if (data) {
        return data.data
          .filter((item) => item.targetProvinces.some((provinceItem) => provinceItem.prefectureName === province))
          .map((item) => ({
            label: item.officeName,
            value: item.id,
            hidden: item.isHidden,
          }));
      } else {
        return [];
      }
    },
    [data],
  );

  return { data: data?.data ?? [], localGovernmentCounterOptions, localGovernmentCounterByProvinceOptions, ...rest };
};

//language-test
export const fetchLanguageTestListApi = async (): Promise<TGetLanguageTestListResDto> => {
  const rs = await globalApi.get<TGetLanguageTestListResDto>("/master/language-tests");
  return rs.data;
};

export const useGetLanguageTestList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_LANGUAGE_TEST],
    queryFn: () => fetchLanguageTestListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const languageTestOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.languageTestName, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], languageTestOptions, ...rest };
};

//skill-test
export const fetchSkillTestListApi = async (): Promise<TGetSkillTestListResDto> => {
  const rs = await globalApi.get<TGetSkillTestListResDto>("/master/skill-tests");
  return rs.data;
};

export const useGetSkillTestList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_SKILL_TEST],
    queryFn: () => fetchSkillTestListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const skillTestOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.skillTestName, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], ...rest, skillTestOptions };
};

//company-specific-industry
export const fetchCompanySpecificIndustryListApi = async (): Promise<TGetCompanySpecificIndustryListResDto> => {
  const rs = await globalApi.get<TGetCompanySpecificIndustryListResDto>("/master/company-specific-industries");
  return rs.data;
};

export const useGetCompanySpecificIndustryList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY],
    queryFn: () => fetchCompanySpecificIndustryListApi(),
    staleTime: CACHED_TIME,
    gcTime: CACHED_TIME,
  });

  const companySpecificIndustryOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.companySpecificIndustry, value: toNumber(item.id), hidden: item.isHidden })) : []),
    [data],
  );

  const getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry = useCallback(
    (companySpecificIndustryId: number | string) => {
      const foundCompanySpecificIndustry = data ? data.data.find((item) => toNumber(item.id) === toNumber(companySpecificIndustryId)) : undefined;
      if (!foundCompanySpecificIndustry) return [];
      const options =
        foundCompanySpecificIndustry?.companySpecificBusinessClassifications?.map((item) => ({
          label: item.companySpecificBusinessClassificationName,
          value: item.id,
          hidden: item.isHidden,
        })) || [];
      return options;
    },
    [data],
  );

  return {
    data: data?.data ?? [],
    companySpecificIndustryOptions,
    getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry,
    ...rest,
  };
};
