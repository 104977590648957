import { getDynamicRoute } from "@/utils/url";
import { AppRoutes } from "@/routes/config";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

const EmployeeDetail = () => {
  const { id } = useParams();

  return <Navigate to={getDynamicRoute(AppRoutes.employeeEdit, { id })} />;
};

export default EmployeeDetail;
