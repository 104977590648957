import { useGetProvinceAndMunicipalityList } from "@/features/renew-visa/hook/useGetProvinceAndMunicipalityList";

type UseGetMunicipalityCodeReturn = {
  getMunicipalityCode: (municipalityName: string) => string;
};

export const useGetMunicipalityCode = (): UseGetMunicipalityCodeReturn => {
  const { municipalities } = useGetProvinceAndMunicipalityList();

  const getActualMunicipalityName = (municipalityName: string): string => {
    const cuttingChars = ["市", "区", "町", "村"];

    const positions = cuttingChars.map((char) => municipalityName.indexOf(char)).filter((pos) => pos !== -1);

    if (positions.length === 0) return municipalityName;

    const cityPos = municipalityName.indexOf("市");
    const districtPos = municipalityName.indexOf("区");

    if (cityPos !== -1 && districtPos !== -1 && cityPos < districtPos) {
      return municipalityName.slice(0, districtPos + 1);
    }

    return municipalityName.slice(0, Math.min(...positions) + 1);
  };

  const getMunicipalityCode = (municipalityName: string) =>
    municipalities?.find((item) => item.municipalityName === getActualMunicipalityName(municipalityName))?.municipalityCode?.toString() ?? "";

  return { getMunicipalityCode };
};
