import { TRegularDocumentStep } from "@/api/entities/regularDocumentStep.entity";
import { finishSubmitDocumentApi } from "@/api/services/main/regular-document";
import { TRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { StepCard } from "@/components/elements/StepCard";
import { TypoLink } from "@/components/elements/TypoLink";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useDialog } from "@/hooks/useDialog";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { showError } from "@/utils/error";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";

type TStepProps = {
  stepData: TRegularDocumentStep;
  readonly?: boolean;
  document: TRegularDocumentDto;
};

export const LifeSupportSubmitStep: FC<TStepProps> = ({ stepData, readonly, document }) => {
  const { id, masterDocumentStep } = stepData;
  const { stepOrder, stepName } = masterDocumentStep;
  const confirmFinish = useDialog();
  const [completeOtherSteps, setCompleteOtherSteps] = useState<boolean>(false);
  const queryClient = useQueryClient();
  useEffect(() => {
    const createDocumentStep = document.steps.find((step) => step.masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT);
    const hasCompleteConvertRequest = !!createDocumentStep?.convertRequests?.find((req) => req.status === DOCUMENT_STATUS.COMPLETE);
    setCompleteOtherSteps(hasCompleteConvertRequest);
  }, [document]);

  if (readonly) return null;

  const handleFinishSubmitDocument = async () => {
    try {
      const agree = await confirmFinish.show();
      if (!agree) return;
      window.open("https://www.accord-immi.moj.go.jp/accord-u/profile/userLogin");
      await finishSubmitDocumentApi(Number(id));
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <StepCard
      title={`ステップ${stepOrder}`}
      subTitle={stepName}
      buttons={
        <Button variant="primary" size="sm" disabled={!completeOtherSteps} onClick={handleFinishSubmitDocument}>
          提出
        </Button>
      }
    >
      <Stack spacing={1}>
        <Typography variant="body14Bold">
          電子届出システムで提出後、irohanaで届出完了ボタンを押すとステップが完了となります。ステップ完了すると以降編集ができなくなります。
        </Typography>
        <Typography variant="body14">電子申請システムで届出や添付書類を更新してください</Typography>
        <TypoLink to="https://www.iro-hana.com/fff5d48302ae807597b7fb2e82f952af?pvs=4" target="_blank" variant="link14Semi">
          申請フローの詳細はこちら
        </TypoLink>
      </Stack>
      <ConfirmSaveModal open={confirmFinish.open} onOk={confirmFinish.confirm} onClose={confirmFinish.cancel}>
        <Typography>
          提出形式に変換したファイルを用いて届出の提出に移ります。出入国在留管理庁の電子届出システムが開かれます、よろしいですか？
        </Typography>
      </ConfirmSaveModal>
    </StepCard>
  );
};
