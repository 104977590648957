import { TGetConversationByUserOutputDto } from "@/api/services/main/conversation/dtos/get-conversation-by-user.dto";
import { TRoom } from "@/features/chat/types/room";

export const formatConversationDto = (data: TGetConversationByUserOutputDto): TRoom => {
  const room = {} as TRoom;
  room.id = data.id;
  room.companyId = data.companyId;
  room.createdAt = data.createdAt;
  room.creator = data.creator;
  room.company = data.company;
  room.messages = null;
  return room;
};
