import { createCompanyBranchApi, deleteCompanyBranchApi, updateCompanyBranchInfoApi } from "@/api/services/main/company";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { ConfirmDeleteBranch } from "@/features/company/components/ConfirmDeleteBranch";
import { CompanyBranchSchema } from "@/features/company/schema/companyBranchSchema";
import { TCompanyBranchForm } from "@/features/company/types/companyBranchForm";
import { convertCompanyBranchFormDataToReqDto } from "@/features/company/utils/convertFormDataToReqDto";
import { useDialog } from "@/hooks/useDialog";
import { useLocationInfo } from "@/hooks/useLocationInfo";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { FCC } from "@/types/common";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TCompanyBranchFormProps = {
  isHeadQuarter?: boolean;
  previousValues?: Partial<TCompanyBranchForm> | undefined;
};

const initForm: Partial<TCompanyBranchForm> = {
  branchName: "",
  zipCode: "",
  province: "",
  municipality: "",
  address: "",
  telNumber: "",
};

export const CompanyBranchForm: FCC<TCompanyBranchFormProps> = ({ isHeadQuarter, previousValues }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const confirmDelete = useDialog();

  const methods = useForm<TCompanyBranchForm>({
    defaultValues: { ...initForm, ...previousValues },
    resolver: yupResolver(CompanyBranchSchema),
    mode: "onBlur",
  });
  const { control } = methods;

  useLocationInfo({ methods, names: { zipCode: "zipCode", province: "province", municipality: "municipality", address: "address" } });

  const onSubmit = async (data: TCompanyBranchForm) => {
    try {
      const payload = convertCompanyBranchFormDataToReqDto(data);
      if (!previousValues) {
        const rs = await createCompanyBranchApi(payload);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_BRANCH_DETAIL, rs.data.id] });
      } else {
        if (!id) return;
        const rs = await updateCompanyBranchInfoApi(id, payload);
        navigate(AppRoutes.companyBranch);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_BRANCH_DETAIL, rs.data.id] });
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_BRANCH_LIST] });
      navigate(AppRoutes.companyBranch);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const handleDeleteCompanyBranch = async () => {
    if (!id) return;
    try {
      const rs = await deleteCompanyBranchApi(id);
      navigate(AppRoutes.companyBranch);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.COMPANY.FETCH_BRANCH_LIST],
      });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <Form methods={methods} onSubmit={onSubmit} schema={CompanyBranchSchema}>
        <Stack gap={2}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="head20Semi" fontWeight={100} color={text.primary}>
              {previousValues ? "事業所情報の編集" : "新規事業所登録"}
            </Typography>
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Button type="submit" variant="primary" size="sm">
                保存
              </Button>
              {previousValues && (
                <Button
                  variant="primary"
                  size="sm"
                  disabled={isHeadQuarter}
                  onClick={() => {
                    confirmDelete.show();
                  }}
                >
                  削除
                </Button>
              )}
            </Stack>
          </Stack>
          <InnerBox>
            <Stack gap={2}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormField control={control} name="branchName" required>
                    <Input readonly={isHeadQuarter} />
                  </FormField>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="zipCode">
                    <Input readonly={isHeadQuarter} />
                  </FormField>
                </Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="province">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="municipality">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="address">
                    <Input readonly={isHeadQuarter} />
                  </FormField>
                </Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="telNumber">
                    <Input readonly={isHeadQuarter} />
                  </FormField>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="weeklyWorkingHours">
                    <Input />
                  </FormField>
                </Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="monthWorkingHours">
                    <Input />
                  </FormField>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="businessInsuranceNumber">
                    <Input readonly={isHeadQuarter} />
                  </FormField>
                </Grid>
                <Grid item xs={4}>
                  <FormField control={control} name="employmentInsuranceNumber">
                    <Input readonly={isHeadQuarter} />
                  </FormField>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Stack>
          </InnerBox>
        </Stack>
      </Form>
      <ConfirmDeleteBranch open={confirmDelete.open} onClose={confirmDelete.cancel} onOk={handleDeleteCompanyBranch} />
    </>
  );
};
