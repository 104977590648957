import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";

export const checkInValidFormatFile = (jsonData: Record<string, string>[]) => {
  return jsonData.some((object) => {
    if (!(PLAIN_TEXT.numberOrder in object)) return true;
    if (!(PLAIN_TEXT.employeeFullName in object)) return true;
    if (!(PLAIN_TEXT.employeeBirthday in object)) return true;
    if (!(PLAIN_TEXT.isChangeWorkingLocation in object)) return true;
    if (!(PLAIN_TEXT.isChangeWorkingDetail in object)) return true;
    if (!(PLAIN_TEXT.isChangeDispatchPlace in object)) return true;
    if (!(PLAIN_TEXT.month in object)) return true;
    if (!(PLAIN_TEXT.workingDay in object)) return true;
    if (!(PLAIN_TEXT.totalAmountPaid in object)) return true;
    if (!(PLAIN_TEXT.netAmountPaid in object)) return true;
    if (!(PLAIN_TEXT.legalDeductionAmount in object)) return true;
    if (!(PLAIN_TEXT.hasComparisonEmployee in object)) return true;
    return false;
  });
};
