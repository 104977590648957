import { refreshTokenApi } from "@/api/services/main/auth";
import { getCustomSentryExceptionError, handleApiErrorWithCode } from "@/utils/error";
import { removeToken, verifyToken } from "@/utils/token";
import { getMatchRoutes } from "@/utils/url";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

if (!process.env.REACT_APP_API_HOST) {
  throw new Error("please provide REACT_APP_API_HOST in env");
}

export const baseURL = `${process.env.REACT_APP_API_HOST}`;

export const globalBaseURL = `${process.env.REACT_APP_API_HOST}/global`;

export const zipCodeURL = `${process.env.REACT_APP_API_ZIP_CODE}`;

export const globalApi = axios.create({
  baseURL: globalBaseURL,
});

export const zipCodeApi = axios.create({
  baseURL: zipCodeURL,
});

export const clientApi = axios.create({
  baseURL,
});

// If successful (do nothing)
export const onSuccess = (response: AxiosResponse) => {
  Sentry.endSession();
  return response;
};

let isRefreshing = false;
let refreshQueue: ((config: AxiosRequestConfig) => void)[] = [];
//  in case of error
export const onError = async (error: AxiosError) => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401 && error.config) {
      Cookies.remove("token");
      if (!isRefreshing) {
        const errorHeader = !error.config.headers.Authorization && Cookies.get("token");

        if (!errorHeader) {
          try {
            const refreshToken = Cookies.get("refreshToken");
            const email = Cookies.get("email");
            const companyCode = Cookies.get("companyCode");
            if (!email || !refreshToken || !companyCode) {
              logout();
              return;
            }
            isRefreshing = true;
            // Call an API to refresh the token here and store the new token
            await refreshTokenApi({
              refreshToken,
              email,
              companyCode,
            });
            isRefreshing = false;
          } catch (error) {
            logout();
          }
        }

        const newToken = Cookies.get("token");

        // Retry all the requests in the queue
        refreshQueue.forEach((request) =>
          request({
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          }),
        );
        refreshQueue = [];
        return axios({
          ...error.config,
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        });
      }

      // Return a promise to wait for the request to be retried after the token is updated
      return new Promise((resolve) => {
        refreshQueue.push((config: AxiosRequestConfig) => {
          resolve(axios(config));
        });
      });
    }
  }
  handleApiErrorWithCode(error);
  if (error.response?.status && error.response.status >= 500 && error.response.status !== 503) {
    Sentry.captureException(getCustomSentryExceptionError(error));
  }
  Sentry.endSession();
  return Promise.reject(error.response?.data);
};

clientApi.interceptors.request.use(
  (config) => {
    const matchRoutes = getMatchRoutes();
    config.headers["X-RouteName"] = matchRoutes.at(-1)?.id;

    Sentry.startSession();
    return config;
  },
  (error) => {
    if (error.response?.status && error.response.status >= 500 && error.response.status !== 503) {
      Sentry.captureException(getCustomSentryExceptionError(error));
    }
    Sentry.endSession();
    return Promise.reject(error);
  },
);

globalApi.interceptors.request.use(
  (config) => {
    Sentry.startSession();
    return config;
  },
  (error) => {
    if (error.response?.status && error.response.status >= 500 && error.response.status !== 503) {
      Sentry.captureException(getCustomSentryExceptionError(error));
    }
    Sentry.endSession();
    return Promise.reject(error);
  },
);

clientApi.interceptors.response.use(onSuccess, onError);
globalApi.interceptors.response.use(onSuccess, onError);

export const setAxiosAuth = () => {
  const token = Cookies.get("token");
  clientApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  globalApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAxiosAuth = () => {
  clientApi.defaults.headers.common.Authorization = "";
  globalApi.defaults.headers.common.Authorization = "";
};

export const isAuth = () => {
  if (typeof window !== "undefined") {
    const token = Cookies.get("token");
    const isAuthenticated = !!token && verifyToken(token);
    return isAuthenticated;
  }
  return false;
};

export const logout = () => {
  removeToken();
  Cookies.remove("userId");
  Cookies.remove("companyId");
  Cookies.remove("email");
  Cookies.remove("companyCode");
};

export const lazyApi = <T = void>(fn: () => Promise<T>): [AbortController, () => Promise<T>] => {
  const controller = new AbortController();

  return [controller, fn];
};
