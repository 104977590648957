import { TCreateOrUpdateRenewVisaUploadFileDocumentReqDto } from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-upload-file-document.dto";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";

export const convertUploadApplicationFormDataToReqDto = (
  data: TRenewVisaUploadFileForm,
  filterConditionArrayKeys: (keyof TRenewVisaUploadFileForm)[],
  isDraft: boolean,
): TCreateOrUpdateRenewVisaUploadFileDocumentReqDto => {
  return {
    annualSalaryReportCopyFilePath:
      data && filterConditionArrayKeys.includes("annualSalaryReportCopyFilePath") ? data.annualSalaryReportCopyFilePath ?? null : null,
    applicantIndividualResidentTaxCertFilePath:
      data && filterConditionArrayKeys.includes("applicantIndividualResidentTaxCertFilePath")
        ? data.applicantIndividualResidentTaxCertFilePath ?? null
        : null,
    applicantResidentTaxPaymentCertFilePath:
      data && filterConditionArrayKeys.includes("applicantResidentTaxPaymentCertFilePath")
        ? data.applicantResidentTaxPaymentCertFilePath ?? null
        : null,
    applicantWithholdingSlipCopyFilePath:
      data && filterConditionArrayKeys.includes("applicantWithholdingSlipCopyFilePath") ? data.applicantWithholdingSlipCopyFilePath ?? null : null,
    careWorkplaceOverviewFilePath:
      data && filterConditionArrayKeys.includes("careWorkplaceOverviewFilePath") ? data.careWorkplaceOverviewFilePath ?? null : null,
    certifiedCompanyProofFilePath:
      data && filterConditionArrayKeys.includes("certifiedCompanyProofFilePath") ? data.certifiedCompanyProofFilePath ?? null : null,
    corporateResidentTaxPaymentCertFilePath:
      data && filterConditionArrayKeys.includes("corporateResidentTaxPaymentCertFilePath")
        ? data.corporateResidentTaxPaymentCertFilePath ?? null
        : null,
    specifiedSkillAutomobileMaintenanceCertFilePath:
      data && filterConditionArrayKeys.includes("specifiedSkillAutomobileMaintenanceCertFilePath")
        ? data.specifiedSkillAutomobileMaintenanceCertFilePath ?? null
        : null,
    specifiedSkillAutomobileMaintenanceIssuranceFilePath:
      data && filterConditionArrayKeys.includes("specifiedSkillAutomobileMaintenanceIssuranceFilePath")
        ? data.specifiedSkillAutomobileMaintenanceIssuranceFilePath ?? null
        : null,
    councilMembershipCertFilePath:
      data && filterConditionArrayKeys.includes("councilMembershipCertFilePath") ? data.councilMembershipCertFilePath ?? null : null,
    hotelBusinessLicenseFilePath:
      data && filterConditionArrayKeys.includes("hotelBusinessLicenseFilePath") ? data.hotelBusinessLicenseFilePath ?? null : null,
    documentOmissionPledgeFilePath:
      data && filterConditionArrayKeys.includes("documentOmissionPledgeFilePath") ? data.documentOmissionPledgeFilePath ?? null : null,
    employmentConditionsCopyFilePaths:
      data && filterConditionArrayKeys.includes("employmentConditionsCopyFilePaths")
        ? data.employmentConditionsCopyFilePaths && data.employmentConditionsCopyFilePaths.length > 0
          ? data.employmentConditionsCopyFilePaths
          : null
        : null,
    isExistEmploymentContractCopyFilePath: data.isExistEmploymentContractCopyFilePath || false,
    isExistWagePaymentFilePath: data.isExistWagePaymentFilePath || false,
    isExistEmploymentConditionsCopyFilePaths: data.isExistEmploymentConditionsCopyFilePaths || false,
    employmentContractCopyFilePath:
      data && filterConditionArrayKeys.includes("employmentContractCopyFilePath") ? data.employmentContractCopyFilePath ?? null : null,
    executiveResidentRecordCopyFilePath:
      data && filterConditionArrayKeys.includes("executiveResidentRecordCopyFilePath") ? data.executiveResidentRecordCopyFilePath ?? null : null,
    foreignWorkerCompensationStatementFilePaths:
      data && filterConditionArrayKeys.includes("foreignWorkerCompensationStatementFilePaths")
        ? data.foreignWorkerCompensationStatementFilePaths && data.foreignWorkerCompensationStatementFilePaths.length > 0
          ? data.foreignWorkerCompensationStatementFilePaths
          : null
        : null,
    healthInsuranceWelfarePensionCertFilePath:
      data && filterConditionArrayKeys.includes("healthInsuranceWelfarePensionCertFilePath")
        ? data.healthInsuranceWelfarePensionCertFilePath ?? null
        : null,
    immigrationElectronicSystemPledgeFilePath:
      data && filterConditionArrayKeys.includes("immigrationElectronicSystemPledgeFilePath")
        ? data.immigrationElectronicSystemPledgeFilePath ?? null
        : null,
    individualBusinessOwnerResidentCertFilePath:
      data && filterConditionArrayKeys.includes("individualBusinessOwnerResidentCertFilePath")
        ? data.individualBusinessOwnerResidentCertFilePath ?? null
        : null,
    individualBusinessOwnerTaxOfficePaymentCertNo3FilePath:
      data && filterConditionArrayKeys.includes("individualBusinessOwnerTaxOfficePaymentCertNo3FilePath")
        ? data.individualBusinessOwnerTaxOfficePaymentCertNo3FilePath ?? null
        : null,
    individualHealthInsurancePaymentCertFilePath:
      data && filterConditionArrayKeys.includes("individualHealthInsurancePaymentCertFilePath")
        ? data.individualHealthInsurancePaymentCertFilePath ?? null
        : null,
    individualPensionReceiptCopyFilePath:
      data && filterConditionArrayKeys.includes("individualPensionReceiptCopyFilePath") ? data.individualPensionReceiptCopyFilePath ?? null : null,
    individualResidentTaxCertFilePath:
      data && filterConditionArrayKeys.includes("individualResidentTaxCertFilePath") ? data.individualResidentTaxCertFilePath ?? null : null,
    innovationCompanyProofFilePath:
      data && filterConditionArrayKeys.includes("innovationCompanyProofFilePath") ? data.innovationCompanyProofFilePath ?? null : null,
    laborInsuranceDelegationFilePath:
      data && filterConditionArrayKeys.includes("laborInsuranceDelegationFilePath") ? data.laborInsuranceDelegationFilePath ?? null : null,
    laborInsuranceNoDelegationFilePath:
      data && filterConditionArrayKeys.includes("laborInsuranceNoDelegationFilePath") ? data.laborInsuranceNoDelegationFilePath ?? null : null,
    nonHealthInsuranceWelfarePensionCertFilePath:
      data && filterConditionArrayKeys.includes("nonHealthInsuranceWelfarePensionCertFilePath")
        ? data.nonHealthInsuranceWelfarePensionCertFilePath ?? null
        : null,
    quarterlyReportCopyFilePath:
      data && filterConditionArrayKeys.includes("quarterlyReportCopyFilePath") ? data.quarterlyReportCopyFilePath ?? null : null,
    registrationCertFilePath: data && filterConditionArrayKeys.includes("registrationCertFilePath") ? data.registrationCertFilePath ?? null : null,
    socialInsurancePaymentStatusCertFilePath:
      data && filterConditionArrayKeys.includes("socialInsurancePaymentStatusCertFilePath")
        ? data.socialInsurancePaymentStatusCertFilePath ?? null
        : null,
    specificSkillsInstitutionOfficerAffidavitFilePath:
      data && filterConditionArrayKeys.includes("specificSkillsInstitutionOfficerAffidavitFilePath")
        ? data.specificSkillsInstitutionOfficerAffidavitFilePath ?? null
        : null,
    specificSkillsInstitutionOverviewDocumentFilePath:
      data && filterConditionArrayKeys.includes("specificSkillsInstitutionOverviewDocumentFilePath")
        ? data.specificSkillsInstitutionOverviewDocumentFilePath ?? null
        : null,
    taxOfficePaymentCertNo3FilePath:
      data && filterConditionArrayKeys.includes("taxOfficePaymentCertNo3FilePath") ? data.taxOfficePaymentCertNo3FilePath ?? null : null,
    wagePaymentFilePath: data && filterConditionArrayKeys.includes("wagePaymentFilePath") ? data.wagePaymentFilePath ?? null : null,
    applicantHealthInsuranceCardCopyFilePath:
      data && filterConditionArrayKeys.includes("applicantHealthInsuranceCardCopyFilePath")
        ? data.applicantHealthInsuranceCardCopyFilePath ?? null
        : null,
    applicantHealthInsurancePaymentCertFilePath:
      data && filterConditionArrayKeys.includes("applicantHealthInsurancePaymentCertFilePath")
        ? data.applicantHealthInsurancePaymentCertFilePath ?? null
        : null,
    applicantPensionReceiptCopy1FilePath:
      data && filterConditionArrayKeys.includes("applicantPensionReceiptCopy1FilePath") ? data.applicantPensionReceiptCopy1FilePath ?? null : null,
    applicantPensionRecordInquiry2FilePath:
      data && filterConditionArrayKeys.includes("applicantPensionRecordInquiry2FilePath")
        ? data.applicantPensionRecordInquiry2FilePath ?? null
        : null,
    buildingEnvHealthMgmtRegistrationCertFilePath:
      data && filterConditionArrayKeys.includes("buildingEnvHealthMgmtRegistrationCertFilePath")
        ? data.buildingEnvHealthMgmtRegistrationCertFilePath ?? null
        : null,
    fishingBoatRegistrationCertCopyFilePath:
      data && filterConditionArrayKeys.includes("fishingBoatRegistrationCertCopyFilePath")
        ? data.fishingBoatRegistrationCertCopyFilePath ?? null
        : null,
    fishingBoatRegistryCopyFilePath:
      data && filterConditionArrayKeys.includes("fishingBoatRegistryCopyFilePath") ? data.fishingBoatRegistryCopyFilePath ?? null : null,
    fishingCooperativeMembershipDocumentFilePath:
      data && filterConditionArrayKeys.includes("fishingCooperativeMembershipDocumentFilePath")
        ? data.fishingCooperativeMembershipDocumentFilePath ?? null
        : null,
    fishingCooperativeRightsDocumentFilePath:
      data && filterConditionArrayKeys.includes("fishingCooperativeRightsDocumentFilePath")
        ? data.fishingCooperativeRightsDocumentFilePath ?? null
        : null,
    fishingLicenseCopy1FilePath:
      data && filterConditionArrayKeys.includes("fishingLicenseCopy1FilePath") ? data.fishingLicenseCopy1FilePath ?? null : null,
    fishingLicenseCopy2FilePath:
      data && filterConditionArrayKeys.includes("fishingLicenseCopy2FilePath") ? data.fishingLicenseCopy2FilePath ?? null : null,
    fishingLicenseCopy3FilePath:
      data && filterConditionArrayKeys.includes("fishingLicenseCopy3FilePath") ? data.fishingLicenseCopy3FilePath ?? null : null,
    previousApplicationDocumentsFilePath:
      data && filterConditionArrayKeys.includes("previousApplicationDocumentsFilePath") ? data.previousApplicationDocumentsFilePath ?? null : null,
    publicDutyPledgeFilePath: data && filterConditionArrayKeys.includes("publicDutyPledgeFilePath") ? data.publicDutyPledgeFilePath ?? null : null,
    publicDutyComplianceFilePath:
      data && filterConditionArrayKeys.includes("publicDutyComplianceFilePath") ? data.publicDutyComplianceFilePath ?? null : null,
    shipbuildingIndustryConfirmationFilePath:
      data && filterConditionArrayKeys.includes("shipbuildingIndustryConfirmationFilePath")
        ? data.shipbuildingIndustryConfirmationFilePath ?? null
        : null,
    businessLicenseOrNotificationFormFilePath:
      data && filterConditionArrayKeys.includes("businessLicenseOrNotificationFormFilePath")
        ? data.businessLicenseOrNotificationFormFilePath ?? null
        : null,
    specificSkillOrganizationOverviewFilePath:
      data && filterConditionArrayKeys.includes("specificSkillOrganizationOverviewFilePath")
        ? data.specificSkillOrganizationOverviewFilePath ?? null
        : null,
    residenceApplicationsForEmployeeTable1FilePath:
      data && data.residenceApplicationsForEmployeeTable1FilePath ? data.residenceApplicationsForEmployeeTable1FilePath : null,
    residenceApplicationsForEmployeeTable2And1FilePath:
      data && data.residenceApplicationsForEmployeeTable2And1FilePath ? data.residenceApplicationsForEmployeeTable2And1FilePath : null,
    residenceApplicationsForEmployeeTable2And2FilePath:
      data && data.residenceApplicationsForEmployeeTable2And2FilePath ? data.residenceApplicationsForEmployeeTable2And2FilePath : null,
    residenceApplicationsForEmployeeTable2And3FilePath:
      data && data.residenceApplicationsForEmployeeTable2And3FilePath ? data.residenceApplicationsForEmployeeTable2And3FilePath : null,
    residenceApplicationsForEmployeeTable3And1FilePath:
      data && data.residenceApplicationsForEmployeeTable3And1FilePath ? data.residenceApplicationsForEmployeeTable3And1FilePath : null,
    residenceApplicationsForEmployeeTable3And2FilePath:
      data && data.residenceApplicationsForEmployeeTable3And2FilePath ? data.residenceApplicationsForEmployeeTable3And2FilePath : null,
    residenceApplicationsForEmployeeTable3And3FilePath:
      data && data.residenceApplicationsForEmployeeTable3And3FilePath ? data.residenceApplicationsForEmployeeTable3And3FilePath : null,
    isDraft,
  };
};
