import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { createRenewVisaApplicationInformationDocumentApi, updateRenewVisaApplicationInformationDocumentApi } from "@/api/services/main/renew-visa";
import { TGetTRenewVisaApplicationInformationDocumentOutputDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-application-information-document.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { StepCard } from "@/components/elements/StepCard";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { ISO_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { renewVisaApplicationInformationSchema } from "@/features/renew-visa/schema/renewVisaApplicaitonInformationSchema";
import { TRenewVisaApplicationInformationForm } from "@/features/renew-visa/types/renewVisaApplicationInformationForm";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export type TAfterSubmitApplicationStepCardProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  applicationInformationData?: TGetTRenewVisaApplicationInformationDocumentOutputDto;
  readonly: boolean;
};

export const AfterSubmitApplicationStepCard: FC<TAfterSubmitApplicationStepCardProps> = ({ stepData, applicationInformationData, readonly }) => {
  const { stepOrder, stepName } = stepData.masterDocumentStep;
  const { id: documentStepId } = stepData;

  const { renewVisaDocumentId } = useParams();
  const queryClient = useQueryClient();

  const methods = useForm<TRenewVisaApplicationInformationForm>({
    resolver: yupResolver(renewVisaApplicationInformationSchema),
    defaultValues: {
      applicationNumber: applicationInformationData ? applicationInformationData.applicationNumber : undefined,
      applicationDate: applicationInformationData ? dayjs(applicationInformationData.applicationDate) : undefined,
    },
    mode: "onBlur",
  });

  const { control } = methods;

  const onSubmitRenewVisaApplicaitonInformation = async (data: TRenewVisaApplicationInformationForm) => {
    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      if (applicationInformationData?.updater) {
        const rs = await updateRenewVisaApplicationInformationDocumentApi(renewVisaDocumentId, documentStepId, {
          applicationDate: data.applicationDate.format(ISO_FORMAT),
          applicationNumber: data.applicationNumber,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createRenewVisaApplicationInformationDocumentApi(renewVisaDocumentId, documentStepId, {
          applicationDate: data.applicationDate.format(ISO_FORMAT),
          applicationNumber: data.applicationNumber,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_APPLICATION_INFORMATION] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmitRenewVisaApplicaitonInformation} schema={renewVisaApplicationInformationSchema}>
      <StepCard
        title={`ステップ${stepOrder}`}
        subTitle={stepName}
        buttons={
          <Button variant="primary" size="sm" type="submit" disabled={readonly}>
            保存
          </Button>
        }
      >
        <Typography component="p" variant="body14" mb="16px">
          申請日・申請番号入力後、新しい在留カード情報を特定技能外国人の一覧ページにて更新すると完了となります。
        </Typography>
        <Grid container columnSpacing="16px">
          <Grid item xs={4}>
            <FormField label="申請日" control={control} name="applicationDate">
              <InputDatePicker disabled={readonly} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField label="申請番号" control={control} name="applicationNumber">
              <Input disabled={readonly} />
            </FormField>
          </Grid>
        </Grid>
      </StepCard>
    </Form>
  );
};
