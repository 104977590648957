import { TCreateConvertRequestReqDto } from "@/api/services/main/convert-request/dtos/create-convert-request.dto";
import { clientApi } from "@/utils/api";

export const setDownloadedConvertRequestApi = (convertRequestId: string | number) => {
  return clientApi.put(`/convert-requests/${convertRequestId}/set-downloaded`);
};

export const createConvertRequestApi = (payload: TCreateConvertRequestReqDto) => {
  return clientApi.post("/convert-requests/create", payload);
};
