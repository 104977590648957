import { Tag } from "@/components/elements/Tag";
import { pink, state } from "@/theme/colors";
import { EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS } from "@/types/enum";
import { FC, useMemo } from "react";

export type TRetirementDocumentStatusTagProps = {
  status: string;
  title: string;
};

export const RetirementDocumentStatusTag: FC<TRetirementDocumentStatusTagProps> = ({ status, title }) => {
  const tagProps = useMemo(() => {
    switch (status) {
      case EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.INCOMPLETE:
        return { color: state.warning_1 };
      case EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.PROCESSING:
        return { color: pink[70] };
      case EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.CREATING:
        return { color: state.link_1 };
      case EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE:
        return { color: state.success_1 };
      case EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.ERROR:
        return { color: state.error_1 };
      default:
        return { color: state.success_1 };
    }
  }, [status]);

  return <Tag title={title} variant="bold" textColor="white" {...tagProps} />;
};
