import { TContactForm } from "@/features/contact/types/contactForm";
import { TCreateContactReqDto } from "@/api/services/main/contact/dtos/create-contact.dto";

export const convertContactFormDataToReqDto = (data: TContactForm): TCreateContactReqDto => {
  return {
    contactTypeId: data.contactTypeId,
    content: data.content,
    email: data.email,
    telNumber: data.telNumber ?? null,
    filePath: data.filePath ?? null,
  };
};
