import { useEnum } from "@/api/services/global/enum";
import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { Avatar } from "@/components/elements/Avatar";
import { Button } from "@/components/elements/Button";
import { StatusTag } from "@/components/elements/StatusTag";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { RetirementStatusTag } from "@/features/employee/components/RetirementStatusTag";
import { EMPLOYEE_LIST_TAB, TEmployeeListTab } from "@/features/employee/constants/employeeListTab";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export const useMakeEmployeeListColumns = (currentTab: TEmployeeListTab) => {
  const { data: employmentStatusOptions } = useEnum("EEmploymentStatus");

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeListOutputDto>[]>(
    () => [
      {
        header: "在籍状況",
        Cell: ({ row }) => {
          const { retirementStatus, employmentStatus } = row.original;
          if (currentTab === EMPLOYEE_LIST_TAB.OFFER || currentTab === EMPLOYEE_LIST_TAB.WORKING_AND_LEFT) {
            if (!employmentStatus) return EMPTY_STR.TEXT;
            const label = findLabelByValue(employmentStatusOptions, employmentStatus);
            if (!label) return EMPTY_STR.TEXT;
            return <StatusTag status={employmentStatus} variant="bold" title={label} />;
          } else {
            if (!retirementStatus) return EMPTY_STR.TEXT;
            return <RetirementStatusTag retirementStatus={retirementStatus} />;
          }
        },
        muiTableBodyCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
      },
      {
        header: "管理番号",
        Cell: ({ row }) => row.original.managementNumber || EMPTY_STR.TEXT,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
      },
      {
        header: "外国人名",
        Cell: ({ row }) => {
          const { firstName, lastName, middleName, avatarFilePath } = row.original;
          const fullName = getFullName({ firstName, lastName, middleName });

          return (
            <Stack direction="row" gap={1} alignItems="center">
              <Avatar filePath={avatarFilePath} width={30} height={40} sx={{ minWidth: 30 }} />
              <Typography variant="body14">
                {fullName} <br /> {row.original.nickName || EMPTY_STR.TEXT}
              </Typography>
            </Stack>
          );
        },
      },
      {
        header: "国籍",
        Cell: ({ row }) => row.original.nationality?.nationalityName || EMPTY_STR.TEXT,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "在留期限",
        Cell: ({ row }) => (row.original.residenceExpiryDate ? dayjs(row.original.residenceExpiryDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "パスポート期限",
        Cell: ({ row }) => (row.original.passportExpiryDate ? dayjs(row.original.passportExpiryDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "勤務場所",
        Cell: ({ row }) => row.original.contractCompanyBranch?.branchName || EMPTY_STR.TEXT,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
      },
      {
        header: "入社日",
        Cell: ({ row }) => (row.original.hiringDate ? dayjs(row.original.hiringDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        muiTableBodyCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
      },
      {
        header: "1号経過期間",
        Cell: ({ row }) => {
          const blankPeriodMonthly = row.original.blankPeriodMonthly || 0;
          const totalMonth = dayjs(new Date()).diff(row.original.skillOneStartDate, "M") - blankPeriodMonthly;
          const yearPeriod = Math.floor(totalMonth / 12);
          const monthPeriod = totalMonth % 12;
          const period = `${yearPeriod > 0 ? yearPeriod + "年" : ""}${monthPeriod > 0 ? monthPeriod + "ヶ月" : ""}`;
          return period || "0ヶ月";
        },
        size: 80,
      },
      {
        header: "編集",
        id: "action",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button variant="outline" size="sm" component={Link} to={getDynamicRoute(AppRoutes.employeeEdit, { id })}>
              編集
            </Button>
          );
        },
        size: 80,
      },
    ],
    [employmentStatusOptions, currentTab],
  );
  return columns;
};
