import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import { DocumentCard } from "@/components/elements/DocumentCard";
import { AcceptingWorkersCreateConvertRequestStep } from "@/features/retirement/components/retirement-document/documents/accepting-worker/AcceptingWorkersCreateConvertRequestStep";
import { AcceptingWorkersCreateDocumentStep } from "@/features/retirement/components/retirement-document/documents/accepting-worker/AcceptingWorkersCreateDocumentStep";
import { AcceptingWorkerSubmitStep } from "@/features/retirement/components/retirement-document/documents/accepting-worker/AcceptingWorkerSubmitStep";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findRetirementDocumentStep } from "@/utils/document";
import { FC, useMemo } from "react";

type TDocumentProps = {
  document: TEmployeeRetirementDocumentOutputDto;
  readonly?: boolean;
};

export const AcceptingWorkerDocument: FC<TDocumentProps> = ({ document, readonly }) => {
  const renderAcceptingWorkerCreateDocumentStep = useMemo(() => {
    const stepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT);
    const convertingStepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST);
    if (!stepData || !convertingStepData) return null;
    return <AcceptingWorkersCreateDocumentStep stepData={stepData} document={document} readonly={readonly} />;
  }, [readonly, document]);

  const renderAcceptingWorkerCreateConvertRequestStep = useMemo(() => {
    const creatingDocumentStepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT);
    const stepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST);

    if (!creatingDocumentStepData || !stepData) return null;
    return (
      <AcceptingWorkersCreateConvertRequestStep
        document={document}
        creatingDocumentStepData={creatingDocumentStepData}
        stepData={stepData}
        readonly={readonly}
      />
    );
  }, [readonly, document]);

  const renderAcceptingWorkerSubmitStep = useMemo(() => {
    const stepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_SUBMIT);

    if (!stepData) return null;
    return <AcceptingWorkerSubmitStep document={document} stepData={stepData} readonly={readonly} />;
  }, [readonly, document]);

  return (
    <DocumentCard title={`届出書類${document.masterDocument.documentOrder}`} subTitle={document.masterDocument.documentName}>
      {renderAcceptingWorkerCreateDocumentStep}
      {renderAcceptingWorkerCreateConvertRequestStep}
      {renderAcceptingWorkerSubmitStep}
    </DocumentCard>
  );
};
