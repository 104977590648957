import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { useGetTargetQuarter } from "@/features/regular-document/hooks/useGetTargetQuarter";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { button, divider, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { TabContext, TabList } from "@mui/lab";
import { Box, css, styled } from "@mui/material";
import Tab from "@mui/material/Tab";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";

type TRegularReportLayoutProps = {};

export const RegularReportLayout: FC<TRegularReportLayoutProps> = () => {
  const navigate = useNavigate();
  const { data: presentDocumentListResult, isLoading } = usePresentDocumentList();
  const { isLoading: isInitLoading } = useInitSetup();

  const presentDocumentList = presentDocumentListResult?.data;

  const { targetQuarter } = useGetTargetQuarter();

  if (isLoading || !presentDocumentList || isInitLoading) return <LoadingPage />;
  return (
    <Box>
      <TopPage title="定期届出" description="届出書類1、2のそれぞれのステップを進んで、「届出提出」を押してください" backUrl={AppRoutes.home} />
      <InnerBox padding="0">
        <BoxTabWrapper>
          <TabContext value={location.pathname}>
            <TabListWrapper onChange={(_, tab) => navigate(tab)} variant="fullWidth">
              <TabWrapper label={targetQuarter} value={AppRoutes.regularPresent} />
              <TabWrapper label="過去提出分" value={AppRoutes.regularHistory} />
            </TabListWrapper>
          </TabContext>
          <Outlet />
        </BoxTabWrapper>
      </InnerBox>
    </Box>
  );
};

const BoxTabWrapper = styled(Box)`
  & .MuiTabs-flexContainer {
    justify-content: space-between;

    & .MuiButtonBase-root {
      text-transform: none;
      font-weight: bold;
      ${css(typography.head20Bold)};
      color: ${text.disable};
    }

    & .Mui-selected {
      color: ${button.pressed};
    }
  }
`;

const TabListWrapper = styled(TabList)`
  .MuiTabs-indicator {
    background-color: ${button.pressed};
    height: 4px;
  }
`;

const TabWrapper = styled(Tab)`
  border-bottom: 4px solid ${divider.high};
`;
