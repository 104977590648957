import {
  createAcceptanceActivityDocumentApi,
  updateAcceptanceActivityDocumentApi,
} from "@/api/services/main/regular-document/acceptance-activity-document";
import { Form } from "@/components/elements/Form";
import { FormDescription } from "@/components/elements/FormDescription";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { AcceptActivityBtnActions } from "@/features/regular-document/components/accept-activity/AcceptActivityBtnActions";
import { AffiliationAgency } from "@/features/regular-document/components/accept-activity/AffiliationAgency";
import { CompanySupporterForm } from "@/features/regular-document/components/accept-activity/CompanySupporterForm";
import { CostsRequired } from "@/features/regular-document/components/accept-activity/CostsRequired";
import { EmploymentStatus } from "@/features/regular-document/components/accept-activity/EmploymentStatus";
import { TargetPeriod } from "@/features/regular-document/components/accept-activity/TargetPeriod";
import { acceptActivityDraftSchema, acceptActivitySchema } from "@/features/regular-document/schema/acceptActivitySchema";
import { TAcceptanceActivityForm } from "@/features/regular-document/types/acceptanceActivityForm";
import { AppRoutes } from "@/routes/config";
import { FCC, TUpdaterDto } from "@/types/common";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { isEmpty } from "@/utils/object";
import { getFormValues } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type TAcceptanceActivityFormProps = {
  documentStepId: number;
  previousValues?: Partial<TAcceptanceActivityForm>;
  updater: TUpdaterDto | undefined;
  readonly?: boolean;
  isDraft?: boolean;
  year: number;
  quarter: number;
  isEditable?: boolean;
  setIsEditable?: (bool: boolean) => void;
};

export const AcceptanceActivityForm: FCC<TAcceptanceActivityFormProps> = ({
  documentStepId,
  previousValues,
  updater,
  readonly,
  year,
  quarter,
  isDraft: isPageDraft,
  isEditable,
  setIsEditable,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const { pathname } = useLocation();

  const methods = useForm<TAcceptanceActivityForm>({
    defaultValues: previousValues,
    resolver: readonly || !isEditable ? undefined : yupResolver(acceptActivitySchema),
    mode: "onBlur",
  });

  const saveDataForm = async (data: TAcceptanceActivityForm, isDraft: boolean) => {
    try {
      if (updater) {
        if (isEmpty(methods.formState.dirtyFields) && isPageDraft === isDraft) {
          if (isDraft) {
            navigate(AppRoutes.regularPresent);
          } else {
            navigate(AppRoutes.regularPresentAcceptActivityPayCheck);
          }
          return;
        }
        const rs = await updateAcceptanceActivityDocumentApi(documentStepId, {
          ...data,
          companySpecificIndustryId: toNumber(data.companySpecificIndustryId),
          isDraft,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createAcceptanceActivityDocumentApi(documentStepId, {
          ...data,
          companySpecificIndustryId: toNumber(data.companySpecificIndustryId),
          isDraft,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_ACCEPTANCE_ACTIVITY_DOCUMENT] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
      if (isDraft) {
        methods.unregister();
        navigate(AppRoutes.regularPresent);
      } else {
        navigate(AppRoutes.regularPresentAcceptActivityPayCheck);
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TAcceptanceActivityForm) => {
    if (readonly) {
      navigate(getDynamicRoute(AppRoutes.regularHistoryAcceptActivityPayCheck, params));
      return;
    }
    await saveDataForm(data, false);
  };

  const saveDraft = async () => {
    const data = methods.getValues();
    try {
      const values = await getFormValues(data, acceptActivityDraftSchema);
      await saveDataForm(values as TAcceptanceActivityForm, true);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const isPresent = pathname.startsWith(AppRoutes.regularPresentAcceptActivity);

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={acceptActivitySchema}>
      <Box maxWidth={COMPANY_CONTAINER}>
        <TopPage
          title={readonly ? "定期届出" : "受入れ・活動状況に係る届出"}
          backUrl={readonly && !isPresent ? getDynamicRoute(AppRoutes.regularHistoryDetail, params) : AppRoutes.regularPresent}
          isDraft={isPageDraft}
        />
        <MenuNavigate
          contents={[
            { title: "対象期間", content: <TargetPeriod /> },
            { title: "特定技能所属機関", content: <AffiliationAgency readonly={readonly || !isEditable} /> },
            { title: "雇用状況", content: <EmploymentStatus readonly={readonly || !isEditable} /> },
            {
              title: "特定技能外国人の受入れに要した費用",
              content: <CostsRequired year={year} quarter={quarter} readonly={readonly || !isEditable} />,
            },
            { title: "届出担当者", content: <CompanySupporterForm readonly={readonly || !isEditable} /> },
          ]}
          contentPrefix={readonly ? undefined : <FormDescription />}
          contentAffix={
            <AcceptActivityBtnActions saveDraft={saveDraft} readonly={readonly} isEditable={!!isEditable} setIsEditable={(v) => setIsEditable?.(v)} />
          }
        />
      </Box>
    </Form>
  );
};
