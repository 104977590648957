import { TEmployeePayroll } from "@/api/entities/employeePayroll.entity";
import { DATE_FORMAT, ISO_FORMAT } from "@/constants/datetime";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";

import { TUpdateEmployeePayrollInputDto } from "@/api/services/main/regular-document/acceptance-activity-document/dtos/update-multi-employee-payroll.dto";
import { TPayrollRecord } from "@/features/regular-document/types/payrollRecord";
import { TPayrollRequest } from "@/features/regular-document/types/payrollRequest";
import { TPayrollTableRow } from "@/features/regular-document/types/payrollTableRow";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { newRef, pick } from "@/utils/object";
import { makeUuid } from "@/utils/pieces";
import { toString } from "@/utils/string";

export const paycheckCSVConverter = (json: Record<string, string>[]) => {
  const initData = {
    recordId: makeUuid(),
    no: "",
    month: "",
    employeeFullName: "",
    employeeBirthday: "",
    employeeGender: "",
    employeeResidenceCode: "",
    employeeZipCode: "",
    employeeAddress: "",
    isChangeWorkingLocation: PLAIN_TEXT.noChange,
    isChangeDispatchPlace: PLAIN_TEXT.noChange,
    isChangeWorkingDetail: PLAIN_TEXT.noChange,
    workingDayCount: "0",
    totalAmountPaid: "0",
    netAmountPaid: "0",
    legalDeductionAmount: "0",
    hasComparisonEmployee: PLAIN_TEXT.yes,
    status: PAYROLL_STATUS.OK,
  } as TPayrollRecord;

  return json.map((object) => {
    const data = newRef(initData);
    data.recordId = makeUuid();

    if (PLAIN_TEXT.numberOrder in object) {
      data.no = toString(object[PLAIN_TEXT.numberOrder]);
    }
    if (PLAIN_TEXT.employeeFullName in object) {
      data.employeeFullName = toString(object[PLAIN_TEXT.employeeFullName]);
    }
    if (PLAIN_TEXT.employeeBirthday in object) {
      data.employeeBirthday = toString(object[PLAIN_TEXT.employeeBirthday]);
    }
    if (
      PLAIN_TEXT.isChangeWorkingLocation in object &&
      [PLAIN_TEXT.noChange, PLAIN_TEXT.change].includes(object[PLAIN_TEXT.isChangeWorkingLocation])
    ) {
      data.isChangeWorkingLocation = object[PLAIN_TEXT.isChangeWorkingLocation];
    }
    if (PLAIN_TEXT.isChangeWorkingDetail in object && [PLAIN_TEXT.noChange, PLAIN_TEXT.change].includes(object[PLAIN_TEXT.isChangeWorkingDetail])) {
      data.isChangeWorkingDetail = object[PLAIN_TEXT.isChangeWorkingDetail];
    }
    if (PLAIN_TEXT.isChangeDispatchPlace in object && [PLAIN_TEXT.noChange, PLAIN_TEXT.change].includes(object[PLAIN_TEXT.isChangeDispatchPlace])) {
      data.isChangeDispatchPlace = object[PLAIN_TEXT.isChangeDispatchPlace];
    }
    if (PLAIN_TEXT.month in object) {
      data.month = toString(object[PLAIN_TEXT.month]);
    }
    if (PLAIN_TEXT.workingDay in object) {
      data.workingDayCount = toString(object[PLAIN_TEXT.workingDay]);
    }
    if (PLAIN_TEXT.totalAmountPaid in object) {
      data.totalAmountPaid = toString(object[PLAIN_TEXT.totalAmountPaid]);
    }
    if (PLAIN_TEXT.netAmountPaid in object) {
      data.netAmountPaid = toString(object[PLAIN_TEXT.netAmountPaid]);
    }
    if (PLAIN_TEXT.legalDeductionAmount in object) {
      data.legalDeductionAmount = toString(object[PLAIN_TEXT.legalDeductionAmount]);
    }

    if (PLAIN_TEXT.hasComparisonEmployee in object && [PLAIN_TEXT.no, PLAIN_TEXT.yes].includes(object[PLAIN_TEXT.hasComparisonEmployee])) {
      data.hasComparisonEmployee = object[PLAIN_TEXT.hasComparisonEmployee];
    }
    if (PLAIN_TEXT.month in object) {
      data.month = toString(object[PLAIN_TEXT.month]);
    }

    return data;
  });
};

export const makePayrollRequestsFromRecords = (
  records: TPayrollRecord[],
  year: number,
  quarter: number,
  isDraft: boolean,
): TUpdateEmployeePayrollInputDto[] => {
  const data: TUpdateEmployeePayrollInputDto[] = records.map((record) => {
    return {
      employeeFullName: record.employeeFullName,
      employeeGender: record.employeeGender,
      employeeZipCode: record.employeeZipCode,
      employeeAddress: record.employeeAddress,
      employeeNationalityId: record.employeeNationalityId ? toNumber(record.employeeNationalityId) : null,
      employeeBirthday: record.employeeBirthday ? dayjs(record.employeeBirthday).format(ISO_FORMAT) : null,
      no: toNumber(record.no),
      month: toNumber(record.month),
      year,
      quarter,
      isChangeWorkingLocation: record.isChangeWorkingLocation === PLAIN_TEXT.change ? true : false,
      isChangeDispatchPlace: record.isChangeDispatchPlace === PLAIN_TEXT.change ? true : false,
      isChangeWorkingDetail: record.isChangeWorkingDetail === PLAIN_TEXT.change ? true : false,
      hasComparisonEmployee: record.hasComparisonEmployee === PLAIN_TEXT.yes ? true : false,
      workingDayCount: toNumber(record.workingDayCount),
      totalAmountPaid: toNumber(record.totalAmountPaid),
      netAmountPaid: toNumber(record.netAmountPaid),
      legalDeductionAmount: toNumber(record.legalDeductionAmount),
      employeeId: toNumber(record.employeeId),
      employeeResidenceCardNumber: record.employeeResidenceCode,
      isDraft,
    };
  });

  return data;
};

export const makePayrollRecords = (payrolls: (TPayrollRequest | TEmployeePayroll)[]): TPayrollRecord[] => {
  const data = payrolls.map((payroll) => ({
    ...pick(
      payroll,
      "employeeFullName",
      "employeeNationalityId",
      "employeeBirthday",
      "employeeGender",
      "employeeResidenceCardNumber",
      "employeeZipCode",
      "employeeAddress",
    ),
    employeeBirthday: dayjs(payroll.employeeBirthday).format(DATE_FORMAT),
    isChangeWorkingLocation: payroll.isChangeWorkingLocation ? PLAIN_TEXT.change : PLAIN_TEXT.noChange,
    isChangeDispatchPlace: payroll.isChangeDispatchPlace ? PLAIN_TEXT.change : PLAIN_TEXT.noChange,
    isChangeWorkingDetail: payroll.isChangeWorkingDetail ? PLAIN_TEXT.change : PLAIN_TEXT.noChange,
    hasComparisonEmployee: payroll.hasComparisonEmployee ? PLAIN_TEXT.yes : PLAIN_TEXT.no,
    no: payroll.no.toString(),
    month: payroll.month.toString(),
    workingDayCount: payroll.workingDayCount.toString(),
    totalAmountPaid: payroll.totalAmountPaid.toString(),
    netAmountPaid: payroll.netAmountPaid.toString(),
    legalDeductionAmount: payroll.legalDeductionAmount.toString(),
    employeeId: payroll.employeeId,
    recordId: makeUuid(),
    employeeResidenceCode: payroll.employeeResidenceCardNumber,
  }));

  return data as TPayrollRecord[];
};

export const convertToDataTableRow = (payrolls: TPayrollRecord[]): TPayrollTableRow[] => {
  const dataError: TPayrollTableRow[] = [];
  const dataGroupByEmployeeId: Record<string, TPayrollRecord[]> = {};

  const dataRows: TPayrollRecord[] = [];

  payrolls.forEach((payroll) => {
    if (payroll.status === PAYROLL_STATUS.ERROR) {
      dataError.push(payroll);
      return;
    }
    dataRows.push(payroll);
    if (dataGroupByEmployeeId[payroll.employeeId]) {
      dataGroupByEmployeeId[payroll.employeeId].push(payroll);
    } else {
      dataGroupByEmployeeId[payroll.employeeId] = [payroll];
    }
  });

  const data: TPayrollTableRow[] = Object.values(dataGroupByEmployeeId).map((items) => ({ ...items[0], children: items }));

  return [dataError, data].flat();
};
