import { EXTENSION_NAMES, FILE_ERROR_MESSAGE, FILE_STATUS, MIME_TYPES, TFileStatus } from "@/constants/file";
import { uniqueArray } from "@/utils/pieces";
import { isObject } from "@/utils/object";
import { TExtendFile } from "@/types/file";
import csvParse from "papaparse";
import { Accept } from "react-dropzone";
import { replaceStr } from "@/utils/string";
import { PDFDocument } from "pdf-lib";

/**
 * Converts bytes to kilobytes.
 *
 * @param {number} size - The size in bytes.
 * @returns {number} - The size in kilobytes.
 */
export const convertByteToKb = (size: number): number => {
  return size / 1024;
};

/**
 * Converts kilobytes to bytes.
 *
 * @param {number} size - The size in kilobytes.
 * @returns {number} - The size in bytes.
 */
export const convertKbToByte = (size: number): number => {
  return size * 1024;
};

/**
 * Converts bytes to megabytes.
 *
 * @param {number} size - The size in bytes.
 * @returns {number} - The size in megabytes.
 */
export const convertByteToMb = (size: number): number => {
  return size / (1024 * 1024);
};

/**
 * Converts megabytes to bytes.
 *
 * @param {number} size - The size in megabytes.
 * @returns {number} - The size in bytes.
 */
export const convertMbToByte = (size: number): number => {
  return size * (1024 * 1024);
};

/**
 * Type definition for file validation rules.
 */
export type TFileRules = Partial<{
  maxSize: number;
  minSize: number;
  acceptMimeTypes: string[];
}>;

/**
 * Determines the file status based on the provided rules.
 *
 * @param {File} file - The file to validate.
 * @param {TFileRules} options - The validation rules.
 * @returns {TFileStatus} - The status of the file.
 */
export const getFileStatusWithRules = (file: File, options: TFileRules): TFileStatus => {
  try {
    if (options.minSize !== undefined && file.size < options.minSize) {
      return FILE_STATUS.ERROR_MIN_SIZE;
    }
    if (options.maxSize !== undefined && file.size > options.maxSize) {
      return FILE_STATUS.ERROR_MAX_SIZE;
    }
    if (options.acceptMimeTypes !== undefined && !options.acceptMimeTypes.includes(file.type)) {
      return FILE_STATUS.ERROR_ACCEPT_FILES;
    }
    return FILE_STATUS.OK;
  } catch {
    return FILE_STATUS.ERROR;
  }
};

/**
 * Checks if the file has an error status.
 *
 * @param {TExtendFile} file - The file to check.
 * @returns {boolean} - True if the file has an error status, false otherwise.
 */
export const isFileError = (file: TExtendFile): boolean => {
  if (!file.status) return false;
  return [FILE_STATUS.ERROR, FILE_STATUS.ERROR_ACCEPT_FILES, FILE_STATUS.ERROR_MAX_SIZE, FILE_STATUS.ERROR_MIN_SIZE].includes(file.status);
};

/**
 * Converts a CSV file to JSON.
 *
 * @param {File} file - The CSV file to convert.
 * @returns {Promise<Record<string, string>[]>} - A promise that resolves to the JSON data.
 */
export const convertCSVToJson = (file: File): Promise<Record<string, string>[]> => {
  return new Promise((resolve, reject) => {
    try {
      csvParse.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          // Iterating data to get column name and their values
          const data: Record<string, string>[] = [];
          results.data.map((d) => {
            if (isObject(d)) data.push(d as Record<string, string>);
            else reject("ファイル形式はCSVにてお願いします。");
          });
          resolve(data);
        },
      });
    } catch (error) {
      return reject("ファイル形式はCSVにてお願いします。");
    }
  });
};

/**
 * Gets the file extension based on the MIME type.
 *
 * @param {string} mimeType - The MIME type of the file.
 * @returns {string | null} - The file extension, or null if not found.
 */
export const getExtensionOfMimeType = (mimeType: string): string | null => {
  const found = Object.entries(MIME_TYPES).find(([_, mimeTypes]) => mimeTypes.includes(mimeType));
  if (!found) return null;
  const extensionKey = found[0];
  return EXTENSION_NAMES[extensionKey] ?? null;
};

/**
 * Gets the file extensions based on the MIME types.
 *
 * @param {string[]} mimeTypes - The MIME types of the files.
 * @returns {string[]} - The file extensions.
 */
export const getExtensionsOfMimeTypes = (mimeTypes: string[]): string[] => {
  const extensionNames: string[] = [];
  mimeTypes.forEach((mimeType) => {
    const extensionName = getExtensionOfMimeType(mimeType);
    if (extensionName) extensionNames.push(extensionName);
  });
  return uniqueArray(extensionNames);
};

/**
 * Converts MIME types to the accepted format for react-dropzone.
 *
 * @param {string[]} [mimeTypes] - The MIME types to convert.
 * @returns {Accept | undefined} - The accepted format for react-dropzone, or undefined if no MIME types are provided.
 */
export const covertMimeTypesToAccepts = (mimeTypes?: string[]): Accept | undefined => {
  if (!mimeTypes) return undefined;
  return mimeTypes.reduce((cur, mimeType) => ({ ...cur, [mimeType]: [] }), {} as Accept);
};

/**
 * Extracts the file name from the file path.
 *
 * @param {string} [filePath=""] - The file path.
 * @returns {string | null} - The file name, or null if not found. Ex: 1724653683544_sample.pdf => sample.pdf
 */
export const getFileNameFromPath = (filePath: string = ""): string | null => {
  const fileName = filePath.split("/").pop();
  if (!fileName) return null;
  const [_, ...fileNameWithoutTimestamp] = fileName.split("_");
  if (fileNameWithoutTimestamp) return fileNameWithoutTimestamp.join("_");
  return null;
};
/**
 * Get file name and extension from file path
 *
 * @param filePath - The file path
 * @returns
 */
export const getFileNameAndExtension = (filePath: string) => {
  const fileNameWithExtension = filePath.split("/").pop() || "";
  const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
  const name = lastDotIndex !== -1 ? fileNameWithExtension.slice(0, lastDotIndex) : fileNameWithExtension;
  const extension = lastDotIndex !== -1 ? fileNameWithExtension.slice(lastDotIndex + 1) : "";
  return { name, extension };
};

/**
 * get size text label by size
 *
 * @param {number} size - size
 * @returns {string} - Ex: 20KB || 5MB
 */
export const getSizeTextLabel = (size: number): string => {
  if (size >= 1024 * 1024) return `${convertByteToMb(size)}MB`;
  else return `${convertByteToKb(size)}KB`;
};

/**
 * Gets the error message for a file based on its status and validation rules.
 *
 * @param {TExtendFile} file - The file to check.
 * @param {TFileRules} rules - The validation rules.
 * @returns {string} - The error message, or an empty string if no error.
 */
export const getFileErrorMessage = (file: TExtendFile, rules: TFileRules): string => {
  if (isFileError(file)) {
    //error message for MIME_TYPES.IMAGE
    if (rules.acceptMimeTypes?.find((item) => !!MIME_TYPES.IMAGE.includes(item)) && rules.maxSize)
      return `${getSizeTextLabel(rules.maxSize)}以下の写真をアップロードしてください。`;

    return replaceStr(FILE_ERROR_MESSAGE[file.status], {
      maxSize: rules.maxSize ? getSizeTextLabel(rules.maxSize) : undefined,
      minSize: rules.minSize ? getSizeTextLabel(rules.minSize) : undefined,
      extensions: getExtensionsOfMimeTypes(rules.acceptMimeTypes ?? []).join("、"),
    });
  }
  return "";
};

// Function to merge PDFs
export const mergePdfs = async (files: (File | null | undefined)[], fileName: string) => {
  // Create a new PDF document
  const mergedPdf = await PDFDocument.create();

  // Loop through the uploaded files
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file) {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await PDFDocument.load(arrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());

      // Add each page to the merged PDF
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
  }

  // Save the merged PDF as a Blob
  const mergedPdfBytes = await mergedPdf.save();
  const mergedPdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

  const mergedFile = new File([mergedPdfBlob], fileName, { type: mergedPdfBlob.type });

  return mergedFile;
};
