import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TConclusionFormProps = {
  readonly?: boolean;
};

export const ConclusionForm: FC<TConclusionFormProps> = ({ readonly }) => {
  const { control } = useFormContext<TEmploymentContractForm>();

  return (
    <InnerBox gap={2} title="新たな契約の締結" required>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormField control={control} name="renewContractDate">
            <InputDatePicker readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="renewContractContent">
            <Input readonly={readonly} />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};
