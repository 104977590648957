import { MAX_INTEGER } from "@/constants/common";
import { INVALID_NUMBER_MAX } from "@/constants/invalids";
import { replaceStr } from "@/utils/string";

import { validator } from "@/utils/validator";

export const ContactSchema = validator.object().shape({
  companyId: validator
    .number()
    .isSelection()
    .autoTransformToHalfWidth()
    .decimal()
    .emptyToNull()
    .limit(MAX_INTEGER)
    .label("お客様の企業名")
    .required(),
  createdBy: validator.number().isSelection().autoTransformToHalfWidth().decimal().emptyToNull().limit(MAX_INTEGER).label("担当者名").required(),
  contactTypeId: validator.string().trim().isSelection().label("お問い合わせの種類").required(),
  content: validator.string().trim().label("お問い合わせ内容の詳細").required(),
  filePath: validator.string().trim().emptyToNull().label("添付ファイル").notRequired().optional(),
  email: validator.string().trim().autoTransformToHalfWidth().email().max(60).label("メールアドレス").required(),
  telNumber: validator
    .string()
    .trim()
    .autoTransformToHalfWidth()
    .numbersOnly()
    .max(12, replaceStr(INVALID_NUMBER_MAX, { path: "連絡先電話番号", max: 12 }))
    .label("連絡先電話番号")
    .notRequired()
    .optional(),
});
