import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { InputCard } from "@/features/retirement/components/retirement-detail/accepting-worker/ExplanationCard";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TCircumstanceExplanationProps = {
  readonly?: boolean;
};

export const CircumstanceExplanation: FC<TCircumstanceExplanationProps> = ({ readonly }) => {
  const { control } = useFormContext<TRetirementAcceptingWorkerForm>();

  return (
    <Stack gap={2}>
      <Typography variant="head20Semi">受入れ困難となるに至った経緯に係る説明書（参考様式第５－１１号）</Typography>
      <InnerBox padding={3}>
        <Stack gap={3}>
          <InputCard
            title="具体的な事情（経緯、対応、退職、解雇理由）"
            selectName="specificCircumstances"
            inputName="specificCircumstanceContent"
            enumSelectName="ESpecificCircumstance"
            enumInputName="ESpecificCircumstanceContent"
            readonly={readonly}
          />
          <InputCard
            title="退職に係る相談の有無、その対応"
            selectName="anyConsultation"
            inputName="anyConsultationContent"
            enumSelectName="EAnyConsultation"
            enumInputName="EAnyConsultationContent"
            readonly={readonly}
          />
          <InputCard
            title="転職先、転職予定年月日(転職予定がある場合)"
            selectName="plansToChangeJob"
            inputName="plansToChangeJobContent"
            enumSelectName="EPlanToChangeJob"
            enumInputName="EPlanToChangeJobContent"
            readonly={readonly}
          />
          <InputCard
            title="転職支援を実施する場合は支援の内容"
            selectName="supportType"
            inputName="supportContent"
            enumSelectName="ESupportType"
            enumInputName="ESupportContent"
            readonly={readonly}
          />
          <InputCard
            title="帰国を希望している場合はその理由"
            selectName="reasonReturnHome"
            inputName="reasonReturnHomeContent"
            enumSelectName="EReasonReturnHome"
            enumInputName="EReasonReturnHomeContent"
            readonly={readonly}
          />
          <InputCard
            title="帰国予定年月日、航空券の手配状況"
            selectName="planDateReturn"
            inputName="planDateReturnContent"
            enumSelectName="EPlanDateReturn"
            enumInputName="EPlanDateReturnContent"
            readonly={readonly}
          />
          <InputCard
            title="退職後に転居する予定がある場合は転居先"
            selectName="planToMove"
            inputName="planToMoveContent"
            enumSelectName="EPlanToMove"
            enumInputName="EPlanToMoveContent"
            readonly={readonly}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormField control={control} name="telNumber">
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractName">
                <Input readonly={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="planedDateOfReturn">
                <InputDatePicker readonly={readonly} />
              </FormField>
            </Grid>
          </Grid>
        </Stack>
      </InnerBox>
    </Stack>
  );
};
