import { IconChevronDown, IconHamburger, IconLogo, IconNotification } from "@/assets/icons";
import { AppRoutes } from "@/routes/config";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { blue, divider, text } from "@/theme/colors";
import { elevation, spacing } from "@/theme/variables";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Popover } from "@/components/elements/Popover";
import { useMe } from "@/api/services/main/auth";
import { Linkable } from "@/components/elements/Linkable";
import { useCheckUnread } from "@/api/services/main/information";

type TAppHeaderProps = {
  handleOpenDrawer: () => void;
};

export const AppHeader: FC<TAppHeaderProps> = ({ handleOpenDrawer }) => {
  const { isLaptop } = useMediaQuery();
  const navigate = useNavigate();
  const { data: meResult } = useMe();
  const meData = meResult?.data;
  const { data: checkResult } = useCheckUnread();

  return (
    <BoxWrapper>
      <Stack direction="row" alignItems="center">
        {isLaptop && (
          <Button onClick={handleOpenDrawer}>
            <IconHamburger color={text.white} fontSize="32" />
          </Button>
        )}
        <StyledLink to={AppRoutes.home} className={isLaptop ? "centered" : ""}>
          <IconLogo />
        </StyledLink>
      </Stack>
      <AppMenu mt={1}>
        <Linkable href={AppRoutes.information}>
          <IconNotification cursor="pointer" fontSize={24} hasDot={!!checkResult?.data} />
        </Linkable>
        <NameTypography mb={1}>{meData ? `${meData?.company?.companyName} | ${meData?.fullName}` : "---- | ----"}</NameTypography>
        <Popover
          sx={{
            marginTop: "8px",
            borderRadius: 0,
            ".MuiPopover-paper": {
              borderRadius: "0 0 8px 8px",
              boxShadow: "0px 4px 4px 0px #00000040",
            },
          }}
          anchor={<IconChevronDown cursor="pointer" fontSize="24" color={text.white} />}
        >
          <LogoutOption
            onClick={() => {
              navigate(AppRoutes.logout);
            }}
          >
            ログアウト
          </LogoutOption>
        </Popover>
      </AppMenu>
    </BoxWrapper>
  );
};

const BoxWrapper = styled(Box)`
  background-color: ${blue[70]};
  height: 56px;
  padding: 0px ${spacing.sm};
  border-bottom: 1px solid ${divider.middle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${elevation.lv1};
  position: sticky;
  top: 0;
  z-index: ${zIndex.appBar};
`;

const AppMenu = styled(Box)`
  display: flex;
  align-items: center;
`;

const NameTypography = styled(Typography)`
  color: ${text.white};
  margin-right: 13px;
  margin-left: 24px;
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LogoutOption = styled(Box)`
  font-size: 14px;
  padding: 4px 28px;
  cursor: pointer;
  :hover {
    background: ${divider.low};
    color: ${text.primary};
  }
  :focus {
    background: ${divider.middle};
    color: ${text.primary};
  }
`;
