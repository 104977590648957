import { TMasterNationality } from "@/api/entities/master-nationality.entity";
import { createLifeSupportDocumentApi, updateLifeSupportDocumentApi } from "@/api/services/main/regular-document/life-support-document";
import { TUpdateLifeSupportDocumentEmployeeInputDto } from "@/api/services/main/regular-document/life-support-document/dtos/update-life-support-document.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { InnerBox } from "@/components/elements/InnerBox";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { ButtonGroup } from "@/features/regular-document/components/life-support/ButtonGroup";
import { CompanySupporterForm } from "@/features/regular-document/components/life-support/CompanySupporterForm";
import { ForeignerTable } from "@/features/regular-document/components/life-support/ForeignerTable";
import { SupportStatus } from "@/features/regular-document/components/life-support/SupportStatus";
import { SupportStatusReadonly } from "@/features/regular-document/components/life-support/SupportStatusReadonly";
import { lifeSupportDraftSchema, lifeSupportSchema } from "@/features/regular-document/schema/lifeSupportSchema";
import { TLifeSupportForm } from "@/features/regular-document/types/lifeSupportForm";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { FCC, TUpdaterDto } from "@/types/common";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { isEmpty, omit } from "@/utils/object";
import { getFormValues } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

type TRegularPresentLifeSupportFormProps = {
  documentStepId?: number;
  previousValues: Partial<TLifeSupportForm>;
  isDraft?: boolean;
  resourceData: Partial<{
    year: number;
    quarter: number;
    employees: (TUpdateLifeSupportDocumentEmployeeInputDto & { employeeNationality?: TMasterNationality | null })[];
  }>;
  updater: TUpdaterDto | undefined;
  readonly?: boolean;
  isEditable?: boolean;
  setIsEditable?: (v: boolean) => void;
};

export const RegularPresentLifeSupportForm: FCC<TRegularPresentLifeSupportFormProps> = ({
  documentStepId,
  previousValues,
  isDraft: isPageDraft,
  resourceData,
  updater,
  readonly,
  isEditable,
  setIsEditable,
}: TRegularPresentLifeSupportFormProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const { pathname } = useLocation();

  const methods = useForm<TLifeSupportForm>({
    defaultValues: previousValues,
    resolver: readonly || !isEditable ? undefined : yupResolver(lifeSupportSchema),
    mode: "onBlur",
  });

  const saveDataForm = async (data: TLifeSupportForm, isDraft: boolean) => {
    try {
      if (isEmpty(methods.formState.dirtyFields) && isPageDraft === isDraft) {
        navigate(AppRoutes.regular);
        return;
      }
      const payload = {
        ...data,
        year: toNumber(resourceData?.year),
        quarter: toNumber(resourceData?.quarter),
        employees:
          resourceData && resourceData?.employees && resourceData?.employees?.length > 0
            ? resourceData.employees.map((item) => omit(item, "employeeNationality"))
            : [],
        isDraft,
      };
      if (!documentStepId) return;
      if (updater) {
        const rs = await updateLifeSupportDocumentApi(documentStepId, payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createLifeSupportDocumentApi(documentStepId, payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      methods.unregister();
      navigate(`${AppRoutes.regularPresent}#life_support_section`);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_LIFE_SUPPORT_DOCUMENT] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TLifeSupportForm) => {
    if (readonly) {
      navigate(getDynamicRoute(AppRoutes.regularHistoryDetail, params));
      return;
    }
    await saveDataForm(data, false);
  };

  const saveDraft = async () => {
    const data = methods.getValues();
    try {
      const values = await getFormValues(data, lifeSupportDraftSchema);
      await saveDataForm(values as TLifeSupportForm, true);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const isPresent = pathname.startsWith(AppRoutes.regularPresentLifeSupport);

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={lifeSupportSchema}>
      <Box maxWidth={COMPANY_CONTAINER}>
        <TopPage
          title="支援実施状況に係る届出"
          backUrl={readonly && !isPresent ? getDynamicRoute(AppRoutes.regularHistoryDetail, params) : AppRoutes.regularPresent}
          isDraft={isPageDraft ?? false}
        />
        <MenuNavigate
          contents={[
            { title: "外国人名簿", content: <ForeignerTable employees={resourceData.employees ?? []} /> },
            { title: "支援実施状況", content: readonly || !isEditable ? <SupportStatusReadonly /> : <SupportStatus /> },
            { title: "届出担当者", content: <CompanySupporterForm readonly={readonly || !isEditable} /> },
          ]}
          contentAffix={
            !readonly ? (
              <ButtonGroup
                saveDraft={saveDraft}
                disabled={resourceData.employees?.length === 0}
                readonly={readonly}
                isEditable={!!isEditable}
                setEditable={(v) => setIsEditable?.(v)}
              />
            ) : (
              <InnerBox bordered p={3} boxShadow={elevation.lv2}>
                <Stack direction="row" gap={3} justifyContent="center">
                  <Button variant="primary" size="lg" component={Link} to={AppRoutes.regularPresent}>
                    本届出のトップに戻る
                  </Button>
                </Stack>
              </InnerBox>
            )
          }
        />
      </Box>
    </Form>
  );
};
