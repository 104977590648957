import { useEmployeeList } from "@/api/services/main/employee";
import { EMPLOYEE_FILTER } from "@/features/employee/constants/employeeFilter";
import { toNumber } from "@/utils/number";

type UseGetWorkingAndLeftEmployeeQuantityReturn = {
  quantity: number;
};
export const useGetWorkingAndLeftEmployeeQuantity = (): UseGetWorkingAndLeftEmployeeQuantityReturn => {
  const { data } = useEmployeeList({
    filter: EMPLOYEE_FILTER.WORKING_AND_LEFT,
  });
  return {
    quantity: toNumber(data?.pagination.total),
  };
};
