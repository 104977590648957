import { IconCheck } from "@/assets/icons";
import { background, divider, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { Box, css, Grid, Stack, styled, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

type TStepperItem = { title: string; description: string; content?: ReactNode };

type TStepperProps = {
  items: TStepperItem[];
  currentStep: number;
};

export const Stepper: FC<TStepperProps> = ({ items, currentStep }) => {
  return (
    <>
      {items.map(({ title, description, content }, index, self) => {
        const isFinished = index + 1 <= currentStep;
        const isWaiting = index + 1 >= currentStep;
        const isCurrent = index + 1 === currentStep;
        const isFinal = index === self.length - 1;

        return (
          <Grid container key={index}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center">
                <CircleBox active={isFinished}>{isFinished && !isCurrent ? <IconCheck fontSize={24} /> : index + 1}</CircleBox>
                <Typography variant="head20Semi" color={isWaiting ? text.primary : text.secondary}>
                  {title}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="stretch">
                <StepLine active={isFinished && !isCurrent} hidden={isFinal} />
                <Stack flex={1} pb={isFinal ? 0 : "38px"} gap={1}>
                  <Typography color={isWaiting ? text.primary : text.secondary}>{description}</Typography>
                  {content}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const options = { shouldForwardProp: (propName: string) => !["active", "hidden"].includes(propName) };

const CircleActiveBox = css`
  background-color: ${background.main};
  color: ${text.white};
  border: none;
`;

const CircleBox = styled(Box, options)<{ active: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${text.tertiary};
  color: ${text.secondary};
  ${css(typography.sub16Semi)}
  ${({ active }) => active && CircleActiveBox}
`;

const StepLineActive = css`
  &::before {
    background-color: ${background.main};
    border: none;
  }
`;

const StepLine = styled(Box, options)<{ active: boolean; hidden: boolean }>`
  width: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;

  &::before {
    content: "";
    border: 1px solid ${divider.high};
    width: 8px;
    height: 100%;
    border-radius: 4px;
    background-color: ${divider.low};
    ${({ hidden }) =>
      hidden &&
      css`
        content: unset;
      `}
  }

  ${({ active }) => active && StepLineActive}
`;
