import { INVALID_NOT_CONTAINS, INVALID_NUMBER, INVALID_NUMBER_MAX } from "@/constants/invalids";
import { PATTERN } from "@/constants/pattern";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { toNumber } from "@/utils/number";
import { replaceStr } from "@/utils/string";
import { validator } from "@/utils/validator";

export const payrollRecordSchema = validator.object().shape({
  month: validator.string().trim().emptyToNull().label(PLAIN_TEXT.month).required(),
  workingDayCount: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .matches(PATTERN.NUMBER_ONLY, INVALID_NUMBER)
    .test("maxValue", INVALID_NUMBER_MAX, function (value, ctx) {
      if (!value) return true;
      if (!toNumber(value)) return true;
      if (toNumber(value) > 31)
        return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_NUMBER_MAX, { path: label, max: 31 }) });
      return true;
    })
    .label(PLAIN_TEXT.workingDay)
    .required(),
  totalAmountPaid: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .matches(PATTERN.NUMBER_ONLY, INVALID_NUMBER)
    .label(PLAIN_TEXT.totalAmountPaid)
    .required(),
  netAmountPaid: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .matches(PATTERN.NUMBER_ONLY, INVALID_NUMBER)
    .label(PLAIN_TEXT.netAmountPaid)
    .required(),
  legalDeductionAmount: validator
    .string()
    .trim()
    .emptyToNull()
    .autoTransformToHalfWidth()
    .matches(PATTERN.NUMBER_ONLY, INVALID_NUMBER)
    .label(PLAIN_TEXT.legalDeductionAmount)
    .required(),
  recordId: validator.string().trim().emptyToNull().required(),
});

export const payrollSchema = validator.object().shape({
  no: validator.string().trim().emptyToNull().label(PLAIN_TEXT.numberOrder).required(),
  employeeFullName: validator.string().trim().emptyToNull().label(PLAIN_TEXT.employeeFullName).required(),
  employeeNationalityId: validator.string().isSelection().trim().emptyToNull().label(PLAIN_TEXT.employeeNationality).notRequired(),
  employeeBirthday: validator.string().isSelection().trim().emptyToNull().label(PLAIN_TEXT.employeeBirthday).notRequired(),
  employeeGender: validator.string().isSelection().trim().emptyToNull().label(PLAIN_TEXT.employeeGender).notRequired(),
  employeeResidenceCode: validator.string().trim().emptyToNull().label(PLAIN_TEXT.employeeResidenceCode).notRequired(),
  employeeZipCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().zipCode().label(PLAIN_TEXT.employeeZipCode).notRequired(),
  employeeAddress: validator.string().trim().label(PLAIN_TEXT.employeeAddress).notRequired(),

  isChangeWorkingLocation: validator
    .string()
    .isSelection()
    .trim()
    .oneOf(
      [PLAIN_TEXT.change, PLAIN_TEXT.noChange],
      replaceStr(INVALID_NOT_CONTAINS, { includes: [PLAIN_TEXT.change, PLAIN_TEXT.noChange].join(", ") }),
    )
    .label(PLAIN_TEXT.isChangeWorkingLocation)
    .required(),
  isChangeDispatchPlace: validator
    .string()
    .isSelection()
    .trim()
    .oneOf(
      [PLAIN_TEXT.change, PLAIN_TEXT.noChange],
      replaceStr(INVALID_NOT_CONTAINS, { includes: [PLAIN_TEXT.change, PLAIN_TEXT.noChange].join(", ") }),
    )
    .label(PLAIN_TEXT.isChangeDispatchPlace)
    .required(),
  isChangeWorkingDetail: validator
    .string()
    .isSelection()
    .trim()
    .oneOf(
      [PLAIN_TEXT.change, PLAIN_TEXT.noChange],
      replaceStr(INVALID_NOT_CONTAINS, { includes: [PLAIN_TEXT.change, PLAIN_TEXT.noChange].join(", ") }),
    )
    .label(PLAIN_TEXT.isChangeWorkingDetail)
    .required(),
  hasComparisonEmployee: validator
    .string()
    .isSelection()
    .trim()
    .oneOf([PLAIN_TEXT.yes, PLAIN_TEXT.no], replaceStr(INVALID_NOT_CONTAINS, { includes: [PLAIN_TEXT.yes, PLAIN_TEXT.no].join(", ") }))
    .label(PLAIN_TEXT.hasComparisonEmployee)
    .required(),

  records: validator.array().of(payrollRecordSchema).required(),
});
