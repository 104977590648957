import { useAnytimeDocument } from "@/api/services/main/any-time";
import { CONVERT_STATUS, DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { useMemo } from "react";

type useCheckAnytimeDocumentReturn = {
  completed: boolean;
  finished: boolean;
  failed: boolean;
};

export const useCheckAnytimeDocument = (): useCheckAnytimeDocumentReturn => {
  const { data: documentResult } = useAnytimeDocument();
  const documentData = documentResult?.data;

  const completed = useMemo(() => {
    const checkKeys = [DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT, DOCUMENT_STEP_KEYS.ANY_TIME_UPLOAD_DOCUMENT];
    if (!documentData) return true;
    return documentData.steps.every(({ masterDocumentStep, status }) => {
      if (!checkKeys.includes(masterDocumentStep.stepKey)) return true;
      return status === DOCUMENT_STATUS.COMPLETE || status === DOCUMENT_STATUS.CONVERT_COMPLETE;
    });
  }, [documentData]);

  const finished = useMemo(() => {
    const checkKeys = [DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT];
    if (!documentData) return true;
    return documentData?.steps.every(({ masterDocumentStep, status, convertRequests }) => {
      if (!checkKeys.includes(masterDocumentStep.stepKey)) return true;
      if ([DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT].includes(masterDocumentStep.stepKey)) {
        const latestConvertRequest = convertRequests?.at(-1);
        if (latestConvertRequest?.status !== CONVERT_STATUS.COMPLETE) return false;
        if ((DOCUMENT_STATUS.COMPLETE || status === DOCUMENT_STATUS.CONVERT_COMPLETE) && latestConvertRequest?.isDownloaded) return true;
        return false;
      }
      return status === DOCUMENT_STATUS.COMPLETE || status === DOCUMENT_STATUS.CONVERT_COMPLETE;
    });
  }, [documentData]);

  const failed = useMemo(() => {
    const checkKeys = [DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT];
    if (!documentData) return true;
    return documentData?.steps.some(({ masterDocumentStep, status }) => {
      if (checkKeys.includes(masterDocumentStep.stepKey)) {
        return status === DOCUMENT_STATUS.ERROR;
      }
      return false;
    });
  }, [documentData]);

  return {
    completed,
    finished,
    failed,
  };
};
