import { useRegularDocumentHistoryDetail } from "@/api/services/main/regular-document";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { Document } from "@/features/regular-document/components/documents";
import { useGetTargetQuarter } from "@/features/regular-document/hooks/useGetTargetQuarter";
import { AppRoutes } from "@/routes/config";
import { button } from "@/theme/colors";
import { Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

type TRegularReportHistoryDetailPageProps = {};

export const RegularReportHistoryDetailPage: FC<TRegularReportHistoryDetailPageProps> = () => {
  const { year, quarter } = useParams();
  const { data: documentResult, isLoading } = useRegularDocumentHistoryDetail(year, quarter);
  const documentData = documentResult?.data;

  const { targetQuarter } = useGetTargetQuarter();

  const renderDocs = useMemo(() => {
    if (!documentData) return null;
    documentData.regularDocuments.sort((a, b) => a.masterDocument.documentOrder - b.masterDocument.documentOrder);
    documentData.regularDocuments.forEach((document) =>
      document.steps.sort((a, b) => a.masterDocumentStep.stepOrder - b.masterDocumentStep.stepOrder),
    );
    return documentData.regularDocuments.map((document) => (
      <Document key={document.id} masterData={document.masterDocument} document={document} readonly />
    ));
  }, [documentData]);

  if (!documentData || isLoading) return <LoadingPage />;

  return (
    <Stack>
      <TopPage title="過去の定期届出" subTitle="過去の資料を確認・ダウンロードできます。" backUrl={AppRoutes.regularHistory} />
      <InnerBox padding={3}>
        <Stack gap={3}>
          <Typography variant="head20Bold" color={button.pressed}>
            {targetQuarter}
          </Typography>
          {renderDocs}
        </Stack>
      </InnerBox>
    </Stack>
  );
};
