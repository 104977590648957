import { IconPlusCircle } from "@/assets/icons/IconPlusCircle";
import { background, button, divider, green, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { Box, CircularProgress, LinearProgress, Typography, styled } from "@mui/material";
import { MRT_RowData, MRT_TableInstance, MaterialReactTable } from "material-react-table";

export type TTableExtendOptions = { addNewRow?: () => void };

type TTableProps<T extends MRT_RowData> = {
  addBtnText?: string;
  table: MRT_TableInstance<T> & TTableExtendOptions;
  isLoading?: boolean;
  isFetching?: boolean;
  maxHeight?: number;
  addNewRow?: () => void;
  id?: string;
};

export const Table = <T extends MRT_RowData>({ addBtnText, table, isLoading, isFetching, maxHeight, id }: TTableProps<T>) => {
  const { addNewRow } = table;
  const hasFnAddRow = !!addNewRow;
  return (
    <StyledTable id={id} tableMaxHeight={maxHeight} pb={hasFnAddRow ? 3.5 : 0}>
      <MaterialReactTable table={table} />
      {table.getRowCount() === 0 && (
        <CenterBox>
          <Typography color={text.tertiary} fontWeight={700}>
            データがありません。
          </Typography>
        </CenterBox>
      )}
      {isLoading && (
        <CenterBox>
          <CircularProgress sx={{ color: text.tertiary }} size="24px" />
        </CenterBox>
      )}
      {isFetching && !isLoading && <LinearProgress sx={{ height: 3 }} color="inherit" />}
      {hasFnAddRow && (
        <AddRowButton onClick={addNewRow}>
          <IconPlusCircle />
          <Typography variant="cap12">{addBtnText ?? "行を追加する"}</Typography>
        </AddRowButton>
      )}
    </StyledTable>
  );
};

const options = { shouldForwardProp: (propName: string) => !["tableMaxHeight"].includes(propName) };

const StyledTable = styled(Box, options)<{ tableMaxHeight?: number }>`
  position: relative;
  border-radius: ${rounded.sm};
  overflow: hidden;
  border: 1px solid ${divider.middle};
  background-color: ${background.white};
  * {
    box-shadow: none;
  }
  .MuiTableContainer-root {
    max-height: ${({ tableMaxHeight }) => `${tableMaxHeight ? `${tableMaxHeight}px` : "unset"}`};
  }
  .MuiToolbar-dense {
    display: none;
  }
  .MuiTableCell-head {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .Mui-TableHeadCell-Content-Labels {
    > span {
      margin-left: 4px;
      img {
        opacity: 0.2;
      }
      .MuiBadge-badge {
        display: none;
      }
    }
  }
  .MuiTableCell-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  // Default
  .MuiTableRow-root {
    background-color: ${background.white};
    box-shadow: none;
    .MuiTableCell-root {
      padding: ${spacing.xxs} ${spacing.sm};
      border-color: ${divider.middle};
      white-space: nowrap;
      opacity: 1;

      & .MuiCheckbox-root {
        margin-left: 10px;
      }
    }
    &:not(.MuiTableRow-head):last-child {
      .MuiTableCell-root {
        border-bottom: 0;
      }
    }
  }
  // Hover
  .MuiTableRow-root:not(.MuiTableRow-head):hover {
    &,
    td {
      background-color: ${background.white};
      &::after {
        background-color: ${background.primary};
      }
    }
  }
  // Click/Pressed
  .Mui-selected {
    background-color: ${background.white} !important;
    td::after {
      background: ${background.secondary}!important;
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiPaper-root > .MuiBox-root {
    display: none;
  }

  .MuiCheckbox-root {
    color: ${button.primary}!important;
    height: 24px;
    width: 24px;
    padding: 0;
  }

  .MuiTableCell-root {
    padding: 4px 8px !important;
  }

  // Disabled Row
  .MuiTableCell-root.disabled {
    opacity: 1;
    background: ${background.disable}!important;
    pointer-events: none;
  }

  // added row
  .MuiTableCell-root.added {
    background: ${green[99]}!important;
    &::after {
      background: unset !important;
    }
  }

  // Failed row
  .MuiTableCell-root.failed {
    background: ${background.error}!important;
    &::after {
      background: unset !important;
    }
  }

  // Failed row
  .MuiTableCell-root.clickable {
    cursor: pointer;
  }

  .MuiTypography-root {
    padding: 8px;
  }
`;

const CenterBox = styled(Box)`
  position: absolute;
  margin-top: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: ${background.white};
  text-align: center;
  padding: 20px;
`;

const AddRowButton = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  padding: 4px 16px;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;
