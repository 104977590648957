import { fetchMaintenanceInfoApi } from "@/api/services/global/maintenance";
import { LoadingScreen } from "@/components/elements/Loading/Loading";
import { MaintenanceCard } from "@/features/maintenance/components/MaintenanceCard";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { MAINTENANCE_MODE } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { Box, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

type TMaintenancePageProps = {};

export const MaintenancePage: FC<TMaintenancePageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMaintenanceInfo = async () => {
    try {
      setIsLoading(true);
      const rs = await fetchMaintenanceInfoApi();
      const { data } = rs;
      if (data?.maintenanceMode && data?.maintenanceMode !== MAINTENANCE_MODE.SYSTEM_NORMAL_OPERATION) {
        if (data?.modeStartDateTime && dayjs().isBefore(dayjs(data.modeStartDateTime))) {
          window.location.replace(AppRoutes.home);
        }
        if (data?.modeEndDateTime && dayjs().isAfter(dayjs(data.modeEndDateTime))) {
          window.location.replace(AppRoutes.home);
        }
        if (data?.maintenanceMode === MAINTENANCE_MODE.MAINTENANCE_READABLE) {
          window.location.replace(AppRoutes.home);
        }
      } else {
        window.location.replace(AppRoutes.home);
      }
      return data;
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaintenanceInfo();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      getMaintenanceInfo();
    }, 30000); // 30s to recheck
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (isLoading) return <LoadingScreen />;
  return (
    <Box width="100%" display="flex" justifyContent="center" mt={13}>
      <Stack gap={7} alignItems="center">
        <Typography fontSize={50} fontWeight={600}>
          irohana
        </Typography>
        <Stack maxWidth={400} gap={6} alignItems="center">
          <Stack>
            <Typography variant="body14" color={text.error}>
              大変申し訳ございませんが、現在メンテナンス中です。
            </Typography>
            <Typography variant="body14" color={text.error}>
              お手数おかけしますが、時間を置いて再度アクセスください。
            </Typography>
          </Stack>
          <MaintenanceCard />
        </Stack>
      </Stack>
    </Box>
  );
};
