import { useEnum } from "@/api/services/global/enum";
import { useGetLanguageTestList, useGetMajorList, useGetRegionList, useGetSkillTestList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Select } from "@/components/elements/Select";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { text } from "@/theme/colors";
import { YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Divider, Grid, Accordion as MuiAccordion, styled, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TEmployeeCareerRelatedActitivyFormProps = {
  readonly: boolean;
};

export const EmployeeCareerRelatedActitivyForm: FC<TEmployeeCareerRelatedActitivyFormProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control } = methods;

  const { isLoading: isLoadingMajor, majorOptions, getJobTypeListOptionByMajor } = useGetMajorList();

  const { isLoading: isLoadingSkillTest, skillTestOptions } = useGetSkillTestList();
  const { isLoading: isLoadingLanguageTest, languageTestOptions } = useGetLanguageTestList();

  const { nationalityOptions, isLoading: isLoadingNationality } = useGetRegionList();

  const relatedActivityMajorIdWatchField = useWatch({ control, name: "relatedActivityMajorId" });
  const relatedActivityHasCollectionForSpecificSkillOneWatchField = useWatch({ control, name: "relatedActivityHasCollectionForSpecificSkillOne" });
  const relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFeesWatchField = useWatch({
    control,
    name: "relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees",
  });

  const { data: yesNoAnswerOptions } = useEnum("EYesNoAnswer");
  const { data: yesNoAndNotApplicableAnswerOptions } = useEnum("EYesNoAndNotApplicableAnswer");

  return (
    <InnerBox title="活動に関する事項（STEP2-3)" bordered p={3} required>
      <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
        <AccordionSummaryWrapper
          sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
          expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
        >
          省略項目を表示
        </AccordionSummaryWrapper>
        <AccordionDetails>
          <Divider sx={{ mb: 5 }} />
          <Typography component="h2" variant="sub16Semi" mb="16px">
            良好に修了した技能実習2号
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityCompletionProof">
                <Select enumName="EEmployeeCareerCompletionProof" readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityMajorId">
                <Select loading={isLoadingMajor} options={majorOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityJobTypeId">
                <Select
                  loading={isLoadingMajor}
                  options={relatedActivityMajorIdWatchField ? getJobTypeListOptionByMajor(relatedActivityMajorIdWatchField) : []}
                  readonly
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="sub16Bold" mt="16px">
                技能水準
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivitySkillCertificationOne">
                <Select enumName="EEmployeeCareerSkillCertificationOne" readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivitySkillExamOneId">
                <Select loading={isLoadingSkillTest} options={skillTestOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivitySkillExamLocationOne">
                <Select enumName="EEmployeeCareerLocation" readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivitySkillExamOverseaOneNationalityId">
                <Select loading={isLoadingNationality} options={nationalityOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivitySkillOtherEvaluation">
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <Typography component="h2" variant="sub16Bold" mt="16px">
                日本語能力
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityJapaneseCertificationTwo">
                <Select enumName="EEmployeeCareerJapaneseCertification" readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityJapaneseExamTwoId">
                <Select loading={isLoadingLanguageTest} options={languageTestOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityJapaneseExamLocationTwo">
                <Select enumName="EEmployeeCareerLocation" readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityJapaneseExamOverseaTwoNationalityId">
                <Select loading={isLoadingNationality} options={nationalityOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityJapaneseCertificationThree">
                <Select enumName="EEmployeeCareerJapaneseCertification" readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityJapaneseExamThreeId">
                <Select options={languageTestOptions} loading={isLoadingLanguageTest} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityJapaneseExamLocationThree">
                <Select enumName="EEmployeeCareerLocation" readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityJapaneseExamOverseaThreeNationalityId">
                <Select loading={isLoadingNationality} options={nationalityOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivitySpecificSkillTransitionPeriod" breakLineLabel={false}>
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityHasCollectionForSpecificSkillOne">
                <RadioGroup
                  direction="row"
                  options={yesNoAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_ANSWER.YES) {
                      methods.setValue("relatedActivityCollectionOrManagementAgencyName", "");
                      methods.setValue("relatedActivityCollectionAmount", "");
                    }
                  }}
                />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityCollectionOrManagementAgencyName">
                <Input readonly={relatedActivityHasCollectionForSpecificSkillOneWatchField === YES_NO_ANSWER.NO || readonly} />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityCollectionAmount">
                <Input readonly={relatedActivityHasCollectionForSpecificSkillOneWatchField === YES_NO_ANSWER.NO || readonly} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFees">
                <RadioGroup
                  direction="row"
                  options={yesNoAndNotApplicableAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_AND_NOT_APPLICABLE_ANSWER.YES) {
                      methods.setValue("relatedActivityForeignAgencyName", "");
                      methods.setValue("relatedActivityForeignAgencyFee", "");
                    }
                  }}
                />
                <Typography color="#D11F4E" variant="cap12" mt="4px">
                  当該費用の支払いがある場合に入力
                </Typography>
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityForeignAgencyName">
                <Input
                  readonly={
                    !(relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFeesWatchField === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES) || readonly
                  }
                />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="relatedActivityForeignAgencyFee">
                <Input
                  readonly={
                    !(relatedActivityHasUnderstandingAndAgreementOnForeignAgencyFeesWatchField === YES_NO_AND_NOT_APPLICABLE_ANSWER.YES) || readonly
                  }
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityHasComplianceWithProceduresInHomeCountry">
                <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                <Typography color="#D11F4E" variant="cap12" mt="4px">
                  当該手続きが定められている場合に入力
                </Typography>
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityWhetherRegularPayments">
                <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                <Typography color="#D11F4E" variant="cap12" mt="4px">
                  当該費用の負担がある場合に入力
                </Typography>
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityHasEffortToTransferSkillsToHomeCountry">
                <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
              </FormField>
              <Typography color="#D11F4E" variant="cap12" mt="4px">
                技能実習の在留資格をもって在留していたことがある場合であって、「特定技能2号」での在留を希望する場合に入力
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="relatedActivityHasComplianceWithIndustrySpecificCriteria">
                <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                <Typography color="#D11F4E" variant="cap12" mt="4px">
                  当該基準が定められている場合に入力
                </Typography>
              </FormField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </MuiAccordionWrapper>
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &::before {
    display: none;
  }

  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
