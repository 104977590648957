import { useCompanyInfo } from "@/api/services/main/company";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { CompanyForm } from "@/features/company/components/CompanyForm";
import { convertCompanyResToFormDataDto } from "@/features/company/utils/convertResToFormData";

export const CompanyEditPage = () => {
  const { data, isLoading } = useCompanyInfo();

  if (!data || isLoading) return <LoadingPage />;
  return <CompanyForm previousValues={convertCompanyResToFormDataDto(data.data)} />;
};
