import { employeeMenu } from "@/features/employee/constants/employeeMenu";
import { TEmployeeMenu } from "@/features/employee/types/employeeMenu";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC, useMemo } from "react";
import { Link, Outlet, matchRoutes, useLocation, useParams } from "react-router-dom";

type TEmployeeDetailLayoutProps = {};

export const EmployeeDetailLayout: FC<TEmployeeDetailLayoutProps> = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const renderRoutes = (menu: TEmployeeMenu[]) => {
    return menu.map(({ title, url, children }) => {
      const isActive = [url, children?.map(({ url }) => url) ?? []].flat().some((url) => Boolean(matchRoutes([{ path: url }], pathname)));

      return (
        <Link key={url} className={isActive ? "active" : ""} to={getDynamicRoute(url, { id })}>
          {title}
        </Link>
      );
    });
  };

  const activeRoute = useMemo(() => {
    return employeeMenu.find(({ url, children }) => {
      return [url, children?.map(({ url }) => url) ?? []].flat().some((url) => Boolean(matchRoutes([{ path: url }], pathname)));
    });
  }, [pathname]);

  return (
    <Stack gap={2.5}>
      <Stack direction="row" gap={10}>
        {renderRoutes(employeeMenu)}
      </Stack>
      {activeRoute?.children && (
        <Stack direction="row" gap={10}>
          {renderRoutes(activeRoute.children)}
        </Stack>
      )}
      <Outlet />
    </Stack>
  );
};
