import { useGetResidenceStatusList } from "@/api/services/global/master";
import { UploadResidenceCardFileBtn } from "@/components/commons/UploadResidenceCardFileBtn";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { toNumber } from "@/utils/number";
import { Grid, Stack } from "@mui/material";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const ResidentForm = () => {
  const { control, setValue } = useFormContext<TEmployeeForm>();

  const residenceStatusIdWatchField = useWatch({
    control,
    name: "residenceStatusId",
  });

  const firstNameWatchField = useWatch({ control, name: "firstName" });
  const residenceExpiryDateWatchField = useWatch({ control, name: "residenceExpiryDate" });

  const { data: residenceStatus, isLoading: isLoadingResidenceStatus, residenceStatusOptions } = useGetResidenceStatusList();

  const residencePeriodOptions = useMemo(() => {
    const founded = residenceStatus ? residenceStatus.find((item) => toNumber(item.id) === toNumber(residenceStatusIdWatchField)) : undefined;
    if (founded) return founded.residencePeriods.map((item) => ({ label: item.residencePeriod ?? "", value: item.id, hidden: item.isHidden }));
    else return [];
  }, [residenceStatus, residenceStatusIdWatchField]);

  return (
    <Stack gap={1}>
      <InnerBox title="在留カード情報">
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={4}>
            <FormField control={control} name="residenceCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="residenceStatusId">
              <Select
                value={residenceStatusIdWatchField}
                loading={isLoadingResidenceStatus}
                options={residenceStatusOptions}
                onChange={async (value) => {
                  setValue("residenceStatusId", value as string);
                  setValue("residencePeriodId", null);
                  setValue("contractSpecificBusinessClassificationOneId", null);
                  setValue("contractSpecificBusinessClassificationTwoId", null);
                  setValue("contractSpecificBusinessClassificationThreeId", null);
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="residencePeriodId">
              <Select options={residencePeriodOptions} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="residenceExpiryDate">
              <InputDatePicker />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="residenceCardFrontFile">
              <UploadResidenceCardFileBtn
                residenceExpiryDate={residenceExpiryDateWatchField ? residenceExpiryDateWatchField.format("YYYYMMDD") : null}
                employeeFirstName={firstNameWatchField}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="residenceCardBackFile">
              <UploadResidenceCardFileBtn
                residenceExpiryDate={residenceExpiryDateWatchField ? residenceExpiryDateWatchField.format("YYYYMMDD") : null}
                employeeFirstName={firstNameWatchField}
              />
            </FormField>
          </Grid>
        </Grid>
      </InnerBox>
    </Stack>
  );
};
