import { IconInfo } from "@/assets/icons";
import { Accordion } from "@/components/elements/Accordion";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { useHideElm } from "@/hooks/useHideElm";
import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";

export const FormDescription: FC = () => {
  const { visible, onClose } = useHideElm("activity-hidden");

  if (!visible) return null;

  return (
    <Box>
      <Accordion title="フォームの説明" icon={<IconInfo fontSize={24} />} defaultExpanded closeLabel="次回から非表示" onChange={onClose}>
        <Stack gap={1.25}>
          <Typography variant="body14">前回入力した内容が反映されている場合は、フォームの内容が青字で表示されます。</Typography>
          <Typography variant="body14">例：</Typography>
          <Stack maxWidth={550} flexDirection="row" alignItems="center" gap={2} padding={2} border="1px solid #D9D9D9">
            <FormField label="前月の値をそのまま使う場合">
              <Input value="8" previousValue="8" endAdornment="人" nonInteractive={true} clearable={false} />
            </FormField>
            ➡
            <FormField label="新しく入力した場合">
              <Input value="8" nonInteractive={true} />
            </FormField>
          </Stack>
        </Stack>
      </Accordion>
    </Box>
  );
};
