import { validator } from "@/utils/validator";

export const companyAccountSchema = (previousPassword?: string) =>
  validator.object().shape({
    accordImmiId: validator.string().trim().halfWidthOnly().emptyToNull().autoTransformToHalfWidth().label("利用者ID").min(1).max(20).required(),
    accordImmiPassword: validator
      .string()
      .trim()
      .autoTransformToHalfWidth()
      .halfWidthOnly()
      .emptyToNull()
      .label("パスワード")
      .min(8)
      .max(32)
      .when("accordImmiId", (_, schema) => {
        if (previousPassword) return schema.notRequired();
        return schema.required();
      }),
    accordImmiFile: validator.mixed().isExtendFile().label("出入国在留管理庁電子届出システム").notRequired(),
    rasImmiFullName: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("申請取次者名").notRequired(),
    rasImmiId: validator.string().trim().autoTransformToHalfWidth().halfWidthOnly().emptyToNull().label("認証ID").min(1).max(20).notRequired(),
  });
