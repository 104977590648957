import { InnerBox } from "@/components/elements/InnerBox";
import { IrohanaAsking } from "@/features/regular-document/components/accept-activity/paycheck/IrohanaAsking";
import { PaycheckChosenType } from "@/features/regular-document/components/accept-activity/paycheck/PaycheckChosenType";
import { PayrollTable } from "@/features/regular-document/components/accept-activity/paycheck/PayrollTable";
import { PayrollUploading } from "@/features/regular-document/components/accept-activity/paycheck/PayrollUploading";

import { Stack } from "@mui/material";

export const SalaryReflection = () => {
  return (
    <Stack gap={4}>
      <InnerBox title="賃金台帳の反映方法" p={3}>
        <Stack gap={5}>
          <PaycheckChosenType />
          <IrohanaAsking />
          <PayrollUploading />
        </Stack>
      </InnerBox>
      <PayrollTable />
    </Stack>
  );
};
