import { elevation, rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Accordion as MuiAccordion, AccordionProps, AccordionDetails, AccordionSummary, styled, Typography } from "@mui/material";
import { FC, ReactElement } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { text } from "@/theme/colors";

export type TAccordionProps = {
  icon?: ReactElement;
  title: string;
  closeLabel?: string;
} & AccordionProps;

type TCustomExpandIconProps = {
  closeLabel?: string;
};

export const CustomExpandIcon: FC<TCustomExpandIconProps> = ({ closeLabel }) => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
          ".MuiSvgIcon-root": {
            transform: "rotate(0)",
            transition: "none",
          },
        },
        ".collapsIconWrapper, .Mui-expanded & > .expandIconWrapper": {
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          columnGap: "4px",
        },
      }}
    >
      <div className="collapsIconWrapper">
        <StyledButton>開ける</StyledButton>
        <ExpandMoreIcon sx={{ color: text.primary }} />
      </div>
      <div className="expandIconWrapper">
        <StyledButton>{closeLabel || "閉じる"}</StyledButton>
        <ExpandMoreIcon sx={{ color: text.primary }} />
      </div>
    </Box>
  );
};

export const Accordion: FCC<TAccordionProps> = ({ icon, title, children, closeLabel, ...rest }) => {
  return (
    <StyledAccordion {...rest}>
      <AccordionSummary expandIcon={<CustomExpandIcon closeLabel={closeLabel} />}>
        <StyledTitle>
          {icon}
          <Typography variant="sub16Bold">{title}</Typography>
        </StyledTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(MuiAccordion)`
  box-shadow: ${elevation.lv2};
  padding: ${spacing.md};
  border-radius: ${rounded.sm};
  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    padding: 0;
    min-height: fit-content;
  }
  .MuiAccordionSummary-expandIconWrapper {
    transition: none;
  }
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: none;
    transition: none;
    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 auto;
  }
  .MuiAccordionDetails-root {
    padding: 10px 0 0;
  }
`;

const StyledTitle = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: bold;
`;

const StyledButton = styled(Box)`
  font-size: 14px;
  color: ${text.primary};
`;
