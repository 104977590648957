import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { CompanySupporterForm } from "@/features/anytime/components/anytime-detail/CompanySupporterForm";
import { EligibleForeignNationals } from "@/features/anytime/components/anytime-detail/EligibleForeignNationals";
import { SpecificSkillsAffiliation } from "@/features/anytime/components/anytime-detail/SpecificSkillsAffiliation";
import { ReasonForm } from "@/features/anytime/components/anytime-detail/support-contract/ReasonForm";
import { ESupportOutsourcingContractModificationType } from "@/features/anytime/constants/enums";
import { supportContractSchema } from "@/features/anytime/schema/supportContractSchema";
import { TSupportContractForm } from "@/features/anytime/types/supportContractForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

type TAnytimeSupportContractDetailPageProps = {
  defaultValues: TSupportContractForm;
};

export const AnytimeSupportContractDetailPage: FC<TAnytimeSupportContractDetailPageProps> = ({ defaultValues }) => {
  const { documentId } = useParams();
  const methods = useForm<TSupportContractForm>({
    defaultValues,
    resolver: yupResolver(supportContractSchema),
  });
  const modificationType = useWatch({ control: methods.control, name: "modificationType" });

  const onSubmit = () => {};

  const labelReasonForm = useMemo(() => {
    if (modificationType === ESupportOutsourcingContractModificationType.RENEW_CONTRACT) return "契約の締結変更";
    if (modificationType === ESupportOutsourcingContractModificationType.MODIFY_CONTRACT) return "契約の変更";
    if (modificationType === ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT) return "契約の終了";
    return null;
  }, [modificationType]);

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={supportContractSchema}>
      <TopPage title="支援委託契約に係る届出" backUrl={getDynamicRoute(AppRoutes.anytimeDocument, { id: documentId })} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <EligibleForeignNationals readonly /> },
          { title: "届出の事由", content: <ReasonForm readonly /> },
          labelReasonForm ? { title: labelReasonForm, content: <Box /> } : [],
          { title: "特定技能所属機関", content: <SpecificSkillsAffiliation readonly /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly /> },
        ].flat()}
      />
    </Form>
  );
};
