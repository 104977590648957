import { TAnyTimeDocumentStep } from "@/api/entities/any-time-document-step.entity";
import { TEmployeeRetirementDocumentStep } from "@/api/entities/employee-retirement-document-step.entity";
import { TRegularDocumentStep } from "@/api/entities/regularDocumentStep.entity";
import { useGovernmentOnlineSystemAccountInfo } from "@/api/services/main/company";
import { useDialog } from "@/hooks/useDialog";
import { DOCUMENT_STATUS } from "@/types/enum";
import { toNumber } from "@/utils/number";

type TUseConvertRequestProps = {
  stepData: TRegularDocumentStep | TAnyTimeDocumentStep | TEmployeeRetirementDocumentStep;
};

export const useConvertRequest = ({ stepData }: TUseConvertRequestProps) => {
  const { convertRequests } = stepData;
  const confirmRequestConvert = useDialog();
  const confirmNeedAccount = useDialog();
  const { data: companyAccountResult } = useGovernmentOnlineSystemAccountInfo();
  const companyAccountData = companyAccountResult?.data;

  const latestConvert = convertRequests?.sort((a, b) => toNumber(a.id) - toNumber(b.id)).at(-1);
  const isLatestConvertInProgress = latestConvert?.status === DOCUMENT_STATUS.IN_PROGRESS;
  const isLatestConvertComplete = latestConvert?.status === DOCUMENT_STATUS.COMPLETE;

  return {
    companyAccountData,
    isLatestConvertInProgress,
    confirmRequestConvert,
    confirmNeedAccount,
    latestConvert,
    isLatestConvertComplete,
  };
};
