import { EModifyContractTerminateReasonForCompany, EModifyContractTerminateReasonForEmployee } from "@/features/anytime/constants/enums";
import { EMeasure, EPlanToContinueActivity, ERetirementReasonType } from "@/features/retirement/constants/enums";
import { companySchema } from "@/features/retirement/schema/common/companySchema";
import { companySupporterSchema } from "@/features/retirement/schema/common/companySupporterSchema";
import { validator } from "@/utils/validator";
import { object } from "yup";

export const retirementAcceptingWorkerSchema = validator
  .object()
  .shape({
    reasonType: validator.string().trim().emptyToNull().required("「事由」を選択してください。"),

    // A
    changeContractCategoryType: validator
      .string()
      .isSelection()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("reasonType", {
        is: ERetirementReasonType.CONVENIENT_FOR_COMPANY,
        then: (schema) => schema.required("「事由の区分」を選択してください。"),
      }),
    changeContractCategoryContent: validator
      .string()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("changeContractCategoryType", {
        is: EModifyContractTerminateReasonForCompany.OTHER,
        then: (schema) => schema.required("「その他」を入力してください。"),
      }),
    changeContractDateOfOccurrence: validator.mixed().isDayjs().label("事由発生日").notRequired(),
    changeContractSummary: validator.string().emptyToNull().trim().emptyToNull().label("事由の概要").max(20).notRequired(),

    // B
    convenienceCategoryType: validator
      .string()
      .isSelection()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("reasonType", {
        is: ERetirementReasonType.CONVENIENT_FOR_EMPLOYEE,
        then: (schema) => schema.required("「事由の区分」を選択してください。"),
      }),
    convenienceCategoryContent: validator
      .string()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("convenienceCategoryType", {
        is: EModifyContractTerminateReasonForEmployee.OTHER,
        then: (schema) => schema.required("「その他」を入力してください。"),
      }),
    convenienceDateOfOccurrence: validator.mixed().isDayjs().label("事由発生日").notRequired(),
    convenienceSummary: validator.string().emptyToNull().trim().max(20).emptyToNull().label("事由の概要").notRequired(),

    currentSituation: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    planToContinueActivity: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    planToContinueActivityContent: validator
      .string()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("planToContinueActivity", {
        is: EPlanToContinueActivity.OTHER,
        then: (schema) => schema.required("「その他」を入力してください。"),
      }),
    measuresTakenType: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    measuresTakenContent: validator
      .string()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("measuresTakenType", {
        is: EMeasure.OTHER,
        then: (schema) => schema.required("「その他」を入力してください。"),
      }),

    specificCircumstances: validator.string().trim().emptyToNull().label("").notRequired(),
    specificCircumstanceContent: validator.string().trim().emptyToNull().label("").notRequired(),
    anyConsultation: validator.string().trim().emptyToNull().label("").notRequired(),
    anyConsultationContent: validator.string().trim().emptyToNull().label("").notRequired(),
    plansToChangeJob: validator.string().trim().emptyToNull().label("").notRequired(),
    plansToChangeJobContent: validator.string().trim().emptyToNull().label("").notRequired(),
    supportType: validator.string().trim().emptyToNull().label("").notRequired(),
    supportContent: validator.string().trim().emptyToNull().label("").notRequired(),
    reasonReturnHome: validator.string().trim().emptyToNull().label("").notRequired(),
    reasonReturnHomeContent: validator.string().trim().emptyToNull().label("").notRequired(),
    planDateReturn: validator.string().trim().emptyToNull().label("").notRequired(),
    planDateReturnContent: validator.string().trim().emptyToNull().label("").notRequired(),
    planToMove: validator.string().trim().emptyToNull().label("").notRequired(),
    planToMoveContent: validator.string().trim().emptyToNull().label("").notRequired(),

    telNumber: validator.string().trim().emptyToNull().numbersOnly().max(12).label("特定技能外国人の電話番号").notRequired(),
    contractName: validator
      .string()
      .trim()
      .emptyToNull()
      .label("連絡先の名称(電話番号がない場合)")
      .notRequired()
      .when("telNumber", {
        is: (value: string | null) => !value,
        then: (schema) => schema.required(),
      }),
    planedDateOfReturn: validator.mixed().isDayjs().label("復帰予定年月日").notRequired(),

    providedLegalProtection: validator.array().label("").notRequired(),

    employeeFullName: validator.string().trim().emptyToNull().notRequired(),
    employeeNickname: validator.string().trim().emptyToNull().notRequired(),
    employeeGender: validator.string().trim().emptyToNull().notRequired(),
    employeeNationalityId: validator.number().emptyToNull().notRequired(),
    employeeResidenceCardNumber: validator.string().trim().emptyToNull().notRequired(),
    birthday: validator.string().isSelection().trim().emptyToNull().notRequired(),
    zipCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().zipCode().notRequired(),
    fullAddress: validator.string().trim().emptyToNull().notRequired(),
    employeeTelNumber: validator.string().trim().emptyToNull().notRequired(),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);

export const retirementAcceptingWorkerDraftSchema = object()
  .shape({
    reasonType: validator.string().trim().emptyToNull().notRequired(),

    // A
    changeContractCategoryType: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    changeContractCategoryContent: validator
      .string()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("changeContractCategoryType", {
        is: "other_reason",
        then: (schema) => schema.notRequired(),
      }),
    changeContractDateOfOccurrence: validator.mixed().isDayjs().label("事由発生日").notRequired(),
    changeContractSummary: validator.string().emptyToNull().trim().max(20).emptyToNull().label("事由の概要").notRequired(),

    // B
    convenienceCategoryType: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    convenienceCategoryContent: validator
      .string()
      .trim()
      .emptyToNull()
      .label("")
      .notRequired()
      .when("changeContractCategoryType", {
        is: "other_reason",
        then: (schema) => schema.notRequired(),
      }),
    convenienceDateOfOccurrence: validator.mixed().isDayjs().label("事由発生日").notRequired(),
    convenienceSummary: validator.string().emptyToNull().trim().max(20).emptyToNull().label("事由の概要").notRequired(),

    currentSituation: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),

    planToContinueActivity: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    planToContinueActivityContent: validator.string().trim().emptyToNull().label("").notRequired(),
    measuresTakenType: validator.string().isSelection().trim().emptyToNull().label("").notRequired(),
    measuresTakenContent: validator.string().trim().emptyToNull().label("").notRequired(),

    specificCircumstances: validator.string().trim().emptyToNull().label("").notRequired(),
    specificCircumstanceContent: validator.string().trim().emptyToNull().label("").notRequired(),
    anyConsultation: validator.string().trim().emptyToNull().label("").notRequired(),
    anyConsultationContent: validator.string().trim().emptyToNull().label("").notRequired(),
    plansToChangeJob: validator.string().trim().emptyToNull().label("").notRequired(),
    plansToChangeJobContent: validator.string().trim().emptyToNull().label("").notRequired(),
    supportType: validator.string().trim().emptyToNull().label("").notRequired(),
    supportContent: validator.string().trim().emptyToNull().label("").notRequired(),
    reasonReturnHome: validator.string().trim().emptyToNull().label("").notRequired(),
    reasonReturnHomeContent: validator.string().trim().emptyToNull().label("").notRequired(),
    planDateReturn: validator.string().trim().emptyToNull().label("").notRequired(),
    planDateReturnContent: validator.string().trim().emptyToNull().label("").notRequired(),
    planToMove: validator.string().trim().emptyToNull().label("").notRequired(),
    planToMoveContent: validator.string().trim().emptyToNull().label("").notRequired(),

    telNumber: validator.string().trim().emptyToNull().numbersOnly().max(12).label("特定技能外国人の電話番号").notRequired(),
    contractName: validator.string().trim().emptyToNull().label("連絡先の名称(電話番号がない場合)").notRequired(),
    planedDateOfReturn: validator.mixed().isDayjs().label("復帰予定年月日").notRequired(),

    providedLegalProtection: validator.array().label("").notRequired(),

    employeeFullName: validator.string().trim().emptyToNull().notRequired(),
    employeeNickname: validator.string().trim().emptyToNull().notRequired(),
    employeeGender: validator.string().trim().emptyToNull().notRequired(),
    employeeNationalityId: validator.string().trim().emptyToNull().notRequired(),
    employeeResidenceCardNumber: validator.string().trim().emptyToNull().notRequired(),
    birthday: validator.string().isSelection().trim().emptyToNull().notRequired(),
    zipCode: validator.string().trim().emptyToNull().notRequired(),
    fullAddress: validator.string().trim().emptyToNull().notRequired(),
    employeeTelNumber: validator.string().trim().emptyToNull().notRequired(),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);
