import { TAnyTimeDocumentStep } from "@/api/entities/any-time-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { TAnyTimeDocumentOutputDto } from "@/api/services/main/any-time/dtos/get-any-time-document";
import { createConvertRequestApi, setDownloadedConvertRequestApi } from "@/api/services/main/convert-request";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { ConvertDocumentStatusTag } from "@/components/elements/ConvertDocumentStatusTag";
import { StepCard } from "@/components/elements/StepCard";
import { TypoLink } from "@/components/elements/TypoLink";
import { DATE_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { EAnyTimeDocumentType } from "@/features/anytime/constants/enums";
import { useConvertRequest } from "@/hooks/useConvertRequest";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { downloadFileFromPath } from "@/utils/export";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

type TAnytimeDocumentCreateStepProps = {
  document: TAnyTimeDocumentOutputDto;
  stepData: TAnyTimeDocumentStep;
  readonly?: boolean;
};

export const AnytimeDocumentCreateStep: FC<TAnytimeDocumentCreateStepProps> = ({ document, stepData, readonly = false }) => {
  const { id } = useParams();
  const { data: statusOptions } = useEnum("EDocumentStatus");
  const { data: convertErrors } = useEnum("EConvertError");
  const queryClient = useQueryClient();

  const editDocumentRoute = useMemo(() => {
    switch (document.anyTimeDocumentType) {
      case EAnyTimeDocumentType.EMPLOYMENT_CONTRACT_MODIFICATION:
        return getDynamicRoute(AppRoutes.anytimeEmploymentContractEdit, { documentId: id, id: stepData?.id });
      case EAnyTimeDocumentType.SUPPORT_PLAN_MODIFICATION:
        return getDynamicRoute(AppRoutes.anytimeSupportPlanEdit, { documentId: id, id: stepData?.id });
      case EAnyTimeDocumentType.SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION:
        return getDynamicRoute(AppRoutes.anytimeSupportContractEdit, { documentId: id, id: stepData?.id });
      default:
        return AppRoutes.anytime;
    }
  }, [document]);

  const detailDocumentRoute = useMemo(() => {
    switch (document.anyTimeDocumentType) {
      case EAnyTimeDocumentType.EMPLOYMENT_CONTRACT_MODIFICATION:
        return getDynamicRoute(AppRoutes.anytimeEmploymentContractDetail, { documentId: id, id: stepData?.id });
      case EAnyTimeDocumentType.SUPPORT_PLAN_MODIFICATION:
        return getDynamicRoute(AppRoutes.anytimeSupportPlanDetail, { documentId: id, id: stepData?.id });
      case EAnyTimeDocumentType.SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION:
        return getDynamicRoute(AppRoutes.anytimeSupportContractDetail, { documentId: id, id: stepData?.id });
      default:
        return AppRoutes.anytime;
    }
  }, [document]);

  const { isLatestConvertInProgress, confirmRequestConvert, confirmNeedAccount, companyAccountData, latestConvert, isLatestConvertComplete } =
    useConvertRequest({
      stepData,
    });

  const handleCreateConvertRequest = async () => {
    if (!stepData?.id) return;
    try {
      if (!companyAccountData) {
        await confirmNeedAccount.show();
      }
      const agree = await confirmRequestConvert.show();
      if (!agree) return;
      await createConvertRequestApi({ documentStepId: Number(stepData.id), documentType: document.masterDocument.documentType });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id] });
    } catch (error) {
      showError(error);
    }
  };

  const convertFilePaths = useMemo(() => {
    return [latestConvert?.filePath ?? []].flat();
  }, [latestConvert]);

  const handleDownloadFile = async () => {
    try {
      if (convertFilePaths && latestConvert && isLatestConvertComplete) {
        await Promise.all(convertFilePaths.map((filePath) => downloadFileFromPath(filePath)));
        if (!latestConvert.isDownloaded) {
          await setDownloadedConvertRequestApi(latestConvert.id);
          await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
          await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id] });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <StepCard
        title="ステップ1"
        subTitle="届出の作成"
        buttons={
          readonly ? (
            <Button variant="primary" size="sm" component={Link} to={detailDocumentRoute}>
              確認
            </Button>
          ) : (
            <Stack flexDirection="row" alignItems="center" gap="10px">
              <Button
                disabled={stepData.status !== DOCUMENT_STATUS.COMPLETE || isLatestConvertInProgress}
                variant="primary"
                size="sm"
                onClick={handleCreateConvertRequest}
              >
                {!isLatestConvertInProgress ? "提出形式に変換" : "提出形式への変換処理中"}
              </Button>
              <Button
                disabled={stepData.status !== DOCUMENT_STATUS.CONVERT_COMPLETE || !isLatestConvertComplete}
                variant="primary"
                size="sm"
                onClick={handleDownloadFile}
              >
                提出形式をダウンロード
              </Button>
            </Stack>
          )
        }
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack flexDirection="row" alignItems="flex-start" gap={8}>
            <Stack gap={1}>
              <Typography variant="body14Bold">更新日時</Typography>
              <Typography variant="body14">
                {stepData?.updater && stepData.updatedAt ? dayjs(stepData.updatedAt).format(DATE_FORMAT) : EMPTY_STR.DATE}
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="body14Bold">最終更新者</Typography>
              <Typography variant="body14">{stepData?.updater.fullName || EMPTY_STR.TEXT}</Typography>
            </Stack>
            {stepData?.status && (
              <Stack gap={1}>
                <Typography variant="body14Bold">ステータス</Typography>
                <ConvertDocumentStatusTag status={stepData.status} title={findLabelByValue(statusOptions, stepData?.status)} />
                {/* {latestConvert ? (
                  <>
                    {latestConvert.status === CONVERT_STATUS.IN_PROGRESS ? (
                      <ConvertDocumentStatusTag
                        status={CONVERT_DOCUMENT_STATUS.CONVERTING}
                        title={CONVERT_DOCUMENT_STATUS_LABEL[CONVERT_DOCUMENT_STATUS.CONVERTING]}
                      />
                    ) : latestConvert.status === CONVERT_STATUS.COMPLETE ? (
                      <ConvertDocumentStatusTag
                        status={CONVERT_DOCUMENT_STATUS.COMPLETED_CONVERT}
                        title={CONVERT_DOCUMENT_STATUS_LABEL[CONVERT_DOCUMENT_STATUS.COMPLETED_CONVERT]}
                      />
                    ) : (
                      <ConvertDocumentStatusTag
                        status={CONVERT_DOCUMENT_STATUS.ERROR}
                        title={CONVERT_DOCUMENT_STATUS_LABEL[CONVERT_DOCUMENT_STATUS.ERROR]}
                      />
                    )}
                  </>
                ) : (
                  <ConvertDocumentStatusTag status={stepData.status} title={findLabelByValue(statusOptions, stepData?.status)} />
                )} */}
              </Stack>
            )}
            {stepData?.status === DOCUMENT_STATUS.ERROR ? (
              <Stack gap={1}>
                <Typography variant="body14Bold">{"エラー原因"}</Typography>
                <Typography variant="body14">{findLabelByValue(convertErrors, latestConvert?.error) || EMPTY_STR.TEXT}</Typography>
              </Stack>
            ) : (
              ""
            )}
          </Stack>
          {!readonly && (
            <Button variant="secondary" size="sm" component={Link} to={isLatestConvertInProgress ? detailDocumentRoute : editDocumentRoute}>
              {stepData.status === DOCUMENT_STATUS.IN_PROGRESS ? "編集" : "確認"}
            </Button>
          )}
        </Stack>
        <ConfirmSaveModal open={confirmRequestConvert.open} onOk={confirmRequestConvert.confirm} onClose={confirmRequestConvert.cancel}>
          <Typography>変換要求を実行すると、レポートを変更できなくなります。続行してもよろしいですか？</Typography>
        </ConfirmSaveModal>
        <ConfirmSaveModal open={confirmNeedAccount.open} onOk={confirmNeedAccount.confirm} onClose={confirmNeedAccount.cancel} enableFooter={false}>
          <Typography>提出形式へ変換できるようにオンライン申請のアカウントを提供お願いいたします。下のリンクで提供してください。</Typography>
          <TypoLink to={AppRoutes.companyAccountEdit}>事業所情報の編集へ</TypoLink>
        </ConfirmSaveModal>
      </StepCard>
    </>
  );
};
