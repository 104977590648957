import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo({ behavior: hash ? "instant" : "smooth", top: 0 });
  }, [pathname]);

  return null;
};
