export const AppRoutes = {
  // Home
  home: "/",

  // Login
  login: "/login",
  logout: "/logout",
  recover: "/recover",
  resetPassword: "/reset-password",
  register: "/register",

  // Information
  information: "/information",
  informationDetail: "/information/:id",

  // Regular
  regular: "/regular",

  regularPresent: "/regular/present",
  regularPresentAcceptActivity: "/regular/present/acceptance-activity",
  regularPresentAcceptActivityPayCheck: "/regular/present/acceptance-activity/paycheck",
  regularPresentLifeSupport: "/regular/present/life-support",
  regularPresentAcceptActivityPayrollFiles: "/regular/present/acceptance-activity/payroll-files",

  regularHistory: "/regular/history",
  regularHistoryDetail: "/regular/history/:year/:quarter",
  regularHistoryDetailDownload: "/regular/history/:year/:quarter/download",
  regularHistoryAcceptActivity: "/regular/history/:year/:quarter/acceptance-activity",
  regularHistoryAcceptActivityPayCheck: "/regular/history/:year/:quarter/acceptance-activity/paycheck",
  regularHistoryLifeSupport: "/regular/history/:year/:quarter/life-support",
  regularHistoryAcceptActivityPayrollFiles: "/regular/history/:year/:quarter/acceptance-activity/payroll-files",

  // Renew visa
  renewVisa: "/renew-visa",
  renewVisaPresent: "/renew-visa/:renewVisaDocumentId/present",
  renewVisaPresentEmployeeApplication: "/renew-visa/:renewVisaDocumentId/present/:documentStepId/employee-application",
  renewVisaPresentCompanyApplication: "/renew-visa/:renewVisaDocumentId/present/:documentStepId/company-application",
  renewVisaPresentUploadApplication: "/renew-visa/:renewVisaDocumentId/present/:documentStepId/upload-application",
  renewVisaInfoHistory: "/renew-visa/:renewVisaDocumentId/history",
  renewVisaInfoHistoryDetail: "/renew-visa/:renewVisaDocumentId/history/detail",
  renewVisaInfoHistoryEmployeeApplication: "/renew-visa/:renewVisaDocumentId/history/detail/:documentStepId/employee-application",
  renewVisaInfoHistoryCompanyApplication: "/renew-visa/:renewVisaDocumentId/history/detail/:documentStepId/company-application",
  renewVisaInfoHistoryUploadApplication: "/renew-visa/:renewVisaDocumentId/history/detail/:documentStepId/upload-application",

  // Retirement
  retirement: "/retirement",
  retirementDocument: "/retirement/:documentGroupId/document",
  retirementFiles: "/retirement/:documentGroupId/document/:documentId/steps/:id/files",

  retirementAcceptingWorker: "/retirement/:documentGroupId/document/:documentId/steps/:id/accepting-worker",
  retirementAcceptingWorkerDetail: "/retirement/:documentGroupId/document/:documentId/steps/:id/accepting-worker/detail",
  retirementEmploymentContract: "/retirement/:documentGroupId/document/:documentId/steps/:id/employment-contract",
  retirementEmploymentContractDetail: "/retirement/:documentGroupId/document/:documentId/steps/:id/employment-contract/detail",

  // Anytime
  anytime: "/anytime",
  anytimeTemplates: "/anytime/templates",
  anytimeDocument: "/anytime/:id/document",
  anytimeFiles: "/anytime/:id/files",

  anytimeEmploymentContractCreate: "/anytime/employment-contract/create",
  anytimeSupportPlanCreate: "/anytime/support-plan/create",
  anytimeSupportContractCreate: "/anytime/support-contract/create",

  anytimeEmploymentContractDetail: "/anytime/:documentId/employment-contract/:id/detail",
  anytimeSupportPlanDetail: "/anytime/:documentId/support-plan/:id/detail",
  anytimeSupportContractDetail: "/anytime/:documentId/support-contract/:id/detail",

  anytimeEmploymentContractEdit: "/anytime/:documentId/employment-contract/:id/edit",
  anytimeSupportPlanEdit: "/anytime/:documentId/support-plan/:id/edit",
  anytimeSupportContractEdit: "/anytime/:documentId/support-contract/:id/edit",

  // Contact
  contact: "/contact",

  // Employee
  employeeList: "/employee",
  employeeCreate: "/employee/create",
  employeeDetail: "/employee/:id/detail",
  employeeUploadAvatar: "/employee/:id/upload-avatar",
  employeeEdit: "/employee/:id/edit",
  employeeDocuments: "/employee/:id/documents",
  employeeGuideDocuments: "/employee/:id/documents/guide",

  // about
  company: "/company",
  companyEdit: "/company/edit",
  companyBranch: "/company/branch",
  companyBranchCreate: "/company/branch/create",
  companyBranchEdit: "/company/branch/:id/edit",
  companyAccount: "/company/account",
  companyAccountEdit: "/company/account/edit",
  companyDocument: "/company/document",
  companyDocumentEdit: "/company/document/:id/edit",

  // page not found
  pageNotFound: "/page-not-found",
  serverErrorPage: "/server-error",

  // Maintenance
  maintenance: "/maintenance",

  //
  interview: "/interview",
  lifeSupport: "/life-support",
  inquire: "/inquire",
  procedure: "/procedure",
  setting: "/setting",
  booking: "/booking",
};

export const InformationRoutes = [AppRoutes.information, AppRoutes.informationDetail];

export const RegularRoutes = [
  AppRoutes.regular,
  AppRoutes.regularPresent,
  AppRoutes.regularPresentAcceptActivity,
  AppRoutes.regularPresentAcceptActivityPayCheck,
  AppRoutes.regularPresentLifeSupport,
  AppRoutes.regularPresentAcceptActivityPayrollFiles,

  AppRoutes.regularHistory,
  AppRoutes.regularHistoryDetail,
  AppRoutes.regularHistoryAcceptActivity,
  AppRoutes.regularHistoryAcceptActivityPayCheck,
  AppRoutes.regularHistoryLifeSupport,
  AppRoutes.regularHistoryAcceptActivityPayrollFiles,
];

export const RenewVisaRoutes = [
  AppRoutes.renewVisa,
  AppRoutes.renewVisaPresent,
  AppRoutes.renewVisaPresentEmployeeApplication,
  AppRoutes.renewVisaPresentCompanyApplication,
  AppRoutes.renewVisaPresentUploadApplication,
  AppRoutes.renewVisaInfoHistory,
  AppRoutes.renewVisaInfoHistoryDetail,
  AppRoutes.renewVisaInfoHistoryCompanyApplication,
  AppRoutes.renewVisaInfoHistoryEmployeeApplication,
  AppRoutes.renewVisaInfoHistoryUploadApplication,
];

export const RetirementRoutes = [
  AppRoutes.retirement,
  AppRoutes.retirementDocument,
  AppRoutes.retirementFiles,
  AppRoutes.retirementAcceptingWorker,
  AppRoutes.retirementEmploymentContract,
  AppRoutes.retirementAcceptingWorkerDetail,
  AppRoutes.retirementEmploymentContractDetail,
];

export const AnytimeRoutes = [
  AppRoutes.anytime,
  AppRoutes.anytimeDocument,
  AppRoutes.anytimeEmploymentContractCreate,
  AppRoutes.anytimeSupportPlanCreate,
  AppRoutes.anytimeSupportContractCreate,
  AppRoutes.anytimeEmploymentContractEdit,
  AppRoutes.anytimeSupportPlanEdit,
  AppRoutes.anytimeSupportContractEdit,
];

export const ContactRoutes = [AppRoutes.contact];

export const EmployeeRoutes = [
  AppRoutes.employeeList,
  AppRoutes.employeeCreate,
  AppRoutes.employeeEdit,
  AppRoutes.employeeDocuments,
  AppRoutes.employeeDetail,
];

export const CompanyRoutes = [
  AppRoutes.company,
  AppRoutes.companyEdit,
  AppRoutes.companyBranch,
  AppRoutes.companyBranchCreate,
  AppRoutes.companyBranchEdit,
  AppRoutes.companyAccount,
  AppRoutes.companyAccountEdit,
  AppRoutes.companyDocument,
];

export const LoginRoutes = [AppRoutes.login, AppRoutes.recover, AppRoutes.resetPassword, AppRoutes.register];

export const ClientRoutes = [
  AppRoutes.home,
  InformationRoutes,
  RegularRoutes,
  RenewVisaRoutes,
  RetirementRoutes,
  AnytimeRoutes,
  ContactRoutes,
  EmployeeRoutes,
  CompanyRoutes,
].flat();
