import { TMasterNationality } from "@/api/entities/master-nationality.entity";
import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { TUpdateLifeSupportDocumentEmployeeInputDto } from "@/api/services/main/regular-document/life-support-document/dtos/update-life-support-document.dto";
import { InnerBox } from "@/components/elements/InnerBox";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

type TForeignerTableProps = {
  employees: (TUpdateLifeSupportDocumentEmployeeInputDto & { employeeNationality?: TMasterNationality | null })[];
};

export const ForeignerTable = ({ employees }: TForeignerTableProps) => {
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TUpdateLifeSupportDocumentEmployeeInputDto & { employeeNationality?: TMasterNationality | null }>[]>(
    () => [
      {
        header: "氏名",
        accessorKey: "employeeFullName",
        size: 120,
      },
      {
        header: "性別",
        Cell: ({ row }) => {
          return <Typography variant="body14">{findLabelByValue(genderOptions, row.original.employeeGender) || EMPTY_STR.TEXT}</Typography>;
        },
        size: 120,
      },
      {
        header: "生年月日",
        Cell: ({ row }) => (row.original?.birthday ? dayjs(row.original?.birthday).format(DATE_FORMAT) : EMPTY_STR.DATE),
        size: 120,
      },
      {
        header: "国籍",
        accessorKey: "employeeNationality",
        Cell: ({ row }) => {
          return (
            <Typography variant="body14">{findLabelByValue(nationalityOptions, row.original.employeeNationalityId) || EMPTY_STR.TEXT}</Typography>
          );
        },
        size: 120,
      },
      {
        header: "在留カード番号",
        accessorKey: "employeeResidenceCardNumber",
        Cell: ({ row }) => {
          return <Typography variant="body14">{row.original.employeeResidenceCardNumber || EMPTY_STR.TEXT}</Typography>;
        },
        size: 120,
      },
      {
        header: "郵便番号",
        accessorKey: "zipCode",
        Cell: ({ row }) => {
          return <Typography variant="body14">〒{row.original.zipCode || EMPTY_STR.TEXT}</Typography>;
        },
        size: 120,
      },
      {
        header: "住所",
        accessorKey: "fullAddress",
        Cell: ({ row }) => {
          return <Typography variant="body14">{row.original.fullAddress || EMPTY_STR.TEXT}</Typography>;
        },
        size: 120,
      },
      {
        header: "電話番号",
        accessorKey: "telNumber",
        Cell: ({ row }) => {
          return <Typography variant="body14">{row.original.telNumber || EMPTY_STR.TEXT}</Typography>;
        },
        size: 120,
      },
    ],
    [genderOptions, nationalityOptions],
  );

  const { table } = useTable({ rows: employees, columns });

  return (
    <InnerBox title="外国人名簿" bordered p={3}>
      <Table table={table} maxHeight={230} />
    </InnerBox>
  );
};
