import {
  TCreateSupportContractModificationDocumentReqDto,
  TCreateSupportContractModificationDocumentResDto,
} from "@/api/services/main/any-time/support-contract/dtos/create-support-contract-modification-document.dto";
import { TGetSupportContractModificationDocumentResDto } from "@/api/services/main/any-time/support-contract/dtos/get-support-contract-modification-document.dto";
import {
  TUpdateSupportContractModificationDocumentReqDto,
  TUpdateSupportContractModificationDocumentResDto,
} from "@/api/services/main/any-time/support-contract/dtos/update-support-contract-modification-document.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createAnytimeSupportContractApi = (payload: TCreateSupportContractModificationDocumentReqDto) => {
  return clientApi.post<TCreateSupportContractModificationDocumentResDto>("/any-time-documents/support-contract-modification-documents", payload);
};

export const updateAnytimeSupportContractApi = (stepId: number | string, payload: TUpdateSupportContractModificationDocumentReqDto) => {
  return clientApi.put<TUpdateSupportContractModificationDocumentResDto>(
    `/any-time-documents/support-contract-modification-documents/steps/${stepId}`,
    payload,
  );
};

export const fetchAnytimeSupportContractApi = async (stepId: string | number) => {
  const rs = await clientApi.get<TGetSupportContractModificationDocumentResDto>(
    `/any-time-documents/support-contract-modification-documents/steps/${stepId}`,
  );
  return rs.data;
};

export const useAnytimeSupportContract = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_SUPPORT_CONTRACT, id],
    queryFn: () => (id ? fetchAnytimeSupportContractApi(id) : undefined),
    enabled: Boolean(id),
  });
};
