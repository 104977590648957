import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { SelectMulti } from "@/components/elements/SelectMulti";
import { Textarea } from "@/components/elements/Textarea";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TChangesFormProps = {
  readonly?: boolean;
};

export const ChangesForm: FC<TChangesFormProps> = ({ readonly }) => {
  const { control } = useFormContext<TEmploymentContractForm>();
  const { data: modificationReasonOptions } = useEnum("EModifyContractModificationReason");
  const { data: locationReasonsOptions } = useEnum("EModifyContractChangeDispatchLocationReasons");

  return (
    <InnerBox title="契約の変更" required gap={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormField control={control} name="modifyContractDate">
            <InputDatePicker readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="modifyContractModificationReasons">
            <SelectMulti options={modificationReasonOptions} readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="modifyContractChangeDispatchLocationReasons">
            <SelectMulti options={locationReasonsOptions} readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="modifyContractContent">
            <Textarea readonly={readonly} />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};
