import { useRegularDocumentHistoryDetail } from "@/api/services/main/regular-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { PayrollFilesTable } from "@/features/regular-document/components/accept-activity/payroll-files/PayrollFilesTable";
import { PayrollFilesTop } from "@/features/regular-document/components/accept-activity/payroll-files/PayrollFilesTop";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const RegularHistorytAcceptActivityPayrollFilesPage = () => {
  const { year, quarter } = useParams();
  const { data: presentDocumentListResult, isLoading } = useRegularDocumentHistoryDetail(year, quarter);
  const documentData = presentDocumentListResult?.data;
  const stepData = findDocumentStep(documentData?.regularDocuments, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT);
  const documentStepId = stepData?.id;

  if (isLoading) return <LoadingPage />;
  if (!documentStepId) return null;
  return (
    <Box>
      <TopPage
        title="賃金台帳原本のアップロード"
        description={`特定技能外国人と比較対象となる日本人の賃金台帳をアップロードしてください。\n提出形式：PDF Excel`}
        backUrl={getDynamicRoute(AppRoutes.regularHistoryDetail, { year, quarter })}
      />
      <PayrollFilesWrapper gap={3}>
        <PayrollFilesTop documentStepId={documentStepId} documentStepStatus={stepData.status} readonly />
        <PayrollFilesTable documentStepId={documentStepId} readonly />
      </PayrollFilesWrapper>
    </Box>
  );
};

const PayrollFilesWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
`;
