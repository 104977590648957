import { useEnum } from "@/api/services/global/enum";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { CheckboxGroup } from "@/components/elements/CheckboxGroup";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { StaffingTargetCheckBoxGroup } from "@/features/renew-visa/components/applicationStep/formApplication/companyApplication/StaffingTargetCheckBoxGroup";
import { ERenewVisaConditionsApplyForSupportPlanDelegation } from "@/features/renew-visa/enums/companyApplicationForm";
import { TCompanyApplicationForm } from "@/features/renew-visa/types/companyApplicationForm";
import { useDialog } from "@/hooks/useDialog";
import { divider, text } from "@/theme/colors";
import { YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Box, Divider, Grid, Accordion as MuiAccordion, Stack, styled, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TCompanyOtherInfoFormProps = {
  readonly?: boolean;
};

export const CompanyOtherInfoForm: FC<TCompanyOtherInfoFormProps> = ({ readonly }) => {
  const methods = useFormContext<TCompanyApplicationForm>();
  const { control, setValue } = methods;
  const confirmClearConditionsApplyForStaffingTargetData = useDialog();
  const confirmClearConditionsApplyForSupportPlanDelegationData = useDialog();

  const { data: yesNoAnswerOptions } = useEnum("EYesNoAnswer");

  const { data: yesNoAndNotApplicableAnswerOptions } = useEnum("EYesNoAndNotApplicableAnswer");

  const { data: renewVisaConditionsApplyForSupportPlanDelegationOptions } = useEnum("ERenewVisaConditionsApplyForSupportPlanDelegation");

  const whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLawsWatchField = useWatch({
    control,
    name: "whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws",
  });

  const whetherInvoluntaryDismissalOfWorkersInSimilarRolesWatchField = useWatch({
    control,
    name: "whetherInvoluntaryDismissalOfWorkersInSimilarRoles",
  });

  const whetherMissingForeignWorkersDueToOrgIsFaultWatchField = useWatch({
    control,
    name: "whetherMissingForeignWorkersDueToOrgIsFault",
  });

  const whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnelWatchField = useWatch({
    control,
    name: "whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel",
  });

  const whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelWatchField = useWatch({
    control,
    name: "whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel",
  });

  const whetherUnrehabilitatedBankruptcyOfOrgOrPersonnelWatchField = useWatch({
    control,
    name: "whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel",
  });

  const whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationWatchField = useWatch({
    control,
    name: "whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification",
  });

  const whetherUnfairActsRelatedToImmigrationLaborLawsWatchField = useWatch({
    control,
    name: "whetherUnfairActsRelatedToImmigrationLaborLaws",
  });

  const whetherAffiliationWithOrganizedCrimeWatchField = useWatch({
    control,
    name: "whetherAffiliationWithOrganizedCrime",
  });

  const whetherLegalRepresentativeIsEligibilityWatchField = useWatch({
    control,
    name: "whetherLegalRepresentativeIsEligibility",
  });

  const whetherOrganizedCrimeControlOverBusinessWatchField = useWatch({
    control,
    name: "whetherOrganizedCrimeControlOverBusiness",
  });

  const whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsWatchField = useWatch({
    control,
    name: "whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements",
  });

  const whetherPenaltyClausesForBreachOfEmploymentContractWatchField = useWatch({
    control,
    name: "whetherPenaltyClausesForBreachOfEmploymentContract",
  });

  const whetherHostCompanyComplianceWithSectionsWatchField = useWatch({
    control,
    name: "whetherHostCompanyComplianceWithSections",
  });

  const whetherWorkerIsCompensationInsuranceEnrollmentWatchField = useWatch({
    control,
    name: "whetherWorkerIsCompensationInsuranceEnrollment",
  });

  const whetherNeglectOfSupportPlanOccurredInPastFiveYearsWatchField = useWatch({
    control,
    name: "whetherNeglectOfSupportPlanOccurredInPastFiveYears",
  });

  const conditionsApplyForSupportPlanDelegationDetailWatchField = useWatch({
    control,
    name: "conditionsApplyForSupportPlanDelegationDetail",
  });

  const whetherCancellationOfTrainingCertificationWatchField = useWatch({
    control,
    name: "whetherCancellationOfTrainingCertification",
  });

  const whetherConditionsApplyForStaffingTargetsWatchField = useWatch({
    control,
    name: "whetherConditionsApplyForStaffingTargets",
  });

  const whetherConditionsApplyForSupportPlanDelegationWatchField = useWatch({
    control,
    name: "whetherConditionsApplyForSupportPlanDelegation",
  });

  const conditionsApplyForStaffingTargetsDetailWatchField = useWatch({
    control,
    name: "conditionsApplyForStaffingTargetsDetail",
  });

  useEffect(() => {
    if (whetherWorkerIsCompensationInsuranceEnrollmentWatchField === YES_NO_ANSWER.YES) {
      setValue("workerIsCompensationInsuranceEnrollmentDetail", "労災保険加入");
    } else {
      setValue("workerIsCompensationInsuranceEnrollmentDetail", "");
    }
  }, [whetherWorkerIsCompensationInsuranceEnrollmentWatchField]);

  const handleClearConditionsApplyForStaffingTargetData = () => {
    setValue("conditionsApplyForStaffingTargetsDetail", []);
    setValue("relevantIndustryWorkAtDispatchSiteDetail", "");
    setValue("agriculturalWorkInDesignatedStrategicZoneDetail", "");
    setValue("majorityCapitalInvestmentByIndustryRelatedEntityDetail", "");
    setValue("substantialInvolvementByIndustryRelatedEntityDetail", "");
    confirmClearConditionsApplyForStaffingTargetData.cancel();
  };

  const handleClearConditionsApplyForSupportPlanDelegationData = () => {
    setValue("conditionsApplyForSupportPlanDelegationDetail", []);
    setValue("conditionsApplyForSupportPlanDelegationOtherDetail", "");
    confirmClearConditionsApplyForSupportPlanDelegationData.cancel();
  };

  const handleConfirmClearConditionsApplyForStaffingTargetData = () => {
    if (
      whetherConditionsApplyForStaffingTargetsWatchField === YES_NO_ANSWER.YES &&
      conditionsApplyForStaffingTargetsDetailWatchField?.length &&
      conditionsApplyForStaffingTargetsDetailWatchField?.length > 0
    ) {
      confirmClearConditionsApplyForStaffingTargetData.show();
    } else {
      handleClearConditionsApplyForStaffingTargetData();
    }
  };

  const handleConfirmClearConditionsApplyForSupportPlanDelegationData = () => {
    if (
      whetherConditionsApplyForSupportPlanDelegationWatchField === YES_NO_ANSWER.YES &&
      conditionsApplyForSupportPlanDelegationDetailWatchField?.length &&
      conditionsApplyForSupportPlanDelegationDetailWatchField?.length > 0
    ) {
      confirmClearConditionsApplyForSupportPlanDelegationData.show();
    } else {
      handleClearConditionsApplyForSupportPlanDelegationData();
    }
  };

  const handleCancelClearConditionsApplyForStaffingTargetData = () => {
    setValue("whetherConditionsApplyForStaffingTargets", YES_NO_ANSWER.YES);
    confirmClearConditionsApplyForStaffingTargetData.cancel();
  };

  const handleCancelClearConditionsApplyForSupportPlanDelegationData = () => {
    setValue("whetherConditionsApplyForSupportPlanDelegation", YES_NO_ANSWER.YES);
    confirmClearConditionsApplyForSupportPlanDelegationData.cancel();
  };

  return (
    <InnerBox title="その他質問事項" bordered p={3} required>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField control={control} name="whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("violationsOfLaborSocialInsuranceAndTaxLawsDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="violationsOfLaborSocialInsuranceAndTaxLawsDetail">
              <Input disabled={whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLawsWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherInvoluntaryDismissalOfWorkersInSimilarRoles">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("involuntaryDismissalOfWorkersInSimilarRolesDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="involuntaryDismissalOfWorkersInSimilarRolesDetail">
              <Input disabled={whetherInvoluntaryDismissalOfWorkersInSimilarRolesWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherMissingForeignWorkersDueToOrgIsFault">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("missingForeignWorkersDueToOrgIsFaultDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="missingForeignWorkersDueToOrgIsFaultDetail">
              <Input disabled={whetherMissingForeignWorkersDueToOrgIsFaultWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail">
              <Input disabled={whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnelWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail">
              <Input disabled={whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("unrehabilitatedBankruptcyOfOrgOrPersonnelDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="unrehabilitatedBankruptcyOfOrgOrPersonnelDetail">
              <Input disabled={whetherUnrehabilitatedBankruptcyOfOrgOrPersonnelWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherCancellationOfTrainingCertification">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("cancellationOfTrainingCertificationDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="cancellationOfTrainingCertificationDetail">
              <Input
                disabled={whetherCancellationOfTrainingCertificationWatchField === YES_NO_ANSWER.NO || readonly}
                placeholder="入力してください"
              />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail">
              <Input disabled={whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherUnfairActsRelatedToImmigrationLaborLaws">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("unfairActsRelatedToImmigrationLaborLawsDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="unfairActsRelatedToImmigrationLaborLawsDetail">
              <Input disabled={whetherUnfairActsRelatedToImmigrationLaborLawsWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherAffiliationWithOrganizedCrime">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("affiliationWithOrganizedCrimeDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="affiliationWithOrganizedCrimeDetail">
              <Input disabled={whetherAffiliationWithOrganizedCrimeWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField control={control} name="whetherLegalRepresentativeIsEligibility">
                <RadioGroup
                  direction="row"
                  options={yesNoAndNotApplicableAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_AND_NOT_APPLICABLE_ANSWER.YES) {
                      methods.setValue("legalRepresentativeIsEligibilityDetail", "");
                    }
                  }}
                />
              </FormField>
              <Box sx={{ width: "calc(50% - 16px)" }}>
                <FormField sx={{ mt: 0.5 }} control={control} name="legalRepresentativeIsEligibilityDetail">
                  <Input disabled={whetherLegalRepresentativeIsEligibilityWatchField !== YES_NO_AND_NOT_APPLICABLE_ANSWER.YES || readonly} />
                </FormField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="whetherOrganizedCrimeControlOverBusiness">
                <RadioGroup
                  direction="row"
                  options={yesNoAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_ANSWER.YES) {
                      methods.setValue("organizedCrimeControlOverBusinessDetail", "");
                    }
                  }}
                />
              </FormField>
              <Box sx={{ width: "calc(50% - 16px)" }}>
                <FormField sx={{ mt: 0.5 }} control={control} name="organizedCrimeControlOverBusinessDetail">
                  <Input disabled={whetherOrganizedCrimeControlOverBusinessWatchField === YES_NO_ANSWER.NO || readonly} />
                </FormField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="whetherRetentionOfActivityDocuments">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements">
                <RadioGroup
                  direction="row"
                  options={yesNoAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_ANSWER.YES) {
                      methods.setValue("awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail", "");
                    }
                  }}
                />
              </FormField>
              <Box sx={{ width: "calc(50% - 16px)" }}>
                <FormField sx={{ mt: 0.5 }} control={control} name="awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail">
                  <Input
                    disabled={whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsWatchField === YES_NO_ANSWER.NO || readonly}
                  />
                </FormField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="whetherPenaltyClausesForBreachOfEmploymentContract">
                <RadioGroup
                  direction="row"
                  options={yesNoAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_ANSWER.YES) {
                      methods.setValue("penaltyClausesForBreachOfEmploymentContractDetail", "");
                    }
                  }}
                />
              </FormField>
              <Box sx={{ width: "calc(50% - 16px)" }}>
                <FormField sx={{ mt: 0.5 }} control={control} name="penaltyClausesForBreachOfEmploymentContractDetail">
                  <Input disabled={whetherPenaltyClausesForBreachOfEmploymentContractWatchField === YES_NO_ANSWER.NO || readonly} />
                </FormField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <Typography component="h3" variant="body14Bold" color={text.accent} mb={1}>
            外国人を労働者派遣の対象とする場合に記入
          </Typography>
          <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherConditionsApplyForStaffingTargets">
                    <RadioGroup
                      direction="row"
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={handleConfirmClearConditionsApplyForStaffingTargetData}
                    />
                  </FormField>
                  <Stack gap="8px" border={`1px solid ${divider.middle}`} p="16px" borderRadius="8px" mt="16px">
                    <StaffingTargetCheckBoxGroup readonly={readonly || whetherConditionsApplyForStaffingTargetsWatchField === YES_NO_ANSWER.NO} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherHostCompanyComplianceWithSections">
                    <RadioGroup
                      direction="row"
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={(value) => {
                        if (value !== YES_NO_ANSWER.YES) {
                          methods.setValue("hostCompanyComplianceWithSectionsDetail", "");
                        }
                      }}
                    />
                  </FormField>
                  <Box sx={{ width: "calc(50% - 16px)" }}>
                    <FormField sx={{ mt: 0.5 }} control={control} name="hostCompanyComplianceWithSectionsDetail">
                      <Input disabled={whetherHostCompanyComplianceWithSectionsWatchField === YES_NO_ANSWER.NO || readonly} />
                    </FormField>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </MuiAccordionWrapper>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 3 }} />
          <FormField control={control} name="whetherWorkerIsCompensationInsuranceEnrollment">
            <RadioGroup
              direction="row"
              options={yesNoAnswerOptions}
              disabled={readonly}
              onChange={(value) => {
                if (value !== YES_NO_ANSWER.YES) {
                  methods.setValue("workerIsCompensationInsuranceEnrollmentDetail", "");
                }
              }}
            />
          </FormField>
          <Box sx={{ width: "calc(50% - 16px)" }}>
            <FormField sx={{ mt: 0.5 }} control={control} name="workerIsCompensationInsuranceEnrollmentDetail" breakLineLabel={false}>
              <Input disabled={whetherWorkerIsCompensationInsuranceEnrollmentWatchField === YES_NO_ANSWER.NO || readonly} />
            </FormField>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherAdequateSystemForContinuedEmploymentContractFulfillment">
            <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting">
            <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="whetherComplianceWithIndustrySpecificStandardsForContractFulfillment">
            <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <Typography component="h3" variant="body14Bold" color={text.accent} mb={1}>
            以下、(34)から(42)は申請人が「特定技能1号」での在留を希望する場合であって、契約により登録支援機関に1号特定技能外国人支援計画の全部の実施を委託しない場合に入力
          </Typography>
          <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormField control={control} name="supportSupervisor">
                    <Input disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="supportSupervisorRole">
                    <Input disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherAppointmentOfSupportSupervisorFromStaff">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="supportPersonnel">
                    <Input disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="supportPersonnelRole">
                    <Input disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherAppointmentOfSupportPersonnelFromStaff">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherConditionsApplyForSupportPlanDelegation">
                    <RadioGroup
                      direction="row"
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={handleConfirmClearConditionsApplyForSupportPlanDelegationData}
                    />
                  </FormField>
                  <Stack gap="8px" border={`1px solid ${divider.middle}`} p="16px" borderRadius="8px" mt="16px">
                    <FormField control={control} name="conditionsApplyForSupportPlanDelegationDetail">
                      <CheckboxGroup
                        options={renewVisaConditionsApplyForSupportPlanDelegationOptions}
                        enableOrderNumber
                        disabled={readonly || whetherConditionsApplyForSupportPlanDelegationWatchField === YES_NO_ANSWER.NO}
                        onChange={(value) => {
                          if (!value.includes(ERenewVisaConditionsApplyForSupportPlanDelegation.ABILITY_TO_PROPERLY_PERFORM_OTHER_SUPPORT_DUTIES)) {
                            methods.setValue("conditionsApplyForSupportPlanDelegationOtherDetail", "");
                          }
                        }}
                      />
                    </FormField>
                    <Stack direction="row">
                      <Box sx={{ width: "30px" }}></Box>
                      <FormField control={control} name="conditionsApplyForSupportPlanDelegationOtherDetail">
                        <Input
                          disabled={
                            !conditionsApplyForSupportPlanDelegationDetailWatchField?.includes(
                              ERenewVisaConditionsApplyForSupportPlanDelegation.ABILITY_TO_PROPERLY_PERFORM_OTHER_SUPPORT_DUTIES,
                            ) || readonly
                          }
                        />
                      </FormField>
                    </Stack>
                  </Stack>
                  {/* {methods.getFieldState('conditionsApplyForSupportPlanDelegationDetail').error} */}
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportSystemInUnderstandingLanguageIsAvailable">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherRetentionOfSupportDocumentation">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportPersonnelAreNeutralInImplementation">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherNeglectOfSupportPlanOccurredInPastFiveYears">
                    <RadioGroup
                      direction="row"
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={(value) => {
                        if (value !== YES_NO_ANSWER.YES) {
                          methods.setValue("neglectOfSupportPlanOccurredInPastFiveYearsDetail", "");
                        }
                      }}
                    />
                  </FormField>
                  <Box sx={{ width: "calc(50% - 16px)" }}>
                    <FormField sx={{ mt: 0.5 }} control={control} name="neglectOfSupportPlanOccurredInPastFiveYearsDetail">
                      <Input disabled={whetherNeglectOfSupportPlanOccurredInPastFiveYearsWatchField === YES_NO_ANSWER.NO || readonly} />
                    </FormField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherRegularMeetingSystemWithSupervisorsIsInPlace">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherComplianceWithIndustryStandardsIsEnsured">
                    <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
              </Grid>
            </AccordionDetails>
          </MuiAccordionWrapper>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <Typography component="h3" variant="body14Bold" color={text.accent} mb={1}>
             1号特定技能外国人支援計画（申請人が「特定技能1号」での在留を希望する場合に入力）
          </Typography>
          <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherTransportationToPortOrAirportIsProvided">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportForSecuringAppropriateHousingIsProvided">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportForOpeningBankAccountsAndContractsIsProvided">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherAccompanimentForProceduresIsProvidedIfNeeded">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherOpportunitiesForJapaneseLanguageLearningAreProvided">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherTimelyResponseToConsultationsOrComplaintsIsEnsured">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherJobChangeSupportIsProvidedForContractTermination">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherRegularMeetingsWithSupervisorsAreConducted">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportPlanIsCreatedInUnderstandableLanguages">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSpecificIndustryRequirementsAreIncludedInSupportPlan">
                    <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportIsAppropriateForForeignersProperStay">
                    <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="whetherSupportPlanMeetsIndustryStandards">
                    <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                  </FormField>
                </Grid>
              </Grid>
            </AccordionDetails>
          </MuiAccordionWrapper>
        </Grid>
      </Grid>
      <ConfirmSaveModal
        title="入力したデータがなくなりますが、よろしいですか。"
        open={confirmClearConditionsApplyForStaffingTargetData.open}
        onClose={handleCancelClearConditionsApplyForStaffingTargetData}
        onOk={handleClearConditionsApplyForStaffingTargetData}
      />
      <ConfirmSaveModal
        title="入力したデータがなくなりますが、よろしいですか。"
        open={confirmClearConditionsApplyForSupportPlanDelegationData.open}
        onClose={handleCancelClearConditionsApplyForSupportPlanDelegationData}
        onOk={handleClearConditionsApplyForSupportPlanDelegationData}
      />
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &::before {
    display: none;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
    max-width: 300px;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
