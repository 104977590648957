import { createContactApi } from "@/api/services/main/contact";
import { Button } from "@/components/elements/Button";
import { PAYCHECK_CHOSEN_TYPE } from "@/features/regular-document/constants/paycheckChosenType";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { text } from "@/theme/colors";
import { showError } from "@/utils/error";
import { Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { toast } from "@/utils/toast";

export const IrohanaAsking = () => {
  const { chosenType, resourceData } = usePayCheck();
  const [isAskingSupport, setIsAskingSupport] = useState(false);

  const handleCreateContactToAskSupport = async () => {
    const email = Cookies.get("email") || "";
    setIsAskingSupport(true);
    await createContactApi({
      contactTypeId: "3",
      content: "",
      email,
    })
      .then((rs) => {
        toast.success(rs.message ?? "irohanaへ賃金台帳の入力代行を依頼しました。");
      })
      .catch((error) => {
        showError(error);
      })
      .finally(() => {
        setIsAskingSupport(false);
      });
  };

  if (chosenType !== PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT) {
    return null;
  }

  return (
    <Stack gap={2}>
      <Typography variant="sub16Semi">賃金台帳入力の代行を依頼する</Typography>
      <Typography variant="body14" color={text.accent}>
        依頼後は本画面で下書き保存してください。
        <br />
        代行の入力が完了したのち、担当者よりメールにてご連絡いたします。
      </Typography>
      <Stack flexDirection="row" justifyContent="center">
        <Button type="button" onClick={handleCreateContactToAskSupport} isLoading={isAskingSupport}>
          irohanaへ賃金台帳の入力代行を依頼
        </Button>
      </Stack>
    </Stack>
  );
};
