import { TRegularDocumentStep } from "@/api/entities/regularDocumentStep.entity";
import { useEnum } from "@/api/services/global/enum";
import { createConvertRequestApi, setDownloadedConvertRequestApi } from "@/api/services/main/convert-request";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { ConvertDocumentStatusTag } from "@/components/elements/ConvertDocumentStatusTag";
import { StepCard } from "@/components/elements/StepCard";
import { TypoLink } from "@/components/elements/TypoLink";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { useConvertRequest } from "@/hooks/useConvertRequest";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { downloadFileFromPath } from "@/utils/export";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

type TStepProps = {
  stepData: TRegularDocumentStep;
  readonly?: boolean;
  documentType: string;
};

export const LifeSupportCreateStep: FC<TStepProps> = ({ stepData, documentType, readonly }) => {
  const { masterDocumentStep, convertRequests, id, updatedAt, updater, status } = stepData;
  const params = useParams();
  const { stepOrder, stepName } = masterDocumentStep;
  const { checkFinishInitStep } = useInitSetup();
  const { data: documentStatuses } = useEnum("EDocumentStatus");
  const queryClient = useQueryClient();
  const { data: convertErrors } = useEnum("EConvertError");
  const { isLatestConvertInProgress, confirmRequestConvert, confirmNeedAccount, companyAccountData, latestConvert, isLatestConvertComplete } =
    useConvertRequest({
      stepData,
    });

  const handleCreateConvertRequest = async () => {
    try {
      if (!companyAccountData) {
        const agree = await confirmNeedAccount.show();
        if (!agree) return;
      }
      const agree = await confirmRequestConvert.show();
      if (!agree) return;
      await createConvertRequestApi({ documentStepId: Number(id), documentType });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
    } catch (error) {
      showError(error);
    }
  };

  const convertFilePaths = useMemo(() => {
    return [latestConvert?.filePath ?? []].flat();
  }, [latestConvert]);

  const handleDownloadFile = async () => {
    try {
      if (convertFilePaths && latestConvert && isLatestConvertComplete) {
        await Promise.all(convertFilePaths.map((filePath) => downloadFileFromPath(filePath)));
        if (!latestConvert.isDownloaded) {
          await setDownloadedConvertRequestApi(latestConvert.id);
          await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <StepCard
      title={`ステップ${stepOrder}`}
      subTitle={stepName}
      buttons={
        readonly ? (
          <Button variant="primary" size="sm" component={Link} to={getDynamicRoute(AppRoutes.regularHistoryLifeSupport, params)}>
            確認
          </Button>
        ) : (
          <Stack flexDirection="row" alignItems="center" gap="10px">
            <Button
              disabled={stepData.status !== DOCUMENT_STATUS.COMPLETE || isLatestConvertInProgress}
              variant="primary"
              size="sm"
              onClick={handleCreateConvertRequest}
            >
              {!isLatestConvertInProgress ? "提出形式に変換" : "提出形式への変換処理中"}
            </Button>
            <Button
              disabled={stepData.status !== DOCUMENT_STATUS.CONVERT_COMPLETE || !isLatestConvertComplete}
              variant="primary"
              size="sm"
              onClick={handleDownloadFile}
            >
              提出形式をダウンロード
            </Button>
          </Stack>
        )
      }
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack flexDirection="row" alignItems="flex-start" gap={8}>
          <Stack gap={1}>
            <Typography variant="body14Bold">更新日時</Typography>
            <Typography variant="body14">{updatedAt && updater ? dayjs(updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}</Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body14Bold">最終更新者</Typography>
            <Typography variant="body14">{updater?.fullName || EMPTY_STR.TEXT}</Typography>
          </Stack>
          {status && (
            <Stack gap={1}>
              <Typography variant="body14Bold">ステータス</Typography>
              <ConvertDocumentStatusTag status={stepData.status} title={findLabelByValue(documentStatuses, stepData?.status)} />
              {/* {latestConvert ? (
                <>
                  {latestConvert.status === CONVERT_STATUS.IN_PROGRESS ? (
                    <ConvertDocumentStatusTag
                      status={CONVERT_DOCUMENT_STATUS.CONVERTING}
                      title={CONVERT_DOCUMENT_STATUS_LABEL[CONVERT_DOCUMENT_STATUS.CONVERTING]}
                    />
                  ) : latestConvert.status === CONVERT_STATUS.COMPLETE ? (
                    <ConvertDocumentStatusTag
                      status={CONVERT_DOCUMENT_STATUS.COMPLETED_CONVERT}
                      title={CONVERT_DOCUMENT_STATUS_LABEL[CONVERT_DOCUMENT_STATUS.COMPLETED_CONVERT]}
                    />
                  ) : (
                    <ConvertDocumentStatusTag
                      status={CONVERT_DOCUMENT_STATUS.ERROR}
                      title={CONVERT_DOCUMENT_STATUS_LABEL[CONVERT_DOCUMENT_STATUS.ERROR]}
                    />
                  )}
                </>
              ) : (
                <ConvertDocumentStatusTag status={stepData.status} title={findLabelByValue(documentStatuses, stepData?.status)} />
              )} */}
            </Stack>
          )}
          {status === DOCUMENT_STATUS.ERROR ? (
            <Stack gap={1}>
              <Typography variant="body14Bold">{"エラー原因"}</Typography>
              <Typography variant="body14">{findLabelByValue(convertErrors, latestConvert?.error) || EMPTY_STR.TEXT}</Typography>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
        {!readonly && (
          <Button variant="secondary" size="sm" component={Link} to={AppRoutes.regularPresentLifeSupport} onClick={checkFinishInitStep}>
            {status === DOCUMENT_STATUS.IN_PROGRESS ? "編集" : "確認"}
          </Button>
        )}
      </Stack>
      <ConfirmSaveModal open={confirmRequestConvert.open} onOk={confirmRequestConvert.confirm} onClose={confirmRequestConvert.cancel}>
        <Typography>変換要求を実行すると、レポートを変更できなくなります。続行してもよろしいですか？</Typography>
      </ConfirmSaveModal>
      <ConfirmSaveModal open={confirmNeedAccount.open} onOk={confirmNeedAccount.confirm} onClose={confirmNeedAccount.cancel} enableFooter={false}>
        <Typography>提出形式へ変換できるようにオンライン申請のアカウントを提供お願いいたします。下のリンクで提供してください。</Typography>
        <TypoLink to={AppRoutes.companyAccountEdit}>事業所情報の編集へ</TypoLink>
      </ConfirmSaveModal>
    </StepCard>
  );
};
