import { LoadingPage } from "@/components/elements/Loading/Loading";
import { DATE_TIME_FORMAT_WITH_SECONDS } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useMaintenance } from "@/providers/MaintenanceProvider";
import { divider } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";

type TMaintenanceCardProps = {};

export const MaintenanceCard: FC<TMaintenanceCardProps> = () => {
  const { data, isLoading } = useMaintenance();

  if (isLoading) return <LoadingPage />;
  return (
    <Stack width="100%" border={`1px solid ${divider.high}`} borderRadius={rounded.xs} padding={3} gap={2}>
      <Typography variant="sub16Bold">本メンテナンスの予定スケジュール</Typography>
      <Stack width="fit-content" alignItems="end">
        <Typography variant="body14" width="fit-content">
          開始日時：
          {data?.modeStartDateTime ? dayjs(data?.modeStartDateTime).tz("Asia/Tokyo").format(DATE_TIME_FORMAT_WITH_SECONDS) : EMPTY_STR.DATE_TIME}
        </Typography>
        <Typography variant="body14" width="fit-content">
          終了予定日時：
          {data?.modeEndDateTime ? dayjs(data?.modeEndDateTime).tz("Asia/Tokyo").format(DATE_TIME_FORMAT_WITH_SECONDS) : EMPTY_STR.DATE_TIME}
        </Typography>
      </Stack>
      <Typography variant="sub16Bold">本メンテナンスのお問い合わせ先</Typography>
      <Typography variant="body14">E-mail: notification@iro-hana.com</Typography>
    </Stack>
  );
};
