import { useGetCompanySpecificIndustryList, useGetMainIndustryList } from "@/api/services/global/master";
import { updateCompanyInfoApi } from "@/api/services/main/company";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { SelectMulti } from "@/components/elements/SelectMulti";
import { Textarea } from "@/components/elements/Textarea";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { SupporterFormTable } from "@/features/company/components/SupporterFormTable";
import { useGetCompanySchema } from "@/features/company/hooks/useGetCompanySchema";
import { TCompanyInfoForm } from "@/features/company/types/CompanyInfoForm";
import { convertCompanyFormDataToReqDto } from "@/features/company/utils/convertFormDataToReqDto";
import { useLocationInfo } from "@/hooks/useLocationInfo";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { FCC } from "@/types/common";
import { COMPANY_DIVISION } from "@/types/enum";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TCompanyFormProps = {
  previousValues?: Partial<TCompanyInfoForm>;
};

const defaultSupporter = {
  supporterName: "",
  supporterFuriganaName: "",
  companyPosition: "",
  telNumber: "",
  email: "",
};

const roles = ["contact", "support_manager", "support"];

const initSupportersForm = roles.map((role) => ({
  ...defaultSupporter,
  supporterRole: role,
}));

export const CompanyForm: FCC<TCompanyFormProps> = ({ previousValues }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { companySchema } = useGetCompanySchema();

  const methods = useForm<TCompanyInfoForm>({
    defaultValues: {
      ...previousValues,
      supporters: previousValues?.supporters && previousValues?.supporters.length > 0 ? previousValues?.supporters : initSupportersForm,
      specificBusinessClassificationIds: previousValues?.specificBusinessClassificationIds ?? [],
      specificBusinessClassificationTwoIds: previousValues?.specificBusinessClassificationTwoIds ?? [],
      specificBusinessClassificationThreeIds: previousValues?.specificBusinessClassificationThreeIds ?? [],
      companyNote: previousValues?.companyNote ?? "",
    },
    resolver: yupResolver(companySchema),
    mode: "onBlur",
  });
  const [telNumberHint, setTelNumberHint] = useState<string>("");
  const { control, setValue } = methods;

  const specificIndustryIdWatchField = useWatch({ control, name: "specificIndustryId" });
  const specificIndustryTwoIdWatchField = useWatch({ control, name: "specificIndustryTwoId" });
  const specificIndustryThreeIdWatchField = useWatch({ control, name: "specificIndustryThreeId" });
  const paymentDateWatchField = useWatch({ control, name: "payrollDeadline" });
  const telNumberWatchField = useWatch({ control, name: "telNumber" });
  const divisionWatchField = useWatch({ control, name: "division" });
  const mainIndustryIdWatchField = useWatch({ control, name: "mainIndustryId" });

  useEffect(() => {
    if (paymentDateWatchField === "month_end_closing") {
      methods.setValue("paymentDate", "next_month");
    }
    if (telNumberWatchField) {
      if (telNumberWatchField.length === 1 || (telNumberWatchField.length >= 6 && telNumberWatchField.includes("-"))) {
        setTelNumberHint("日本国内かつ市外局番を含めた電話番号を入力ください。");
      } else if (telNumberWatchField.length >= 2 && !telNumberWatchField.includes("-")) {
        setTelNumberHint("ハイフン「-」で区切った電話番号を入力ください。");
      } else {
        setTelNumberHint("");
      }
    }
    if (divisionWatchField === COMPANY_DIVISION.SOLE_PROPRIETOR) {
      methods.setValue("capital", 0);
      methods.setValue("companyIdentificationNumber", "");
    }
  }, [paymentDateWatchField, telNumberWatchField, divisionWatchField]);

  const onSubmit = async (data: TCompanyInfoForm) => {
    const payload = convertCompanyFormDataToReqDto(data);
    try {
      const rs = await updateCompanyInfoApi(payload);
      navigate(AppRoutes.company);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const {
    isLoading: isLoadingCompanySpecificIndustry,
    companySpecificIndustryOptions,
    getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry,
  } = useGetCompanySpecificIndustryList();

  const { isLoading: isLoadingMainIndustry, mainIndustryOptions, mainIndustryOtherIds } = useGetMainIndustryList();

  useLocationInfo({ methods, names: { zipCode: "zipCode", province: "province", municipality: "municipality", address: "address" } });

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={companySchema}>
      <Stack gap={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="head20Semi" fontWeight={100} color={text.primary}>
            基本情報の編集
          </Typography>
          <Button type="submit" variant="primary" size="sm">
            保存
          </Button>
        </Stack>
        <InnerBox>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormField control={control} name="companyName">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="companyFuriganaName">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="representativeName">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="representativeFuriganaName">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="representativeJobTitle">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="division">
                <Select enumName="ECompanyDivision" />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="capital">
                <Input endAdornment="円" clearable={false} disabled={divisionWatchField === COMPANY_DIVISION.SOLE_PROPRIETOR} />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="companyIdentificationNumber" required={divisionWatchField === COMPANY_DIVISION.CORPORATE}>
                <Input disabled={divisionWatchField === COMPANY_DIVISION.SOLE_PROPRIETOR} />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="businessInsuranceNumber">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="employmentInsuranceNumber">
                <Input />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={12}>
              <Stack gap={2}>
                <Typography variant="body14Bold">本社住所</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <FormField control={control} name="zipCode">
                      <Input />
                    </FormField>
                  </Grid>
                  <Grid item xs={4}>
                    <FormField control={control} name="province">
                      <Input readonly />
                    </FormField>
                  </Grid>
                  <Grid item xs={4}>
                    <FormField control={control} name="municipality">
                      <Input readonly />
                    </FormField>
                  </Grid>
                  <Grid item xs={8}>
                    <FormField control={control} name="address">
                      <Input />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="telNumber">
                <Input />
                {telNumberHint && (
                  <Typography variant="cap12" mt={0.5}>
                    {telNumberHint}
                  </Typography>
                )}
              </FormField>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="employeeCount">
                <Input endAdornment="人" clearable={false} />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="annualRevenue">
                <Input endAdornment="円" clearable={false} />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="yearsInBusiness">
                <Input endAdornment="年度" clearable={false} numberFormat={false} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <Stack gap={2}>
                <Typography variant="body14Bold">支援者情報</Typography>
                <SupporterFormTable />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="specificIndustryId">
                <Select
                  onChange={() => {
                    setValue("specificBusinessClassificationIds", []);
                  }}
                  loading={isLoadingCompanySpecificIndustry}
                  options={companySpecificIndustryOptions}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="specificBusinessClassificationIds">
                <SelectMulti
                  loading={isLoadingCompanySpecificIndustry}
                  // disabled={!specificIndustryIdWatchField}
                  options={
                    specificIndustryIdWatchField
                      ? getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry(specificIndustryIdWatchField)
                      : []
                  }
                />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="specificIndustryTwoId">
                <Select
                  onChange={() => {
                    setValue("specificBusinessClassificationTwoIds", []);
                  }}
                  loading={isLoadingCompanySpecificIndustry}
                  options={companySpecificIndustryOptions}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="specificBusinessClassificationTwoIds">
                <SelectMulti
                  loading={isLoadingCompanySpecificIndustry}
                  // disabled={!specificIndustryTwoIdWatchField}
                  options={
                    specificIndustryTwoIdWatchField
                      ? getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry(specificIndustryTwoIdWatchField)
                      : []
                  }
                />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="specificIndustryThreeId">
                <Select
                  onChange={() => {
                    setValue("specificBusinessClassificationThreeIds", []);
                  }}
                  loading={isLoadingCompanySpecificIndustry}
                  options={companySpecificIndustryOptions}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="specificBusinessClassificationThreeIds">
                <SelectMulti
                  loading={isLoadingCompanySpecificIndustry}
                  // disabled={!specificIndustryThreeIdWatchField}
                  options={
                    specificIndustryThreeIdWatchField
                      ? getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry(specificIndustryThreeIdWatchField)
                      : []
                  }
                />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="mainIndustryId">
                <Select
                  loading={isLoadingMainIndustry}
                  options={mainIndustryOptions}
                  onChange={() => {
                    methods.setValue("mainIndustryOther", null);
                    methods.clearErrors("mainIndustryOther");
                  }}
                />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="mainIndustryOther">
                <Input disabled={!mainIndustryOtherIds.includes(Number(mainIndustryIdWatchField))} />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="immigrationJurisdiction">
                <Select enumName="EImmigrationJurisdiction" />
              </FormField>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <FormField control={control} name="payrollDeadline">
                <Select enumName="EPayrollDeadline" />
              </FormField>
            </Grid>
            <Grid item xs={4}>
              <FormField control={control} name="paymentDate">
                <Select enumName="EPaymentDate" disabled={paymentDateWatchField === "month_end_closing"} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="companyNote">
                <Textarea />
              </FormField>
            </Grid>
          </Grid>
        </InnerBox>
      </Stack>
    </Form>
  );
};
