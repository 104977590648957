import { useEmployeeList } from "@/api/services/main/employee";
import { EMPLOYEE_FILTER } from "@/features/employee/constants/employeeFilter";
import { toNumber } from "@/utils/number";

type UseGetProcessingAndRetireEmployeeQuantityReturn = {
  quantity: number;
};
export const useGetProcessingAndRetireEmployeeQuantity = (): UseGetProcessingAndRetireEmployeeQuantityReturn => {
  const { data } = useEmployeeList({
    filter: EMPLOYEE_FILTER.PROCESSING_AND_RETIRE,
  });
  return {
    quantity: toNumber(data?.pagination.total),
  };
};
