import { useCompanyInfo } from "@/api/services/main/company";
import { useRetirementAcceptingWorkerDocument } from "@/api/services/main/retirement-document/retirement-accepting-worker-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { RetirementAcceptingWorkerDetailPage } from "@/features/retirement/pages/documents/RetirementAcceptingWorkerDetail";
import { getInitValues } from "@/features/retirement/utils/retirement-accepting-worker";
import { toNumber } from "@/utils/number";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const RetirementAcceptingWorkerDetail = () => {
  const { id } = useParams();
  const { data: acceptingWorkerResult, isLoading: isLoadingAcceptingWorker } = useRetirementAcceptingWorkerDocument(toNumber(id));
  const acceptingWorkerData = acceptingWorkerResult?.data;
  const { data: companyResult, isLoading } = useCompanyInfo();
  const companyData = companyResult?.data;

  const previousValues = useMemo(() => {
    return getInitValues({ companyData, acceptingWorkerData });
  }, [companyData, acceptingWorkerData]);

  if (isLoading || !companyResult || isLoadingAcceptingWorker) return <LoadingPage />;
  return <RetirementAcceptingWorkerDetailPage defaultValues={previousValues} />;
};

export default RetirementAcceptingWorkerDetail;
