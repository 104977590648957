import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConfirmReplaceContentProps = {
  title: string;
  content: string;
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};

export const ConfirmReplaceContent: FC<TConfirmReplaceContentProps> = ({ title, content, open, onClose, onOk }) => {
  return (
    <Modal title={title} isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい">
      <Stack mb={2}>
        <Typography>{content}</Typography>
      </Stack>
    </Modal>
  );
};
