import { ScrollTop } from "@/components/commons/ScrollTop";
import { AppDrawer } from "@/components/layout/elements/AppDrawer";
import { AppHeader } from "@/components/layout/elements/AppHeader";
import { Chat } from "@/features/chat/components/Chat";
import { ChatProvider } from "@/features/chat/providers/ChatProvider";
import { SocketProvider } from "@/features/chat/providers/SocketProvider";
import { withAuth } from "@/hoc/withAuth";
import { useVisible } from "@/hooks/useVisible";
import { InitSetupProvider } from "@/providers/InitSetupProvider";
import { useMaintenance } from "@/providers/MaintenanceProvider";
import { background } from "@/theme/colors";
import { spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { MAINTENANCE_MODE } from "@/types/enum";
import { Box, Stack, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

export const CompanyLayout: FCC = ({ children }) => {
  const showDrawer = useVisible();
  const { data } = useMaintenance();
  return (
    <InitSetupProvider>
      <ScrollTop />
      <AppHeader handleOpenDrawer={showDrawer.open} />
      <StackWrapper direction="row">
        <AppDrawer isOpenDrawer={showDrawer.visible} toggleDrawer={showDrawer.toggle} />
        <ChildrenWrapper onClick={showDrawer.close}>
          <Outlet />
          {children}
        </ChildrenWrapper>
      </StackWrapper>
      {data?.maintenanceMode === MAINTENANCE_MODE.SYSTEM_NORMAL_OPERATION && (
        <SocketProvider>
          <ChatProvider>
            <Chat />
          </ChatProvider>
        </SocketProvider>
      )}
    </InitSetupProvider>
  );
};

export const CompanyLayoutWithAuth = withAuth(CompanyLayout);

const ChildrenWrapper = styled(Box)`
  padding: ${spacing.md};
  position: relative;
  flex: 1 1 0;
  min-width: 0;
  max-width: 1200px;
  margin: 0 auto;
`;

const StackWrapper = styled(Stack)`
  min-height: calc(100vh - 57px);
  background-color: ${background.primary};
`;
