import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RadioGroup } from "@/components/elements/RadioGroup";
import {
  EModifyContractTerminateReason,
  EModifyContractTerminateReasonForCompany,
  EModifyContractTerminateReasonForEmployee,
} from "@/features/retirement/constants/enums";
import { TRetirementEmploymentContractForm } from "@/features/retirement/types/retirementEmploymentContractForm";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TSpecificSkillTerminationFormProps = {
  readonly?: boolean;
};

export const SpecificSkillTerminationForm: FC<TSpecificSkillTerminationFormProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TRetirementEmploymentContractForm>();
  const terminateContractReason = useWatch({ control, name: "terminateContractReason" });
  const terminateContractReasonByCompany = useWatch({ control, name: "terminateContractReasonByCompany" });
  const terminateContractReasonByEmployee = useWatch({ control, name: "terminateContractReasonByEmployee" });

  const { data: modifyContractTerminateReasonOptions } = useEnum("EModifyContractTerminateReason");
  const { data: modifyContractTerminateReasonForCompanyOptions } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: modifyContractTerminateReasonForEmployeeOptions } = useEnum("EModifyContractTerminateReasonForEmployee");

  return (
    <InnerBox title="契約の終了" required gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="terminateContractDate">
            <InputDatePicker readonly />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <FormField control={control} name="terminateContractReason" largeLabel>
            <RadioGroup
              options={modifyContractTerminateReasonOptions}
              disabled={readonly}
              onChange={(value) => {
                {
                  if (value !== EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY) {
                    setValue("terminateContractReasonByCompany", null);
                    setValue("terminateContractOtherByCompany", null);
                  }
                  if (value !== EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE) {
                    setValue("terminateContractReasonByEmployee", null);
                    setValue("terminateContractOtherReasonByEmployee", null);
                  }
                }
              }}
            />
          </FormField>
        </Grid>
        {terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY && (
          <>
            <Grid item xs={12}>
              <FormField control={control} name="terminateContractReasonByCompany" largeLabel>
                <RadioGroup
                  options={modifyContractTerminateReasonForCompanyOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== EModifyContractTerminateReasonForCompany.OTHER) {
                      setValue("terminateContractOtherByCompany", null);
                    }
                  }}
                />
              </FormField>
            </Grid>
            {terminateContractReasonByCompany === EModifyContractTerminateReasonForCompany.OTHER && (
              <Grid item xs={12}>
                <FormField control={control} name="terminateContractOtherByCompany" label="">
                  <Input readonly={readonly} />
                </FormField>
              </Grid>
            )}
          </>
        )}
        {terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE && (
          <>
            <Grid item xs={12}>
              <FormField control={control} name="terminateContractReasonByEmployee" largeLabel>
                <RadioGroup
                  options={modifyContractTerminateReasonForEmployeeOptions.map((option, i) => {
                    if (i === 3) {
                      return { ...option, helper: `外国人の責めに帰すべき重大な理由に\nよる解雇の場合に選択。` };
                    }
                    return option;
                  })}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== EModifyContractTerminateReasonForEmployee.OTHER) {
                      setValue("terminateContractOtherReasonByEmployee", null);
                    }
                  }}
                />
              </FormField>
            </Grid>
            {terminateContractReasonByEmployee === EModifyContractTerminateReasonForEmployee.OTHER && (
              <Grid item xs={12}>
                <FormField control={control} name="terminateContractOtherReasonByEmployee" label="">
                  <Input readonly={readonly} />
                </FormField>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </InnerBox>
  );
};
