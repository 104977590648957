import { useGetMainOccupationList } from "@/api/services/global/master";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { EmployeeCreatePage } from "@/features/employee/pages/EmployeeCreate";

const EmployeeCreate = () => {
  const { isLoading: isLoadingMainOccupation } = useGetMainOccupationList();

  if (isLoadingMainOccupation) return <LoadingPage />;

  return <EmployeeCreatePage />;
};

export default EmployeeCreate;
