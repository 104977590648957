import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { TLifeSupportForm } from "@/features/regular-document/types/lifeSupportForm";
import { SUPPORT_IMPLEMENTATION_STATUS } from "@/types/enum";
import { findLabelByValue } from "@/utils/object";
import { Stack, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";

export const SupportStatusReadonly = () => {
  const { control } = useFormContext<TLifeSupportForm>();
  const { data: supportImplementationStatusOptions } = useEnum("ESupportImplementationStatus");

  const values = useWatch({ control });

  return (
    <InnerBox title="支援実施状況" bordered p={3} required>
      <Stack gap={3}>
        <Stack gap={1}>
          <FormField label="1. 空港などへの出迎え" control={control} name="airportPickup">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.airportPickup)}</Typography>
          </FormField>
          {values.airportPickup === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.airportPickupReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="2. 空港への見送り" control={control} name="seeOffAirport">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.seeOffAirport)}</Typography>
          </FormField>
          {values.seeOffAirport === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.seeOffAirportReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="3. 生活の確保・生活に必要な契約に関する支援" control={control} name="supportLivelihood">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.supportLivelihood)}</Typography>
          </FormField>
          {values.supportLivelihood === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.supportLivelihoodReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="4. 生活オリエンテーション" control={control} name="supportLifeOrientation">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.supportLifeOrientation)}</Typography>
          </FormField>
          {values.supportLifeOrientation === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.supportLifeOrientationReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="5. 関係機関への同行その他必要な支援" control={control} name="accompanyToRelevantInstitutionsAndOtherSupport">
            <Typography variant="body14">
              {findLabelByValue(supportImplementationStatusOptions, values.accompanyToRelevantInstitutionsAndOtherSupport)}
            </Typography>
          </FormField>
          {values.accompanyToRelevantInstitutionsAndOtherSupport === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.accompanyToRelevantInstitutionsAndOtherSupportReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="6. 日本語学習の機会の提供" control={control} name="supportLearnJapanese">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.supportLearnJapanese)}</Typography>
          </FormField>
          {values.supportLearnJapanese === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.supportLearnJapaneseReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="7. 相談・苦情対応" control={control} name="handleConsultationAndComplaint"></FormField>
          {values.handleConsultationAndComplaint === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.handleConsultationAndComplaintReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="8. 日本人との交流促進" control={control} name="supportExchangeWithJapanesePeople">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.supportExchangeWithJapanesePeople)}</Typography>
          </FormField>
          {values.supportExchangeWithJapanesePeople === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.supportExchangeWithJapanesePeopleReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="9. 非自発的離職時の転職支援" control={control} name="supportFindJob">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.supportFindJob)}</Typography>
          </FormField>
          {values.supportFindJob === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.supportFindJobReason}</Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <FormField label="10. 定期的な面談の実施" control={control} name="conductRegularInterview">
            <Typography variant="body14">{findLabelByValue(supportImplementationStatusOptions, values.conductRegularInterview)}</Typography>
          </FormField>
          {values.conductRegularInterview === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Typography variant="body14">未実施の理由: {values.conductRegularInterviewReason}</Typography>
          )}
        </Stack>
      </Stack>
    </InnerBox>
  );
};
