import { FormField } from "@/components/elements/FormField";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { TSupportPlanForm } from "@/features/anytime/types/supportPlanForm";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TProps = {
  readonly?: boolean;
};

export const ChangesForm: FC<TProps> = ({ readonly }) => {
  const { control } = useFormContext<TSupportPlanForm>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormField control={control} name="modifyContractDate">
          <InputDatePicker readonly={readonly} />
        </FormField>
      </Grid>
    </Grid>
  );
};
