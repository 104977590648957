import { useGetRenewVisaDocumentInfo, useGetRenewVisaEmployeeApplicationDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { EmployeeApplicationForm } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeApplicationForm";
import { convertResToEmployeeApplicationFormData } from "@/features/renew-visa/utils/convertResToEmployeeApplicationFormData";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

type TRenewVisaHistoryEmployeeApplicationPageProps = {};

export const RenewVisaHistoryEmployeeApplicationPage: FCC<TRenewVisaHistoryEmployeeApplicationPageProps> = ({}) => {
  const { renewVisaDocumentId } = useParams();

  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const { data: employeeApplicationData, isLoading: isLoadingEmployeeApplication } = useGetRenewVisaEmployeeApplicationDocumentInfo();

  const isLoading = useMemo(
    () => isLoadingRenewVisaDocument || isLoadingEmployeeApplication,
    [isLoadingRenewVisaDocument, isLoadingEmployeeApplication],
  );

  if (isLoading) return <LoadingPage />;
  return (
    <Box maxWidth={946}>
      <TopPage
        title={`${getFullName({ firstName: renewVisaDocument?.employee.firstName ?? "", middleName: renewVisaDocument?.employee.middleName, lastName: renewVisaDocument?.employee.lastName ?? "" })}さんの申請データの作成`}
        backUrl={getDynamicRoute(AppRoutes.renewVisaInfoHistoryDetail, { renewVisaDocumentId })}
      />
      <EmployeeApplicationForm
        readonly
        previousValues={convertResToEmployeeApplicationFormData(renewVisaDocument?.employee, employeeApplicationData)}
      />
    </Box>
  );
};
