import * as React from "react";

export const IconWarningInfo = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_16593_4061)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
        fill="#E15F00"
      />
    </g>
    <defs>
      <clipPath id="clip0_16593_4061">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
