import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { Dayjs } from "dayjs";

type TCaculateSpecificSkillTransitionPeriodParams = {
  skillOneStartDate: Dayjs | null | undefined;
  blankPeriodMonthly: string | null | undefined;
};

// caculate 特定技能経過期
export const caculateSpecificSkillTransitionPeriod = ({ skillOneStartDate, blankPeriodMonthly }: TCaculateSpecificSkillTransitionPeriodParams) => {
  const totalMonth = skillOneStartDate ? dayjs(new Date()).diff(skillOneStartDate, "M") - toNumber(blankPeriodMonthly) : 0;
  const yearPeriod = Math.floor(totalMonth / 12);
  const monthPeriod = totalMonth % 12;
  const period = `${yearPeriod > 0 ? yearPeriod + "年" : ""}${monthPeriod > 0 ? monthPeriod + "ヶ月" : ""}` || "0ヶ月";
  return { period, yearPeriod, monthPeriod };
};
