import {
  createRetirementEmploymentContractDocumentApi,
  updateRetirementEmploymentContractDocumentApi,
} from "@/api/services/main/retirement-document/employment-contract-document";
import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { ButtonGroup } from "@/features/retirement/components/retirement-detail/accepting-worker/ButtonGroup";
import { CompanySupporterForm } from "@/features/retirement/components/retirement-detail/accepting-worker/CompanySupporterForm";
import { RetirementEmployeeForm } from "@/features/retirement/components/retirement-detail/employment-contract/forms/RetirementEmployeeForm";
import { SpecificSkillManagingAgency } from "@/features/retirement/components/retirement-detail/employment-contract/SpecificSkillManagingAgency";
import { TargetForeigner } from "@/features/retirement/components/retirement-detail/employment-contract/TargetForeigner";
import { retirementEmploymentContractSchema } from "@/features/retirement/schema/retirementEmploymentContractSchema";
import { TRetirementEmploymentContractForm } from "@/features/retirement/types/retirementEmploymentContractForm";
import { convertFormDataToReqDto } from "@/features/retirement/utils/retirement-employment-contract";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TRetirementEmploymentContractPageProps = {
  defaultValues?: Partial<TRetirementEmploymentContractForm>;
  isCreating: boolean;
  isDraft: boolean;
};

export const RetirementEmploymentContractPage: FC<TRetirementEmploymentContractPageProps> = ({ defaultValues, isCreating, isDraft }) => {
  const { documentGroupId, id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TRetirementEmploymentContractForm>({
    defaultValues,
    resolver: yupResolver(retirementEmploymentContractSchema),
  });
  const { getValues } = methods;
  const [editable, setEditable] = useState(isCreating || isDraft);

  const handleSave = async (data: TRetirementEmploymentContractForm, isDraft: boolean) => {
    try {
      if (!id) return;
      const payload = convertFormDataToReqDto(data, isDraft);
      if (isCreating) {
        const rs = await createRetirementEmploymentContractDocumentApi(Number(id), payload);
        navigate(`${getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })}#employment_contract_section`);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await updateRetirementEmploymentContractDocumentApi(Number(id), payload);
        navigate(`${getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })}#employment_contract_section`);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_EMPLOYEE_CONTRACT] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });
      if (isDraft) {
        methods.unregister();
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TRetirementEmploymentContractForm) => {
    await handleSave(data, false);
  };

  const saveDraft = async () => {
    await handleSave(getValues() as TRetirementEmploymentContractForm, true);
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={retirementEmploymentContractSchema}>
      <TopPage title="特定技能雇用契約に係る届出" backUrl={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })} isDraft={isDraft} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <RetirementEmployeeForm readonly={!editable} /> },
          { title: "届出の事由", content: <TargetForeigner readonly={!editable} /> },
          { title: "特定技能所属機関", content: <SpecificSkillManagingAgency readonly={!editable} /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly={!editable} /> },
        ]}
        contentAffix={<ButtonGroup saveDraft={saveDraft} isEditable={editable} setIsEditable={setEditable} />}
      />
    </Form>
  );
};
