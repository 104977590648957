import { validator } from "@/utils/validator";

export const renewVisaResidenceCardInfoSchema = validator.object().shape({
  residenceStatusId: validator.string().trim().isSelection().emptyToNull().label("在留資格").required(),
  residencePeriodId: validator.string().isSelection().trim().emptyToNull().label("在留期間").required(),
  residenceExpiryDate: validator.mixed().isSelection().isDayjs().label("在留期限（在留期間満了日）").required(),
  residenceCode: validator
    .string()
    .trim()
    .emptyToNull()
    .length(12)
    .uppercaseAlphanumericCharactersOnly()
    .autoTransformToHalfWidth()
    .label("在留カード番号")
    .required(),
  residenceCardFrontFile: validator
    .array()
    .of(validator.mixed().isExtendFile())
    .label("在留カード写し表データ")
    .min(1, "「在留カード写し表データ」を選択してください。")
    .required(),
  residenceCardBackFile: validator
    .array()
    .of(validator.mixed().isExtendFile())
    .label("在留カード写し裏データ")
    .min(1, "「在留カード写し裏データ」を選択してください。")
    .required(),
});
