import { TUpdateEmployeeReqDto, TUpdateEmployeeResDto } from "@/api/services/global/employee/dtos/update-employee.dto";
import { TUploadEmployeeAvatarReqDto, TUploadEmployeeAvatarResDto } from "@/api/services/global/employee/dtos/upload-employee-avatar.dto";
import { pick } from "@/utils/object";
import { createUrlWithParams } from "@/utils/url";
import axios from "axios";

export const globalBaseURL = `${process.env.REACT_APP_API_HOST}/global`;

export const globalApiNotInterceptor = axios.create({
  baseURL: globalBaseURL,
});

export const updateEmployeeInfoApi = async (employeeId: string, payload: TUpdateEmployeeReqDto): Promise<TUpdateEmployeeResDto> => {
  const url = createUrlWithParams(`/employee/${employeeId}`, {
    ...pick(payload, "companyId", "token", "renewVisaDocumentStepId", "userId"),
    employeeId,
  });
  const rs = await globalApiNotInterceptor.put<TUpdateEmployeeResDto>(url, { avatarFilePath: payload.avatarFilePath }).catch((err) => {
    throw err;
  });
  return rs.data;
};

export const uploadEmployeeAvatarApi = async (employeeId: string, payload: TUploadEmployeeAvatarReqDto): Promise<TUploadEmployeeAvatarResDto> => {
  const url = createUrlWithParams(`/employee/${employeeId}/upload-avatar`, { ...pick(payload, "companyId", "token"), employeeId });
  const formData = new FormData();
  formData.append("file", payload.file);
  const rs = await globalApiNotInterceptor.post(url, formData);
  return rs.data;
};
