import { useGetRenewVisaApplicationInformationDocumentInfo, useGetRenewVisaDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { DATE_FORMAT } from "@/constants/datetime";
import { RenewVisaAfterSubmitApplicationStep } from "@/features/renew-visa/components/present/RenewVisaAfterSubmitApplicationStep";
import { RenewVisaApplicationStep } from "@/features/renew-visa/components/present/RenewVisaApplicationStep";
import { RenewVisaBasicInfoStep } from "@/features/renew-visa/components/present/RenewVisaBasicInfoStep";
import { RenewVisaOnlineSubmitApplicationStep } from "@/features/renew-visa/components/present/RenewVisaOnlineSubmitApplicationStep";
import { RenewVisaResidenceCardInfoStep } from "@/features/renew-visa/components/present/RenewVisaResidenceCardInfoStep";
import { findRenewVisaDocumentStep } from "@/features/renew-visa/utils/findDocumentStep";
import { AppRoutes } from "@/routes/config";
import { background, button, divider } from "@/theme/colors";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { toNumber } from "@/utils/number";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const RenewVisaHistoryDetailPage = () => {
  const { renewVisaDocumentId } = useParams();

  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const basicInfoStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_BASIC_INFORMATION);
  const applicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_CREATE_DOCUMENT);
  const onlineSubmitApplicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_SUBMIT);
  const afterSubmitApplicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_AFTER_SUBMIT_APPLICATION);
  const residenceCardInfoStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_UPDATE_RESIDENCE_CARD);

  const { data: applicationInformationData, isLoading: isLoadingApplicationInformationData } = useGetRenewVisaApplicationInformationDocumentInfo({
    documentStepId: afterSubmitApplicationStepData?.id ?? "",
    enabled: !!afterSubmitApplicationStepData?.id,
  });

  const isLoading = useMemo(
    () => isLoadingRenewVisaDocument || isLoadingApplicationInformationData,
    [isLoadingRenewVisaDocument, isLoadingApplicationInformationData],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <TopPage
        title={`${getFullName({ firstName: renewVisaDocument?.employee.firstName ?? "", middleName: renewVisaDocument?.employee.middleName, lastName: renewVisaDocument?.employee.lastName ?? "" })}さんの在留期間更新`}
        backUrl={getDynamicRoute(AppRoutes.renewVisaInfoHistory, { renewVisaDocumentId })}
      />
      <Box p="24px" border={`1px solid ${divider.middle}`} borderRadius={2} bgcolor={background.white}>
        <Typography component="h2" variant="head20Bold" color={button.pressed} mb={3}>
          {applicationInformationData && dayjs(applicationInformationData.applicationDate).format(DATE_FORMAT)}
        </Typography>
        <Stack gap="24px">
          {basicInfoStepData && <RenewVisaBasicInfoStep stepData={basicInfoStepData} readonly />}
          {applicationStepData && basicInfoStepData && (
            <RenewVisaApplicationStep
              employeeId={toNumber(renewVisaDocument?.employeeId)}
              basicInfoStepData={basicInfoStepData}
              stepData={applicationStepData}
              readonly
            />
          )}
          {onlineSubmitApplicationStepData && <RenewVisaOnlineSubmitApplicationStep stepData={onlineSubmitApplicationStepData} readonly />}
          {afterSubmitApplicationStepData && <RenewVisaAfterSubmitApplicationStep stepData={afterSubmitApplicationStepData} readonly />}
          {residenceCardInfoStepData && <RenewVisaResidenceCardInfoStep stepData={residenceCardInfoStepData} readonly />}
        </Stack>
      </Box>
    </>
  );
};
