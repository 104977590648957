import { ConfirmLeavePage } from "@/components/commons/ConfirmLeavePage";
import { FCC } from "@/types/common";
import { isEmpty } from "@/utils/object";
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from "react";
import { FieldValues, FormProvider, Path, UseFormReturn } from "react-hook-form";
import { BlockerFunction } from "react-router-dom";
import { ObjectSchema } from "yup";

type TFormProps<T extends FieldValues> = {
  methods: UseFormReturn<T, object, undefined>;
  onSubmit: (data: T) => void;
  shouldBlock?: boolean | BlockerFunction;
  enableBlocker?: boolean;
  children: ReactNode;
  schema: ObjectSchema<object>;
};

export const Form = <T extends FieldValues>({ methods, onSubmit, shouldBlock = false, enableBlocker = true, children, schema }: TFormProps<T>) => {
  const ref = useRef<HTMLFormElement | null>(null);
  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!ref.current) return;
    if (!errors) return;
    const firstError = Object.keys(errors)[0] as Path<T>;
    if (firstError) {
      const field = ref.current.querySelector(`[name="${firstError}"]`) as HTMLInputElement;
      field?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errors]);

  const isBlockedForm = useMemo(() => {
    if (methods.formState.isSubmitted && isEmpty(methods.formState.errors)) return false;
    if (isEmpty(methods.formState.dirtyFields)) return false;
    return true;
  }, [methods.formState]);

  return (
    <FormProvider {...methods}>
      <FormSchemaProvider schema={schema}>
        {enableBlocker && <ConfirmLeavePage shouldBlock={shouldBlock || isBlockedForm} />}
        <form onSubmit={methods.handleSubmit(onSubmit)} ref={ref}>
          {children}
        </form>
      </FormSchemaProvider>
    </FormProvider>
  );
};

const FormSchemaContext = createContext<Record<string, TFieldMeta>>({});

type TFormSchemaProviderProps = {
  schema: ObjectSchema<object>;
};

export type TFieldMeta = {
  label: string;
  required: boolean;
  name: string;
  type: string;
  meta: {
    type?: string;
    isZipCode?: boolean;
    isFurigana?: boolean;
    autoTransformToHalfWidth?: boolean;
    autoTransformToFullWidth?: boolean;
  };
};

const FormSchemaProvider: FCC<TFormSchemaProviderProps> = ({ children, schema }) => {
  const fieldsMeta = useMemo(() => {
    const { fields } = schema as ObjectSchema<Record<string, unknown>>;
    return Object.keys(fields).reduce(
      (cur, fieldName) => {
        const fieldMeta: TFieldMeta = { name: fieldName, required: false, label: "", type: "", meta: {} };
        try {
          if (fieldName in fields) {
            const data = fields[fieldName].describe();
            if ("label" in data && data.label) {
              fieldMeta.label = data.label;
            }
            if ("optional" in data && data.optional === false && "nullable" in data && data.nullable === false) {
              fieldMeta.required = true;
            }
            if ("type" in data && data.type) {
              fieldMeta.type = data.type;
            }
            if ("meta" in data && data.meta) {
              fieldMeta.meta = data.meta;
            }
          }
        } catch (error) {
          // do nothing
        }
        cur[fieldName] = fieldMeta;
        return cur;
      },
      {} as Record<string, TFieldMeta>,
    );
  }, [schema]);

  return <FormSchemaContext.Provider value={fieldsMeta}>{children}</FormSchemaContext.Provider>;
};

export const useFormSchema = () => {
  return useContext(FormSchemaContext);
};
