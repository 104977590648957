import { useAnytimeEmploymentContract } from "@/api/services/main/any-time/employment-contract";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AnytimeEmploymentContractEditPage } from "@/features/anytime/pages/documents/AnytimeEmploymentContractEdit";
import { convertResDtoToFormData } from "@/features/anytime/utils/employment-contract";

const AnytimeEmploymentContractEdit = () => {
  const { data: employmentContractResult, isLoading } = useAnytimeEmploymentContract();
  const employmentContractData = employmentContractResult?.data;

  if (isLoading || !employmentContractData) return <LoadingPage />;

  return (
    <AnytimeEmploymentContractEditPage defaultValues={convertResDtoToFormData(employmentContractData)} isDraft={employmentContractData.isDraft} />
  );
};

export default AnytimeEmploymentContractEdit;
