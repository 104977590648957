import { useEnum } from "@/api/services/global/enum";
import { useGetMainOccupationList, useGetSpecificIndustryList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Select } from "@/components/elements/Select";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { text } from "@/theme/colors";
import { YES_NO_ANSWER } from "@/types/enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Box, Divider, Grid, Accordion as MuiAccordion, styled, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TEmployeeContractFormProps = {
  readonly: boolean;
};

export const EmployeeContractForm: FC<TEmployeeContractFormProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control } = methods;

  const { data: yesNoAnswerOptions } = useEnum("EYesNoAnswer");
  const { data: yesNoAndNotApplicableAnswerOptions } = useEnum("EYesNoAndNotApplicableAnswer");

  const contractIsDiscriminatedForBeingForeignerWatchField = useWatch({
    control,
    name: "contractIsDiscriminatedForBeingForeigner",
  });

  const { isLoading: isLoadingMainOccupation, contractMainOccupationOptions } = useGetMainOccupationList();

  const { data: specificIndustry, isLoading: isLoadingSpecificIndustry } = useGetSpecificIndustryList();

  const contractSpecificIndustryOptions = useMemo(
    () =>
      specificIndustry
        ? specificIndustry.map((item) => ({ label: item.companySpecificIndustry.companySpecificIndustry, value: item.id, hidden: item.isHidden }))
        : [],
    [specificIndustry],
  );

  const contractSpecificBusinessClassificationOptions = useMemo(() => {
    return specificIndustry.flatMap((item) =>
      item.specificBusinessClassifications.map((item) => ({
        label: item.companySpecificBusinessClassification.companySpecificBusinessClassificationName ?? "",
        value: item.id,
        hidden: item.isHidden,
      })),
    );
  }, [specificIndustry]);

  return (
    <InnerBox title="特定技能雇用契約" bordered p={3} required>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="contractSalaryPaymentMethod">
            <Select enumName="EPaymentMethod" readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="contractHourlySalary">
            <Input endAdornment="円" clearable={false} readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="contractMonthlySalaryForJapanesePerson">
            <Input endAdornment="円" clearable={false} readonly={readonly} />
            <Typography variant="cap12" color={text.secondary} mt="4px">
              （該当がない場合は空欄とする）
            </Typography>
          </FormField>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
        <AccordionSummaryWrapper
          sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
          expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
        >
          省略項目を表示
        </AccordionSummaryWrapper>
        <AccordionDetails>
          <Divider sx={{ mb: 5 }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormField control={control} name="contractStartDate">
                <InputDatePicker readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractEndDate">
                <InputDatePicker readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractSpecificIndustryId">
                <Select options={contractSpecificIndustryOptions} loading={isLoadingSpecificIndustry} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractSpecificBusinessClassificationId">
                <Select options={contractSpecificBusinessClassificationOptions} loading={isLoadingSpecificIndustry} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractSpecificIndustryTwoId">
                <Select options={contractSpecificIndustryOptions} loading={isLoadingSpecificIndustry} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractSpecificBusinessClassificationTwoId">
                <Select options={contractSpecificBusinessClassificationOptions} loading={isLoadingSpecificIndustry} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractSpecificIndustryThreeId">
                <Select options={contractSpecificIndustryOptions} loading={isLoadingSpecificIndustry} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractSpecificBusinessClassificationThreeId">
                <Select options={contractSpecificBusinessClassificationOptions} loading={isLoadingSpecificIndustry} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractMainOccupationId">
                <Select options={contractMainOccupationOptions} loading={isLoadingMainOccupation} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <FormField control={control} name="contractWeeklyWorkingHours">
                <Input endAdornment="時間" clearable={false} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractMonthlyWorkingHours">
                <Input endAdornment="時間" clearable={false} readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="whetherEquivalentWorkingHoursToRegularEmployee">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="contractMonthlySalary">
                <Input endAdornment="円" clearable={false} readonly />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="compensationEqualOrAboveJapaneseStandard">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="contractIsDiscriminatedForBeingForeigner">
                <RadioGroup
                  direction="row"
                  options={yesNoAnswerOptions}
                  disabled={readonly}
                  onChange={(value) => {
                    if (value !== YES_NO_ANSWER.YES) {
                      methods.setValue("contractIsDiscriminatedForBeingForeignerDetail", "");
                    }
                  }}
                />
              </FormField>
              <Box sx={{ width: "calc(50% - 16px)" }}>
                <FormField sx={{ mt: 0.5 }} control={control} name="contractIsDiscriminatedForBeingForeignerDetail">
                  <Input disabled={contractIsDiscriminatedForBeingForeignerWatchField === YES_NO_ANSWER.NO || readonly} />
                </FormField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="contractHasPaidLeaveForReturningHomeTemporarily">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="contractHasComplianceWithEmploymentStandardsInSpecificIndustry">
                <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                <Typography color="#D11F4E" variant="cap12" mt="4px">
                  当該基準が定められている場合に入力
                </Typography>
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="contractHasProvisionForTravelExpensesWhenLeave">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="complianceWithStandardsForProperResidenceInSpecificIndustry">
                <RadioGroup direction="row" options={yesNoAnswerOptions} disabled={readonly} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="contractHasSupportForeignerHealthAndLivingConditions">
                <RadioGroup direction="row" options={yesNoAndNotApplicableAnswerOptions} disabled={readonly} />
                <Typography color="#D11F4E" variant="cap12" mt="4px">
                  当該基準が定められている場合に入力
                </Typography>
              </FormField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </MuiAccordionWrapper>
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &::before {
    display: none;
  }

  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
