import * as React from "react";

export const IconAvatar = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => (
  <svg width="1em" height="em" viewBox="0 0 132 146" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3674 98.1174C18.1656 91.3192 27.3859 87.5 37 87.5H95C104.614 87.5 113.834 91.3192 120.633 98.1174C127.431 104.916 131.25 114.136 131.25 123.75V138.25C131.25 142.254 128.004 145.5 124 145.5C119.996 145.5 116.75 142.254 116.75 138.25V123.75C116.75 117.982 114.458 112.449 110.38 108.37C106.301 104.292 100.768 102 95 102H37C31.2315 102 25.6993 104.292 21.6204 108.37C17.5415 112.449 15.25 117.982 15.25 123.75V138.25C15.25 142.254 12.0041 145.5 8 145.5C3.99594 145.5 0.75 142.254 0.75 138.25V123.75C0.75 114.136 4.56918 104.916 11.3674 98.1174Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66 15C53.9878 15 44.25 24.7378 44.25 36.75C44.25 48.7622 53.9878 58.5 66 58.5C78.0122 58.5 87.75 48.7622 87.75 36.75C87.75 24.7378 78.0122 15 66 15ZM29.75 36.75C29.75 16.7297 45.9797 0.5 66 0.5C86.0203 0.5 102.25 16.7297 102.25 36.75C102.25 56.7703 86.0203 73 66 73C45.9797 73 29.75 56.7703 29.75 36.75Z"
      fill="currentColor"
    />
  </svg>
);
