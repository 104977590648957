import { Avatar } from "@/components/elements/Avatar";
import { Modal } from "@/components/elements/Modal";
import { Box } from "@mui/material";
import { FC } from "react";

type TPreviewAvatarModalProps = {
  isOpen: boolean;
  avatarFilePath: string | undefined;
  onClose: () => void;
};

export const PreviewAvatarModal: FC<TPreviewAvatarModalProps> = ({ isOpen, onClose, avatarFilePath }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} enableFooter={false}>
      <Box mb={2}>
        <Avatar filePath={avatarFilePath} width={300} height={400} />
      </Box>
    </Modal>
  );
};
