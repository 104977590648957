import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { useCheckRenewVisaHistoryPage } from "@/features/renew-visa/hook/useCheckRenewVisaHistoryPage";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TCompanyApplicationBtnActionsProps = {
  saveDraft: () => Promise<void>;
  readonly?: boolean;
  isEditable: boolean;
  setIsEditable: (v: boolean) => void;
};

export const CompanyApplicationBtnActions: FC<TCompanyApplicationBtnActionsProps> = ({ saveDraft, readonly, isEditable, setIsEditable }) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const { isHistoryPage } = useCheckRenewVisaHistoryPage();

  return (
    <InnerBox bordered p={3} boxShadow={elevation.lv2}>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="outline"
          size="lg"
          component={Link}
          to={
            isHistoryPage
              ? getDynamicRoute(AppRoutes.renewVisaInfoHistoryEmployeeApplication, { renewVisaDocumentId, documentStepId })
              : getDynamicRoute(AppRoutes.renewVisaPresentEmployeeApplication, { renewVisaDocumentId, documentStepId })
          }
        >
          戻る
        </Button>
        <Stack direction="row" gap={2} justifyContent={"center"}>
          {!readonly && isEditable && (
            <>
              <Button variant="outline" size="lg" onClick={saveDraft}>
                下書き保存する
              </Button>
              <Button type="submit" variant="primary" size="lg">
                保存
              </Button>
            </>
          )}

          {!readonly && !isEditable && (
            <>
              <Button variant="outline" size="lg" onClick={() => setIsEditable(true)}>
                再編集する
              </Button>
              <Button variant="primary" size="lg" component={Link} to={getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId })}>
                次へ
              </Button>
            </>
          )}

          {readonly && (
            <Button
              variant="primary"
              size="lg"
              component={Link}
              to={
                isHistoryPage
                  ? getDynamicRoute(AppRoutes.renewVisaInfoHistoryDetail, { renewVisaDocumentId })
                  : getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId })
              }
            >
              {isHistoryPage ? "届出トップに戻る" : "次へ"}
            </Button>
          )}
        </Stack>
      </Stack>
    </InnerBox>
  );
};
