import { useRegularDocumentHistoryDetail } from "@/api/services/main/regular-document";
import { useLifeSupportDocument } from "@/api/services/main/regular-document/life-support-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { RegularPresentLifeSupportForm } from "@/features/regular-document/components/life-support/RegularPresentLifeSupportForm";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const RegularHistoryLifeSupportPage = () => {
  const { year, quarter } = useParams();
  const { data: presentDocumentListResult, isLoading: isLoadingDocumentData } = useRegularDocumentHistoryDetail(year, quarter);
  const documentData = presentDocumentListResult?.data;
  const stepData = findDocumentStep(documentData?.regularDocuments, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT);
  const stepId = stepData?.id;
  const { data: lifeSupportResult, isLoading: isLoadingAcceptanceActivity } = useLifeSupportDocument({
    documentStepId: stepId,
    enabled: !!stepData?.updater,
  });
  const lifeSupportData = lifeSupportResult?.data;
  // const resourceData = { year: documentData?.year ?? 0, quarter: documentData?.quarter ?? 0 };
  // const { data: employeeListData, isLoading: isEmployeeListData } = useEmployeeList({
  //   isFetchedAll: true,
  //   enabled: stepId === null,
  //   filter: {
  //     startWorking: getStartDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
  //     endWorking: getEndDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
  //   },
  // });

  const employeesInfo = useMemo(() => {
    if (lifeSupportData) {
      return lifeSupportData?.employees.map((employee) => ({
        employeeId: Number(employee.employeeId),
        employeeFullName: employee.employeeFullName,
        employeeGender: employee.employeeGender,
        employeeNationalityId: employee.employeeNationalityId,
        employeeResidenceCardNumber: employee.employeeResidenceCardNumber,
        birthday: employee.birthday,
        zipCode: employee.zipCode,
        fullAddress: employee.fullAddress,
        telNumber: employee.telNumber,
      }));
    }
    // if (employeeListData) {
    //   return employeeListData?.data.map((employee) => ({
    //     employeeId: Number(employee.id),
    //     employeeFullName: getFullName({ firstName: employee.firstName, lastName: employee.lastName, middleName: employee.middleName }),
    //     employeeGender: employee.gender ?? null,
    //     employeeNationalityId: employee.nationalityId ?? null,
    //     employeeResidenceCardNumber: employee.residenceCode ?? "",
    //     birthday: employee.birthday,
    //     zipCode: employee.zipCode,
    //     fullAddress: employee.province && employee.address ? `${employee.province}${employee.address}` : null,
    //     telNumber: employee.telNumber,
    //   }));
    // }
    return [];
  }, [lifeSupportData]);

  const previousValues = useMemo(
    () => ({
      airportPickup: lifeSupportData?.airportPickup ?? "no_participant",
      airportPickupReason: lifeSupportData?.airportPickupReason ?? null,
      seeOffAirport: lifeSupportData?.seeOffAirport ?? "no_participant",
      seeOffAirportReason: lifeSupportData?.seeOffAirportReason ?? null,
      supportLivelihood: lifeSupportData?.supportLivelihood ?? "no_participant",
      supportLivelihoodReason: lifeSupportData?.supportLivelihoodReason ?? null,
      supportLifeOrientation: lifeSupportData?.supportLifeOrientation ?? "no_participant",
      supportLifeOrientationReason: lifeSupportData?.supportLifeOrientationReason ?? null,
      accompanyToRelevantInstitutionsAndOtherSupport: lifeSupportData?.accompanyToRelevantInstitutionsAndOtherSupport ?? "no_participant",
      accompanyToRelevantInstitutionsAndOtherSupportReason: lifeSupportData?.accompanyToRelevantInstitutionsAndOtherSupportReason ?? null,
      supportLearnJapanese: lifeSupportData?.supportLearnJapanese ?? "no_participant",
      supportLearnJapaneseReason: lifeSupportData?.supportLearnJapaneseReason ?? null,
      handleConsultationAndComplaint: lifeSupportData?.handleConsultationAndComplaint ?? "no_participant",
      handleConsultationAndComplaintReason: lifeSupportData?.handleConsultationAndComplaintReason ?? null,
      supportExchangeWithJapanesePeople: lifeSupportData?.supportExchangeWithJapanesePeople ?? "no_participant",
      supportExchangeWithJapanesePeopleReason: lifeSupportData?.supportExchangeWithJapanesePeopleReason ?? null,
      supportFindJob: lifeSupportData?.supportFindJob ?? "no_participant",
      supportFindJobReason: lifeSupportData?.supportFindJobReason ?? null,
      conductRegularInterview: lifeSupportData?.conductRegularInterview ?? "no_participant",
      conductRegularInterviewReason: lifeSupportData?.conductRegularInterviewReason ?? null,
      companySupporterName: lifeSupportData?.companySupporterName ?? null,
      companySupporterTelNumber: lifeSupportData?.companySupporterTelNumber ?? null,
      companySupporterId: lifeSupportData?.companySupporterId ?? null,
    }),
    [lifeSupportData, employeesInfo],
  );

  if (isLoadingDocumentData || isLoadingAcceptanceActivity) return <LoadingPage />;
  return (
    <>
      <RegularPresentLifeSupportForm
        documentStepId={stepData?.id}
        updater={stepData?.updater}
        previousValues={previousValues}
        isDraft={lifeSupportResult?.isDraft}
        resourceData={{ year: documentData?.year, quarter: documentData?.quarter, employees: employeesInfo }}
        readonly
      />
    </>
  );
};
