export const useCacheLocal = (cacheKey: string) => {
  const set = (value: string) => {
    localStorage.setItem(cacheKey, value);
  };

  const setJSON = (value: unknown) => {
    localStorage.setItem(cacheKey, JSON.stringify(value));
  };

  const get = () => {
    if (!isExist()) return null;
    return localStorage.getItem(cacheKey);
  };

  const getJSON = () => {
    try {
      const str = get();
      if (!str) return null;
      return JSON.parse(str);
    } catch {
      return null;
    }
  };

  const remove = () => {
    if (isExist()) {
      localStorage.removeItem(cacheKey);
    }
  };

  const isExist = () => {
    return localStorage.getItem(cacheKey);
  };

  return { isExist, get, getJSON, set, setJSON, remove };
};
