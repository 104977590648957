import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { BlockerFunction, useBlocker } from "react-router-dom";

type TConfirmLeavePageProps = {
  shouldBlock: boolean | BlockerFunction;
  callBackLeave?: () => void;
};

export const ConfirmLeavePage: FC<TConfirmLeavePageProps> = ({ shouldBlock }) => {
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    const beforeUnLoadHandler = (event: BeforeUnloadEvent) => {
      if (!shouldBlock) return;
      event.preventDefault();
      event.returnValue = "行った変更が保存されない可能性があります";
      return event;
    };
    window.addEventListener("beforeunload", beforeUnLoadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnLoadHandler);
    };
  }, [shouldBlock]);

  useEffect(() => {
    if (blocker.state === "blocked" && !shouldBlock) {
      blocker.proceed();
    }
  }, [shouldBlock, blocker]);

  if (blocker.state === "blocked" && shouldBlock) {
    return (
      <Modal isOpen onClose={blocker.reset} onOk={blocker.proceed} labelClose="いいえ" labelOk="はい">
        <Stack mb={2}>
          <Typography>入力したデータがなくなりますが、よろしいですか。</Typography>
        </Stack>
      </Modal>
    );
  }
  return null;
};
