import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: 5000, // After 5 seconds have elapsed since the data was retrieved
      gcTime: 5000, //  Data in cache is retained until 5 seconds has passed
      retry: 0,
    },
  },
});

export const queryClientNoCache = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
});
