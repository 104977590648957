import { SUPPORT_IMPLEMENTATION_STATUS } from "@/types/enum";
import { validator } from "@/utils/validator";
import { object } from "yup";

export const lifeSupportSchema = validator.object().shape({
  airportPickup: validator.string().isSelection().trim().emptyToNull().label("1. 空港などへの出迎え").required(),
  airportPickupReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("airportPickup", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  seeOffAirport: validator.string().isSelection().trim().emptyToNull().label("2. 空港への見送り").required(),
  seeOffAirportReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("seeOffAirport", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  supportLivelihood: validator.string().isSelection().trim().emptyToNull().label("3. 生活の確保・生活に必要な契約に関する支援").required(),
  supportLivelihoodReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("supportLivelihood", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  supportLifeOrientation: validator.string().isSelection().trim().emptyToNull().label("4. 生活オリエンテーション").required(),
  supportLifeOrientationReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("supportLifeOrientation", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  accompanyToRelevantInstitutionsAndOtherSupport: validator
    .string()
    .isSelection()
    .trim()
    .emptyToNull()
    .label("5. 関係機関への同行その他必要な支援")
    .required(),
  accompanyToRelevantInstitutionsAndOtherSupportReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("accompanyToRelevantInstitutionsAndOtherSupport", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  supportLearnJapanese: validator.string().isSelection().trim().emptyToNull().label("6. 日本語学習の機会の提供").required(),
  supportLearnJapaneseReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("supportLearnJapanese", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  handleConsultationAndComplaint: validator.string().isSelection().trim().emptyToNull().label("7. 相談・苦情対応").required(),
  handleConsultationAndComplaintReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("handleConsultationAndComplaint", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  supportExchangeWithJapanesePeople: validator.string().isSelection().trim().emptyToNull().label("8. 日本人との交流促進").required(),
  supportExchangeWithJapanesePeopleReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("supportExchangeWithJapanesePeople", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  supportFindJob: validator.string().isSelection().trim().emptyToNull().label("9. 非自発的離職時の転職支援").required(),
  supportFindJobReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("supportFindJob", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  conductRegularInterview: validator.string().isSelection().trim().emptyToNull().label("10. 定期的な面談の実施").required(),
  conductRegularInterviewReason: validator
    .string()
    .trim()
    .emptyToNull()
    .label("未実施の理由")
    .notRequired()
    .when("conductRegularInterview", {
      is: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED,
      then: (schema) => schema.required(),
    }),
  companySupporterTelNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),
  companySupporterName: validator.string().trim().emptyToNull().notRequired(),
  companySupporterId: validator.number().emptyToNull().notRequired(),
});

export const lifeSupportDraftSchema = object().shape({
  airportPickup: validator.string().trim().emptyToNull().notRequired().nullable(),
  seeOffAirport: validator.string().trim().emptyToNull().notRequired().nullable(),
  supportLivelihood: validator.string().trim().emptyToNull().notRequired().nullable(),
  supportLifeOrientation: validator.string().trim().emptyToNull().notRequired().nullable(),
  accompanyToRelevantInstitutionsAndOtherSupport: validator.string().trim().emptyToNull().notRequired().nullable(),
  supportLearnJapanese: validator.string().trim().emptyToNull().notRequired().nullable(),
  handleConsultationAndComplaint: validator.string().trim().emptyToNull().notRequired().nullable(),
  supportExchangeWithJapanesePeople: validator.string().trim().emptyToNull().notRequired().nullable(),
  supportFindJob: validator.string().trim().emptyToNull().notRequired().nullable(),
  conductRegularInterview: validator.string().trim().emptyToNull().notRequired().nullable(),
  companySupporterTelNumber: validator.string().trim().emptyToNull().nullable(),
  companySupporterName: validator.string().trim().emptyToNull().nullable(),
  companySupporterId: validator.number().emptyToNull().nullable(),
});
