import { useEmployeeInfo } from "@/api/services/main/employee";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { EmployeeEditPage } from "@/features/employee/pages/EmployeeEdit";
import { convertEmployeeResToFormDataDto } from "@/features/employee/utils/convertResToFormData";

const EmployeeEdit = () => {
  const { data: employeeResult, isLoading } = useEmployeeInfo();

  const data = employeeResult?.data;

  if (isLoading) return <LoadingPage />;

  return <EmployeeEditPage previousValues={convertEmployeeResToFormDataDto(data)} />;
};

export default EmployeeEdit;
