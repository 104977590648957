import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { TypoLink } from "@/components/elements/TypoLink";
import { TLifeSupportForm } from "@/features/regular-document/types/lifeSupportForm";
import { SUPPORT_IMPLEMENTATION_STATUS } from "@/types/enum";
import { Stack, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";

export const SupportStatus = () => {
  const { control, setValue } = useFormContext<TLifeSupportForm>();
  const { data: supportImplementationStatusOptions } = useEnum("ESupportImplementationStatus");
  const options = supportImplementationStatusOptions;

  const airportPickupValue = useWatch({ control, name: "airportPickup" });
  const seeOffAirportValue = useWatch({ control, name: "seeOffAirport" });
  const supportLivelihoodValue = useWatch({ control, name: "supportLivelihood" });
  const supportLifeOrientationValue = useWatch({ control, name: "supportLifeOrientation" });
  const accompanyToRelevantInstitutionsAndOtherSupportValue = useWatch({ control, name: "accompanyToRelevantInstitutionsAndOtherSupport" });
  const supportLearnJapaneseValue = useWatch({ control, name: "supportLearnJapanese" });
  const handleConsultationAndComplaintValue = useWatch({ control, name: "handleConsultationAndComplaint" });
  const supportExchangeWithJapanesePeopleValue = useWatch({ control, name: "supportExchangeWithJapanesePeople" });
  const supportFindJobValue = useWatch({ control, name: "supportFindJob" });
  const conductRegularInterviewValue = useWatch({ control, name: "conductRegularInterview" });

  return (
    <InnerBox title="支援実施状況" bordered p={3} required>
      <Stack gap={3}>
        <Stack gap={1}>
          <FormField control={control} name="airportPickup" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("airportPickupReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="airportPickupReason">
            <Input sx={{ width: "50%" }} disabled={airportPickupValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="seeOffAirport" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("seeOffAirportReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="seeOffAirportReason">
            <Input sx={{ width: "50%" }} disabled={seeOffAirportValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="supportLivelihood" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("supportLivelihoodReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="supportLivelihoodReason">
            <Input sx={{ width: "50%" }} disabled={supportLivelihoodValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="supportLifeOrientation" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("supportLifeOrientationReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="supportLifeOrientationReason">
            <Input sx={{ width: "50%" }} disabled={supportLifeOrientationValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="accompanyToRelevantInstitutionsAndOtherSupport" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("accompanyToRelevantInstitutionsAndOtherSupportReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="accompanyToRelevantInstitutionsAndOtherSupportReason">
            <Input
              sx={{ width: "50%" }}
              disabled={accompanyToRelevantInstitutionsAndOtherSupportValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED}
            />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="supportLearnJapanese" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("supportLearnJapaneseReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="supportLearnJapaneseReason">
            <Input sx={{ width: "50%" }} disabled={supportLearnJapaneseValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="handleConsultationAndComplaint" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("handleConsultationAndComplaintReason", "");
                }
              }}
            />
            <Typography variant="cap12" mt={1}>
              実施の場合は、
              <TypoLink variant="cap12" target="blank" to={"https://www.moj.go.jp/isa/content/001340543.docx"}>
                相談記録書（様式5－4）
              </TypoLink>
              を作成して、いろはな担当者までご連絡ください。
            </Typography>
          </FormField>
          <FormField control={control} name="handleConsultationAndComplaintReason">
            <Input sx={{ width: "50%" }} disabled={handleConsultationAndComplaintValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="supportExchangeWithJapanesePeople" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("supportExchangeWithJapanesePeopleReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="supportExchangeWithJapanesePeopleReason">
            <Input sx={{ width: "50%" }} disabled={supportExchangeWithJapanesePeopleValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="supportFindJob" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("supportFindJobReason", "");
                }
              }}
            />
            <Typography variant="cap12" mt={1}>
              実施の場合は、
              <TypoLink variant="cap12" target="blank" to={"https://www.moj.go.jp/isa/content/001378832.docx"}>
                転職支援実施報告書（様式5－12）
              </TypoLink>
              を作成して、いろはな担当者までご連絡ください。
            </Typography>
          </FormField>
          <FormField control={control} name="supportFindJobReason">
            <Input sx={{ width: "50%" }} disabled={supportFindJobValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
        <Stack gap={1}>
          <FormField control={control} name="conductRegularInterview" required>
            <RadioGroup
              direction="row"
              options={options}
              onChange={(value) => {
                if (value !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED) {
                  setValue("conductRegularInterviewReason", "");
                }
              }}
            />
          </FormField>
          <FormField control={control} name="conductRegularInterviewReason">
            <Input sx={{ width: "50%" }} disabled={conductRegularInterviewValue !== SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED} />
          </FormField>
        </Stack>
      </Stack>
    </InnerBox>
  );
};
