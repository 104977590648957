import { useAnytimeSupportPlan } from "@/api/services/main/any-time/support-plan";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AnytimeSupportPlanEditPage } from "@/features/anytime/pages/documents/AnytimeSupportPlanEdit";
import { convertResDtoToFormData } from "@/features/anytime/utils/support-plan";

const AnytimeSupportPlanEdit = () => {
  const { data: supportPlanResult, isLoading } = useAnytimeSupportPlan();
  const supportPlanData = supportPlanResult?.data;

  if (isLoading || !supportPlanData) return <LoadingPage />;

  return <AnytimeSupportPlanEditPage defaultValues={convertResDtoToFormData(supportPlanData)} isDraft={supportPlanData.isDraft} />;
};

export default AnytimeSupportPlanEdit;
