import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { Linkable } from "@/components/elements/Linkable";
import { AppRoutes } from "@/routes/config";
import { background, text } from "@/theme/colors";
import { Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const DocumentTemplates = () => {
  return (
    <InnerBox
      title="新しく届出書類を作成する"
      rightContent={
        <Button variant="outline" size="sm" component={Link} to={AppRoutes.anytimeTemplates}>
          書類の様式から作成する
        </Button>
      }
    >
      <Stack gap={3.5} mt={2} zIndex={2} position="relative">
        <Grid container width="100%" spacing={2}>
          <Grid item xs={3}>
            <Linkable href={AppRoutes.anytimeEmploymentContractCreate} width="100%">
              <Stack p={2} bgcolor={background.white} gap={0.5} border="1px solid" borderColor={background.main} borderRadius={1}>
                <Typography variant="sub16Bold">💸給与変更</Typography>
                <Typography variant="cap12" color={text.secondary} whiteSpace="nowrap">
                  雇用契約の変更に係る届出(3-1-1)
                </Typography>
              </Stack>
            </Linkable>
          </Grid>
          <Grid item xs={3}>
            <Linkable href={AppRoutes.anytimeEmploymentContractCreate} width="100%">
              <Stack p={2} bgcolor={background.white} gap={0.5} border="1px solid" borderColor={background.main} borderRadius={1}>
                <Typography variant="sub16Bold">📍勤務地の変更</Typography>
                <Typography variant="cap12" color={text.secondary} whiteSpace="nowrap">
                  雇用契約の変更に係る届出(3-1-1)
                </Typography>
              </Stack>
            </Linkable>
          </Grid>
          <Grid item xs={3}>
            <Linkable href={AppRoutes.anytimeSupportContractCreate} width="100%">
              <Stack p={2} bgcolor={background.white} gap={0.5} border="1px solid" borderColor={background.main} borderRadius={1}>
                <Typography variant="sub16Bold">👤自社支援に変更 (1/2)</Typography>
                <Typography variant="cap12" color={text.secondary} whiteSpace="nowrap">
                  特定技能所属機関・随時３(3-3-2)
                </Typography>
              </Stack>
            </Linkable>
          </Grid>
          <Grid item xs={3}>
            <Linkable href={AppRoutes.anytimeSupportPlanCreate} width="100%">
              <Stack p={2} bgcolor={background.white} gap={0.5} border="1px solid" borderColor={background.main} borderRadius={1}>
                <Typography variant="sub16Bold">👤自社支援に変更 (2/2)</Typography>
                <Typography variant="cap12" color={text.secondary} whiteSpace="nowrap">
                  特定技能所属機関・随時２(3-2)
                </Typography>
              </Stack>
            </Linkable>
          </Grid>
          {/* <Grid item xs={3}>
            <Linkable href={AppRoutes.anytimeSupportPlanCreate} width="100%">
              <Stack p={2} bgcolor={background.white} gap={0.5} border="1px solid" borderColor={background.main} borderRadius={1}>
                <Typography variant="sub16Bold">👤自社支援に変更</Typography>
                <Typography variant="cap12" color={text.secondary} whiteSpace="nowrap">
                  雇用契約の変更に係る届出(3-1-1)
                </Typography>
              </Stack>
            </Linkable>
          </Grid> */}
        </Grid>
      </Stack>
    </InnerBox>
  );
};
