import { IconChevronRight } from "@/assets/icons";
import { background, divider, text } from "@/theme/colors";
import { elevation, rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type TTaskCardProps = {
  title: string;
  seeMoreHref: string;
  subTitle?: ReactNode;
};

export const TaskCard: FCC<TTaskCardProps> = ({ title, seeMoreHref, subTitle, children }) => {
  return (
    <TaskCardWrap gap={2}>
      <TitleWrap gap={3}>
        <Typography variant="head20Bold" color={text.primary} flex={1}>
          {title}
        </Typography>
        {subTitle}
        <SeeDetailWrap to={seeMoreHref}>
          <Typography variant="cap12Bold" color={text.primary}>
            詳しく見る
          </Typography>
          <IconChevronRight fontSize={24} color={text.primary} />
        </SeeDetailWrap>
      </TitleWrap>
      <Box>{children}</Box>
    </TaskCardWrap>
  );
};

const TaskCardWrap = styled(Stack)`
  border: 1px solid ${divider.middle};
  border-radius: ${rounded.sm};
  padding: ${spacing.sm};
  background: ${background.white};
  box-shadow: ${elevation.lv1};
  height: 100%;
`;

const TitleWrap = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SeeDetailWrap = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  gap: ${spacing.xs};
  &:hover {
    opacity: 0.8;
  }
`;
