import { TGetFileInfoResDto } from "@/api/services/global/file/dtos/get-file-info.dto";
import { TGetSingedUrlResDto } from "@/api/services/global/file/dtos/get-signed-url";
import { TUploadFileResDto } from "@/api/services/global/file/dtos/upload-file.dto";
import { TFileUploadFor } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { globalApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { AxiosProgressEvent } from "axios";

export const uploadFileApi = async (
  payload: { uploadFor: TFileUploadFor; file: File },
  onUploadProgress?: (event: AxiosProgressEvent) => void,
): Promise<TUploadFileResDto> => {
  const formData = new FormData();
  formData.append("file", payload.file);
  const url = createUrlWithParams("/files/upload-file", { uploadFor: payload.uploadFor });
  const rs = await globalApi.post(url, formData, {
    onUploadProgress,
  });
  return rs.data;
};

export const fetchFileInfoApi = async (filePath: string): Promise<TGetFileInfoResDto> => {
  const url = createUrlWithParams("/files", { filePath });
  const rs = await globalApi.get(url);
  return rs.data;
};

export const useFileInfo = (filePath?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FILE.FETCH_INFO, filePath],
    queryFn: () => (filePath ? fetchFileInfoApi(filePath) : undefined),
    enabled: Boolean(filePath),
  });
};

export const fetchSignedUrlApi = async (filePath: string, size?: string): Promise<TGetSingedUrlResDto> => {
  const url = createUrlWithParams("/files/signed-url", { filePath, size });
  const rs = await globalApi.get(url);
  return rs.data;
};

export const useFileSignedUrl = (filePath?: string | null, size?: "300x400" | "500x600") => {
  return useQuery({
    queryKey: [QUERY_KEYS.FILE.FETCH_SIGNED_URL, filePath, size],
    queryFn: () => (filePath ? fetchSignedUrlApi(filePath, size) : undefined),
    enabled: Boolean(filePath),
  });
};
