import { button, divider } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { toNumber } from "@/utils/number";
import { sleep } from "@/utils/pieces";
import { Box, Stack, css, styled } from "@mui/material";
import { FC, ReactNode, useEffect, useRef, useState } from "react";

export type TMenuContent = {
  title: string;
  content: ReactNode;
};

type TMenuNavigateProps = {
  contents: TMenuContent[];
  menuPrefix?: ReactNode;
  menuAffix?: ReactNode;
  contentPrefix?: ReactNode;
  contentAffix?: ReactNode;
  stickyTop?: number;
};

export const MenuNavigate: FC<TMenuNavigateProps> = ({ contents, menuPrefix, menuAffix, contentPrefix, contentAffix, stickyTop = 80 }) => {
  const refs = useRef<HTMLDivElement[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const disabledScrollFn = useRef(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleClickMenuItem = async (index: number) => {
    if (!refs.current[index]) return;
    disabledScrollFn.current = true;
    const position = refs.current[index].getBoundingClientRect();
    window.scrollTo({ behavior: "smooth", left: position.left, top: position.top + window.scrollY - stickyTop });
    setActiveIndex(index);
    await sleep(720);
    disabledScrollFn.current = false;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (disabledScrollFn.current) return;
      const isHalfBottom = window.scrollY > toNumber(containerRef.current?.clientHeight) / 3;

      refs.current.forEach((ref, index) => {
        if (!ref) return;
        const rect = ref.getBoundingClientRect();
        if (rect.top - window.innerHeight + (window.innerHeight * (isHalfBottom ? 2 : 5)) / 7 < 0) setActiveIndex(index);
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <MenuNavigateWrap ref={containerRef}>
      <Menu stickyTop={stickyTop}>
        {menuPrefix}
        {contents.map(({ title }, index) => (
          <MenuItem active={activeIndex === index} key={index} onClick={() => handleClickMenuItem(index)}>
            {title}
          </MenuItem>
        ))}
        {menuAffix}
      </Menu>
      <Stack width="100px" gap={4} flex={1}>
        {contentPrefix}
        {contents.map(({ content }, index) => (
          <Box key={index} ref={(ref: HTMLDivElement) => (refs.current[index] = ref)}>
            {content}
          </Box>
        ))}
        {contentAffix}
      </Stack>
    </MenuNavigateWrap>
  );
};

const options = { shouldForwardProp: (propName: string) => !["active", "stickyTop"].includes(propName) };

const MenuNavigateWrap = styled(Stack)`
  flex-direction: row;
  gap: 24px;
`;

const Menu = styled(Stack, options)<{ stickyTop: number }>`
  gap: 8px;
  min-width: 200px;
  position: sticky;
  ${({ stickyTop }) => `top: ${stickyTop}px`};
  height: fit-content;
`;

const MenuItem = styled(Box, options)<{ active: boolean }>`
  position: relative;
  border-left: 4px solid ${divider.middle};
  padding: 8px 16px;
  padding-left: 12px;
  cursor: pointer;
  max-width: 210px;
  ${css(typography.body14)};
  ${({ active }) => active && MenuItemActive};
`;

const MenuItemActive = css`
  border-color: ${button.primary};
  pointer-events: none;
`;
