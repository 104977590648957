import { Button } from "@/components/elements/Button";
import { DropZone } from "@/components/elements/DropZone";
import { useFormField } from "@/components/elements/FormField";
import { TFileUploadFor } from "@/constants/file";
import { divider } from "@/theme/colors";
import { TFileRules } from "@/utils/file";
import { Box } from "@mui/material";
import { FC, useMemo } from "react";

type TUploadFileBtnFormProps = {
  rules?: TFileRules;
  disabled?: boolean;
  multiple?: boolean;
  uploadFor: TFileUploadFor;
};

export const UploadFileBtnForm: FC<TUploadFileBtnFormProps> = ({ rules, disabled, multiple = false, uploadFor }) => {
  const { field } = useFormField();
  const isDisabledUpload = useMemo(() => {
    if (!field) return false;
    if (multiple) return false;
    if (!field.value) return false;
    return true;
  }, [field, multiple]);

  return (
    <Box p={2} border="1px solid" borderColor={divider.middle} borderRadius={2}>
      <DropZone uploadFor={uploadFor} options={{ multiple }} bordered={false} rules={rules} disabled={isDisabledUpload || disabled}>
        <Button size="md" fullWidth disabled={isDisabledUpload || disabled}>
          データをアップロード
        </Button>
      </DropZone>
    </Box>
  );
};
