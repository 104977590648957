import { useEnum } from "@/api/services/global/enum";
import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { TGetEmployeeOutputDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { useEmployeeRetirementDocument, useEmployeeRetirementDocumentList } from "@/api/services/main/retirement-document";
import { useRetirementEmploymentContractDocument } from "@/api/services/main/retirement-document/employment-contract-document";
import { useGetRegionList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Table } from "@/components/elements/Table";
import { EMPTY_STR } from "@/constants/string";
import { ConfirmReplaceContent } from "@/features/retirement/components/retirement-detail/accepting-worker/ConfirmReplaceContent";
import { TRetirementEmploymentContractForm } from "@/features/retirement/types/retirementEmploymentContractForm";
import { useDialog } from "@/hooks/useDialog";
import { useTable } from "@/hooks/useTable";
import { text } from "@/theme/colors";
import { DOCUMENT_KEYS, DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { findRetirementDocumentStep } from "@/utils/document";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { getFullAddress, getFullName } from "@/utils/string";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

type TRetirementEmployeeFormProps = {
  readonly?: boolean;
};

export const RetirementEmployeeForm: FC<TRetirementEmployeeFormProps> = ({ readonly }) => {
  const { data: retirementDocumentResult } = useEmployeeRetirementDocument();
  const methods = useFormContext<TRetirementEmploymentContractForm>();
  const { setValue, getValues } = methods;
  const confirmFillData = useDialog();
  const { data: retirementDocumentListResult } = useEmployeeRetirementDocumentList({ filter: { documentStatus: [DOCUMENT_STATUS.COMPLETE] } });
  const mostRecentDocument = retirementDocumentListResult?.data?.reduce((latest, current) => {
    return new Date(current.updatedAt) > new Date(latest.updatedAt) ? current : latest;
  }, retirementDocumentListResult?.data?.[0] || null);
  const { data: mostRecentRetirementDocumentResult } = useEmployeeRetirementDocument(mostRecentDocument?.documentGroupId);
  const employmentContractDocument = mostRecentRetirementDocumentResult?.data.retirementDocuments.find(
    (document) => document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT,
  );
  const creatingStepData = findRetirementDocumentStep(employmentContractDocument, DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT);
  const { data: mostRecentEmploymentContractResult } = useRetirementEmploymentContractDocument(creatingStepData?.id);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TGetEmployeeOutputDto | undefined>(undefined);

  const handleFetchEmployeeInfo = async (retirementEmployeeId?: string) => {
    setIsDataLoading(true);
    const rs = await fetchEmployeeInfoApi(retirementEmployeeId ?? "");
    setTableData(rs.data);
    setIsDataLoading(false);
    const {
      firstName,
      middleName,
      lastName,
      nickName,
      gender,
      nationalityId,
      residenceCode,
      contractSpecificBusinessClassificationOne,
      birthday,
      zipCode,
      province,
      municipality,
      address,
      telNumber,
      retirementDate,
    } = rs.data;
    setValue("employeeFullName", getFullName({ firstName, middleName, lastName }));
    setValue("employeeNickName", nickName);
    setValue("employeeGender", gender);
    setValue("employeeNationalityId", nationalityId);
    setValue("employeeResidenceCardNumber", residenceCode);
    setValue("specificIndustryName", contractSpecificBusinessClassificationOne?.specificIndustry.regularAnyTimeSpecificIndustry);
    setValue("specificBusinessClassificationName", contractSpecificBusinessClassificationOne?.specificBusinessClassificationName);
    setValue("birthday", birthday);
    setValue("zipCode", zipCode);
    setValue(
      "fullAddress",
      province || municipality || address ? getFullAddress({ province: province, municipality: municipality, address: address }) : null,
    );
    setValue("employeeTelNumber", telNumber);
    setValue("telNumber", telNumber);
    setValue("terminateContractDate", retirementDate ? dayjs(retirementDate) : null);
  };

  const {
    terminateContractReason,
    terminateContractReasonByCompany,
    terminateContractOtherByCompany,
    terminateContractReasonByEmployee,
    terminateContractOtherReasonByEmployee,
  } = getValues();

  const handleConfirmFillData = () => {
    if (
      terminateContractReason ||
      terminateContractReasonByCompany ||
      terminateContractOtherByCompany ||
      terminateContractReasonByEmployee ||
      terminateContractOtherReasonByEmployee
    ) {
      confirmFillData.show();
    } else handleFillData();
  };

  const handleFillData = () => {
    if (mostRecentEmploymentContractResult?.data) {
      setValue("terminateContractReason", mostRecentEmploymentContractResult.data.terminateContractReason);
      setValue("terminateContractReasonByCompany", mostRecentEmploymentContractResult.data.terminateContractReasonByCompany);
      setValue("terminateContractOtherByCompany", mostRecentEmploymentContractResult.data.terminateContractOtherByCompany);
      setValue("terminateContractReasonByEmployee", mostRecentEmploymentContractResult.data.terminateContractReasonByEmployee);
      setValue("terminateContractOtherReasonByEmployee", mostRecentEmploymentContractResult.data.terminateContractOtherReasonByEmployee);

      confirmFillData.cancel();
    }
  };

  useEffect(() => {
    if (retirementDocumentResult?.data.retirementDocuments[0].employeeId) {
      handleFetchEmployeeInfo(retirementDocumentResult?.data.retirementDocuments[0].employeeId.toString());
    }
  }, [retirementDocumentResult]);

  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeOutputDto>[]>(
    () => [
      {
        header: "氏名",
        size: 120,
        Cell: ({ row }) => {
          const { firstName, lastName, middleName } = row.original;
          const fullName = getFullName({ firstName, lastName, middleName });
          return fullName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "呼び名",
        size: 120,
        Cell: ({ row }) => {
          return row.original.nickName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "性別",
        size: 120,
        Cell: ({ row }) => {
          return findLabelByValue(genderOptions, row.original.gender) || EMPTY_STR.TEXT;
        },
      },
      {
        header: "生年月日",
        size: 120,
        Cell: ({ row }) => {
          return row.original.birthday || EMPTY_STR.DATE;
        },
      },
      {
        header: "国籍",
        size: 120,
        Cell: ({ row }) => findLabelByValue(nationalityOptions, row.original.nationalityId) || EMPTY_STR.TEXT,
      },
      {
        header: "在留カード番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.residenceCode || EMPTY_STR.TEXT;
        },
      },
      {
        header: "郵便番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.zipCode ? convertNumberToZipCode(row.original.zipCode) : EMPTY_STR.TEXT;
        },
      },
      {
        header: "住所",
        size: 120,
        Cell: ({ row }) => {
          const { province, address, municipality } = row.original;
          const fullAddress = getFullAddress({ province: province, municipality: municipality, address: address });
          return fullAddress || EMPTY_STR.TEXT;
        },
      },
      {
        header: "電話番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.telNumber || EMPTY_STR.TEXT;
        },
      },
      {
        header: "特定産業分野",
        size: 120,
        Cell: ({ row }) => {
          return row.original.contractSpecificBusinessClassificationOne?.specificIndustry.regularAnyTimeSpecificIndustry || EMPTY_STR.TEXT;
        },
      },
      {
        header: "業務区分",
        size: 120,
        Cell: ({ row }) => {
          return row.original.contractSpecificBusinessClassificationOne?.specificBusinessClassificationName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "データ更新",
        id: "action",
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => {
                handleFetchEmployeeInfo(row.original.id.toString());
              }}
              disabled={readonly}
              size="sm"
            >
              再取得
            </Button>
          );
        },
        size: 80,
      },
    ],
    [genderOptions, nationalityOptions, readonly],
  );

  const { table } = useTable({ columns, rows: tableData ? [tableData] : [], pinningColumns: ["action"] });
  return (
    <Stack gap={5}>
      <Stack gap={2}>
        <Typography variant="head20Semi" color={text.primary}>
          届出の対象者
        </Typography>
        <Table table={table} isLoading={isDataLoading} />
      </Stack>
      <Button disabled={readonly || !retirementDocumentListResult || retirementDocumentListResult.data.length === 0} onClick={handleConfirmFillData}>
        前回の届出から作成内容を反映する
      </Button>
      <ConfirmReplaceContent
        title="リセットの確認"
        content="内容が上書きされますがよろしいですか？"
        open={confirmFillData.open}
        onClose={confirmFillData.cancel}
        onOk={handleFillData}
      />
    </Stack>
  );
};
