import { EAnyTimeContentOfSupport, ESupportRegistered, ESupportSpecifiedSkill } from "@/features/anytime/constants/enums";
import { companySchema } from "@/features/anytime/schema/common/companySchema";
import { companySupporterSchema } from "@/features/anytime/schema/common/companySupporterSchema";
import { employeeSchema } from "@/features/anytime/schema/common/employeeSchema";
import { validator } from "@/utils/validator";

export const supportPlanSchema = validator
  .object()
  .shape({
    employees: validator
      .array()
      .of(employeeSchema)
      .test((value, ctx) => {
        if (Array.isArray(value) && value.length === 0) return ctx.createError({ message: "１人以上の外国人を追加してください。" });
        return true;
      })
      .required(),
    modifyContractDate: validator.mixed().isSelection().isDayjs().label("変更発生日　※登録支援機関との委託契約を終了した日の翌日").required(),
    contractTermTarget: validator.array().of(validator.string().trim().required()).label("支援対象者").notRequired(),
    contractSpecifiedSkill: validator.array().of(validator.string().trim().required()).label("特定技能所属機関").notRequired(),
    contractTermOtherContent: validator
      .string()
      .trim()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("特定技能所属機関「その他」の内容")
      .max(300)
      .notRequired()
      .when("contractSpecifiedSkill", {
        is: (value: ESupportSpecifiedSkill[]) => value && value.includes(ESupportSpecifiedSkill.OTHER),
        then: (schema) => schema.required(),
      }),
    contractTermRegisteredSupportOrganization: validator.array().of(validator.string().trim().required()).label("登録支援機関").notRequired(),
    contentAfterChange: validator
      .string()
      .trim()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("登録支援機関「その他」の内容")
      .max(300)
      .notRequired()
      .when("contractTermRegisteredSupportOrganization", {
        is: (value: ESupportRegistered[]) => value && value.includes(ESupportRegistered.OTHER),
        then: (schema) => schema.required(),
      }),
    contractContentOfSupport: validator.array().of(validator.string().autoTransformToFullWidth().trim().required()).label("支援の内容").notRequired(),
    contractContentOfSupportOther: validator
      .string()
      .trim()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("支援の内容「その他」の内容")
      .max(300)
      .notRequired()
      .when("contractContentOfSupport", {
        is: (value: EAnyTimeContentOfSupport[]) => value && value.includes(EAnyTimeContentOfSupport.OTHER),
        then: (schema) => schema.required(),
      }),
    contractTermOther: validator.string().trim().emptyToNull().label("変更後の内容").max(20).required(),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);
