import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TCurrentSituationProps = {
  readonly?: boolean;
};

export const CurrentSituation: FC<TCurrentSituationProps> = ({ readonly }) => {
  const { data: currentSituationOptions } = useEnum("ECurrentSituation");

  const { control } = useFormContext<TRetirementAcceptingWorkerForm>();
  return (
    <Stack gap={2}>
      <Typography variant="head20Semi">特定技能外国人の現状</Typography>
      <InnerBox bordered p={3}>
        <Stack gap={3}>
          <FormField control={control} name="currentSituation" label="">
            <RadioGroup options={currentSituationOptions} disabled={readonly} />
          </FormField>
        </Stack>
      </InnerBox>
    </Stack>
  );
};
