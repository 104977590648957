import { useEnum } from "@/api/services/global/enum";
import { DATE_FORMAT } from "@/constants/datetime";
import { button, text } from "@/theme/colors";
import { textEllipsis } from "@/theme/minxin";
import { typography } from "@/theme/typography";
import { spacing } from "@/theme/variables";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { extractTextFromHTML } from "@/utils/pieces";
import { Box, Chip, css, Stack, styled, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";

export type TNotificationCardProps = {
  id: string | number;
  time: string;
  type: string;
  title: string;
  description: string;
  filePath: string | null;
  isSingleLine?: boolean;
  isHideContent?: boolean;
};

export const NotificationCard: FC<TNotificationCardProps> = ({ id, time, type, title, description, filePath, isSingleLine, isHideContent }) => {
  const { data: typeOptions } = useEnum("EInformationType");
  const { pathname } = useLocation();

  const content = useMemo(() => {
    return (
      <Stack gap={1}>
        {description && isSingleLine ? <Box>{extractTextFromHTML(description)}</Box> : <Box dangerouslySetInnerHTML={{ __html: description }} />}
      </Stack>
    );
  }, [title, description]);

  return (
    <Stack gap={1} p={2}>
      <BoxWrapper>
        <Typography variant="sub16Semi" fontWeight={500}>
          {dayjs(time).format(DATE_FORMAT)}
        </Typography>
        <Box width={"120px"}>
          <ChipWrapper label={<Typography variant="sub16Semi">{findLabelByValue(typeOptions, type) ?? type}</Typography>} />
        </Box>
        <Typography variant="sub16Semi" flex={1}>
          {title}
        </Typography>
        {isSingleLine && !isHideContent && (
          <Typography variant="sub16Semi" flex={1}>
            {content}
          </Typography>
        )}
      </BoxWrapper>
      {!isSingleLine && !isHideContent && <ContentBox>{content}</ContentBox>}
    </Stack>
  );
};

const BoxWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  .MuiBox-root {
    ${css(textEllipsis)}
  }
`;

const ChipWrapper = styled(Chip)`
  background-color: ${button.pressed};
  color: ${text.white};
  font-size: 16px;
  ${css(typography.sub16Semi)};
`;

const ContentBox = styled(Box)`
  ${css(typography.body14)};
  max-height: 80px;
  ${css(textEllipsis)}
`;
