import { InputMsg } from "@/features/chat/components/InputMsg";
import { Room } from "@/features/chat/components/Room";
import { RoomHead } from "@/features/chat/components/RoomHead";
import { styled } from "@mui/material";
import { FC } from "react";

type TChatBoxProps = {
  open: boolean;
};

export const ChatBox: FC<TChatBoxProps> = ({ open = false }) => {
  return (
    <ChatBoxWrapper open={open}>
      <InputMsg />
      <Room />
      <RoomHead />
    </ChatBoxWrapper>
  );
};

const ChatBoxWrapper = styled("div")<{ open: boolean }>`
  transition: all 0.2s ease;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(10%)")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  position: absolute;
  bottom: 100px;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 400px;
  height: calc(75vh - 100px);
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  background-color: white;
`;
