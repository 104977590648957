export enum ERetirementReasonType {
  CONVENIENT_FOR_COMPANY = "convenient_for_company",
  CONVENIENT_FOR_EMPLOYEE = "convenient_for_employee",
}

export enum ESubmittingDeclarationReasonType {
  SPECIFIC_SKILL_CHANGE_REASON = "specific_skill_change_reason",
  SPECIFIC_SKILL_TERMINATION_REASON = "specific_skill_termination_reason",
  SPECIFIC_SKILL_NEW_CONTRACT_REASON = "specific_skill_new_contract_reason",
}

export enum ESpecificCircumstance {
  DEFAULT = "default",
  JOB_CHANGE = "job_change",
  RETURNING_TO_JAPAN = "returning_to_japan",
  MARRIAGE = "marriage",
}

export enum EAnyConsultation {
  ONE = "one",
  TWO = "two",
  THREE = "three",
  DEFAULT = "default",
}

export enum EPlanToChangeJob {
  NO_PLAN = "no_plan",
  CHANGE_1 = "change_1",
  CHANGE_2 = "change_2",
  DEFAULT = "default",
}

export enum EReasonReturnHome {
  FAMILY_BUSINESS = "family_business",
  EMPLOYMENT_IN_HOME = "employment_in_home",
  DEFAULT = "default",
}

export enum EPlanDateReturn {
  OPTION_1 = "option_1",
  OPTION_2 = "option_2",
  DEFAULT = "default",
}

export enum EPlanToMove {
  AVAILABLE = "available",
  UNKNOWN = "unknown",
  DEFAULT = "default",
}

export enum ESupportType {
  IMPLEMENT = "implement",
  NOT_IMPLEMENT = "not_implement",
  DEFAULT = "default",
}

export enum EPlanToContinueActivity {
  PLAN_TO_RETURN = "plan_to_return",
  NO_PLAN_TO_RETURN = "no_plan_to_return",
  CHANGE_JOB = "change_job",
  RETURN_TO_JAPAN = "return_to_japan",
  UNCONFIRMED = "unconfirmed",
  OTHER = "other",
  DEFAULT = "default",
}

export enum EMeasure {
  CONTINUE_EMPLOYMENT = "continue_employment",
  SUPPORT_CHANGING_JOB = "support_changing_job",
  SUPPORT_RETURNING_JAPAN = "support_returning_japan",
  TERMINATE_EMPLOYMENT_CONTRACT = "terminate_employeement_contract",
  OTHER = "other",
  DEFAULT = "default",
}

export enum EModifyContractTerminateReason {
  EMPLOYMENT_CONTRACT_TERMINATION = "end_contract",
  CONVENIENT_FOR_COMPANY = "convenient_for_company",
  CONVENIENT_FOR_EMPLOYEE = "convenient_for_employee",
}

export enum EModifyContractTerminateReasonForCompany {
  BUSINESS_CONVENIENCE = "business_convenience",
  STANDARD_NON_COMPLIANCE = "standard_non_compliance",
  COMPANY_OWNER_DEATH = "company_owner_death",
  OTHER = "other",
}

export enum EModifyContractTerminateReasonForEmployee {
  EMPLOYEE_DEATH = "business_convenience",
  ILLNESS_INJURY = "illness_injury",
  MISSING_EMPLOYEE = "missing_employee",
  SERIOUS_DUTY_DISMISSAL = "serious_duty_dismissal",
  VOLUNTARY_RESIGNATION = "voluntary_resignation",
  OTHER = "other",
}
