import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConfirmDeleteBranchProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};

export const ConfirmDeleteBranch: FC<TConfirmDeleteBranchProps> = ({ open, onClose, onOk }) => {
  return (
    <Modal title="削除の確認" isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい">
      <Stack mb={2}>
        <Typography>事業所を削除してもよろしいですか。</Typography>
      </Stack>
    </Modal>
  );
};
