import { useGetRegionList } from "@/api/services/global/master";
import { useEmployeeInfo } from "@/api/services/main/employee";
import { IconWarningInfo } from "@/assets/icons";
import { Checkbox } from "@/components/elements/Checkbox";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { caculateSpecificSkillTransitionPeriod } from "@/features/employee/utils/caculateSpecificSkillTransitionPeriod";
import { useLocationInfo } from "@/hooks/useLocationInfo";
import { text } from "@/theme/colors";
import { EMPLOYMENT_STATUS, RETIREMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { Grid, Stack, Typography } from "@mui/material";
import { isDayjs } from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";

export const InfoForm = () => {
  const methods = useFormContext<TEmployeeForm>();
  const { control, setValue } = methods;

  const { data: employeeResult } = useEmployeeInfo();

  const isEditScreen = !!employeeResult;

  const hasRetiredBeforeWatchField = useWatch({ control, name: "hasRetiredBefore" });
  const retirementStatusWatchField = useWatch({ control, name: "retirementStatus" });
  const skillOneStartDateWatchValue = useWatch({ control, name: "skillOneStartDate" });
  const blankPeriodMonthlyWatchValue = useWatch({ control, name: "blankPeriodMonthly" });

  const {
    errors: { skillOneStartDate: skillOneStartDateError, blankPeriodMonthly: blankPeriodMonthlyError },
  } = useFormState({
    control,
  });

  useEffect(() => {
    const blankPeriodMonthlyWatch = !blankPeriodMonthlyError ? blankPeriodMonthlyWatchValue || 0 : 0;
    if (!isDayjs(skillOneStartDateWatchValue) || skillOneStartDateError || blankPeriodMonthlyError) {
      setValue("specificSkillTransitionPeriod", "");
      return;
    }
    const { period } = caculateSpecificSkillTransitionPeriod({
      skillOneStartDate: skillOneStartDateWatchValue,
      blankPeriodMonthly: blankPeriodMonthlyWatch.toString(),
    });
    setValue("specificSkillTransitionPeriod", period);
  }, [skillOneStartDateWatchValue, blankPeriodMonthlyWatchValue, skillOneStartDateError, blankPeriodMonthlyError]);

  const { isLoading: isLoadingRegion, nationalityOptions } = useGetRegionList();
  useLocationInfo({ methods, names: { zipCode: "zipCode", province: "province", municipality: "municipality", address: "address" } });

  const disabledEmploymentStatusValues = useMemo(() => {
    if (!employeeResult) return [];
    switch (employeeResult.data.employmentStatus) {
      case EMPLOYMENT_STATUS.OFFERED:
        return [];
      case EMPLOYMENT_STATUS.WORKING:
        return [EMPLOYMENT_STATUS.OFFERED];
      case EMPLOYMENT_STATUS.LEFT:
        return [EMPLOYMENT_STATUS.OFFERED];
      default:
        return [];
    }
  }, [employeeResult]);

  const currentBilling = useMemo(() => {
    const current = {
      month: dayjs().tz("Asia/Tokyo").month() + 1,
      year: dayjs().tz("Asia/Tokyo").year(),
    };
    return employeeResult?.data?.billings.find((billing) => billing.year === current.year && billing.month === current.month);
  }, [employeeResult]);

  const isPresentChargedBilling = useMemo(() => {
    const employee = employeeResult?.data;
    if (!employee?.billings) return false;
    if (
      currentBilling?.isCurrentPayment &&
      employee.employmentStatus !== EMPLOYMENT_STATUS.OFFERED &&
      employee.retirementStatus !== RETIREMENT_STATUS.RETIRED
    ) {
      return true;
    }
    return false;
  }, [employeeResult]);

  const getSubLabelForRetirementCheckBox = useCallback(() => {
    const retirementStatus = employeeResult?.data.retirementStatus || "";
    const employmentStatus = employeeResult?.data.employmentStatus || "";
    if (!isEditScreen && (retirementStatus === RETIREMENT_STATUS.ENROLLMENT || !employmentStatus)) {
      return "各種届出や申請は作成せず、記録のみとする。";
    }
    if (
      isEditScreen &&
      employeeResult?.data.employmentStatus === EMPLOYMENT_STATUS.OFFERED &&
      (!retirementStatus || retirementStatus === RETIREMENT_STATUS.ENROLLMENT) &&
      currentBilling?.isCurrentPayment
    ) {
      return "内定辞退の場合、退職手続きからご対応ください。";
    }
    if (
      isEditScreen &&
      [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT].includes(employmentStatus) &&
      (!retirementStatus || retirementStatus === RETIREMENT_STATUS.ENROLLMENT) &&
      currentBilling?.isCurrentPayment
    ) {
      return "退職手続きをご利用ください。";
    }
    if (
      isEditScreen &&
      [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT].includes(employmentStatus) &&
      retirementStatus === RETIREMENT_STATUS.PROCESSING
    ) {
      return "退職手続き中です。退職手続きから再開ください。";
    }
    if (
      isEditScreen &&
      [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT].includes(employmentStatus) &&
      retirementStatus === RETIREMENT_STATUS.RETIRED &&
      currentBilling?.isCurrentPayment
    ) {
      return "退職済みのため、記録用として登録されています。";
    }
    if (employeeResult?.data.retirementStatus === RETIREMENT_STATUS.ENROLLMENT && !currentBilling?.isCurrentPayment) {
      return "退職済みとして登録します。後から変更できません。";
    }
    if (employeeResult?.data.retirementStatus === RETIREMENT_STATUS.RETIRED && !currentBilling?.isCurrentPayment) {
      return "退職済みとして登録済みです。変更できません。";
    }
  }, [employeeResult, isEditScreen]);

  const getWarningInfo = useCallback(() => {
    const retirementStatus = employeeResult?.data.retirementStatus || "";
    const employmentStatus = employeeResult?.data.employmentStatus || "";
    let sentence = 0;
    if (!isEditScreen && (!retirementStatus || retirementStatus === RETIREMENT_STATUS.ENROLLMENT)) {
      sentence = 2;
    } else if (
      isEditScreen &&
      employmentStatus === EMPLOYMENT_STATUS.OFFERED &&
      (!retirementStatus || retirementStatus === RETIREMENT_STATUS.ENROLLMENT) &&
      currentBilling?.isCurrentPayment
    ) {
      sentence = 1;
    } else if (
      isEditScreen &&
      [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT].includes(employmentStatus) &&
      retirementStatus === RETIREMENT_STATUS.ENROLLMENT &&
      currentBilling?.isCurrentPayment
    ) {
      sentence = 1;
    } else if (
      isEditScreen &&
      [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT].includes(employmentStatus) &&
      retirementStatus === RETIREMENT_STATUS.PROCESSING &&
      currentBilling?.isCurrentPayment
    ) {
      sentence = 1;
    } else if (
      isEditScreen &&
      [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT].includes(employmentStatus) &&
      retirementStatus === RETIREMENT_STATUS.RETIRED &&
      currentBilling?.isCurrentPayment
    ) {
      sentence = 1;
    } else if ((!retirementStatus || retirementStatus === RETIREMENT_STATUS.ENROLLMENT) && !currentBilling?.isCurrentPayment) {
      sentence = 0;
    } else if (retirementStatus === RETIREMENT_STATUS.RETIRED) {
      sentence = 0;
    }

    if (sentence === 2) {
      return "在籍者は、退職手続きを完了することで退職に変更できます。退職日以降に入管へ提出する随時届出の手続終了までは「退職届出中」となり、当サービス利用のため課金対象となります。\nなお、irohanaご利用前に雇用歴があり、データベースに記録したい方は「退職済み」をチェックください。";
    }
    if (sentence === 1) {
      return "在籍者は、退職手続きを完了することで退職に変更できます。退職日以降に入管へ提出する随時届出の手続終了までは「退職届出中」となり、当サービス利用のため課金対象となります。";
    }
    return;
  }, [isPresentChargedBilling, isEditScreen]);

  return (
    <Stack gap={1}>
      <InnerBox title="基本情報">
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={4}>
            <FormField control={control} name="managementNumber">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <FormField control={control} name="lastName">
              <Input cleanWhitespace />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="middleName">
              <Input cleanWhitespace />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="firstName">
              <Input cleanWhitespace />
            </FormField>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <FormField control={control} name="nickName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="nationalityId">
              <Select loading={isLoadingRegion} options={nationalityOptions} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="birthday">
              <InputDatePicker minDate={dayjs("1870/01/01")} maxDate={dayjs().subtract(18, "year")} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="gender">
              <Select enumName="EGender" />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="skillOneStartDate">
              <InputDatePicker />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="blankPeriodMonthly">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="specificSkillTransitionPeriod">
              <Input readonly />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            {isEditScreen && employeeResult.data.retirementStatus !== RETIREMENT_STATUS.ENROLLMENT ? (
              <FormField control={control} name="retirementStatus" label="在籍ステータス">
                <Select enumName="ERetirementStatus" readonly />
              </FormField>
            ) : (
              <FormField control={control} name="employmentStatus">
                <Select
                  disabledValues={disabledEmploymentStatusValues}
                  enumName="EEmploymentStatus"
                  readonly={retirementStatusWatchField === RETIREMENT_STATUS.RETIRED}
                />
              </FormField>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="hiringDate">
              <InputDatePicker />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="retirementDate" required={retirementStatusWatchField === RETIREMENT_STATUS.RETIRED}>
              <InputDatePicker
                readonly={
                  isEditScreen
                    ? employeeResult.data.retirementStatus === RETIREMENT_STATUS.RETIRED
                    : retirementStatusWatchField !== RETIREMENT_STATUS.RETIRED
                }
              />
            </FormField>
          </Grid>
          <Grid item xs={4} mt={1}>
            <FormField control={control} name="hasRetiredBefore">
              <Checkbox
                disabled={
                  isEditScreen &&
                  !(employeeResult.data.retirementStatus === RETIREMENT_STATUS.ENROLLMENT && currentBilling?.isCurrentPayment === false)
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    methods.setValue("retirementStatus", RETIREMENT_STATUS.RETIRED);
                    methods.setValue("employmentStatus", EMPLOYMENT_STATUS.WORKING);
                  } else {
                    methods.setValue("retirementStatus", RETIREMENT_STATUS.ENROLLMENT);
                    methods.setValue("retirementDate", null);
                  }
                }}
                checked={
                  employeeResult?.data.hasRetiredBefore || RETIREMENT_STATUS.RETIRED === retirementStatusWatchField || !!hasRetiredBeforeWatchField
                }
                label="退職済みとして登録する"
                subLabel={getSubLabelForRetirementCheckBox()}
              />
            </FormField>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body14" fontSize={13} color={text.accent} whiteSpace="pre-line">
              {getWarningInfo()}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="zipCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="province">
              <Input readonly />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="municipality">
              <Input readonly />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="address">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="telNumber">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="email">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <IconWarningInfo fontSize={24} />
              <Typography variant="body14Bold" color={text.accent}>
                注意：電話番号が空欄の場合、申請では会社情報の電話番号が使用されます。
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="referralCompanyName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="registeredSupportOrganizationName">
              <Input />
            </FormField>
          </Grid>
          {currentBilling?.isCurrentPayment === false && (
            <Grid item xs={12}>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <IconWarningInfo fontSize={24} />
                <Typography variant="body14Bold" color={text.accent}>
                  情報：この外国人はirohana課金対象外です。
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </InnerBox>
    </Stack>
  );
};
