import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { Document } from "@/features/regular-document/components/documents";
import { Stack } from "@mui/material";
import { FC, useMemo } from "react";

export type TDocumentsContentProps = {};

export const DocumentsContent: FC<TDocumentsContentProps> = () => {
  const { data: presentDocumentListResult } = usePresentDocumentList();

  const documentData = presentDocumentListResult?.data;

  const renderDocs = useMemo(() => {
    if (!documentData) return null;
    documentData.regularDocuments.sort((a, b) => a.masterDocument.documentOrder - b.masterDocument.documentOrder);
    documentData.regularDocuments.forEach((document) =>
      document.steps.sort((a, b) => a.masterDocumentStep.stepOrder - b.masterDocumentStep.stepOrder),
    );
    return documentData.regularDocuments.map((document) => <Document key={document.id} masterData={document.masterDocument} document={document} />);
  }, [documentData]);
  return <Stack gap={3}>{renderDocs}</Stack>;
};
