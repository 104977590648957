import { DocumentsContent } from "@/features/regular-document/components/DocumentsContent";
import { DocumentsDescription } from "@/features/regular-document/components/DocumentsDescription";
import { Stack } from "@mui/material";
import { FC } from "react";

type TRegularReportPresentPageProps = {};

export const RegularReportPresentPage: FC<TRegularReportPresentPageProps> = () => {
  return (
    <Stack padding={3} gap={3}>
      <DocumentsDescription />
      <DocumentsContent />
    </Stack>
  );
};
