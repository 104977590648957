import { useGetUploadFileListWithFilterCondition } from "@/features/renew-visa/hook/useGetUploadFileListWithFilterCondition";
import { validator } from "@/utils/validator";

export const useGetRenewVisaUploadFileSchema = () => {
  const { filterConditionArrayKeys } = useGetUploadFileListWithFilterCondition();

  const renewVisaUploadFileSchema = validator.object().shape({
    applicantIndividualResidentTaxCertFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("applicantIndividualResidentTaxCertFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    applicantResidentTaxPaymentCertFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("applicantResidentTaxPaymentCertFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    applicantWithholdingSlipCopyFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("applicantWithholdingSlipCopyFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    applicantHealthInsuranceCardCopyFilePath: validator.string().trim().emptyToNull().notRequired(),
    applicantHealthInsurancePaymentCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    applicantPensionReceiptCopy1FilePath: validator.string().trim().emptyToNull().notRequired(),
    applicantPensionRecordInquiry2FilePath: validator.string().trim().emptyToNull().notRequired(),
    previousApplicationDocumentsFilePath: validator.string().trim().emptyToNull().notRequired(),
    publicDutyPledgeFilePath: validator.string().trim().emptyToNull().notRequired(),
    publicDutyComplianceFilePath: validator.string().trim().emptyToNull().notRequired(),
    immigrationElectronicSystemPledgeFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("immigrationElectronicSystemPledgeFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    documentOmissionPledgeFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("documentOmissionPledgeFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    foreignWorkerCompensationStatementFilePaths: validator
      .array()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("foreignWorkerCompensationStatementFilePaths"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    isExistEmploymentContractCopyFilePath: validator.boolean().notRequired(),
    employmentContractCopyFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when("isExistEmploymentContractCopyFilePath", {
        is: (value: boolean) => !value && filterConditionArrayKeys.includes("employmentContractCopyFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    isExistEmploymentConditionsCopyFilePaths: validator.boolean().notRequired(),
    employmentConditionsCopyFilePaths: validator
      .array()
      .notRequired()
      .when("isExistEmploymentConditionsCopyFilePaths", {
        is: (value: boolean) => !value && filterConditionArrayKeys.includes("employmentConditionsCopyFilePaths"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    isExistWagePaymentFilePath: validator.boolean().notRequired(),
    wagePaymentFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when("isExistWagePaymentFilePath", {
        is: (value: boolean) => !value && filterConditionArrayKeys.includes("wagePaymentFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    quarterlyReportCopyFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("quarterlyReportCopyFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    innovationCompanyProofFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("innovationCompanyProofFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    certifiedCompanyProofFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("certifiedCompanyProofFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    annualSalaryReportCopyFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("annualSalaryReportCopyFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    careWorkplaceOverviewFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("careWorkplaceOverviewFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    councilMembershipCertFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("councilMembershipCertFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    specifiedSkillAutomobileMaintenanceCertFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("specifiedSkillAutomobileMaintenanceCertFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    specifiedSkillAutomobileMaintenanceIssuranceFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("specifiedSkillAutomobileMaintenanceIssuranceFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    specificSkillOrganizationOverviewFilePath: validator.string().trim().emptyToNull().notRequired(),
    hotelBusinessLicenseFilePath: validator.string().trim().emptyToNull().notRequired(),
    buildingEnvHealthMgmtRegistrationCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    specificSkillsInstitutionOverviewDocumentFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("specificSkillsInstitutionOverviewDocumentFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    registrationCertFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("registrationCertFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    executiveResidentRecordCopyFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("executiveResidentRecordCopyFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    specificSkillsInstitutionOfficerAffidavitFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("specificSkillsInstitutionOfficerAffidavitFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    individualBusinessOwnerResidentCertFilePath: validator
      .string()
      .trim()
      .emptyToNull()
      .notRequired()
      .when([], {
        is: () => filterConditionArrayKeys.includes("individualBusinessOwnerResidentCertFilePath"),
        then: (schema) => schema.required("ファイルを選択してください。"),
      }),
    shipbuildingIndustryConfirmationFilePath: validator.string().trim().emptyToNull().notRequired(),
    businessLicenseOrNotificationFormFilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingLicenseCopy1FilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingLicenseCopy2FilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingLicenseCopy3FilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingCooperativeRightsDocumentFilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingCooperativeMembershipDocumentFilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingBoatRegistryCopyFilePath: validator.string().trim().emptyToNull().notRequired(),
    fishingBoatRegistrationCertCopyFilePath: validator.string().trim().emptyToNull().notRequired(),
    laborInsuranceNoDelegationFilePath: validator.string().trim().emptyToNull().notRequired(),
    laborInsuranceDelegationFilePath: validator.string().trim().emptyToNull().notRequired(),
    socialInsurancePaymentStatusCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    healthInsuranceWelfarePensionCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    nonHealthInsuranceWelfarePensionCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    individualHealthInsurancePaymentCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    individualPensionReceiptCopyFilePath: validator.string().trim().emptyToNull().notRequired(),
    taxOfficePaymentCertNo3FilePath: validator.string().trim().emptyToNull().notRequired(),
    corporateResidentTaxPaymentCertFilePath: validator.string().trim().emptyToNull().notRequired(),
    individualBusinessOwnerTaxOfficePaymentCertNo3FilePath: validator.string().trim().emptyToNull().notRequired(),
    individualResidentTaxCertFilePath: validator.string().trim().emptyToNull().notRequired(),
  });

  //draft schema
  const renewVisaUploadFileDraftSchema = validator.object().shape({
    applicantIndividualResidentTaxCertFilePath: validator.string().trim().emptyToNull().nullable(),
    applicantResidentTaxPaymentCertFilePath: validator.string().trim().emptyToNull().nullable(),
    applicantWithholdingSlipCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    applicantHealthInsuranceCardCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    applicantHealthInsurancePaymentCertFilePath: validator.string().trim().emptyToNull().nullable(),
    applicantPensionReceiptCopy1FilePath: validator.string().trim().emptyToNull().nullable(),
    applicantPensionRecordInquiry2FilePath: validator.string().trim().emptyToNull().nullable(),
    previousApplicationDocumentsFilePath: validator.string().trim().emptyToNull().nullable(),
    publicDutyPledgeFilePath: validator.string().trim().emptyToNull().nullable(),
    publicDutyComplianceFilePath: validator.string().trim().emptyToNull().nullable(),
    immigrationElectronicSystemPledgeFilePath: validator.string().trim().emptyToNull().nullable(),
    documentOmissionPledgeFilePath: validator.string().trim().emptyToNull().nullable(),
    foreignWorkerCompensationStatementFilePaths: validator.array().nullable(),
    employmentContractCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    isExistEmploymentContractCopyFilePath: validator.boolean().nullable(),
    employmentConditionsCopyFilePaths: validator.array().nullable(),
    isExistEmploymentConditionsCopyFilePaths: validator.boolean().nullable(),
    wagePaymentFilePath: validator.string().trim().emptyToNull().nullable(),
    isExistWagePaymentFilePath: validator.boolean().nullable(),
    quarterlyReportCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    innovationCompanyProofFilePath: validator.string().trim().emptyToNull().nullable(),
    certifiedCompanyProofFilePath: validator.string().trim().emptyToNull().nullable(),
    annualSalaryReportCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    careWorkplaceOverviewFilePath: validator.string().trim().emptyToNull().nullable(),
    councilMembershipCertFilePath: validator.string().trim().emptyToNull().nullable(),
    specifiedSkillAutomobileMaintenanceCertFilePath: validator.string().trim().emptyToNull().nullable(),
    specifiedSkillAutomobileMaintenanceIssuranceFilePath: validator.string().trim().emptyToNull().nullable(),
    hotelBusinessLicenseFilePath: validator.string().trim().emptyToNull().nullable(),
    specificSkillOrganizationOverviewFilePath: validator.string().trim().emptyToNull().nullable(),
    buildingEnvHealthMgmtRegistrationCertFilePath: validator.string().trim().emptyToNull().nullable(),
    specificSkillsInstitutionOverviewDocumentFilePath: validator.string().trim().emptyToNull().nullable(),
    registrationCertFilePath: validator.string().trim().emptyToNull().nullable(),
    executiveResidentRecordCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    specificSkillsInstitutionOfficerAffidavitFilePath: validator.string().trim().emptyToNull().nullable(),
    individualBusinessOwnerResidentCertFilePath: validator.string().trim().emptyToNull().nullable(),
    shipbuildingIndustryConfirmationFilePath: validator.string().trim().emptyToNull().nullable(),
    businessLicenseOrNotificationFormFilePath: validator.string().trim().emptyToNull().nullable(),
    fishingLicenseCopy1FilePath: validator.string().trim().emptyToNull().nullable(),
    fishingLicenseCopy2FilePath: validator.string().trim().emptyToNull().nullable(),
    fishingLicenseCopy3FilePath: validator.string().trim().emptyToNull().nullable(),
    fishingCooperativeRightsDocumentFilePath: validator.string().trim().emptyToNull().nullable(),
    fishingCooperativeMembershipDocumentFilePath: validator.string().trim().emptyToNull().nullable(),
    fishingBoatRegistryCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    fishingBoatRegistrationCertCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    laborInsuranceNoDelegationFilePath: validator.string().trim().emptyToNull().nullable(),
    laborInsuranceDelegationFilePath: validator.string().trim().emptyToNull().nullable(),
    socialInsurancePaymentStatusCertFilePath: validator.string().trim().emptyToNull().nullable(),
    healthInsuranceWelfarePensionCertFilePath: validator.string().trim().emptyToNull().nullable(),
    nonHealthInsuranceWelfarePensionCertFilePath: validator.string().trim().emptyToNull().nullable(),
    individualHealthInsurancePaymentCertFilePath: validator.string().trim().emptyToNull().nullable(),
    individualPensionReceiptCopyFilePath: validator.string().trim().emptyToNull().nullable(),
    taxOfficePaymentCertNo3FilePath: validator.string().trim().emptyToNull().nullable(),
    corporateResidentTaxPaymentCertFilePath: validator.string().trim().emptyToNull().nullable(),
    individualBusinessOwnerTaxOfficePaymentCertNo3FilePath: validator.string().trim().emptyToNull().nullable(),
    individualResidentTaxCertFilePath: validator.string().trim().emptyToNull().nullable(),
  });

  return { renewVisaUploadFileSchema, renewVisaUploadFileDraftSchema };
};
