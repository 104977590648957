import { useCompanyInfo } from "@/api/services/main/company";
import { useRegularDocumentHistoryDetail } from "@/api/services/main/regular-document";
import { useAcceptanceActivityDocument } from "@/api/services/main/regular-document/acceptance-activity-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AcceptanceActivityForm } from "@/features/regular-document/components/accept-activity/AcceptanceActivityForm";
import { getPreviousValues } from "@/features/regular-document/utils/getPreviousValues";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const RegularHistoryAcceptanceActivityPage = () => {
  const { year, quarter } = useParams();
  const { data: documentResult, isLoading: isLoadingDocumentData } = useRegularDocumentHistoryDetail(year, quarter);
  const documentData = documentResult?.data;
  const stepData = findDocumentStep(documentData?.regularDocuments, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT);
  const stepId = stepData?.id;
  const { data: acceptanceActivityResult, isLoading: isLoadingAcceptanceActivity } = useAcceptanceActivityDocument({
    documentStepId: stepId,
    enabled: !!stepData?.updater,
  });
  const acceptanceActivityData = acceptanceActivityResult?.data;
  const { data: companyResult, isLoading: isLoadingCompany } = useCompanyInfo();
  const companyData = companyResult?.data;

  const previousValues = useMemo(() => {
    return getPreviousValues({ companyData, acceptanceActivityData, documentData });
  }, [documentData, companyData, stepId, acceptanceActivityData]);

  if (isLoadingDocumentData || isLoadingAcceptanceActivity || isLoadingCompany || !stepId || !documentData) return <LoadingPage />;

  return (
    <AcceptanceActivityForm
      previousValues={previousValues}
      updater={acceptanceActivityData?.updater}
      year={documentData.year}
      quarter={documentData.quarter}
      documentStepId={stepId}
      readonly
    />
  );
};
