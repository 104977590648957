import { usePresentDocumentList } from "@/api/services/main/regular-document";
import {
  useAcceptanceActivityDocument,
  useEmployeePayrollList,
  useGetPayrollFilesApi,
} from "@/api/services/main/regular-document/acceptance-activity-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { ButtonGroup } from "@/features/regular-document/components/accept-activity/paycheck/ButtonGroup";
import { SalaryReflection } from "@/features/regular-document/components/accept-activity/paycheck/SalaryReflection";
import { PayCheckProvider } from "@/features/regular-document/providers/PayCheckProvider";
import { makePayrollRecords } from "@/features/regular-document/utils/convert";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_KEYS, DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocument, findDocumentStep } from "@/utils/document";
import { toNumber } from "@/utils/number";
import { Box } from "@mui/material";

export const RegularPresentAcceptActivityPayCheckPage = () => {
  const { data: presentDocumentListResult } = usePresentDocumentList();
  const documentData = findDocument(presentDocumentListResult?.data.regularDocuments, DOCUMENT_KEYS.ACCEPTANCE_ACTIVITY);
  const stepData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT);
  const stepId = stepData?.id;
  const stepUploadData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT);
  const stepUploadDataId = stepUploadData?.id;
  const { data: payrollFilesResult } = useGetPayrollFilesApi(toNumber(stepUploadDataId));

  const { data: acceptanceActivityResult, isLoading: isActivityLoading } = useAcceptanceActivityDocument({
    documentStepId: stepId,
    enabled: !!stepData?.updater,
  });
  const acceptanceActivityData = acceptanceActivityResult?.data;
  const { data: payrollListResult, isLoading } = useEmployeePayrollList(stepData?.id);
  const payrollListData = payrollListResult?.data;
  // const hasConverted = stepData?.convertRequests?.some(({ status }) => [DOCUMENT_STATUS.COMPLETE, DOCUMENT_STATUS.IN_PROGRESS].includes(status));

  if (!documentData || !stepId || !acceptanceActivityData || isLoading || isActivityLoading) return <LoadingPage />;
  return (
    <PayCheckProvider
      previousData={payrollListData?.length ? makePayrollRecords(payrollListData) : undefined}
      previousFilePath={acceptanceActivityData?.payrollFilePath}
      resourceData={{
        year: documentData.year,
        quarter: documentData.quarter,
        acceptanceActivityDocumentId: acceptanceActivityData.id,
        payrollAskIrohanaSupport: acceptanceActivityData.payrollAskIrohanaSupport,
        status: stepData.status,
      }}
      payrollFilesTotal={payrollFilesResult?.pagination.total}
      stepDocumentId={stepId}
      readonly={stepData.status === DOCUMENT_STATUS.CONVERTING}
      isEditable={stepData?.status === DOCUMENT_STATUS.IN_PROGRESS}
    >
      <Box maxWidth={COMPANY_CONTAINER}>
        <TopPage
          title="特定技能外国人の受入れ状況・報酬の支払状況"
          backUrl={AppRoutes.regularPresentAcceptActivity}
          isDraft={payrollListData?.some(({ isDraft }) => isDraft) || acceptanceActivityData.payrollAskIrohanaSupport === true}
        />
        <MenuNavigate contents={[{ title: "賃金台帳の反映", content: <SalaryReflection /> }]} contentAffix={<ButtonGroup />} />
      </Box>
    </PayCheckProvider>
  );
};
