import { TopPage } from "@/components/elements/TopPage";
import { InformationDetailPage } from "@/features/information/pages/InformationDetail";
import { AppRoutes } from "@/routes/config";
import React from "react";

const InformationDetail = () => {
  return (
    <>
      <TopPage title="お知らせ" backUrl={AppRoutes.information} />
      <InformationDetailPage />
    </>
  );
};

export default InformationDetail;
