import * as wanakana from "wanakana";

export const handleKatakanaToFurigana = (value: string) => {
  return wanakana.toHiragana(value, { passRomaji: true });
};

export const convertNumberToZipCode = (value: string) => {
  if (/^[0-9]+$/.test(value)) {
    let formattedValue = value.replace(/[^\d０-９]/g, "");

    if (formattedValue.length > 3) {
      formattedValue = formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
    }

    if (formattedValue.length > 8) {
      formattedValue = formattedValue.slice(0, 8);
    }

    return formattedValue;
  }
  return value;
};

export const convertZipCodeToNumber = (zipCode: string) => {
  if (!zipCode) return;
  return zipCode.replace("-", "");
};

export const convertNumberToDecimal = (value: string) => {
  let newValue = value;
  if (/^[0-9.,]+$/.test(newValue)) {
    newValue = newValue.replace(/[^0-9.,]/g, "");
    const [integerPart, decimalPart] = newValue.split(".");
    const formattedIntegerPart = parseFloat(integerPart.replace(/,/g, "")).toLocaleString("en-GB");
    newValue = decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }
  return newValue;
};

export const convertDecimalToNumber = (value: string) => {
  return value.replace(/,/g, "");
};
