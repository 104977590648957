import { useAnytimeSupportContract } from "@/api/services/main/any-time/support-contract";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AnytimeSupportContractEditPage } from "@/features/anytime/pages/documents/AnytimeSupportContractEdit";
import { convertResDtoToFormData } from "@/features/anytime/utils/support-scontract";

const AnytimeSupportContractEdit = () => {
  const { data: supportContractResult, isLoading } = useAnytimeSupportContract();
  const supportContractData = supportContractResult?.data;

  if (isLoading || !supportContractData) return <LoadingPage />;

  return <AnytimeSupportContractEditPage defaultValues={convertResDtoToFormData(supportContractData)} isDraft={supportContractData.isDraft} />;
};

export default AnytimeSupportContractEdit;
