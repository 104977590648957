import { TCompanyMenu } from "@/features/company/types/companyMenu";
import { AppRoutes } from "@/routes/config";

export const companyMenu: TCompanyMenu[] = [
  {
    title: "基本情報",
    url: AppRoutes.company,
    extraUrls: [AppRoutes.companyEdit],
  },
  {
    title: "事業所管理",
    url: AppRoutes.companyBranch,
    extraUrls: [AppRoutes.companyBranchCreate, AppRoutes.companyBranchEdit],
  },
  {
    title: "オンライン申請のアカウント管理",
    url: AppRoutes.companyAccount,
    extraUrls: [AppRoutes.companyAccountEdit],
  },
];
