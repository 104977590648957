//データ様式
export enum EDataFormat {
  IROHANA_STANDARD = "IROHANA_STANDARD",
  BULK_APPLICATION_TEMPLATE_COMPATIBLE = "BULK_APPLICATION_TEMPLATE_COMPATIBLE",
}

export enum EDataFormatImport {
  IROHANA_STANDARD = "IROHANA_STANDARD",
}

export const dataFormatImportOptions = [{ value: EDataFormat.IROHANA_STANDARD, label: "irohana標準" }];

export const dataFormatOptions = [
  { value: EDataFormat.IROHANA_STANDARD, label: "irohana標準" },
  { value: EDataFormat.BULK_APPLICATION_TEMPLATE_COMPATIBLE, label: "一括申請用テンプレート互換" },
];

//先頭行の仕様
export enum EFirstRowSpecification {
  JAPANESE_NAME = "JAPANESE_NAME",
  NONE = "NONE",
}

export const firstRowSpecificationOptions = [
  { value: EFirstRowSpecification.JAPANESE_NAME, label: "項目名" },
  { value: EFirstRowSpecification.NONE, label: "なし" },
];

//文字コード
export enum ECharacterCode {
  AUTO_DETECT = "AUTO_DETECT",
  SHIFT_JIS = "SHIFT_JIS",
  UTF8_UNICODE = "UTF8_UNICODE",
  UTF8_UNICODE_WITH_BOM = "UTF8_UNICODE_WITH_BOM",
}

export enum ECharacterCodeExport {
  SHIFT_JIS = "SHIFT_JIS",
  UTF8_UNICODE = "UTF8_UNICODE",
  UTF8_UNICODE_WITH_BOM = "UTF8_UNICODE_WITH_BOM",
}

export const characterCodeExportOptions = [
  { value: ECharacterCode.SHIFT_JIS, label: "Shift-JIS" },
  { value: ECharacterCode.UTF8_UNICODE, label: "UTF-8 / Unicode" },
  { value: ECharacterCode.UTF8_UNICODE_WITH_BOM, label: "UTF-8 / Unicode (BOM付き)" },
];

export const characterCodeOptions = [
  { value: ECharacterCode.AUTO_DETECT, label: "自動認識" },
  { value: ECharacterCode.SHIFT_JIS, label: "Shift-JIS" },
  { value: ECharacterCode.UTF8_UNICODE, label: "UTF-8 / Unicode" },
  { value: ECharacterCode.UTF8_UNICODE_WITH_BOM, label: "UTF-8 / Unicode (BOM付き)" },
];

//区切り文字
export enum EDelimiter {
  COMMA = "COMMA",
  TAB_CHARACTER = "TAB_CHARACTER",
}

export const delimiterOptions = [
  { value: EDelimiter.COMMA, label: "カンマ" },
  { value: EDelimiter.TAB_CHARACTER, label: "タブ文字" },
];
