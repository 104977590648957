import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { AvatarUploadForm } from "@/features/employee/components/employee-forms/AvatarUploadForm";
import { ContractForm } from "@/features/employee/components/employee-forms/ContractForm";
import { CareerForm } from "@/features/employee/components/employee-forms/CareerForm";
import { InfoForm } from "@/features/employee/components/employee-forms/InfoForm";
import { PassportForm } from "@/features/employee/components/employee-forms/PassportForm";
import { ResidentForm } from "@/features/employee/components/employee-forms/ResidentForm";
import { FC } from "react";
import { Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { getFullName } from "@/utils/string";

type TEmployeeFormProps = {};

export const EmployeeForm: FC<TEmployeeFormProps> = () => {
  const { control } = useFormContext<TEmployeeForm>();

  const lastName = useWatch({ control, name: "lastName" });
  const middleName = useWatch({ control, name: "middleName" });
  const firstName = useWatch({ control, name: "firstName" });
  const nickName = useWatch({ control, name: "nickName" });

  return (
    <MenuNavigate
      stickyTop={240}
      contents={[
        { title: "基本情報", content: <InfoForm /> },
        { title: "本人画像縮尺ツール", content: <AvatarUploadForm /> },
        { title: "パスポート情報", content: <PassportForm /> },
        { title: "在留カード情報", content: <ResidentForm /> },
        { title: "特定技能契約情報", content: <ContractForm /> },
        { title: "職歴", content: <CareerForm /> },
      ]}
      menuPrefix={
        <Typography component="p" variant="body14Bold" sx={{ mb: 1, maxWidth: "200px" }}>
          {getFullName({ lastName: lastName ?? "", firstName: firstName ?? "", middleName })}
          {nickName ? `（${nickName}）` : ""}
        </Typography>
      }
    />
  );
};
