import { useFormField } from "@/components/elements/FormField/FormField";
import { button, state, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { Box, FormControlLabel, Radio as MuiRadio, RadioProps, Typography, css, styled } from "@mui/material";
import { FC, ReactNode } from "react";

type TInputDateProps = {
  label?: string;
  subLabel?: ReactNode;
  nonControl?: boolean;
  error?: boolean;
} & RadioProps;

export const Radio: FC<TInputDateProps> = ({ label, subLabel, nonControl, error, ...rest }) => {
  const { field } = useFormField(nonControl);

  return (
    <RadioWrap>
      <FormControlLabel
        label={
          <Typography variant="body14" color={text.primary}>
            {label}
          </Typography>
        }
        control={<StyledRadio error={Boolean(error)} {...rest} {...field} />}
      />
      <Box ml={3.75}>
        <Typography variant="cap12" color={text.secondary}>
          {subLabel}
        </Typography>
      </Box>
    </RadioWrap>
  );
};

const RadioWrap = styled(Box)`
  .MuiFormControlLabel-root {
    align-items: flex-start;
    gap: 12px;
    margin: 0;
  }
`;

const options = { shouldForwardProp: (propName: string) => !["error"].includes(propName) };

const StyledRadio = styled(MuiRadio, options)<{ error: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  ${({ error }) =>
    error
      ? css`
          color: ${state.error_1}!important;
        `
      : css`
          color: ${button.primary}!important;
        `};
  &.Mui-disabled {
    color: ${button.disable}!important;
  }

  &.Mui-checked .MuiSvgIcon-root:nth-of-type(2) {
    transform: scale(1.2);
  }
  &.Mui-focused {
    &::after {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      width: 100%;
      height: 100%;
      padding: 2px;
      border: 2px solid ${button.secondary};
      box-sizing: content-box;
      border-radius: ${rounded.sm};
      z-index: -1;
    }
  }
`;
