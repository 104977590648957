import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { TSupportPlanForm } from "@/features/anytime/types/supportPlanForm";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TProps = {
  readonly?: boolean;
};

export const ConclusionForm: FC<TProps> = ({ readonly }) => {
  const { control } = useFormContext<TSupportPlanForm>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormField control={control} name="contractTermOther" label="">
          <Input fullWidth readonly={readonly} />
          <Typography variant="cap12" mt={0.5}>
            20文字以下
          </Typography>
        </FormField>
      </Grid>
    </Grid>
  );
};
