import { IconClose } from "@/assets/icons";
import { IconChat } from "@/assets/icons/IconChat";
import { ChatBox } from "@/features/chat/components/ChatBox";
import { useChat } from "@/features/chat/providers/ChatProvider";
import { useVisible } from "@/hooks/useVisible";
import { background, state } from "@/theme/colors";
import { FCC } from "@/types/common";
import { getQueryFromURL } from "@/utils/url";
import { Box, Button, Stack, styled } from "@mui/material";
import { useEffect, useState } from "react";

type TChatProps = {};

export const Chat: FCC<TChatProps> = ({}) => {
  const query = getQueryFromURL();
  const chatPopup = useVisible(!!query && "chat" in query);
  const { currentRoom } = useChat();
  const [unread, setUnread] = useState(false);

  useEffect(() => {
    const hasUnreadOldMessages = currentRoom.oldMessages.some((message) => message.position === "left" && message.status === "sent");
    if (!chatPopup.visible && (currentRoom.newMessages.length > 0 || hasUnreadOldMessages)) {
      setUnread(true);
    } else {
      setUnread(false);
    }
  }, [currentRoom.oldMessages, currentRoom.newMessages]);

  const toggleChat = () => {
    chatPopup.toggle();
    const url = new URL(window.location.href);
    if (unread) setUnread(!unread);

    if (chatPopup.visible) {
      url.searchParams.delete("chat");
    } else {
      url.searchParams.set("chat", "open");
    }
    window.history.pushState(null, "", url.toString());
  };

  return (
    <ChatWrapper>
      <ChatBox open={chatPopup.visible} />
      <ChatButton open={chatPopup.visible} onClick={toggleChat}>
        {unread && <UnreadCircle />}
        <Box>{chatPopup.visible ? <IconClose width={32} height={32} color="white" /> : <IconChat width={24} height={24} />}</Box>
      </ChatButton>
    </ChatWrapper>
  );
};

const ChatWrapper = styled(Stack)`
  position: fixed;
  right: 20px;
  bottom: 0;
  z-index: 10000;
`;

const UnreadCircle = styled(Box)`
  position: absolute;
  right: 0;
  top: 8px;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: ${state.error_1};
  border: 0.5px solid white;
`;

const ChatButton = styled(Button)<{ open: boolean }>`
  width: 64px;
  height: 64px;
  transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0deg)")};
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
  background-color: ${background.main};
  border-radius: 100px;
  border-width: 0px;
  bottom: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16) !important;
  cursor: pointer;
  padding: 0px;
  user-select: none;
  &:hover {
    background-color: ${background.main};
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: baseline;
    transform: ${({ open }) => (open ? "rotateZ(45deg)" : "0")};
  }
`;
