export const INVALID_VALUE = "「{{path}}」は無効です。";
export const INVALID_NOT_EMPTY = "「{{path}}」を入力してください。";
export const INVALID_SELECT_NOT_EMPTY = "「{{path}}」を選択してください。";
export const INVALID_NOT_CONTAINS = "有効な値は「{{includes}}」です。";
export const INVALID_EMAIL = "無効なメールアドレスです。";
export const INVALID_URL = "無効なURL形式です。";
export const INVALID_BOOLEAN = "真偽値で入力する必要があります。";
export const INVALID_LIMIT = "「{{path}}」は「{{max}}」以下を入力してください。";
export const INVALID_NOT_EXISTS = "「{{path}}」が存在しません。";
export const INVALID_NOT_INPUT_REQUIRE_FIELD = "必須項目を入力してください。";
export const INVALID_EXISTED = "「{{path}}」は既に存在する項目です。";

export const INVALID_NUMBER = "「{{path}}」は数字を入力してください。";
export const INVALID_NUMBER_INTEGER = "「{{path}}」は小数を入力できません。整数を入力してください。";
export const INVALID_NUMBER_POSITIVE = "「{{path}}」は0以上を入力してください。";
export const INVALID_NUMBER_NEGATIVE = "「{{path}}」は0以下を入力してください。";
export const INVALID_NUMBER_MIN = "「{{path}}」は「{{min}}」以上を入力してください。";
export const INVALID_NUMBER_MAX = "「{{path}}」は「{{max}}」以下を入力してください。";
export const INVALID_NUMBER_LESS_THAN = "「{{path}}」は「{{less}}」より小さな値を入力してください。";
export const INVALID_NUMBER_MORE_THAN = "「{{path}}」は「{{more}}」より大きな値を入力してください。";

export const INVALID_STRING = "文字を入力してください。";
export const INVALID_STRING_LENGTH = "「{{path}}」は「{{length}}」文字で入力してください。";
export const INVALID_STRING_MIN = "「{{path}}」は少なくとも「{{min}}」文字以上を入力してください。";
export const INVALID_STRING_MAX = "「{{path}}」は最大「{{max}}」文字で入力してください。";
export const INVALID_STRING_EMAIL = "「{{path}}」が無効です。";
export const INVALID_STRING_MATCHES = "「{{path}}」の形式が正しくありません。";
export const INVALID_STRING_KATAKANA = "「{{path}}」はカタカナを入力してください。";
export const INVALID_STRING_KANJI = "「{{path}}」はひらがなを入力してください。";
export const INVALID_STRING_HALF_WIDTH = "「{{path}}」の形式が正しくありません。";
export const INVALID_STRING_UPPERCASE_AND_NUMBER = "「{{path}}」は半角英数字の大文字を入力してください。";
export const INVALID_STRING_LETTERS = "数字ではなく文字のみを入力してください。";
export const INVALID_STRING_LATIN = "「{{path}}」は半角英字を入力してください。";
export const INVALID_STRING_FULL_WIDTH = "「{{path}}」は全角文字を入力してください。";

export const INVALID_PASSWORD_STRONG = "パスワードは半角英数字、最低8桁、大文字1桁以上、小文字1桁以上、数字1桁以上、記号1桁以上にしてください。";
export const INVALID_PASSWORD_CONFIRM = "確認パスワードが一致しません。";

export const INVALID_DATE_STRING = "「{{path}}」の日付の形式が正しくありません。";
export const INVALID_DATE = "「{{path}}」の日付が正しくありません。";
export const INVALID_BEFORE_AGE = "「{{path}}」は、{{age}}歳以上の日付を選択してください。";
export const INVALID_DATE_MUST_AFTER = "「{{end}}」は「{{start}}」より後にする必要があります。";
export const INVALID_DATE_MUST_BEFORE = "「{{start}}」は「{{end}} より前にする必要があります";
export const INVALID_DATE_MUST_PAST = "{{path}}は、今日より前の日付を入力してください。";
export const INVALID_DATE_MUST_PAST_OR_TODAY = "{{path}}は、今日もしくは前の日付を入力してください。";

export const INVALID_ARRAY = "「{{path}}」はリスト形式ではありません。";
export const INVALID_ARRAY_NOT_EMPTY = "「{{path}}」リストは空にすることはできません。";

export const INVALID_ZIP_CODE = "「郵便番号」は7数字で入力してください。";

export const INVALID_TEL_NUMBER = "電話番号が無効です。";
