import { useVisible } from "@/hooks/useVisible";
import Cookies from "js-cookie";
import { useEffect } from "react";

export const useHideElm = (localStorageKey: string) => {
  const { visible, close } = useVisible(true);
  useEffect(() => {
    const companyId = Cookies.get("companyId");
    if (localStorage.getItem(`${companyId}-${localStorageKey}`)) {
      close();
    }
  }, []);

  const onClose = () => {
    const companyId = Cookies.get("companyId");
    localStorage.setItem(`${companyId}-${localStorageKey}`, "true");
    close();
  };

  return { visible, onClose };
};
