import { TAnyTimeDocumentOutputDto } from "@/api/services/main/any-time/dtos/get-any-time-document";
import { TRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
/**
 * Finds a document by its documentKey.
 *
 * @param {TRegularDocumentDto[] | TRegularDocumentDto | undefined} data - The document data to search within. Can be an array of TRegularDocumentDto, a single TRegularDocumentDto, or undefined.
 * @param {string} documentKey - The key of the document to find.
 * @returns {TRegularDocumentDto | undefined} - The document that matches the documentKey, or undefined if not found.
 */
export const findDocument = (data: TRegularDocumentDto[] | TRegularDocumentDto | undefined, documentKey: string): TRegularDocumentDto | undefined => {
  // Return undefined if no data is provided
  if (!data) return undefined;

  // Flatten the data to ensure it's an array and find the document with the matching documentKey
  return [data].flat().find((doc) => doc.masterDocument.documentKey === documentKey);
};

/**
 * Finds a document step by its stepKey.
 *
 * @param {TRegularDocumentDto[] | TRegularDocumentDto | TAnyTimeDocumentOutputDto[] | TAnyTimeDocumentOutputDto | undefined} data - The document data to search within. Can be an array of TRegularDocumentDto, a single TRegularDocumentDto, or undefined.
 * @param {string} stepKey - The key of the document step to find.
 * @returns {(TRegularDocumentDto | TAnyTimeDocumentOutputDto)['steps'][number] | undefined} - The document step that matches the stepKey, or undefined if not found.
 */
export const findDocumentStep = (
  data: TRegularDocumentDto[] | TRegularDocumentDto | TAnyTimeDocumentOutputDto[] | TAnyTimeDocumentOutputDto | undefined,
  stepKey: string,
): (TRegularDocumentDto | TAnyTimeDocumentOutputDto)["steps"][number] | undefined => {
  // Return undefined if no data is provided
  if (!data) return undefined;

  return [data]
    .flat()
    .flatMap((document) => document.steps)
    .find((step) => step.masterDocumentStep.stepKey === stepKey);
};

export const findRetirementDocumentStep = (
  data: TEmployeeRetirementDocumentOutputDto[] | TEmployeeRetirementDocumentOutputDto | undefined,
  stepKey: string,
): TEmployeeRetirementDocumentOutputDto["steps"][number] | undefined => {
  if (!data) return undefined;
  return [data]
    .flat()
    .flatMap((document) => document.steps)
    .find((step) => step.masterDocumentStep.stepKey === stepKey);
};
