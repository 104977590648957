import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { TGetAnyTimeDocumentListOutputDto } from "@/api/services/main/any-time/dtos/get-any-time-document-list.dto";
import { Button } from "@/components/elements/Button";
import { StatusTag } from "@/components/elements/StatusTag";
import { TableSorting } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { AnyTimeDocumentTypeOptions } from "@/features/anytime/constants/enums";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

type TUseDocumentListColumnsProps = {
  handleDeleteDocument: (documentId: number | string) => Promise<void>;
};

export const useDocumentListColumns = ({ handleDeleteDocument }: TUseDocumentListColumnsProps) => {
  const { data: statusOptions } = useEnum("EDocumentStatus");
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TGetAnyTimeDocumentListOutputDto>[]>(
    () => [
      {
        header: "書類の種類",
        Header: <TableSorting name="catd.anyTimeDocumentType">書類の種類</TableSorting>,
        size: 120,
        Cell: ({ row }) => {
          const { anyTimeDocumentType } = row.original;
          return findLabelByValue(AnyTimeDocumentTypeOptions, anyTimeDocumentType) || EMPTY_STR.TEXT;
        },
      },
      {
        header: "外国人名",
        Header: <TableSorting name="employee.employeeFullName">外国人名</TableSorting>,
        size: 120,
        Cell: ({ row }) => {
          return (
            <Stack gap={0.5}>
              {row.original.employees.map((employee) => (
                <Typography variant="body14">{employee.employeeFullName || EMPTY_STR.TEXT}</Typography>
              ))}
            </Stack>
          );
        },
      },
      {
        header: "呼び名",
        Header: <TableSorting name="employee.employeeNickName">呼び名</TableSorting>,
        size: 120,
        Cell: ({ row }) => {
          return (
            <Stack gap={0.5}>
              {row.original.employees.map((employee) => (
                <Typography variant="body14">{employee.employeeNickName || EMPTY_STR.TEXT}</Typography>
              ))}
            </Stack>
          );
        },
      },
      {
        header: "国籍",
        Header: <TableSorting name="employee.employeeNationalityId">国籍</TableSorting>,
        size: 80,
        Cell: ({ row }) => {
          return (
            <Stack gap={0.5}>
              {row.original.employees.map((employee) => (
                <Typography variant="body14">{findLabelByValue(nationalityOptions, employee.employeeNationalityId) || EMPTY_STR.TEXT}</Typography>
              ))}
            </Stack>
          );
        },
      },
      {
        header: "書類作成状況",
        Header: <TableSorting name="catd.status">書類作成状況</TableSorting>,
        size: 100,
        Cell: ({ row }) => {
          return <StatusTag status={row.original.status} title={findLabelByValue(statusOptions, row.original.status)} variant="bold" />;
        },
      },
      {
        header: "最終更新日",
        Header: <TableSorting name="catd.updatedAt">最終更新日</TableSorting>,
        size: 100,
        Cell: ({ row }) => {
          return row.original.updatedAt ? dayjs(row.original.updatedAt).format(DATE_FORMAT) : EMPTY_STR.DATE;
        },
      },
      {
        header: "確認／編集",
        id: "action",
        size: 80,
        Cell: ({ row }) => {
          const { id, status } = row.original;
          return (
            <Button variant="outline" size="sm" component={Link} to={getDynamicRoute(AppRoutes.anytimeDocument, { id })}>
              {status === DOCUMENT_STATUS.IN_PROGRESS ? "編集" : "確認"}
            </Button>
          );
        },
      },
    ],
    [statusOptions, nationalityOptions, genderOptions],
  );

  return columns;
};
