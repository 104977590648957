import { useEnum } from "@/api/services/global/enum";
import { TGetEmployeeDetailListOutputDto } from "@/api/services/main/employee/dtos/get-employee-detail-list.dto";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { useCallback } from "react";
import { RETIREMENT_STATUS } from "@/types/enum";

export const useConvertEmployeeToCsvData = () => {
  const { data: genderOptions } = useEnum("EGender");
  const { data: employmentStatusOptions } = useEnum("EEmploymentStatus");
  const { data: retirementStatusOptions } = useEnum("ERetirementStatus");

  const convertEmployeeToCsvData = useCallback(
    (selectedEmployees: TGetEmployeeDetailListOutputDto[]) => {
      const csvData = selectedEmployees.map((employee) => ({
        ["管理番号"]: employee.managementNumber ? employee.managementNumber.toString() : "",
        ["姓"]: employee.lastName ? employee.lastName.toString() : "",
        ["ミドルネーム"]: employee.middleName ? employee.middleName.toString() : "",
        ["名"]: employee.firstName ? employee.firstName.toString() : "",
        ["国籍"]: employee.nationality ? employee.nationality.nationalityName.toString() : "",
        ["性別"]: employee.gender ? findLabelByValue(genderOptions, employee.gender) : "",
        ["生年月日"]: employee.birthday ? dayjs(employee.birthday).format("YYYY/MM/DD") : "",
        ["入社日"]: employee.hiringDate ? dayjs(employee.hiringDate).format("YYYY/MM/DD") : "",
        ["郵便番号"]: employee.zipCode ? convertNumberToZipCode(employee.zipCode.toString()) : "",
        ["個人住所（都道府県）"]: employee.province ? employee.province : "",
        ["個人住所（市区町村）"]: employee.municipality && employee.province ? employee.municipality : "",
        ["個人住所（都道府県以外）"]: employee.address ? employee.address.toString() : "",
        ["TEL（数字のみ）"]: employee.telNumber ? employee.telNumber.toString() : "",
        ["メールアドレス"]: employee.email ? employee.email.toString() : "",
        ["在留カード番号"]: employee.residenceCode ? employee.residenceCode.toString() : "",
        ["在留期限（在留期間満了日）"]: employee.residenceExpiryDate ? dayjs(employee.residenceExpiryDate).format("YYYY/MM/DD") : "",
        ["配偶者の有無"]: "", //renew-visa
        ["在留資格"]: employee.residenceStatus ? employee.residenceStatus.residenceStatus.toString() : "",
        ["特定産業分野"]: employee.contractSpecificBusinessClassificationOne
          ? employee.contractSpecificBusinessClassificationOne.specificIndustry.companySpecificIndustry.companySpecificIndustry
          : "",
        ["業務区分"]: employee.contractSpecificBusinessClassificationOne
          ? employee.contractSpecificBusinessClassificationOne.companySpecificBusinessClassification.companySpecificBusinessClassificationName
          : "",
        ["職業"]: "", //renew-visa
        ["出生地"]: employee.passportPermanentAddress ? employee.passportPermanentAddress.toString() : "",
        ["旅券番号"]: employee.passportCode ? employee.passportCode.toString() : "",
        ["旅券有効期限"]: employee.passportExpiryDate ? dayjs(employee.passportExpiryDate).format("YYYY/MM/DD") : "",
        ["在留期間"]: employee.residencePeriod && employee.residencePeriod.residencePeriod ? employee.residencePeriod.residencePeriod.toString() : "",
        ["主たる職種"]: employee.contractMainOccupation ? employee.contractMainOccupation.mainOccupation.toString() : "",
        ["月額報酬（円）"]: employee.contractMonthlySalary ? employee.contractMonthlySalary.toString() : "",
        ["雇用契約期間　始期"]: employee.contractStartDate ? dayjs(employee.contractStartDate).format("YYYY/MM/DD") : "",
        ["雇用契約期間　終期"]: employee.contractEndDate ? dayjs(employee.contractEndDate).format("YYYY/MM/DD") : "",
        ["扶養する家族などメモ"]: employee.careerDependentNote ? employee.careerDependentNote.toString() : "",
        ["在籍ステータス"]: employee.employmentStatus
          ? employee.retirementStatus != RETIREMENT_STATUS.ENROLLMENT
            ? findLabelByValue(retirementStatusOptions, employee.retirementStatus)
            : findLabelByValue(employmentStatusOptions, employee.employmentStatus)
          : "",
        ["退職日"]: employee.retirementDate ? dayjs(employee.retirementDate).format("YYYY/MM/DD") : "",
        ["備考"]: employee.note ? employee.note.toString() : "",
      }));
      return csvData;
    },
    [genderOptions, employmentStatusOptions],
  );

  return { convertEmployeeToCsvData };
};
