import { IconChevronLeft } from "@/assets/icons";
import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";

type TPageBackProps = {
  backUrl?: string;
  onBack?: () => void;
};

export const PageBack: FC<TPageBackProps> = ({ backUrl, onBack }) => {
  const navigate = useNavigate();

  const handlePageBack = () => {
    if (!onBack) {
      navigate(-1);
      return;
    }
    onBack();
  };

  return (
    <PageBackWrap>
      <Stack direction="row" gap={1} alignItems="center" onClick={handlePageBack}>
        <IconChevronLeft fontSize={24} />
        <Typography variant="body14">戻る</Typography>
      </Stack>
      {backUrl && <StyledLink to={backUrl} />}
    </PageBackWrap>
  );
};

const PageBackWrap = styled(Box)`
  position: relative;
  cursor: pointer;
  width: fit-content;
  &:hover {
    opacity: 0.5;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
