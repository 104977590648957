import { useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { useCompanyInfo } from "@/api/services/main/company";
import { Button } from "@/components/elements/Button";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { useLocationInfo } from "@/hooks/useLocationInfo";
import { setErrorForm, showError } from "@/utils/error";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TSpecificSkillsAffiliationProps = {
  readonly?: boolean;
};

export const SpecificSkillsAffiliation: FC<TSpecificSkillsAffiliationProps> = ({ readonly }) => {
  const methods = useFormContext<TEmploymentContractForm>();
  const { control, setValue } = methods;
  const { companySpecificIndustryOptions, isLoading: usLoadingSpecificIndustry } = useGetCompanySpecificIndustryList();
  useLocationInfo({ methods, names: { zipCode: "companyZipCode", province: "companyProvince" } });

  const { refetch } = useCompanyInfo(false);

  const refreshCompanyInfo = async () => {
    try {
      const rs = await refetch();
      const companyData = rs.data?.data;
      if (!companyData) return;
      setValue("companyName", companyData.companyName, { shouldDirty: true });
      setValue("companyFuriganaName", companyData.companyFuriganaName ?? null, { shouldDirty: true });
      setValue("companyIdentificationNumber", companyData.companyIdentificationNumber ?? null, { shouldDirty: true });
      setValue("companySpecificIndustryId", companyData.specificIndustryId ?? null, { shouldDirty: true });
      setValue("companyZipCode", companyData.zipCode ?? null, { shouldDirty: true });
      setValue("companyProvince", companyData.province ?? null, { shouldDirty: true });
      setValue("companyAddress", companyData.fullAddress ?? null, { shouldDirty: true });
      setValue("companyTelNumber", companyData.telNumber ?? null, { shouldDirty: true });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <InnerBox
      title="特定技能所属機関"
      mt={-4}
      required
      rightContent={
        readonly ? undefined : (
          <Button size="sm" onClick={refreshCompanyInfo}>
            企業情報を再取得する
          </Button>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="companyName">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companyFuriganaName">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companyIdentificationNumber">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companySpecificIndustryId">
            <Select options={companySpecificIndustryOptions} loading={usLoadingSpecificIndustry} readonly />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="companyZipCode">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companyProvince">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companyAddress">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companyTelNumber">
            <Input readonly />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};
