import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import { AcceptingWorkerDocument } from "@/features/retirement/components/retirement-document/documents/accepting-worker";
import { EmploymentContractDocument } from "@/features/retirement/components/retirement-document/documents/employment-contract";
import { useScrollToElement } from "@/hooks/useScrollToElement";
import { DOCUMENT_KEYS } from "@/types/enum";
import { Box } from "@mui/material";
import { FC } from "react";

type TDocumentProps = {
  document: TEmployeeRetirementDocumentOutputDto;
  readonly?: boolean;
};

export const Document: FC<TDocumentProps> = ({ document, readonly = false }) => {
  const { elmId } = useScrollToElement("employment_contract_section");

  const isAcceptingWorkerDocument = (data: unknown): data is TEmployeeRetirementDocumentOutputDto => {
    return document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_ACCEPTING_WORKERS;
  };
  const isEmploymentContractDocument = (data: unknown): data is TEmployeeRetirementDocumentOutputDto => {
    return document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT;
  };

  if (isAcceptingWorkerDocument(document)) {
    return <AcceptingWorkerDocument document={document} readonly={readonly} />;
  }

  if (isEmploymentContractDocument(document)) {
    return (
      <Box component="section" pt={5} id={elmId}>
        <EmploymentContractDocument document={document} readonly={readonly} />
      </Box>
    );
  }

  return null;
};
