import { TopPage } from "@/components/elements/TopPage";
import { RetirementFilesTable } from "@/pages/retirement/retirement-files/RetirementFilesTable";
import { RetirementFilesTop } from "@/pages/retirement/retirement-files/RetirementFilesTop";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack, styled } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

export const RetirementFilesPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const readonly = searchParams.get("readonly") === "true";

  return (
    <Box>
      <TopPage
        title="雇用契約の変更に係る届出の添付ファイル一覧"
        description={`提出に必要な添付ファイルをアップロードしてください。\n提出形式：PDF`}
        backUrl={getDynamicRoute(AppRoutes.retirementDocument, params)}
      />
      <RetirementFilesWrapper gap={3}>
        <RetirementFilesTop readonly={readonly} />
        <RetirementFilesTable readonly={readonly} />
      </RetirementFilesWrapper>
    </Box>
  );
};

const RetirementFilesWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
`;
