import { useEnum } from "@/api/services/global/enum";
import { useEmployeeRetirementDocumentList } from "@/api/services/main/retirement-document";
import { TGeTEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document-list.dto";
import { Button } from "@/components/elements/Button";
import { StatusTag } from "@/components/elements/StatusTag";
import { Table, TableSorting } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export const RetirementTable = () => {
  const { data: statusOptions } = useEnum("EDocumentStatus");
  const columns = useMemo<MRT_ColumnDef<TGeTEmployeeRetirementDocumentOutputDto>[]>(
    () => [
      {
        header: "外国人名",
        Header: <TableSorting name="employee.fullName">外国人名</TableSorting>,

        Cell: ({ row }) => {
          const { firstName, lastName, middleName } = row.original;
          const fullName = getFullName({ firstName: firstName || "", lastName, middleName });

          return (
            <Typography sx={{ textTransform: "uppercase" }} variant="body14">
              {fullName || EMPTY_STR.TEXT}
            </Typography>
          );
        },
        size: 120,
      },
      {
        header: "呼び名",
        Header: <TableSorting name="employee.nickName">呼び名</TableSorting>,
        Cell: ({ row }) => {
          return row.original.nickName || EMPTY_STR.TEXT;
        },
        size: 100,
      },
      {
        header: "国籍",
        Header: <TableSorting name="employee.nationality">国籍</TableSorting>,
        Cell: ({ row }) => {
          return row.original.nationality || EMPTY_STR.TEXT;
        },
        size: 120,
      },
      {
        header: "退職日",
        Header: <TableSorting name="employee.retirementDate">退職日</TableSorting>,
        id: "retirementDate",
        accessorKey: "retirementDate",
        Cell: ({ row }) => {
          const { retirementDate } = row.original;
          return (
            <Typography variant="body14" color={dayjs(retirementDate)?.isSameOrBefore(dayjs(), "day") ? text.error : text.primary}>
              {retirementDate ? dayjs(retirementDate).format(DATE_FORMAT) : EMPTY_STR.DATE}
            </Typography>
          );
        },
        size: 120,
      },
      {
        header: "提出目標日",
        Header: <TableSorting name="employee.retirementDate">提出目標日</TableSorting>,
        Cell: ({ row }) => {
          const { retirementDate } = row.original;
          const submissionTargetDate = retirementDate ? dayjs(retirementDate).add(13, "day") : null;

          return <Typography variant="body14">{submissionTargetDate ? submissionTargetDate.format(DATE_FORMAT) : EMPTY_STR.DATE}</Typography>;
        },
        size: 120,
      },
      {
        header: "書類作成状況",
        Header: <TableSorting name="empRetirementDoc.status">書類作成状況</TableSorting>,

        Cell: ({ row }) => {
          const { status } = row.original;
          const title = status === DOCUMENT_STATUS.COMPLETE ? "完了" : findLabelByValue(statusOptions, status);

          return <StatusTag status={status} title={title} variant="bold" />;
        },
        size: 120,
      },
      {
        header: "確認／編集",
        id: "action",
        Cell: ({ row }) => {
          const { status, documentGroupId } = row.original;
          return (
            <Button variant="outline" size="sm" component={Link} to={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })}>
              {status === DOCUMENT_STATUS.IN_PROGRESS ? "編集" : "確認"}
            </Button>
          );
        },
        size: 20,
      },
    ],
    [statusOptions],
  );

  const { data, isLoading } = useEmployeeRetirementDocumentList();

  const { table } = useTable({ columns, rows: data?.data ?? [], failedRow: (row) => dayjs(row.retirementDate)?.isSameOrBefore(dayjs(), "day") });

  return <Table table={table} isLoading={isLoading} />;
};
