import { useFormField } from "@/components/elements/FormField";
import { button, state, text } from "@/theme/colors";
import { Checkbox as MuiCheckbox, CheckboxProps, styled, Stack, FormControlLabel, Typography, Box, css } from "@mui/material";
import React, { ChangeEvent, FC, ReactNode } from "react";

export type TCheckboxProps = {
  label?: string;
  subLabel?: string;
  nonControl?: boolean;
  prefixLabel?: ReactNode;
  error?: boolean;
  labelColor?: string;
} & CheckboxProps;

export const Checkbox: FC<TCheckboxProps> = ({ label, subLabel, nonControl = false, prefixLabel, error, labelColor = text.primary, ...rest }) => {
  const { field } = useFormField(nonControl);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    field?.onChange(e);
    field?.onBlur();
    rest.onChange && rest.onChange(e, e.target.checked);
  };
  return (
    <CheckboxWrap>
      <FormControlLabel
        label={
          <Stack flexDirection="row" gap={0.5}>
            {prefixLabel && (
              <Typography variant="body14" color={text.primary}>
                {prefixLabel}
              </Typography>
            )}
            <Stack gap={0.5}>
              {label && (
                <Typography variant="body14" color={labelColor}>
                  {label}
                </Typography>
              )}
              {subLabel && (
                <Typography variant="cap12" color={text.secondary}>
                  {subLabel}
                </Typography>
              )}
            </Stack>
          </Stack>
        }
        control={<StyledCheckbox error={Boolean(error)} {...rest} {...field} onChange={handleChange} />}
      />
    </CheckboxWrap>
  );
};

const CheckboxWrap = styled(Box)`
  .MuiFormControlLabel-root {
    align-items: flex-start;
    gap: 12px;
    margin: 0;
  }
`;

const options = { shouldForwardProp: (propName: string) => !["error"].includes(propName) };

const StyledCheckbox = styled(MuiCheckbox, options)<{ error: boolean }>`
  width: 18px;
  height: 18px;
  ${({ error }) =>
    error
      ? css`
          color: ${state.error_1}!important;
        `
      : css`
          color: ${button.primary}!important;
        `};
  margin-top: 4px;
  &.Mui-disabled {
    color: ${button.disable}!important;
  }
`;
