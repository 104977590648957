import * as React from "react";

export const IconNotification = ({ hasDot, ...rest }: React.SVGProps<SVGSVGElement> & { hasDot?: boolean }): React.JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4552 2.47362C13.6943 2.16764 12.8649 2 11.9999 2C8.64386 2 5.82448 4.52345 5.45387 7.85899L5.20207 10.1252L5.19602 10.1793C5.08319 11.1625 4.76315 12.1106 4.25702 12.9611L4.22907 13.0077L3.65104 13.9711L3.62689 14.0114C3.38537 14.4138 3.16655 14.7785 3.0208 15.0879C2.87268 15.4024 2.71431 15.8286 2.79054 16.3117C2.86589 16.7893 3.11167 17.2233 3.48239 17.5337C3.85746 17.8476 4.30434 17.931 4.65023 17.9658C4.9906 18.0001 5.41585 18 5.88524 18L5.93214 18H18.0677L18.1146 18C18.584 18 19.0092 18.0001 19.3496 17.9658C19.6955 17.931 20.1424 17.8476 20.5174 17.5337C20.8882 17.2233 21.1339 16.7893 21.2093 16.3117C21.2855 15.8286 21.1271 15.4024 20.979 15.0879C20.8333 14.7785 20.6144 14.4138 20.3729 14.0114L20.3488 13.9711L19.7708 13.0077L19.7428 12.9611C19.3641 12.3247 19.0895 11.6336 18.928 10.914C18.6273 10.9705 18.3171 11 17.9999 11C17.6159 11 17.2421 10.9567 16.883 10.8748C17.0675 11.9715 17.454 13.0259 18.0241 13.9839L18.0558 14.0367L18.6338 15.0001C18.9073 15.456 19.0721 15.733 19.1696 15.9401C19.175 15.9514 19.1799 15.9621 19.1844 15.972C19.1735 15.9733 19.1618 15.9746 19.1494 15.9759C18.9216 15.9988 18.5993 16 18.0677 16H5.93214C5.40055 16 5.07817 15.9988 4.85043 15.9759C4.83797 15.9746 4.82633 15.9733 4.81547 15.972C4.81995 15.9621 4.82484 15.9514 4.83017 15.9401C4.9277 15.733 5.09252 15.456 5.36602 15.0001L5.94406 14.0367L5.97569 13.9839C6.62643 12.8904 7.03791 11.6714 7.18298 10.4073L7.18984 10.3461L7.44164 8.07985C7.69971 5.75718 9.66295 4 11.9999 4C12.4629 4 12.9113 4.06898 13.3346 4.19767C13.5866 3.54594 13.9711 2.96023 14.4552 2.47362ZM16.982 4.27804C16.3941 4.62632 15.9999 5.26713 15.9999 6C15.9999 6.14503 16.0153 6.28645 16.0447 6.42272C16.1651 6.6476 16.2674 6.88404 16.3496 7.13021C16.7101 7.65547 17.3148 8 17.9999 8C18.1918 8 18.3774 7.97298 18.553 7.92254L18.546 7.85899C18.3937 6.48875 17.8282 5.25555 16.982 4.27804Z"
      fill="white"
    />
    <path
      d="M9.10222 17.6647C9.27315 18.6215 9.64978 19.467 10.1737 20.0701C10.6976 20.6731 11.3396 21 12 21C12.6604 21 13.3024 20.6731 13.8263 20.0701C14.3502 19.467 14.7269 18.6215 14.8978 17.6647"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    {hasDot && <circle cx="18" cy="6" r="2.5" fill="#E15F00" stroke="#E15F00" />}
  </svg>
);
