import { usePreviousDocumentList } from "@/api/services/main/regular-document";
import { Pagination } from "@/components/elements/Pagination";
import { HistoryTable } from "@/features/regular-document/components/HistoryTable";
import { Stack } from "@mui/material";
import { FC } from "react";

type TRegularReportHistoryPageProps = {};

export const RegularReportHistoryPage: FC<TRegularReportHistoryPageProps> = () => {
  const { data } = usePreviousDocumentList();

  return (
    <Stack padding={3} gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Pagination totalPage={data?.pagination.totalPage} />
      </Stack>
      <HistoryTable />
    </Stack>
  );
};
