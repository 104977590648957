import { createTheme } from "@mui/material/styles";

import { breakpoints } from "@/theme/breakpoints";
import { typography } from "@/theme/typography";
import { components } from "@/theme/components";

const theme = createTheme({
  breakpoints,
  typography,
  components,
});

export default theme;
