import { TAnyTimeDocumentEmployee } from "@/api/entities/any-time-document-employee.entity";
import { FormField } from "@/components/elements/FormField";
import { Table } from "@/components/elements/Table";
import { PickEmployeesModal } from "@/features/anytime/components/anytime-detail/PickEmployeesModal";
import { useEligibleForeignNationalColumns } from "@/features/anytime/hooks/useEligibleForeignNationalColumns";
import { useFormTable } from "@/hooks/useFormTable";
import { useVisible } from "@/hooks/useVisible";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TEligibleForeignNationalsProps = {
  readonly?: boolean;
};

export const EligibleForeignNationals: FC<TEligibleForeignNationalsProps> = ({ readonly }) => {
  const columns = useEligibleForeignNationalColumns({});
  const { control } = useFormContext<{ employees: Omit<TAnyTimeDocumentEmployee, "id">[] }>();
  const { table } = useFormTable({ columns, control, name: "employees", primaryKey: "employeeId", readonly });

  const pickModal = useVisible();
  table.addNewRow = readonly ? undefined : pickModal.open;

  return (
    <Stack gap={1.5}>
      <Typography variant="head20Semi">対象の外国人</Typography>
      <FormField control={control} name="employees" label="">
        <Table table={table} />
      </FormField>
      {pickModal.visible && <PickEmployeesModal isOpen={pickModal.visible} onClose={pickModal.close} />}
    </Stack>
  );
};
