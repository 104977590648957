import { useEnum } from "@/api/services/global/enum";
import { setCompleteRegularDocumentApi, skipPresentDocumentApi, usePresentDocumentList } from "@/api/services/main/regular-document";
import { IconErrorOutline } from "@/assets/icons/IconErrorOutline";
import { Button } from "@/components/elements/Button";
import { Tooltip } from "@/components/elements/Tooltip";
import { DATE_FORMAT, MONTH_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useCheckRegularDocument } from "@/features/regular-document/hooks/useCheckRegularDocument";
import { background, button, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded, spacing } from "@/theme/variables";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { findLabelByValue } from "@/utils/object";
import { getEndDateOfQuarter } from "@/utils/pieces";
import { Box, Stack, Typography, css, styled } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import moment from "moment-timezone";

export type TDocumentsDescriptionProps = {};

export const DocumentsDescription: FC<TDocumentsDescriptionProps> = () => {
  const { data: presentDocumentListResult } = usePresentDocumentList();
  const documentData = presentDocumentListResult?.data;
  const { data: quarterOptions } = useEnum("EQuarter");
  const queryClient = useQueryClient();

  const { finished, completed } = useCheckRegularDocument();

  const hasReachedDeadline = () => {
    if (!documentData) return false;

    const endOfQuarter = getEndDateOfQuarter({ year: documentData.year, quarter: documentData.quarter });
    const day = endOfQuarter.add(1, "day").format(DATE_FORMAT);
    const deadlineDate = moment(day).tz("Asia/Tokyo").startOf("day");
    const currentDate = moment().tz("Asia/Tokyo");
    return currentDate >= deadlineDate;
  };

  const canSubmitDocument = () => {
    return finished && hasReachedDeadline();
  };

  const submitDocument = async () => {
    if (!canSubmitDocument()) return;

    try {
      await setCompleteRegularDocumentApi();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PREVIOUS] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_ACCEPTANCE_ACTIVITY_DOCUMENT] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_ACCEPTANCE_ACTIVITY_FILE] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_FILE_LIST] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_LIST] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_LIFE_SUPPORT_DOCUMENT] });
    } catch (error) {
      showError(error);
    }
  };

  const skipDocument = async () => {
    try {
      await skipPresentDocumentApi();
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT] });
    } catch (error) {
      showError(error);
    }
  };

  const deadlineRender = useMemo(() => {
    if (!documentData) return "";
    const endOfQuarter = getEndDateOfQuarter({ year: documentData.year, quarter: documentData.quarter });
    return endOfQuarter.add(14, "day").format(DATE_FORMAT);
  }, [documentData?.quarter, documentData?.year]);

  const enableSkip = useMemo(() => {
    if (!documentData) return false;
    const endOfQuarter = getEndDateOfQuarter({ year: documentData.year, quarter: documentData.quarter });
    return dayjs().isAfter(endOfQuarter);
  }, [documentData]);

  return (
    <Stack gap={3}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Deadline>
          <Typography variant="sub16Bold">提出締切</Typography>
          <Typography variant="sub16Bold">{deadlineRender}</Typography>
        </Deadline>
        <Stack flexDirection="row" alignItems="flex-start" gap={spacing.sm}>
          <Button variant="secondary" size="md" disabled={!enableSkip} onClick={skipDocument}>
            {`第${documentData?.quarter}四半期をスキップする`}
          </Button>
          <Stack gap={1} alignItems="center">
            <CompletedButton
              disabled={!canSubmitDocument()}
              onClick={submitDocument}
            >{`${findLabelByValue(quarterOptions, toNumber(documentData?.quarter))}の定期届出を完了`}</CompletedButton>
            {!completed && (
              <Typography variant="body14Bold" color={state.error_1}>
                全てのステップを完了してください。
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography variant="head20Bold">準備するもの</Typography>
        <Stack flexDirection="row" alignItems="center" gap={spacing.xxs}>
          <Typography variant="body14">・PDF添付するための外国人と比較対象の日本人の賃金台帳</Typography>
          <Tooltip
            title="補足説明"
            content={
              <Typography variant="body14">
                該当月に入金された金額がわかる賃金台帳。（例：4月就労分給与 が 5月に支給の場合は、5月分の賃金台帳となる）
              </Typography>
            }
          >
            <IconErrorOutline />
          </Tooltip>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={spacing.xxs}>
          <Typography variant="body14">・出勤日数が分かる資料（出勤簿、タイムカード等形式は各社による）</Typography>
          <Tooltip
            title="補足説明"
            content={
              <Typography variant="body14">出勤日数とは該当月の1日から末日までに就労された日数です。（給与の締め日換算ではありません）</Typography>
            }
          >
            <IconErrorOutline />
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Deadline = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${text.accent};
`;

const CompletedButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: ${button.secondary}!important;
    border-radius: ${rounded.sm};
    padding: ${`10px ${spacing.md}`};
    color: ${background.white}!important;
    border: none !important;
    ${css(typography.sub16Semi)}
    cursor: pointer;
    &:disabled {
      background-color: ${button.disable}!important;
    }
  }
`;
