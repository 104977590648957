import { useCompanyInfo } from "@/api/services/main/company";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AnytimeSupportPlanCreatePage } from "@/features/anytime/pages/documents/AnytimeSupportPlanCreate";
import { getInitValues } from "@/features/anytime/utils/support-plan";
import React from "react";

const AnytimeSupportPlanCreate = () => {
  const { data: companyResult, isLoading } = useCompanyInfo();

  if (isLoading || !companyResult) return <LoadingPage />;
  return <AnytimeSupportPlanCreatePage defaultValues={getInitValues(companyResult.data)} />;
};

export default AnytimeSupportPlanCreate;
