import { TRegularDocumentStep } from "@/api/entities/regularDocumentStep.entity";
import { useEmployeeList } from "@/api/services/main/employee";
import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { TRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { StepCard } from "@/components/elements/StepCard";
import { Table } from "@/components/elements/Table";
import { ISO_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { text } from "@/theme/colors";
import { EMPLOYMENT_STATUS } from "@/types/enum";
import { pick } from "@/utils/object";
import { getEndDateOfQuarter, getStartDateOfQuarter } from "@/utils/pieces";
import { getFullName } from "@/utils/string";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo } from "react";

type TStepProps = {
  document: TRegularDocumentDto;
  stepData: TRegularDocumentStep;
  readonly?: boolean;
};

export const LifeSupportConfirmDiscussionRecordStep: FC<TStepProps> = ({ stepData, document, readonly }) => {
  const { masterDocumentStep } = stepData;
  const { stepOrder, stepName } = masterDocumentStep;

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeListOutputDto>[]>(
    () => [
      {
        header: "No.",
        Cell: ({ row }) => row.index + 1,
        size: 50,
      },
      {
        header: "勤務地",
        Cell: ({ row }) => {
          const { contractCompanyBranch } = row.original;
          return contractCompanyBranch?.branchName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "面談対象者名",
        Cell: ({ row }) => getFullName(pick(row.original, "firstName", "middleName", "lastName")),
      },
      {
        header: "呼び名",
        Cell: ({ row }) => row.original.nickName || EMPTY_STR.TEXT,
      },
      {
        header: "国籍",
        Cell: ({ row }) => {
          const { nationality } = row.original;
          return nationality?.nationalityName || EMPTY_STR.TEXT;
        },
        size: 250,
      },
    ],
    [],
  );

  const { data, isLoading } = useEmployeeList({
    filter: {
      startWorking: getStartDateOfQuarter(pick(document, "year", "quarter")).format(ISO_FORMAT),
      endWorking: getEndDateOfQuarter(pick(document, "year", "quarter")).format(ISO_FORMAT),
      residenceStatusIds: [1],
      employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
    },
  });

  const employeeList = data?.data ?? [];
  const countEmployeeList = employeeList.length;

  const { table } = useTable({
    columns,
    rows: employeeList,
  });

  if (readonly) return null;

  return (
    <StepCard title={`ステップ${stepOrder}`} subTitle={stepName} rightText={`対象者：${countEmployeeList}名`}>
      <Stack spacing={3}>
        <Typography variant="body14">定期面談報告書の記録・保管をお願いします。提出は不要です。</Typography>
        <Table table={table} isLoading={isLoading} maxHeight={180} />
        <Typography variant="body14" color={text.accent}>
          注意：上記の特定技能外国人だけでなく、必ず監督者も面談を実施ください。
        </Typography>
      </Stack>
    </StepCard>
  );
};
