import { TopPage } from "@/components/elements/TopPage";
import { findDocumentStep } from "@/utils/document";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { PayrollFilesTop } from "@/features/regular-document/components/accept-activity/payroll-files/PayrollFilesTop";
import { PayrollFilesTable } from "@/features/regular-document/components/accept-activity/payroll-files/PayrollFilesTable";
import { LoadingPage } from "@/components/elements/Loading/Loading";

export const RegularPresentAcceptActivityPayrollFilesPage = () => {
  const { data: presentDocumentListResult, isLoading } = usePresentDocumentList();
  const documentData = presentDocumentListResult?.data;
  const stepData = findDocumentStep(documentData?.regularDocuments, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT);
  const stepId = stepData?.id;

  if (isLoading) return <LoadingPage />;
  if (!stepId) return null;
  return (
    <Box>
      <TopPage
        title="賃金台帳原本のアップロード"
        description={`特定技能外国人と比較対象となる日本人の賃金台帳をアップロードしてください。\n提出形式：PDF Excel`}
        backUrl={AppRoutes.regularPresent}
      />
      <PayrollFilesWrapper gap={3}>
        <PayrollFilesTop documentStepId={stepId} documentStepStatus={stepData.status} />
        <PayrollFilesTable documentStepId={stepId} />
      </PayrollFilesWrapper>
    </Box>
  );
};

const PayrollFilesWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
`;
