import { IconSend } from "@/assets/icons/IconSend";
import { Input } from "@/components/elements/Input";
import { useChat } from "@/features/chat/providers/ChatProvider";
import { primary } from "@/theme/colors";
import { Box, Button, CircularProgress, styled } from "@mui/material";
import { KeyboardEventHandler, useState } from "react";

export const InputMsg = () => {
  const { currentRoom } = useChat();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleSendMessage = async () => {
    setLoading(true);
    await currentRoom.sendMsg(inputValue);
    setInputValue("");
    setLoading(false);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = async (e) => {
    if (loading || e.nativeEvent.isComposing) return;
    if (e.key === "Enter" && e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      if (!inputValue) return;
      await handleSendMessage();
    }
  };

  const onFocus = async () => {
    await currentRoom?.readMsg();
  };

  return (
    <InputMsgWrapper>
      <InputWrapper>
        <Input
          multiline
          autoComplete="off"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          fullWidth
          size="lg"
          disabled={loading}
        />
      </InputWrapper>
      <StyledButton onClick={handleSendMessage} disabled={loading || !inputValue}>
        {loading ? <CircularProgress size={20} color="inherit" /> : <IconSend fontSize={20} />}
      </StyledButton>
    </InputMsgWrapper>
  );
};

const InputMsgWrapper = styled(Box)`
  padding: 16px 16px 24px;
  display: flex;
  gap: 8px;
  align-items: flex-end;
  .MuiInputBase-root {
    padding: 0;
  }
`;

const InputWrapper = styled(Box)`
  height: 100%;
  flex: 1;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 20, 10, 0.1);
  display: flex;
  width: 100%;
  > div {
    flex: 1;
    height: 100%;
    > div {
      flex: 1;
    }
    > input {
      height: 100%;
      width: 100%;
      padding: 8px 16px;
      border: none;
      outline: none;
    }
    fieldset {
      top: 0;
      border: none;
    }
  }
`;

const StyledButton = styled(Button)`
  color: white !important;
  background-color: ${primary.main};
  display: flex;
  gap: 4px;
  align-items: center;
  height: 45px;
  min-width: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${primary.main};
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.8;
  }
`;
