import { useCompanyInfo } from "@/api/services/main/company";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { ESupportOutsourcingContractModificationType } from "@/features/anytime/constants/enums";
import { AnytimeSupportContractCreatePage } from "@/features/anytime/pages/documents/AnytimeSupportContractCreate";
import { getInitValues } from "@/features/anytime/utils/support-scontract";
import React from "react";

const AnytimeSupportContractCreate = () => {
  const { data: companyResult, isLoading } = useCompanyInfo();

  if (isLoading || !companyResult) return <LoadingPage />;
  return (
    <AnytimeSupportContractCreatePage
      defaultValues={{
        ...getInitValues(companyResult.data),
        modificationType: ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT,
      }}
    />
  );
};

export default AnytimeSupportContractCreate;
