import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { ChangesForm } from "@/features/anytime/components/anytime-detail/support-contract/forms/ChangesForm";
import { ConclusionForm } from "@/features/anytime/components/anytime-detail/support-contract/forms/ConclusionForm";
import { TerminationForm } from "@/features/anytime/components/anytime-detail/support-contract/forms/TerminationForm";
import { ESupportOutsourcingContractModificationType } from "@/features/anytime/constants/enums";
import { Stack } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TReasonFormProps = {
  readonly?: boolean;
};

export const ReasonForm: FC<TReasonFormProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext();
  const { data: supportOutsourcingContractModificationTypeOptions } = useEnum("ESupportOutsourcingContractModificationType");
  const value = useWatch({ control, name: "modificationType" });

  return (
    <Stack gap={2}>
      <InnerBox title="届出の事由" required>
        <FormField control={control} name="modificationType" label="">
          <RadioGroup
            options={supportOutsourcingContractModificationTypeOptions}
            disabled={readonly}
            onChange={(value) => {
              if (value !== ESupportOutsourcingContractModificationType.RENEW_CONTRACT) {
                setValue("renewContractDate", null);
                setValue("renewContractContent", null);
              }
              if (value !== ESupportOutsourcingContractModificationType.MODIFY_CONTRACT) {
                setValue("modifyContractDate", null);
                setValue("modifyContractReason", null);
                setValue("modifyContractContent", null);
              }
              if (value !== ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT) {
                setValue("terminateContractDate", null);
                setValue("terminateContractMajorCategoryReason", null);
                setValue("terminateContractSubcategoryReason", null);
                setValue("terminateContractOtherReason", null);
              }
            }}
          />
        </FormField>
      </InnerBox>
      {value === ESupportOutsourcingContractModificationType.RENEW_CONTRACT && (
        <InnerBox title="契約の締結変更" required>
          <ChangesForm readonly={readonly} />
        </InnerBox>
      )}
      {value === ESupportOutsourcingContractModificationType.MODIFY_CONTRACT && (
        <InnerBox title="契約の変更" required>
          <TerminationForm readonly={readonly} />
        </InnerBox>
      )}
      {value === ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT && (
        <InnerBox title="契約の終了" required>
          <ConclusionForm readonly={readonly} />
        </InnerBox>
      )}
    </Stack>
  );
};
