import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { CompanySupporterForm } from "@/features/anytime/components/anytime-detail/CompanySupporterForm";
import { EligibleForeignNationals } from "@/features/anytime/components/anytime-detail/EligibleForeignNationals";
import { ReasonForm } from "@/features/anytime/components/anytime-detail/employment-contract/ReasonForm";
import { SpecificSkillsAffiliation } from "@/features/anytime/components/anytime-detail/SpecificSkillsAffiliation";
import { EEmploymentContractModificationType } from "@/features/anytime/constants/enums";
import { employmentContractSchema } from "@/features/anytime/schema/employmentContractSchema";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

type TAnytimeEmploymentContractDetailPageProps = {
  defaultValues: TEmploymentContractForm;
};

export const AnytimeEmploymentContractDetailPage: FC<TAnytimeEmploymentContractDetailPageProps> = ({ defaultValues }) => {
  const { documentId } = useParams();
  const methods = useForm<TEmploymentContractForm>({
    defaultValues,
    resolver: yupResolver(employmentContractSchema),
  });

  const modificationType = useWatch({ control: methods.control, name: "modificationType" });

  const labelReasonForm = useMemo(() => {
    if (modificationType === EEmploymentContractModificationType.MODIFY_CONTRACT) return "契約の変更";
    if (modificationType === EEmploymentContractModificationType.TERMINATE_CONTRACT) return "契約の終了";
    if (modificationType === EEmploymentContractModificationType.RENEW_CONTRACT) return "新たな契約の締結";
    return null;
  }, [modificationType]);

  const onSubmit = () => {};

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={employmentContractSchema}>
      <TopPage title="雇用契約の変更に係る届出" backUrl={getDynamicRoute(AppRoutes.anytimeDocument, { id: documentId })} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <EligibleForeignNationals readonly /> },
          { title: "届出の事由", content: <ReasonForm readonly /> },
          labelReasonForm ? { title: labelReasonForm, content: <Box /> } : [],
          { title: "特定技能所属機関", content: <SpecificSkillsAffiliation readonly /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly /> },
        ].flat()}
      />
    </Form>
  );
};
