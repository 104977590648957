import { Button } from "@/components/elements/Button";
import { EmployeeExportCsvButton } from "@/features/employee/components/EmployeeExportCsvButton";
import { EmployeeImportCsvButton } from "@/features/employee/components/EmployeeImportCsvButton";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

type TEmployeeListBtnActionsProps = {
  selectedEmployeeIds: string[];
};

export const EmployeeListBtnActions: FC<TEmployeeListBtnActionsProps> = ({ selectedEmployeeIds }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" ml="auto">
      <Stack direction="row" gap={3}>
        <EmployeeExportCsvButton selectedEmployeeIds={selectedEmployeeIds} />
        <EmployeeImportCsvButton />
        <Button component={Link} size="sm" to={AppRoutes.employeeCreate}>
          新規外国人登録
        </Button>
      </Stack>
    </Stack>
  );
};
