import { useFileInfo } from "@/api/services/global/file";
import { IconClose, IconFile } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { EMPTY_STR } from "@/constants/string";
import { state, text } from "@/theme/colors";
import { textEllipsis } from "@/theme/minxin";
import { typography } from "@/theme/typography";
import { TRequiredOne } from "@/types/common";
import { showError } from "@/utils/error";
import { downloadFileByUrl, downloadFileFromPath } from "@/utils/export";
import { convertByteToMb } from "@/utils/file";
import { Box, Skeleton, Stack, StackProps, Typography, css, styled } from "@mui/material";
import { FC, useState } from "react";

type TFileItemProps = {
  onRemove?: () => void;
  errorMsg?: string;
  downloadable?: boolean;
  hideIcon?: boolean;
  enableFileSize?: boolean;
} & TRequiredOne<{
  file: File;
  filePath?: string;
}> &
  StackProps;

export const FileItem: FC<TFileItemProps> = ({ file, filePath, downloadable, ...rest }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownloadFile = async () => {
    if (!downloadable) return;
    try {
      setIsDownloading(true);
      let url = "";
      if (file) {
        url = URL.createObjectURL(file);
        await downloadFileByUrl(file?.name, url);
      }
      if (!file && filePath) {
        downloadFileFromPath(filePath);
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsDownloading(false);
    }
  };

  if (file) {
    return (
      <FileItemContent
        fileName={file.name}
        fileSize={file.size}
        downloadable={downloadable}
        isDownloading={isDownloading}
        onDownload={handleDownloadFile}
        {...rest}
      />
    );
  }
  if (filePath) {
    return <FilePathItem filePath={filePath} isDownloading={isDownloading} downloadable={downloadable} onDownload={handleDownloadFile} {...rest} />;
  }
  return null;
};

type TFilePathItemProps = { filePath: string; onDownload: () => void; isDownloading: boolean } & TFileItemProps;

const FilePathItem: FC<TFilePathItemProps> = ({ filePath, onDownload, ...rest }) => {
  const { data: infoResult, isLoading } = useFileInfo(filePath);
  const infoData = infoResult?.data;

  if (!infoData && !isLoading) return null;
  return (
    <FileItemContent
      isLoading={isLoading}
      fileName={infoData?.name || EMPTY_STR.TEXT}
      fileSize={infoData?.size || 0}
      onDownload={onDownload}
      {...rest}
    />
  );
};

type TFileItemContentProps = {
  onRemove?: () => void;
  onDownload: () => void;
  errorMsg?: string;
  downloadable?: boolean;
  fileName: string;
  fileSize: number;
  isLoading?: boolean;
  isDownloading?: boolean;
  hideIcon?: boolean;
  enableFileSize?: boolean;
} & StackProps;

const FileItemContent: FC<TFileItemContentProps> = ({
  onRemove,
  onDownload,
  errorMsg,
  downloadable = false,
  isLoading = false,
  isDownloading = false,
  fileName,
  fileSize,
  hideIcon = false,
  enableFileSize = false,
  ...rest
}) => {
  return (
    <Stack {...rest} gap={0.5}>
      <Stack direction="row" alignItems="center">
        <FileItemWrap error={!!errorMsg} downloadable={downloadable} isDownloading={isDownloading} onClick={onDownload}>
          {!hideIcon && <IconFile fontSize="32px" />}
          <FileName>{isLoading ? <Skeleton variant="text" height={32} /> : fileName}</FileName>
        </FileItemWrap>
        {!enableFileSize || isLoading ? null : (
          <Typography variant="body14" mr={0.5} whiteSpace="nowrap">
            {convertByteToMb(fileSize).toFixed(2)}MB
          </Typography>
        )}
        {onRemove && (
          <IconBox onClick={onRemove}>
            <IconClose />
          </IconBox>
        )}
      </Stack>
      {errorMsg && (
        <Typography variant="body14" color={text.error} whiteSpace="pre-line">
          {errorMsg}
        </Typography>
      )}
    </Stack>
  );
};

const options = { shouldForwardProp: (propName: string) => !["error", "downloadable", "isDownloading"].includes(propName) };

const FileItemWrap = styled(Box, options)<{ error: boolean; downloadable: boolean; isDownloading: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
  margin-right: 4px;
  ${({ error }) => error && FileItemError}
  ${({ downloadable }) => downloadable && FileItemDownload}
  ${({ isDownloading }) => isDownloading && FileItemDownloading}
`;

const FileItemDownloading = css`
  pointer-events: none;
  opacity: 0.8;
`;

const FileItemDownload = css`
  cursor: pointer;
  color: ${state.link_1};
  :hover {
    color: ${state.link_2};
  }
`;

const FileItemError = css`
  &,
  button {
    color: ${text.error};
  }
`;

const FileName = styled(Typography)`
  ${textEllipsis};
  ${typography.body14};
  flex: 1;
`;
