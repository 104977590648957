import { fetchRenewVisaDocumentListToExportCsvApi } from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { useConvertRenewVisaToCsvData } from "@/features/renew-visa/hook/useConvertRenewVisaToCsvData";
import { dayjs } from "@/utils/dayjs";
import { downloadJSONByCSV } from "@/utils/export";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { FC } from "react";

type TRenewVisaExportCsvButtonProps = {
  selectedRenewVisaDocumentIds: string[];
};

export const RenewVisaExportCsvButton: FC<TRenewVisaExportCsvButtonProps> = ({ selectedRenewVisaDocumentIds }) => {
  const { convertRenewVisaToCsvData } = useConvertRenewVisaToCsvData();

  const handleExportCsv = async () => {
    if (selectedRenewVisaDocumentIds.length > 300) {
      toast.error("300人以下を選択してください。");
      return;
    }
    try {
      const rs = await fetchRenewVisaDocumentListToExportCsvApi({ ids: selectedRenewVisaDocumentIds.map((id) => toNumber(id)) });
      const renewVisaList = rs.data
        ? rs.data.map((item) => ({ employeeApplication: item.employeeApplication, companyApplication: item.companyApplication }))
        : [];
      if (renewVisaList.length === 0) return;
      const csvData = convertRenewVisaToCsvData(renewVisaList);
      downloadJSONByCSV(csvData, `renew-visa-${dayjs().valueOf()}.csv`, "utf-16le", {
        prependHeader: true,
        delimiter: { field: "\t", eol: "\r\n" },
        expandArrayObjects: true,
        escapeHeaderNestedDots: false,
      });
    } catch (error) {}
  };

  return (
    <Button size="sm" onClick={() => handleExportCsv()} disabled={selectedRenewVisaDocumentIds.length === 0}>
      まとめてダウンロード
    </Button>
  );
};
