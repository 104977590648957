import { InnerBox } from "@/components/elements/InnerBox";
import { background, primary } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, Stack, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

export type StepCardProps = {
  title: string;
  subTitle: string;
  buttons?: ReactNode;
  rightText?: string;
};

export const StepCard: FCC<StepCardProps> = ({ title, subTitle, buttons, rightText, children }) => {
  return (
    <InnerBox padding="0">
      <Stack>
        <CardHeader>
          <Stack flexDirection="row" alignItems="center" gap={3}>
            <Typography color={primary[300]} variant="sub16Bold">
              {title}
            </Typography>
            <Typography variant="sub16Bold">{subTitle}</Typography>
          </Stack>
          <Stack>
            <Stack>{buttons}</Stack>
            <Stack>{rightText}</Stack>
          </Stack>
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Stack>
    </InnerBox>
  );
};

const CardHeader = styled(Box)`
  background-color: ${background.secondary};
  padding: ${spacing.sm} ${spacing.md};
  border-top-left-radius: ${rounded.sm};
  border-top-right-radius: ${rounded.sm};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardBody = styled(Box)`
  padding: ${spacing.md};
`;
