import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { DebounceInput } from "@/components/elements/Input/Input";
import { Select } from "@/components/elements/Select";
import { DATE_FORMAT } from "@/constants/datetime";
import { PATTERN } from "@/constants/pattern";
import { EMPTY_STR } from "@/constants/string";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { TPayrollTableRow } from "@/features/regular-document/types/payrollTableRow";
import { divider } from "@/theme/colors";
import { dayjs } from "@/utils/dayjs";
import { convertDecimalToNumber, convertNumberToDecimal } from "@/utils/input";
import { toNumber, zeroPad } from "@/utils/number";
import { findLabelByValue } from "@/utils/object";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

export const useMakePaycheckColumn = (onClickEdit: (employeeId: number) => void) => {
  const { updatePayroll, updateMultiPayroll, readonly, isEditable, isInvalidData, resourceData } = usePayCheck();
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TPayrollTableRow>[]>(
    () =>
      (
        [
          {
            header: PLAIN_TEXT.numberOrder,
            accessorKey: "no",
            size: 120,
          },
          {
            header: PLAIN_TEXT.employeeFullName,
            accessorKey: "employeeFullName",
            size: 120,
            grow: true,
          },
          {
            header: PLAIN_TEXT.employeeNationality,
            size: 120,
            Cell: ({ row }) => {
              const { employeeNationalityId } = row.original;
              return (
                <Typography variant="body14">{findLabelByValue(nationalityOptions, employeeNationalityId?.toString()) || EMPTY_STR.TEXT}</Typography>
              );
            },
          },
          {
            header: PLAIN_TEXT.employeeBirthday,
            Cell: ({ row }) => {
              const { employeeBirthday } = row.original;
              return <Typography variant="body14">{employeeBirthday || EMPTY_STR.DATE}</Typography>;
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.employeeGender,
            Cell: ({ row }) => {
              const { employeeGender } = row.original;
              return <Typography variant="body14">{findLabelByValue(genderOptions, employeeGender)}</Typography>;
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.employeeResidenceCode,
            size: 120,
            Cell: ({ row }) => {
              const { employeeResidenceCode } = row.original;
              return <Typography variant="body14">{employeeResidenceCode || EMPTY_STR.TEXT}</Typography>;
            },
          },
          {
            header: PLAIN_TEXT.employeeZipCode,
            Cell: ({ row }) => {
              const { employeeZipCode } = row.original;
              return <Typography variant="body14">〒{employeeZipCode || EMPTY_STR.TEXT}</Typography>;
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.employeeAddress,
            size: 120,
            Cell: ({ row }) => {
              const { employeeAddress } = row.original;
              return <Typography variant="body14">{employeeAddress || EMPTY_STR.TEXT}</Typography>;
            },
          },
          {
            header: PLAIN_TEXT.isChangeWorkingLocation,
            Cell: ({ row }) => {
              const { isChangeWorkingLocation, employeeId, status } = row.original;
              const isDisabled = status === PAYROLL_STATUS.ERROR;
              const options = [
                { value: PLAIN_TEXT.noChange, label: PLAIN_TEXT.noChange },
                { value: PLAIN_TEXT.change, label: PLAIN_TEXT.change },
              ];
              return (
                <Select
                  disabled={isDisabled}
                  value={isChangeWorkingLocation}
                  options={options}
                  onChange={(value) => {
                    employeeId && updateMultiPayroll(employeeId, { isChangeWorkingLocation: value as string });
                  }}
                  readonly={readonly || !isEditable}
                />
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.isChangeWorkingDetail,
            Cell: ({ row }) => {
              const { isChangeWorkingDetail, employeeId, status } = row.original;
              const isDisabled = status === PAYROLL_STATUS.ERROR;
              const options = [
                { value: PLAIN_TEXT.noChange, label: PLAIN_TEXT.noChange },
                { value: PLAIN_TEXT.change, label: PLAIN_TEXT.change },
              ];
              return (
                <Select
                  disabled={isDisabled}
                  value={isChangeWorkingDetail}
                  options={options}
                  onChange={(value) => employeeId && updateMultiPayroll(employeeId, { isChangeWorkingDetail: value as string })}
                  readonly={readonly || !isEditable}
                />
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.isChangeDispatchPlace,
            Cell: ({ row }) => {
              const { isChangeDispatchPlace, employeeId, status } = row.original;
              const isDisabled = status === PAYROLL_STATUS.ERROR;
              const options = [
                { value: PLAIN_TEXT.noChange, label: PLAIN_TEXT.noChange },
                { value: PLAIN_TEXT.change, label: PLAIN_TEXT.change },
              ];
              return (
                <Select
                  disabled={isDisabled}
                  value={isChangeDispatchPlace}
                  options={options}
                  onChange={(value) => employeeId && updateMultiPayroll(employeeId, { isChangeDispatchPlace: value as string })}
                  readonly={readonly || !isEditable}
                />
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.month,
            Cell: ({ row }) => {
              const { children } = row.original;
              const data = children ? children : [row.original];
              return (
                <Stack gap={0.5}>
                  {data.map(({ month }) => (
                    <DebounceInput key={month} readonly value={`${month}月`} />
                  ))}
                </Stack>
              );
            },
            size: 10,
          },
          {
            header: PLAIN_TEXT.workingDay,
            Cell: ({ row }) => {
              const { children } = row.original;
              const data = children ? children : [row.original];
              return (
                <Stack gap={0.5}>
                  {data.map((item, index) => {
                    const { workingDayCount, month, status } = item;
                    const maxDate = dayjs(`${resourceData.year}/${zeroPad(month, 2)}/01`, DATE_FORMAT)
                      .endOf("month")
                      .date();
                    const isDisabled = status === PAYROLL_STATUS.ERROR;

                    return (
                      <DebounceInput
                        key={index}
                        disabled={isDisabled}
                        pattern={PATTERN.DECIMAL}
                        value={convertNumberToDecimal(workingDayCount)}
                        transformToHalfWidth
                        onChange={(e) => {
                          updatePayroll({ ...item, workingDayCount: convertDecimalToNumber(e.target.value) });
                        }}
                        readonly={readonly || !isEditable}
                        error={toNumber(workingDayCount) > maxDate}
                      />
                    );
                  })}
                </Stack>
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.totalAmountPaid,
            Cell: ({ row }) => {
              const { children } = row.original;
              const data = children ? children : [row.original];
              return (
                <Stack gap={0.5}>
                  {data.map((item, index) => {
                    const { totalAmountPaid, status } = item;
                    const isDisabled = status === PAYROLL_STATUS.ERROR;
                    return (
                      <DebounceInput
                        key={index}
                        disabled={isDisabled}
                        pattern={PATTERN.DECIMAL}
                        value={convertNumberToDecimal(totalAmountPaid)}
                        transformToHalfWidth
                        onChange={(e) => {
                          updatePayroll({ ...item, totalAmountPaid: convertDecimalToNumber(e.target.value) });
                        }}
                        readonly={readonly || !isEditable}
                      />
                    );
                  })}
                </Stack>
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.netAmountPaid,
            Cell: ({ row }) => {
              const { children } = row.original;
              const data = children ? children : [row.original];
              return (
                <Stack gap={0.5}>
                  {data.map((item, index) => {
                    const { netAmountPaid, status } = item;
                    const isDisabled = status === PAYROLL_STATUS.ERROR;
                    return (
                      <DebounceInput
                        key={index}
                        disabled={isDisabled}
                        pattern={PATTERN.DECIMAL}
                        value={convertNumberToDecimal(netAmountPaid)}
                        transformToHalfWidth
                        onChange={(e) => {
                          updatePayroll({ ...item, netAmountPaid: convertDecimalToNumber(e.target.value) });
                        }}
                        readonly={readonly || !isEditable}
                      />
                    );
                  })}
                </Stack>
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.legalDeductionAmount,
            Cell: ({ row }) => {
              const { children } = row.original;
              const data = children ? children : [row.original];
              return (
                <Stack gap={0.5}>
                  {data.map((item, index) => {
                    const { legalDeductionAmount, status } = item;
                    const isDisabled = status === PAYROLL_STATUS.ERROR;

                    return (
                      <DebounceInput
                        key={index}
                        disabled={isDisabled}
                        pattern={PATTERN.DECIMAL}
                        value={convertNumberToDecimal(legalDeductionAmount)}
                        transformToHalfWidth
                        onChange={(e) => {
                          updatePayroll({ ...item, legalDeductionAmount: convertDecimalToNumber(e.target.value) });
                        }}
                        readonly={readonly || !isEditable}
                      />
                    );
                  })}
                </Stack>
              );
            },
            size: 120,
          },
          {
            header: PLAIN_TEXT.hasComparisonEmployee,
            Cell: ({ row }) => {
              const { hasComparisonEmployee, employeeId } = row.original;
              const { status } = row.original;
              const isDisabled = status === PAYROLL_STATUS.ERROR;
              const options = [
                { value: PLAIN_TEXT.yes, label: PLAIN_TEXT.yes },
                { value: PLAIN_TEXT.no, label: PLAIN_TEXT.no },
              ];

              return (
                <Select
                  disabled={isDisabled}
                  options={options}
                  value={hasComparisonEmployee}
                  onChange={(value) => employeeId && updateMultiPayroll(employeeId, { hasComparisonEmployee: value as string })}
                  readonly={readonly || !isEditable}
                />
              );
            },
            size: 120,
          },
          {
            header: "編集",
            id: "action",
            muiTableHeadCellProps: { sx: { borderLeft: `1px solid ${divider.middle}` } },
            enableHiding: true,
            muiTableBodyCellProps: {
              sx: { borderLeft: "1px solid", borderColor: divider.middle },
            },
            Cell: ({ row }) => {
              const { children } = row.original;
              const data = children ? children : [row.original];
              return (
                <Button variant="primary" size="sm" onClick={() => !isInvalidData && onClickEdit(data[0].employeeId)} disabled={isInvalidData}>
                  編集
                </Button>
              );
            },
            size: 120,
          },
        ] as MRT_ColumnDef<TPayrollTableRow>[]
      ).filter((item) => {
        if ((readonly || !isEditable) && item.id === "action") return false;
        return true;
      }),
    [genderOptions, readonly, isEditable, onClickEdit, isInvalidData, nationalityOptions, resourceData],
  );

  return columns;
};
