import { InnerBox } from "@/components/elements/InnerBox";
import { ControlledPagination } from "@/components/elements/Pagination";
import { RequiredTag } from "@/components/elements/RequiredTag";
import { Table } from "@/components/elements/Table";
import { PayrollDetailModal } from "@/features/regular-document/components/accept-activity/paycheck/PayrollDetailModal";
import { PAYCHECK_CHOSEN_TYPE } from "@/features/regular-document/constants/paycheckChosenType";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { useMakePaycheckColumn } from "@/features/regular-document/hooks/useMakePaycheckColumn";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { TPayrollRecord } from "@/features/regular-document/types/payrollRecord";
import { validationPayroll } from "@/features/regular-document/utils/validationPayroll";
import { useTable } from "@/hooks/useTable";
import { Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

const PER_PAGE = 15;
export const PayrollTable = () => {
  const { paycheckRows, paycheckData, isLoading, chosenType, readonly, isEditable, resourceData } = usePayCheck();
  const [payrollRecords, setPayrollRecords] = useState<TPayrollRecord[]>();
  const [page, setPage] = useState(1);

  const handleOpenPayrollDetailModal = useCallback(
    (employeeId: number) => {
      const records = paycheckData.filter((rc) => rc.employeeId === employeeId);
      setPayrollRecords(records);
    },
    [paycheckData],
  );

  const columns = useMakePaycheckColumn(handleOpenPayrollDetailModal);

  const originalRows = useMemo(() => {
    if (isLoading) return [];
    return paycheckRows.filter(({ status }) => {
      if ((readonly || !isEditable) && [PAYROLL_STATUS.ADDED].includes(status)) return false;
      return true;
    });
  }, [isLoading, paycheckRows, readonly, isEditable, page]);

  const rows = useMemo(() => {
    return originalRows.filter((_, index) => Math.floor(index / PER_PAGE) + 1 === page);
  }, [originalRows, page]);

  const { table } = useTable({
    rows,
    columns,
    getRowId: ({ recordId }) => recordId,
    disableRow: ({ status }) => status === PAYROLL_STATUS.ERROR,
    failedRow: (row) => (readonly || !isEditable ? false : !validationPayroll(row, resourceData)),
    addedRow: ({ status }) => status === PAYROLL_STATUS.ADDED,
    initialState: {
      columnPinning: {
        right: ["action"],
      },
    },
  });

  const setNextEmployeePayroll = (currentEmployeeId: number) => {
    const index = paycheckRows.findIndex((row) => row.employeeId === currentEmployeeId);
    if (index === -1 || index === paycheckRows.length - 1) {
      setPayrollRecords(undefined);
      return;
    }
    setPayrollRecords(paycheckRows[index + 1].children ?? undefined);
  };

  if ((chosenType === PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE && !paycheckRows.length) || chosenType === PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT)
    return null;

  return (
    <InnerBox title={chosenType === PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE ? "" : "ご自身でフォームに入力する"} p={3}>
      <Stack gap={2}>
        <Stack gap={2} flexDirection="row" alignItems="center">
          <Typography variant="sub16Semi">フォームに入力する</Typography>
          {!readonly && <RequiredTag />}
        </Stack>
        <ControlledPagination totalPage={Math.ceil(originalRows.length / PER_PAGE)} page={page} onChangePage={setPage} />
        <Table table={table} isLoading={isLoading} maxHeight={350} />
        <Stack>
          <Typography variant="cap12">
            ※活動日数は該当月の1日から末日までに就労された日数を入力してください。（給与の締め日換算ではありません）
          </Typography>
          <Typography variant="cap12">※給与欄は該当月に実際に入金された金額を入力してください。</Typography>
          <Typography variant="cap12">（例：4月就労分給与 → 5月に支給 → 該当月 5月 に入力）</Typography>
          <br />
          <Typography variant="cap12">【支給総額】 控除前総支給額 【差引支給額】手取り額</Typography>
          <Typography variant="cap12">【法定控除額】税金（所得税・住民税）、社会保険料（健康保険及び年金）、雇用保険料、介護保険料</Typography>
        </Stack>
        {payrollRecords && (
          <PayrollDetailModal
            isOpen={!!payrollRecords}
            key={payrollRecords[0].employeeId}
            onClose={() => {
              setPayrollRecords(undefined);
            }}
            payrollRecords={payrollRecords}
            setNextEmployeePayroll={setNextEmployeePayroll}
          />
        )}
      </Stack>
    </InnerBox>
  );
};
