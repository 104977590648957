import { deleteRetirementFileApi } from "@/api/services/main/retirement-document";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useParams } from "react-router-dom";

type TDeleteRetirementFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  retirementFileId: number;
};

export const DeleteRetirementFileModal: FC<TDeleteRetirementFileModalProps> = ({ isOpen, onClose, retirementFileId }) => {
  const { documentGroupId, id } = useParams();
  const queryClient = useQueryClient();

  const handleDeleteFile = async () => {
    try {
      if (!id) return;
      const rs = await deleteRetirementFileApi(retirementFileId);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_FILE_LIST, id],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId],
      });
    } catch (error) {
      showError(error);
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} enableCloseIcon={false} title="削除の確認" onOk={() => handleDeleteFile()}>
      <Box mt="8px" mb="24px">
        <Typography component="p" variant="cap12">
          アップロードしたファイルが削除されますが、よろしいいですか
        </Typography>
      </Box>
    </Modal>
  );
};
