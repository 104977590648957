import { useAnytimeFileList } from "@/api/services/main/any-time";
import { TGetAnytimeFileListDto } from "@/api/services/main/any-time/dtos/get-any-time-file-list";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { Table } from "@/components/elements/Table";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { DeleteAnytimeFileModal } from "@/features/anytime/components/anytime-files/DeleteAnytimeFileModal";
import { useTable } from "@/hooks/useTable";
import { useVisible } from "@/hooks/useVisible";
import { dayjs } from "@/utils/dayjs";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo, useState } from "react";

type Props = {
  readonly?: boolean;
};

export const AnytimeFilesTable: FC<Props> = ({ readonly }) => {
  const { data: anytimeFileListResult, isLoading } = useAnytimeFileList();
  const anytimeFileListData = anytimeFileListResult?.data;

  const [deleteFileId, setDeleteFileId] = useState<number>();

  const deleteFileModal = useVisible();

  const columns = useMemo<MRT_ColumnDef<TGetAnytimeFileListDto>[]>(
    () =>
      (
        [
          {
            header: "ファイル名",
            Cell: ({ row }) => {
              return <FileItem filePath={row.original.filePath} downloadable hideIcon width={350} />;
            },
            muiTableBodyCellProps: {
              sx: { width: "49%" },
            },
          },
          {
            header: "アップロード日時",
            Cell: ({ row }) => <Typography variant="body14">{dayjs(row.original.createdAt).format(DATE_TIME_FORMAT)}</Typography>,
            muiTableBodyCellProps: {
              sx: { width: "49%" },
            },
          },
          {
            header: "削除",
            id: "action",
            Cell: ({ row }) =>
              readonly === false ? (
                <Button
                  size="sm"
                  onClick={() => {
                    deleteFileModal.open();
                    setDeleteFileId(row.original.id);
                  }}
                >
                  削除
                </Button>
              ) : (
                <></>
              ),
            size: 50,
            muiTableBodyCellProps: {
              sx: { width: "2%" },
            },
          },
        ] as MRT_ColumnDef<TGetAnytimeFileListDto>[]
      ).filter((item) => {
        if (readonly && item.id === "action") return false;
        return true;
      }),
    [readonly],
  );

  const { table } = useTable({ columns, rows: anytimeFileListData ?? [] });

  return (
    <>
      <Table table={table} isLoading={isLoading} />
      {deleteFileId && (
        <DeleteAnytimeFileModal
          anytimeFileId={deleteFileId}
          isOpen={deleteFileModal.visible}
          onClose={() => {
            deleteFileModal.close();
            setDeleteFileId(undefined);
          }}
        />
      )}
    </>
  );
};
