import { TEmployeeMenu } from "@/features/employee/types/employeeMenu";
import { AppRoutes } from "@/routes/config";

export const employeeMenu: TEmployeeMenu[] = [
  {
    title: "基本情報",
    url: AppRoutes.employeeEdit,
  },
  {
    title: "書類管理",
    url: AppRoutes.employeeDocuments,
    children: [
      { title: "書類一覧", url: AppRoutes.employeeDocuments },
      { title: "ガイダンス前の印刷書類", url: AppRoutes.employeeGuideDocuments },
    ],
  },
];
