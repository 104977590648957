import { useEnum } from "@/api/services/global/enum";
import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { YEAR_MONTH_FORMAT } from "@/constants/datetime";
import { toNumber } from "@/utils/number";
import { findLabelByValue } from "@/utils/object";
import { getEndDateOfQuarter, getStartDateOfQuarter } from "@/utils/pieces";
import { useMemo } from "react";

type TUseGetTargetQuarterProps = {
  year: string | number;
  quarter: string | number;
};

type TUseGetTargetQuarterReturn = {
  targetQuarter: string;
};

export const useGetTargetQuarter = (props?: TUseGetTargetQuarterProps): TUseGetTargetQuarterReturn => {
  const { data: presentDocumentListResult } = usePresentDocumentList({ enabled: !Boolean(props?.quarter && props.year) });
  const documentData = presentDocumentListResult?.data;

  const { data: quarterOptions } = useEnum("EQuarter");

  const targetQuarter = useMemo(() => {
    const year = props?.year || documentData?.year;
    const quarter = props?.quarter || documentData?.quarter;
    if (!year || !quarter) return "";
    const quarterStr = findLabelByValue(quarterOptions, toNumber(quarter));
    const startTimeStr = getStartDateOfQuarter({ year: toNumber(year), quarter: toNumber(quarter) }).format(YEAR_MONTH_FORMAT);
    const endTimeStr = getEndDateOfQuarter({ year: toNumber(year), quarter: toNumber(quarter) }).format(YEAR_MONTH_FORMAT);

    return `${quarterStr}　${startTimeStr}-${endTimeStr}`;
  }, [documentData, quarterOptions, props]);

  return {
    targetQuarter,
  };
};
