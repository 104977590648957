import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TButtonGroupProps = {
  saveDraft?: () => void;
  isEditable?: boolean;
  setIsEditable?: (v: boolean) => void;
};

export const ButtonGroup: FC<TButtonGroupProps> = ({ saveDraft, isEditable, setIsEditable }) => {
  const { documentGroupId } = useParams();

  return (
    <InnerBox>
      <Stack gap={2}>
        <Box paddingY={2} textAlign="center" fontSize={14} borderBottom="2px solid #D9D9D9">
          必須項目を入力してください
          <br />
          （エラー項目あればここに表示）
        </Box>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Button variant="outline" size="lg" component={Link} to={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })}>
            戻る
          </Button>
          <Stack gap={2} direction="row">
            {isEditable && (
              <>
                <Button onClick={saveDraft} size="lg" variant="outline">
                  下書きを保存する
                </Button>
                <Button type="submit" size="lg">
                  保存する
                </Button>
              </>
            )}
            {!isEditable && (
              <>
                <Button
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    setIsEditable?.(true);
                  }}
                >
                  再編集する
                </Button>
                <Button size="lg" component={Link} to={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })}>
                  届出トップに戻る
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </InnerBox>
  );
};
