import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { TEmployee } from "@/api/entities/employee.entity";
import { useGetRenewVisaResidenceCardInformationDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { ResidenceCardInfoStepCard } from "@/features/renew-visa/components/residenceCardInfoStep/ResidenceCardInfoStepCard";
import { FC } from "react";

export type TRenewVisaResidenceCardInfoStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
  employee?: TEmployee;
};

export const RenewVisaResidenceCardInfoStep: FC<TRenewVisaResidenceCardInfoStepProps> = ({ stepData, readonly, employee }) => {
  const { id: documentStepId } = stepData;
  const { data: residenceCardInfoData, isLoading } = useGetRenewVisaResidenceCardInformationDocumentInfo({ documentStepId });
  if (isLoading) return <LoadingArea />;
  return <ResidenceCardInfoStepCard employee={employee} readonly={readonly} stepData={stepData} residenceCardInfoData={residenceCardInfoData} />;
};
