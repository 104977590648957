import { useCompanyInfo } from "@/api/services/main/company";
import { useRetirementEmploymentContractDocument } from "@/api/services/main/retirement-document/employment-contract-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { RetirementEmploymentContractPage } from "@/features/retirement/pages/documents/RetirementEmploymentContract";
import { getInitValues } from "@/features/retirement/utils/retirement-employment-contract";
import { toNumber } from "@/utils/number";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

const RetirementEmploymentContract = () => {
  const { id } = useParams();
  const { data: employmentContractResult, isLoading: isLoadingEmploymentContract } = useRetirementEmploymentContractDocument(toNumber(id));
  const employmentContractData = employmentContractResult?.data;

  const { data: companyResult, isLoading } = useCompanyInfo();
  const companyData = companyResult?.data;

  const previousValues = useMemo(() => {
    return getInitValues({ companyData, employmentContractData });
  }, [companyData, employmentContractData]);

  if (isLoading || !companyResult || isLoadingEmploymentContract) return <LoadingPage />;
  return (
    <RetirementEmploymentContractPage
      defaultValues={previousValues}
      isDraft={Boolean(employmentContractData?.isDraft)}
      isCreating={employmentContractData === null}
    />
  );
};

export default RetirementEmploymentContract;
