import { jwtDecode } from "jwt-decode";
import { removeAxiosAuth, setAxiosAuth } from "@/utils/api";
import Cookies from "js-cookie";

/**
 * Sets the access and refresh tokens in cookies and updates the Axios authentication configuration.
 *
 * @param {string} accessToken - The access token to set.
 * @param {string} refreshToken - The refresh token to set.
 */
export const setToken = (accessToken: string, refreshToken: string): void => {
  Cookies.set("token", accessToken);
  Cookies.set("refreshToken", refreshToken);
  setAxiosAuth(); // Updates Axios instance with new tokens for authentication
};

/**
 * Removes the access and refresh tokens from cookies and updates the Axios authentication configuration.
 */
export const removeToken = (): void => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  removeAxiosAuth(); // Updates Axios instance to remove authentication
};

/**
 * Verifies if a given token is still valid based on its expiration time.
 *
 * @param {string} token - The token to verify.
 * @returns {boolean} - `true` if the token is still valid, `false` otherwise.
 */
export const verifyToken = (token: string): boolean => {
  try {
    const decoded = jwtDecode<{ exp: number }>(token);
    // Check if the token's expiration time is greater than the current time
    return decoded.exp > new Date().getTime() / 1000;
  } catch (e) {
    // Return false if decoding fails
    return false;
  }
};
