import { Tag } from "@/components/elements/Tag";
import { pink, state } from "@/theme/colors";
import { CONVERT_DOCUMENT_STATUS } from "@/types/enum";
import { FC, useMemo } from "react";

export type TConvertDocumentStatusTagProps = {
  status: string;
  title: string;
};

export const ConvertDocumentStatusTag: FC<TConvertDocumentStatusTagProps> = ({ status, title }) => {
  const tagProps = useMemo(() => {
    switch (status) {
      case CONVERT_DOCUMENT_STATUS.IN_PROGRESS:
        return { color: state.warning_1 };
      case CONVERT_DOCUMENT_STATUS.COMPLETE:
        return { color: state.link_1 };
      case CONVERT_DOCUMENT_STATUS.CONVERTING:
        return { color: pink[70] };
      case CONVERT_DOCUMENT_STATUS.COMPLETED_CONVERT:
        return { color: state.success_1 };
      case CONVERT_DOCUMENT_STATUS.ERROR:
        return { color: state.error_1 };
      default:
        return { color: state.success_1 };
    }
  }, [status]);

  return <Tag title={title} variant="bold" textColor="white" {...tagProps} />;
};
