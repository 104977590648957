import { useCallback } from "react";
import { jsPDF } from "jspdf";
import dayjs from "dayjs";
import { TGetRetirementAcceptingWorkerDocumentDto } from "@/api/services/main/retirement-document/retirement-accepting-worker-document/dtos/get-retirement-accepting-worker-document.dto";
import { ERetirementReasonType } from "@/features/retirement/constants/enums";
import { EMPTY_STR } from "@/constants/string";
import { YEAR_MONTH_DAY_STR_FORMAT } from "@/constants/datetime";
import { useEnum } from "@/api/services/global/enum";
import { notoSansJP } from "@/assets/fonts/notoSansJp";
import { TEmployeeRetirementDocumentStep } from "@/api/entities/employee-retirement-document-step.entity";

type TUseDownloadDocumentPdf = {
  acceptingWorkerInfo: TGetRetirementAcceptingWorkerDocumentDto | undefined;
  stepData: TEmployeeRetirementDocumentStep;
};

export const useDownloadDocumentPdf = ({ acceptingWorkerInfo, stepData }: TUseDownloadDocumentPdf) => {
  const { data: providedLegalProtectionOptions } = useEnum("EProvidedLegalProtection");
  const { updatedAt } = stepData;
  const handleDownloadPdf = useCallback(() => {
    if (!acceptingWorkerInfo) return null;
    const doc = new jsPDF();
    doc.addFileToVFS("NotoSansJP-VariableFont_wght.ttf", notoSansJP);
    doc.addFont("NotoSansJP-VariableFont_wght.ttf", "NotoSansJP", "bold");

    doc.setFont("NotoSansJP", "bold");
    doc.setFontSize(10);
    const leftMargin = 20;
    const topPadding = 28;
    doc.text("参考様式第５－１１号", leftMargin, topPadding);

    doc.setFontSize(14);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight() - 12;
    const titleText = "受入れ困難となるに至った経緯に係る説明書";
    const textWidth = doc.getTextWidth(titleText);
    const textX = (pageWidth - textWidth) / 2;

    doc.text(titleText, textX, topPadding + 10);
    doc.setFontSize(10);
    const employeeName = acceptingWorkerInfo?.employeeFullName || "";
    const explanationText = `特定技能外国人 ${employeeName} の受入れ困難に係る届出を行うに当たっての経緯は以下のとおりです。`;
    const maxWidth = pageWidth - 44;

    let currentY = 48;
    const lineHeight = 6;

    const totalWidth = doc.getTextWidth(explanationText);

    let isFirstLine = true;

    if (totalWidth <= maxWidth) {
      if (currentY + lineHeight > pageHeight) {
        doc.addPage();
        currentY = 20;
      }
      doc.text(explanationText, 22, currentY);
      currentY += lineHeight;
    } else {
      let remainingText = explanationText;

      while (remainingText.length > 0) {
        let partToRender = "";

        for (let i = 0; i < remainingText.length; i++) {
          const currentPart = remainingText.slice(0, i + 1);

          if (doc.getTextWidth(currentPart) > maxWidth) {
            partToRender = remainingText.slice(0, i);
            break;
          }

          if (i === remainingText.length - 1) {
            partToRender = remainingText;
          }
        }

        if (currentY + lineHeight > pageHeight) {
          doc.addPage();
          currentY = 20;
        }

        const xPosition = isFirstLine ? 24 : 20;
        doc.text(partToRender, xPosition, currentY);
        currentY += lineHeight;

        isFirstLine = false;
        remainingText = remainingText.slice(partToRender.length);
      }
    }

    const addTextAndBox = (number: number, title: string | string[], content: string) => {
      const padding = 5;
      const lineHeight = 4;
      const boxX = 26;
      const boxWidth = pageWidth - boxX * 2 + 6;

      const titleLines = Array.isArray(title) ? title.flatMap((line) => doc.splitTextToSize(line, boxWidth)) : doc.splitTextToSize(title, boxWidth);
      const titleHeight = titleLines.length * lineHeight;
      const textLines = doc.splitTextToSize(content, boxWidth - padding * 2);

      let minHeight = 0;
      if (title === "特定技能所属機関の都合による場合の具体的な事情") {
        minHeight = 32;
      } else if (Array.isArray(title) && title.includes("特定技能外国人の都合による場合の具体的な事情")) {
        minHeight = 48;
      }

      const contentHeight = textLines.length * lineHeight + padding * 2;
      const boxHeight = Math.max(contentHeight, minHeight);

      if (currentY + titleHeight + boxHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 26;
      }

      if (number !== 0) {
        doc.text(number.toString(), 20, currentY);
      }

      titleLines.forEach((line: string, index: number) => {
        if (currentY + lineHeight > pageHeight) {
          doc.addPage();
          currentY = 26;
        }
        doc.text(line, 26, currentY + index * lineHeight);
      });

      currentY += titleHeight;

      const boxY = currentY - 2;
      doc.rect(boxX, boxY, boxWidth, boxHeight);
      doc.text(textLines, boxX + padding, boxY + padding + lineHeight / 2);

      currentY = boxY + boxHeight + 8;
    };

    addTextAndBox(
      1,
      "特定技能所属機関の都合による場合の具体的な事情",
      acceptingWorkerInfo?.reasonType === ERetirementReasonType.CONVENIENT_FOR_COMPANY
        ? acceptingWorkerInfo?.specificCircumstanceContent?.toString() || ""
        : "",
    );
    addTextAndBox(
      2,
      [
        "特定技能外国人の都合による場合の具体的な事情",
        "事由発生までの経緯、発生後の特定技能所属機関の対応、退職（自己都合退職の場合）又は解雇（重責解雇の場合）の理由",
      ],
      acceptingWorkerInfo?.reasonType === ERetirementReasonType.CONVENIENT_FOR_EMPLOYEE
        ? acceptingWorkerInfo?.specificCircumstanceContent?.toString() || ""
        : "",
    );

    addTextAndBox(
      3,
      ["受入れ困難となるに至った後の対応等", "特定技能外国人から退職に係る相談の有無、相談があった場合はそれに対する対応"],
      acceptingWorkerInfo?.anyConsultationContent || "",
    );
    addTextAndBox(0, "退職後に特定技能外国人が転職する予定がある場合は転職先、転職予定年月日", acceptingWorkerInfo?.plansToChangeJobContent || "");
    addTextAndBox(0, "特定技能外国人に転職支援を実施する場合は支援の内容", acceptingWorkerInfo?.supportContent || "");
    addTextAndBox(0, "退職後に特定技能外国人が帰国を希望している場合はその理由", acceptingWorkerInfo?.reasonReturnHomeContent || "");
    addTextAndBox(0, "特定技能外国人に帰国支援を実施する場合は帰国予定年月日、航空券の手配状況", acceptingWorkerInfo?.planDateReturnContent || "");
    addTextAndBox(0, "退職後に特定技能外国人が転居する予定がある場合は転居先", acceptingWorkerInfo?.planToMoveContent || "");

    const additionalInfoY = currentY;
    doc.text("4", 20, additionalInfoY);
    doc.text("特定技能外国人の連絡先", 26, additionalInfoY);

    const telNumberText = `特定技能外国人に連絡を取ることが可能な電話番号 `;
    const telNumberValue = `${acceptingWorkerInfo?.telNumber?.toString() || EMPTY_STR.TEXT}`;
    const contractNameText = `連絡先の名称（特定技能外国人が電話番号を保有していない場合）`;
    const contractNameValue = `${acceptingWorkerInfo?.contractName?.toString() || EMPTY_STR.TEXT}`;

    const spaceNeeded = 40;
    if (additionalInfoY + spaceNeeded > doc.internal.pageSize.getHeight()) {
      doc.addPage();
      currentY = 20;
    } else {
      currentY = additionalInfoY;
    }

    doc.text(telNumberText, 26, currentY + 6);
    doc.text(telNumberValue, pageWidth - 60, currentY + 6);
    doc.text(contractNameText, 26, currentY + 12);
    doc.text(contractNameValue, pageWidth - 60, currentY + 12);

    currentY = currentY + 22;
    doc.text("5", 20, currentY);
    doc.text("復帰の予定", 26, currentY);

    const planedDateOfReturnNameText = `特定技能外国人が復帰予定ありとする場合、復帰予定年月日`;
    const planedDateOfReturnValue = `${acceptingWorkerInfo?.planedDateOfReturn ? dayjs(acceptingWorkerInfo?.planedDateOfReturn).format(YEAR_MONTH_DAY_STR_FORMAT) : "____年 __月 __日"}`;

    doc.text(planedDateOfReturnNameText, 26, currentY + 6);
    doc.text(planedDateOfReturnValue, pageWidth - 60, currentY + 6);

    currentY = currentY + 17;
    doc.text("6", 20, currentY);
    doc.text("特定技能外国人の法的保護のための案内実施の有無", 26, currentY);
    currentY = currentY + 4;
    const checkboxX = 26;
    const checkboxWidth = 4;
    const maxLabelWidth = pageWidth - checkboxX - 26;

    const providedLegalProtection = acceptingWorkerInfo?.providedLegalProtection || [];

    providedLegalProtectionOptions.forEach(({ label, value }) => {
      const labelLines = doc.splitTextToSize(label, maxLabelWidth);
      const labelHeight = labelLines.length * 5 - 6;

      if (currentY + Math.max(labelHeight, checkboxWidth) + 10 > pageHeight) {
        doc.addPage();
        currentY = 20;
      }

      const checkboxY = currentY;
      const isChecked = providedLegalProtection.includes(value);
      doc.rect(checkboxX, checkboxY, checkboxWidth, checkboxWidth);

      if (isChecked) {
        doc.setFontSize(10);
        doc.text("✓", checkboxX + 1, checkboxY + checkboxWidth - 1);
      }

      doc.text(labelLines, checkboxX + checkboxWidth + 2, checkboxY + 3);
      currentY += Math.max(labelHeight, checkboxWidth) + 6;
    });

    currentY += 12;

    if (currentY + 20 > pageHeight) {
      doc.addPage();
      currentY = 26;
    }

    doc.text("上記の記載内容は、事実と相違ありません。", 20, currentY);

    const createdDateText = `作成年月日　${updatedAt ? dayjs(updatedAt).format(YEAR_MONTH_DAY_STR_FORMAT) : EMPTY_STR.DATE_TIME}`;
    const createdDateTextWidth = doc.getTextWidth(createdDateText);
    const createdDateX = pageWidth - createdDateTextWidth - 24;

    if (currentY + 8 > pageHeight) {
      doc.addPage();
      currentY = 26;
    }

    doc.text(createdDateText, createdDateX, currentY + 8);
    currentY += 20;

    const companyNameLabel = "特定技能所属機関の氏名又は名称";
    const companyNameContent = acceptingWorkerInfo?.companyName || EMPTY_STR.TEXT;

    const companyNameContentWidth = doc.getTextWidth(companyNameContent);
    const companyNameContentX = pageWidth - companyNameContentWidth - 24;
    const companyNameLabelX = pageWidth - 130;

    if (currentY + 8 > pageHeight) {
      doc.addPage();
      currentY = 26;
    }

    doc.text(companyNameLabel, companyNameLabelX, currentY);
    doc.text(companyNameContent, companyNameContentX, currentY);
    currentY += 8;

    const supporterNameLabel = "作成責任者の氏名";
    const supporterNameContent = acceptingWorkerInfo?.supporterName || EMPTY_STR.TEXT;

    const supporterContentWidth = doc.getTextWidth(supporterNameContent);
    const supporterNameContentX = pageWidth - supporterContentWidth - 24;
    const supporterNameLabelX = pageWidth - 105;

    if (currentY + 8 > pageHeight) {
      doc.addPage();
      currentY = 26;
    }

    doc.text(supporterNameLabel, supporterNameLabelX, currentY);
    doc.text(supporterNameContent, supporterNameContentX, currentY);

    currentY += 16;
    doc.setFontSize(9);

    const cautionaryText = [
      [
        "１",
        "     １欄について、「受入れ困難に係る届出書」（参考様式第３－４号）における届出の事由を「特定技能所属機関の都合」とした場合に記載すること。",
      ],
      [
        "２",
        "     ２欄について、「受入れ困難に係る届出書」（参考様式第３－４号）における届出の事由を「特定技能外国人の都合」とした場合に記載すること。",
      ],
      [
        "３",
        "     ４欄について、特定技能外国人自身が電話契約をしていない場合でも、連絡を取ることが可能な電話番号がある場合は当該連絡先を記載すること。",
      ],
      ["４", "     ６欄について、特定技能外国人の退職に当たって、実施した案内がある場合はチェックすること。"],
    ];

    doc.text("（注意）", 20, currentY - 8);
    currentY += 4;

    const cautionaryLineHeight = lineHeight - 2;

    cautionaryText.forEach(([id, text]) => {
      const cautionaryLines = doc.splitTextToSize(text, pageWidth - 48);
      const cautionaryTextWidth = doc.getTextWidth(id) - 16;

      if (currentY + cautionaryLineHeight > pageHeight) {
        doc.addPage();
        currentY = 20;
      }
      doc.text(id, 22, currentY - 4);

      cautionaryLines.forEach((cautionLine: string) => {
        if (currentY + cautionaryLineHeight > pageHeight) {
          doc.addPage();
          currentY = 20;
        }
        doc.text(cautionLine, 24 + cautionaryTextWidth + 16, currentY - 4);
        currentY += cautionaryLineHeight;
      });
    });

    doc.save("受入れ困難となるに至った経緯に係る説明書.pdf");
  }, [acceptingWorkerInfo]);

  return handleDownloadPdf;
};
