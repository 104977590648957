import { useCompanyInfo } from "@/api/services/main/company";
import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { useAcceptanceActivityDocument } from "@/api/services/main/regular-document/acceptance-activity-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AcceptanceActivityForm } from "@/features/regular-document/components/accept-activity/AcceptanceActivityForm";
import { getPreviousValues } from "@/features/regular-document/utils/getPreviousValues";
import { DOCUMENT_KEYS, DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocument, findDocumentStep } from "@/utils/document";
import { useEffect, useMemo, useState } from "react";

export const RegularPresentAcceptActivityPage = () => {
  const { data: documentResult, isLoading: isLoadingDocumentData } = usePresentDocumentList();
  const documentData = findDocument(documentResult?.data.regularDocuments, DOCUMENT_KEYS.ACCEPTANCE_ACTIVITY);
  const stepData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT);
  const { data: acceptanceActivityResult, isLoading: isLoadingAcceptanceActivity } = useAcceptanceActivityDocument({
    documentStepId: stepData?.id,
    enabled: !!stepData?.updater,
  });
  const acceptanceActivityData = acceptanceActivityResult?.data;
  const { data: companyResult, isLoading: isLoadingCompany } = useCompanyInfo();
  const companyData = companyResult?.data;

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (stepData?.status === DOCUMENT_STATUS.IN_PROGRESS) {
      setIsEditable(true);
    }
  }, [stepData?.status]);

  const previousValues = useMemo(() => {
    return getPreviousValues({ companyData, acceptanceActivityData, documentData: documentResult?.data });
  }, [documentData, companyData, acceptanceActivityData]);

  if (isLoadingDocumentData || isLoadingAcceptanceActivity || isLoadingCompany || !stepData || !documentData) return <LoadingPage />;

  return (
    <AcceptanceActivityForm
      previousValues={previousValues}
      updater={acceptanceActivityData?.updater}
      documentStepId={stepData.id}
      isDraft={Boolean(acceptanceActivityData?.isDraft)}
      readonly={stepData?.status === DOCUMENT_STATUS.CONVERTING}
      isEditable={isEditable}
      year={documentData.year}
      quarter={documentData.quarter}
      setIsEditable={setIsEditable}
    />
  );
};
