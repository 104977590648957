import { useGetRenewVisaDocumentInfo } from "@/api/services/main/renew-visa";
import { MAXIMUM_NUMBER_VALIDATION } from "@/constants/common";
import { INVALID_NUMBER_MAX, INVALID_NUMBER_MIN } from "@/constants/invalids";
import {
  ERenewVisaConditionsApplyForStaffingTarget,
  ERenewVisaConditionsApplyForSupportPlanDelegation,
} from "@/features/renew-visa/enums/companyApplicationForm";
import { YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import { replaceStr } from "@/utils/string";
import { validator } from "@/utils/validator";
import { useMemo } from "react";

export const useGetCompanyApplicationSchema = () => {
  const { data: renewVisaDocument } = useGetRenewVisaDocumentInfo();

  const employeeData = renewVisaDocument ? renewVisaDocument.employee : undefined;

  const companyApplicationSchema = useMemo(() => {
    const schema = validator.object().shape({
      employeeId: validator.string().trim().emptyToNull().required(),
      companyName: validator.string().trim().autoTransformToFullWidth().emptyToNull().label("氏名又は名称").notRequired(),
      companyIdentificationNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .length(13)
        .label("法人番号")
        .notRequired(),
      businessInsuranceNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .length(11)
        .label("雇用保険適用事業所番号")
        .notRequired(),
      mainIndustryId: validator.string().isSelection().emptyToNull().label("主たる業種").notRequired(),
      mainIndustryOther: validator.string().emptyToNull().max(40).label("その他を選択した場合の業種名").notRequired(),
      province: validator.string().trim().emptyToNull().label("法人住所（都道府県）").notRequired(),
      municipality: validator.string().trim().emptyToNull().label("法人住所（市区町村）").notRequired(),
      address: validator.string().trim().emptyToNull().max(80).label("法人住所（都道府県以外）").notRequired(),
      telNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),
      capital: validator
        .number()
        .decimal()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .integer()
        .min(0)
        .max(MAXIMUM_NUMBER_VALIDATION)
        .label("資本金（円）")
        .notRequired(),
      annualRevenue: validator
        .number()
        .decimal()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .min(0)
        .max(9999999990000)
        .label("年間売上金額（直近年度）（万円）")
        .required(),
      employeeCount: validator.number().decimal().emptyToNull().autoTransformToHalfWidth().integer().max(99999).label("常勤職員数").required(),
      representativeName: validator.string().trim().emptyToNull().autoTransformToFullWidth().label("代表者氏名").notRequired(),
      branchName: validator.string().trim().emptyToNull().autoTransformToFullWidth().label("勤務させる事業所名").notRequired(),
      branchProvince: validator.string().trim().emptyToNull().label("所在地（都道府県）").required(),
      branchMunicipality: validator.string().trim().emptyToNull().label("勤務先所在地（市区町村）").required(),
      branchAddress: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(80).label("所在地（都道府県以外）").required(),
      branchTelNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),
      companyHasComplianceWithHealthAndWelfarePensionInsurance: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("健康保険及び厚生年金保険の適用事業所であることの有無")
        .notRequired(),
      complianceWithWorkersCompensationAndEmploymentInsurance: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("労災保険及び雇用保険の適用事業所であることの有無")
        .notRequired(),
      employmentInsuranceNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .numbersOnly()
        .min(11, replaceStr(INVALID_NUMBER_MIN, { path: "労働保険番号", min: 11 }))
        .max(18, replaceStr(INVALID_NUMBER_MAX, { path: "労働保険番号", max: 18 }))
        .label("労働保険番号")
        .notRequired(),
      whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(12) 労働，社会保険及び租税に関する法令の規定に違反したことの有無")
        .notRequired(),
      violationsOfLaborSocialInsuranceAndTaxLawsDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherInvoluntaryDismissalOfWorkersInSimilarRoles: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(13) 特定技能雇用契約の締結の日前1年以内又は締結の日以後に，外国人が従事する業務と同種の業務に従事していた労働者を非自発的に離職させたことの有無",
        )
        .notRequired(),
      involuntaryDismissalOfWorkersInSimilarRolesDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherInvoluntaryDismissalOfWorkersInSimilarRoles", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherMissingForeignWorkersDueToOrgIsFault: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(14) 特定技能所属機関に特定技能雇用契約の締結の日前1年以内又は締結の日以後に，特定技能所属機関の責めに帰すべき事由により外国人の行方不明者を発生させたことの有無",
        )
        .notRequired(),
      missingForeignWorkersDueToOrgIsFaultDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherMissingForeignWorkersDueToOrgIsFault", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(15) 特定技能所属機関・その役員・支援責任者・支援担当者が法令に違反して刑に処せられたことの有無")
        .notRequired(),
      criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(16) 特定技能所属機関・その役員・支援責任者・支援担当者が特定技能雇用契約の適正な履行に影響する精神の機能の障害を有することの有無")
        .notRequired(),
      mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(17) 特定技能所属機関・その役員・支援責任者・支援担当者が破産手続開始の決定を受けて復権を得ないことの有無")
        .notRequired(),
      unrehabilitatedBankruptcyOfOrgOrPersonnelDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherCancellationOfTrainingCertification: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(18) 特定技能所属機関・その役員・支援責任者・支援担当者が技能実習法第16条第1項の規定により実習認定を取り消されたことの有無")
        .notRequired(),
      cancellationOfTrainingCertificationDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherCancellationOfTrainingCertification", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(19) 特定技能所属機関・その役員・支援責任者・支援担当者が技能実習法第16条第1項の規定により実習認定を取り消された法人の役員であったことの有無",
        )
        .notRequired(),
      formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherUnfairActsRelatedToImmigrationLaborLaws: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(20) 特定技能所属機関・その役員・支援責任者・支援担当者が特定技能雇用契約の締結の日前5年以内又は締結の日以後に，出入国又は労働に関する法令に関し不正又は著しく不当な行為をしたことの有無",
        )
        .notRequired(),
      unfairActsRelatedToImmigrationLaborLawsDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherUnfairActsRelatedToImmigrationLaborLaws", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherAffiliationWithOrganizedCrime: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(21) 特定技能所属機関・その役員・支援責任者・支援担当者が暴力団員であること又は5年以内に暴力団員であったことの有無")
        .notRequired(),
      affiliationWithOrganizedCrimeDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherAffiliationWithOrganizedCrime", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherLegalRepresentativeIsEligibility: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(22) 特定技能所属機関・その役員・支援責任者・支援担当者の法定代理人(法人である場合はその役員)が(15)から(21)に該当することの有無（特定技能所属機関・その役員・支援責任者・支援担当者が営業に関し成年者と同一の行為能力を有しない未成年者である場合に入力）",
        )
        .notRequired(),
      legalRepresentativeIsEligibilityDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherLegalRepresentativeIsEligibility", {
          is: YES_NO_AND_NOT_APPLICABLE_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherOrganizedCrimeControlOverBusiness: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(23) 暴力団員又は5年以内に暴力団員であった者がその事業活動を支配する者であることの有無")
        .notRequired(),
      organizedCrimeControlOverBusinessDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherOrganizedCrimeControlOverBusiness", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherRetentionOfActivityDocuments: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(24) 外国人の活動内容に関する文書を作成し，活動をさせる事務所に特定技能雇用契約終了の日から１年以上備えて置くこととしていることの有無",
        )
        .notRequired(),
      whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(25) 特定技能雇用契約に係る保証金の徴収その他財産管理又は違約金等の支払契約があることを認識して特定技能雇用契約を締結していることの有無",
        )
        .notRequired(),
      awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherPenaltyClausesForBreachOfEmploymentContract: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(26) 特定技能雇用契約の不履行について違約金等の支払契約を締結していることの有無")
        .notRequired(),
      penaltyClausesForBreachOfEmploymentContractDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherPenaltyClausesForBreachOfEmploymentContract", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(27) １号特定技能外国人支援に要する費用について，直接又は間接に外国人に負担させないこととしていることの有無")
        .notRequired(),
      whetherConditionsApplyForStaffingTargets: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(28) 次のいずれかに該当することの有無")
        .notRequired(),
      conditionsApplyForStaffingTargetsDetail: validator
        .array()
        .notRequired()
        .when("whetherConditionsApplyForStaffingTargets", {
          is: YES_NO_ANSWER.YES,
          then: (schema) =>
            schema.test("checkConditionsApplyForStaffingTargetsDetail", "", function (value) {
              const { path, createError } = this;
              if ((value?.length ?? 0) === 0) {
                return createError({ path, message: () => "「有」の場合は該当するものを選択してください。" });
              } else {
                return true;
              }
            }),
        }),
      relevantIndustryWorkAtDispatchSiteDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("該当する場合はチェックして内容を入力")
        .notRequired()
        .when("conditionsApplyForStaffingTargetsDetail", {
          is: (value: ERenewVisaConditionsApplyForStaffingTarget[]) =>
            value && value.includes(ERenewVisaConditionsApplyForStaffingTarget.RELEVANT_INDUSTRY_WORK_AT_DISPATCH_SITE),
          then: (schema) => schema.required(),
        }),
      majorityCapitalInvestmentByIndustryRelatedEntityDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("該当する場合はチェックして内容を入力")
        .notRequired()
        .when("conditionsApplyForStaffingTargetsDetail", {
          is: (value: ERenewVisaConditionsApplyForStaffingTarget[]) =>
            value && value.includes(ERenewVisaConditionsApplyForStaffingTarget.MAJORITY_CAPITAL_INVESTMENT_BY_INDUSTRY_RELATED_ENTITY),
          then: (schema) => schema.required(),
        }),
      substantialInvolvementByIndustryRelatedEntityDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("該当する場合はチェックして内容を入力")
        .notRequired()
        .when("conditionsApplyForStaffingTargetsDetail", {
          is: (value: ERenewVisaConditionsApplyForStaffingTarget[]) =>
            value && value.includes(ERenewVisaConditionsApplyForStaffingTarget.SUBSTANTIAL_INVOLVEMENT_BY_INDUSTRY_RELATED_ENTITY),
          then: (schema) => schema.required(),
        }),
      agriculturalWorkInDesignatedStrategicZoneDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("該当する場合はチェックして内容を入力")
        .notRequired()
        .when("conditionsApplyForStaffingTargetsDetail", {
          is: (value: ERenewVisaConditionsApplyForStaffingTarget[]) =>
            value && value.includes(ERenewVisaConditionsApplyForStaffingTarget.AGRICULTURAL_WORK_IN_DESIGNATED_STRATEGIC_ZONE),
          then: (schema) => schema.required(),
        }),
      whetherHostCompanyComplianceWithSections: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(29) 労働者派遣をすることとしている派遣先が(12)から(23)に該当していることの有無")
        .notRequired(),
      hostCompanyComplianceWithSectionsDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherHostCompanyComplianceWithSections", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherWorkerIsCompensationInsuranceEnrollment: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(30) 労災保険加入当の措置の有無")
        .notRequired(),
      workerIsCompensationInsuranceEnrollmentDetail: validator
        .string()
        .trim()
        .max(200)
        .emptyToNull()
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherWorkerIsCompensationInsuranceEnrollment", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherAdequateSystemForContinuedEmploymentContractFulfillment: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(31) 特定技能雇用契約を継続して履行する体制が適切に整備されていることの有無")
        .notRequired(),
      whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(32) 外国人の報酬を，当該外国人の指定する銀行その他の金融機関に対する振込み又は現実に支払われた額を確認できる方法によって支払われることとしており，かつ後者の場合には，出入国在留管理庁長官に報酬の支払を裏付ける客観的な資料を提出し，その確認を受けることとしていることの有無 ",
        )
        .notRequired(),
      whetherComplianceWithIndustrySpecificStandardsForContractFulfillment: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(33) 特定技能雇用契約の適正な履行の確保につき特定産業分野に特有の事情に鑑みて告示で定められる基準に適合していることの有無")
        .notRequired(),
      supportSupervisor: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("(34) 支援責任者").notRequired(),
      supportSupervisorRole: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("所属・役職").notRequired(),
      whetherAppointmentOfSupportSupervisorFromStaff: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("役員または職員の中から支援責任者を選任していることの有無")
        .notRequired(),
      supportPersonnel: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("(35) 支援担当者").notRequired(),
      supportPersonnelRole: validator.string().trim().emptyToNull().autoTransformToFullWidth().max(26).label("所属・役職").notRequired(),
      whetherAppointmentOfSupportPersonnelFromStaff: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("役員または職員の中から支援担当者を選任していることの有無")
        .notRequired(),
      whetherConditionsApplyForSupportPlanDelegation: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(36) 次のいずれかに該当することの有無")
        .notRequired()
        .test("checkNo", "", function (value) {
          const { path, createError } = this;
          // residenceStatusId === 1 => specificIndustry === 特定技能1号
          if (value === YES_NO_ANSWER.NO && employeeData && employeeData?.residenceStatusId?.toString() === "1") {
            return createError({ path, message: () => "在留資格が特定技能1号なので、「有り」を選択してください。" });
          } else {
            return true;
          }
        }),
      conditionsApplyForSupportPlanDelegationDetail: validator
        .array()
        .notRequired()
        .when("whetherConditionsApplyForSupportPlanDelegation", {
          is: YES_NO_ANSWER.YES,
          then: (schema) =>
            schema.test("checkConditionsApplyForSupportPlanDelegationDetail", "", function (value) {
              const { path, createError } = this;
              if ((value?.length ?? 0) === 0) {
                return createError({ path, message: () => "「有」の場合は該当するものを選択してください。" });
              } else {
                return true;
              }
            }),
        }),
      conditionsApplyForSupportPlanDelegationOtherDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("該当する場合はチェックして内容を入力")
        .notRequired()
        .when("conditionsApplyForSupportPlanDelegationDetail", {
          is: (value: ERenewVisaConditionsApplyForSupportPlanDelegation[]) =>
            value && value.includes(ERenewVisaConditionsApplyForSupportPlanDelegation.ABILITY_TO_PROPERLY_PERFORM_OTHER_SUPPORT_DUTIES),
          then: (schema) => schema.required(),
        }),
      whetherSupportSystemInUnderstandingLanguageIsAvailable: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(37) 1号特定技能外国人支援計画に基づく支援を，外国人が十分に理解することができる言語によって行うことができる体制を有していることの有無",
        )
        .notRequired(),
      whetherRetentionOfSupportDocumentation: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(38) 1号特定技能外国人支援の状況に関する文書を作成し，1号特定技能外国人支援を行う事務所に特定技能雇用契約終了の日から1年以上備えて置くこととしていることの有無",
        )
        .notRequired(),
      whetherSupportPersonnelAreNeutralInImplementation: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(39) 支援責任者及び支援担当者が，1号特定技能外国人支援計画の中立な実施を行うことができる立場の者であることの有無")
        .notRequired(),
      whetherNeglectOfSupportPlanOccurredInPastFiveYears: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(40) 特定技能雇用契約締結の日前5年以内又は契約締結の日以後に適合1号特定技能外国人支援計画に基づく1号特定技能外国人支援を怠ったこと有無",
        )
        .notRequired(),
      neglectOfSupportPlanOccurredInPastFiveYearsDetail: validator
        .string()
        .trim()
        .emptyToNull()
        .max(200)
        .label("「有」を選択した場合に、内容・該当者名を入力")
        .notRequired()
        .when("whetherNeglectOfSupportPlanOccurredInPastFiveYears", {
          is: YES_NO_ANSWER.YES,
          then: (schema) => schema.required(),
        }),
      whetherRegularMeetingSystemWithSupervisorsIsInPlace: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("(41) 支援責任者又は支援担当者が外国人及びその監督をする立場にある者と定期的な面談を実施できる体制を有していることの有無")
        .notRequired(),
      whetherComplianceWithIndustryStandardsIsEnsured: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "(42) 適合1号特定技能外国人支援計画の適正な実施の確保につき特定産業分野に特有の事情に鑑みて告示で定められる基準に適合していることの有無",
        )
        .notRequired(),
      whetherTransportationToPortOrAirportIsProvided: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("出入国時に港又は飛行場への送迎をすることとしていることの有無 ")
        .notRequired(),
      whetherSupportForSecuringAppropriateHousingIsProvided: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("適切な住居の確保に係る支援をすることとしていることの有無 ")
        .notRequired(),
      whetherSupportForOpeningBankAccountsAndContractsIsProvided: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("金融機関における預金口座等の開設及び携帯電話の利用に関する契約その他の生活に必要な契約に係る支援をすることとしていることの有無")
        .notRequired(),
      whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "在留期間更新後に、本邦での生活一般に関する事項，国又は地方公共団体の機関への届出その他の手続，相談又は苦情の申出に関する連絡先、十分に理解することができる言語で医療を受けることができる医療機関に関する事項，防 災・防犯に関する事項，緊急時における対応に必要な事項及び外国人の法的保護に必要な事項に関する情報の提供を外国人が十分に理解することができる言語により実施することとしていることの有無",
        )
        .notRequired(),
      whetherAccompanimentForProceduresIsProvidedIfNeeded: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "外国人が国又は地方公共団体の機関への届出その他の手続を履行するに当たり，必要に応じ，関係機関への同行その他の必要な措置を講ずることとしていることの有無",
        )
        .notRequired(),
      whetherOpportunitiesForJapaneseLanguageLearningAreProvided: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("日本語学習提供有無日本語を学習する機会を提供することとしていることの有無")
        .notRequired(),
      whetherTimelyResponseToConsultationsOrComplaintsIsEnsured: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "外国人が十分に理解することができる言語により，相談又は苦情の申出に対して，遅滞なく，適切に応じるとともに，必要な措置を講ずることとしていることの有無 ",
        )
        .notRequired(),
      whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("外国人と日本人の交流の促進に係る支援をすることとしていることの有無")
        .notRequired(),
      whetherJobChangeSupportIsProvidedForContractTermination: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("外国人が，その責めに帰すべき事由によらずに特定技能雇用契約を解除される場合は，転職支援をすることとしていることの有無")
        .notRequired(),
      whetherRegularMeetingsWithSupervisorsAreConducted: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "支援責任者又は支援担当者が外国人及びその監督をする立場にある者と定期的な面談(外国人と行う場合には当該外国人が十分に理解することができる言語による面談)を実施し，問題の発生を知ったときは，その旨を関係行政機関に通報することとしていることの有無",
        )
        .notRequired(),
      whetherSupportPlanIsCreatedInUnderstandableLanguages: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "１号特定技能外国人支援計画を日本語及び外国人が十分に理解することができる言語により作成し，当該外国人にその写しを交付することとしていることの有無",
        )
        .notRequired(),
      whetherSpecificIndustryRequirementsAreIncludedInSupportPlan: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "特定産業分野に特有の事情に鑑みて告示で定められる事項を１号特定技能外国人支援計画に記載していることの有無（当該基準が定められている場合に入力）",
        )
        .notRequired(),
      whetherSupportIsAppropriateForForeignersProperStay: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("支援の内容が外国人の適正な在留に資するものであって，かつ，支援を実施する者において適切に実施することができるものであることの有無 ")
        .notRequired(),
      whetherSupportPlanMeetsIndustryStandards: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label(
          "１号特定技能外国人支援計画の内容につき特定産業分野に特有の事情に鑑みて告示で定められる基準に適合していることの有無（当該基準が定められている場合に入力）",
        )
        .notRequired(),
    });

    return schema;
  }, [employeeData]);

  //draft schema
  const companyApplicationDraftSchema = validator.object().shape({
    employeeId: validator.string().trim().emptyToNull().nullable(),
    companyName: validator.string().trim().emptyToNull().nullable(),
    companyIdentificationNumber: validator.string().trim().emptyToNull().nullable(),
    businessInsuranceNumber: validator.string().trim().emptyToNull().nullable(),
    mainIndustryId: validator.string().isSelection().emptyToNull().nullable(),
    mainIndustryOther: validator.string().isSelection().emptyToNull().nullable(),
    province: validator.string().trim().isSelection().emptyToNull().nullable(),
    municipality: validator.string().trim().isSelection().emptyToNull().nullable(),
    address: validator.string().trim().emptyToNull().nullable(),
    telNumber: validator.string().trim().emptyToNull().nullable(),
    capital: validator.number().autoTransformToHalfWidth().decimal().emptyToNull().nullable(),
    annualRevenue: validator.number().autoTransformToHalfWidth().decimal().emptyToNull().nullable(),
    employeeCount: validator.number().autoTransformToHalfWidth().decimal().emptyToNull().nullable(),
    representativeName: validator.string().trim().emptyToNull().nullable(),
    branchName: validator.string().trim().isSelection().emptyToNull().nullable(),
    branchProvince: validator.string().trim().isSelection().emptyToNull().nullable(),
    branchMunicipality: validator.string().trim().isSelection().emptyToNull().nullable(),
    branchAddress: validator.string().trim().emptyToNull().nullable(),
    branchTelNumber: validator.string().trim().emptyToNull().nullable(),
    companyHasComplianceWithHealthAndWelfarePensionInsurance: validator.string().isSelection().trim().emptyToNull().nullable(),
    complianceWithWorkersCompensationAndEmploymentInsurance: validator.string().isSelection().trim().emptyToNull().nullable(),
    employmentInsuranceNumber: validator.string().trim().emptyToNull().nullable(),
    whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws: validator.string().isSelection().trim().emptyToNull().nullable(),
    violationsOfLaborSocialInsuranceAndTaxLawsDetail: validator.string().trim().emptyToNull().nullable(),
    whetherInvoluntaryDismissalOfWorkersInSimilarRoles: validator.string().isSelection().trim().emptyToNull().nullable(),
    involuntaryDismissalOfWorkersInSimilarRolesDetail: validator.string().trim().emptyToNull().nullable(),
    whetherMissingForeignWorkersDueToOrgIsFault: validator.string().isSelection().trim().emptyToNull().nullable(),
    missingForeignWorkersDueToOrgIsFaultDetail: validator.string().trim().emptyToNull().nullable(),
    whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel: validator.string().isSelection().trim().emptyToNull().nullable(),
    criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail: validator.string().trim().emptyToNull().nullable(),
    whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel: validator.string().isSelection().trim().emptyToNull().nullable(),
    mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail: validator.string().trim().emptyToNull().nullable(),
    whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel: validator.string().isSelection().trim().emptyToNull().nullable(),
    unrehabilitatedBankruptcyOfOrgOrPersonnelDetail: validator.string().trim().emptyToNull().nullable(),
    whetherCancellationOfTrainingCertification: validator.string().isSelection().trim().emptyToNull().nullable(),
    cancellationOfTrainingCertificationDetail: validator.string().trim().emptyToNull().nullable(),
    whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification: validator.string().isSelection().trim().emptyToNull().nullable(),
    formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail: validator.string().trim().emptyToNull().nullable(),
    whetherUnfairActsRelatedToImmigrationLaborLaws: validator.string().isSelection().trim().emptyToNull().nullable(),
    unfairActsRelatedToImmigrationLaborLawsDetail: validator.string().trim().emptyToNull().nullable(),
    whetherAffiliationWithOrganizedCrime: validator.string().isSelection().trim().emptyToNull().nullable(),
    affiliationWithOrganizedCrimeDetail: validator.string().trim().emptyToNull().nullable(),
    whetherLegalRepresentativeIsEligibility: validator.string().isSelection().trim().emptyToNull().nullable(),
    legalRepresentativeIsEligibilityDetail: validator.string().trim().emptyToNull().nullable(),
    whetherOrganizedCrimeControlOverBusiness: validator.string().isSelection().trim().emptyToNull().nullable(),
    organizedCrimeControlOverBusinessDetail: validator.string().trim().emptyToNull().nullable(),
    whetherRetentionOfActivityDocuments: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements: validator.string().isSelection().trim().emptyToNull().nullable(),
    awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail: validator.string().trim().emptyToNull().nullable(),
    whetherPenaltyClausesForBreachOfEmploymentContract: validator.string().isSelection().trim().emptyToNull().nullable(),
    penaltyClausesForBreachOfEmploymentContractDetail: validator.string().trim().emptyToNull().nullable(),
    whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherConditionsApplyForStaffingTargets: validator.string().isSelection().trim().emptyToNull().nullable(),
    relevantIndustryWorkAtDispatchSiteDetail: validator.string().trim().emptyToNull().nullable(),
    majorityCapitalInvestmentByIndustryRelatedEntityDetail: validator.string().trim().emptyToNull().nullable(),
    substantialInvolvementByIndustryRelatedEntityDetail: validator.string().trim().emptyToNull().nullable(),
    agriculturalWorkInDesignatedStrategicZoneDetail: validator.string().trim().emptyToNull().nullable(),
    whetherHostCompanyComplianceWithSections: validator.string().isSelection().trim().emptyToNull().nullable(),
    hostCompanyComplianceWithSectionsDetail: validator.string().trim().emptyToNull().nullable(),
    whetherWorkerIsCompensationInsuranceEnrollment: validator.string().isSelection().trim().emptyToNull().nullable(),
    workerIsCompensationInsuranceEnrollmentDetail: validator.string().trim().emptyToNull().nullable(),
    whetherAdequateSystemForContinuedEmploymentContractFulfillment: validator.string().trim().emptyToNull().nullable(),
    whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting: validator.string().trim().emptyToNull().nullable(),
    whetherComplianceWithIndustrySpecificStandardsForContractFulfillment: validator.string().trim().emptyToNull().nullable(),
    supportSupervisor: validator.string().trim().emptyToNull().nullable(),
    supportSupervisorRole: validator.string().trim().emptyToNull().nullable(),
    whetherAppointmentOfSupportSupervisorFromStaff: validator.string().trim().emptyToNull().nullable(),
    supportPersonnel: validator.string().trim().emptyToNull().nullable(),
    supportPersonnelRole: validator.string().trim().emptyToNull().nullable(),
    whetherAppointmentOfSupportPersonnelFromStaff: validator.string().trim().emptyToNull().nullable(),
    whetherConditionsApplyForSupportPlanDelegation: validator.string().isSelection().trim().emptyToNull().nullable(),
    conditionsApplyForSupportPlanDelegationOtherDetail: validator.string().trim().emptyToNull().nullable(),
    whetherSupportSystemInUnderstandingLanguageIsAvailable: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherRetentionOfSupportDocumentation: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportPersonnelAreNeutralInImplementation: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherNeglectOfSupportPlanOccurredInPastFiveYears: validator.string().isSelection().trim().emptyToNull().nullable(),
    neglectOfSupportPlanOccurredInPastFiveYearsDetail: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherRegularMeetingSystemWithSupervisorsIsInPlace: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherComplianceWithIndustryStandardsIsEnsured: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherTransportationToPortOrAirportIsProvided: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportForSecuringAppropriateHousingIsProvided: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportForOpeningBankAccountsAndContractsIsProvided: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherAccompanimentForProceduresIsProvidedIfNeeded: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherOpportunitiesForJapaneseLanguageLearningAreProvided: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherTimelyResponseToConsultationsOrComplaintsIsEnsured: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherJobChangeSupportIsProvidedForContractTermination: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherRegularMeetingsWithSupervisorsAreConducted: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportPlanIsCreatedInUnderstandableLanguages: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSpecificIndustryRequirementsAreIncludedInSupportPlan: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportIsAppropriateForForeignersProperStay: validator.string().isSelection().trim().emptyToNull().nullable(),
    whetherSupportPlanMeetsIndustryStandards: validator.string().isSelection().trim().emptyToNull().nullable(),
  });

  return { companyApplicationSchema, companyApplicationDraftSchema };
};
