import { TGetTRenewVisaUploadFileDocumentOutputDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-upload-file-document.dto";
import { TRenewVisaUploadFileForm } from "@/features/renew-visa/types/renewVisaUploadFileForm";

export const convertResToUploadApplicationFormData = (
  filterConditionArrayKeys: (keyof TRenewVisaUploadFileForm)[],
  uploadFileData: TGetTRenewVisaUploadFileDocumentOutputDto | undefined,
): TRenewVisaUploadFileForm | undefined => {
  if (!uploadFileData || filterConditionArrayKeys.length === 0) return undefined;
  return {
    annualSalaryReportCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("annualSalaryReportCopyFilePath")
        ? uploadFileData.annualSalaryReportCopyFilePath ?? ""
        : "",
    applicantIndividualResidentTaxCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantIndividualResidentTaxCertFilePath")
        ? uploadFileData.applicantIndividualResidentTaxCertFilePath ?? ""
        : "",
    applicantResidentTaxPaymentCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantResidentTaxPaymentCertFilePath")
        ? uploadFileData.applicantResidentTaxPaymentCertFilePath ?? ""
        : "",
    applicantWithholdingSlipCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantWithholdingSlipCopyFilePath")
        ? uploadFileData.applicantWithholdingSlipCopyFilePath ?? ""
        : "",
    careWorkplaceOverviewFilePath:
      uploadFileData && filterConditionArrayKeys.includes("careWorkplaceOverviewFilePath") ? uploadFileData.careWorkplaceOverviewFilePath ?? "" : "",
    certifiedCompanyProofFilePath:
      uploadFileData && filterConditionArrayKeys.includes("certifiedCompanyProofFilePath") ? uploadFileData.certifiedCompanyProofFilePath ?? "" : "",
    corporateResidentTaxPaymentCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("corporateResidentTaxPaymentCertFilePath")
        ? uploadFileData.corporateResidentTaxPaymentCertFilePath ?? ""
        : "",
    specifiedSkillAutomobileMaintenanceCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("specifiedSkillAutomobileMaintenanceCertFilePath")
        ? uploadFileData.specifiedSkillAutomobileMaintenanceCertFilePath ?? ""
        : "",
    specifiedSkillAutomobileMaintenanceIssuranceFilePath:
      uploadFileData && filterConditionArrayKeys.includes("specifiedSkillAutomobileMaintenanceIssuranceFilePath")
        ? uploadFileData.specifiedSkillAutomobileMaintenanceIssuranceFilePath ?? ""
        : "",
    councilMembershipCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("councilMembershipCertFilePath") ? uploadFileData.councilMembershipCertFilePath ?? "" : "",
    hotelBusinessLicenseFilePath:
      uploadFileData && filterConditionArrayKeys.includes("hotelBusinessLicenseFilePath") ? uploadFileData.hotelBusinessLicenseFilePath ?? "" : "",
    documentOmissionPledgeFilePath:
      uploadFileData && filterConditionArrayKeys.includes("documentOmissionPledgeFilePath")
        ? uploadFileData.documentOmissionPledgeFilePath ?? ""
        : "",

    employmentConditionsCopyFilePaths:
      uploadFileData && filterConditionArrayKeys.includes("employmentConditionsCopyFilePaths")
        ? uploadFileData.employmentConditionsCopyFilePaths ?? []
        : [],
    isExistEmploymentContractCopyFilePath: uploadFileData?.isExistEmploymentContractCopyFilePath ?? false,
    isExistEmploymentConditionsCopyFilePaths: uploadFileData?.isExistEmploymentConditionsCopyFilePaths ?? false,
    employmentContractCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("employmentContractCopyFilePath")
        ? uploadFileData.employmentContractCopyFilePath ?? ""
        : "",
    executiveResidentRecordCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("executiveResidentRecordCopyFilePath")
        ? uploadFileData.executiveResidentRecordCopyFilePath ?? ""
        : "",
    foreignWorkerCompensationStatementFilePaths:
      uploadFileData && filterConditionArrayKeys.includes("foreignWorkerCompensationStatementFilePaths")
        ? uploadFileData.foreignWorkerCompensationStatementFilePaths ?? []
        : [],
    healthInsuranceWelfarePensionCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("healthInsuranceWelfarePensionCertFilePath")
        ? uploadFileData.healthInsuranceWelfarePensionCertFilePath ?? ""
        : "",
    immigrationElectronicSystemPledgeFilePath:
      uploadFileData && filterConditionArrayKeys.includes("immigrationElectronicSystemPledgeFilePath")
        ? uploadFileData.immigrationElectronicSystemPledgeFilePath ?? ""
        : "",
    individualBusinessOwnerResidentCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("individualBusinessOwnerResidentCertFilePath")
        ? uploadFileData.individualBusinessOwnerResidentCertFilePath ?? ""
        : "",
    individualBusinessOwnerTaxOfficePaymentCertNo3FilePath:
      uploadFileData && filterConditionArrayKeys.includes("individualBusinessOwnerTaxOfficePaymentCertNo3FilePath")
        ? uploadFileData.individualBusinessOwnerTaxOfficePaymentCertNo3FilePath ?? ""
        : "",
    individualHealthInsurancePaymentCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("individualHealthInsurancePaymentCertFilePath")
        ? uploadFileData.individualHealthInsurancePaymentCertFilePath ?? ""
        : "",
    individualPensionReceiptCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("individualPensionReceiptCopyFilePath")
        ? uploadFileData.individualPensionReceiptCopyFilePath ?? ""
        : "",
    individualResidentTaxCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("individualResidentTaxCertFilePath")
        ? uploadFileData.individualResidentTaxCertFilePath ?? ""
        : "",
    innovationCompanyProofFilePath:
      uploadFileData && filterConditionArrayKeys.includes("innovationCompanyProofFilePath")
        ? uploadFileData.innovationCompanyProofFilePath ?? ""
        : "",
    laborInsuranceDelegationFilePath:
      uploadFileData && filterConditionArrayKeys.includes("laborInsuranceDelegationFilePath")
        ? uploadFileData.laborInsuranceDelegationFilePath ?? ""
        : "",
    laborInsuranceNoDelegationFilePath:
      uploadFileData && filterConditionArrayKeys.includes("laborInsuranceNoDelegationFilePath")
        ? uploadFileData.laborInsuranceNoDelegationFilePath ?? ""
        : "",
    nonHealthInsuranceWelfarePensionCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("nonHealthInsuranceWelfarePensionCertFilePath")
        ? uploadFileData.nonHealthInsuranceWelfarePensionCertFilePath ?? ""
        : "",
    quarterlyReportCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("quarterlyReportCopyFilePath") ? uploadFileData.quarterlyReportCopyFilePath ?? "" : "",
    registrationCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("registrationCertFilePath") ? uploadFileData.registrationCertFilePath ?? "" : "",
    socialInsurancePaymentStatusCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("socialInsurancePaymentStatusCertFilePath")
        ? uploadFileData.socialInsurancePaymentStatusCertFilePath ?? ""
        : "",
    specificSkillsInstitutionOfficerAffidavitFilePath:
      uploadFileData && filterConditionArrayKeys.includes("specificSkillsInstitutionOfficerAffidavitFilePath")
        ? uploadFileData.specificSkillsInstitutionOfficerAffidavitFilePath ?? ""
        : "",
    specificSkillsInstitutionOverviewDocumentFilePath:
      uploadFileData && filterConditionArrayKeys.includes("specificSkillsInstitutionOverviewDocumentFilePath")
        ? uploadFileData.specificSkillsInstitutionOverviewDocumentFilePath ?? ""
        : "",
    taxOfficePaymentCertNo3FilePath:
      uploadFileData && filterConditionArrayKeys.includes("taxOfficePaymentCertNo3FilePath")
        ? uploadFileData.taxOfficePaymentCertNo3FilePath ?? ""
        : "",
    isExistWagePaymentFilePath: uploadFileData?.isExistWagePaymentFilePath ?? false,
    wagePaymentFilePath: uploadFileData && filterConditionArrayKeys.includes("wagePaymentFilePath") ? uploadFileData.wagePaymentFilePath ?? "" : "",
    applicantHealthInsuranceCardCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantHealthInsuranceCardCopyFilePath")
        ? uploadFileData.applicantHealthInsuranceCardCopyFilePath ?? ""
        : "",
    applicantHealthInsurancePaymentCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantHealthInsurancePaymentCertFilePath")
        ? uploadFileData.applicantHealthInsurancePaymentCertFilePath ?? ""
        : "",
    applicantPensionReceiptCopy1FilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantPensionReceiptCopy1FilePath")
        ? uploadFileData.applicantPensionReceiptCopy1FilePath ?? ""
        : "",
    applicantPensionRecordInquiry2FilePath:
      uploadFileData && filterConditionArrayKeys.includes("applicantPensionRecordInquiry2FilePath")
        ? uploadFileData.applicantPensionRecordInquiry2FilePath ?? ""
        : "",
    buildingEnvHealthMgmtRegistrationCertFilePath:
      uploadFileData && filterConditionArrayKeys.includes("buildingEnvHealthMgmtRegistrationCertFilePath")
        ? uploadFileData.buildingEnvHealthMgmtRegistrationCertFilePath ?? ""
        : "",
    fishingBoatRegistrationCertCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingBoatRegistrationCertCopyFilePath")
        ? uploadFileData.fishingBoatRegistrationCertCopyFilePath ?? ""
        : "",
    fishingBoatRegistryCopyFilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingBoatRegistryCopyFilePath")
        ? uploadFileData.fishingBoatRegistryCopyFilePath ?? ""
        : "",
    fishingCooperativeMembershipDocumentFilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingCooperativeMembershipDocumentFilePath")
        ? uploadFileData.fishingCooperativeMembershipDocumentFilePath ?? ""
        : "",
    fishingCooperativeRightsDocumentFilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingCooperativeRightsDocumentFilePath")
        ? uploadFileData.fishingCooperativeRightsDocumentFilePath ?? ""
        : "",
    fishingLicenseCopy1FilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingLicenseCopy1FilePath") ? uploadFileData.fishingLicenseCopy1FilePath ?? "" : "",
    fishingLicenseCopy2FilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingLicenseCopy2FilePath") ? uploadFileData.fishingLicenseCopy2FilePath ?? "" : "",
    fishingLicenseCopy3FilePath:
      uploadFileData && filterConditionArrayKeys.includes("fishingLicenseCopy3FilePath") ? uploadFileData.fishingLicenseCopy3FilePath ?? "" : "",
    previousApplicationDocumentsFilePath:
      uploadFileData && filterConditionArrayKeys.includes("previousApplicationDocumentsFilePath")
        ? uploadFileData.previousApplicationDocumentsFilePath ?? ""
        : "",
    publicDutyPledgeFilePath:
      uploadFileData && filterConditionArrayKeys.includes("publicDutyPledgeFilePath") ? uploadFileData.publicDutyPledgeFilePath ?? "" : "",
    publicDutyComplianceFilePath:
      uploadFileData && filterConditionArrayKeys.includes("publicDutyComplianceFilePath") ? uploadFileData.publicDutyComplianceFilePath ?? "" : "",
    shipbuildingIndustryConfirmationFilePath:
      uploadFileData && filterConditionArrayKeys.includes("shipbuildingIndustryConfirmationFilePath")
        ? uploadFileData.shipbuildingIndustryConfirmationFilePath ?? ""
        : "",
    businessLicenseOrNotificationFormFilePath:
      uploadFileData && filterConditionArrayKeys.includes("businessLicenseOrNotificationFormFilePath")
        ? uploadFileData.businessLicenseOrNotificationFormFilePath ?? ""
        : "",
    specificSkillOrganizationOverviewFilePath:
      uploadFileData && filterConditionArrayKeys.includes("specificSkillOrganizationOverviewFilePath")
        ? uploadFileData.specificSkillOrganizationOverviewFilePath ?? ""
        : "",
    residenceApplicationsForEmployeeTable1FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable1FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable1FilePath
        : "",
    residenceApplicationsForEmployeeTable2And1FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable2And1FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable2And1FilePath
        : "",
    residenceApplicationsForEmployeeTable2And2FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable2And2FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable2And2FilePath
        : "",
    residenceApplicationsForEmployeeTable2And3FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable2And3FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable2And3FilePath
        : "",
    residenceApplicationsForEmployeeTable3And1FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable3And1FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable3And1FilePath
        : "",
    residenceApplicationsForEmployeeTable3And2FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable3And2FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable3And2FilePath
        : "",
    residenceApplicationsForEmployeeTable3And3FilePath:
      uploadFileData && uploadFileData.residenceApplicationsForEmployeeTable3And3FilePath
        ? uploadFileData.residenceApplicationsForEmployeeTable3And3FilePath
        : "",
  };
};
