import { Form } from "@/components/elements/Form";
import { InnerBox } from "@/components/elements/InnerBox";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { CompanySupporterForm } from "@/features/anytime/components/anytime-detail/CompanySupporterForm";
import { EligibleForeignNationals } from "@/features/anytime/components/anytime-detail/EligibleForeignNationals";
import { SpecificSkillsAffiliation } from "@/features/anytime/components/anytime-detail/SpecificSkillsAffiliation";
import { ChangesForm } from "@/features/anytime/components/anytime-detail/support-plan/forms/ChangesForm";
import { ConclusionForm } from "@/features/anytime/components/anytime-detail/support-plan/forms/ConclusionForm";
import { TerminationForm } from "@/features/anytime/components/anytime-detail/support-plan/forms/TerminationForm";
import { supportPlanSchema } from "@/features/anytime/schema/supportPlanSchema";
import { TSupportPlanForm } from "@/features/anytime/types/supportPlanForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

type TAnytimeSupportPlanDetailPageProps = {
  defaultValues: TSupportPlanForm;
};

export const AnytimeSupportPlanDetailPage: FC<TAnytimeSupportPlanDetailPageProps> = ({ defaultValues }) => {
  const { documentId } = useParams();
  const methods = useForm<TSupportPlanForm>({
    defaultValues,
    resolver: yupResolver(supportPlanSchema),
  });

  const onSubmit = () => {};

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={supportPlanSchema}>
      <TopPage title="支援計画変更に係る届出" backUrl={getDynamicRoute(AppRoutes.anytimeDocument, { id: documentId })} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <EligibleForeignNationals readonly /> },
          {
            title: "契約の変更",
            content: (
              <InnerBox title="契約の変更" required>
                <ChangesForm readonly />
              </InnerBox>
            ),
          },
          {
            title: "契約事項",
            content: (
              <InnerBox title="契約事項" required>
                <TerminationForm readonly />
              </InnerBox>
            ),
          },
          {
            title: "変更後の内容",
            content: (
              <InnerBox title="変更後の内容" required mb={4}>
                <ConclusionForm readonly />
              </InnerBox>
            ),
          },
          { title: "特定技能所属機関", content: <SpecificSkillsAffiliation readonly /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly /> },
        ]}
      />
    </Form>
  );
};
