import { useEmployeeRetirementDocumentList } from "@/api/services/main/retirement-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { EmptyRetirement } from "@/features/retirement/components/retirement-list/EmptyRetirement";
import { RetirementListTop } from "@/features/retirement/components/retirement-list/RetirementListTop";
import { RetirementTable } from "@/features/retirement/components/retirement-list/RetirementTable";
import { Stack } from "@mui/material";
import { FC } from "react";

type TRetirementListProps = {};

export const RetirementList: FC<TRetirementListProps> = () => {
  const { data, isLoading } = useEmployeeRetirementDocumentList();

  if (isLoading) return <LoadingPage />;
  return (
    <>
      {data?.data.length === 0 ? (
        <EmptyRetirement />
      ) : (
        <Stack flex={1} gap={3}>
          <RetirementListTop />
          <RetirementTable />
        </Stack>
      )}
    </>
  );
};
