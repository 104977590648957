import { createAnytimeSupportContractApi } from "@/api/services/main/any-time/support-contract";
import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { ButtonGroup } from "@/features/anytime/components/anytime-detail/ButtonGroup";
import { CompanySupporterForm } from "@/features/anytime/components/anytime-detail/CompanySupporterForm";
import { EligibleForeignNationals } from "@/features/anytime/components/anytime-detail/EligibleForeignNationals";
import { SpecificSkillsAffiliation } from "@/features/anytime/components/anytime-detail/SpecificSkillsAffiliation";
import { ReasonForm } from "@/features/anytime/components/anytime-detail/support-contract/ReasonForm";
import { ESupportOutsourcingContractModificationType } from "@/features/anytime/constants/enums";
import { supportContractSchema } from "@/features/anytime/schema/supportContractSchema";
import { TSupportContractForm } from "@/features/anytime/types/supportContractForm";
import { convertFormDataToReqDto } from "@/features/anytime/utils/support-scontract";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TAnytimeSupportContractCreatePageProps = {
  defaultValues: Partial<TSupportContractForm>;
};

export const AnytimeSupportContractCreatePage: FC<TAnytimeSupportContractCreatePageProps> = ({ defaultValues }) => {
  const methods = useForm<TSupportContractForm>({
    defaultValues,
    resolver: yupResolver(supportContractSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const navigate = useNavigate();
  const modificationType = useWatch({ control: methods.control, name: "modificationType" });

  const { getValues } = methods;

  const handleSave = async (data: TSupportContractForm, isDraft: boolean) => {
    try {
      const payload = convertFormDataToReqDto(data, isDraft);
      const rs = await createAnytimeSupportContractApi(payload);
      if (isDraft) {
        methods.unregister();
      }
      navigate(getDynamicRoute(AppRoutes.anytimeDocument, { id: rs.data.data.anyTimeDocumentId }));
      toast.success(rs.data.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TSupportContractForm) => {
    await handleSave(data, false);
  };

  const saveDraft = async () => {
    await handleSave(getValues() as TSupportContractForm, true);
  };

  const labelReasonForm = useMemo(() => {
    if (modificationType === ESupportOutsourcingContractModificationType.RENEW_CONTRACT) return "契約の締結変更";
    if (modificationType === ESupportOutsourcingContractModificationType.MODIFY_CONTRACT) return "契約の変更";
    if (modificationType === ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT) return "契約の終了";
    return null;
  }, [modificationType]);

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={supportContractSchema}>
      <TopPage title="支援委託契約に係る届出" backUrl={AppRoutes.anytime} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <EligibleForeignNationals /> },
          { title: "届出の事由", content: <ReasonForm /> },
          labelReasonForm ? { title: labelReasonForm, content: <Box /> } : [],
          { title: "特定技能所属機関", content: <SpecificSkillsAffiliation /> },
          { title: "届出担当者", content: <CompanySupporterForm /> },
        ].flat()}
        contentAffix={<ButtonGroup saveDraft={saveDraft} editable />}
      />
    </Form>
  );
};
