import { TAnyTimeDocumentStep } from "@/api/entities/any-time-document-step.entity";
import { finishSubmitDocumentStepApi } from "@/api/services/main/any-time";
import { TAnyTimeDocumentOutputDto } from "@/api/services/main/any-time/dtos/get-any-time-document";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { StepCard } from "@/components/elements/StepCard";
import { TypoLink } from "@/components/elements/TypoLink";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useDialog } from "@/hooks/useDialog";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { showError } from "@/utils/error";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type TAnytimeDocumentSubmitStepProps = {
  document: TAnyTimeDocumentOutputDto;
  stepData: TAnyTimeDocumentStep;
  readonly?: boolean;
};

export const AnytimeDocumentSubmitStep: FC<TAnytimeDocumentSubmitStepProps> = ({ document, stepData, readonly }) => {
  const confirmFinish = useDialog();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [completeOtherSteps, setCompleteOtherSteps] = useState<boolean>(false);

  useEffect(() => {
    const createDocumentStep = document.steps.find((step) => step.masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT);
    const hasCompleteConvertRequest = !!createDocumentStep?.convertRequests?.find((req) => req.status === DOCUMENT_STATUS.COMPLETE);
    setCompleteOtherSteps(hasCompleteConvertRequest);
  }, [document]);

  const handleFinishSubmitDocument = async () => {
    try {
      const agree = await confirmFinish.show();
      if (!agree) return;
      window.open("https://www.accord-immi.moj.go.jp/accord-u/profile/userLogin");
      await finishSubmitDocumentStepApi(stepData.id);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id] });
    } catch (error) {
      showError(error);
    }
  };

  if (readonly) return null;

  return (
    <StepCard
      title="ステップ3"
      subTitle="電子申請システムで提出"
      buttons={
        <Button variant="primary" size="sm" disabled={!completeOtherSteps} onClick={handleFinishSubmitDocument}>
          提出
        </Button>
      }
    >
      <Stack spacing={1}>
        <Typography variant="body14Bold">
          電子システムで提出後、irohanaで届出完了ボタンを押すとステップが完了となります。ステップ完了すると以降編集ができなくなります。
        </Typography>
        <Typography variant="body14">電子申請システムで届出や添付書類を更新してください</Typography>
        <TypoLink to="https://www.iro-hana.com/e379c64902144428ab1e73d20f261b1f?pvs=4" target="_blank" variant="link14Semi">
          申請フローの詳細はこちら
        </TypoLink>
      </Stack>
      <ConfirmSaveModal open={confirmFinish.open} onOk={confirmFinish.confirm} onClose={confirmFinish.cancel}>
        <Typography>
          提出形式に変換したファイルを用いて届出の提出に移ります。出入国在留管理庁の電子届出システムが開かれます、よろしいですか？
        </Typography>
      </ConfirmSaveModal>
    </StepCard>
  );
};
