import { useEnum } from "@/api/services/global/enum";
import { DebounceInput } from "@/components/elements/Input/Input";
import { DATE_FORMAT } from "@/constants/datetime";
import { PATTERN } from "@/constants/pattern";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { TPayrollRecordForm } from "@/features/regular-document/types/payrollForm";
import { dayjs } from "@/utils/dayjs";
import { convertDecimalToNumber, convertNumberToDecimal } from "@/utils/input";
import { toNumber, zeroPad } from "@/utils/number";
import { Stack } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

export const useMakeRelatedColumn = (onChangeRecordField: (index: number, key: keyof TPayrollRecordForm, value: string) => void) => {
  const { data: genderOptions } = useEnum("EGender");
  const { resourceData } = usePayCheck();

  const columns = useMemo<MRT_ColumnDef<Partial<TPayrollRecordForm>>[]>(
    () => [
      {
        header: PLAIN_TEXT.month,
        Cell: ({ row }) => {
          return (
            <Stack gap={0.5}>
              <DebounceInput readonly value={`${row.original.month}月`} />
            </Stack>
          );
        },
        size: 10,
      },
      {
        header: PLAIN_TEXT.workingDay,
        Cell: ({ row }) => {
          const { workingDayCount, month } = row.original;
          const maxDate = dayjs(`${resourceData.year}/${zeroPad(toNumber(month), 2)}/01`, DATE_FORMAT)
            .endOf("month")
            .date();

          return (
            <DebounceInput
              pattern={PATTERN.DECIMAL}
              value={convertNumberToDecimal(workingDayCount || "")}
              onChange={(e) => {
                onChangeRecordField(row.index, "workingDayCount", convertDecimalToNumber(e.target.value));
              }}
              error={toNumber(workingDayCount) > maxDate}
            />
          );
        },
        size: 120,
      },
      {
        header: PLAIN_TEXT.totalAmountPaid,
        Cell: ({ row }) => {
          return (
            <DebounceInput
              pattern={PATTERN.DECIMAL}
              value={convertNumberToDecimal(row.original.totalAmountPaid || "")}
              onChange={(e) => onChangeRecordField(row.index, "totalAmountPaid", convertDecimalToNumber(e.target.value))}
            />
          );
        },
        size: 120,
      },
      {
        header: PLAIN_TEXT.netAmountPaid,
        Cell: ({ row }) => {
          return (
            <DebounceInput
              pattern={PATTERN.DECIMAL}
              value={convertNumberToDecimal(row.original.netAmountPaid || "")}
              onChange={(e) => onChangeRecordField(row.index, "netAmountPaid", convertDecimalToNumber(e.target.value))}
            />
          );
        },
        size: 120,
      },
      {
        header: PLAIN_TEXT.legalDeductionAmount,
        Cell: ({ row }) => {
          return (
            <DebounceInput
              pattern={PATTERN.DECIMAL}
              value={convertNumberToDecimal(row.original.legalDeductionAmount || "")}
              onChange={(e) => onChangeRecordField(row.index, "legalDeductionAmount", convertDecimalToNumber(e.target.value))}
            />
          );
        },
        size: 120,
      },
    ],
    [genderOptions, resourceData],
  );

  return columns;
};
