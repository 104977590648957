import { useCompanyInfo } from "@/api/services/main/company";
import { useGetRenewVisaCompanyApplicationDocumentInfo, useGetRenewVisaDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { CompanyApplicationForm } from "@/features/renew-visa/components/applicationStep/formApplication/companyApplication/CompanyApplicationForm";
import { convertResToCompanyApplicationFormData } from "@/features/renew-visa/utils/convertResToCompanyApplicationFormData";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

type TRenewVisaHistoryCompanyApplicationPageProps = {};

export const RenewVisaHistoryCompanyApplicationPage: FCC<TRenewVisaHistoryCompanyApplicationPageProps> = ({}) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const { data: companyApplicationData, isLoading: isLoadingCompanyApplication } = useGetRenewVisaCompanyApplicationDocumentInfo();

  const { data: companyData, isLoading: isLoadingCompanyData } = useCompanyInfo();

  const isLoading = useMemo(
    () => isLoadingRenewVisaDocument || isLoadingCompanyApplication || isLoadingCompanyData,
    [isLoadingRenewVisaDocument, isLoadingCompanyApplication],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <Box maxWidth={COMPANY_CONTAINER}>
      <TopPage
        title={`${getFullName({ firstName: renewVisaDocument?.employee.firstName ?? "", middleName: renewVisaDocument?.employee.middleName, lastName: renewVisaDocument?.employee.lastName ?? "" })}さんの申請データの作成`}
        backUrl={getDynamicRoute(AppRoutes.renewVisaInfoHistoryEmployeeApplication, { renewVisaDocumentId, documentStepId })}
      />
      <CompanyApplicationForm
        readonly
        previousValues={convertResToCompanyApplicationFormData(renewVisaDocument?.employee, companyData?.data, companyApplicationData)}
      />
    </Box>
  );
};
