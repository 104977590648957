import { useEnum } from "@/api/services/global/enum";
import { useGetLanguageTestList, useGetMajorList, useGetRegionList, useGetSkillTestList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Select } from "@/components/elements/Select";
import { Textarea } from "@/components/elements/Textarea";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { UploadFileBtnForm } from "@/features/employee/components/employee-forms/UploadFileBtnForm";
import { TEmployeeForm } from "@/features/employee/types/employeeForm";
import { EEmployeeCareerJapaneseCertification, EEmployeeCareerSkillCertificationOne } from "@/features/renew-visa/enums/employeeApplicationForm";
import { EMPLOYEE_CAREER_LOCATION, EMPLOYEE_TRAINING_STATUS } from "@/types/enum";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const CareerForm = () => {
  const { control, setValue } = useFormContext<TEmployeeForm>();
  const { data: trainingStatusOptions } = useEnum("EEmployeeTrainingStatus");
  const [filteredTrainingStatusOptions, setFilteredTrainingStatusOptions] = useState(trainingStatusOptions);
  const { data: employeeCareerLocationOptions } = useEnum("EEmployeeCareerLocation");

  const { isLoading: isLoadingSkillTest, skillTestOptions } = useGetSkillTestList();

  const { isLoading: isLoadingLanguageTest, languageTestOptions } = useGetLanguageTestList();

  const careerTrainingStatusWatchField = useWatch({ control, name: "careerTrainingStatus" });
  const careerJapaneseExamLocationTwoWatchField = useWatch({ control, name: "careerJapaneseExamLocationTwo" });
  const careerJapaneseExamLocationOneWatchField = useWatch({ control, name: "careerJapaneseExamLocationOne" });
  const careerSkillExamLocationOneWatchField = useWatch({ control, name: "careerSkillExamLocationOne" });
  const careerMajorIdWatchField = useWatch({ control, name: "careerMajorId" });
  const careerSkillCertificationOneWatchField = useWatch({ control, name: "careerSkillCertificationOne" });
  const careerJapaneseCertificationOneWatchField = useWatch({ control, name: "careerJapaneseCertificationOne" });
  const careerJapaneseCertificationTwoWatchField = useWatch({ control, name: "careerJapaneseCertificationTwo" });

  const { isLoading: isLoadingMajor, majorOptions, getJobTypeListOptionByMajor } = useGetMajorList();
  const { nationalityOptions, isLoading: isLoadingNationality } = useGetRegionList();

  useEffect(() => {
    if (
      careerSkillCertificationOneWatchField !== EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING &&
      careerJapaneseCertificationOneWatchField !== EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
    ) {
      setFilteredTrainingStatusOptions(trainingStatusOptions);
    } else {
      const filteredOptions = trainingStatusOptions.map((option) => ({
        ...option,
        disabled: option.value !== EMPLOYEE_TRAINING_STATUS.YES,
      }));

      setFilteredTrainingStatusOptions(filteredOptions);
      setValue("careerTrainingStatus", EMPLOYEE_TRAINING_STATUS.YES);
    }
  }, [careerSkillCertificationOneWatchField, careerJapaneseCertificationOneWatchField, trainingStatusOptions]);

  return (
    <Stack gap={1}>
      <InnerBox title="職歴">
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <Typography>技術実習</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="careerTrainingStatus">
              <RadioGroup
                direction="row"
                options={filteredTrainingStatusOptions}
                onChange={(value) => {
                  if (value !== EMPLOYEE_TRAINING_STATUS.YES) {
                    setValue("careerMajorId", null);
                    setValue("careerJobTypeId", null);
                    setValue("careerCompletionProof", null);
                    setValue("careerTrainingCompletionFile", null);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerMajorId">
              <Select
                loading={isLoadingMajor}
                options={majorOptions}
                readonly={careerTrainingStatusWatchField !== EMPLOYEE_TRAINING_STATUS.YES}
                onChange={() => {
                  setValue("careerJobTypeId", null);
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerJobTypeId">
              <Select
                loading={isLoadingMajor}
                options={careerMajorIdWatchField ? getJobTypeListOptionByMajor(careerMajorIdWatchField) : []}
                readonly={careerTrainingStatusWatchField !== EMPLOYEE_TRAINING_STATUS.YES}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="careerCompletionProof">
              <Select enumName="EEmployeeCareerCompletionProof" readonly={careerTrainingStatusWatchField !== EMPLOYEE_TRAINING_STATUS.YES} />
            </FormField>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <FormField control={control} name="careerTrainingCompletionFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_TRAINING_COMPLETION_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF].flat() }}
                disabled={careerTrainingStatusWatchField !== EMPLOYEE_TRAINING_STATUS.YES}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Typography>技能水準</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerSkillCertificationOne">
              <Select
                enumName="EEmployeeCareerSkillCertificationOne"
                onChange={(value) => {
                  if (value === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING) {
                    setValue("careerSkillExamOneId", null);
                    setValue("careerSkillOtherEvaluation", null);
                    setValue("careerSkillExamOverseaOneNationalityId", null);
                    setValue("careerSkillExamLocationOne", null);
                  } else {
                    setValue("careerSkillExamLocationOne", EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerSkillExamOneId">
              <Select
                loading={isLoadingSkillTest}
                options={skillTestOptions}
                disabled={careerSkillCertificationOneWatchField === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={12}>
            <FormField control={control} name="careerSkillExamLocationOne">
              <RadioGroup
                direction="row"
                options={employeeCareerLocationOptions}
                disabled={careerSkillCertificationOneWatchField === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING}
                onChange={(value) => {
                  if (value !== EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN) {
                    setValue("careerSkillExamOverseaOneNationalityId", null);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerSkillExamOverseaOneNationalityId">
              <Select
                loading={isLoadingNationality}
                options={nationalityOptions}
                disabled={
                  careerSkillCertificationOneWatchField === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING ||
                  careerSkillExamLocationOneWatchField !== EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN
                }
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerSkillOtherEvaluation">
              <Input disabled={careerSkillCertificationOneWatchField === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="careerSkillCompletionFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_SKILL_COMPLETION_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF].flat() }}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Typography>日本語能力</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerJapaneseCertificationOne">
              <Select
                enumName="EEmployeeCareerJapaneseCertification"
                onChange={(value) => {
                  if (value === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING) {
                    setValue("careerJapaneseExamOneId", null);
                    setValue("careerJapaneseExamOverseaOneNationalityId", null);
                    setValue("careerJapaneseExamLocationOne", null);
                  } else {
                    setValue("careerJapaneseExamLocationOne", EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN);
                  }
                  if (!value) {
                    setValue("careerJapaneseExamTwoId", null);
                    setValue("careerJapaneseCertificationTwo", null);
                    setValue("careerJapaneseExamLocationTwo", EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN);
                    setValue("careerJapaneseExamOverseaTwoNationalityId", null);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerJapaneseExamOneId">
              <Select
                loading={isLoadingLanguageTest}
                options={languageTestOptions}
                disabled={careerJapaneseCertificationOneWatchField === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={12}>
            <FormField control={control} name="careerJapaneseExamLocationOne">
              <RadioGroup
                direction="row"
                options={employeeCareerLocationOptions}
                disabled={careerJapaneseCertificationOneWatchField === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING}
                onChange={(value) => {
                  if (value !== EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN) {
                    setValue("careerJapaneseExamOverseaOneNationalityId", null);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerJapaneseExamOverseaOneNationalityId">
              <Select
                loading={isLoadingNationality}
                options={nationalityOptions}
                disabled={
                  careerJapaneseCertificationOneWatchField === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING ||
                  careerJapaneseExamLocationOneWatchField !== EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN
                }
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="careerJanpaneseCompletionOneFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_JP_COMPLETION_1_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF].flat() }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="careerJapaneseCertificationTwo">
              <Select
                disabled={!careerJapaneseCertificationOneWatchField}
                enumName="EEmployeeCareerJapaneseCertification"
                onChange={(value) => {
                  if (value === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING) {
                    setValue("careerJapaneseExamTwoId", null);
                    setValue("careerJapaneseExamLocationTwo", null);
                    setValue("careerJapaneseExamOverseaTwoNationalityId", null);
                  } else {
                    setValue("careerJapaneseExamLocationTwo", EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerJapaneseExamTwoId">
              <Select
                disabled={
                  !careerJapaneseCertificationOneWatchField ||
                  careerJapaneseCertificationTwoWatchField === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
                }
                options={languageTestOptions}
                loading={isLoadingLanguageTest}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={12}>
            <FormField control={control} name="careerJapaneseExamLocationTwo">
              <RadioGroup
                disabled={
                  !careerJapaneseCertificationOneWatchField ||
                  careerJapaneseCertificationTwoWatchField === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
                }
                direction="row"
                options={employeeCareerLocationOptions}
                onChange={(value) => {
                  if (value !== EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN) {
                    setValue("careerJapaneseExamOverseaTwoNationalityId", null);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerJapaneseExamOverseaTwoNationalityId">
              <Select
                loading={isLoadingNationality}
                options={nationalityOptions}
                disabled={
                  careerJapaneseExamLocationTwoWatchField !== EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN ||
                  !careerJapaneseCertificationOneWatchField ||
                  careerJapaneseCertificationTwoWatchField === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
                }
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="careerJanpaneseCompletionTwoFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_JP_COMPLETION_2_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF].flat() }}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Typography>確認書</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerConfirmationGuidanceFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_CONFIRM_GUIDE_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.EXCEL].flat() }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerConfirmationOrientationFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_CONFIRM_ORI_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.EXCEL].flat() }}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Typography>扶養控除</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerDependentNote">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="careerDependentFamilyFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_DEPEND_FAMILY_FILE}
                multiple
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.EXCEL].flat() }}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="careerDependentRemittanceFile">
              <UploadFileBtnForm
                uploadFor={FILE_UPLOAD_FOR.EMPLOYEE_CAREER_DEPEND_REMIT_FILE}
                rules={{ acceptMimeTypes: [MIME_TYPES.IMAGE, MIME_TYPES.PDF, MIME_TYPES.EXCEL].flat() }}
                multiple
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="note">
              <Textarea />
            </FormField>
          </Grid>
        </Grid>
      </InnerBox>
    </Stack>
  );
};
