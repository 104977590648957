import { useEmployeeRetirementDocument } from "@/api/services/main/retirement-document";
import { DOCUMENT_KEYS, EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { toNumber } from "@/utils/number";
import { useMemo } from "react";

type useCheckRetirementDocumentReturn = {
  completed: boolean;
  finished: boolean;
  failed: boolean;
};

export const useCheckRetirementDocument = (): useCheckRetirementDocumentReturn => {
  const { data: documentResult } = useEmployeeRetirementDocument();
  const documentData = documentResult?.data.retirementDocuments;

  const completed = useMemo(() => {
    const acceptingWorkerCheckKeys = [
      DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT,
      DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST,
    ];

    const employmentContractCheckKeys = [
      DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT,
      DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST,
    ];

    if (!documentData || documentData.length === 0) return true;
    return documentData.every((document) => {
      if (document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_ACCEPTING_WORKERS) {
        return document.steps.every(({ masterDocumentStep, status }) => {
          if (!acceptingWorkerCheckKeys.includes(masterDocumentStep.stepKey)) return true;
          return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
        });
      }

      if (document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT) {
        return document.steps.every(({ masterDocumentStep, status }) => {
          if (!employmentContractCheckKeys.includes(masterDocumentStep.stepKey)) return true;
          return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
        });
      }
      return true;
    });
  }, [documentData]);

  const finished = useMemo(() => {
    const acceptingWorkerCheckKeys = [
      DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT,
      DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST,
    ];

    const employmentContractCheckKeys = [
      DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT,
      DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST,
    ];
    if (!documentData || documentData.length === 0) return true;
    return documentData.every((document) => {
      if (document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_ACCEPTING_WORKERS) {
        return document.steps.every(({ masterDocumentStep, status, convertRequests }) => {
          if (!acceptingWorkerCheckKeys.includes(masterDocumentStep.stepKey)) return true;
          if (masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST) {
            const latestConvertRequest = convertRequests?.sort((a, b) => toNumber(a.id) - toNumber(b.id)).at(-1);
            if (latestConvertRequest?.status !== EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE || !latestConvertRequest.isDownloaded)
              return false;
            return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
          }
          return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
        });
      }

      if (document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT) {
        return document.steps.every(({ masterDocumentStep, status, convertRequests }) => {
          if (!employmentContractCheckKeys.includes(masterDocumentStep.stepKey)) return true;
          if (masterDocumentStep.stepKey === DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST) {
            const latestConvertRequest = convertRequests?.sort((a, b) => toNumber(a.id) - toNumber(b.id)).at(-1);
            if (latestConvertRequest?.status !== EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE || !latestConvertRequest.isDownloaded)
              return false;
            return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
          }
          return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
        });
      }
      return true;
    });
  }, [documentData]);

  const failed = useMemo(() => {
    const acceptingWorkerCheckKeys = [DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT];
    const employmentContractCheckKeys = [DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT];

    if (!documentData || documentData.length === 0) return false;
    return documentData.some((document) => {
      if (document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_ACCEPTING_WORKERS) {
        return document.steps.some(({ masterDocumentStep, status }) => {
          if (acceptingWorkerCheckKeys.includes(masterDocumentStep.stepKey)) {
            return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.ERROR;
          }
          return false;
        });
      }

      if (document.masterDocument.documentKey === DOCUMENT_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT) {
        return document.steps.some(({ masterDocumentStep, status }) => {
          if (employmentContractCheckKeys.includes(masterDocumentStep.stepKey)) {
            return status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.ERROR;
          }
          return false;
        });
      }
      return false;
    });
  }, [documentData]);

  return {
    completed,
    finished,
    failed,
  };
};
