import { fetchSignedUrlApi } from "@/api/services/global/file";
import { API_UNKNOWN_ERROR_MESSAGE } from "@/constants/common";
import { TCharset } from "@/types/file";
import { getFileNameFromPath } from "@/utils/file";
import axios from "axios";
import { json2csv, Json2CsvOptions } from "json-2-csv";
import { toast } from "react-toastify";
import iconv from "iconv-lite";

/**
 * convert CSV Data To Download Url With UTF8
 *
 * @param {string} csvData - The csv data to encode to UTF8
 */
export const convertCSVDataToDownloadUrlWithUTF8 = (csvData: string) => {
  // Create a data URL with UTF-8 encoded CSV
  const downloadUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`;
  return downloadUrl;
};

/**
 * encode JSON data to shift-JIS
 *
 * @param {string} csvData - The csv data to encode to shift-JIS.
 */
export const convertCSVDataToDownloadUrlWithShiftJIS = (csvData: string) => {
  // Encode the CSV content to Shift-JIS
  const csvShiftJIS = iconv.encode(csvData, "Shift_JIS");
  // Convert Shift-JIS content to base64
  const base64Csv = btoa(new Uint8Array(csvShiftJIS).reduce((data, byte) => data + String.fromCharCode(byte), ""));

  // Create a data URL with base64 encoded CSV
  const downloadUrl = `data:text/csv;charset=Shift_JIS;base64,${base64Csv}`;
  return downloadUrl;
};

/**
 * encode JSON data to UTF16Csv
 *
 * @param {string} csvData - The csv data to encode to UTF16Csv.
 */
export const convertCSVDataToDownloadUrlWithUTF16LE = (csvData: string) => {
  // Encode the CSV content to UTF-16LE
  const csvWithBom = "\uFEFF" + csvData;
  const utf16Csv = iconv.encode(csvWithBom, "utf16le");

  const blob = new Blob([utf16Csv], { type: "text/csv;charset=utf-16le;" });
  const downloadUrl = URL.createObjectURL(blob);

  return downloadUrl;
};

/**
 * Converts JSON data to CSV and initiates a download.
 *
 * @param {object[]} data - The JSON data to convert to CSV.
 * @param {string} fileName - The name of the file to download.
 */
export const downloadJSONByCSV = (
  data: object[],
  fileName: string,
  charset: TCharset = "utf-8",
  options?: Json2CsvOptions,
  customHeader?: string, // Add custom header parameter
) => {
  let csvData = json2csv(data, options);
  if (customHeader) {
    csvData = `${customHeader}\r\n${csvData}`;
  }
  if (charset === "shift_jis") {
    const downloadUrl = convertCSVDataToDownloadUrlWithShiftJIS(csvData);
    downloadFileByUrl(fileName, downloadUrl);
  } else if (charset === "utf-8") {
    const downloadUrl = convertCSVDataToDownloadUrlWithUTF8(csvData);
    downloadFileByUrl(fileName, downloadUrl);
  } else if (charset === "utf-16le") {
    const downloadUrl = convertCSVDataToDownloadUrlWithUTF16LE(csvData + "\r\n");
    downloadFileByUrl(fileName, downloadUrl);
  }
};

/**
 * Downloads a file from a given URL.
 *
 * @param {string} fileName - The name of the file to download.
 * @param {string} downloadUrl - The URL to download the file from.
 */
export const downloadFileByUrl = async (fileName: string, downloadUrl: string) => {
  const res = await axios.get(downloadUrl, { responseType: "blob" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(res.data);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const downloadFile = async (fileName: string, file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const blob = new Blob([arrayBuffer]);
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
};

/**
 * Fetches a signed URL for a file and initiates its download.
 *
 * @param {string} filePath - The path of the file to download.
 */
export const downloadFileFromPath = async (filePath: string, aliasFileName?: string) => {
  const rs = await fetchSignedUrlApi(filePath);
  const url = rs.data.signedUrl;
  const fileName = aliasFileName ?? getFileNameFromPath(filePath);
  if (!fileName) {
    toast.error(API_UNKNOWN_ERROR_MESSAGE);
    return;
  }
  await downloadFileByUrl(fileName, url);
};

/**
 * generate file from file path
 *
 * @param {string} filePath - The path of the file to generate.
 */
export const generateFileFromFilePath = async (filePath: string) => {
  const rs = await fetchSignedUrlApi(filePath);
  const url = rs.data.signedUrl;
  const response = await fetch(url);
  const blob = await response.blob();
  const fileName = getFileNameFromPath(filePath);
  if (!fileName) {
    toast.error(API_UNKNOWN_ERROR_MESSAGE);
    return;
  }
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

/**
 * open new tab by link
 *
 * @param {string} url - link
 */
export const openInNewTab = (url: string): void => {
  window.open(url, "_blank");
};
