import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { TEmployee } from "@/api/entities/employee.entity";
import { useGetResidenceStatusList } from "@/api/services/global/master";
import { createRenewVisaResidenceCardInformationDocumentApi } from "@/api/services/main/renew-visa";
import { TGetTRenewVisaResidenceCardInformationDocumentOutputDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-residence-card-information-document.dto";
import { UploadResidenceCardFileBtn } from "@/components/commons/UploadResidenceCardFileBtn";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { StepCard } from "@/components/elements/StepCard";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { ISO_FORMAT } from "@/constants/datetime";
import { FILE_STATUS } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { renewVisaResidenceCardInfoSchema } from "@/features/renew-visa/schema/renewVisaResidenceCardInfoSchema";
import { TRenewVisaResidenceCardInfoForm } from "@/features/renew-visa/types/renewVisaResidenceCardInfoForm";
import { TExtendFile } from "@/types/file";
import { dayjs, today } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { getFileNameFromPath } from "@/utils/file";
import { toNumber } from "@/utils/number";
import { makeUuid } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

export type TResidenceCardInfoStepCardProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  residenceCardInfoData?: TGetTRenewVisaResidenceCardInformationDocumentOutputDto;
  readonly: boolean;
  employee?: TEmployee;
};

export const ResidenceCardInfoStepCard: FC<TResidenceCardInfoStepCardProps> = ({ stepData, residenceCardInfoData, readonly, employee }) => {
  const { stepOrder, stepName } = stepData.masterDocumentStep;
  const { id: documentStepId } = stepData;

  const { renewVisaDocumentId } = useParams();
  const queryClient = useQueryClient();

  const methods = useForm<TRenewVisaResidenceCardInfoForm>({
    resolver: yupResolver(renewVisaResidenceCardInfoSchema),
    defaultValues: {
      residenceExpiryDate: residenceCardInfoData ? dayjs(residenceCardInfoData.residenceExpiryDate) : today(),
      residenceCode: residenceCardInfoData ? residenceCardInfoData.residenceCode : undefined,
      residencePeriodId: residenceCardInfoData ? residenceCardInfoData.residencePeriodId.toString() : undefined,
      residenceStatusId: residenceCardInfoData ? residenceCardInfoData.residenceStatusId.toString() : employee?.residenceStatusId?.toString(),
      residenceCardBackFile: residenceCardInfoData
        ? residenceCardInfoData.residenceCardBackFilePath.map(
            (filePath) =>
              ({
                id: makeUuid(),
                status: FILE_STATUS.OK,
                name: getFileNameFromPath(filePath),
                filePath: filePath,
              }) as TExtendFile,
          )
        : [],
      residenceCardFrontFile: residenceCardInfoData
        ? residenceCardInfoData.residenceCardFrontFilePath.map(
            (filePath) =>
              ({
                id: makeUuid(),
                status: FILE_STATUS.OK,
                name: getFileNameFromPath(filePath),
                filePath: filePath,
              }) as TExtendFile,
          )
        : [],
    },
    mode: "onBlur",
  });

  const { control } = methods;

  const residenceStatusIdWatchField = useWatch({ control, name: "residenceStatusId" });
  const residenceExpiryDate = useWatch({ control, name: "residenceExpiryDate" });

  const { data: residenceStatus, isLoading: isLoadingResidenceStatus, residenceStatusOptions } = useGetResidenceStatusList();

  const residencePeriodOptions = useMemo(() => {
    const founded = residenceStatus ? residenceStatus.find((item) => toNumber(item.id) === toNumber(residenceStatusIdWatchField)) : undefined;
    if (founded) return founded.residencePeriods.map((item) => ({ label: item.residencePeriod ?? "", value: item.id, hidden: item.isHidden }));
    else return [];
  }, [residenceStatus, residenceStatusIdWatchField]);

  const onSubmitRenewVisaBasicInfo = async (data: TRenewVisaResidenceCardInfoForm) => {
    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      const rs = await createRenewVisaResidenceCardInformationDocumentApi(renewVisaDocumentId, documentStepId, {
        residenceCode: data.residenceCode,
        residencePeriodId: toNumber(data.residencePeriodId),
        residenceStatusId: toNumber(data.residenceStatusId),
        residenceExpiryDate: data.residenceExpiryDate.format(ISO_FORMAT),
        residenceCardBackFilePath: data.residenceCardBackFile.map((file) => file.filePath ?? ""),
        residenceCardFrontFilePath: data.residenceCardFrontFile.map((file) => file.filePath ?? ""),
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_RESIDENCE_CARD_INFORMATION] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmitRenewVisaBasicInfo} schema={renewVisaResidenceCardInfoSchema}>
      <StepCard
        title={`ステップ${stepOrder}`}
        subTitle={stepName}
        buttons={
          <Button variant="primary" size="sm" type="submit" disabled={readonly}>
            在留カード情報更新
          </Button>
        }
      >
        <Grid container columnSpacing="16px" rowSpacing="16px">
          <Grid item xs={4}>
            <FormField label="在留カード番号" control={control} name="residenceCode">
              <Input disabled={readonly} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField label="在留資格" control={control} name="residenceStatusId">
              <Select options={residenceStatusOptions} loading={isLoadingResidenceStatus} readonly />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField label="在留期間" control={control} name="residencePeriodId">
              <Select options={residencePeriodOptions} loading={isLoadingResidenceStatus} disabled={readonly} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="residenceExpiryDate">
              <InputDatePicker disabled={readonly} />
            </FormField>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <FormField control={control} name="residenceCardFrontFile">
              <UploadResidenceCardFileBtn
                readonly={readonly}
                employeeFirstName={employee?.firstName ?? null}
                residenceExpiryDate={dayjs(residenceExpiryDate)?.format("YYYYMMDD") ?? null}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="residenceCardBackFile">
              <UploadResidenceCardFileBtn
                readonly={readonly}
                employeeFirstName={employee?.firstName ?? null}
                residenceExpiryDate={dayjs(residenceExpiryDate)?.format("YYYYMMDD") ?? null}
              />
            </FormField>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </StepCard>
    </Form>
  );
};
