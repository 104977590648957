import { TEmployeeRetirementDocumentStep } from "@/api/entities/employee-retirement-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { createConvertRequestApi, setDownloadedConvertRequestApi } from "@/api/services/main/convert-request";
import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import { useRetirementAcceptingWorkerDocument } from "@/api/services/main/retirement-document/retirement-accepting-worker-document";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { TypoLink } from "@/components/elements/TypoLink";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { RetirementDocumentStatusTag } from "@/features/retirement/components/retirement-document/documents/RetirementDocumentStatusTag";
import { RetirementStepCard } from "@/features/retirement/components/retirement-document/documents/RetirementStepCard";
import { useDownloadDocumentPdf } from "@/features/retirement/hooks/useDownloadDocumentPdf";
import { useRetirementConvertRequest } from "@/features/retirement/hooks/useRetirementConvertRequest";
import { AppRoutes } from "@/routes/config";
import { EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { downloadFileFromPath } from "@/utils/export";
import { findLabelByValue } from "@/utils/object";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

type TStepProps = {
  document: TEmployeeRetirementDocumentOutputDto;
  creatingDocumentStepData: TEmployeeRetirementDocumentStep;
  stepData: TEmployeeRetirementDocumentStep;
  readonly?: boolean;
};

export const AcceptingWorkersCreateConvertRequestStep: FC<TStepProps> = ({ document, creatingDocumentStepData, stepData, readonly }) => {
  const { masterDocumentStep, updatedAt, updater, status } = stepData;
  const { stepOrder, stepName } = masterDocumentStep;
  const { data: employeeRetirementDocumentStepStatuses } = useEnum("EEmployeeRetirementDocumentStepStatus");
  const { data: convertErrors } = useEnum("EConvertError");
  const { documentGroupId } = useParams();
  const queryClient = useQueryClient();
  const { data: acceptingWorkerResult } = useRetirementAcceptingWorkerDocument(creatingDocumentStepData.id);
  const acceptingWorkerInfo = acceptingWorkerResult?.data;

  const { confirmRequestConvert, confirmNeedAccount, companyAccountData, latestConvert, isLatestConvertComplete, isLatestConvertInProgress } =
    useRetirementConvertRequest({
      stepData,
    });

  const handleCreateConvertRequest = async () => {
    if (!stepData?.id) return;
    try {
      if (!companyAccountData) {
        await confirmNeedAccount.show();
      }
      const agree = await confirmRequestConvert.show();
      if (!agree) return;
      await createConvertRequestApi({ documentStepId: Number(stepData.id), documentType: document.masterDocument.documentType });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });
    } catch (error) {
      showError(error);
    }
  };

  const convertFilePaths = useMemo(() => {
    return [latestConvert?.filePath ?? []].flat();
  }, [latestConvert]);

  const downloadPdf = useDownloadDocumentPdf({
    acceptingWorkerInfo,
    stepData,
  });

  const handleDownloadFile = async () => {
    try {
      if (convertFilePaths && latestConvert && isLatestConvertComplete) {
        await Promise.all([downloadPdf(), ...convertFilePaths.map((filePath) => downloadFileFromPath(filePath))]);
        if (!latestConvert.isDownloaded) {
          await setDownloadedConvertRequestApi(latestConvert.id);
          await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });
          await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });
        }
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <RetirementStepCard
      title={`ステップ${stepOrder}`}
      subTitle={stepName}
      buttons={
        <Stack flexDirection="row" alignItems="center" gap={3}>
          <Button
            disabled={
              !(
                creatingDocumentStepData?.status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE &&
                stepData.status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.INCOMPLETE
              ) || readonly
            }
            variant="outline"
            size="sm"
            onClick={handleCreateConvertRequest}
          >
            {!isLatestConvertInProgress ? "提出形式に変換" : "提出形式への変換処理中"}
          </Button>
          <Button
            disabled={stepData.status !== EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE || !isLatestConvertComplete || readonly}
            variant="primary"
            size="sm"
            onClick={handleDownloadFile}
          >
            提出形式をダウンロード
          </Button>
        </Stack>
      }
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack flexDirection="row" flex={1} alignItems="flex-start" gap={10}>
          <Stack gap={1}>
            <Typography variant="body14Bold">更新日時</Typography>
            <Typography variant="body14">{updatedAt && updater ? dayjs(updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}</Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="body14Bold">最終更新者</Typography>
            <Typography variant="body14">{updater?.fullName || EMPTY_STR.TEXT}</Typography>
          </Stack>
          {status && (
            <Stack gap={1}>
              <Typography variant="body14Bold">ステータス</Typography>
              <RetirementDocumentStatusTag
                status={status}
                title={findLabelByValue(employeeRetirementDocumentStepStatuses, status) || EMPTY_STR.TEXT}
              />
            </Stack>
          )}
          {status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.ERROR ? (
            <Stack gap={1}>
              <Typography variant="body14Bold">エラー原因</Typography>
              <Typography variant="body14">{findLabelByValue(convertErrors, latestConvert?.error) || EMPTY_STR.TEXT}</Typography>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </Stack>
      <ConfirmSaveModal open={confirmRequestConvert.open} onOk={confirmRequestConvert.confirm} onClose={confirmRequestConvert.cancel}>
        <Typography>変換要求を実行すると、レポートを変更できなくなります。続行してもよろしいですか？</Typography>
      </ConfirmSaveModal>
      <ConfirmSaveModal open={confirmNeedAccount.open} onOk={confirmNeedAccount.confirm} onClose={confirmNeedAccount.cancel} enableFooter={false}>
        <Typography>提出形式へ変換できるようにオンライン申請のアカウントを提供お願いいたします。下のリンクで提供してください。</Typography>
        <TypoLink to={AppRoutes.companyAccountEdit}>事業所情報の編集へ</TypoLink>
      </ConfirmSaveModal>
    </RetirementStepCard>
  );
};
