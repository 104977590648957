import { useFileSignedUrl } from "@/api/services/global/file";
import { IconAvatar, IconClose } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { divider } from "@/theme/colors";
import { AvatarProps, Box, Avatar as MuiAvatar, Stack, styled } from "@mui/material";
import { FC } from "react";

type TAvatarProps = {
  width: number;
  height: number;
  filePath?: string | null;
  imageSrc?: string | null;
  onRemove?: () => void;
} & Omit<AvatarProps, "src">;

export const Avatar: FC<TAvatarProps> = ({ width, height, filePath, imageSrc, sx, onRemove, ...rest }) => {
  const { data: infoResult, isLoading } = useFileSignedUrl(filePath, "300x400");

  if (!filePath && !imageSrc) {
    return (
      <AvatarArea width={width} height={height} sx={sx} {...rest}>
        <IconAvatar fontSize={100} color="#8C8C8C" width={width / 2} height={height / 2} />
      </AvatarArea>
    );
  }

  return (
    <AvatarWrapper>
      {isLoading ? (
        <LoadingArea width={width} height={height} />
      ) : (
        <MuiAvatar
          variant="square"
          sx={{
            borderRadius: "2px",
            width: width,
            height: height,
            ...sx,
          }}
          src={(imageSrc || infoResult?.data.signedUrl) ?? undefined}
          {...rest}
        />
      )}
      {!!onRemove && (
        <IconBox
          size={32}
          sx={{
            position: "absolute",
            top: "-25px",
            right: "-40px",
          }}
          onClick={onRemove}
        >
          <IconClose />
        </IconBox>
      )}
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: fill;
  }
`;

const AvatarArea = styled(Stack)`
  align-items: center;
  justify-content: center;
  border: 1px solid ${divider.middle};
  border-radius: 2px;
`;
