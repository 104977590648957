import { TUseTableProps, useTable } from "@/hooks/useTable";
import { MRT_RowData, MRT_RowSelectionState } from "material-react-table";
import { Key, useMemo, useState } from "react";

type TUseSelectionTableProps<T extends MRT_RowData> = {
  primaryKey: keyof T;
  defaultSelectionKeys?: Key[];
  singleSelection?: boolean;
} & TUseTableProps<T>;

export const useSelectionTable = <T extends MRT_RowData>({
  primaryKey,
  defaultSelectionKeys,
  singleSelection,
  ...rest
}: TUseSelectionTableProps<T>) => {
  const formatSelectionKeys = (data: Key[]) => {
    return data.reduce((cur, item) => ({ ...cur, [item.toString()]: true }), {} as MRT_RowSelectionState);
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(formatSelectionKeys(defaultSelectionKeys ?? []));

  const clearSelection = () => {
    setRowSelection({});
  };

  const removeRowSelectionById = (id: string | number) => {
    setRowSelection((cur) => {
      if (cur[id]) delete cur[id];
      return { ...cur };
    });
  };

  const rowSelectionKeys = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const { table } = useTable({
    enableRowSelection: true,
    getRowId: (row, index) => {
      // getRowId by primaryKey of the table
      if (primaryKey in row) {
        if (typeof row[primaryKey] === "string") return row[primaryKey];
        return JSON.stringify(row[primaryKey]);
      }
      return index.toString();
    },
    state: { rowSelection },
    onRowSelectionChange: (newSelection) => {
      if (singleSelection) {
        const updatedSelection = typeof newSelection === "function" ? newSelection(rowSelection) : newSelection;
        const selectedKeys = Object.keys(updatedSelection);

        const newRowSelection: MRT_RowSelectionState = {};

        if (selectedKeys.length > 0) {
          const currentSelectedKey = Object.keys(rowSelection)[0];
          const selectedKey = selectedKeys.find((key) => key !== currentSelectedKey) || selectedKeys[0];
          newRowSelection[selectedKey] = true;
        }

        setRowSelection(newRowSelection);
      } else {
        setRowSelection(newSelection);
      }
    },
    ...rest,
  });

  return { table, rowSelectionKeys, clearSelection, removeRowSelectionById, setRowSelection };
};
