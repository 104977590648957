import { IconClose } from "@/assets/icons";
import sidebarLogo from "@/assets/images/sidebar_logo.png";
import { Linkable } from "@/components/elements/Linkable";
import { menus } from "@/constants/menu";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { AppRoutes } from "@/routes/config";
import { background, button, divider, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { hexToRgba } from "@/utils/pieces";
import { createUrlWithParams } from "@/utils/url";
import { Drawer, Stack, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import zIndex from "@mui/material/styles/zIndex";
import { FC, useMemo } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

type TAppDrawerProps = {
  isOpenDrawer: boolean;
  toggleDrawer: () => void;
};

export const AppDrawer: FC<TAppDrawerProps> = ({ isOpenDrawer, toggleDrawer }) => {
  const { isLaptop } = useMediaQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const DrawerContent = useMemo(
    () => (
      <ListWrapper gap={1}>
        {menus.map((item, index) => {
          if (typeof item === "string") {
            if (item === "|") return <Divider color={divider.lower} key={index} />;
            if (item === "booking") {
              const handleContactNavigation = (type: string) => {
                const url = createUrlWithParams(AppRoutes.contact, { type: type });
                navigate(url);
              };

              return (
                <Stack key={index} marginTop={4} gap={1}>
                  <BookingButton onClick={() => handleContactNavigation("1")} fullWidth>
                    通訳の予約
                  </BookingButton>
                  <BookingButton onClick={() => handleContactNavigation("2")} fullWidth>
                    日本語学習支援の予約
                  </BookingButton>
                </Stack>
              );
            }
            return null;
          }
          const isActive = item.relatedRoutes.some((route) => Boolean(matchRoutes([{ path: route }], pathname)));
          return (
            <ListItemWrapper key={item.text} className={isActive ? "active" : ""} href={item.path}>
              <Typography variant="body14Bold">{item.text}</Typography>
            </ListItemWrapper>
          );
        })}
        <Logo>
          <img src={sidebarLogo} alt="logo" />
        </Logo>
      </ListWrapper>
    ),
    [pathname],
  );

  if (!isLaptop) return DrawerContent;
  return (
    <Drawer
      open={isOpenDrawer}
      onClose={toggleDrawer}
      hideBackdrop
      ModalProps={{ sx: { width: "fit-content" } }}
      PaperProps={{ sx: { bgcolor: background.main } }}
    >
      <IconCloseWrapper>
        <IconCloseBtn onClick={toggleDrawer} />
      </IconCloseWrapper>
      {DrawerContent}
    </Drawer>
  );
};

const ListWrapper = styled(Stack)`
  padding: ${spacing.md} ${spacing.sm};
  min-width: 242px;
  background-color: ${background.main};
  color: ${text.white};
  min-height: calc(100vh - 57px);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 57px;
  z-index: ${zIndex.drawer};
`;

const ListItemWrapper = styled(Linkable)`
  transition: all 0.2s ease-in-out;
  border-radius: ${rounded.sm};
  padding: ${spacing.xs} ${spacing.sm};
  &.active {
    background-color: ${hexToRgba(background.white, 0.3)} !important;
  }

  &:hover {
    background-color: ${hexToRgba(background.white, 0.1)};
  }
`;

const BookingButton = styled(Button)`
  background: transparent;
  border: 1px solid ${divider.lower};
  color: ${text.white};
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background-color: ${button.hover_02};
  }
`;

const IconCloseWrapper = styled(Box)`
  background-color: ${background.main};
  height: 57px;
  border-bottom: 1px solid ${divider.lower};
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

const IconCloseBtn = styled(IconClose)`
  font-size: 32px;
  color: ${text.white};
  transition: all 0.2s ease-in-out;
  border-radius: 32px;
  &:hover {
    cursor: pointer;
    background-color: ${hexToRgba(background.white, 0.3)};
  }
`;

const Logo = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
`;
