import { fetchFileInfoApi } from "@/api/services/global/file";
import { finishUploadDocumentStepApi } from "@/api/services/main/regular-document";
import { uploadPayrollFilesApi, useGetPayrollFilesApi } from "@/api/services/main/regular-document/acceptance-activity-document";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { UploadPopup } from "@/components/elements/UploadPopup";
import { FILE_STATUS, FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useDialog } from "@/hooks/useDialog";
import { useVisible } from "@/hooks/useVisible";
import { DOCUMENT_STATUS } from "@/types/enum";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";

type TPayrollFilesTopProps = {
  documentStepId: number;
  documentStepStatus: string;
  readonly?: boolean;
};

export const PayrollFilesTop: FC<TPayrollFilesTopProps> = ({ documentStepId, documentStepStatus, readonly }) => {
  const { data: payrollFilesResult } = useGetPayrollFilesApi(documentStepId);
  const payrollUploadFilePopup = useVisible();
  const confirmFinish = useDialog();
  const queryClient = useQueryClient();

  const [isCheckPayrollFileLoading, setIsCheckPayrollFileLoading] = useState<boolean>(false);

  const [isDisableUploadBtn, setIsDisableUploadBtn] = useState<boolean>(false);

  const handleCheckMaximumPayrollFiles = async (newFiles: TExtendFile[]) => {
    //Maximum upload of 19 files, Total file upload capacity of 1 user is 19MB
    if (!payrollFilesResult) return true;
    setIsCheckPayrollFileLoading(true);
    const uploadedPayrollFiles = await Promise.all(
      payrollFilesResult.data.map(async (item) => {
        const rs = await fetchFileInfoApi(item.filePath);
        return rs.data;
      }),
    );
    //Check Maximum upload of 19 files
    if (uploadedPayrollFiles.length + newFiles.length > 19) {
      toast.error("19ファイル以下を選択してください");
      setIsCheckPayrollFileLoading(false);
      return true;
    }
    //Check Total file upload capacity of 1 user is 19MB
    if (uploadedPayrollFiles.reduce((prev, curr) => curr.size + prev, 0) + newFiles.reduce((prev, curr) => curr.size + prev, 0) > 19 * 1024 * 1024) {
      toast.error("総容量は19MBまでです。別のファイルをアップロードしてください");
      setIsCheckPayrollFileLoading(false);
      return true;
    }
    setIsCheckPayrollFileLoading(false);
    setIsDisableUploadBtn(true);
    return false;
  };

  const handleFinishUploadPayrollFiles = async (_: TExtendFile[], newFiles: TExtendFile[]) => {
    const filesWithStatusOk = newFiles.filter((file) => file.status === FILE_STATUS.OK);
    if (filesWithStatusOk.length === 0) return;
    try {
      await uploadPayrollFilesApi({
        files: newFiles.flatMap((file) => (file.filePath ? { filePath: file.filePath, regularDocumentStepId: toNumber(documentStepId) } : [])),
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_FILE_LIST],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT],
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsDisableUploadBtn(false);
    }
  };

  const handleSetFinishDocumentStep = async () => {
    try {
      const agree = await confirmFinish.show();
      if (!agree) return;
      await finishUploadDocumentStepApi(documentStepId);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT],
      });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="end" alignItems="center">
        {!readonly && (
          <Stack direction="row" alignItems="end" spacing={1}>
            <Button size="md" variant="primary" disabled={documentStepStatus === DOCUMENT_STATUS.COMPLETE} onClick={handleSetFinishDocumentStep}>
              アップロード完了
            </Button>
            <Button size="md" variant="primary" disabled={isDisableUploadBtn} onClick={() => payrollUploadFilePopup.open()}>
              新規アップロード
            </Button>
          </Stack>
        )}
      </Stack>
      <UploadPopup
        isLoading={isCheckPayrollFileLoading}
        uploadFor={FILE_UPLOAD_FOR.PAYROLL_FILES}
        onFinishUpload={handleFinishUploadPayrollFiles}
        onDropFiles={handleCheckMaximumPayrollFiles}
        isOpen={payrollUploadFilePopup.visible}
        onClose={() => payrollUploadFilePopup.close()}
        options={{ multiple: true }}
        rules={{ acceptMimeTypes: [...MIME_TYPES.PDF, ...MIME_TYPES.EXCEL] }}
        title={<Typography variant="body14Bold">ファイルをアップロードしてください。</Typography>}
      />
      <ConfirmSaveModal open={confirmFinish.open} onOk={confirmFinish.confirm} onClose={confirmFinish.cancel}>
        <Typography>賃金台帳本のアップロードが完了してもよろしいでしょうか。</Typography>
      </ConfirmSaveModal>
    </>
  );
};
