import { updateAnytimeEmploymentContractApi } from "@/api/services/main/any-time/employment-contract";
import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { ButtonGroup } from "@/features/anytime/components/anytime-detail/ButtonGroup";
import { CompanySupporterForm } from "@/features/anytime/components/anytime-detail/CompanySupporterForm";
import { EligibleForeignNationals } from "@/features/anytime/components/anytime-detail/EligibleForeignNationals";
import { ReasonForm } from "@/features/anytime/components/anytime-detail/employment-contract/ReasonForm";
import { SpecificSkillsAffiliation } from "@/features/anytime/components/anytime-detail/SpecificSkillsAffiliation";
import { EEmploymentContractModificationType } from "@/features/anytime/constants/enums";
import { employmentContractSchema } from "@/features/anytime/schema/employmentContractSchema";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { convertFormDataToReqDto } from "@/features/anytime/utils/employment-contract";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TAnytimeEmploymentContractEditPageProps = {
  defaultValues: TEmploymentContractForm;
  isDraft: boolean;
};

export const AnytimeEmploymentContractEditPage: FC<TAnytimeEmploymentContractEditPageProps> = ({ defaultValues, isDraft }) => {
  const { documentId, id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const methods = useForm<TEmploymentContractForm>({
    defaultValues,
    resolver: yupResolver(employmentContractSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const { getValues } = methods;
  const [editable, setEditable] = useState(isDraft);
  const modificationType = useWatch({ control: methods.control, name: "modificationType" });

  const handleSave = async (data: TEmploymentContractForm, isDraft: boolean) => {
    try {
      if (!id) return;
      const payload = convertFormDataToReqDto(data, isDraft);
      const rs = await updateAnytimeEmploymentContractApi(id, payload);
      if (isDraft) {
        methods.unregister();
      }
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, documentId] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_EMPLOYMENT_CONTRACT, id] });
      navigate(getDynamicRoute(AppRoutes.anytimeDocument, { id: documentId }));
      toast.success(rs.data.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TEmploymentContractForm) => {
    await handleSave(data, false);
  };

  const saveDraft = async () => {
    await handleSave(getValues() as TEmploymentContractForm, true);
  };

  const labelReasonForm = useMemo(() => {
    if (modificationType === EEmploymentContractModificationType.MODIFY_CONTRACT) return "契約の変更";
    if (modificationType === EEmploymentContractModificationType.TERMINATE_CONTRACT) return "契約の終了";
    if (modificationType === EEmploymentContractModificationType.RENEW_CONTRACT) return "新たな契約の締結";
    return null;
  }, [modificationType]);

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={employmentContractSchema}>
      <TopPage title="雇用契約の変更に係る届出" backUrl={getDynamicRoute(AppRoutes.anytimeDocument, { id: documentId })} isDraft={isDraft} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <EligibleForeignNationals readonly={!editable} /> },
          { title: "届出の事由", content: <ReasonForm readonly={!editable} /> },
          labelReasonForm ? { title: labelReasonForm, content: <Box /> } : [],
          { title: "特定技能所属機関", content: <SpecificSkillsAffiliation readonly={!editable} /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly={!editable} /> },
        ].flat()}
        contentAffix={<ButtonGroup saveDraft={saveDraft} editable={editable} setEditable={setEditable} />}
      />
    </Form>
  );
};
