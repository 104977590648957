import { useCompanyInfo } from "@/api/services/main/company";
import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { StatusTag } from "@/components/elements/StatusTag";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { TaskCard } from "@/features/dashboard/components/tasks/TaskCard";
import { useCheckRegularDocument } from "@/features/regular-document/hooks/useCheckRegularDocument";
import { useGetTargetQuarter } from "@/features/regular-document/hooks/useGetTargetQuarter";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { spacing } from "@/theme/variables";
import { DOCUMENT_STATUS, DOCUMENT_STATUS_LABEL, TDocumentStatus } from "@/types/enum";
import { getEndDateOfQuarter } from "@/utils/pieces";
import { Box, Stack, Typography, styled } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

type TRegularTask = {
  targetQuarter: string;
  status: TDocumentStatus;
  supporterName: string | null;
};

export const RegularTaskCard = () => {
  const { data: presentDocumentListResult, isLoading: isLoadingPresentDocument } = usePresentDocumentList();
  const documentData = presentDocumentListResult?.data;

  const { data: companyData, isLoading: isLoadingCompany } = useCompanyInfo();

  const deadlineRender = useMemo(() => {
    if (!documentData) return "";
    const days = ["日", "月", "火", "水", "木", "金", "土"];
    const deadline = getEndDateOfQuarter({ year: documentData.year, quarter: documentData.quarter }).add(14, "day");
    return deadline.format(`${DATE_FORMAT}（${days[deadline.day()]}）`);
  }, [documentData?.quarter, documentData?.year]);

  const { targetQuarter } = useGetTargetQuarter();

  const { finished, failed } = useCheckRegularDocument();

  const supporterName = useMemo(() => {
    if (!companyData) return null;
    return companyData?.data?.supporters.length > 0 ? companyData?.data?.supporters?.[0].supporterName : null;
  }, [companyData]);

  const rows: TRegularTask[] = [
    {
      targetQuarter,
      status: failed ? DOCUMENT_STATUS.ERROR : finished ? DOCUMENT_STATUS.COMPLETE : DOCUMENT_STATUS.IN_PROGRESS,
      supporterName,
    },
  ];

  const columns = useMemo<MRT_ColumnDef<TRegularTask>[]>(
    () => [
      {
        header: "対象四半期",
        Cell: ({ row }) => (row.original.targetQuarter.toString() ? row.original.targetQuarter : EMPTY_STR.TEXT),
        size: 250,
      },
      {
        header: "ステータス",
        Cell: ({ row }) => {
          const { status } = row.original;
          return <StatusTag status={status} title={DOCUMENT_STATUS_LABEL[status]} variant="bold" />;
        },
        size: 80,
      },
      {
        header: "担当者",
        Cell: ({ row }) => {
          return (
            <Box display="flex" alignItems="center" gap={1.75}>
              {row.original.supporterName || EMPTY_STR.TEXT}
            </Box>
          );
        },
      },
    ],
    [],
  );

  const { table } = useTable({ rows, columns });

  const isLoading = useMemo(() => isLoadingPresentDocument || isLoadingCompany, [isLoadingPresentDocument, isLoadingCompany]);

  return (
    <TaskCard title="定期届出" seeMoreHref={AppRoutes.regularPresent}>
      <Stack gap={2}>
        <Deadline>
          <Typography variant="sub16Semi">次の申請締切は</Typography>
          <Typography variant="head32Bold">{deadlineRender}</Typography>
          <Typography variant="sub16Semi">です。</Typography>
        </Deadline>
        <Table table={table} isLoading={isLoading} />
      </Stack>
    </TaskCard>
  );
};

const Deadline = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  color: ${text.primary};
`;
