import { Linkable } from "@/components/elements/Linkable";
import { TopPage } from "@/components/elements/TopPage";
import { AppRoutes } from "@/routes/config";
import { divider, text } from "@/theme/colors";
import { Grid, Stack, Typography } from "@mui/material";

export const AnytimeTemplatesPage = () => {
  return (
    <>
      <TopPage title="随時届出一覧" backUrl={AppRoutes.anytime} />
      <Stack gap={3}>
        <Typography variant="head20Semi">書類の様式から書類を作成する</Typography>
        <Grid container width="100%" spacing={2}>
          <Grid item xs={4}>
            <Linkable href={AppRoutes.anytimeEmploymentContractCreate} height="100%" p={2} border="1px solid" borderColor={divider.middle}>
              <Stack gap={0.5}>
                <Typography variant="sub16Semi">雇用契約の変更に係る届出</Typography>
                <Typography variant="cap12" color={text.secondary}>
                  3-1-1
                </Typography>
              </Stack>
              <Typography variant="body14" color={text.secondary}>
                給与変更、勤務地の変更、労働時間の変更など、雇用契約に記載されている内容が変更になった場合に、提出します。
              </Typography>
            </Linkable>
          </Grid>
          <Grid item xs={4}>
            <Linkable href={AppRoutes.anytimeSupportPlanCreate} height="100%" p={2} border="1px solid" borderColor={divider.middle}>
              <Stack gap={0.5}>
                <Typography variant="sub16Semi">支援計画変更に係る届出</Typography>
                <Typography variant="cap12" color={text.secondary}>
                  3-2
                </Typography>
              </Stack>
              <Typography variant="body14" color={text.secondary}>
                会社名の変更、事前ガイダンスなどの生活支援に関する内容が変更になった際に提出します。
              </Typography>
            </Linkable>
          </Grid>
          <Grid item xs={4}>
            <Linkable href={AppRoutes.anytimeSupportContractCreate} height="100%" p={2} border="1px solid" borderColor={divider.middle}>
              <Stack gap={0.5}>
                <Typography variant="sub16Semi">支援委託契約に係る届出</Typography>
                <Typography variant="cap12" color={text.secondary}>
                  3-3-2
                </Typography>
              </Stack>
              <Typography variant="body14" color={text.secondary}>
                登録支援機関からのサポートを終了する場合に、提出が必要となります。
              </Typography>
            </Linkable>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
