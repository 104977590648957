import { TCreateConversationReqDto, TCreateConversationResDto } from "@/api/services/main/conversation/dtos/create-conversation.dto";
import { TCreateMessageReqDto, TCreateMessageResDto } from "@/api/services/main/conversation/dtos/create-message.dto";
import { TGetConversationByUserResDto } from "@/api/services/main/conversation/dtos/get-conversation-by-user.dto";
import { TGetMessageListByConversationResDto } from "@/api/services/main/conversation/dtos/get-message-list-by-conversation.dto";
import { TSetReadMessageResDto } from "@/api/services/main/conversation/dtos/set-read-message.dto";
import { DEFAULT_PAGINATION_REQUEST } from "@/constants/pagination";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { TApiPagReq } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";

type TMessageListByConversationProps = {
  conversationId: string;
  enabled?: boolean;
};

type TSetReadMessageProps = {
  conversationId: string;
  messageId: string;
};

export const fetchConversationApi = async (): Promise<TGetConversationByUserResDto> => {
  const { data } = await clientApi.get<TGetConversationByUserResDto>("/conversations");
  return data;
};

export const useConversation = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.CONVERSATION.FETCH_DETAIL],
    queryFn: () => fetchConversationApi(),
  });
};

export const fetchMessageListByConversationApi = async (conversationId: string, req: TApiPagReq): Promise<TGetMessageListByConversationResDto> => {
  const url = createUrlWithParams(`/conversations/${conversationId}/messages`, req);
  const { data } = await clientApi.get<TGetMessageListByConversationResDto>(url);
  return data;
};

export const useMessageListByConversation = ({ conversationId, enabled = true }: TMessageListByConversationProps) => {
  return useInfiniteScroll(
    [QUERY_KEYS.CONVERSATION.FETCH_MESSAGE_LIST, conversationId],
    (page) => fetchMessageListByConversationApi(conversationId.toString() ?? "", { ...DEFAULT_PAGINATION_REQUEST, page, perPage: 20 }),
    { enabled: enabled },
  );
};

export const createConversationApi = async (payload: TCreateConversationReqDto): Promise<TCreateConversationResDto> => {
  const { data } = await clientApi.post<TCreateConversationResDto>("/conversations", payload);
  return data;
};

export const createMessageApi = async (conversationId: string, payload: TCreateMessageReqDto): Promise<TCreateMessageResDto> => {
  const { data } = await clientApi.post<TCreateMessageResDto>(`/conversations/${conversationId}/messages`, payload);
  return data;
};

export const setReadMessageApi = async ({ conversationId, messageId }: TSetReadMessageProps): Promise<TSetReadMessageResDto> => {
  const { data } = await clientApi.post<TSetReadMessageResDto>(`/conversations/${conversationId}/messages/${messageId}/set-read`);
  return data;
};
