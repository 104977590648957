import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { DATE_FORMAT } from "@/constants/datetime";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { TPayrollRecord } from "@/features/regular-document/types/payrollRecord";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { omit, pick } from "@/utils/object";
import { makeUuid } from "@/utils/pieces";
import { getFullName } from "@/utils/string";

export const mappingPayrollWithEmployees = (data: TPayrollRecord[], employeeList: TGetEmployeeListOutputDto[], quarter: number): TPayrollRecord[] => {
  const lastData: TPayrollRecord[] = data.map((item) => ({ ...item, status: PAYROLL_STATUS.ERROR }));
  const newData: TPayrollRecord[] = [];

  const mappingData = employeeList.flatMap((employee, index) => {
    let hasEmployee = false;
    let employeeNumberOrder = "";
    const payrollObj = {
      employeeId: employee.id,
      employeeFullName: getFullName(pick(employee, "firstName", "middleName", "lastName")),
      employeeNationalityId: employee.nationalityId ? toNumber(employee.nationalityId) : null,
      employeeBirthday: dayjs(employee.birthday).format(DATE_FORMAT) ?? "",
      employeeGender: employee.gender ?? "",
      employeeResidenceCode: employee.residenceCode ?? "",
      employeeZipCode: employee.zipCode ?? "",
      employeeAddress: employee.fullAddress ?? "",
      isChangeWorkingLocation: PLAIN_TEXT.noChange,
      isChangeDispatchPlace: PLAIN_TEXT.noChange,
      isChangeWorkingDetail: PLAIN_TEXT.noChange,
      workingDayCount: "0",
      totalAmountPaid: "0",
      netAmountPaid: "0",
      legalDeductionAmount: "0",
      hasComparisonEmployee: PLAIN_TEXT.yes,
      status: PAYROLL_STATUS.OK,
      no: "",
    } as TPayrollRecord;

    return Array(3)
      .fill(null)
      .flatMap((_, i) => {
        // load data from DB
        const month = (quarter * 3 - 2 + i).toString();
        if (!data.length) return { ...payrollObj, recordId: makeUuid(), month, no: (index + 1).toString(), status: PAYROLL_STATUS.OK };

        // handle upload file and previous data
        const foundIndex = lastData.findIndex((item) => {
          if (item.employeeId === employee.id) return true;
          return (
            item.employeeFullName === payrollObj.employeeFullName && item.employeeBirthday === payrollObj.employeeBirthday && month === item.month
          );
        });

        // check employee inside data
        if (i === 0) {
          hasEmployee = foundIndex !== -1;
        }

        if (hasEmployee) {
          employeeNumberOrder = lastData[foundIndex]?.no ?? "";
        }

        if (!hasEmployee) {
          newData.push({
            ...payrollObj,
            recordId: makeUuid(),
            month,
            no: employeeNumberOrder,
            status: PAYROLL_STATUS.ADDED,
          });
          return [];
        }

        if (foundIndex === -1)
          return {
            ...payrollObj,
            recordId: makeUuid(),
            month,
            no: employeeNumberOrder,
            status: PAYROLL_STATUS.ERROR,
          };

        const updater = {
          ...payrollObj,
          ...omit(
            lastData[foundIndex],
            "employeeAddress",
            "employeeBirthday",
            "employeeFullName",
            "employeeGender",
            "employeeId",
            "employeeResidenceCardNumber",
            "employeeResidenceCode",
            "employeeZipCode",
          ),
          no: employeeNumberOrder,
          status: PAYROLL_STATUS.OK,
        };
        lastData.splice(foundIndex, 1);
        return updater;
      });
  });

  const newDataMapping = newData.map((item, index) => {
    const numberOrder = data.length / 3 + Math.floor(index / 3) + 1;

    return { ...item, no: numberOrder.toString() };
  }, []);

  return [lastData, mappingData, lastData.length ? [] : newDataMapping].flat();
};
