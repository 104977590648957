import { useInformationList } from "@/api/services/main/information";
import { NotificationList } from "@/components/commons/notification/NotificationList";
import { InfiniteScroll } from "@/components/elements/InfiniteScroll";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { NOTIFICATION_TAB, NOTIFICATION_TAB_LABEL, TNotificationTab } from "@/features/dashboard/constants/notificationTab";
import { button, divider, text } from "@/theme/colors";
import { TabContext, TabList } from "@mui/lab";
import { Box, styled } from "@mui/material";
import Tab from "@mui/material/Tab";
import { FC, useState } from "react";

export type TNotificationProps = {
  isSingleLine?: boolean;
  isHideContent?: boolean;
};

export const Notification: FC<TNotificationProps> = ({ isSingleLine = false, isHideContent = false }) => {
  const [tab, setTab] = useState<TNotificationTab>(NOTIFICATION_TAB.ALL);
  const {
    dataFormatter: informationListData,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInformationList({
    types: tab === NOTIFICATION_TAB.ALL ? ["system", "tenant"] : tab,
  });

  return (
    <BoxTabWrapper>
      <TabContext value={tab}>
        <Box>
          <TabList
            onChange={(_, tab) => setTab(tab)}
            TabIndicatorProps={{
              style: {
                backgroundColor: button.pressed,
                height: "4px",
              },
            }}
            variant="fullWidth"
          >
            {Object.values(NOTIFICATION_TAB).map((tab) => (
              <TabWrapper label={NOTIFICATION_TAB_LABEL[tab]} value={tab} key={tab} />
            ))}
          </TabList>
        </Box>
        {!informationListData || isLoading ? (
          <LoadingArea height={100} />
        ) : (
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage}>
            <NotificationList
              data={informationListData.map((notification) => ({
                id: notification.id,
                description: notification.content,
                time: notification.createdAt,
                title: notification.title,
                type: notification.type,
                filePath: notification.filePath,
              }))}
              isSingleLine={isSingleLine}
              isHideContent={isHideContent}
            />
          </InfiniteScroll>
        )}
      </TabContext>
    </BoxTabWrapper>
  );
};

const BoxTabWrapper = styled(Box)`
  & .MuiTabs-flexContainer {
    justify-content: space-between;

    & .MuiButtonBase-root {
      text-transform: none;
      font-weight: bold;
      font-size: 20px;
      color: ${text.disable};
    }

    & .Mui-selected {
      color: ${button.pressed};
    }
  }
`;

const TabWrapper = styled(Tab)`
  border-bottom: 4px solid ${divider.high};
`;
