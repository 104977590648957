import { useEnum } from "@/api/services/global/enum";
import { useGetLocalGovernmentCounterList, useGetRegionList } from "@/api/services/global/master";
import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { Button } from "@/components/elements/Button";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Select } from "@/components/elements/Select";
import { caculateSpecificSkillTransitionPeriod } from "@/features/employee/utils/caculateSpecificSkillTransitionPeriod";
import { EmployeeFamilyMemberFormTable } from "@/features/renew-visa/components/applicationStep/formApplication/employeeApplication/EmployeeFamilyMemberFormTable";
import { EResidenceCardReceiptMethod } from "@/features/renew-visa/enums/employeeApplicationForm";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { text } from "@/theme/colors";
import { YES_NO_ANSWER } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { getFullName } from "@/utils/string";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Divider, Grid, Accordion as MuiAccordion, styled } from "@mui/material";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TEmployeeInfoOneFormProps = {
  readonly: boolean;
};

export const EmployeeInfoOneForm: FC<TEmployeeInfoOneFormProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control, setValue } = methods;

  const employeeInfoHasReceivedPunishmentForCrimeWatchField = useWatch({ control, name: "employeeInfoHasReceivedPunishmentForCrime" });
  const employeeFamilyMembersWatchField = useWatch({ control, name: "employeeFamilyMembers" });
  const employeeInfoResidenceCardReceiveMethodWatchField = useWatch({ control, name: "employeeInfoResidenceCardReceiveMethod" });
  const employeeInfoProvinceWatchField = useWatch({ control, name: "employeeInfoProvince" });

  const { localGovernmentCounterByProvinceOptions, isLoading: isLoadingLocalGovernmentCounter } = useGetLocalGovernmentCounterList();

  const { isLoading: isLoadingRegion, nationalityOptions } = useGetRegionList();

  const { data: yesNoAnswerOptions } = useEnum("EYesNoAnswer");

  const refreshEmployeeInfo = async () => {
    const employeeId = methods.getValues("employeeId");
    try {
      const rs = await fetchEmployeeInfoApi(employeeId);
      const employeeData = rs.data;
      if (!employeeData) return;

      const { period } = caculateSpecificSkillTransitionPeriod({
        skillOneStartDate: dayjs(employeeData.skillOneStartDate),
        blankPeriodMonthly: employeeData.blankPeriodMonthly?.toString(),
      });
      setValue("relatedActivitySpecificSkillTransitionPeriod", period);

      if (employeeInfoProvinceWatchField !== employeeData.province) {
        setValue("employeeInfoResidenceCardReceiveGovernmentCounterId", null);
      }
      setValue(
        "employeeInfoFullName",
        getFullName({ lastName: employeeData.lastName, firstName: employeeData.firstName, middleName: employeeData.middleName }) ?? null,
        {
          shouldDirty: true,
        },
      );
      setValue("employeeInfoBirthday", dayjs(employeeData.birthday), { shouldDirty: true });
      setValue("employeeInfoPassportExpiryDate", employeeData.passportExpiryDate ? dayjs(employeeData.passportExpiryDate) : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoGender", employeeData.gender, { shouldDirty: true });
      setValue("employeeInfoPassportPermanentAddress", employeeData.passportPermanentAddress, { shouldDirty: true });
      setValue("employeeInfoProvince", employeeData.province, { shouldDirty: true });
      setValue("employeeInfoMunicipality", employeeData.municipality, { shouldDirty: true });
      setValue("employeeInfoAddress", employeeData.address, { shouldDirty: true });
      setValue("employeeInfoTelNumber", employeeData?.contractCompanyBranch?.telNumber ?? "", { shouldDirty: true });
      setValue("employeeInfoEmail", employeeData.email, { shouldDirty: true });
      setValue("employeeInfoPassportCode", employeeData.passportCode, { shouldDirty: true });
      setValue("employeeInfoNationalityId", employeeData.nationalityId ? employeeData.nationalityId.toString() : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidenceStatusId", employeeData.residenceStatusId ? employeeData.residenceStatusId.toString() : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidencePeriodId", employeeData.residencePeriodId ? employeeData.residencePeriodId.toString() : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidenceExpiryDate", employeeData.residenceExpiryDate ? dayjs(employeeData.residenceExpiryDate) : null, {
        shouldDirty: true,
      });
      setValue("employeeInfoTwoResidenceCode", employeeData.residenceCode, { shouldDirty: true });
      setValue("relatedActivityCompletionProof", employeeData.careerCompletionProof, { shouldDirty: true });
      setValue("employeeInfoTwoRasImmiFullName", employeeData?.company?.governmentOnlineSystemAccount?.rasImmiFullName ?? "", {
        shouldDirty: true,
      });
      setValue("relatedActivityMajorId", employeeData.careerMajorId ? employeeData.careerMajorId.toString() : null, {
        shouldDirty: true,
      });
      setValue("relatedActivityJobTypeId", employeeData.careerJobTypeId ? employeeData.careerJobTypeId.toString() : null, {
        shouldDirty: true,
      });
      setValue("relatedActivitySkillCertificationOne", employeeData.careerSkillCertificationOne, {
        shouldDirty: true,
      });
      setValue("relatedActivitySkillExamOneId", employeeData.careerSkillExamOneId ? employeeData.careerSkillExamOneId.toString() : null, {
        shouldDirty: true,
      });
      setValue(
        "relatedActivitySkillExamOverseaOneNationalityId",
        employeeData.careerSkillExamOverseaOneNationalityId ? employeeData.careerSkillExamOverseaOneNationalityId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("relatedActivitySkillExamLocationOne", employeeData.careerSkillExamLocationOne, { shouldDirty: true });
      setValue("relatedActivitySkillOtherEvaluation", employeeData.careerSkillOtherEvaluation, { shouldDirty: true });
      setValue("relatedActivityJapaneseCertificationTwo", employeeData.careerJapaneseCertificationOne, { shouldDirty: true });
      setValue("relatedActivityJapaneseExamTwoId", employeeData.careerJapaneseExamOneId ? employeeData.careerJapaneseExamOneId.toString() : null, {
        shouldDirty: true,
      });
      setValue("relatedActivityJapaneseExamLocationTwo", employeeData.careerJapaneseExamLocationOne, { shouldDirty: true });
      setValue(
        "relatedActivityJapaneseExamOverseaTwoNationalityId",
        employeeData.careerJapaneseExamOverseaOneNationalityId ? employeeData.careerJapaneseExamOverseaOneNationalityId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("relatedActivityJapaneseCertificationThree", employeeData.careerJapaneseCertificationTwo, { shouldDirty: true });
      setValue("relatedActivityJapaneseExamThreeId", employeeData.careerJapaneseExamTwoId ? employeeData.careerJapaneseExamTwoId.toString() : null, {
        shouldDirty: true,
      });
      setValue(
        "relatedActivityJapaneseExamOverseaThreeNationalityId",
        employeeData.careerJapaneseExamOverseaTwoNationalityId ? employeeData.careerJapaneseExamOverseaTwoNationalityId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("relatedActivityJapaneseExamLocationThree", employeeData.careerJapaneseExamLocationTwo, { shouldDirty: true });
      setValue("contractStartDate", employeeData.contractStartDate ? dayjs(employeeData.contractStartDate) : null, {
        shouldDirty: true,
      });
      setValue("contractEndDate", employeeData.contractEndDate ? dayjs(employeeData.contractEndDate) : null, {
        shouldDirty: true,
      });
      setValue(
        "contractSpecificIndustryId",
        employeeData.contractSpecificBusinessClassificationOne
          ? employeeData.contractSpecificBusinessClassificationOne.specificIndustryId.toString()
          : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificBusinessClassificationId",
        employeeData.contractSpecificBusinessClassificationOneId ? employeeData.contractSpecificBusinessClassificationOneId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificIndustryTwoId",
        employeeData.contractSpecificBusinessClassificationTwo
          ? employeeData.contractSpecificBusinessClassificationTwo.specificIndustryId.toString()
          : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificBusinessClassificationTwoId",
        employeeData.contractSpecificBusinessClassificationTwoId ? employeeData.contractSpecificBusinessClassificationTwoId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificIndustryThreeId",
        employeeData.contractSpecificBusinessClassificationThree
          ? employeeData.contractSpecificBusinessClassificationThree.specificIndustryId.toString()
          : null,
        {
          shouldDirty: true,
        },
      );
      setValue(
        "contractSpecificBusinessClassificationThreeId",
        employeeData.contractSpecificBusinessClassificationThreeId ? employeeData.contractSpecificBusinessClassificationThreeId.toString() : null,
        {
          shouldDirty: true,
        },
      );
      setValue("contractMainOccupationId", employeeData.contractMainOccupationId ? employeeData.contractMainOccupationId.toString() : null, {
        shouldDirty: true,
      });
      setValue("contractMonthlySalary", employeeData.contractMonthlySalary, { shouldDirty: true });
      setValue(
        "employeeInfoCareer",
        employeeData
          ? employeeData.contractSpecificBusinessClassificationOne?.specificIndustry?.companySpecificIndustry?.companySpecificIndustry?.toString() ??
              ""
          : null,
        {
          shouldDirty: true,
        },
      );
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (employeeFamilyMembersWatchField && employeeFamilyMembersWatchField.length > 0) {
      setValue("employeeInfoLivingWithDependentPerson", YES_NO_ANSWER.YES);
    } else {
      setValue("employeeInfoLivingWithDependentPerson", YES_NO_ANSWER.NO);
    }
  }, [employeeFamilyMembersWatchField]);

  return (
    <InnerBox
      title="外国人情報（身分事項1）"
      bordered
      p={3}
      required
      rightContent={
        readonly ? undefined : (
          <Button size="sm" onClick={refreshEmployeeInfo}>
            外国人情報を再取得する
          </Button>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoFullName">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoMaritalStatus">
            <Select enumName="EMaritalStatus" readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoLivingWithDependentPerson">
            <Select enumName="EYesNoAnswer" readonly />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <EmployeeFamilyMemberFormTable readonly={readonly} />
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoResidenceCardReceiveMethod">
            <Select
              enumName="EResidenceCardReceiptMethod"
              readonly={readonly}
              onChange={() => {
                setValue("employeeInfoResidenceCardReceiveGovernmentCounterId", null);
              }}
            />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoResidenceCardReceiveGovernmentCounterId">
            <Select
              loading={isLoadingLocalGovernmentCounter}
              options={employeeInfoProvinceWatchField ? localGovernmentCounterByProvinceOptions(employeeInfoProvinceWatchField) : []}
              readonly={readonly}
              disabled={employeeInfoResidenceCardReceiveMethodWatchField !== EResidenceCardReceiptMethod.COUNTER}
            />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoRecentResidenceTax">
            <Input endAdornment="円" clearable={false} readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <MuiAccordionWrapper sx={{ boxShadow: "none", width: "100%" }}>
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
            <AccordionDetails>
              <Divider sx={{ mb: 5 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoBirthday">
                    <InputDatePicker readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoGender">
                    <Select enumName="EGender" readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoNationalityId">
                    <Select loading={isLoadingRegion} readonly options={nationalityOptions} />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="employeeInfoHasReceivedPunishmentForCrime">
                    <RadioGroup
                      options={yesNoAnswerOptions}
                      disabled={readonly}
                      onChange={(value) => {
                        if (value !== YES_NO_ANSWER.YES) {
                          methods.setValue("employeeInfoPunishmentDetailForCrime", "");
                        }
                      }}
                    />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="employeeInfoPunishmentDetailForCrime">
                    <Input readonly={readonly || employeeInfoHasReceivedPunishmentForCrimeWatchField === YES_NO_ANSWER.NO} />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoCareer">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoPassportPermanentAddress">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoProvince">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoMunicipality">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="employeeInfoAddress">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoTelNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoMobileNumber">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoEmail">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoPassportCode">
                    <Input readonly />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="employeeInfoPassportExpiryDate">
                    <InputDatePicker readonly />
                  </FormField>
                </Grid>
              </Grid>
            </AccordionDetails>
          </MuiAccordionWrapper>
        </Grid>
      </Grid>
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &::before {
    display: none;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
