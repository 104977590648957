import { MAX_INTEGER } from "@/constants/common";

import { validator } from "@/utils/validator";
import { object } from "yup";

export const acceptActivitySchema = validator.object().shape({
  year: validator.number().decimal().emptyToNull().autoTransformToHalfWidth().integer().limit(MAX_INTEGER).label("年").required(),
  quarter: validator.number().decimal().emptyToNull().autoTransformToHalfWidth().integer().limit(MAX_INTEGER).label("対象4半期").required(),

  companyName: validator.string().trim().emptyToNull().label("氏名又は名称").required(),
  companyFuriganaName: validator.string().trim().emptyToNull().autoTransformToFullWidth().fullWidthOnly().notKanji().label("フリガナ").notRequired(),
  companyIdentificationNumber: validator.string().trim().emptyToNull().autoTransformToHalfWidth().label("法人番号").notRequired(),
  companySpecificIndustryId: validator.number().isSelection().autoTransformToHalfWidth().decimal().emptyToNull().label("特定産業分野").notRequired(),
  companyZipCode: validator.string().trim().emptyToNull().autoTransformToHalfWidth().zipCode().label("郵便番号").notRequired(),
  companyProvince: validator.string().isSelection().trim().emptyToNull().label("都道府県").notRequired(),
  companyAddress: validator.string().trim().emptyToNull().label("住所（都道府県以外）").notRequired(),
  companyTelNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),

  specificSkillTotalRegisteredEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .integer()
    .limit(MAX_INTEGER)
    .autoTransformToHalfWidth()
    .label("在籍者数（対象期間末日）")
    .required(),
  specificSkillTotalNewEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .limit(MAX_INTEGER)
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .label("新規雇用者数（対象期間中）")
    .required(),
  specificSkillTotalVoluntaryResignedEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("自己都合退職者数（対象期間中）")
    .required(),
  specificSkillTotalLayoffEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("解雇等会社都合退職者数（対象期間中）")
    .required(),
  specificSkillTotalMissingEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("行方不明者数（対象期間中）")
    .required(),

  specificSkillJapanRegisteredEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("在籍者数（対象期間末日）")
    .required(),
  specificSkillJapanNewEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新規雇用者数（対象期間中）")
    .required(),
  specificSkillJapanVoluntaryResignedEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("自己都合退職者数（対象期間中）")
    .required(),
  specificSkillJapanVoluntaryLayoffEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("解雇等会社都合退職者数（対象期間中）")
    .required(),

  specificSkillForeignRegisteredEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("在籍者数（対象期間末日）")
    .required(),
  specificSkillForeignNewEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新規雇用者数（対象期間中）")
    .required(),
  specificSkillForeignVoluntaryResignedEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("自己都合退職者数（対象期間中）")
    .required(),
  specificSkillForeignVoluntaryLayoffEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("解雇等会社都合退職者数（対象期間中）")
    .required(),
  specificSkillTotalRegisteredEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .integer()
    .limit(MAX_INTEGER)
    .autoTransformToHalfWidth()
    .label("在籍者数（対象期間末日）")
    .required(),
  specificSkillTotalNewEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .limit(MAX_INTEGER)
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .label("新規雇用者数（対象期間中）")
    .required(),
  specificSkillTotalVoluntaryResignedEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("自己都合退職者数（対象期間中）")
    .required(),
  specificSkillTotalLayoffEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("解雇等会社都合退職者数（対象期間中）")
    .required(),
  specificSkillTotalMissingEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("行方不明者数（対象期間中）")
    .required(),

  specificSkillJapanRegisteredEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("在籍者数（対象期間末日）")
    .required(),
  specificSkillJapanNewEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新規雇用者数（対象期間中）")
    .required(),
  specificSkillJapanVoluntaryResignedEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("自己都合退職者数（対象期間中）")
    .required(),
  specificSkillJapanVoluntaryLayoffEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("解雇等会社都合退職者数（対象期間中）")
    .required(),

  specificSkillForeignRegisteredEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("在籍者数（対象期間末日）")
    .required(),
  specificSkillForeignNewEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新規雇用者数（対象期間中）")
    .required(),
  specificSkillForeignVoluntaryResignedEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("自己都合退職者数（対象期間中）")
    .required(),
  specificSkillForeignVoluntaryLayoffEmployeeTwoCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("解雇等会社都合退職者数（対象期間中）")
    .required(),

  nonSpecificSkillRegisteredEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("在籍者数（対象期間末日）")
    .required(),
  nonSpecificSkillNewEmployeeCount: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新規雇用者数（対象期間中）")
    .required(),

  acceptanceActivityImplementationCost: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("支援計画の実施費用")
    .required(),
  acceptanceActivityPrepareCost: validator
    .number()
    .decimal()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受入準備費用")
    .required(),
  companySupporterTelNumber: validator.string().trim().emptyToNull().label("電話番号").notRequired(),
  companySupporterPosition: validator.string().trim().emptyToNull().label("役職").notRequired(),
  companySupporterName: validator.string().trim().emptyToNull().notRequired(),
  companySupporterId: validator.number().emptyToNull().notRequired(),
  acceptedPeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受け入れていた対象者数"),
  newlyAcceptedPeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新たに受け入れを開始した対象者数"),
  specifiedSkillOnePeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受入の内訳）１号特定技能外国人数"),
  specifiedSkillTwoPeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受入の内訳）２号特定技能外国人数"),
});

export const acceptActivityDraftSchema = object().shape({
  year: validator.number().emptyToNull().autoTransformToHalfWidth().integer().nullable(),
  quarter: validator.number().isSelection().emptyToNull().autoTransformToHalfWidth().integer().nullable(),
  companyName: validator.string().trim().emptyToNull().nullable(),
  companyFuriganaName: validator.string().trim().emptyToNull().nullable(),
  companySpecificIndustry: validator.string().trim().emptyToNull().nullable(),
  companyIdentificationNumber: validator.string().trim().emptyToNull().nullable(),
  companyZipCode: validator.string().trim().emptyToNull().nullable(),
  companyProvince: validator.string().isSelection().trim().emptyToNull().nullable(),
  companyAddress: validator.string().trim().emptyToNull().nullable(),
  companyTelNumber: validator.string().trim().emptyToNull().nullable(),
  specificSkillTotalRegisteredEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillTotalNewEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillTotalVoluntaryResignedEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillTotalLayoffEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillTotalMissingEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillJapanRegisteredEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanVoluntaryLayoffEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanVoluntaryResignedEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanNewEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillForeignRegisteredEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillForeignNewEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillForeignVoluntaryResignedEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillForeignVoluntaryLayoffEmployeeCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),

  specificSkillTotalRegisteredEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillTotalNewEmployeeTwoCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillTotalVoluntaryResignedEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillTotalLayoffEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillTotalMissingEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanRegisteredEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanVoluntaryLayoffEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanVoluntaryResignedEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillJapanNewEmployeeTwoCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillForeignRegisteredEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillForeignNewEmployeeTwoCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  specificSkillForeignVoluntaryResignedEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  specificSkillForeignVoluntaryLayoffEmployeeTwoCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .nullable(),
  nonSpecificSkillRegisteredEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  nonSpecificSkillNewEmployeeCount: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  acceptanceActivityImplementationCost: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  acceptanceActivityPrepareCost: validator.number().emptyToNull().min(0).autoTransformToHalfWidth().integer().limit(MAX_INTEGER).nullable(),
  companySupporterTelNumber: validator.string().trim().emptyToNull().nullable(),
  companySupporterPosition: validator.string().trim().emptyToNull().nullable(),
  companySupporterName: validator.string().trim().emptyToNull().nullable(),
  companySupporterId: validator.number().emptyToNull().nullable(),
  acceptedPeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受け入れていた対象者数"),
  newlyAcceptedPeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("新たに受け入れを開始した対象者数"),
  specifiedSkillOnePeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受入の内訳）１号特定技能外国人数"),
  specifiedSkillTwoPeopleCount: validator
    .number()
    .emptyToNull()
    .min(0)
    .autoTransformToHalfWidth()
    .integer()
    .limit(MAX_INTEGER)
    .label("受入の内訳）２号特定技能外国人数"),
});
