import { useEnum } from "@/api/services/global/enum";
import { Checkbox } from "@/components/elements/Checkbox";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { ERenewVisaConditionsApplyForStaffingTarget } from "@/features/renew-visa/enums/companyApplicationForm";
import { TCompanyApplicationForm } from "@/features/renew-visa/types/companyApplicationForm";
import { text } from "@/theme/colors";
import { Box, FormHelperText, Stack } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

type TStaffingTargetCheckBoxGroupProps = {
  readonly?: boolean;
};

export const StaffingTargetCheckBoxGroup: FC<TStaffingTargetCheckBoxGroupProps> = ({ readonly }) => {
  const methods = useFormContext<TCompanyApplicationForm>();
  const { data: renewVisaConditionsApplyForStaffingTargetOptions } = useEnum("ERenewVisaConditionsApplyForStaffingTarget");
  const { control } = methods;
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  useEffect(() => {
    setCheckedValues(methods.getValues("conditionsApplyForStaffingTargetsDetail") ?? []);
  }, [methods]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let newValues = [...checkedValues];
    if (checked) {
      newValues = [...newValues, value];
    } else {
      newValues = newValues.filter((v) => v !== value);
    }
    setCheckedValues(newValues);
    methods.setValue("conditionsApplyForStaffingTargetsDetail", newValues);
    if (!newValues.includes(ERenewVisaConditionsApplyForStaffingTarget.RELEVANT_INDUSTRY_WORK_AT_DISPATCH_SITE)) {
      methods.setValue("relevantIndustryWorkAtDispatchSiteDetail", "");
    }
    if (!newValues.includes(ERenewVisaConditionsApplyForStaffingTarget.AGRICULTURAL_WORK_IN_DESIGNATED_STRATEGIC_ZONE)) {
      methods.setValue("agriculturalWorkInDesignatedStrategicZoneDetail", "");
    }
    if (!newValues.includes(ERenewVisaConditionsApplyForStaffingTarget.MAJORITY_CAPITAL_INVESTMENT_BY_INDUSTRY_RELATED_ENTITY)) {
      methods.setValue("majorityCapitalInvestmentByIndustryRelatedEntityDetail", "");
    }
    if (!newValues.includes(ERenewVisaConditionsApplyForStaffingTarget.SUBSTANTIAL_INVOLVEMENT_BY_INDUSTRY_RELATED_ENTITY)) {
      methods.setValue("substantialInvolvementByIndustryRelatedEntityDetail", "");
    }
    methods.trigger("conditionsApplyForStaffingTargetsDetail");
  };

  const fieldState = methods.getFieldState("conditionsApplyForStaffingTargetsDetail");

  return (
    <Stack gap={1}>
      {renewVisaConditionsApplyForStaffingTargetOptions.map(({ label, value }, index) => {
        return (
          <Stack key={index} gap={1}>
            <Checkbox
              {...methods.register("conditionsApplyForStaffingTargetsDetail")}
              label={label}
              error={!!fieldState?.error}
              prefixLabel={`${index + 1}.`}
              value={value}
              checked={checkedValues.includes(value.toString())}
              onChange={handleChange}
              nonControl
              disabled={readonly}
            />
            <Stack direction="row">
              <Box sx={{ width: "30px" }}></Box>
              {ERenewVisaConditionsApplyForStaffingTarget.RELEVANT_INDUSTRY_WORK_AT_DISPATCH_SITE === value && (
                <FormField control={control} name="relevantIndustryWorkAtDispatchSiteDetail">
                  <Input
                    disabled={
                      !methods
                        .watch("conditionsApplyForStaffingTargetsDetail")
                        ?.includes(ERenewVisaConditionsApplyForStaffingTarget.RELEVANT_INDUSTRY_WORK_AT_DISPATCH_SITE) || readonly
                    }
                  />
                </FormField>
              )}
              {ERenewVisaConditionsApplyForStaffingTarget.AGRICULTURAL_WORK_IN_DESIGNATED_STRATEGIC_ZONE === value && (
                <FormField control={control} name="agriculturalWorkInDesignatedStrategicZoneDetail">
                  <Input
                    disabled={
                      !methods
                        .watch("conditionsApplyForStaffingTargetsDetail")
                        ?.includes(ERenewVisaConditionsApplyForStaffingTarget.AGRICULTURAL_WORK_IN_DESIGNATED_STRATEGIC_ZONE) || readonly
                    }
                  />
                </FormField>
              )}
              {ERenewVisaConditionsApplyForStaffingTarget.MAJORITY_CAPITAL_INVESTMENT_BY_INDUSTRY_RELATED_ENTITY === value && (
                <FormField control={control} name="majorityCapitalInvestmentByIndustryRelatedEntityDetail">
                  <Input
                    disabled={
                      !methods
                        .watch("conditionsApplyForStaffingTargetsDetail")
                        ?.includes(ERenewVisaConditionsApplyForStaffingTarget.MAJORITY_CAPITAL_INVESTMENT_BY_INDUSTRY_RELATED_ENTITY) || readonly
                    }
                  />
                </FormField>
              )}
              {ERenewVisaConditionsApplyForStaffingTarget.SUBSTANTIAL_INVOLVEMENT_BY_INDUSTRY_RELATED_ENTITY === value && (
                <FormField control={control} name="substantialInvolvementByIndustryRelatedEntityDetail">
                  <Input
                    disabled={
                      !methods
                        .watch("conditionsApplyForStaffingTargetsDetail")
                        ?.includes(ERenewVisaConditionsApplyForStaffingTarget.SUBSTANTIAL_INVOLVEMENT_BY_INDUSTRY_RELATED_ENTITY) || readonly
                    }
                  />
                </FormField>
              )}
            </Stack>
          </Stack>
        );
      })}
      {fieldState.error && <FormHelperText sx={{ color: text.error }}>{fieldState.error.message}</FormHelperText>}
    </Stack>
  );
};
