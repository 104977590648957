export enum EMaritalStatus {
  SINGLE = "single",
  MARRIED = "married",
}

export enum EResidenceCardReceiptMethod {
  MAIL = "mail",
  COUNTER = "counter",
}

export enum EEmployeeCareerSkillCertificationOne {
  FIELD_SPECIFIC_OPERATION_POLICY = "field_specific_operation_policy",
  COMPLETED_TECHNICAL_INTERN_TRAINING = "completed_technical_intern_training",
}

export enum EEmployeeCareerJapaneseCertification {
  FIELD_SPECIFIC_OPERATION_POLICY = "field_specific_operation_policy",
  COMPLETED_TECHNICAL_INTERN_TRAINING = "completed_technical_intern_training",
}

export enum EEmployeeCareerCompletionProof {
  PASSING_THREE_LEVEL_SKILL_OR_EQUIVALENT = "passing_three_level_skill_or_equivalent",
  TRAINING_STATUS = "training_status",
}

export enum EPaymentMethod {
  BANKING = "banking",
  CASH = "cash",
}
