import { Button } from "@/components/elements/Button";
import { AppRoutes } from "@/routes/config";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledContainer = styled(Stack)({
  textAlign: "center",
  padding: "32px",
  borderRadius: "8px",
  alignItems: "center",
  gap: 8,
});

const FeatureNotReadyPage = () => {
  return (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <StyledContainer>
        <Typography variant="head32Bold">まだ準備中です</Typography>
        <Typography variant="head20Bold"> 申し訳ありませんが、この機能はまだ準備中です。</Typography>
        <Typography variant="body14"> この機能は現在開発中です。後ほど再度お試しください。</Typography>
        <Button href={AppRoutes.home}>ホームページに戻る</Button>
      </StyledContainer>
    </Box>
  );
};

export default FeatureNotReadyPage;
