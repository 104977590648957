import { useEnum } from "@/api/services/global/enum";
import { useCompanyInfo } from "@/api/services/main/company";
import { EMPTY_STR } from "@/constants/string";
import { InfoRow } from "@/features/company/components/InfoRow";
import { FCC } from "@/types/common";
import { findLabelByValue } from "@/utils/object";
import { Grid, Stack } from "@mui/material";

type TSupporterInfoProps = {};

export const SupportersInfo: FCC<TSupporterInfoProps> = ({}) => {
  const { data, isLoading } = useCompanyInfo();
  const supporter = data?.data.supporters;
  const { data: companySupporterRoleOptions } = useEnum("ECompanySupporterRole");
  if (isLoading || !supporter) return null;
  return (
    <InfoRow title="支援者情報">
      {supporter.map((supporter) => (
        <Stack key={supporter.id} padding={1} borderBottom="1px solid #E5E5E5">
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <InfoRow
                paddingTop={2}
                titleBold={false}
                title={findLabelByValue(companySupporterRoleOptions, supporter.supporterRole) || EMPTY_STR.TEXT}
              />
            </Grid>
            <Grid item xs={2}>
              <InfoRow titleBold={false} gap={1} padding={0} title="名前" contentText={supporter.supporterName} />
            </Grid>
            <Grid item xs={2}>
              <InfoRow titleBold={false} gap={1} padding={0} title="フリガナ" contentText={supporter.supporterFuriganaName || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={2}>
              <InfoRow titleBold={false} gap={1} padding={0} title="役職" contentText={supporter.companyPosition} />
            </Grid>
            <Grid item xs={2}>
              <InfoRow titleBold={false} gap={1} padding={0} title="電話番号（数字のみ）" contentText={supporter.telNumber || EMPTY_STR.TEXT} />
            </Grid>
            <Grid item xs={2}>
              <InfoRow titleBold={false} gap={1} padding={0} title="メールアドレス" contentText={supporter.email || EMPTY_STR.TEXT} />
            </Grid>
          </Grid>
        </Stack>
      ))}
    </InfoRow>
  );
};
