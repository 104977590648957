import { TAnyTimeDocumentStep } from "@/api/entities/any-time-document-step.entity";
import { TEmployeeRetirementDocumentStep } from "@/api/entities/employee-retirement-document-step.entity";
import { TRegularDocumentStep } from "@/api/entities/regularDocumentStep.entity";
import { useGovernmentOnlineSystemAccountInfo } from "@/api/services/main/company";
import { useDialog } from "@/hooks/useDialog";
import { EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS } from "@/types/enum";
import { toNumber } from "@/utils/number";

type TUseRetirementConvertRequestProps = {
  stepData: TRegularDocumentStep | TAnyTimeDocumentStep | TEmployeeRetirementDocumentStep;
};

export const useRetirementConvertRequest = ({ stepData }: TUseRetirementConvertRequestProps) => {
  const { convertRequests } = stepData;
  const confirmRequestConvert = useDialog();
  const confirmNeedAccount = useDialog();
  const { data: companyAccountResult } = useGovernmentOnlineSystemAccountInfo();
  const companyAccountData = companyAccountResult?.data;

  const latestConvert = convertRequests?.sort((a, b) => toNumber(a.id) - toNumber(b.id)).at(-1);
  const isLatestConvertInProgress = latestConvert?.status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.PROCESSING;
  const isLatestConvertComplete = latestConvert?.status === EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE;
  return {
    companyAccountData,
    isLatestConvertInProgress,
    confirmRequestConvert,
    confirmNeedAccount,
    latestConvert,
    isLatestConvertComplete,
  };
};
