import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConfirmResetDataProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};

export const ConfirmResetData: FC<TConfirmResetDataProps> = ({ open, onClose, onOk }) => {
  return (
    <Modal isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい">
      <Stack mb={2}>
        <Typography>前回に入力したデータがリセットされますが、よろしいですか</Typography>
      </Stack>
    </Modal>
  );
};
