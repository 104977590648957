import { Modal } from "@/components/elements/Modal";
import { FCC } from "@/types/common";
import { Box } from "@mui/material";

type TConfirmSaveModalProps = {
  title?: string;
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  enableFooter?: boolean;
};

export const ConfirmSaveModal: FCC<TConfirmSaveModalProps> = ({ open, onClose, onOk, title, enableFooter, children }) => {
  return (
    <Modal isOpen={open} onClose={onClose} onOk={onOk} labelClose={"いいえ"} labelOk={"はい"} width={500} title={title} enableFooter={enableFooter}>
      <Box mb={2}>{children}</Box>
    </Modal>
  );
};
