import { Button } from "@/components/elements/Button";
import { DropZone } from "@/components/elements/DropZone";
import { FileItem } from "@/components/elements/FileItem";
import { FILE_STATUS, MIME_TYPES } from "@/constants/file";
import { PAYCHECK_CHOSEN_TYPE } from "@/features/regular-document/constants/paycheckChosenType";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { TExtendFile } from "@/types/file";
import { Box, Stack, Typography } from "@mui/material";
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";

export const PayrollUploading = () => {
  const {
    currentFile,
    handleSetCurrentFile,
    chosenType,
    paycheckRows,
    showConfirmReset,
    isLoading,
    resetData,
    readonly,
    resourceData,
    setIsDirty,
    isInvalidFormatFile,
  } = usePayCheck();
  const [file, setFile] = useState<TExtendFile | undefined>(currentFile);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (chosenType !== PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE) {
      setFile(undefined);
    }
  }, [chosenType]);

  const onChangeFile = async (value: TExtendFile | TExtendFile[] | null) => {
    const dropFile = [value].flat().at(0);
    if (!dropFile || dropFile.id === file?.id) return;

    if (!file) {
      setFile(dropFile);
      return;
    }

    setIsDirty(true);
    setFile(dropFile);
    resetData();
  };

  const setCurrentFile = async () => {
    if (!file) return;
    await handleSetCurrentFile(file);
  };

  const onClickDropZone: MouseEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    if (file) {
      const result = await showConfirmReset();
      if (result) {
        e.preventDefault();
        ref.current?.querySelector("input")?.click();
      }
    }
  };

  const isDisabled = useMemo(() => {
    if (!file) return true;
    return file.id === currentFile?.id;
  }, [file, currentFile]);

  const isValid = useMemo(() => {
    if (isLoading) return true;
    if (!paycheckRows.length) return true;
    return !paycheckRows.some((record) => record.status === PAYROLL_STATUS.ERROR);
  }, [paycheckRows]);

  if (chosenType !== PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE || resourceData.status === "complete") return null;

  if (readonly) return null;

  return (
    <Stack gap={2}>
      <Typography variant="sub16Semi">irohana書式の賃金台帳フォーマットをアップロードする</Typography>
      <Stack gap={2}>
        <Box onClick={onClickDropZone} ref={ref}>
          <DropZone
            value={file}
            onChange={onChangeFile}
            enableFileList={false}
            rules={{ acceptMimeTypes: MIME_TYPES.CSV }}
            options={{ noClick: !!file }}
          />
        </Box>
        {file && (
          <Box>
            {file.filePath ? (
              <FileItem
                filePath={file.filePath}
                errorMsg={
                  isInvalidFormatFile
                    ? "誤ったファイルがアップロードされました。正しいファイルを選択してください。"
                    : isValid
                      ? ""
                      : `正しく入力されていない項目、または未入力の項目があります。\nアップロードされたCSVデータを再確認の上、修正をお願いします。`
                }
              />
            ) : (
              <FileItem
                file={file}
                onRemove={() => setFile(undefined)}
                errorMsg={
                  isInvalidFormatFile
                    ? "誤ったファイルがアップロードされました。正しいファイルを選択してください。"
                    : isValid
                      ? ""
                      : `正しく入力されていない項目、または未入力の項目があります。\nアップロードされたCSVデータを再確認の上、修正をお願いします。`
                }
              />
            )}
          </Box>
        )}
      </Stack>
      {file && file.status === FILE_STATUS.OK && (
        <Button variant="primary" sx={{ mx: "auto" }} onClick={setCurrentFile} disabled={isDisabled}>
          フォームに反映する
        </Button>
      )}
    </Stack>
  );
};
