import { useCompanyBranchInfo } from "@/api/services/main/company";
import { CompanyBranchForm } from "@/features/company/components/CompanyBranchForm";
import { convertCompanyBranchResToFormDataDto } from "@/features/company/utils/convertResToFormData";
import { FC } from "react";

type TCompanyBranchEditPageProps = {};

export const CompanyBranchEditPage: FC<TCompanyBranchEditPageProps> = () => {
  const { data, isLoading } = useCompanyBranchInfo();

  if (!data || isLoading) return null;
  return <CompanyBranchForm isHeadQuarter={data.data.isHeadquarters} previousValues={convertCompanyBranchResToFormDataDto(data.data)} />;
};
