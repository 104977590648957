import { EMPTY_STR } from "@/constants/string";

/**
 * Replaces placeholders in a template string with corresponding values from an object.
 *
 * @param {string} template - The string containing placeholders (e.g., "Hello, {{name}}!").
 * @param {object} replaces - An object where keys are placeholders and values are replacements (e.g., { name: "Alice" }).
 * @returns {string} - The resulting string with placeholders replaced by actual values.
 */
export const replaceStr = (template: string, replaces: object): string => {
  return Object.entries(replaces).reduce((cur, [key, value]) => (value !== undefined ? cur.replace(`{{${key}}}`, value.toString()) : cur), template);
};

/**
 * Constructs a full name from an object with optional middle name and nickname.
 *
 * @param {object} objectName - An object containing name parts.
 * @param {string} objectName.lastName - The last name.
 * @param {string | null} [objectName.middleName] - The middle name (optional).
 * @param {string} objectName.firstName - The first name.
 * @param {string | null} [objectName.nickName] - The nickname (optional).
 * @returns {string} - The full name, or the nickname if provided.
 */
export const getFullName = (objectName: {
  lastName?: string | null;
  middleName?: string | null;
  firstName: string;
  nickName?: string | null;
}): string => {
  const { lastName, middleName, firstName, nickName } = objectName;

  if (nickName) return nickName;

  return [lastName ?? [], middleName ?? [], firstName].flat().join(" ");
};

export const getFullAddress = (addressObject: { province?: string | null; municipality?: string | null; address?: string | null }): string => {
  const { province, municipality, address } = addressObject;

  const fullAddress = [province ?? [], municipality ?? [], address ?? []].flat().join("").trim();

  return fullAddress.length ? fullAddress : EMPTY_STR.TEXT;
};

/**
 * Converts a value to a string. Returns an empty string if the value is not a string.
 *
 * @param {unknown} data - The value to convert.
 * @returns {string} - The string representation of the value, or an empty string if the value is not a string.
 */
export const toString = (data: unknown): string => {
  if (typeof data === "string") return data;
  return "";
};

/**
 * Truncates a string with an ellipsis if it exceeds a given length
 * @param {string} str - The input string to truncate
 * @param {number} maxLength - The maximum allowed length for the string
 * @returns {string} - The truncated string with an ellipsis (if needed)
 */
export const truncateWithEllipsis = (str: string, maxLength: number): string => {
  // Check if the string needs truncation
  if (str.length <= maxLength) {
    return str;
  }
  // Truncate the string and add an ellipsis
  return str.slice(0, maxLength - 3) + "...";
};
