import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { TGetRetirementAcceptingWorkerDocumentDto } from "@/api/services/main/retirement-document/retirement-accepting-worker-document/dtos/get-retirement-accepting-worker-document.dto";
import { TUpdateRetirementAcceptingWorkerDocumentReqDto } from "@/api/services/main/retirement-document/retirement-accepting-worker-document/dtos/update-retirement-accepting-worker-document.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import {
  EAnyConsultation,
  EPlanDateReturn,
  EPlanToChangeJob,
  EPlanToMove,
  EReasonReturnHome,
  ERetirementReasonType,
  ESpecificCircumstance,
  ESupportType,
} from "@/features/retirement/constants/enums";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { EProvidedLegalProtection } from "@/routes/constants";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";

const intiForm = {
  reasonType: ERetirementReasonType.CONVENIENT_FOR_COMPANY,
  changeContractCategoryContent: null,
  changeContractSummary: null,

  convenienceCategoryContent: null,
  convenienceDateOfOccurrence: null,
  convenienceSummary: null,

  specificCircumstances: ESpecificCircumstance.DEFAULT,
  anyConsultation: EAnyConsultation.DEFAULT,
  plansToChangeJob: EPlanToChangeJob.DEFAULT,
  supportType: ESupportType.DEFAULT,
  reasonReturnHome: EReasonReturnHome.DEFAULT,
  planDateReturn: EPlanDateReturn.DEFAULT,
  planToMove: EPlanToMove.DEFAULT,

  currentSituation: null,

  measuresTakenContent: null,
};

export const getInitValues = (data: {
  acceptingWorkerData?: TGetRetirementAcceptingWorkerDocumentDto;
  companyData?: TGetCompanyDto;
}): Partial<TRetirementAcceptingWorkerForm> | undefined => {
  const { acceptingWorkerData, companyData } = data;

  if (!companyData) return undefined;
  return {
    ...intiForm,
    ...acceptingWorkerData,
    providedLegalProtection: acceptingWorkerData?.providedLegalProtection
      ? acceptingWorkerData?.providedLegalProtection
      : Object.values(EProvidedLegalProtection),
    planedDateOfReturn: acceptingWorkerData?.planedDateOfReturn ? dayjs(acceptingWorkerData?.planedDateOfReturn) : null,
    changeContractDateOfOccurrence: acceptingWorkerData?.changeContractDateOfOccurrence
      ? dayjs(acceptingWorkerData?.changeContractDateOfOccurrence)
      : null,
    convenienceDateOfOccurrence: acceptingWorkerData?.convenienceDateOfOccurrence ? dayjs(acceptingWorkerData?.convenienceDateOfOccurrence) : null,
    supporterName: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].supporterName : null,
    supporterTelNumber: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].telNumber : null,
    supporterId: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].id : null,
    companyName: companyData.companyName ?? null,
    companyFuriganaName: companyData.companyFuriganaName ?? null,
    companySpecificIndustryId: companyData.specificIndustryId ?? null,
    companyIdentificationNumber: companyData.companyIdentificationNumber ?? null,
    companyZipCode: companyData.zipCode ?? null,
    companyProvince: companyData.province ?? null,
    companyAddress: companyData.fullAddress ?? null,
    companyTelNumber: companyData.telNumber ?? null,
  };
};

export const convertFormDataToReqDto = (data: TRetirementAcceptingWorkerForm, isDraft: boolean): TUpdateRetirementAcceptingWorkerDocumentReqDto => {
  return {
    ...intiForm,
    reasonType: data.reasonType ?? null,
    changeContractCategoryType: data.changeContractCategoryType ?? null,
    changeContractCategoryContent: data.changeContractCategoryContent ?? null,
    planedDateOfReturn: data.planedDateOfReturn ? data.planedDateOfReturn.format(ISO_FORMAT) : null,
    convenienceDateOfOccurrence: data.convenienceDateOfOccurrence ? data.convenienceDateOfOccurrence.format(ISO_FORMAT) : null,
    changeContractDateOfOccurrence: data.changeContractDateOfOccurrence ? data.changeContractDateOfOccurrence.format(ISO_FORMAT) : null,
    changeContractSummary: data.changeContractSummary ?? null,
    convenienceCategoryType: data.convenienceCategoryType ?? null,
    convenienceCategoryContent: data.convenienceCategoryContent ?? null,
    convenienceSummary: data.convenienceSummary ?? null,
    currentSituation: data.currentSituation ?? null,
    planToContinueActivity: data.planToContinueActivity ?? null,
    planToContinueActivityContent: data.planToContinueActivityContent ?? null,
    measuresTakenType: data.measuresTakenType ?? null,
    measuresTakenContent: data.measuresTakenContent ?? null,
    specificCircumstances: data.specificCircumstances ?? null,
    specificCircumstanceContent: data.specificCircumstanceContent ?? null,
    anyConsultation: data.anyConsultation ?? null,
    anyConsultationContent: data.anyConsultationContent ?? null,
    plansToChangeJob: data.plansToChangeJob ?? null,
    plansToChangeJobContent: data.plansToChangeJobContent ?? null,
    supportType: data.supportType ?? null,
    supportContent: data.supportContent ?? null,
    reasonReturnHome: data.reasonReturnHome ?? null,
    reasonReturnHomeContent: data.reasonReturnHomeContent ?? null,
    planDateReturn: data.planDateReturn ?? null,
    planDateReturnContent: data.planDateReturnContent ?? null,
    planToMove: data.planToMove ?? null,
    planToMoveContent: data.planToMoveContent ?? null,
    contractName: data.contractName ?? null,
    providedLegalProtection: data.providedLegalProtection ?? null,

    supporterId: data.supporterId ? toNumber(data.supporterId) : null,
    supporterName: data.supporterName ?? null,
    supporterTelNumber: data.supporterTelNumber ?? null,
    companyName: data.companyName ?? null,
    companyFuriganaName: data.companyFuriganaName ?? null,
    companySpecificIndustryId: data.companySpecificIndustryId ? toNumber(data.companySpecificIndustryId) : null,
    companyIdentificationNumber: data.companyIdentificationNumber ?? null,
    companyZipCode: data.companyZipCode ?? null,
    companyProvince: data.companyProvince ?? null,
    companyAddress: data.companyAddress ?? null,
    companyTelNumber: data.companyTelNumber ?? null,
    employeeFullName: data.employeeFullName,
    employeeNickName: data.employeeNickName ?? null,
    employeeGender: data.employeeGender ?? null,
    employeeNationalityId: data.employeeNationalityId ? Number(data.employeeNationalityId) : null,
    employeeResidenceCardNumber: data.employeeResidenceCardNumber ?? null,
    specificBusinessClassificationName: data.specificBusinessClassificationName ?? null,
    specificIndustryName: data.specificIndustryName ?? null,
    birthday: data.birthday ?? null,
    zipCode: data.zipCode ?? null,
    fullAddress: data.fullAddress ?? null,
    telNumber: data.telNumber ?? null,
    isDraft,
  };
};

export const convertResDtoToFormData = (data: TGetRetirementAcceptingWorkerDocumentDto): TRetirementAcceptingWorkerForm => {
  return {
    ...data,
    reasonType: data.reasonType ?? null,
    changeContractCategoryType: data.changeContractCategoryType ?? null,
    changeContractCategoryContent: data.changeContractCategoryContent ?? null,
    changeContractSummary: data.changeContractSummary ?? null,
    convenienceCategoryType: data.convenienceCategoryType ?? null,
    convenienceCategoryContent: data.convenienceCategoryContent ?? null,
    convenienceSummary: data.convenienceSummary ?? null,
    currentSituation: data.currentSituation ?? null,
    planToContinueActivity: data.planToContinueActivity ?? null,
    planToContinueActivityContent: data.planToContinueActivityContent ?? null,
    measuresTakenType: data.measuresTakenType ?? null,
    measuresTakenContent: data.measuresTakenContent ?? null,
    specificCircumstances: data.specificCircumstances ?? null,
    specificCircumstanceContent: data.specificCircumstanceContent ?? null,
    anyConsultation: data.anyConsultation ?? null,
    anyConsultationContent: data.anyConsultationContent ?? null,
    plansToChangeJob: data.plansToChangeJob ?? null,
    plansToChangeJobContent: data.plansToChangeJobContent ?? null,
    supportType: data.supportType ?? null,
    supportContent: data.supportContent ?? null,
    reasonReturnHome: data.reasonReturnHome ?? null,
    reasonReturnHomeContent: data.reasonReturnHomeContent ?? null,
    planDateReturn: data.planDateReturn ?? null,
    planDateReturnContent: data.planDateReturnContent ?? null,
    planToMove: data.planToMove ?? null,
    planToMoveContent: data.planToMoveContent ?? null,
    contractName: data.contractName ?? null,
    providedLegalProtection: data.providedLegalProtection ?? null,

    planedDateOfReturn: data.planedDateOfReturn ? dayjs(data.planedDateOfReturn) : null,
    changeContractDateOfOccurrence: data.changeContractDateOfOccurrence ? dayjs(data.changeContractDateOfOccurrence) : null,
    convenienceDateOfOccurrence: data.convenienceDateOfOccurrence ? dayjs(data.convenienceDateOfOccurrence) : null,
  };
};
