import { DropZone, TDropZoneProps } from "@/components/elements/DropZone";
import { Modal } from "@/components/elements/Modal";
import { AutoUpload } from "@/components/elements/AutoUpload/AutoUpload";
import { divider } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { TExtendFile } from "@/types/file";
import { Box, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { TFileUploadFor } from "@/constants/file";
import { LoadingArea } from "@/components/elements/Loading/Loading";

type TUploadPopupProps = {
  title?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onDropFiles?: (files: TExtendFile[]) => Promise<boolean>; //isError
  onFinishUpload?: (files: TExtendFile[], newFiles: TExtendFile[]) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  uploadFor: TFileUploadFor;
} & Omit<TDropZoneProps, "enableFileList">;

export const UploadPopup: FCC<TUploadPopupProps> = ({
  children,
  title,
  isOpen,
  onClose,
  onDropFiles,
  rules = {},
  onFinishUpload,
  onCancel,
  isLoading = false,
  uploadFor,
  ...rest
}) => {
  const { options } = rest;
  const [dropFiles, setDropFiles] = useState<TExtendFile[]>([]);

  const handleChangeFiles = async (value: TExtendFile | TExtendFile[] | null) => {
    let files = [value ?? []].flat();
    if (!files.length) return;
    if (options?.multiple === false) {
      files = [files[0]];
    }
    const isError = await onDropFiles?.(files);
    if (isError) return;
    setDropFiles(files);
    onClose();
  };

  const onCancelUpload = () => {
    setDropFiles([]);
    onCancel?.();
  };

  return (
    <>
      <Modal title={title} isOpen={isOpen} onClose={onClose} enableFooter={false} enableCloseIcon={!isLoading} preventCloseBackdrop={isLoading}>
        <Box mt={2} p={3} border="1px solid" borderColor={divider.high} borderRadius={rounded.sm}>
          {isLoading ? (
            <LoadingArea />
          ) : (
            <DropZone {...rest} enableFileList={false} onChange={handleChangeFiles} nonControl rules={rules}>
              {children ?? <Typography variant="body14" whiteSpace="pre-wrap" textAlign="center">{`ファイルを\nドラッグ&ドロップ`}</Typography>}
            </DropZone>
          )}
        </Box>
      </Modal>
      {uploadFor && (
        <AutoUpload
          files={dropFiles}
          rules={rules}
          onFinishUpload={onFinishUpload}
          onCancelUpload={onCancelUpload}
          uploadFor={uploadFor}
          multiple={options?.multiple}
        />
      )}
    </>
  );
};
