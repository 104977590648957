import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { CONVERT_STATUS, DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { useMemo } from "react";

type useCheckRegularDocumentReturn = {
  completed: boolean;
  finished: boolean;
  failed: boolean;
};

export const useCheckRegularDocument = (): useCheckRegularDocumentReturn => {
  const { data: presentDocumentListResult } = usePresentDocumentList();
  const documentData = presentDocumentListResult?.data;

  const completed = useMemo(() => {
    const checkKeys = [
      DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT,
      DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT,
      DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT,
    ];
    if (!documentData) return true;
    return documentData?.regularDocuments
      .flatMap(({ steps }) => steps)
      .every(({ masterDocumentStep, status }) => {
        if (!checkKeys.includes(masterDocumentStep.stepKey)) return true;
        return status === DOCUMENT_STATUS.COMPLETE || status === DOCUMENT_STATUS.CONVERT_COMPLETE;
      });
  }, [documentData]);

  const finished = useMemo(() => {
    const checkKeys = [DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT];
    if (!documentData) return true;
    return documentData?.regularDocuments
      .flatMap(({ steps }) => steps)
      .every(({ masterDocumentStep, status, convertRequests }) => {
        if (!checkKeys.includes(masterDocumentStep.stepKey)) return true;
        if (
          [DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT].includes(
            masterDocumentStep.stepKey,
          )
        ) {
          const latestConvertRequest = convertRequests?.sort((a, b) => b.id - a.id)[0];
          if (latestConvertRequest?.status !== CONVERT_STATUS.COMPLETE) return false;

          const availableStatues = [DOCUMENT_STATUS.COMPLETE, DOCUMENT_STATUS.CONVERT_COMPLETE];
          if (availableStatues.includes(status) && latestConvertRequest?.isDownloaded) return true;

          return false;
        }
        return status === DOCUMENT_STATUS.COMPLETE || status === DOCUMENT_STATUS.CONVERT_COMPLETE;
      });
  }, [documentData]);

  const failed = useMemo(() => {
    const checkKeys = [DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT];
    if (!documentData) return true;
    return documentData?.regularDocuments
      .flatMap(({ steps }) => steps)
      .some(({ masterDocumentStep, status }) => {
        if (checkKeys.includes(masterDocumentStep.stepKey)) {
          return status === DOCUMENT_STATUS.ERROR;
        }
        return false;
      });
  }, [documentData]);

  return {
    completed,
    finished,
    failed,
  };
};
