import { fetchEmployeeListApi } from "@/api/services/main/employee";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { ISO_FORMAT } from "@/constants/datetime";
import { PAYCHECK_CHOSEN_TYPE, TPaycheckChosenType } from "@/features/regular-document/constants/paycheckChosenType";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { downloadPaycheckSampleFile } from "@/features/regular-document/utils/downloadPaycheckSampleFile";
import { state } from "@/theme/colors";
import { EMPLOYMENT_STATUS } from "@/types/enum";
import { pick } from "@/utils/object";
import { getEndDateOfQuarter, getStartDateOfQuarter } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TPaycheckChosenTypeProps = {};

export const PaycheckChosenType: FC<TPaycheckChosenTypeProps> = () => {
  const { chosenType, handleChosenType, resourceData, payrollFilesTotal, readonly, isEditable } = usePayCheck();

  const download = async () => {
    try {
      const { data } = await fetchEmployeeListApi({
        startWorking: getStartDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
        endWorking: getEndDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
        residenceStatusIds: [1, 2],
        employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
      });
      if (!data.length) {
        toast.error("この四半期に従業員はいません。");
        return;
      }
      downloadPaycheckSampleFile(data, resourceData.quarter);
    } catch (error) {
      // do nothing
    }
  };

  if (readonly || !isEditable) {
    if (chosenType === PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE) {
      return (
        <Stack gap={1}>
          <Typography variant="body14">irohana書式の賃金台帳フォーマットをアップロードする</Typography>
          <Typography variant="cap12">フォーマットに必要項目を入力し、アップロードすると自動でirohana上に必要項目が反映されます。</Typography>
        </Stack>
      );
    }
    if (chosenType === PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT) {
      return <Typography variant="body14">賃金台帳入力の代行を依頼する</Typography>;
    }
    if (chosenType === PAYCHECK_CHOSEN_TYPE.LOAD_DATA) {
      return <Typography variant="body14">ご自身でフォームに入力する</Typography>;
    }
    return null;
  }

  return (
    <Stack gap={3}>
      <RadioGroup
        direction="column"
        value={chosenType}
        onChange={(v) => handleChosenType(v as TPaycheckChosenType)}
        disabled={readonly || !isEditable}
        options={[
          {
            label: "irohana書式の賃金台帳フォーマットをアップロードする",
            subLabel: (
              <>
                フォーマットに必要項目を入力し、アップロードすると自動でirohana上に必要項目が反映されます。 irohana書式のフォーマットは
                <Typography variant="cap12" color={state.link_1} sx={{ cursor: "pointer" }} onClick={download}>
                  こちら
                </Typography>
              </>
            ),
            value: PAYCHECK_CHOSEN_TYPE.UPLOAD_FILE,
          },
          {
            label: "賃金台帳の入力代行を依頼",
            value: PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT,
            subLabel: <>事前に定期届出トップから賃金台帳原本をアップロードしてからご依頼ください。</>,
            disabled: !payrollFilesTotal || payrollFilesTotal === 0,
          },
          { label: "ご自身でフォームに入力する", value: PAYCHECK_CHOSEN_TYPE.LOAD_DATA },
        ]}
      />
    </Stack>
  );
};
