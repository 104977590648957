import { usePreviousDocumentList } from "@/api/services/main/regular-document";
import { TGetPreviousDocumentListDto } from "@/api/services/main/regular-document/dtos/get-previous-document-list.dto";
import { Button } from "@/components/elements/Button";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { DocumentDownload } from "@/features/regular-document/components/DocumentDownload";
import { useTable } from "@/hooks/useTable";
import { useVisible } from "@/hooks/useVisible";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { getDynamicRoute } from "@/utils/url";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";

export type THistoryTableProps = {};

export const HistoryTable: FC<THistoryTableProps> = () => {
  const printPopup = useVisible();
  const [selectedPrintDoc, setSelectedPrintDoc] = useState<TGetPreviousDocumentListDto | null>(null);
  const { data: dataResult, isLoading } = usePreviousDocumentList();

  const columns = useMemo<MRT_ColumnDef<TGetPreviousDocumentListDto>[]>(
    () => [
      {
        header: "年",
        id: "year",
        accessorKey: "year",
        size: 120,
      },
      {
        header: "四半期",
        Cell: ({ row }) => `第${row.original?.quarter}四半期`,
        size: 120,
      },
      {
        header: "最終更新日",
        Cell: ({ row }) => (row.original?.updatedAt ? dayjs(row.original?.updatedAt).format(DATE_FORMAT) : EMPTY_STR.DATE),
        size: 120,
      },
      {
        header: "最終更新者",
        Cell: ({ row }) => row.original?.updater?.fullName || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "PDFダウンロード",
        Cell: ({ row }) => {
          const { year, quarter } = row.original;

          return (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setSelectedPrintDoc(row.original);
                printPopup.open();
              }}
            >
              ダウンロード
            </Button>
          );

          return (
            <Button variant="primary" size="sm" component={Link} to={getDynamicRoute(AppRoutes.regularHistoryDetailDownload, { year, quarter })}>
              取得
            </Button>
          );
        },
        size: 120,
      },
      {
        header: "確認",
        Cell: ({ row }) => {
          const { year, quarter } = row.original;

          return (
            <Button variant="outline" size="sm" component={Link} to={getDynamicRoute(AppRoutes.regularHistoryDetail, { year, quarter })}>
              確認
            </Button>
          );
        },
        size: 120,
      },
    ],
    [],
  );
  const { table } = useTable({ columns, rows: dataResult?.data ?? [] });

  return (
    <>
      <Table table={table} isLoading={isLoading} />
      {printPopup.visible && selectedPrintDoc && (
        <DocumentDownload
          year={selectedPrintDoc.year}
          quarter={selectedPrintDoc.quarter}
          onClose={() => {
            printPopup.close();
            setSelectedPrintDoc(null);
          }}
        />
      )}
    </>
  );
};
