import { useEnum } from "@/api/services/global/enum";
import { TGetEmployeeDetailListOutputDto } from "@/api/services/main/employee/dtos/get-employee-detail-list.dto";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { useCallback } from "react";
import { getFullName } from "@/utils/string";
import { YEAR_MONTH_DAY_STR_FORMAT } from "@/constants/datetime";
import { RETIREMENT_STATUS } from "@/types/enum";

export const useConvertEmployeeBulkApplicationToCsvData = () => {
  const { data: genderOptions } = useEnum("EGender");
  const { data: employmentStatusOptions } = useEnum("EEmploymentStatus");
  const { data: retirementStatusOptions } = useEnum("ERetirementStatus");

  const convertEmployeeBulkApplicationFormatToCsvData = useCallback(
    (selectedEmployees: TGetEmployeeDetailListOutputDto[]) => {
      return selectedEmployees.map((employee, index) => ({
        ["項目"]: index + 1,
        ["国籍・地域"]: employee.nationality ? employee.nationality.nationalityName.toString() : "",
        ["氏名"]: getFullName({ firstName: employee.firstName, lastName: employee.lastName, middleName: employee.middleName }),
        ["性別"]: employee.gender ? findLabelByValue(genderOptions, employee.gender) : "",
        ["生年月日"]: employee.birthday ? dayjs(employee.birthday).format(YEAR_MONTH_DAY_STR_FORMAT) : "",
        ["配偶者の有無"]: "",
        ["職業(特定産業分野が入力されます)"]: "",
        ["本国における居住地"]: employee.passportPermanentAddress ? employee.passportPermanentAddress.toString() : "",
        ["居住地(都道府県)"]: employee.province ? employee.province : "",
        ["居住地(市区町村) "]: employee.municipality && employee.province ? employee.municipality : "",
        ["居住地(町名丁目番地号等、全角のみ)"]: employee.address ? employee.address.toString() : "",
        ["電話番号(半角数字のみ)"]: employee.telNumber ? employee.telNumber.toString() : "",
        ["携帯電話番号"]: "",
        ["メールアドレス"]: employee.email ? employee.email.toString() : "",
        ["旅券番号"]: employee.passportCode ? employee.passportCode.toString() : "",
        ["旅券有効期限(年)"]: employee.passportExpiryDate ? dayjs(employee.passportExpiryDate).format("YYYY") : "",
        ["旅券有効期限(月)"]: employee.passportExpiryDate ? dayjs(employee.passportExpiryDate).format("MM") : "",
        ["旅券有効期限(日)"]: employee.passportExpiryDate ? dayjs(employee.passportExpiryDate).format("DD") : "",
        ["在留資格"]: employee.residenceStatus ? employee.residenceStatus.residenceStatus : "",
        ["在留期間"]: employee.residencePeriod && employee.residencePeriod.residencePeriod ? employee.residencePeriod.residencePeriod.toString() : "",
        ["在留期間の満了日(年)"]: employee.residenceExpiryDate ? dayjs(employee.residenceExpiryDate).format("YYYY") : "",
        ["在留期間の満了日(月)"]: employee.residenceExpiryDate ? dayjs(employee.residenceExpiryDate).format("MM") : "",
        ["在留期間の満了日(日)"]: employee.residenceExpiryDate ? dayjs(employee.residenceExpiryDate).format("DD") : "",
        ["在留カード番号"]: employee.residenceCode ? employee.residenceCode.toString() : "",
        ["特定産業分野1"]: employee.contractSpecificBusinessClassificationOne
          ? employee.contractSpecificBusinessClassificationOne.specificIndustry.companySpecificIndustry.companySpecificIndustry
          : "",
        ["業務区分1"]: employee.contractSpecificBusinessClassificationOne
          ? employee.contractSpecificBusinessClassificationOne.companySpecificBusinessClassification.companySpecificBusinessClassificationName
          : "",
        ["特定産業分野2"]: employee.contractSpecificBusinessClassificationTwo
          ? employee.contractSpecificBusinessClassificationTwo.specificIndustry.companySpecificIndustry.companySpecificIndustry
          : "",
        ["業務区分2"]: employee.contractSpecificBusinessClassificationTwo
          ? employee.contractSpecificBusinessClassificationTwo.companySpecificBusinessClassification.companySpecificBusinessClassificationName
          : "",
        ["特定産業分野3"]: employee.contractSpecificBusinessClassificationThree
          ? employee.contractSpecificBusinessClassificationThree.specificIndustry.companySpecificIndustry.companySpecificIndustry
          : "",
        ["業務区分3"]: employee.contractSpecificBusinessClassificationThree
          ? employee.contractSpecificBusinessClassificationThree.companySpecificBusinessClassification.companySpecificBusinessClassificationName
          : "",
        ["主たる職種"]: employee.contractMainOccupation ? employee.contractMainOccupation.mainOccupation.toString() : "",
        ["月額報酬（円）"]: employee.contractMonthlySalary ? employee.contractMonthlySalary.toString() : "",
        ["所定労働時間（週平均）"]: employee.contractCompanyBranch ? Math.floor(employee.contractCompanyBranch?.weeklyWorkingHours ?? 0) : "",
        ["所定労働時間（月平均）"]: employee.contractCompanyBranch ? Math.floor(employee.contractCompanyBranch?.monthWorkingHours ?? 0) : "",
        ["雇用契約期間　始期 "]: employee.contractStartDate ? dayjs(employee.contractStartDate).format(YEAR_MONTH_DAY_STR_FORMAT) : "",
        ["雇用契約期間　終期"]: employee.contractEndDate ? dayjs(employee.contractEndDate).format(YEAR_MONTH_DAY_STR_FORMAT) : "",
        ["在籍ステータス"]: employee.employmentStatus
          ? employee.retirementStatus != RETIREMENT_STATUS.ENROLLMENT
            ? findLabelByValue(retirementStatusOptions, employee.retirementStatus)
            : findLabelByValue(employmentStatusOptions, employee.employmentStatus)
          : "",
        ["入社日"]: employee.hiringDate ? dayjs(employee.hiringDate).format(YEAR_MONTH_DAY_STR_FORMAT) : "",
        ["退職日"]: employee.retirementDate ? dayjs(employee.retirementDate).format(YEAR_MONTH_DAY_STR_FORMAT) : "",
        ["備考"]: employee.note ? employee.note.toString() : "",
      }));
    },
    [genderOptions, employmentStatusOptions],
  );

  return { convertEmployeeBulkApplicationFormatToCsvData };
};
