import {
  TCreateRetirementAcceptingWorkerDocumentReqDto,
  TCreateRetirementAcceptingWorkerDocumentResDto,
} from "@/api/services/main/retirement-document/retirement-accepting-worker-document/dtos/create-retirement-accepting-worker-document.dto";
import { TGetRetirementAcceptingWorkerDocumentResDto } from "@/api/services/main/retirement-document/retirement-accepting-worker-document/dtos/get-retirement-accepting-worker-document.dto";
import {
  TUpdateRetirementAcceptingWorkerDocumentReqDto,
  TUpdateRetirementAcceptingWorkerDocumentResDto,
} from "@/api/services/main/retirement-document/retirement-accepting-worker-document/dtos/update-retirement-accepting-worker-document.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";

export const createRetirementAcceptingWorkerDocumentApi = async (
  documentStepId: number,
  payload: TCreateRetirementAcceptingWorkerDocumentReqDto,
): Promise<TCreateRetirementAcceptingWorkerDocumentResDto> => {
  const { data } = await clientApi.post<TCreateRetirementAcceptingWorkerDocumentResDto>(
    `/retirement-documents/retirement-accepting-worker/documents?documentStepId=${documentStepId}`,
    payload,
  );
  return Promise.resolve(data);
};

export const updateRetirementAcceptingWorkerDocumentApi = async (
  documentStepId: number,
  payload: TUpdateRetirementAcceptingWorkerDocumentReqDto,
): Promise<TUpdateRetirementAcceptingWorkerDocumentResDto> => {
  const rs = await clientApi.put<TUpdateRetirementAcceptingWorkerDocumentResDto>(
    `/retirement-documents/retirement-accepting-worker/documents/${documentStepId}`,
    payload,
  );
  return rs.data;
};

export const fetchRetirementAcceptingWorkerDocumentApi = async (documentStepId: number): Promise<TGetRetirementAcceptingWorkerDocumentResDto> => {
  const rs = await clientApi.get<TGetRetirementAcceptingWorkerDocumentResDto>(
    `/retirement-documents/retirement-accepting-worker/documents/${documentStepId}`,
  );
  return rs.data;
};

export const useRetirementAcceptingWorkerDocument = (documentStepId?: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_ACCEPTING_WORKER, documentStepId],
    queryFn: () => (documentStepId ? fetchRetirementAcceptingWorkerDocumentApi(documentStepId) : undefined),
    enabled: Boolean(documentStepId),
  });
};
