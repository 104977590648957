import { useEnum } from "@/api/services/global/enum";
import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { TGetEmployeeOutputDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { useGetRegionList } from "@/api/services/global/master";
import { InnerBox } from "@/components/elements/InnerBox";
import { Table } from "@/components/elements/Table";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { text } from "@/theme/colors";
import { spacing } from "@/theme/variables";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { getFullAddress, getFullName } from "@/utils/string";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useEffect, useMemo, useState } from "react";
import { RetirementEmployeeForm } from "@/features/retirement/components/retirement-document/documents/RetirementEmployeeForm";
import { TRetirementEmployeeForm } from "@/features/retirement/types/retirementEmployeeForm";
import { dayjs } from "@/utils/dayjs";

type TRetirementEmployeeDetailProps = {
  employeeId?: number | null;
  readonly?: boolean;
};

export const RetirementEmployeeDetail: FC<TRetirementEmployeeDetailProps> = ({ employeeId, readonly }) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TGetEmployeeOutputDto | undefined>(undefined);
  const [defaultValues, setDefaultValues] = useState<TRetirementEmployeeForm | undefined>(undefined);
  const handleFetchEmployeeInfo = async (retirementEmployeeId?: string) => {
    setIsDataLoading(true);
    const rs = await fetchEmployeeInfoApi(retirementEmployeeId ? retirementEmployeeId : employeeId || "");
    if (rs.data.retirementDate) setDefaultValues({ retirementDate: dayjs(rs.data.retirementDate) });
    setTableData(rs.data);
    setIsDataLoading(false);
  };

  useEffect(() => {
    handleFetchEmployeeInfo();
  }, [employeeId]);

  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeOutputDto>[]>(
    () => [
      {
        header: "氏名",
        size: 120,
        Cell: ({ row }) => {
          const { firstName, lastName, middleName } = row.original;
          const fullName = getFullName({ firstName, lastName, middleName });
          return fullName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "呼び名",
        size: 120,
        Cell: ({ row }) => {
          return row.original.nickName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "性別",
        size: 120,
        Cell: ({ row }) => {
          return findLabelByValue(genderOptions, row.original.gender) || EMPTY_STR.TEXT;
        },
      },
      {
        header: "生年月日",
        size: 120,
        Cell: ({ row }) => {
          return row.original.birthday || EMPTY_STR.DATE;
        },
      },
      {
        header: "国籍",
        size: 120,
        Cell: ({ row }) => findLabelByValue(nationalityOptions, row.original.nationalityId) || EMPTY_STR.TEXT,
      },
      {
        header: "在留カード番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.residenceCode || EMPTY_STR.TEXT;
        },
      },
      {
        header: "郵便番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.zipCode ? convertNumberToZipCode(row.original.zipCode) : EMPTY_STR.TEXT;
        },
      },
      {
        header: "住所",
        size: 120,
        Cell: ({ row }) => {
          const { province, address, municipality } = row.original;
          const fullAddress = getFullAddress({ province: province, municipality: municipality, address: address });
          return fullAddress || EMPTY_STR.TEXT;
        },
      },
      {
        header: "電話番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.telNumber || EMPTY_STR.TEXT;
        },
      },
      {
        header: "特定産業分野",
        size: 120,
        Cell: ({ row }) => {
          return row.original.contractSpecificBusinessClassificationOne?.specificIndustry.regularAnyTimeSpecificIndustry || EMPTY_STR.TEXT;
        },
      },
      {
        header: "業務区分",
        size: 120,
        Cell: ({ row }) => {
          return row.original.contractSpecificBusinessClassificationOne?.specificBusinessClassificationName || EMPTY_STR.TEXT;
        },
      },
    ],
    [genderOptions, nationalityOptions],
  );

  const { table } = useTable({ columns, rows: tableData ? [tableData] : [], pinningColumns: ["action"] });
  return (
    <InnerBox padding={`${spacing.lg} ${spacing.md}`}>
      <Stack gap={3}>
        <Stack flexDirection="row" gap={2}>
          <Typography color={text.accent} variant="head20Bold">
            手続き準備
          </Typography>
          <Typography variant="head20Bold">対象の特定技能外国人</Typography>
        </Stack>
        <Table table={table} isLoading={isDataLoading} />
        {!isDataLoading && <RetirementEmployeeForm defaultValues={defaultValues} retirementEmployeeId={employeeId} readonly={readonly} />}
      </Stack>
    </InnerBox>
  );
};
