import { TGetSupportPlanModificationDocumentDto } from "@/api/services/main/any-time/support-plan/dtos/get-support-plan-modification-document.dto";
import { TUpdateSupportPlanModificationDocumentReqDto } from "@/api/services/main/any-time/support-plan/dtos/update-support-plan-modification-document.dto";
import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { TSupportPlanForm } from "@/features/anytime/types/supportPlanForm";
import { SUPPORT_SPECIFIED_SKILL } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { pick } from "@/utils/object";

export const getInitValues = (company: TGetCompanyDto): Partial<TSupportPlanForm> => {
  return {
    employees: [],
    contractTermTarget: [],
    contractSpecifiedSkill: [SUPPORT_SPECIFIED_SKILL.SUPPORT_MANAGER_NAME_AND_POSITION],
    contractTermRegisteredSupportOrganization: [],
    contractContentOfSupport: [],
    companySupporterName: company?.supporters && company.supporters.length > 0 ? company.supporters[0].supporterName : null,
    companySupporterTelNumber: company?.supporters && company.supporters.length > 0 ? company.supporters[0].telNumber : null,
    companySupporterId: company?.supporters && company.supporters.length > 0 ? company.supporters[0].id : null,
    companyName: company.companyName ?? null,
    companyFuriganaName: company.companyFuriganaName ?? null,
    companySpecificIndustryId: company.specificIndustryId ?? null,
    companyIdentificationNumber: company.companyIdentificationNumber ?? null,
    companyZipCode: company.zipCode ?? null,
    companyProvince: company.province ?? null,
    companyAddress: company.fullAddress ?? null,
    companyTelNumber: company.telNumber ?? null,
  };
};

export const convertFormDataToReqDto = (data: TSupportPlanForm, isDraft: boolean): TUpdateSupportPlanModificationDocumentReqDto => {
  return {
    employees: data.employees ?? [],
    modifyContractDate: data.modifyContractDate ? dayjs(data.modifyContractDate).format(ISO_FORMAT) : null,
    contractTermTarget: data.contractTermTarget && data.contractTermTarget.length > 0 ? data.contractTermTarget : null,
    contractSpecifiedSkill: data.contractSpecifiedSkill && data.contractSpecifiedSkill.length > 0 ? data.contractSpecifiedSkill : null,
    contractTermOtherContent: data.contractTermOtherContent ?? null,
    contractTermRegisteredSupportOrganization:
      data.contractTermRegisteredSupportOrganization && data.contractTermRegisteredSupportOrganization.length > 0
        ? data.contractTermRegisteredSupportOrganization
        : null,
    contractContentOfSupport: data.contractContentOfSupport && data.contractContentOfSupport.length > 0 ? data.contractContentOfSupport : null,
    contractContentOfSupportOther: data.contractContentOfSupportOther ?? null,
    contractTermOther: data.contractTermOther ?? null,
    companySupporterId: data.companySupporterId ? toNumber(data.companySupporterId) : null,
    companySupporterName: data.companySupporterName ?? null,
    companySupporterTelNumber: data.companySupporterTelNumber ?? null,
    contentAfterChange: data.contentAfterChange ?? null,
    companyName: data.companyName ?? null,
    companyFuriganaName: data.companyFuriganaName ?? null,
    companySpecificIndustryId: data.companySpecificIndustryId ?? null,
    companyIdentificationNumber: data.companyIdentificationNumber ?? null,
    companyZipCode: data.companyZipCode ?? null,
    companyProvince: data.companyProvince ?? null,
    companyAddress: data.companyAddress ?? null,
    companyTelNumber: data.companyTelNumber ?? null,
    isDraft,
  };
};

export const convertResDtoToFormData = (data: TGetSupportPlanModificationDocumentDto): TSupportPlanForm => {
  return {
    ...data,
    employees: data.employees.map((employee) =>
      pick(
        employee,
        "employeeId",
        "employeeFullName",
        "employeeNickName",
        "employeeGender",
        "employeeNationalityId",
        "employeeResidenceCardNumber",
        "birthday",
        "zipCode",
        "fullAddress",
        "telNumber",
      ),
    ),
    modifyContractDate: data.modifyContractDate ? dayjs(data.modifyContractDate) : undefined,
    contractTermTarget: data.contractTermTarget ?? [],
    contractSpecifiedSkill: data.contractSpecifiedSkill ?? [],
    contractTermRegisteredSupportOrganization: data.contractTermRegisteredSupportOrganization ?? [],
    contractContentOfSupport: data.contractContentOfSupport ?? [],
  } as TSupportPlanForm;
};
