import { createGovernmentOnlineSystemAccountApi, updateGovernmentOnlineSystemAccountApi } from "@/api/services/main/company";
import { Button } from "@/components/elements/Button";
import { DropZone } from "@/components/elements/DropZone";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { TypoLink } from "@/components/elements/TypoLink";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { companyAccountSchema } from "@/features/company/schema/companyAccountSchema";
import { TCompanyAccountInfoForm } from "@/features/company/types/CompanyAccountInfoForm";
import { convertCompanyAccountFormDataToReqDto } from "@/features/company/utils/convertFormDataToReqDto";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { FCC } from "@/types/common";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TCompanyAccountFormProps = {
  previousValues?: TCompanyAccountInfoForm;
};

export const CompanyAccountForm: FCC<TCompanyAccountFormProps> = ({ previousValues }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const methods = useForm<TCompanyAccountInfoForm>({
    defaultValues: { ...previousValues, accordImmiPassword: "" },
    resolver: yupResolver(companyAccountSchema(previousValues?.accordImmiPassword ?? "")),
    mode: "onBlur",
  });
  const { control } = methods;

  const onSubmit = async (data: TCompanyAccountInfoForm) => {
    try {
      const payload = convertCompanyAccountFormDataToReqDto(data);
      const apiFunc = previousValues ? updateGovernmentOnlineSystemAccountApi : createGovernmentOnlineSystemAccountApi;
      const rs = await apiFunc(payload);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_ACCOUNT_DETAIL] });
      navigate(AppRoutes.companyAccount);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={companyAccountSchema(previousValues?.accordImmiPassword ?? "")}>
      <Stack gap={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="head20Semi" fontWeight={100} color={text.primary}>
            オンライン申請のアカウント情報編集
          </Typography>
          <Button type="submit" variant="primary" size="sm">
            保存
          </Button>
        </Stack>
        <InnerBox padding="32px">
          <Stack gap={3}>
            <Stack gap={3} paddingBottom={2} borderBottom="1px solid #E5E5E5">
              <Stack gap={2}>
                <Typography variant="body14Bold">出入国在留管理庁電子届出システムの利用者情報</Typography>
                <Stack>
                  <Typography variant="body14">
                    出入国在留管理庁の電子届出システムに利用者登録した際のIDとパスワードを入力ください。これらは電子届出の提出に必須の項目です。
                  </Typography>
                  <Stack flexDirection="row" alignItems="center">
                    <TypoLink variant="link14Semi" target="blank" to={"https://www.accord-immi.moj.go.jp/accord-u/profile/userLogin"}>
                      電子届出システムへのログインはこちら
                    </TypoLink>
                  </Stack>
                  <Typography variant="body14" color={"red"}>
                    必ず電子届出システムでログインできることをご確認してから入力ください。
                  </Typography>
                </Stack>
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Stack gap="4px">
                    <FormField control={control} name="accordImmiId">
                      <Input />
                    </FormField>
                    <Typography variant="cap12" style={{ color: text.secondary }}>
                      半角英字・半角数字・半角記号により6文字以上20文字以内、入力可能な記号は $%&=@_#*+-?!) です。
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack gap="4px">
                    <FormField control={control} name="accordImmiPassword">
                      <Input />
                    </FormField>
                    <Typography variant="cap12" style={{ color: text.secondary }}>
                      半角英字・半角数字・半角記号により8文字以上32文字以内、入力可能な記号は $%&=@_#*+-?!) です。
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={3}>
                  <FormField control={control} name="accordImmiFile">
                    <DropZone
                      uploadFor={FILE_UPLOAD_FOR.COMPANY_ACCOUNT_RAS_IMMI_FILE}
                      options={{ multiple: false }}
                      rules={{ acceptMimeTypes: [...MIME_TYPES.WORD, ...MIME_TYPES.EXCEL, ...MIME_TYPES.PDF] }}
                      bordered={false}
                    >
                      <Button size="md" fullWidth>
                        アップロード
                      </Button>
                    </DropZone>
                  </FormField>
                </Grid>
              </Grid>
            </Stack>
            <Stack gap={3} paddingBottom={2}>
              <Stack gap={2}>
                <Typography variant="body14Bold">在留申請オンラインシステムの利用者情報</Typography>
                <Stack>
                  <Typography variant="body14">
                    出入国在留管理庁の在留申請オンラインシステムに利用者登録した際の登録情報です。備忘録としてご利用ください。
                  </Typography>
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <TypoLink variant="link14Semi" target="blank" to={"https://www.ras-immi.moj.go.jp/WC01/WCAAS010/"}>
                      在留申請オンラインシステムへのログインはこちら
                    </TypoLink>
                    <TypoLink variant="link14Semi" target="blank" to={"https://www.iro-hana.com/fc88d3b289dc45178b38cf6141e558b4?pvs=4"}>
                      利用登録をされる方はこちら
                    </TypoLink>
                  </Stack>
                </Stack>
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormField control={control} name="rasImmiFullName">
                    <Input />
                  </FormField>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                  <Stack gap="4px">
                    <FormField control={control} name="rasImmiId">
                      <Input />
                    </FormField>
                    <Typography variant="cap12" style={{ color: text.secondary }}>
                      システムから発行された認証IDを入力ください。
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={8} />
              </Grid>
            </Stack>
          </Stack>
        </InnerBox>
      </Stack>
    </Form>
  );
};
