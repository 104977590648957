import { TGetEmploymentContractModificationDocumentDto } from "@/api/services/main/any-time/employment-contract/dtos/get-employment-contract-modification-document.dto";
import { TUpdateEmploymentContractModificationDocumentReqDto } from "@/api/services/main/any-time/employment-contract/dtos/update-employment-contract-modification-document.dto";
import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import {
  EEmploymentContractModificationType,
  EModifyContractTerminateReason,
  EModifyContractTerminateReasonForCompany,
  EModifyContractTerminateReasonForEmployee,
} from "@/features/anytime/constants/enums";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { pick } from "@/utils/object";

const intiForm = {
  modifyContractDate: null,
  modifyContractModificationReasons: null,
  modifyContractChangeDispatchLocationReasons: null,
  modifyContractContent: null,

  terminateContractDate: null,
  terminateContractReason: null,
  terminateContractReasonByCompany: null,
  terminateContractOtherByCompany: null,
  terminateContractReasonByEmployee: null,
  terminateContractOtherReasonByEmployee: null,

  renewContractDate: null,
  renewContractContent: null,
};

export const getInitValues = (company: TGetCompanyDto) => {
  return {
    employees: [],
    ...intiForm,
    companySupporterName: company?.supporters && company.supporters.length > 0 ? company.supporters[0].supporterName : null,
    companySupporterTelNumber: company?.supporters && company.supporters.length > 0 ? company.supporters[0].telNumber : null,
    companySupporterId: company?.supporters && company.supporters.length > 0 ? company.supporters[0].id : null,
    companyName: company.companyName ?? null,
    companyFuriganaName: company.companyFuriganaName ?? null,
    companySpecificIndustryId: company.specificIndustryId ?? null,
    companyIdentificationNumber: company.companyIdentificationNumber ?? null,
    companyZipCode: company.zipCode ?? null,
    companyProvince: company.province ?? null,
    companyAddress: company.fullAddress ?? null,
    companyTelNumber: company.telNumber ?? null,
  };
};

export const convertFormDataToReqDto = (data: TEmploymentContractForm, isDraft: boolean): TUpdateEmploymentContractModificationDocumentReqDto => {
  const modifyContract =
    data.modificationType === EEmploymentContractModificationType.MODIFY_CONTRACT
      ? {
          modifyContractDate: data.modifyContractDate ? data.modifyContractDate.format(ISO_FORMAT) : null,
          modifyContractModificationReasons: data.modifyContractModificationReasons,
          modifyContractChangeDispatchLocationReasons: data.modifyContractChangeDispatchLocationReasons,
          modifyContractContent: data.modifyContractContent,
        }
      : {};

  const terminateContract =
    data.modificationType === EEmploymentContractModificationType.TERMINATE_CONTRACT
      ? {
          terminateContractDate: data.terminateContractDate ? data.terminateContractDate.format(ISO_FORMAT) : null,
          terminateContractReason: data.terminateContractReason,
          terminateContractReasonByCompany:
            data.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY ? data.terminateContractReasonByCompany : null,
          terminateContractOtherByCompany:
            data.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY &&
            data.terminateContractReasonByCompany === EModifyContractTerminateReasonForCompany.OTHER
              ? data.terminateContractOtherByCompany
              : null,
          terminateContractReasonByEmployee:
            data.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE ? data.terminateContractReasonByEmployee : null,
          terminateContractOtherReasonByEmployee:
            data.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE &&
            data.terminateContractReasonByEmployee === EModifyContractTerminateReasonForEmployee.OTHER
              ? data.terminateContractOtherReasonByEmployee
              : null,
        }
      : {};

  const renewContract =
    data.modificationType === EEmploymentContractModificationType.RENEW_CONTRACT
      ? {
          renewContractDate: data.renewContractDate ? data.renewContractDate.format(ISO_FORMAT) : null,
          renewContractContent: data.renewContractContent,
        }
      : {};

  return {
    employees: data.employees,
    modificationType: data.modificationType,
    ...intiForm,
    ...modifyContract,
    ...renewContract,
    ...terminateContract,
    companySupporterId: data.companySupporterId ? toNumber(data.companySupporterId) : null,
    companySupporterName: data.companySupporterName ?? null,
    companySupporterTelNumber: data.companySupporterTelNumber ?? null,
    companyName: data.companyName ?? null,
    companyFuriganaName: data.companyFuriganaName ?? null,
    companySpecificIndustryId: data.companySpecificIndustryId ?? null,
    companyIdentificationNumber: data.companyIdentificationNumber ?? null,
    companyZipCode: data.companyZipCode ?? null,
    companyProvince: data.companyProvince ?? null,
    companyAddress: data.companyAddress ?? null,
    companyTelNumber: data.companyTelNumber ?? null,
    isDraft,
  };
};

export const convertResDtoToFormData = (data: TGetEmploymentContractModificationDocumentDto): TEmploymentContractForm => {
  return {
    ...data,
    employees: data.employees.map((employee) =>
      pick(
        employee,
        "employeeId",
        "employeeFullName",
        "employeeNickName",
        "employeeGender",
        "employeeNationalityId",
        "employeeResidenceCardNumber",
        "birthday",
        "zipCode",
        "fullAddress",
        "telNumber",
        "specificBusinessClassificationName",
        "specificIndustryName",
      ),
    ),
    modifyContractDate: data.modifyContractDate ? dayjs(data.modifyContractDate) : null,
    terminateContractDate: data.terminateContractDate ? dayjs(data.terminateContractDate) : null,
    renewContractDate: data.renewContractDate ? dayjs(data.renewContractDate) : null,
  };
};
