import { TFinishSubmitDocumentResDto } from "@/api/services/main/regular-document/dtos/finish-submit-document.dto";
import { TFinishUploadDocumentStepResDto } from "@/api/services/main/regular-document/dtos/finish-upload-document-step.dto";
import { TGetPresentRegularDocumentResDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import {
  TGetPreviousDocumentListReqDto,
  TGetPreviousDocumentListResDto,
} from "@/api/services/main/regular-document/dtos/get-previous-document-list.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";

export const fetchPresentDocumentListApi = async (): Promise<TGetPresentRegularDocumentResDto> => {
  const rs = await clientApi.get<TGetPresentRegularDocumentResDto>("/regular-documents/present");
  return rs.data;
};

export const usePresentDocumentList = (options = { enabled: true }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT],
    queryFn: () => fetchPresentDocumentListApi(),
    enabled: options.enabled,
  });
};

export const setCompleteRegularDocumentApi = () => {
  return clientApi.post("/regular-documents/present/set-complete");
};

export const fetchPreviousDocumentListApi = async (req: TGetPreviousDocumentListReqDto): Promise<TGetPreviousDocumentListResDto> => {
  const url = createUrlWithParams("/regular-documents/previous-documents", req);
  const rs = await clientApi.get<TGetPreviousDocumentListResDto>(url);

  return rs.data;
};

export const usePreviousDocumentList = () => {
  const pagReq = usePagination();
  pagReq.perPage = pagReq.perPage ?? 20;
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PREVIOUS, pagReq],
    // queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PREVIOUS],
    queryFn: () => fetchPreviousDocumentListApi(pagReq),
    // queryFn: () => fetchPreviousDocumentListApi({}),
  });
};

export const fetchRegularDocumentHistoryDetailApi = async (
  year: string | number,
  quarter: string | number,
): Promise<TGetPresentRegularDocumentResDto> => {
  const rs = await clientApi.get<TGetPresentRegularDocumentResDto>(`/regular-documents/history/${year}/${quarter}`);
  return rs.data;
};

export const useRegularDocumentHistoryDetail = (year?: string | number, quarter?: string | number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_HISTORY_DETAIL, year, quarter],
    queryFn: year && quarter ? () => fetchRegularDocumentHistoryDetailApi(year, quarter) : undefined,
    enabled: Boolean(year && quarter),
  });
};

export const finishSubmitDocumentApi = async (documentStepId: number | string): Promise<TFinishSubmitDocumentResDto> => {
  const rs = await clientApi.post<TFinishSubmitDocumentResDto>("/regular-documents/finish-submit-document-step", {
    documentStepId: Number(documentStepId),
  });
  return rs.data;
};

export const finishUploadDocumentStepApi = async (documentStepId: number | string): Promise<TFinishUploadDocumentStepResDto> => {
  const rs = await clientApi.post<TFinishUploadDocumentStepResDto>("/regular-documents/finish-upload-document-step", {
    documentStepId: Number(documentStepId),
  });
  return rs.data;
};

export const skipPresentDocumentApi = async () => {
  return clientApi.post("/regular-documents/present/skip");
};
