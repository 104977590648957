import { InnerBox } from "@/components/elements/InnerBox";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { SpecificSkillTerminationForm } from "@/features/retirement/components/retirement-detail/employment-contract/forms/SpecificSkillTerminationForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TTargetForeignerProps = {
  readonly?: boolean;
};

export const TargetForeigner: FC<TTargetForeignerProps> = ({ readonly }) => {
  return (
    <Stack gap={2}>
      <Typography variant="head20Semi">届出の事由</Typography>
      <InnerBox title="事由" required bordered p={3}>
        <Stack gap={3}>
          <RadioGroup
            value={"2"}
            options={[
              { label: "特定技能雇用契約の変更", value: "1", disabled: true },
              { label: "特定技能雇用契約の終了", value: "2", disabled: readonly },
              { label: "新たな特定技能雇用契約の締結", value: "3", disabled: true },
            ]}
          />
        </Stack>
      </InnerBox>
      <SpecificSkillTerminationForm readonly={readonly} />
    </Stack>
  );
};
