import { IconCheck } from "@/assets/icons";
import { background, text } from "@/theme/colors";
import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";

type TProgressStepProps = {
  isInProgessCompanyApplication?: boolean;
};

export const ProgressStep: FC<TProgressStepProps> = ({ isInProgessCompanyApplication = false }) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap="8px">
      <Stack alignItems="center" gap="8px">
        <Stack justifyContent="center" alignItems="center" borderRadius={999} bgcolor={background.main} width={40} height={40} color={text.white}>
          {isInProgessCompanyApplication ? <IconCheck width={20} height={20} /> : <Typography variant="sub16Semi">1</Typography>}
        </Stack>
        <Typography variant="sub16Semi">外国人情報</Typography>
      </Stack>
      <Box width={162} height={2} mb={4} bgcolor={background.main}></Box>
      <Stack alignItems="center" gap="8px">
        <Stack
          justifyContent="center"
          alignItems="center"
          borderRadius={999}
          bgcolor={isInProgessCompanyApplication ? background.main : undefined}
          sx={!isInProgessCompanyApplication ? { border: `2px solid ${text.primary}` } : undefined}
          width={40}
          height={40}
          color={isInProgessCompanyApplication ? text.white : text.primary}
        >
          <Typography variant="sub16Semi">2</Typography>
        </Stack>
        <Typography variant="sub16Semi">企業情報</Typography>
      </Stack>
    </Stack>
  );
};
