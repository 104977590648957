import { TRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { AcceptanceActivityCreateStep } from "@/features/regular-document/components/documents/acceptance-activity/AcceptanceActivityCreateStep";
import { AcceptanceActivitySubmitStep } from "@/features/regular-document/components/documents/acceptance-activity/AcceptanceActivitySubmitStep";
import { AcceptanceActivityUploadStep } from "@/features/regular-document/components/documents/acceptance-activity/AcceptanceActivityUploadStep";
import { DocumentCard } from "@/components/elements/DocumentCard";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { FC, useMemo } from "react";

type TDocumentProps = {
  document: TRegularDocumentDto;
  readonly?: boolean;
};

export const AcceptanceActivityDocument: FC<TDocumentProps> = ({ document, readonly }) => {
  const renderAcceptanceActivityCreateStep = useMemo(() => {
    const stepData = findDocumentStep(document, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT);

    if (!stepData) return null;
    return <AcceptanceActivityCreateStep stepData={stepData} readonly={readonly} documentType={document.masterDocument.documentType} />;
  }, [readonly, document]);

  const renderAcceptanceActivityUploadStep = useMemo(() => {
    const stepData = findDocumentStep(document, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT);

    if (!stepData) return null;
    return <AcceptanceActivityUploadStep stepData={stepData} readonly={readonly} />;
  }, [readonly, document]);

  const renderAcceptanceActivitySubmitStep = useMemo(() => {
    const stepData = findDocumentStep(document, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_SUBMIT);

    if (!stepData) return null;
    return <AcceptanceActivitySubmitStep stepData={stepData} readonly={readonly} document={document} />;
  }, [readonly, document]);

  return (
    <DocumentCard title={`届出書類${document.masterDocument.documentOrder}`} subTitle={document.masterDocument.documentName}>
      {renderAcceptanceActivityCreateStep}
      {renderAcceptanceActivityUploadStep}
      {renderAcceptanceActivitySubmitStep}
    </DocumentCard>
  );
};
