import { useCompanyBranchList } from "@/api/services/main/company";
import { TGetCompanyBranchListDto } from "@/api/services/main/company/dtos/get-company-branch-list.dto";
import { Button } from "@/components/elements/Button";
import { Table } from "@/components/elements/Table";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { convertNumberToZipCode } from "@/utils/input";
import { getFullAddress, truncateWithEllipsis } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export const CompanyBranchList = () => {
  const { data, isLoading } = useCompanyBranchList();

  const renderCompanies = useMemo(() => {
    if (!data) return null;
    data.data.sort((a, b) => (b.isHeadquarters ? 1 : 0) - (a.isHeadquarters ? 1 : 0));
    return data;
  }, [data]);

  const columns = useMemo<MRT_ColumnDef<TGetCompanyBranchListDto>[]>(
    () => [
      {
        header: "事業所名",
        Cell: ({ row }) =>
          row.original.isHeadquarters
            ? `${truncateWithEllipsis(row.original.branchName, 30)} （本店）` || EMPTY_STR.TEXT
            : truncateWithEllipsis(row.original.branchName, 30) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "郵便番号",
        Cell: ({ row }) => (row.original.zipCode ? convertNumberToZipCode(row.original.zipCode) : EMPTY_STR.TEXT),
        size: 120,
      },
      {
        header: "所在地",
        Cell: ({ row }) => {
          const { province, address, municipality } = row.original;
          return <Typography variant="body14">{getFullAddress({ province: province, municipality: municipality, address: address })}</Typography>;
        },
        size: 120,
      },
      {
        header: "電話番号",
        Cell: ({ row }) => row.original.telNumber || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "編集",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button variant="outline" size="sm" component={Link} to={getDynamicRoute(AppRoutes.companyBranchEdit, { id })}>
              編集
            </Button>
          );
        },
        size: 120,
      },
    ],
    [data],
  );

  const { table } = useTable({ columns, rows: renderCompanies?.data ?? [] });

  return <Table table={table} isLoading={isLoading} />;
};
