import { useEnum } from "@/api/services/global/enum";
import { CheckboxGroup } from "@/components/elements/CheckboxGroup";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TLegalProtectionStatusProps = {
  readonly?: boolean;
};

export const LegalProtectionStatus: FC<TLegalProtectionStatusProps> = ({ readonly }) => {
  const methods = useFormContext<TRetirementAcceptingWorkerForm>();
  const { control } = methods;
  const { data: providedLegalProtectionOptions } = useEnum("EProvidedLegalProtection");

  return (
    <Stack gap={2}>
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Typography variant="head20Semi">法的保護のための案内実施の有無</Typography>
      </Stack>
      <InnerBox>
        <Stack paddingX={2} paddingY={1} gap={3}>
          <FormField control={control} name="providedLegalProtection">
            <CheckboxGroup rowGap={3} options={providedLegalProtectionOptions} disabled={readonly} />
          </FormField>
        </Stack>
      </InnerBox>
    </Stack>
  );
};
