export const QUERY_KEYS = {
  GLOBAL: {
    FETCH_ENUM: "GLOBAL_FETCH_ENUM",
    MASTER_REGION: "MASTER_REGION",
    MASTER_LIVING_RELATIONSHIP: "MASTER_LIVING_RELATIONSHIP",
    MASTER_MAIN_OCCUPATION: "MASTER_MAIN_OCCUPATION",
    MASTER_MAIN_INDUSTRY: "MASTER_MAIN_INDUSTRY",
    MASTER_LOCAL_GOVERNMENT_COUNTER: "MASTER_LOCAL_GOVERNMENT_COUNTER",
    MASTER_LANGUAGE_TEST: "MASTER_LANGUAGE_TEST",
    MASTER_SKILL_TEST: "MASTER_SKILL_TEST",
    MASTER_SPECIFIC_INDUSTRY: "MASTER_SPECIFIC_INDUSTRY",
    MASTER_MAJOR: "MASTER_MAJOR",
    MASTER_COMPANY_SPECIFIC_INDUSTRY: "MASTER_COMPANY_SPECIFIC_INDUSTRY",
    MASTER_RESIDENCE_STATUS: "MASTER_RESIDENCE_STATUS",
    FETCH_MAINTENANCE: "GLOBAL_FETCH_MAINTENANCE",
    PROVINCES_MUNICIPALITIES: "PROVINCES_MUNICIPALITIES",
  },
  COMPANY: {
    FETCH_LIST: "COMPANY_FETCH_LIST",
    FETCH_DETAIL: "COMPANY_FETCH_DETAIL",
    FETCH_USER_LIST: "COMPANY_FETCH_USER_LIST",
    FETCH_USER_DETAIL: "COMPANY_FETCH_USER_DETAIL",
    FETCH_BRANCH_LIST: "COMPANY_FETCH_BRANCH_LIST",
    FETCH_BRANCH_DETAIL: "COMPANY_FETCH_BRANCH_DETAIL",
    FETCH_ACCOUNT_DETAIL: "COMPANY_FETCH_ACCOUNT_DETAIL",
  },
  ADMIN_USER: {
    FETCH_LIST: "ADMIN_USER_FETCH_LIST",
  },
  COMPANY_NAME: {
    FETCH_LIST_BY_EMAIL: "COMPANY_NAME_FETCH_LIST_BY_EMAIL",
  },
  FILE: {
    FETCH_DETAIL: "FILE_FETCH_DETAIL",
    FETCH_INFO: "FILE_FETCH_INFO",
    FETCH_SIGNED_URL: "FILE_FETCH_SIGNED_URL",
  },
  EMPLOYEE: {
    FETCH_LIST: "EMPLOYEE_FETCH_LIST",
    FETCH_DETAIL: "EMPLOYEE_FETCh_DETAIL",
  },
  EMPLOYEE_RETIREMENT: {
    FETCH_LIST: "EMPLOYEE_RETIREMENT_FETCH_LIST",
  },
  REGULAR_DOCUMENTS: {
    FETCH_PREVIOUS: "REGULAR_DOCUMENTS_FETCH_PREVIOUS",
    FETCH_PRESENT: "REGULAR_DOCUMENTS_FETCH_PRESENT",
    FETCH_ACCEPTANCE_ACTIVITY_DOCUMENT: "REGULAR_DOCUMENTS_FETCH_ACCEPTANCE_ACTIVITY_DOCUMENT",
    FETCH_ACCEPTANCE_ACTIVITY_FILE: "REGULAR_DOCUMENTS_FETCH_ACCEPTANCE_ACTIVITY_FILE",
    FETCH_EMPLOYEE_PAYROLL_LIST: "REGULAR_DOCUMENTS_FETCH_EMPLOYEE_PAYROLL_LIST",
    FETCH_EMPLOYEE_PAYROLL_FILE_LIST: "REGULAR_DOCUMENTS_FETCH_EMPLOYEE_PAYROLL_FILE_LIST",
    FETCH_LIFE_SUPPORT_DOCUMENT: "FETCH_LIFE_SUPPORT_DOCUMENT",
    FETCH_HISTORY_DETAIL: "REGULAR_DOCUMENTS_FETCH_HISTORY_DETAIL",
  },
  RENEW_VISA_DOCUMENTS: {
    FETCH_LIST: "RENEW_VISA_DOCUMENTS_FETCH_LIST",
    FETCH_HISTORY_LIST: "RENEW_VISA_DOCUMENTS_FETCH_HISTORY_LIST",
    FETCH_DETAIL: "RENEW_VISA_DOCUMENTS_FETCH_DETAIL",
    FETCH_LATEST_DETAIL: "RENEW_VISA_DOCUMENTS_FETCH_LATEST_DETAIL",
    FETCH_EMPLOYEE_APPLICATION: "RENEW_VISA_DOCUMENTS_FETCH_EMPLOYEE_APPLICATION",
    FETCH_COMPANY_APPLICATION: "RENEW_VISA_DOCUMENTS_FETCH_COMPANY_APPLICATION",
    FETCH_BASIC_INFORMATION: "RENEW_VISA_DOCUMENTS_FETCH_BASIC_INFORMATION",
    FETCH_UPLOAD_FILE: "RENEW_VISA_DOCUMENTS_FETCH_UPLOAD_FILE",
    FETCH_UPLOAD_AVATAR_FILE: "RENEW_VISA_DOCUMENTS_FETCH_UPLOAD_AVATAR_FILE",
    FETCH_APPLICATION_INFORMATION: "RENEW_VISA_DOCUMENTS_FETCH_APPLICATION_INFORMATION",
    FETCH_RESIDENCE_CARD_INFORMATION: "RENEW_VISA_DOCUMENTS_FETCH_RESIDENCE_CARD_INFORMATION",
  },
  USER: {
    FETCH_ME: "USER_FETCH_ME",
  },
  INFORMATION: {
    FETCH_LIST: "INFORMATION_FETCH_LIST",
    FETCH_DETAIL: "INFORMATION_FETCH_DETAIL",
    CHECK_UNREAD: "INFORMATION_CHECK_UNREAD",
  },
  ANYTIME_DOCUMENTS: {
    FETCH_LIST: "ANYTIME_DOCUMENTS_FETCH_LIST",
    FETCH_DOCUMENT: "ANYTIME_DOCUMENTS_FETCH_DOCUMENT",
    FETCH_EMPLOYMENT_CONTRACT: "ANYTIME_DOCUMENTS_FETCH_EMPLOYMENT_CONTRACT",
    FETCH_SUPPORT_CONTRACT: "ANYTIME_DOCUMENTS_FETCH_SUPPORT_CONTRACT",
    FETCH_SUPPORT_PLAN: "ANYTIME_DOCUMENTS_FETCH_SUPPORT_PLAN",
    FETCH_FILE_LIST: "ANYTIME_DOCUMENTS_FETCH_FILE_LIST",
  },
  CONTACT_TYPE: {
    FETCH_LIST: "CONTACT_TYPE_FETCH_LIST",
  },
  CONVERSATION: {
    FETCH_LIST: "CONVERSATION_FETCH_LIST",
    FETCH_DETAIL: "CONVERSATION_FETCH_DETAIL",
    FETCH_MESSAGE_LIST: "CONVERSATION_FETCH_MESSAGE_LIST",
  },
  RETIREMENT_DOCUMENTS: {
    FETCH_LIST: "RETIREMENT_DOCUMENT_FETCH_LIST",
    FETCH_DOCUMENT: "RETIREMENT_DOCUMENT_FETCH_DOCUMENT",
    FETCH_ACCEPTING_WORKER: "RETIREMENT_DOCUMENTS_FETCH_ACCEPTING_WORKER",
    FETCH_EMPLOYEE_CONTRACT: "RETIREMENT_DOCUMENTS_FETCH_EMPLOYEE__CONTRACT",
    FETCH_FILE_LIST: "RETIREMENT_DOCUMENTS_FETCH_FILE_LIST",
  },
} as const;
