import { TEmployeeRenewVisaDocument } from "@/api/entities/employee-renew-visa-document.entity";
import { useEnum } from "@/api/services/global/enum";
import { updateExpectSubmitDateApi } from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { StatusTag } from "@/components/elements/StatusTag";
import { TableSorting } from "@/components/elements/Table";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { DATE_FORMAT, ISO_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { findLabelByValue } from "@/utils/object";
import { getFullName, truncateWithEllipsis } from "@/utils/string";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

type UseMakeRenewVisaColumnsReturn = MRT_ColumnDef<TEmployeeRenewVisaDocument>[];

export const useMakeRenewVisaColumns = (): UseMakeRenewVisaColumnsReturn => {
  const queryClient = useQueryClient();

  const { data: renewVisaDocumentStatuses } = useEnum("ERenewVisaDocumentStatus");

  const handleChangeExpectSubmitDate = async (renewVisaDocumentId: number, day: Dayjs) => {
    try {
      const rs = await updateExpectSubmitDateApi(renewVisaDocumentId, { expectSubmitDate: day.format(ISO_FORMAT) });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_LIST] });
    } catch (error) {
      showError(error);
    }
  };

  const columns = useMemo<MRT_ColumnDef<TEmployeeRenewVisaDocument>[]>(
    () => [
      {
        header: "外国人名",
        Header: <TableSorting name="employee.fullName">外国人名</TableSorting>,
        Cell: ({ row }) => {
          const { employee } = row.original;
          const fullName = getFullName({ firstName: employee.firstName, lastName: employee.lastName, middleName: employee.middleName });
          return <Typography>{truncateWithEllipsis(fullName, 26)}</Typography>;
        },
        muiTableBodyCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset", maxWidth: "200px" },
        },
      },
      {
        header: "呼び名",
        Header: <TableSorting name="employee.nickName">呼び名</TableSorting>,
        Cell: ({ row }) => row.original.employee.nickName || EMPTY_STR.TEXT,
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "申請予定日",
        Header: <TableSorting name="renewVisaDocument.expectSubmitDate">申請予定日</TableSorting>,
        Cell: ({ row }) => (
          <InputDatePicker
            disabled={row.original.status === DOCUMENT_STATUS.COMPLETE}
            value={dayjs(row.original.expectSubmitDate)}
            onChange={(value) => value && !value.isSame(dayjs(row.original.expectSubmitDate)) && handleChangeExpectSubmitDate(row.original.id, value)}
          />
        ),
        size: 50,
        muiTableBodyCellProps: {
          sx: { width: "17%" },
        },
      },
      {
        header: "在留カード更新期限",
        Header: <TableSorting name="renewVisaDocument.residenceExpiryDate">在留カード更新期限</TableSorting>,
        Cell: ({ row }) => (row.original.residenceExpiryDate ? dayjs(row.original.residenceExpiryDate).format(DATE_FORMAT) : EMPTY_STR.TEXT),
        size: 50,
        muiTableBodyCellProps: {
          sx: { minWidth: "120px", maxWidth: "150px" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "120px", maxWidth: "150px" },
        },
      },
      {
        header: "書類作成状況",
        Header: <TableSorting name="renewVisaDocument.status">書類作成状況</TableSorting>,
        Cell: ({ row }) => {
          const { status } = row.original;
          if (!status) return EMPTY_STR.TEXT;
          return <StatusTag status={status} variant="bold" title={findLabelByValue(renewVisaDocumentStatuses, status) || EMPTY_STR.TEXT} />;
        },
        size: 50,
        muiTableBodyCellProps: {
          sx: { width: "10%" },
        },
      },
      {
        header: "編集",
        Cell: ({ row }) => {
          const { id, status } = row.original;
          return (
            <Button
              variant="outline"
              size="sm"
              component={Link}
              to={
                status === DOCUMENT_STATUS.COMPLETE
                  ? getDynamicRoute(AppRoutes.renewVisaInfoHistoryDetail, { renewVisaDocumentId: id })
                  : getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId: id })
              }
            >
              {status === DOCUMENT_STATUS.COMPLETE ? "確認" : "編集"}
            </Button>
          );
        },
        size: 50,
        muiTableBodyCellProps: {
          sx: { width: "10%" },
        },
      },
    ],
    [renewVisaDocumentStatuses],
  );

  return columns;
};
