import { useInformationList } from "@/api/services/main/information";
import { FileItem } from "@/components/elements/FileItem";
import { InfiniteScroll } from "@/components/elements/InfiniteScroll";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { QuoteScope } from "@/components/elements/QuoteScope";
import { text } from "@/theme/colors";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export const TopAlert = () => {
  const {
    dataFormatter: informationListData,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInformationList({
    types: "important",
  });

  if (!informationListData || isLoading) return <LoadingArea height={50} />;

  return (
    <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage}>
      <Stack gap={1}>
        {informationListData.map((item) => {
          return (
            <QuoteScope key={item.id} variant="danger">
              <Stack gap={1.25}>
                <Typography color={text.primary} variant="sub16Bold">
                  {item.title}
                </Typography>
                <Typography variant="body14">{item.content && <Box dangerouslySetInnerHTML={{ __html: item.content }} />}</Typography>
                {item.filePath && <FileItem filePath={item.filePath} downloadable />}
              </Stack>
            </QuoteScope>
          );
        })}
      </Stack>
    </InfiniteScroll>
  );
};
