import { useAnytimeDocument } from "@/api/services/main/any-time";
import { TopPage } from "@/components/elements/TopPage";
import { AnytimeFilesTable } from "@/features/anytime/components/anytime-files/AnytimeFilesTable";
import { AnytimeFilesTop } from "@/features/anytime/components/anytime-files/AnytimeFilesTop";
import { EAnyTimeDocumentType } from "@/features/anytime/constants/enums";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Box, Stack, styled } from "@mui/material";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export const AnytimeFilesPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const readonly = searchParams.get("readonly") === "true";
  const { data: anytimeResult } = useAnytimeDocument();
  const anytimeData = anytimeResult?.data;
  const anytimeDocumentTitle = useMemo(() => {
    switch (anytimeData?.anyTimeDocumentType) {
      case EAnyTimeDocumentType.EMPLOYMENT_CONTRACT_MODIFICATION:
        return "雇用契約の変更に係る届出の添付ファイル一覧";
      case EAnyTimeDocumentType.SUPPORT_PLAN_MODIFICATION:
        return "支援計画変更に係る届出の添付ファイル一覧";
      case EAnyTimeDocumentType.SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION:
        return "支援委託契約に係る届出の添付ファイル一覧";
      default:
        return "";
    }
  }, [anytimeData]);

  return (
    <Box>
      <TopPage
        title={anytimeDocumentTitle}
        description={`提出に必要な添付ファイルをアップロードしてください。\n提出形式：PDF`}
        backUrl={getDynamicRoute(AppRoutes.anytimeDocument, params)}
      />
      <AnytimeFilesWrapper gap={3}>
        <AnytimeFilesTop readonly={readonly} />
        <AnytimeFilesTable readonly={readonly} />
      </AnytimeFilesWrapper>
    </Box>
  );
};

const AnytimeFilesWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
`;
