import { updateEmployeeInfoApi } from "@/api/services/global/employee";
import { Button } from "@/components/elements/Button";
import { UploadAvatarDropZone } from "@/features/employee/components/UploadAvatarDropZone";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const EmployeeUploadAvatarPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { id: employeeId } = useParams();
  const [avatarFile, setAvatarFile] = useState<TExtendFile | undefined>();
  const [isUploaded, setIsUploaded] = useState(false);

  const handleUploadAvatar = async () => {
    try {
      const token = searchParams.get("token");
      const companyId = toNumber(searchParams.get("companyId"));
      const renewVisaDocumentStepId = toNumber(searchParams.get("renewVisaDocumentStepId"));
      const userId = toNumber(searchParams.get("userId"));
      if (!employeeId || !token || !companyId) return;
      await updateEmployeeInfoApi(employeeId, {
        avatarFilePath: avatarFile?.filePath ?? null,
        companyId,
        employeeId: toNumber(employeeId),
        token,
        renewVisaDocumentStepId,
        userId,
      });
      toast.success("画像がアップロードされました。");
      setIsUploaded(true);
    } catch (error) {
      showError({ message: "このURLは無効であるか、以前に使用されています。" });
    }
  };

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", gap: "116px", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <Typography component="h1" sx={{ fontSize: "32px", color: "#262626", translate: "-50%" }}>
        あなたの写真のアップロード
      </Typography>
      <Stack flexDirection="column" gap="20px" maxWidth="400px">
        <Typography variant="body14Bold" sx={{ textAlign: "center" }}>
          画像ファイルをアップロードしてください
        </Typography>
        <UploadAvatarDropZone avatarFile={avatarFile} setAvatarFile={setAvatarFile} />
        <Button sx={{ width: "100%" }} onClick={() => handleUploadAvatar()} disabled={!avatarFile || isUploaded}>
          アップロード
        </Button>
        <Typography variant="body14">
          肩から顔全体が写るJPEG形式の写真にしてください。
          <br /> ※解像度が300x400pxを超える場合は縮小されます。
        </Typography>
      </Stack>
    </Box>
  );
};

export default EmployeeUploadAvatarPage;
