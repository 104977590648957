import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { CompanySupporterForm } from "@/features/retirement/components/retirement-detail/accepting-worker/CompanySupporterForm";
import { RetirementEmployeeForm } from "@/features/retirement/components/retirement-detail/employment-contract/forms/RetirementEmployeeForm";
import { SpecificSkillManagingAgency } from "@/features/retirement/components/retirement-detail/employment-contract/SpecificSkillManagingAgency";
import { TargetForeigner } from "@/features/retirement/components/retirement-detail/employment-contract/TargetForeigner";
import { retirementEmploymentContractSchema } from "@/features/retirement/schema/retirementEmploymentContractSchema";
import { TRetirementEmploymentContractForm } from "@/features/retirement/types/retirementEmploymentContractForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

type TRetirementEmploymentContractDetailPageProps = {
  defaultValues?: Partial<TRetirementEmploymentContractForm>;
};

export const RetirementEmploymentContractDetailPage: FC<TRetirementEmploymentContractDetailPageProps> = ({ defaultValues }) => {
  const { documentGroupId } = useParams();
  const methods = useForm<TRetirementEmploymentContractForm>({
    defaultValues,
    resolver: yupResolver(retirementEmploymentContractSchema),
  });

  const onSubmit = () => {};

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={retirementEmploymentContractSchema}>
      <TopPage title="特定技能雇用契約に係る届出" backUrl={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <RetirementEmployeeForm readonly /> },
          { title: "届出の事由", content: <TargetForeigner readonly /> },
          { title: "特定技能所属機関", content: <SpecificSkillManagingAgency readonly /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly /> },
        ]}
      />
    </Form>
  );
};
