import { TGetCompanyBranchDto } from "@/api/services/main/company/dtos/get-company-branch.dto";
import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { TGetGovernmentOnlineSystemAccountDto } from "@/api/services/main/company/dtos/get-government-online-system-account.dto";
import { FILE_STATUS } from "@/constants/file";
import { TCompanyAccountInfoForm } from "@/features/company/types/CompanyAccountInfoForm";
import { TCompanyBranchForm } from "@/features/company/types/companyBranchForm";
import { TCompanyInfoForm } from "@/features/company/types/CompanyInfoForm";
import { TExtendFile } from "@/types/file";
import { getFileNameFromPath } from "@/utils/file";
import { toNumber } from "@/utils/number";
import { makeUuid } from "@/utils/pieces";

export const convertCompanyBranchResToFormDataDto = (data?: TGetCompanyBranchDto): Partial<TCompanyBranchForm> | undefined => {
  if (!data) return undefined;

  return {
    branchName: data.branchName,
    zipCode: data.zipCode,
    province: data.province,
    address: data.address,
    municipality: data.municipality,
    telNumber: data.telNumber,
    weeklyWorkingHours: toNumber(data.weeklyWorkingHours),
    monthWorkingHours: data.monthWorkingHours ?? undefined,
    businessInsuranceNumber: data.businessInsuranceNumber ?? undefined,
    employmentInsuranceNumber: data.employmentInsuranceNumber ?? undefined,
  };
};

export const convertCompanyResToFormDataDto = (data?: TGetCompanyDto): Partial<TCompanyInfoForm> | undefined => {
  if (!data) return undefined;
  return {
    companyName: data.companyName,
    companyFuriganaName: data.companyFuriganaName ?? undefined,
    representativeName: data.representativeName ?? undefined,
    representativeFuriganaName: data.representativeFuriganaName,
    representativeJobTitle: data.representativeJobTitle,
    capital: data.capital ?? undefined,
    companyIdentificationNumber: data.companyIdentificationNumber,
    businessInsuranceNumber: data.businessInsuranceNumber ?? undefined,
    employmentInsuranceNumber: data.employmentInsuranceNumber ?? undefined,
    zipCode: data.zipCode ?? undefined,
    province: data.province ?? undefined,
    address: data.address ?? undefined,
    division: data.division,
    telNumber: data.telNumber,
    annualRevenue: data.annualRevenue ?? undefined,
    yearsInBusiness: data.yearsInBusiness ?? undefined,
    municipality: data.municipality ?? undefined,
    employeeCount: data.employeeCount ?? undefined,
    specificIndustryId: data.specificIndustryId?.toString(),
    specificBusinessClassificationIds: data.specificBusinessClassificationIds
      ? data.specificBusinessClassificationIds.map((item) => item.toString())
      : [],
    specificIndustryTwoId: data.specificIndustryTwoId?.toString(),
    specificBusinessClassificationTwoIds: data.specificBusinessClassificationTwoIds
      ? data.specificBusinessClassificationTwoIds.map((item) => item.toString())
      : [],
    specificIndustryThreeId: data.specificIndustryThreeId?.toString(),
    specificBusinessClassificationThreeIds: data.specificBusinessClassificationThreeIds
      ? data.specificBusinessClassificationThreeIds.map((item) => item.toString())
      : [],
    mainIndustryId: data.mainIndustryId?.toString() ?? undefined,
    mainIndustryOther: data.mainIndustryOther,
    immigrationJurisdiction: data.immigrationJurisdiction ?? undefined,
    supporters: data.supporters ?? [],
    companyNote: data.companyNote ?? undefined,
    payrollDeadline: data.payrollDeadline ?? undefined,
    paymentDate: data.paymentDate ?? undefined,
  };
};

export const convertCompanyAccountResToFormDataDto = (data?: TGetGovernmentOnlineSystemAccountDto): TCompanyAccountInfoForm | undefined => {
  if (!data) return undefined;

  return {
    rasImmiFullName: data.rasImmiFullName,
    rasImmiId: data.rasImmiId,
    accordImmiId: data.accordImmiId,
    accordImmiPassword: data.accordImmiPassword,
    accordImmiFile: data.accordImmiFilePath
      ? ({
          filePath: data.accordImmiFilePath,
          id: makeUuid(),
          status: FILE_STATUS.OK,
          name: getFileNameFromPath(data.accordImmiFilePath),
        } as TExtendFile)
      : null,
  };
};
