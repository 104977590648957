import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TEmptyRetirementProps = {};

export const EmptyRetirement: FC<TEmptyRetirementProps> = () => {
  return (
    <Stack flex={1} gap={3}>
      <Stack width="100%" flex={1} justifyContent="center" alignItems="center">
        <Typography fontSize={30} sx={{ opacity: "50%" }}>
          退職の手続きが必要な人に対しては
        </Typography>
        <Typography fontSize={30} sx={{ opacity: "50%" }}>
          新規書類から作成しましょう
        </Typography>
      </Stack>
    </Stack>
  );
};
