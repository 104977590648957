import { createRetirementDocumentApi } from "@/api/services/main/retirement-document";
import { Button } from "@/components/elements/Button";
import { TopPage } from "@/components/elements/TopPage";
import { PickEmployeeRetirementModal } from "@/features/retirement/components/PickEmployeeRetirementModal";
import { RetirementList } from "@/features/retirement/components/retirement-list/RetirementList";
import { useVisible } from "@/hooks/useVisible";
import { AppRoutes } from "@/routes/config";
import { toNumber } from "@/utils/number";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type TRetirementPageProps = {};

export const RetirementPage: FC<TRetirementPageProps> = () => {
  const navigate = useNavigate();
  const pickModal = useVisible();

  const handleEmployeeCreate = async (selectedEmployeeId: string) => {
    await createRetirementDocumentApi(toNumber(selectedEmployeeId)).then((rs) => {
      navigate(getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId: rs.data.data.documentGroupId }));
    });
  };

  return (
    <Stack minHeight="100%">
      <TopPage title="退職手続き" rightContent={<Button onClick={pickModal.open}>新規書類作成</Button>} backUrl={AppRoutes.home} />
      <RetirementList />
      {pickModal.visible && (
        <PickEmployeeRetirementModal isOpen={pickModal.visible} onClose={pickModal.close} onEmployeeSelected={handleEmployeeCreate} />
      )}
    </Stack>
  );
};
