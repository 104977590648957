import App from "@/app";
import reportWebVitals from "@/reportWebVitals";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 0.5, //  Capture 50% of the transactions

  autoSessionTracking: true, // Automatically track sessions

  environment: process.env.REACT_APP_ENV,

  // Session Replay
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: "irohana@1.0.0",
});

root.render(<App />);

reportWebVitals();
