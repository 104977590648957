import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { TAcceptActivityRequest } from "@/features/regular-document/types/acceptActivityRequest";
import { Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "@/components/elements/Button";
import { useEmployeeList } from "@/api/services/main/employee";
import { ISO_FORMAT } from "@/constants/datetime";
import { getStartDateOfQuarter, getEndDateOfQuarter, isDateInQuarter } from "@/utils/pieces";
import { setErrorForm, showError } from "@/utils/error";
import { EMPLOYMENT_STATUS } from "@/types/enum";

type TCostsRequiredProps = {
  readonly?: boolean;
  year: number;
  quarter: number;
};

export const CostsRequired: FC<TCostsRequiredProps> = ({ readonly, year, quarter }) => {
  const methods = useFormContext<TAcceptActivityRequest>();
  const { control, setValue, getValues } = methods;
  const { refetch } = useEmployeeList({
    filter: {
      startWorking: getStartDateOfQuarter({ year: year, quarter: quarter }).format(ISO_FORMAT),
      endWorking: getEndDateOfQuarter({ year: year, quarter: quarter }).format(ISO_FORMAT),
      residenceStatusIds: [1, 2],
      employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
    },
    enabled: false,
  });

  useEffect(() => {
    const values = getValues();
    if (
      ["acceptedPeopleCount", "newlyAcceptedPeopleCount", "specifiedSkillOnePeopleCount", "specifiedSkillTwoPeopleCount"].every(
        (key) => values[key as keyof typeof values] === null || values[key as keyof typeof values] === undefined,
      )
    ) {
      refreshEmployeeInfo();
    }
  }, []);

  const refreshEmployeeInfo = async () => {
    try {
      let employees = null;
      const { data } = await refetch();
      employees = data?.data;
      if (employees) {
        // 受け入れていた対象者数: Total of employees target this quarter with residence status is specified skill 1
        const acceptedPeopleCount = employees?.filter((employee) => employee.residenceStatusId == 1);

        // 新たに受け入れを開始した対象者数: Total of employees that have hiring date in this quarter
        const newlyAcceptedPeopleCount = employees?.filter((employee) => isDateInQuarter(employee.hiringDate, year, quarter));

        // 受入の内訳）１号特定技能外国人数: Total of employees 新たに受け入れを開始した対象者数 on with residence status is specified skill 1
        const specifiedSkillOnePeopleCount = employees?.filter(
          (employee) => isDateInQuarter(employee.hiringDate, year, quarter) && employee.residenceStatusId == 1,
        );

        //  受入の内訳）２号特定技能外国人数: Total of employees 新たに受け入れを開始した対象者数 on with residence status is specified skill 2
        const specifiedSkillTwoPeopleCount = employees?.filter(
          (employee) => isDateInQuarter(employee.hiringDate, year, quarter) && employee.residenceStatusId == 2,
        );

        setValue("acceptedPeopleCount", acceptedPeopleCount?.length, { shouldDirty: true });
        setValue("newlyAcceptedPeopleCount", newlyAcceptedPeopleCount?.length, { shouldDirty: true });
        setValue("specifiedSkillOnePeopleCount", specifiedSkillOnePeopleCount?.length, { shouldDirty: true });
        setValue("specifiedSkillTwoPeopleCount", specifiedSkillTwoPeopleCount?.length, { shouldDirty: true });
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <InnerBox
      title="特定技能外国人の受入れに要した費用"
      bordered
      p={3}
      required
      rightContent={
        readonly ? undefined : (
          <Button size="sm" onClick={refreshEmployeeInfo}>
            対象者数を再計算
          </Button>
        )
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormField
            required
            helper="help"
            helperContent={
              <Typography variant="body14">
                支援計画の実施に要した費用は、「１号特定技能外国人支援計画書」に記載した支援を届出の対象期間中に実施するために要したものを記載してください（例：登録支援機関への支援委託手数料、日本語学習のための教材費等）。
                また、費用については届出対象期間中に発生した額（支払わなければならない額）を記載してください。
              </Typography>
            }
            control={control}
            name="acceptanceActivityImplementationCost"
          >
            <Input endAdornment="円" clearable={false} readonly={readonly} />
          </FormField>
        </Grid>

        <Grid item xs={6}>
          <FormField
            helper="help"
            helperContent={<Typography variant="body14">届出対象期間内に受け入れていた１号特定技能外国人の総数が自動算出されます。</Typography>}
            control={control}
            name="acceptedPeopleCount"
          >
            <Input endAdornment="人" clearable={false} readonly={true} />
          </FormField>
        </Grid>

        <Grid item xs={6}>
          <FormField required control={control} name="acceptanceActivityPrepareCost">
            <Input endAdornment="円" clearable={false} readonly={readonly} />
          </FormField>
          <Typography variant="cap12" mt={0.5}>
            ＊対象期間に特定技能外国人を雇用開始した場合
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <FormField
            helper="help"
            helperContent={<Typography variant="body14">届出対象期間内に新たに受入れを開始した特定技能外国人の総数が自動算出されます。</Typography>}
            control={control}
            name="newlyAcceptedPeopleCount"
          >
            <Input endAdornment="人" clearable={false} readonly={true} />
          </FormField>
        </Grid>

        <Grid item xs={6}>
          <FormField control={control} name="specifiedSkillOnePeopleCount">
            <Input endAdornment="円" clearable={false} readonly={true} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="specifiedSkillTwoPeopleCount">
            <Input endAdornment="人" clearable={false} readonly={true} />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};
