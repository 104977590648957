import { IconAlert } from "@/assets/icons";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { Tooltip } from "@/components/elements/Tooltip";
import { TAcceptActivityRequest } from "@/features/regular-document/types/acceptActivityRequest";
import { Grid, Stack, Typography, AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Divider, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { text } from "@/theme/colors";
import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export type TEmploymentStatusProps = {
  readonly?: boolean;
};

export const EmploymentStatus: FC<TEmploymentStatusProps> = ({ readonly }) => {
  const { control } = useFormContext<TAcceptActivityRequest>();
  const [accordionShowSummary1, setAccordionShowSummary1] = useState(false);
  const [accordionShowSummary2, setAccordionShowSummary2] = useState(false);

  const specificSkillTotalRegisteredEmployeeCountWatchField = useWatch({ control, name: "specificSkillTotalRegisteredEmployeeCount" });
  const specificSkillTotalNewEmployeeCountWatchField = useWatch({ control, name: "specificSkillTotalNewEmployeeCount" });
  const specificSkillTotalVoluntaryResignedEmployeeCountWatchField = useWatch({ control, name: "specificSkillTotalVoluntaryResignedEmployeeCount" });
  const specificSkillTotalLayoffEmployeeCountWatchField = useWatch({ control, name: "specificSkillTotalLayoffEmployeeCount" });
  const specificSkillTotalMissingEmployeeCountWatchField = useWatch({ control, name: "specificSkillTotalMissingEmployeeCount" });
  const specificSkillJapanRegisteredEmployeeCountWatchField = useWatch({ control, name: "specificSkillJapanRegisteredEmployeeCount" });
  const specificSkillJapanNewEmployeeCountWatchField = useWatch({ control, name: "specificSkillJapanNewEmployeeCount" });
  const specificSkillJapanVoluntaryResignedEmployeeCountWatchField = useWatch({ control, name: "specificSkillJapanVoluntaryResignedEmployeeCount" });
  const specificSkillJapanVoluntaryLayoffEmployeeCountWatchField = useWatch({ control, name: "specificSkillJapanVoluntaryLayoffEmployeeCount" });
  const specificSkillForeignRegisteredEmployeeCountWatchField = useWatch({ control, name: "specificSkillForeignRegisteredEmployeeCount" });
  const specificSkillForeignNewEmployeeCountWatchField = useWatch({ control, name: "specificSkillForeignNewEmployeeCount" });
  const specificSkillForeignVoluntaryResignedEmployeeCountWatchField = useWatch({
    control,
    name: "specificSkillForeignVoluntaryResignedEmployeeCount",
  });
  const specificSkillForeignVoluntaryLayoffEmployeeCountWatchField = useWatch({
    control,
    name: "specificSkillForeignVoluntaryLayoffEmployeeCount",
  });
  const specificSkillTotalRegisteredEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillTotalRegisteredEmployeeTwoCount" });
  const specificSkillTotalNewEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillTotalNewEmployeeTwoCount" });
  const specificSkillTotalVoluntaryResignedEmployeeTwoCountWatchField = useWatch({
    control,
    name: "specificSkillTotalVoluntaryResignedEmployeeTwoCount",
  });
  const specificSkillTotalLayoffEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillTotalLayoffEmployeeTwoCount" });
  const specificSkillTotalMissingEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillTotalMissingEmployeeTwoCount" });
  const specificSkillJapanRegisteredEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillJapanRegisteredEmployeeTwoCount" });
  const specificSkillJapanNewEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillJapanNewEmployeeTwoCount" });
  const specificSkillJapanVoluntaryResignedEmployeeTwoCountWatchField = useWatch({
    control,
    name: "specificSkillJapanVoluntaryResignedEmployeeTwoCount",
  });
  const specificSkillJapanVoluntaryLayoffEmployeeTwoCountWatchField = useWatch({
    control,
    name: "specificSkillJapanVoluntaryLayoffEmployeeTwoCount",
  });
  const specificSkillForeignRegisteredEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillForeignRegisteredEmployeeTwoCount" });
  const specificSkillForeignNewEmployeeTwoCountWatchField = useWatch({ control, name: "specificSkillForeignNewEmployeeTwoCount" });
  const specificSkillForeignVoluntaryResignedEmployeeTwoCountWatchField = useWatch({
    control,
    name: "specificSkillForeignVoluntaryResignedEmployeeTwoCount",
  });
  const specificSkillForeignVoluntaryLayoffEmployeeTwoCountWatchField = useWatch({
    control,
    name: "specificSkillForeignVoluntaryLayoffEmployeeTwoCount",
  });

  useEffect(() => {
    const allZero = [
      specificSkillTotalRegisteredEmployeeCountWatchField,
      specificSkillTotalNewEmployeeCountWatchField,
      specificSkillTotalVoluntaryResignedEmployeeCountWatchField,
      specificSkillTotalLayoffEmployeeCountWatchField,
      specificSkillTotalMissingEmployeeCountWatchField,
      specificSkillJapanRegisteredEmployeeCountWatchField,
      specificSkillJapanNewEmployeeCountWatchField,
      specificSkillJapanVoluntaryResignedEmployeeCountWatchField,
      specificSkillJapanVoluntaryLayoffEmployeeCountWatchField,
      specificSkillForeignRegisteredEmployeeCountWatchField,
      specificSkillForeignNewEmployeeCountWatchField,
      specificSkillForeignVoluntaryResignedEmployeeCountWatchField,
      specificSkillForeignVoluntaryLayoffEmployeeCountWatchField,
    ].every((val) => String(val) === "0");
    setAccordionShowSummary1(allZero);
  }, [
    specificSkillTotalRegisteredEmployeeCountWatchField,
    specificSkillTotalNewEmployeeCountWatchField,
    specificSkillTotalVoluntaryResignedEmployeeCountWatchField,
    specificSkillTotalLayoffEmployeeCountWatchField,
    specificSkillTotalMissingEmployeeCountWatchField,
    specificSkillJapanRegisteredEmployeeCountWatchField,
    specificSkillJapanNewEmployeeCountWatchField,
    specificSkillJapanVoluntaryResignedEmployeeCountWatchField,
    specificSkillJapanVoluntaryLayoffEmployeeCountWatchField,
    specificSkillForeignRegisteredEmployeeCountWatchField,
    specificSkillForeignNewEmployeeCountWatchField,
    specificSkillForeignVoluntaryResignedEmployeeCountWatchField,
    specificSkillForeignVoluntaryLayoffEmployeeCountWatchField,
  ]);

  useEffect(() => {
    const allZero = [
      specificSkillTotalRegisteredEmployeeTwoCountWatchField,
      specificSkillTotalNewEmployeeTwoCountWatchField,
      specificSkillTotalVoluntaryResignedEmployeeTwoCountWatchField,
      specificSkillTotalLayoffEmployeeTwoCountWatchField,
      specificSkillTotalMissingEmployeeTwoCountWatchField,
      specificSkillJapanRegisteredEmployeeTwoCountWatchField,
      specificSkillJapanNewEmployeeTwoCountWatchField,
      specificSkillJapanVoluntaryResignedEmployeeTwoCountWatchField,
      specificSkillJapanVoluntaryLayoffEmployeeTwoCountWatchField,
      specificSkillForeignRegisteredEmployeeTwoCountWatchField,
      specificSkillForeignNewEmployeeTwoCountWatchField,
      specificSkillForeignVoluntaryResignedEmployeeTwoCountWatchField,
      specificSkillForeignVoluntaryLayoffEmployeeTwoCountWatchField,
    ].every((val) => String(val) === "0");
    setAccordionShowSummary2(allZero);
  }, [
    specificSkillTotalRegisteredEmployeeTwoCountWatchField,
    specificSkillTotalNewEmployeeTwoCountWatchField,
    specificSkillTotalVoluntaryResignedEmployeeTwoCountWatchField,
    specificSkillTotalLayoffEmployeeTwoCountWatchField,
    specificSkillTotalMissingEmployeeTwoCountWatchField,
    specificSkillJapanRegisteredEmployeeTwoCountWatchField,
    specificSkillJapanNewEmployeeTwoCountWatchField,
    specificSkillJapanVoluntaryResignedEmployeeTwoCountWatchField,
    specificSkillJapanVoluntaryLayoffEmployeeTwoCountWatchField,
    specificSkillForeignRegisteredEmployeeTwoCountWatchField,
    specificSkillForeignNewEmployeeTwoCountWatchField,
    specificSkillForeignVoluntaryResignedEmployeeTwoCountWatchField,
    specificSkillForeignVoluntaryLayoffEmployeeTwoCountWatchField,
  ]);

  return (
    <InnerBox title="雇用状況" bordered p={3}>
      <Stack gap={3}>
        <MuiAccordionWrapper defaultExpanded sx={{ boxShadow: "none", width: "100%" }}>
          {accordionShowSummary1 && (
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
          )}
          <AccordionDetails>
            <Stack gap={3}>
              <Stack gap={2}>
                <Typography variant="sub16Semi">特定技能1号</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Stack>
                          <Typography variant="body14">
                            届出の対象期間の末日に所属機関で就労している特定技能外国人1号人数を記載してください。
                          </Typography>
                          <Typography variant="body14">届出の対象期間中に退職した者は人数に含まれません。</Typography>
                        </Stack>
                      }
                      control={control}
                      name="specificSkillTotalRegisteredEmployeeCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Typography variant="body14">
                          届出の対象期間中に就労を開始した特定技能1号の人数を記載してください。なお、届出の対象期間中に、在留資格「特定技能」の許可を受けていても、特定技能所属機関で就労を開始していない者は人数に含まれません（この場合、参考様式第３－６号別紙「特定技能外国人の受入れ状況・報酬の支払状況」で記載した人数と一致しません。）。
                        </Typography>
                      }
                      control={control}
                      name="specificSkillTotalNewEmployeeCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Stack>
                          <Typography variant="body14">
                            届出の対象期間中に自発的離職（自己都合退職）をした特定技能1号の人数をそれぞれ記載してください。
                          </Typography>
                          <Typography variant="body14">
                            なお、特定技能1号外国人の受入れが困難になった場合は、受入れが困難となった事情が発生した日から１４日以内に「受入れ困難に係る届出書（参考様式第３－４号）」を提出する必要があり、また、特定技能雇用契約が終了した場合は、特定技能雇用契約が終了した日から１４日以内に「特定技能雇用契約に係る届出書（参考様式第３－１号）」を提出する必要があります。
                          </Typography>
                        </Stack>
                      }
                      control={control}
                      name="specificSkillTotalVoluntaryResignedEmployeeCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Typography variant="body14">
                          非自発的離職者を発生させている場合は、労働者名簿（労働基準法第１０７条に規定）の写しを添付してください。
                          なお、特定技能外国人の受入れが困難になった場合は、受入れが困難となった事情が発生した日から１４日以内に「受入れ困難に係る届出書（参考様式第３－４号）」を提出する必要があり、また、特定技能雇用契約が終了した場合は、特定技能雇用契約が終了した日から１４日以内に「特定技能雇用契約に係る届出書（参考様式第３－１
                          号）」を提出する必要があります。
                        </Typography>
                      }
                      control={control}
                      name="specificSkillTotalLayoffEmployeeCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Typography variant="body14">
                          届出の対象期間中に行方不明になった特定技能外国人の人数を記載してください。
                          なお，行方不明者が発生した日から１４日以内に「受入れ困難に係る届出書（参考様式第３－４号）」を提出し，その後，特定技能雇用契約を終了した日から１４日以内に「特定技能雇用契約に係る届出書（参考様式第３－１号）」を提出してください。
                        </Typography>
                      }
                      control={control}
                      name="specificSkillTotalMissingEmployeeCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
              <Stack gap={2}>
                <Stack flexDirection="row" alignItems="center" gap="4px">
                  <Typography variant="sub16Semi">特定技能1号と同一の業務に従事する日本人従業員</Typography>
                  <Tooltip
                    content={
                      <Typography variant="body14">
                        特定技能1号と同一の業務に従事する日本人従業員同一の業務とは、特定技能外国人が従事する業務区分における業務内容と同一の業務をいいます。同一業務に従事している全ての従業員が含まれます。
                      </Typography>
                    }
                  >
                    <IconAlert />
                  </Tooltip>
                </Stack>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanRegisteredEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanNewEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanVoluntaryResignedEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanVoluntaryLayoffEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
              <Stack gap={2}>
                <Stack flexDirection="row" alignItems="center" gap="4px">
                  <Typography variant="sub16Semi">特定技能1号と同一の業務に従事する外国人従業員</Typography>
                  <Tooltip
                    content={
                      <Typography variant="body14">
                        同一の業務とは、特定技能外国人が従事する業務区分における業務内容と同一の業務をいいます。同一業務に従事している全ての従業員が含まれます。
                      </Typography>
                    }
                  >
                    <IconAlert />
                  </Tooltip>
                </Stack>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignRegisteredEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignNewEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignVoluntaryResignedEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignVoluntaryLayoffEmployeeCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </AccordionDetails>
        </MuiAccordionWrapper>
        <Divider />
        <MuiAccordionWrapper defaultExpanded sx={{ boxShadow: "none", width: "100%" }}>
          {accordionShowSummary2 && (
            <AccordionSummaryWrapper
              sx={{ p: 0, justifyContent: "center", alignItems: "center", width: "160px", marginLeft: "auto" }}
              expandIcon={<ExpandMoreIcon sx={{ color: text.primary, width: "40px", height: "40px" }} />}
            >
              省略項目を表示
            </AccordionSummaryWrapper>
          )}
          <AccordionDetails>
            <Stack gap={3}>
              <Stack gap={2}>
                <Typography variant="sub16Semi">特定技能2号</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Stack>
                          <Typography variant="body14">
                            届出の対象期間の末日に所属機関で就労している特定技能外国人1号人数を記載してください。
                          </Typography>
                          <Typography variant="body14">届出の対象期間中に退職した者は人数に含まれません。</Typography>
                        </Stack>
                      }
                      control={control}
                      name="specificSkillTotalRegisteredEmployeeTwoCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Typography variant="body14">
                          届出の対象期間中に就労を開始した特定技能1号の人数を記載してください。なお、届出の対象期間中に、在留資格「特定技能」の許可を受けていても、特定技能所属機関で就労を開始していない者は人数に含まれません（この場合、参考様式第３－６号別紙「特定技能外国人の受入れ状況・報酬の支払状況」で記載した人数と一致しません。）。
                        </Typography>
                      }
                      control={control}
                      name="specificSkillTotalNewEmployeeTwoCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Stack>
                          <Typography variant="body14">
                            届出の対象期間中に自発的離職（自己都合退職）をした特定技能1号の人数をそれぞれ記載してください。
                          </Typography>
                          <Typography variant="body14">
                            なお、特定技能1号外国人の受入れが困難になった場合は、受入れが困難となった事情が発生した日から１４日以内に「受入れ困難に係る届出書（参考様式第３－４号）」を提出する必要があり、また、特定技能雇用契約が終了した場合は、特定技能雇用契約が終了した日から１４日以内に「特定技能雇用契約に係る届出書（参考様式第３－１号）」を提出する必要があります。
                          </Typography>
                        </Stack>
                      }
                      control={control}
                      name="specificSkillTotalVoluntaryResignedEmployeeTwoCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Typography variant="body14">
                          非自発的離職者を発生させている場合は、労働者名簿（労働基準法第１０７条に規定）の写しを添付してください。
                          なお、特定技能外国人の受入れが困難になった場合は、受入れが困難となった事情が発生した日から１４日以内に「受入れ困難に係る届出書（参考様式第３－４号）」を提出する必要があり、また、特定技能雇用契約が終了した場合は、特定技能雇用契約が終了した日から１４日以内に「特定技能雇用契約に係る届出書（参考様式第３－１
                          号）」を提出する必要があります。
                        </Typography>
                      }
                      control={control}
                      name="specificSkillTotalLayoffEmployeeTwoCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      helper="help"
                      helperContent={
                        <Typography variant="body14">
                          届出の対象期間中に行方不明になった特定技能外国人の人数を記載してください。
                          なお，行方不明者が発生した日から１４日以内に「受入れ困難に係る届出書（参考様式第３－４号）」を提出し，その後，特定技能雇用契約を終了した日から１４日以内に「特定技能雇用契約に係る届出書（参考様式第３－１号）」を提出してください。
                        </Typography>
                      }
                      control={control}
                      name="specificSkillTotalMissingEmployeeTwoCount"
                    >
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
              <Stack gap={2}>
                <Stack flexDirection="row" alignItems="center" gap="4px">
                  <Typography variant="sub16Semi">特定技能2号と同一の業務に従事する日本人従業員</Typography>
                  <Tooltip
                    content={
                      <Typography variant="body14">
                        特定技能1号と同一の業務に従事する日本人従業員同一の業務とは、特定技能外国人が従事する業務区分における業務内容と同一の業務をいいます。同一業務に従事している全ての従業員が含まれます。
                      </Typography>
                    }
                  >
                    <IconAlert />
                  </Tooltip>
                </Stack>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanRegisteredEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanNewEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanVoluntaryResignedEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillJapanVoluntaryLayoffEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
              <Stack gap={2}>
                <Stack flexDirection="row" alignItems="center" gap="4px">
                  <Typography variant="sub16Semi">特定技能2号と同一の業務に従事する外国人従業員</Typography>
                  <Tooltip
                    content={
                      <Typography variant="body14">
                        同一の業務とは、特定技能外国人が従事する業務区分における業務内容と同一の業務をいいます。同一業務に従事している全ての従業員が含まれます。
                      </Typography>
                    }
                  >
                    <IconAlert />
                  </Tooltip>
                </Stack>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignRegisteredEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignNewEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignVoluntaryResignedEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField control={control} name="specificSkillForeignVoluntaryLayoffEmployeeTwoCount">
                      <Input endAdornment="人" clearable={false} readonly={readonly} />
                    </FormField>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </AccordionDetails>
        </MuiAccordionWrapper>
        <Stack>
          <Divider sx={{ mb: 3 }} />
          <Stack gap={2}>
            <Stack flexDirection="row" alignItems="center" gap="4px">
              <Typography variant="sub16Semi">上記以外の従業員（日本人＋外国人）</Typography>
              <Tooltip
                content={
                  <Typography variant="body14">
                    特定技能外国人が従事している業務と同一ではない、例えば、総務、人事、経理などの業務が該当します。
                  </Typography>
                }
              >
                <IconAlert />
              </Tooltip>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormField control={control} name="nonSpecificSkillRegisteredEmployeeCount">
                  <Input endAdornment="人" clearable={false} readonly={readonly} />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="nonSpecificSkillNewEmployeeCount">
                  <Input endAdornment="人" clearable={false} readonly={readonly} />
                </FormField>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </InnerBox>
  );
};

const MuiAccordionWrapper = styled(MuiAccordion)`
  .Mui-expanded {
    min-height: 0;
  }
  &.MuiPaper-root {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
  &::before {
    display: none;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: end;
  }
  & .Mui-expanded {
    margin: 0 !important;
  }
`;
