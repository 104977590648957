import { deletePayrollFileApi } from "@/api/services/main/regular-document/acceptance-activity-document";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";

type TDeletePayrollFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deletePayrollFileId: number | undefined;
};

export const DeletePayrollFileModal: FC<TDeletePayrollFileModalProps> = ({ isOpen, onClose, deletePayrollFileId }) => {
  const queryClient = useQueryClient();

  const handleDeletePayrollFile = async () => {
    if (!deletePayrollFileId) return;
    try {
      const rs = await deletePayrollFileApi(deletePayrollFileId);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_FILE_LIST],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_PRESENT],
      });
    } catch (error) {
      showError(error);
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} enableCloseIcon={false} title="削除の確認" onOk={() => handleDeletePayrollFile()}>
      <Box mt="8px" mb="24px">
        <Typography component="p" variant="cap12">
          アップロードしたファイルが削除されますが、よろしいいですか
        </Typography>
      </Box>
    </Modal>
  );
};
