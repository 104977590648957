import { useGetRenewVisaDocumentInfo } from "@/api/services/main/renew-visa";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { button, divider, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { TabContext, TabList } from "@mui/lab";
import { Box, css, styled } from "@mui/material";
import Tab from "@mui/material/Tab";
import { FC } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

type TRenewVisaLayoutProps = {};

export const RenewVisaLayout: FC<TRenewVisaLayoutProps> = () => {
  const navigate = useNavigate();
  const { isLoading: isInitLoading } = useInitSetup();

  const { renewVisaDocumentId } = useParams();

  const { pathname } = useLocation();

  const { data: renewVisaDocument, isLoading: isLoadingRenewVisa } = useGetRenewVisaDocumentInfo();

  if (isLoadingRenewVisa || isInitLoading) return <LoadingPage />;

  return (
    <Box>
      <TopPage
        title={`${getFullName({ firstName: renewVisaDocument?.employee.firstName ?? "", middleName: renewVisaDocument?.employee.middleName, lastName: renewVisaDocument?.employee.lastName ?? "" })}さんの在留期間更新`}
        description={
          pathname === getDynamicRoute(AppRoutes.renewVisaInfoHistory, { renewVisaDocumentId })
            ? "届出書類1、2のそれぞれのステップを進んで、「届出提出」を押してください"
            : undefined
        }
        backUrl={AppRoutes.renewVisa}
      />
      <InnerBox padding="0">
        <BoxTabWrapper>
          <TabContext value={location.pathname}>
            <TabListWrapper onChange={(_, tab) => navigate(tab)} variant="fullWidth">
              <TabWrapper label="今回" value={getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId })} />
              <TabWrapper label="過去提出分" value={getDynamicRoute(AppRoutes.renewVisaInfoHistory, { renewVisaDocumentId })} />
            </TabListWrapper>
          </TabContext>
          <Outlet />
        </BoxTabWrapper>
      </InnerBox>
    </Box>
  );
};

const BoxTabWrapper = styled(Box)`
  & .MuiTabs-flexContainer {
    justify-content: space-between;

    & .MuiButtonBase-root {
      text-transform: none;
      font-weight: bold;
      ${css(typography.head20Bold)};
      color: ${text.disable};
    }

    & .Mui-selected {
      color: ${button.pressed};
    }
  }
`;

const TabListWrapper = styled(TabList)`
  .MuiTabs-indicator {
    background-color: ${button.pressed};
    height: 4px;
  }
`;

const TabWrapper = styled(Tab)`
  border-bottom: 4px solid ${divider.high};
`;
