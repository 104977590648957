import { useGetHistoryRenewVisaDocumentList, useGetRenewVisaDocumentInfo } from "@/api/services/main/renew-visa";
import { Pagination } from "@/components/elements/Pagination";
import { Table } from "@/components/elements/Table";
import { useMakeHistoryRenewVisaColumns } from "@/features/renew-visa/hook/useMakeHistoryRenewVisaColumns";
import { useTable } from "@/hooks/useTable";
import { toNumber } from "@/utils/number";
import { Stack } from "@mui/material";
import styled from "styled-components";

export const RenewVisaHistoryListPage = () => {
  const { data: renewVisaDocument } = useGetRenewVisaDocumentInfo();

  const { data: historyRenewVisaDocumentList, isLoading } = useGetHistoryRenewVisaDocumentList({
    employeeId: toNumber(renewVisaDocument?.employeeId),
  });

  const columns = useMakeHistoryRenewVisaColumns();

  const { table } = useTable({ columns, rows: historyRenewVisaDocumentList?.data ?? [] });

  return (
    <RenewVisaListWrapper gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Pagination totalPage={historyRenewVisaDocumentList?.pagination.totalPage} />
      </Stack>
      <Table table={table} isLoading={isLoading} />
    </RenewVisaListWrapper>
  );
};

const RenewVisaListWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
