import { setAxiosAuth } from "@/utils/api";
import { verifyToken } from "@/utils/token";
import { ClientRoutes, AppRoutes } from "@/routes/config";
import Cookies from "js-cookie";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingScreen } from "@/components/elements/Loading/Loading";

export const withAuth: <CP extends {}>(WrappedComponent: FC<CP>) => FC<CP> = (WrappedComponent) => (props) => {
  const { pathname } = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const [userId, setUserId] = useState(Cookies.get("userId"));
  const navigate = useNavigate();

  useEffect(() => {
    // Every 2 seconds check user id, if user id has changed then redirect to login page
    setInterval(() => {
      if (userId && userId != Cookies.get("userId")) {
        location.href = "/login";
      }
    }, 2000);
  }, []);

  useEffect(() => {
    // const userId = Cookies.get("userId");
    // setUserId(userId);
    const token = Cookies.get("token");
    setAxiosAuth();
    const isAuthenticated = !!token && verifyToken(token);

    // verify admin routes
    if (!isAuthenticated && ClientRoutes.some((path) => pathname.startsWith(path))) {
      location.href = "/login";
    }

    setIsVerified(true);
  }, [pathname, navigate]);

  if (!isVerified) return <LoadingScreen />;

  return <WrappedComponent {...props} />;
};
