import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useGetRenewVisaApplicationInformationDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { AfterSubmitApplicationStepCard } from "@/features/renew-visa/components/afterSubmitApplicationStep/AfterSubmitApplicationStepCard";
import { FC } from "react";

export type TRenewVisaAfterSubmitApplicationStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
};

export const RenewVisaAfterSubmitApplicationStep: FC<TRenewVisaAfterSubmitApplicationStepProps> = ({ stepData, readonly }) => {
  const { id: documentStepId, updater } = stepData;
  const { data: applicationInformationData, isLoading } = useGetRenewVisaApplicationInformationDocumentInfo({ documentStepId, enabled: !!updater });
  if (isLoading) return <LoadingArea />;

  return <AfterSubmitApplicationStepCard readonly={readonly} applicationInformationData={applicationInformationData} stepData={stepData} />;
};
