import { deleteEmployeeInfoApi } from "@/api/services/main/employee";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TDeleteEmployeeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteEmployeeModal: FC<TDeleteEmployeeModalProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDeleteEmployee = async () => {
    try {
      if (id) {
        const rs = await deleteEmployeeInfoApi(id);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_LIST] });
        navigate(AppRoutes.employeeList);
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title="削除の確認"
      isOpen={isOpen}
      onClose={onClose}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleDeleteEmployee()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        特定技能外国人を削除してもよろしいですか？
      </Typography>
    </Modal>
  );
};
