import { Button } from "@/components/elements/Button";
import { AppRoutes } from "@/routes/config";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledContainer = styled(Stack)({
  textAlign: "center",
  padding: "32px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  alignItems: "center",
  gap: 8,
});

const NotFoundPage = () => {
  return (
    <Box sx={{ backgroundColor: "#f2f2f2", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <StyledContainer>
        <Typography variant="head32Bold">404</Typography>
        <Typography variant="head20Bold">申し訳ありませんが、お探しのページは見つかりませんでした。</Typography>
        <Typography variant="body14">URLが間違っているか、ページが削除された可能性があります。</Typography>
        <Button href={AppRoutes.home}>ホームページに戻る</Button>
      </StyledContainer>
    </Box>
  );
};

export default NotFoundPage;
