import { useAnytimeEmploymentContract } from "@/api/services/main/any-time/employment-contract";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AnytimeEmploymentContractDetailPage } from "@/features/anytime/pages/documents/AnytimeEmploymentContractDetail";
import { convertResDtoToFormData } from "@/features/anytime/utils/employment-contract";
import React from "react";

const AnytimeEmploymentContractDetail = () => {
  const { data: employmentContractResult, isLoading } = useAnytimeEmploymentContract();
  const employmentContractData = employmentContractResult?.data;

  if (isLoading || !employmentContractData) return <LoadingPage />;

  return <AnytimeEmploymentContractDetailPage defaultValues={convertResDtoToFormData(employmentContractData)} />;
};

export default AnytimeEmploymentContractDetail;
