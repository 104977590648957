import { fetchFileInfoApi } from "@/api/services/global/file";
import {
  createMultiRetirementFilesApi,
  finishUploadDocumentStepApi,
  useEmployeeRetirementDocument,
  useRetirementFileList,
} from "@/api/services/main/retirement-document";
import { ConfirmSaveModal } from "@/components/commons/ConfirmSaveModal";
import { Button } from "@/components/elements/Button";
import { UploadPopup } from "@/components/elements/UploadPopup";
import { FILE_STATUS, FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useDialog } from "@/hooks/useDialog";
import { useVisible } from "@/hooks/useVisible";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS } from "@/types/enum";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TRetirementFilesTopProps = {
  readonly?: boolean;
};

export const RetirementFilesTop: FC<TRetirementFilesTopProps> = ({ readonly }) => {
  const { documentGroupId, documentId, id } = useParams();
  const retirementUploadFilePopup = useVisible();
  const confirmFinish = useDialog();
  const queryClient = useQueryClient();
  const { data: retirementDocumentResult } = useEmployeeRetirementDocument();
  const documentListData = retirementDocumentResult?.data.retirementDocuments;
  const retirementData = documentListData?.find((document) => document.id === documentId);
  const stepData = retirementData?.steps?.find((step) => step.id.toString() === id);
  const navigate = useNavigate();
  const { data: retirementFileListResult } = useRetirementFileList();

  const [isCheckRetirementFileLoading, setIsCheckRetirementFileLoading] = useState<boolean>(false);
  const [isDisableUploadBtn, setIsDisableUploadBtn] = useState<boolean>(false);

  const handleCheckMaximumRetirementFiles = async (newFiles: TExtendFile[]) => {
    //Maximum upload of 19 files, Total file upload capacity of 1 user is 19MB
    if (!retirementFileListResult) return true;
    setIsCheckRetirementFileLoading(true);
    const uploadedRetirementFiles = await Promise.all(
      retirementFileListResult.data.map(async (item) => {
        const rs = await fetchFileInfoApi(item.filePath);
        return rs.data;
      }),
    );
    //Check Maximum upload of 19 files
    if (uploadedRetirementFiles.length + newFiles.length > 19) {
      toast.error("19ファイル以下を選択してください");
      setIsCheckRetirementFileLoading(false);
      return true;
    }
    //Check Total file upload capacity of 1 user is 19MB
    if (
      uploadedRetirementFiles.reduce((prev, curr) => curr.size + prev, 0) + newFiles.reduce((prev, curr) => curr.size + prev, 0) >
      19 * 1024 * 1024
    ) {
      toast.error("総容量は19MBまでです。別のファイルをアップロードしてください");
      setIsCheckRetirementFileLoading(false);
      return true;
    }
    setIsCheckRetirementFileLoading(false);
    setIsDisableUploadBtn(true);
    return false;
  };

  const handleFinishUploadFiles = async (_: TExtendFile[], newFiles: TExtendFile[]) => {
    const filesWithStatusOk = newFiles.filter((file) => file.status === FILE_STATUS.OK);
    if (filesWithStatusOk.length === 0 || !id) return;
    if (!stepData) {
      navigate(getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId, documentId }));
      return;
    }
    try {
      await createMultiRetirementFilesApi({
        files: newFiles.flatMap((file) => (file.filePath ? { filePath: file.filePath, retirementDocumentStepId: toNumber(id) } : [])),
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_FILE_LIST, id],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId],
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsDisableUploadBtn(false);
    }
  };

  const handleSetFinishDocumentStep = async () => {
    try {
      const agree = await confirmFinish.show();
      if (!agree) return;
      await finishUploadDocumentStepApi(Number(id));
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId],
      });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="end" alignItems="center">
        {!readonly && (
          <Stack direction="row" alignItems="end" spacing={2}>
            <Button size="md" variant="primary" disabled={stepData?.status === DOCUMENT_STATUS.COMPLETE} onClick={handleSetFinishDocumentStep}>
              アップロード完了
            </Button>
            <Button size="md" variant="primary" disabled={isDisableUploadBtn} onClick={() => retirementUploadFilePopup.open()}>
              新規アップロード
            </Button>
          </Stack>
        )}
      </Stack>
      <UploadPopup
        isLoading={isCheckRetirementFileLoading}
        uploadFor={FILE_UPLOAD_FOR.RETIREMENT_FILE}
        onFinishUpload={handleFinishUploadFiles}
        onDropFiles={handleCheckMaximumRetirementFiles}
        isOpen={retirementUploadFilePopup.visible}
        onClose={() => retirementUploadFilePopup.close()}
        options={{ multiple: true }}
        rules={{ acceptMimeTypes: [...MIME_TYPES.PDF, ...MIME_TYPES.IMAGE, ...MIME_TYPES.EXCEL] }}
        title={<Typography variant="body14Bold">ファイルをアップロードしてください。</Typography>}
      />
      <ConfirmSaveModal open={confirmFinish.open} onOk={confirmFinish.confirm} onClose={confirmFinish.cancel}>
        <Typography>添付書類のアップロードが完了してもよろしいでしょうか。</Typography>
      </ConfirmSaveModal>
    </>
  );
};
