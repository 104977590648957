import { useCompanyInfo } from "@/api/services/main/company";
import { useEmployeeList } from "@/api/services/main/employee";
import { usePresentDocumentList } from "@/api/services/main/regular-document";
import { useLifeSupportDocument } from "@/api/services/main/regular-document/life-support-document";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { ISO_FORMAT } from "@/constants/datetime";
import { RegularPresentLifeSupportForm } from "@/features/regular-document/components/life-support/RegularPresentLifeSupportForm";
import { DOCUMENT_KEYS, DOCUMENT_STATUS, DOCUMENT_STEP_KEYS, EMPLOYMENT_STATUS } from "@/types/enum";
import { findDocument, findDocumentStep } from "@/utils/document";
import { pick } from "@/utils/object";
import { getEndDateOfQuarter, getStartDateOfQuarter } from "@/utils/pieces";
import { getFullName } from "@/utils/string";
import { useEffect, useMemo, useState } from "react";

export const RegularPresentLifeSupportPage = () => {
  const { data: documentResult, isLoading: isLoadingDocumentData } = usePresentDocumentList();
  const documentData = findDocument(documentResult?.data.regularDocuments, DOCUMENT_KEYS.LIFE_SUPPORT);
  const stepData = findDocumentStep(documentData, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT);
  const stepId = stepData?.id;
  const { data: lifeSupportResult, isLoading: isLoadingLifeSupport } = useLifeSupportDocument({
    documentStepId: stepId,
    enabled: !!stepData?.updater,
  });
  const lifeSupportData = lifeSupportResult?.data;
  const resourceData = { year: documentData?.year ?? 0, quarter: documentData?.quarter ?? 0 };
  const [isEditable, setIsEditable] = useState(stepData?.status === DOCUMENT_STATUS.IN_PROGRESS);

  useEffect(() => {
    setIsEditable(stepData?.status === DOCUMENT_STATUS.IN_PROGRESS);
  }, [stepData?.status]);

  const { data: employeeListData, isLoading: isEmployeeListData } = useEmployeeList({
    enabled: !isLoadingLifeSupport && !lifeSupportResult,
    filter: {
      startWorking: getStartDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
      endWorking: getEndDateOfQuarter(pick(resourceData, "year", "quarter")).format(ISO_FORMAT),
      residenceStatusIds: [1],
      employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
    },
  });

  const employeesInfo = useMemo(() => {
    if (lifeSupportData) {
      return lifeSupportData?.employees.map((employee) => ({
        employeeId: Number(employee.employeeId),
        employeeFullName: employee.employeeFullName,
        employeeGender: employee.employeeGender,
        employeeNationalityId: employee.employeeNationalityId,
        employeeResidenceCardNumber: employee.employeeResidenceCardNumber,
        birthday: employee.birthday,
        zipCode: employee.zipCode,
        fullAddress: employee.fullAddress,
        telNumber: employee.telNumber,
      }));
    }
    if (employeeListData) {
      return employeeListData?.data.map((employee) => ({
        employeeId: Number(employee.id),
        employeeFullName: getFullName({ firstName: employee.firstName, lastName: employee.lastName, middleName: employee.middleName }),
        employeeGender: employee.gender ?? null,
        employeeNationalityId: employee.nationalityId ?? null,
        employeeResidenceCardNumber: employee.residenceCode ?? "",
        birthday: employee.birthday,
        zipCode: employee.zipCode,
        fullAddress: employee.fullAddress ?? null,
        telNumber: employee.telNumber,
      }));
    }
    return [];
  }, [lifeSupportData, employeeListData]);

  const { data: companyData, isLoading: isLoadingCompany } = useCompanyInfo();

  const supporter = useMemo(() => {
    if (lifeSupportData)
      return {
        companySupporterName: lifeSupportData.companySupporterName,
        companySupporterTelNumber: lifeSupportData.companySupporterTelNumber,
        companySupporterId: lifeSupportData.companySupporterId,
      };

    if (companyData)
      return {
        companySupporterName:
          companyData?.data.supporters && companyData?.data.supporters.length > 0 ? companyData?.data.supporters[0].supporterName : null,
        companySupporterTelNumber:
          companyData?.data.supporters && companyData?.data.supporters.length > 0 ? companyData?.data.supporters[0].telNumber : null,
        companySupporterId: companyData?.data.supporters && companyData?.data.supporters.length > 0 ? companyData?.data.supporters[0].id : null,
      };
  }, [lifeSupportData, companyData]);

  const previousValues = useMemo(
    () => ({
      airportPickup: lifeSupportData?.airportPickup,
      airportPickupReason: lifeSupportData?.airportPickupReason ? lifeSupportData?.airportPickupReason : null,
      seeOffAirport: lifeSupportData?.seeOffAirport,
      seeOffAirportReason: lifeSupportData?.seeOffAirportReason ? lifeSupportData?.seeOffAirportReason : null,
      supportLivelihood: lifeSupportData?.supportLivelihood,
      supportLivelihoodReason: lifeSupportData?.supportLivelihoodReason ? lifeSupportData?.supportLivelihoodReason : null,
      supportLifeOrientation: lifeSupportData?.supportLifeOrientation,
      supportLifeOrientationReason: lifeSupportData?.supportLifeOrientationReason ? lifeSupportData?.supportLifeOrientationReason : null,
      accompanyToRelevantInstitutionsAndOtherSupport: lifeSupportData?.accompanyToRelevantInstitutionsAndOtherSupport,
      accompanyToRelevantInstitutionsAndOtherSupportReason: lifeSupportData?.accompanyToRelevantInstitutionsAndOtherSupportReason
        ? lifeSupportData?.accompanyToRelevantInstitutionsAndOtherSupportReason
        : null,
      supportLearnJapanese: lifeSupportData?.supportLearnJapanese,
      supportLearnJapaneseReason: lifeSupportData?.supportLearnJapaneseReason ? lifeSupportData?.supportLearnJapaneseReason : null,
      handleConsultationAndComplaint: lifeSupportData?.handleConsultationAndComplaint,
      handleConsultationAndComplaintReason: lifeSupportData?.handleConsultationAndComplaintReason
        ? lifeSupportData?.handleConsultationAndComplaintReason
        : null,
      supportExchangeWithJapanesePeople: lifeSupportData?.supportExchangeWithJapanesePeople,
      supportExchangeWithJapanesePeopleReason: lifeSupportData?.supportExchangeWithJapanesePeopleReason
        ? lifeSupportData?.supportExchangeWithJapanesePeopleReason
        : null,
      supportFindJob: lifeSupportData?.supportFindJob,
      supportFindJobReason: lifeSupportData?.supportFindJobReason ? lifeSupportData?.supportFindJobReason : null,
      conductRegularInterview: lifeSupportData?.conductRegularInterview,
      conductRegularInterviewReason: lifeSupportData?.conductRegularInterviewReason ? lifeSupportData?.conductRegularInterviewReason : null,
      companySupporterName: supporter ? supporter.companySupporterName : null,
      companySupporterTelNumber: supporter ? supporter.companySupporterTelNumber : null,
      companySupporterId: supporter ? supporter.companySupporterId : null,
    }),
    [lifeSupportData, employeesInfo],
  );

  if (isLoadingDocumentData || isLoadingLifeSupport || isEmployeeListData || isLoadingCompany) return <LoadingPage />;

  return (
    <RegularPresentLifeSupportForm
      documentStepId={stepId}
      updater={stepData?.updater}
      previousValues={previousValues}
      isDraft={lifeSupportData?.isDraft}
      resourceData={{ year: documentData?.year, quarter: documentData?.quarter, employees: employeesInfo }}
      readonly={stepData?.status === DOCUMENT_STATUS.CONVERTING}
      isEditable={isEditable}
      setIsEditable={setIsEditable}
    />
  );
};
