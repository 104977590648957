import { valueOf } from "@/types/common";

export const USER_TYPE = {
  ADMIN: "admin",
  ADMIN_MEMBER: "admin_member",
  COMPANY_USER: "company_user",
};

export type TUserType = valueOf<typeof USER_TYPE>;

export const ADMIN_ROLE = {
  MANAGER: "manager",
  SALE: "company_sale",
  CUSTOM_SERVICE: "custom_service",
  AUDITOR: "auditor",
};

export type TAdminRole = valueOf<typeof ADMIN_ROLE>;

export const EMPLOYMENT_STATUS = {
  OFFERED: "offered",
  WORKING: "working",
  LEFT: "left",
};

export type TEmploymentStatus = valueOf<typeof EMPLOYMENT_STATUS>;

export const RETIREMENT_STATUS = {
  ENROLLMENT: "enrollment",
  PROCESSING: "processing",
  RETIRED: "retired",
};

export type TRetirementStatus = valueOf<typeof RETIREMENT_STATUS>;

export const COMPANY_DIVISION = {
  CORPORATE: "corporate",
  SOLE_PROPRIETOR: "sole_proprietor",
};

export type TCompanyDivision = valueOf<typeof COMPANY_DIVISION>;

export const GENDER = {
  MALE: "male",
  FEMALE: "female",
} as const;

export type TGender = valueOf<typeof GENDER>;

export const GENDER_LABEL = {
  [GENDER.MALE]: "男性",
  [GENDER.FEMALE]: "女性",
};

export const DOCUMENT_KEYS = {
  ACCEPTANCE_ACTIVITY: "acceptance_activity",
  LIFE_SUPPORT: "life_support",
  ANY_TIME: "any_time",
  RETIREMENT_ACCEPTING_WORKERS: "retirement_accepting_workers",
  RETIREMENT_EMPLOYMENT_CONTRACT: "retirement_employment_contract",
};

export type TDocumentKey = valueOf<typeof DOCUMENT_KEYS>;

export const DOCUMENT_STEP_KEYS = {
  ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT: "acceptance_activity_create_document",
  ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT: "acceptance_activity_upload_document",
  ACCEPTANCE_ACTIVITY_SUBMIT: "acceptance_activity_submit",
  LIFE_SUPPORT_CREATE_DOCUMENT: "life_support_create_document",
  LIFE_SUPPORT_CONFIRM_DISCUSSION_RECORD: "life_support_confirm_discussion_record",
  LIFE_SUPPORT_SUBMIT: "life_support_activity_submit",
  ANY_TIME_CREATE_DOCUMENT: "any_time_create_document",
  ANY_TIME_UPLOAD_DOCUMENT: "any_time_upload_document",
  ANY_TIME_SUBMIT: "any_time_submit",
  RENEW_VISA_BASIC_INFORMATION: "renew_visa_basic_information",
  RENEW_VISA_CREATE_DOCUMENT: "renew_visa_create_document",
  RENEW_VISA_SUBMIT: "renew_visa_submit",
  RENEW_VISA_AFTER_SUBMIT_APPLICATION: "renew_visa_after_submit_application",
  RENEW_VISA_UPDATE_RESIDENCE_CARD: "renew_visa_update_residence_card",
  RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT: "retirement_accepting_workers_create_document",
  RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST: "retirement_accepting_workers_create_convert_request",
  RETIREMENT_ACCEPTING_WORKERS_SUBMIT: "retirement_accepting_workers_submit",
  RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT: "retirement_employment_contract_create_document",
  RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST: "retirement_employment_contract_convert_request",
  RETIREMENT_EMPLOYMENT_CONTRACT_SUBMIT: "retirement_employment_contract_submit",
};

export type TDocumentStepKey = valueOf<typeof DOCUMENT_STEP_KEYS>;

export const DOCUMENT_GROUPS = {
  [DOCUMENT_KEYS.ACCEPTANCE_ACTIVITY]: [
    DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT,
    DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT,
    DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_SUBMIT,
  ],
  [DOCUMENT_KEYS.LIFE_SUPPORT]: [
    DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT,
    DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CONFIRM_DISCUSSION_RECORD,
    DOCUMENT_STEP_KEYS.LIFE_SUPPORT_SUBMIT,
  ],
  [DOCUMENT_KEYS.ANY_TIME]: [DOCUMENT_STEP_KEYS.ANY_TIME_CREATE_DOCUMENT, DOCUMENT_STEP_KEYS.ANY_TIME_SUBMIT],
  [DOCUMENT_KEYS.RETIREMENT_ACCEPTING_WORKERS]: [
    DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_DOCUMENT,
    DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_CREATE_CONVERT_REQUEST,
    DOCUMENT_STEP_KEYS.RETIREMENT_ACCEPTING_WORKERS_SUBMIT,
  ],
  [DOCUMENT_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT]: [
    DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT,
    DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST,
    DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_SUBMIT,
  ],
};

export const DOCUMENT_STATUS = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
  CONVERTING: "converting",
  CONVERT_COMPLETE: "convert_complete",
  ERROR: "error",
  SKIP: "skip",
};

export type TDocumentStatus = valueOf<typeof DOCUMENT_STATUS>;

export const DOCUMENT_STATUS_LABEL = {
  [DOCUMENT_STATUS.IN_PROGRESS]: "未完了",
  [DOCUMENT_STATUS.COMPLETE]: "編集完了",
  [DOCUMENT_STATUS.CONVERTING]: "変換中",
  [DOCUMENT_STATUS.CONVERT_COMPLETE]: "変換完了",
  [DOCUMENT_STATUS.ERROR]: "エラー",
  [DOCUMENT_STATUS.SKIP]: "スキップ",
};

export const CONVERT_STATUS = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
  ERROR: "error",
};

export type TConvertStatus = valueOf<typeof CONVERT_STATUS>;

export const CONVERT_STATUS_LABEL = {
  [CONVERT_STATUS.COMPLETE]: "編集完了",
  [CONVERT_STATUS.IN_PROGRESS]: "未完了",
  [CONVERT_STATUS.ERROR]: "エラー",
};

export const EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS = {
  INCOMPLETE: "incomplete",
  PROCESSING: "processing",
  CREATING: "creating",
  COMPLETE: "complete",
  ERROR: "error",
};

export type TEmployeeRetirementDocumentStepStatus = valueOf<typeof EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS>;

export const EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS_LABEL = {
  [EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.INCOMPLETE]: "未完了",
  [EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.PROCESSING]: "処理中",
  [EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.CREATING]: "作成中",
  [EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.COMPLETE]: "完了",
  [EMPLOYEE_RETIREMENT_DOCUMENT_STEP_STATUS.ERROR]: "エラー",
};

export const RENEW_VISA_DOCUMENT_STATUS = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
};

export const CONVERT_DOCUMENT_STATUS = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
  ERROR: "error",
  SKIP: "skip",
  CONVERTING: "converting",
  COMPLETED_CONVERT: "completed_convert",
};

export type TConvertDocumentStatus = valueOf<typeof CONVERT_DOCUMENT_STATUS>;

export const CONVERT_DOCUMENT_STATUS_LABEL = {
  [CONVERT_DOCUMENT_STATUS.COMPLETE]: "編集完了",
  [CONVERT_DOCUMENT_STATUS.IN_PROGRESS]: "未完了",
  [CONVERT_DOCUMENT_STATUS.ERROR]: "エラー",
  [CONVERT_DOCUMENT_STATUS.CONVERTING]: "変換中",
  [CONVERT_DOCUMENT_STATUS.COMPLETED_CONVERT]: "変換完了",
};

export const SUPPORT_IMPLEMENTATION_STATUS = {
  SUPPORTED: "supported",
  NOT_SUPPORTED: "not_supported",
  NO_PARTICIPANT: "no_participant",
};

export type TSupportImplementationStatus = valueOf<typeof SUPPORT_IMPLEMENTATION_STATUS>;

export const SUPPORT_IMPLEMENTATION_STATUS_OPTIONS = [
  { label: "実施", value: SUPPORT_IMPLEMENTATION_STATUS.SUPPORTED },
  { label: "未実施", value: SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED },
  { label: "支援対象者なし", value: SUPPORT_IMPLEMENTATION_STATUS.NO_PARTICIPANT },
];

export const EMPLOYEE_TRAINING_STATUS = {
  UN_SELECTED: "un_selected",
  YES: "yes",
  NO: "no",
};

export type TEmployeeTrainingStatus = valueOf<typeof EMPLOYEE_TRAINING_STATUS>;

export const EMPLOYEE_CAREER_LOCATION = {
  JAPAN: "japan",
  OUTSIDE_JAPAN: "outside_japan",
};

export type TEmployeeCareerLocation = valueOf<typeof EMPLOYEE_CAREER_LOCATION>;

export const EMPLOYEE_RESIDENCE_STATUS = {
  SPECIFIC_SKILL_ONE: "specific_skill_one",
  SPECIFIC_SKILL_TWO: "specific_skill_two",
  SPECIFIC_ACTIVITY: "specific_activity",
};

export type TEmployeeResidenceStatus = valueOf<typeof EMPLOYEE_RESIDENCE_STATUS>;

export const EMPLOYEE_RESIDENCE_PERIOD = {
  ONE_MONTH: "one_month",
  TWO_MONTH: "two_month",
  THREE_MONTH: "three_month",
  FOUR_MONTH: "four_month",
  FIVE_MONTH: "five_month",
  SIX_MONTH: "six_month",
  SEVEN_MONTH: "seven_month",
  EIGHT_MONTH: "eight_month",
  NINE_MONTH: "nine_month",
  TEN_MONTH: "ten_month",
  ELEVEN_MONTH: "eleven_month",
  TWELVE_MONTH: "twelve_month",
  ONE_YEAR: "one_year",
  THREE_YEAR: "three_year",
  FIVE_YEAR: "five_year",
};

export type TEmployeeResidencePeriod = valueOf<typeof EMPLOYEE_RESIDENCE_PERIOD>;

export const MAINTENANCE_MODE = {
  SYSTEM_NORMAL_OPERATION: "system_normal_operation",
  MAINTENANCE_READABLE: "maintenance_readable",
  MAINTENANCE_UNUSABLE: "maintenance_unusable",
};

export const SUPPORT_SPECIFIED_SKILL = {
  NAME_OR_TITLE: "name_or_title",
  ADDRESS: "address",
  CORPORATE_NUMBER: "corporate_number",
  OTHER: "other",
  SUPPORT_MANAGER_NAME_AND_POSITION: "support_manager_name_and_position",
  NUMBER_OF_FOREIGN_WORKERS: "number_of_foreign_workers",
  NUMBER_OF_SUPPORT_STAFF: "number_of_support_staff",
  OFFICE_LOCATION: "office_location",
};

export const YES_NO_ANSWER = {
  YES: "yes",
  NO: "no",
};

export type TYesNoAnswer = valueOf<typeof YES_NO_ANSWER>;

export const YES_NO_AND_NOT_APPLICABLE_ANSWER = {
  YES: "yes",
  NO: "no",
  NOT_APPLICABLE: "not_applicable",
};

export type TYesNoAndNotApplicableAnswer = valueOf<typeof YES_NO_AND_NOT_APPLICABLE_ANSWER>;

export const COMPANY_SUPPORTER_ROLE = {
  CONTACT: "contact",
  SUPPORT_MANAGER: "support_manager",
  SUPPORT: "support",
};

export const RESIDENCE_STATUS = {
  SPECIFIED_SKILL_NO1: 1,
  SPECIFIED_SKILL_NO2: 2,
  SPECIFIED_ACTIVITY: 3,
} as const;

export type TResidenceStatus = valueOf<typeof RESIDENCE_STATUS>;
