import {
  AnytimeRoutes,
  AppRoutes,
  CompanyRoutes,
  ContactRoutes,
  EmployeeRoutes,
  RegularRoutes,
  RenewVisaRoutes,
  RetirementRoutes,
} from "@/routes/config";

export const menus = [
  {
    text: "ホーム",
    path: AppRoutes.home,
    relatedRoutes: [AppRoutes.home],
  },
  {
    text: "定期届出",
    path: AppRoutes.regular,
    relatedRoutes: RegularRoutes,
  },
  {
    text: "在留期間更新",
    path: AppRoutes.renewVisa,
    relatedRoutes: RenewVisaRoutes,
  },
  // === Out of MVP Scope
  // {
  //   text: "入社手続き",
  //   path: AppRoutes.procedure,
  //   relatedRoutes: [AppRoutes.procedure],
  // },
  {
    text: "退職手続き",
    path: AppRoutes.retirement,
    relatedRoutes: RetirementRoutes,
  },
  {
    text: "その他の随時届出",
    path: AppRoutes.anytime,
    relatedRoutes: AnytimeRoutes,
  },
  // === Out of MVP Scope
  // {
  //   text: "面談・相談記録",
  //   path: AppRoutes.interview,
  //   relatedRoutes: [AppRoutes.interview],
  // },
  // === Out of MVP Scope
  // {
  //   text: "生活支援",
  //   path: AppRoutes.lifeSupport,
  //   relatedRoutes: [AppRoutes.lifeSupport],
  // },
  "|",
  {
    text: "特定技能外国人の一覧",
    path: AppRoutes.employeeList,
    relatedRoutes: EmployeeRoutes,
  },
  {
    text: "会社情報",
    path: AppRoutes.company,
    relatedRoutes: CompanyRoutes,
  },

  // === Not found design
  "booking",
  {
    text: "お問い合わせ",
    path: AppRoutes.contact,
    relatedRoutes: ContactRoutes,
  },
] as const;
