import { Notification } from "@/components/commons/notification/Notification";
import { InnerBox } from "@/components/elements/InnerBox";
import { GuideStepper } from "@/features/dashboard/components/GuideStepper";
import { TopAlert } from "@/features/dashboard/components/TopAlert";
import { TaskBoard } from "@/features/dashboard/components/tasks/TaskBoard";
import { DashboardProvider } from "@/features/dashboard/providers/DashboardProvider";
import { InitSetupProvider } from "@/providers/InitSetupProvider";
import { Stack } from "@mui/material";
import { FC } from "react";

type TDashboardPageProps = {};

export const DashboardPage: FC<TDashboardPageProps> = () => {
  return (
    <DashboardProvider>
      <Stack gap={3}>
        <TopAlert />
        <InitSetupProvider>
          <GuideStepper />
        </InitSetupProvider>
        <InnerBox title="マイタスク">
          <TaskBoard />
        </InnerBox>
        <InnerBox title="お知らせ" bordered={false}>
          <Notification isHideContent={true} />
        </InnerBox>
      </Stack>
    </DashboardProvider>
  );
};
