import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TButtonGroupProps = {
  saveDraft?: () => void;
  editable?: boolean;
  setEditable?: (v: boolean) => void;
};

export const ButtonGroup: FC<TButtonGroupProps> = ({ saveDraft, editable, setEditable }) => {
  const { documentId } = useParams();

  return (
    <InnerBox>
      <Stack justifyContent="center" gap={2} direction="row">
        {editable && (
          <>
            <Button onClick={saveDraft} size="lg" variant="outline">
              下書きを保存する
            </Button>
            <Button type="submit" size="lg">
              保存する
            </Button>
          </>
        )}
        {!editable && (
          <>
            <Button
              variant="outline"
              size="lg"
              onClick={() => {
                setEditable?.(true);
              }}
            >
              再編集する
            </Button>
            <Button size="lg" component={Link} to={getDynamicRoute(AppRoutes.anytimeDocument, { id: documentId })}>
              届出トップに戻る
            </Button>
          </>
        )}
      </Stack>
    </InnerBox>
  );
};
