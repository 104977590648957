import { IconAlert } from "@/assets/icons";
import { useFormSchema } from "@/components/elements/Form/Form";
import { Tooltip } from "@/components/elements/Tooltip";
import { text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { FormControl, FormControlProps, FormHelperText, FormLabel, Typography, css, styled } from "@mui/material";
import { createContext, ReactElement, useContext, useMemo } from "react";
import { Control, Controller, ControllerFieldState, ControllerRenderProps, FieldValues, Path, UseFormStateReturn } from "react-hook-form";

type TFormFieldType = {
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

export const FormFieldContext = createContext<Partial<TFormFieldType>>({});
export const useFormField = (nonControl = false) => (nonControl ? {} : useContext(FormFieldContext));

type FormFieldProps<T extends FieldValues> = {
  name?: Path<T>;
  control?: Control<T>;
  label?: string;
  helper?: string;
  helperTile?: string;
  helperContent?: ReactElement;
  required?: boolean;
  largeLabel?: boolean;
  errorTextAlign?: "left" | "right";
  breakLineLabel?: boolean;
} & FormControlProps;

export const FormField = <T extends FieldValues>({
  name,
  control,
  label,
  children,
  helper,
  required,
  helperTile,
  helperContent,
  largeLabel,
  errorTextAlign = "left",
  breakLineLabel = true,
  ...rest
}: FormFieldProps<T>) => {
  const fieldsMeta = useFormSchema();

  const fieldLabel = useMemo(() => {
    if (typeof label === "string") return label;
    if (name && fieldsMeta[name]) return fieldsMeta[name].label;
    return "";
  }, [label, fieldsMeta, name]);

  const fieldRequired = useMemo(() => {
    if (required) return true;
    if (name && fieldsMeta[name]) return fieldsMeta[name].required;
    return false;
  }, [required, fieldsMeta]);

  if (!control || !name) {
    return (
      <StyledFormControl {...rest}>
        {fieldLabel && (
          <FormLabel required={fieldRequired}>
            <Typography variant={largeLabel ? "sub16Semi" : "cap12"}>{fieldLabel}</Typography>
            {helper && (
              <Tooltip title={helperTile} content={helperContent}>
                <IconAlert />
              </Tooltip>
            )}
          </FormLabel>
        )}
        {children}
      </StyledFormControl>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={(controller) => {
        const { fieldState } = controller;
        return (
          <FormFieldContext.Provider value={controller as unknown as TFormFieldType}>
            <StyledFormControl error={Boolean(fieldState.error)} {...rest}>
              {fieldLabel && (
                <FormLabel required={fieldRequired}>
                  <Typography variant={largeLabel ? "sub16Semi" : "cap12"} whiteSpace={breakLineLabel ? "pre-line" : "none"}>
                    {fieldLabel}
                  </Typography>
                  {helper && (
                    <Tooltip title={helperTile} content={helperContent}>
                      <IconAlert />
                    </Tooltip>
                  )}
                </FormLabel>
              )}
              {children}
              {fieldState.error && <FormHelperText sx={{ textAlign: errorTextAlign }}>{fieldState.error.message}</FormHelperText>}
            </StyledFormControl>
          </FormFieldContext.Provider>
        );
      }}
    />
  );
};

const StyledFormControl = styled(FormControl)`
  width: 100%;
  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-error {
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${text.primary};
    margin-bottom: 4px;
    ${css(typography.cap12)};
    white-space: nowrap;
    &.Mui-focused {
      color: ${text.primary};
    }
  }
  .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    ${css(typography.cap12)};
  }
  .MuiFormLabel-asterisk {
    color: ${text.error};
  }
`;
