import { useRegularDocumentHistoryDetail } from "@/api/services/main/regular-document";
import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { Document } from "@/features/regular-document/components/documents";
import { useGetTargetQuarter } from "@/features/regular-document/hooks/useGetTargetQuarter";
import { AppRoutes } from "@/routes/config";
import { button } from "@/theme/colors";
import { Stack, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";

type TDocumentDownloadProps = {
  year: number;
  quarter: number;
  onClose: () => void;
};

export const DocumentDownload: FC<TDocumentDownloadProps> = ({ year, quarter, onClose }) => {
  const { data: documentResult, isLoading } = useRegularDocumentHistoryDetail(year, quarter);
  const documentData = documentResult?.data;
  const contentToPrint = useRef(null);

  const { targetQuarter } = useGetTargetQuarter({ year, quarter });

  const renderDocs = useMemo(() => {
    if (!documentData) return null;
    documentData.regularDocuments.sort((a, b) => a.masterDocument.documentOrder - b.masterDocument.documentOrder);
    documentData.regularDocuments.forEach((document) =>
      document.steps.sort((a, b) => a.masterDocumentStep.stepOrder - b.masterDocumentStep.stepOrder),
    );
    return documentData.regularDocuments.map((document) => (
      <Document key={document.id} masterData={document.masterDocument} document={document} readonly />
    ));
  }, [documentData]);

  const handlePrint = useReactToPrint({
    documentTitle: `regular_history_${year}_${quarter}`,
    content: () => contentToPrint.current,
    onAfterPrint: () => {
      window.close();
      onClose();
    },
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (contentToPrint.current && documentData) {
      handlePrint();
    }
  }, [documentData, isLoading]);

  if (!documentData || isLoading) return null;

  return (
    <Stack display="none">
      <TopPage title="過去の定期届出" subTitle="過去の資料を確認・ダウンロードできます。" backUrl={AppRoutes.regularHistory} />
      <div ref={contentToPrint}>
        <InnerBox padding={3}>
          <Stack gap={3}>
            <Typography variant="head20Bold" color={button.pressed}>
              {targetQuarter}
            </Typography>
            {renderDocs}
          </Stack>
        </InnerBox>
      </div>
    </Stack>
  );
};
