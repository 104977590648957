import { TCreateMultiAnyTimeFileFileReqDto } from "@/api/services/main/any-time/dtos/create-multi-anytime-files.dto";
import { TFinishUploadDocumentStepResDto } from "@/api/services/main/any-time/dtos/finish-upload-document-step.dto";
import { TGetAnyTimeDocumentResDto } from "@/api/services/main/any-time/dtos/get-any-time-document";
import { TGetAnyTimeDocumentListReqDto, TGetAnyTimeDocumentListResDto } from "@/api/services/main/any-time/dtos/get-any-time-document-list.dto";
import { TGetAnytimeFileListReqDto, TGetAnytimeFileListResDto } from "@/api/services/main/any-time/dtos/get-any-time-file-list";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiRes } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const getAnyTimeDocumentApi = async (documentId: string | number): Promise<TGetAnyTimeDocumentResDto> => {
  const rs = await clientApi.get<TGetAnyTimeDocumentResDto>(`/any-time-documents/${documentId}`);
  return rs.data;
};

export const useAnytimeDocument = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_DOCUMENT, id],
    queryFn: () => (id ? getAnyTimeDocumentApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const getAnyTimeDocumentListApi = async (req: TGetAnyTimeDocumentListReqDto): Promise<TGetAnyTimeDocumentListResDto> => {
  const url = createUrlWithParams("/any-time-documents", req);
  const rs = await clientApi.get<TGetAnyTimeDocumentListResDto>(url);
  return rs.data;
};

export const useAnytimeDocumentList = () => {
  const pag = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_LIST, pag],
    queryFn: () => getAnyTimeDocumentListApi(pag),
  });
};

export const setCompleteAnytimeDocumentApi = (documentId: string | number) => {
  return clientApi.post(`/any-time-documents/${documentId}/set-complete`);
};

export const finishSubmitDocumentStepApi = (documentStepId: string | number) => {
  return clientApi.post(`/any-time-documents/finish-submit-document-step`, { documentStepId: Number(documentStepId) });
};

export const finishUploadDocumentStepApi = async (documentStepId: number | string): Promise<TFinishUploadDocumentStepResDto> => {
  const rs = await clientApi.post<TFinishUploadDocumentStepResDto>("/any-time-documents/finish-upload-document-step", {
    documentStepId: Number(documentStepId),
  });
  return rs.data;
};

export const createMultiAnytimeFilesApi = (documentId: string | number, payload: TCreateMultiAnyTimeFileFileReqDto) => {
  return clientApi.post(`/any-time-documents/${documentId}/files`, payload);
};

export const fetchAnytimeFileListApi = async (documentId: string | number, req: TGetAnytimeFileListReqDto) => {
  const url = createUrlWithParams(`/any-time-documents/${documentId}/files`, req);
  const rs = await clientApi.get<TGetAnytimeFileListResDto>(url);
  return rs.data;
};

export const useAnytimeFileList = () => {
  const { id } = useParams();
  return useQuery({
    queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_FILE_LIST, id],
    queryFn: () => (id ? fetchAnytimeFileListApi(id, {}) : undefined),
    enabled: Boolean(id),
  });
};

export const deleteAnytimeFileApi = async (documentId: string | number, fileId: string | number) => {
  const rs = await clientApi.delete<TApiRes<boolean>>(`/any-time-documents/${documentId}/files/${fileId}`);
  return rs.data;
};

export const deleteAnytimeDocumentApi = async (documentId: string | number) => {
  const rs = await clientApi.delete<TApiRes<boolean>>(`/any-time-documents/${documentId}`);
  return rs.data;
};
