import { createContactApi } from "@/api/services/main/contact";
import { useContactTypeList } from "@/api/services/main/contact-type";
import { Button } from "@/components/elements/Button";
import { DropZone } from "@/components/elements/DropZone";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { Textarea } from "@/components/elements/Textarea";
import { FILE_UPLOAD_FOR } from "@/constants/file";
import { ConfirmReplaceContent } from "@/features/contact/components/ConfirmReplaceContent";
import { ContactSchema } from "@/features/contact/schema/contactSchema";
import { TContactForm } from "@/features/contact/types/contactForm";
import { convertContactFormDataToReqDto } from "@/features/contact/utils/convertFormDataToReqDto";
import { useDialog } from "@/hooks/useDialog";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { TExtendFile } from "@/types/file";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TContactFormProps = {
  companyName: string;
  userName: string;
  previousValues: Partial<TContactForm>;
};

const initForm: Partial<TContactForm> = {
  companyId: undefined,
  createdBy: undefined,
  contactTypeId: undefined,
  content: "",
  email: "",
  telNumber: undefined,
  filePath: null,
};

export const ContactForm: FCC<TContactFormProps> = ({ companyName, userName, previousValues }) => {
  const navigate = useNavigate();
  const confirmReplace = useDialog();
  const { data: contactTypeListResult } = useContactTypeList();
  const contactTypeOptions = contactTypeListResult?.data.map((contactType) => ({
    label: contactType.name,
    value: contactType.id,
  }));

  const methods = useForm<TContactForm>({
    defaultValues: { ...initForm, ...previousValues },
    resolver: yupResolver(ContactSchema),
    mode: "onBlur",
  });
  const {
    control,
    setValue,
    formState: { dirtyFields },
  } = methods;
  const contactTypeId = useWatch({ control, name: "contactTypeId" });
  const filePathField = useWatch({ control, name: "filePath" });

  useEffect(() => {
    if (contactTypeId) {
      if (Boolean(dirtyFields.content)) confirmReplace.show();
      else handleChangeTemplate();
    }
  }, [contactTypeId]);

  const handleChangeTemplate = () => {
    if (contactTypeListResult && contactTypeListResult.data) {
      const selectedContactType = contactTypeListResult.data.find((contactType) => String(contactType.id) === contactTypeId);
      if (selectedContactType) {
        setValue("content", selectedContactType.template ?? "");
        confirmReplace.cancel();
      }
    }
  };

  const onChange = async (value: TExtendFile[] | TExtendFile | null) => {
    const dropFile = [value].flat().at(0);
    if (!dropFile) return;
    setValue("filePath", dropFile.filePath ?? null);
  };

  const onSubmit = async (data: TContactForm) => {
    try {
      const payload = convertContactFormDataToReqDto(data);
      const rs = await createContactApi(payload);
      toast.success(rs.message ?? "お問い合わせを送信しました");
      navigate(AppRoutes.home);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };
  return (
    <>
      <Form methods={methods} onSubmit={onSubmit} schema={ContactSchema}>
        <Stack gap={2}>
          <InnerBox padding={4}>
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormField control={control} name="companyId">
                    <Select readonly options={[{ label: companyName, value: String(previousValues.companyId) }]} />
                  </FormField>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="createdBy">
                    <Select readonly options={[{ label: userName, value: String(previousValues.createdBy) }]} />
                  </FormField>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="contactTypeId">
                    <Select options={contactTypeOptions || []} />
                  </FormField>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                  <FormField control={control} name="content">
                    <Textarea />
                  </FormField>
                </Grid>
                <Grid item xs={4}>
                  <Stack paddingBottom="4px">
                    <Typography variant="cap12">添付ファイル</Typography>
                  </Stack>
                  <DropZone
                    uploadFor={FILE_UPLOAD_FOR.CONTACT_FILE}
                    onChange={onChange}
                    options={{ multiple: false }}
                    bordered={false}
                    disabled={!!filePathField}
                  >
                    <Button fullWidth disabled={!!filePathField}>
                      ファイルを選択
                    </Button>
                  </DropZone>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="email">
                    <Input />
                  </FormField>
                </Grid>
                <Grid item xs={6}>
                  <FormField control={control} name="telNumber">
                    <Input />
                  </FormField>
                </Grid>
              </Grid>
            </Stack>
          </InnerBox>
          <InnerBox>
            <Stack alignItems="end">
              <Button type="submit" variant="primary">
                送信
              </Button>
            </Stack>
          </InnerBox>
        </Stack>
      </Form>
      <ConfirmReplaceContent open={confirmReplace.open} onClose={confirmReplace.cancel} onOk={handleChangeTemplate} />
    </>
  );
};
