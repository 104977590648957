import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useGetRenewVisaBasicInformationDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { BasicInfoStepCard } from "@/features/renew-visa/components/basicInfoStep/BasicInfoStepCard";
import { FC } from "react";

export type TRenewVisaBasicInfoStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
  readonly: boolean;
};

export const RenewVisaBasicInfoStep: FC<TRenewVisaBasicInfoStepProps> = ({ stepData, readonly }) => {
  const { id: documentStepId, updater } = stepData;
  const { data: basicInfoData, isLoading } = useGetRenewVisaBasicInformationDocumentInfo({ documentStepId, enabled: !!updater });
  if (isLoading) return <LoadingArea />;
  return <BasicInfoStepCard stepData={stepData} basicInfoData={basicInfoData} readonly={readonly} />;
};
