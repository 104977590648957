import { IconClose } from "@/assets/icons";
import { IconOutlineInfo } from "@/assets/icons/IconOutlineInfo";
import { IconBox } from "@/components/elements/IconBox";
import { background, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { Box, Tooltip as MuiTooltip, Stack, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { FC, ReactNode, useState } from "react";

export type TTooltipProps = {
  title?: string;
  content?: ReactNode;
} & Omit<TooltipProps, "title" | "content">;

export const Tooltip: FC<TTooltipProps> = ({ title, content, placement = "top", ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <StyledTooltip
      open={open}
      onClose={handleClose}
      placement={placement}
      arrow
      title={
        <Stack gap={2}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <IconOutlineInfo fontSize={24} />
              <Typography variant="body14Bold">{title || "留意事項"}</Typography>
            </Stack>
            <IconBox onClick={handleClose}>
              <IconClose fontSize={24} />
            </IconBox>
          </Stack>
          <Typography variant="body14">{content}</Typography>
        </Stack>
      }
      {...rest}
    >
      <Box sx={{ cursor: "pointer", height: "fit-content" }} onClick={handleToggle}>
        {rest.children}
      </Box>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    backgroundColor: background.white,
    color: text.primary,
    padding: spacing.sm,
    borderRadius: rounded.sm,
    boxShadow: "0px 2px 8px 0px #00000040",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
    marginTop: "4px",
    marginLeft: "4px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: background.white,
  },
}));
