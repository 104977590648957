import { InnerBox } from "@/components/elements/InnerBox";
import { background, primary } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, Stack, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

export type RetirementStepCardProps = {
  title: string;
  subTitle: string;
  buttons?: ReactNode;
  description?: ReactNode;
};

export const RetirementStepCard: FCC<RetirementStepCardProps> = ({ title, subTitle, buttons, description, children }) => {
  return (
    <InnerBox padding="0">
      <CardHeader>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack flexDirection="row" alignItems="center" gap={3}>
            <Typography color={primary[300]} variant="sub16Bold">
              {title}
            </Typography>
            <Typography variant="sub16Bold">{subTitle}</Typography>
          </Stack>
          {buttons}
        </Stack>
        {description}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </InnerBox>
  );
};

const CardHeader = styled(Box)`
  background-color: ${background.secondary};
  padding: 16px 24px;
  border-top-left-radius: ${rounded.sm};
  border-top-right-radius: ${rounded.sm};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CardBody = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
