import {
  createRetirementAcceptingWorkerDocumentApi,
  updateRetirementAcceptingWorkerDocumentApi,
} from "@/api/services/main/retirement-document/retirement-accepting-worker-document";
import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { ButtonGroup } from "@/features/retirement/components/retirement-detail/accepting-worker/ButtonGroup";
import { CircumstanceExplanation } from "@/features/retirement/components/retirement-detail/accepting-worker/CircumstanceExplanation";
import { CurrentSituation } from "@/features/retirement/components/retirement-detail/accepting-worker/CurrentSituation";
import { RetirementEmployeeForm } from "@/features/retirement/components/retirement-detail/accepting-worker/forms/RetirementEmployeeForm";
import { LegalProtectionStatus } from "@/features/retirement/components/retirement-detail/accepting-worker/LegalProtectionStatus";
import { MeasuresTaken } from "@/features/retirement/components/retirement-detail/accepting-worker/MeasuresTaken";
import { SpecificSkillManagingAgency } from "@/features/retirement/components/retirement-detail/accepting-worker/SpecificSkillManagingAgency";
import { TargetForeigner } from "@/features/retirement/components/retirement-detail/accepting-worker/TargetForeigner";
import { CompanySupporterForm } from "@/features/retirement/components/retirement-detail/accepting-worker/CompanySupporterForm";
import { retirementAcceptingWorkerSchema } from "@/features/retirement/schema/retirementAcceptingWorkerSchema";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { convertFormDataToReqDto } from "@/features/retirement/utils/retirement-accepting-worker";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "@/utils/toast";

type TRetirementAcceptingWorkerEditPageProps = {
  defaultValues?: Partial<TRetirementAcceptingWorkerForm>;
  isCreating: boolean;
  isDraft: boolean;
};

export const RetirementAcceptingWorkerPage: FC<TRetirementAcceptingWorkerEditPageProps> = ({ defaultValues, isDraft, isCreating = true }) => {
  const { documentGroupId, id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TRetirementAcceptingWorkerForm>({
    defaultValues,
    resolver: yupResolver(retirementAcceptingWorkerSchema),
  });
  const { getValues } = methods;
  const [editable, setEditable] = useState(isCreating || isDraft);

  const handleSave = async (data: TRetirementAcceptingWorkerForm, isDraft: boolean) => {
    try {
      if (!id) return;
      const payload = convertFormDataToReqDto(data, isDraft);
      if (isCreating) {
        const rs = await createRetirementAcceptingWorkerDocumentApi(Number(id), payload);
        navigate(getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId }));
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await updateRetirementAcceptingWorkerDocumentApi(Number(id), payload);
        navigate(getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId }));
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_ACCEPTING_WORKER] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_DOCUMENT, documentGroupId] });

      if (isDraft) {
        methods.unregister();
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TRetirementAcceptingWorkerForm) => {
    await handleSave(data, false);
  };

  const saveDraft = async () => {
    await handleSave(getValues() as TRetirementAcceptingWorkerForm, true);
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={retirementAcceptingWorkerSchema}>
      <TopPage title="受入れ困難に係る届出" backUrl={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })} isDraft={isDraft} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <RetirementEmployeeForm readonly={!editable} /> },
          { title: "届出の事由", content: <TargetForeigner readonly={!editable} /> },
          { title: "特定技能外国人の現状", content: <CurrentSituation readonly={!editable} /> },
          { title: "受け入れ継続のための措置", content: <MeasuresTaken readonly={!editable} /> },
          { title: "特定技能所属機関", content: <SpecificSkillManagingAgency readonly={!editable} /> },
          { title: "受入れ困難となるに至った経緯に係る説明書（参考様式第５－１１号）", content: <CircumstanceExplanation readonly={!editable} /> },
          { title: "法的保護のための案内実施の有無", content: <LegalProtectionStatus readonly={!editable} /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly={!editable} /> },
        ]}
        contentAffix={<ButtonGroup saveDraft={saveDraft} isEditable={editable} setIsEditable={setEditable} />}
      />
    </Form>
  );
};
