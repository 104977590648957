import { useEmployeeRetirementDocument } from "@/api/services/main/retirement-document";
import { Document } from "@/features/retirement/components/retirement-document/documents";
import { RetirementEmployeeDetail } from "@/features/retirement/components/retirement-document/RetirementEmploymentDetail";
import { DOCUMENT_STATUS } from "@/types/enum";
import { Stack } from "@mui/material";
import { FC, useMemo } from "react";

export type TDocumentsContentProps = {};

export const DocumentsContent: FC<TDocumentsContentProps> = () => {
  const { data: retirementDocumentResult } = useEmployeeRetirementDocument();
  const documentData = retirementDocumentResult?.data.retirementDocuments;
  const isCompleted = documentData?.every((document) => document.status === DOCUMENT_STATUS.COMPLETE);
  const renderDocs = useMemo(() => {
    if (!documentData) return null;
    documentData.sort((a, b) => a.masterDocument.documentOrder - b.masterDocument.documentOrder);
    documentData.forEach((document) => document.steps.sort((a, b) => a.masterDocumentStep.stepOrder - b.masterDocumentStep.stepOrder));
    return documentData.map((document) => <Document key={document.documentGroupId} document={document} readonly={isCompleted} />);
  }, [documentData]);
  return (
    <Stack gap={4}>
      <RetirementEmployeeDetail employeeId={retirementDocumentResult?.data.retirementDocuments[0].employeeId} readonly={isCompleted} />
      <Stack gap={3}>{renderDocs}</Stack>
    </Stack>
  );
};
