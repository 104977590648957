import { EmployeeOfferTaskCard } from "@/features/dashboard/components/tasks/EmployeeOfferTaskCard";
import { PlanningRetireTasks } from "@/features/dashboard/components/tasks/PlanningRetireTasks";
import { RegularTaskCard } from "@/features/dashboard/components/tasks/RegularTaskCard";
import { RenewVisaTaskCard } from "@/features/dashboard/components/tasks/RenewVisaTaskCard";
import { Grid } from "@mui/material";
import { FC } from "react";

type TTaskBoardProps = {};

export const TaskBoard: FC<TTaskBoardProps> = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RegularTaskCard />
      </Grid>
      <Grid item xs={6}>
        <RenewVisaTaskCard />
      </Grid>
      <Grid item xs={6}>
        <EmployeeOfferTaskCard />
      </Grid>
      <Grid item xs={6}>
        <PlanningRetireTasks />
      </Grid>
    </Grid>
  );
};
