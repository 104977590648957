import { createAnytimeSupportPlanApi } from "@/api/services/main/any-time/support-plan";
import { Form } from "@/components/elements/Form";
import { InnerBox } from "@/components/elements/InnerBox";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { ButtonGroup } from "@/features/anytime/components/anytime-detail/ButtonGroup";
import { CompanySupporterForm } from "@/features/anytime/components/anytime-detail/CompanySupporterForm";
import { EligibleForeignNationals } from "@/features/anytime/components/anytime-detail/EligibleForeignNationals";
import { SpecificSkillsAffiliation } from "@/features/anytime/components/anytime-detail/SpecificSkillsAffiliation";
import { ChangesForm } from "@/features/anytime/components/anytime-detail/support-plan/forms/ChangesForm";
import { ConclusionForm } from "@/features/anytime/components/anytime-detail/support-plan/forms/ConclusionForm";
import { TerminationForm } from "@/features/anytime/components/anytime-detail/support-plan/forms/TerminationForm";
import { supportPlanSchema } from "@/features/anytime/schema/supportPlanSchema";
import { TSupportPlanForm } from "@/features/anytime/types/supportPlanForm";
import { convertFormDataToReqDto } from "@/features/anytime/utils/support-plan";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TAnytimeSupportPlanCreatePageProps = {
  defaultValues: Partial<TSupportPlanForm>;
};

export const AnytimeSupportPlanCreatePage: FC<TAnytimeSupportPlanCreatePageProps> = ({ defaultValues }) => {
  const methods = useForm<TSupportPlanForm>({
    defaultValues,
    resolver: yupResolver(supportPlanSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const navigate = useNavigate();

  const { getValues } = methods;

  const handleSave = async (data: TSupportPlanForm, isDraft: boolean) => {
    try {
      const payload = convertFormDataToReqDto(data, isDraft);
      const rs = await createAnytimeSupportPlanApi(payload);
      if (isDraft) {
        methods.unregister();
      }
      navigate(getDynamicRoute(AppRoutes.anytimeDocument, { id: rs.data.data.anyTimeDocumentId }));
      toast.success(rs.data.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TSupportPlanForm) => {
    if (
      data.contractTermTarget &&
      data.contractTermTarget.length === 0 &&
      data.contractSpecifiedSkill &&
      data.contractSpecifiedSkill.length === 0 &&
      data.contractTermRegisteredSupportOrganization &&
      data.contractTermRegisteredSupportOrganization.length === 0 &&
      data.contractContentOfSupport &&
      data.contractContentOfSupport.length == 0
    ) {
      toast.error("「支援対象者」・「特定技能所属機関」・「登録支援機関」・「支援の内容」から少なくとも 1 つを選択してください。");
      return;
    }
    await handleSave(data, false);
  };

  const saveDraft = async () => {
    await handleSave(getValues() as TSupportPlanForm, true);
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={supportPlanSchema}>
      <TopPage title="支援計画変更に係る届出" backUrl={AppRoutes.anytime} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <EligibleForeignNationals /> },
          {
            title: "計画の変更",
            content: (
              <InnerBox title="計画の変更" required>
                <ChangesForm />
              </InnerBox>
            ),
          },
          {
            title: "変更事項",
            content: (
              <InnerBox title="変更事項" required>
                <TerminationForm />
              </InnerBox>
            ),
          },
          {
            title: "変更後の内容",
            content: (
              <InnerBox title="変更後の内容" required mb={4}>
                <ConclusionForm />
              </InnerBox>
            ),
          },
          { title: "特定技能所属機関", content: <SpecificSkillsAffiliation /> },
          { title: "届出担当者", content: <CompanySupporterForm /> },
        ]}
        contentAffix={<ButtonGroup saveDraft={saveDraft} editable />}
      />
    </Form>
  );
};
