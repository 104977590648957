import {
  EEmploymentContractModificationType,
  EModifyContractTerminateReason,
  EModifyContractTerminateReasonForCompany,
  EModifyContractTerminateReasonForEmployee,
} from "@/features/anytime/constants/enums";
import { companySchema } from "@/features/anytime/schema/common/companySchema";
import { companySupporterSchema } from "@/features/anytime/schema/common/companySupporterSchema";
import { employeeSchema } from "@/features/anytime/schema/common/employeeSchema";
import { validator } from "@/utils/validator";

export const employmentContractSchema = validator
  .object()
  .shape({
    employees: validator
      .array()
      .of(employeeSchema)
      .test((value, ctx) => {
        if (Array.isArray(value) && value.length === 0) return ctx.createError({ message: "１人以上の外国人を追加してください。" });
        return true;
      })
      .required(),
    modificationType: validator.string().trim().emptyToNull().required(),

    // A
    modifyContractDate: validator
      .mixed()
      .isSelection()
      .isDayjs()
      .label("変更発生日")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.MODIFY_CONTRACT,
        then: (schema) => schema.required(),
      }),
    modifyContractModificationReasons: validator
      .array()
      .min(1, "「変更事項（複数選択可）」を選択してください。")
      .of(validator.string().trim().required())
      .label("変更事項（複数選択可）")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.MODIFY_CONTRACT,
        then: (schema) => schema.required("「変更事項（複数選択可）」を選択してください。"),
      }),
    modifyContractChangeDispatchLocationReasons: validator
      .array()
      .of(validator.string().trim().required())
      .label("派遣先の変更の場合に選択（複数選択可）")
      .notRequired(),
    modifyContractContent: validator
      .string()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("変更後の内容（300文字以内）")
      .fullWidthOnly()
      .max(300)
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.MODIFY_CONTRACT,
        then: (schema) => schema.required(),
      }),

    // B
    terminateContractDate: validator
      .mixed()
      .isSelection()
      .isDayjs()
      .label("終了年月日")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.TERMINATE_CONTRACT,
        then: (schema) => schema.required(),
      }),
    terminateContractReason: validator
      .string()
      .emptyToNull()
      .label("終了の事由")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.TERMINATE_CONTRACT,
        then: (schema) => schema.required(),
      }),

    terminateContractReasonByCompany: validator
      .string()
      .emptyToNull()
      .label("所属機関都合の詳細")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.TERMINATE_CONTRACT,
        then: (schema) =>
          schema.when("terminateContractReason", {
            is: EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY,
            then: (schema) => schema.required(),
          }),
      }),
    terminateContractOtherByCompany: validator
      .string()
      .emptyToNull()
      .label("その他")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.TERMINATE_CONTRACT,
        then: (schema) =>
          schema.when("terminateContractReason", {
            is: EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY,
            then: (schema) =>
              schema.when("terminateContractReasonByCompany", {
                is: EModifyContractTerminateReasonForCompany.OTHER,
                then: (schema) => schema.required(),
              }),
          }),
      }),

    terminateContractReasonByEmployee: validator
      .string()
      .emptyToNull()
      .label("外国人都合の詳細")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.TERMINATE_CONTRACT,
        then: (schema) =>
          schema.when("terminateContractReason", {
            is: EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE,
            then: (schema) => schema.required(),
          }),
      }),
    terminateContractOtherReasonByEmployee: validator
      .string()
      .emptyToNull()
      .label("その他")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.TERMINATE_CONTRACT,
        then: (schema) =>
          schema.when("terminateContractReason", {
            is: EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE,
            then: (schema) =>
              schema.when("terminateContractReasonByEmployee", {
                is: EModifyContractTerminateReasonForEmployee.OTHER,
                then: (schema) => schema.required(),
              }),
          }),
      }),

    // C
    renewContractDate: validator
      .mixed()
      .isSelection()
      .isDayjs()
      .label("締結年月日")
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.RENEW_CONTRACT,
        then: (schema) => schema.required(),
      }),
    renewContractContent: validator
      .string()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("契約の内容（20文字以内）")
      .fullWidthOnly()
      .max(20)
      .notRequired()
      .when("modificationType", {
        is: EEmploymentContractModificationType.RENEW_CONTRACT,
        then: (schema) => schema.required(),
      }),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);
