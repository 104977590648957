import { Filter, TFilterProps } from "@/components/elements/Filter";
import { SORTING_LABEL } from "@/constants/sorting";
import { useSorting } from "@/hooks/useSorting";
import { createUrlWithParams, getQueryFromURL } from "@/utils/url";
import { FCC, TOption } from "@/types/common";
import { Key, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type TTableSortingProps = {
  name: string;
} & Omit<TFilterProps, "options">;

export const TableSorting: FCC<TTableSortingProps> = ({ name, children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sorts } = useSorting();

  const onChange = (v: Key[]) => {
    const query = getQueryFromURL();
    let newSort = v[0].toString();

    if (sorts[0] === newSort) {
      newSort = "";
    }

    const url = createUrlWithParams(location.pathname, { ...query, sorts: newSort || undefined });
    navigate(url);
  };

  const options: TOption[] = useMemo(() => {
    return Object.entries(SORTING_LABEL).map(([value, label]) => ({ label, value: `${name}|${value}` }));
  }, []);

  const currentSelected = useMemo(() => {
    const found = sorts.find((sort) => sort.startsWith(name));
    if (!found) return undefined;
    return [found];
  }, [sorts]);

  return (
    <Filter multiple={false} value={currentSelected} onChange={onChange} options={options} {...rest}>
      {children}
    </Filter>
  );
};
