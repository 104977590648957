import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Table } from "@/components/elements/Table";
import { EMPTY_STR } from "@/constants/string";
import { useFormTable } from "@/hooks/useFormTable";
import { findLabelByValue } from "@/utils/object";
import { MRT_ColumnDef } from "material-react-table";
import { useFormContext } from "react-hook-form";

export const SupporterFormTable = () => {
  const { data: companySupporterRoleOptions } = useEnum("ECompanySupporterRole");
  const { control } = useFormContext();
  const formColumns: MRT_ColumnDef<{
    id?: number;
    supporterRole: string;
    supporterName: string;
    supporterFuriganaName?: string | null;
    companyPosition: string;
    telNumber?: string | null;
    email: string | null;
  }>[] = [
    {
      header: "",
      id: "supporterRole",
      accessorKey: "supporterRole",
      Cell: ({ row }) => {
        return findLabelByValue(companySupporterRoleOptions, row.original.supporterRole) || EMPTY_STR.TEXT;
      },
    },
    {
      id: "supporterName",
      accessorKey: "supporterName",
      header: "名前",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`supporters.${row.index}.supporterName`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      id: "supporterFuriganaName",
      accessorKey: "supporterFuriganaName",
      header: "ふりがな",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`supporters.${row.index}.supporterFuriganaName`}>
            <Input transformToFullWidth />
          </FormField>
        );
      },
    },
    {
      id: "companyPosition",
      accessorKey: "companyPosition",
      header: "役職",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`supporters.${row.index}.companyPosition`}>
            <Input transformToFullWidth />
          </FormField>
        );
      },
    },
    {
      id: "telNumber",
      accessorKey: "telNumber",
      header: "電話番号（数字のみ）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`supporters.${row.index}.telNumber`}>
            <Input transformToHalfWidth />
          </FormField>
        );
      },
    },
    {
      id: "email",
      accessorKey: "email",
      header: "メールアドレス",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`supporters.${row.index}.email`}>
            <Input transformToHalfWidth />
          </FormField>
        );
      },
    },
  ];
  const { table } = useFormTable({
    columns: formColumns,
    control,
    name: "supporters",
    primaryKey: "id",
    initFieldData: { supporterRole: "support" },
    disableDeleteRow: (index) => index === 0 || index === 1 || index === 2,
  });

  return <Table addBtnText="支援担当者を追加する" table={table} maxHeight={350} />;
};
