import { DATE_FORMAT } from "@/constants/datetime";
import { PATTERN } from "@/constants/pattern";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { TPayrollRecord } from "@/features/regular-document/types/payrollRecord";
import { TPayrollResourceData } from "@/features/regular-document/types/PayrollResourceData";
import { TPayrollTableRow } from "@/features/regular-document/types/payrollTableRow";
import { dayjs } from "@/utils/dayjs";
import { toNumber, zeroPad } from "@/utils/number";

export const validationPayroll = (data: TPayrollTableRow, resourceData: TPayrollResourceData) => {
  const isValid = (data: TPayrollRecord) => {
    if (data.status === PAYROLL_STATUS.ERROR) return false;
    if (![PLAIN_TEXT.change, PLAIN_TEXT.noChange].includes(data.isChangeDispatchPlace)) return false;
    if (![PLAIN_TEXT.change, PLAIN_TEXT.noChange].includes(data.isChangeWorkingDetail)) return false;
    if (![PLAIN_TEXT.change, PLAIN_TEXT.noChange].includes(data.isChangeDispatchPlace)) return false;
    if (![PLAIN_TEXT.yes, PLAIN_TEXT.no].includes(data.hasComparisonEmployee)) return false;
    if (!PATTERN.NUMBER_ONLY.test(data.netAmountPaid)) return false;
    if (!PATTERN.NUMBER_ONLY.test(data.totalAmountPaid)) return false;
    if (!PATTERN.NUMBER_ONLY.test(data.legalDeductionAmount)) return false;
    if (!PATTERN.NUMBER_ONLY.test(data.workingDayCount)) return false;
    const maxDate = dayjs(`${resourceData.year}/${zeroPad(data.month, 2)}/01`, DATE_FORMAT)
      .endOf("month")
      .date();

    if (toNumber(data.workingDayCount) > maxDate) return false;
    return true;
  };
  if (!data.children?.length) return isValid(data);
  return !data.children.some((item) => !isValid(item));
};
