import {
  TCreateRetirementEmploymentContractDocumentReqDto,
  TCreateRetirementEmploymentContractDocumentResDto,
} from "@/api/services/main/retirement-document/employment-contract-document/dtos/create-retirement-employement-contract-document.dto";
import { TGetRetirementEmploymentContractDocumentResDto } from "@/api/services/main/retirement-document/employment-contract-document/dtos/get-retirement-employment-contract-document.dto";
import {
  TUpdateRetirementEmploymentContractDocumentReqDto,
  TUpdateRetirementEmploymentContractDocumentResDto,
} from "@/api/services/main/retirement-document/employment-contract-document/dtos/update-retirement-employment-contract-document.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";

export const createRetirementEmploymentContractDocumentApi = async (
  documentStepId: number,
  payload: TCreateRetirementEmploymentContractDocumentReqDto,
): Promise<TCreateRetirementEmploymentContractDocumentResDto> => {
  const { data } = await clientApi.post<TCreateRetirementEmploymentContractDocumentResDto>(
    `/retirement-documents/retirement-employment-contract-document/documents?documentStepId=${documentStepId}`,
    payload,
  );
  return Promise.resolve(data);
};

export const updateRetirementEmploymentContractDocumentApi = async (
  documentStepId: number,
  payload: TUpdateRetirementEmploymentContractDocumentReqDto,
): Promise<TUpdateRetirementEmploymentContractDocumentResDto> => {
  const rs = await clientApi.put<TUpdateRetirementEmploymentContractDocumentResDto>(
    `/retirement-documents/retirement-employment-contract-document/documents/${documentStepId}`,
    payload,
  );
  return rs.data;
};

export const fetchRetirementEmploymentContractDocumentApi = async (
  documentStepId: number,
): Promise<TGetRetirementEmploymentContractDocumentResDto> => {
  const rs = await clientApi.get<TGetRetirementEmploymentContractDocumentResDto>(
    `/retirement-documents/retirement-employment-contract-document/documents/${documentStepId}`,
  );
  return rs.data;
};

export const useRetirementEmploymentContractDocument = (documentStepId?: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RETIREMENT_DOCUMENTS.FETCH_EMPLOYEE_CONTRACT, documentStepId],
    queryFn: () => (documentStepId ? fetchRetirementEmploymentContractDocumentApi(documentStepId) : undefined),
    enabled: Boolean(documentStepId),
  });
};
