import { FormField } from "@/components/elements/FormField";
import { SelectMulti } from "@/components/elements/SelectMulti";
import { Textarea } from "@/components/elements/Textarea";
import { EAnyTimeContentOfSupport, ESupportRegistered, ESupportSpecifiedSkill } from "@/features/anytime/constants/enums";
import { TSupportPlanForm } from "@/features/anytime/types/supportPlanForm";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TProps = {
  readonly?: boolean;
};

export const TerminationForm: FC<TProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TSupportPlanForm>();

  const contractSpecifiedSkillWatchField = useWatch({
    control,
    name: "contractSpecifiedSkill",
  });

  const contractTermOtherContentWatchField = useWatch({
    control,
    name: "contractTermOtherContent",
  });

  const contractTermRegisteredSupportOrganizationWatchField = useWatch({
    control,
    name: "contractTermRegisteredSupportOrganization",
  });

  const contentAfterChangeWatchField = useWatch({
    control,
    name: "contentAfterChange",
  });

  const contractContentOfSupportWatchField = useWatch({
    control,
    name: "contractContentOfSupport",
  });

  const contractContentOfSupportOtherWatchField = useWatch({
    control,
    name: "contractContentOfSupportOther",
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>注意：「自社支援へ切り替え」の場合 初期値のままにしてください。</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormField control={control} name="contractTermTarget">
          <SelectMulti enumName="ESupportRecipient" readonly={readonly} />
        </FormField>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <FormField control={control} name="contractSpecifiedSkill">
          <SelectMulti
            enumName="ESupportSpecifiedSkill"
            readonly={readonly}
            onChange={(value) => {
              if (!value?.includes(ESupportSpecifiedSkill.OTHER) && contractTermOtherContentWatchField) {
                setValue("contractTermOtherContent", "");
              }
            }}
          />
        </FormField>
      </Grid>
      <Grid item xs={6}>
        <FormField control={control} name="contractTermOtherContent">
          <Textarea readonly={readonly} disabled={!contractSpecifiedSkillWatchField?.includes(ESupportSpecifiedSkill.OTHER)} minRows={1} />
          <Typography variant="cap12">300文字以下</Typography>
        </FormField>
      </Grid>
      <Grid item xs={6}>
        <FormField control={control} name="contractTermRegisteredSupportOrganization">
          <SelectMulti
            enumName="ESupportRegistered"
            readonly={readonly}
            onChange={(value) => {
              if (!value?.includes(ESupportRegistered.OTHER) && contentAfterChangeWatchField) {
                setValue("contentAfterChange", "");
              }
            }}
          />
        </FormField>
      </Grid>
      <Grid item xs={6}>
        <FormField control={control} name="contentAfterChange">
          <Textarea
            readonly={readonly}
            disabled={!contractTermRegisteredSupportOrganizationWatchField?.includes(ESupportRegistered.OTHER)}
            minRows={1}
          />
        </FormField>
      </Grid>
      <Grid item xs={6}>
        <FormField control={control} name="contractContentOfSupport">
          <SelectMulti
            enumName="EAnyTimeContentOfSupport"
            readonly={readonly}
            onChange={(value) => {
              if (!value?.includes(EAnyTimeContentOfSupport.OTHER) && contractContentOfSupportOtherWatchField) {
                setValue("contractContentOfSupportOther", "");
              }
            }}
          />
        </FormField>
      </Grid>
      <Grid item xs={6}>
        <FormField control={control} name="contractContentOfSupportOther">
          <Textarea readonly={readonly} disabled={!contractContentOfSupportWatchField?.includes(EAnyTimeContentOfSupport.OTHER)} minRows={1} />
        </FormField>
      </Grid>
    </Grid>
  );
};
