import { useCompanyInfo } from "@/api/services/main/company";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { EEmploymentContractModificationType } from "@/features/anytime/constants/enums";
import { AnytimeEmploymentContractCreatePage } from "@/features/anytime/pages/documents/AnytimeEmploymentContractCreate";
import { getInitValues } from "@/features/anytime/utils/employment-contract";

const AnytimeEmploymentContractCreate = () => {
  const { data: companyResult, isLoading } = useCompanyInfo();

  if (isLoading || !companyResult) return <LoadingPage />;
  return (
    <AnytimeEmploymentContractCreatePage
      defaultValues={{
        ...getInitValues(companyResult.data),
        modificationType: EEmploymentContractModificationType.MODIFY_CONTRACT,
      }}
    />
  );
};

export default AnytimeEmploymentContractCreate;
