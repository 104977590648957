import { TRegularDocumentStep } from "@/api/entities/regularDocumentStep.entity";
import { useEnum } from "@/api/services/global/enum";
import { Button } from "@/components/elements/Button";
import { ConvertDocumentStatusTag } from "@/components/elements/ConvertDocumentStatusTag";
import { StepCard } from "@/components/elements/StepCard";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TStepProps = {
  stepData: TRegularDocumentStep;
  readonly?: boolean;
};

export const AcceptanceActivityUploadStep: FC<TStepProps> = ({ stepData, readonly }) => {
  const { masterDocumentStep, updatedAt, updater, status } = stepData;
  const params = useParams();
  const { stepOrder, stepName } = masterDocumentStep;
  const { data: documentStatuses } = useEnum("EDocumentStatus");

  const { checkFinishInitStep } = useInitSetup();

  return (
    <StepCard
      title={`ステップ${stepOrder}`}
      subTitle={stepName}
      buttons={
        readonly ? (
          <Button variant="primary" size="sm" component={Link} to={getDynamicRoute(AppRoutes.regularHistoryAcceptActivityPayrollFiles, params)}>
            確認
          </Button>
        ) : (
          <Button variant="primary" size="sm" component={Link} to={AppRoutes.regularPresentAcceptActivityPayrollFiles} onClick={checkFinishInitStep}>
            アップロード
          </Button>
        )
      }
    >
      <Stack flexDirection="row" alignItems="flex-start" gap={10}>
        <Stack gap={1}>
          <Typography variant="body14Bold">更新日時</Typography>
          <Typography variant="body14">{updatedAt && updater ? dayjs(updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}</Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body14Bold">最終更新者</Typography>
          <Typography variant="body14">{updater?.fullName || EMPTY_STR.TEXT}</Typography>
        </Stack>
        {status && (
          <Stack gap={1}>
            <Typography variant="body14Bold">ステータス</Typography>
            <ConvertDocumentStatusTag status={status} title={findLabelByValue(documentStatuses, status) || EMPTY_STR.TEXT} />
          </Stack>
        )}
      </Stack>
    </StepCard>
  );
};
