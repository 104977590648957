import { NotificationCard, TNotificationCardProps } from "@/components/commons/notification/NotificationCard";
import { Linkable } from "@/components/elements/Linkable";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { getDynamicRoute } from "@/utils/url";
import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";

export type TNotificationListProps = {
  data: TNotificationCardProps[];
  isSingleLine?: boolean;
  isHideContent?: boolean;
};

export const NotificationList: FC<TNotificationListProps> = ({ data, isSingleLine, isHideContent }) => {
  if (data.length === 0) {
    return (
      <Box py={5} textAlign="center">
        <Typography color={text.tertiary} fontWeight={700}>
          データがありません。
        </Typography>
      </Box>
    );
  }

  return data.map((notification, index) => (
    <Linkable href={getDynamicRoute(AppRoutes.informationDetail, { id: notification.id })} key={notification.id}>
      {index !== 0 && <Divider />}
      <NotificationCard key={notification.id} {...notification} isSingleLine={isSingleLine} isHideContent={isHideContent} />
    </Linkable>
  ));
};
