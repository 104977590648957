import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConfirmReplaceContentProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};

export const ConfirmReplaceContent: FC<TConfirmReplaceContentProps> = ({ open, onClose, onOk }) => {
  return (
    <Modal title="リセットの確認" isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい">
      <Stack mb={2}>
        <Typography>テンプレートの内容がリセットされますが、よろしいですか。</Typography>
      </Stack>
    </Modal>
  );
};
