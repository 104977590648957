import { TAnyTimeDocumentEmployee } from "@/api/entities/any-time-document-employee.entity";
import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { useCompanyBranchList } from "@/api/services/main/company";
import { fetchEmployeeDetailListApi, useEmployeeList } from "@/api/services/main/employee";
import { TGetEmployeeListOutputDto } from "@/api/services/main/employee/dtos/get-employee-list.dto";
import { IconSearch } from "@/assets/icons";
import { Avatar } from "@/components/elements/Avatar";
import { DebounceInput } from "@/components/elements/Input/Input";
import { Modal } from "@/components/elements/Modal";
import { StatusTag } from "@/components/elements/StatusTag";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useSelectionTable } from "@/hooks/useSelectionTable";
import { TOption } from "@/types/common";
import { EMPLOYMENT_STATUS, RETIREMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { findLabelByValue, pick } from "@/utils/object";
import { sleep } from "@/utils/pieces";
import { getFullAddress, getFullName } from "@/utils/string";
import { Box, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

type TPickEmployeesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PickEmployeesModal: FC<TPickEmployeesModalProps> = ({ isOpen, onClose }) => {
  const { data: employmentStatusOptions } = useEnum("EEmploymentStatus");
  const { data: retirementStatusOptions } = useEnum("ERetirementStatus");
  const { nationalityOptions } = useGetRegionList();
  const { data: companyBranch } = useCompanyBranchList();
  const [searchInput, setSearchInput] = useState("");
  const { data: employeeList, isLoading } = useEmployeeList({
    filter: {
      fullName: searchInput || undefined,
      notRetirementStatus: [RETIREMENT_STATUS.RETIRED],
      employmentStatus: [EMPLOYMENT_STATUS.WORKING, EMPLOYMENT_STATUS.LEFT],
      residenceStatusIds: [1, 2],
    },
  });

  const { getValues, setValue } = useFormContext<{ employees: Omit<TAnyTimeDocumentEmployee, "id">[] }>();

  const contractCompanyBranchOptions: TOption[] = useMemo(
    () => (companyBranch ? companyBranch?.data.map((item) => ({ label: item.branchName, value: item.id })) : []),
    [companyBranch],
  );

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeListOutputDto>[]>(
    () => [
      {
        header: "在籍状況",
        Cell: ({ row }) => {
          const { employmentStatus } = row.original;
          if (!employmentStatus) return EMPTY_STR.TEXT;
          const label = findLabelByValue(employmentStatusOptions, employmentStatus);
          if (!label) return EMPTY_STR.TEXT;
          return <StatusTag status={employmentStatus} variant="bold" title={label} />;
        },
        size: 80,
      },
      {
        header: "管理番号",
        Cell: ({ row }) => row.original.managementNumber || EMPTY_STR.TEXT,
        size: 80,
      },
      {
        header: "外国人名",
        Cell: ({ row }) => {
          const { firstName, lastName, middleName, avatarFilePath } = row.original;
          const fullName = getFullName({ firstName, lastName, middleName });

          return (
            <Stack direction="row" gap={1} alignItems="center">
              <Avatar filePath={avatarFilePath} width={30} height={40} />
              <Typography variant="body14">{fullName || EMPTY_STR.TEXT}</Typography>
            </Stack>
          );
        },
        size: 120,
      },
      {
        header: "呼び名",
        Cell: ({ row }) => {
          return <Typography variant="body14">{row.original.nickName || EMPTY_STR.TEXT}</Typography>;
        },
        size: 100,
      },
      {
        header: "国籍",
        Cell: ({ row }) => findLabelByValue(nationalityOptions, row.original.nationalityId) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "勤務場所",
        Cell: ({ row }) => findLabelByValue(contractCompanyBranchOptions, row.original.contractCompanyBranchId) || EMPTY_STR.TEXT,
        size: 100,
      },
      {
        header: "入社日",
        Cell: ({ row }) => (row.original.hiringDate ? dayjs(row.original.hiringDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
        size: 100,
      },
    ],
    [employmentStatusOptions, retirementStatusOptions],
  );

  const { table, rowSelectionKeys } = useSelectionTable({
    columns,
    primaryKey: "id",
    rows: employeeList?.data ?? [],
    filterRow: (row) => !getValues("employees").some((employee) => employee.employeeId === toNumber(row.id)),
  });

  const handleSaveEmployees = async () => {
    try {
      const rs = await fetchEmployeeDetailListApi({ ids: rowSelectionKeys.map((id) => toNumber(id)) });
      const currentEmployees = getValues("employees");

      const data = rs.data.map((employee) => {
        const fullAddress = getFullAddress({ province: employee.province, municipality: employee.municipality, address: employee.address });

        return {
          employeeFullName: getFullName(pick(employee, "firstName", "lastName", "middleName")),
          employeeNickName: employee.nickName,
          birthday: employee.birthday,
          employeeId: toNumber(employee.id),
          employeeGender: employee.gender,
          employeeNationalityId: toNumber(employee.nationalityId) || null,
          employeeResidenceCardNumber: employee.residenceCode,
          specificBusinessClassificationName: employee.contractSpecificBusinessClassificationOne?.anyTimeSpecificBusinessClassificationName ?? null,
          specificIndustryName: employee.contractSpecificBusinessClassificationOne?.specificIndustry.regularAnyTimeSpecificIndustry ?? null,
          fullAddress,
          telNumber: employee.telNumber,
          zipCode: employee.zipCode,
        };
      });
      await sleep(0);
      setValue("employees", [currentEmployees, data].flat());
      onClose();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title="対象外国人の追加"
      isOpen={isOpen}
      onClose={onClose}
      onOk={handleSaveEmployees}
      labelClose="キャンセル"
      labelOk="追加"
      disabledOk={!rowSelectionKeys.length}
    >
      <Stack py={2} gap={2}>
        <Box maxWidth={500}>
          <DebounceInput endAdornment={<IconSearch />} fullWidth nonControl value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        </Box>
        <Table maxHeight={350} table={table} isLoading={isLoading} />
      </Stack>
    </Modal>
  );
};
