import { useGetResidenceStatusList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { toNumber } from "@/utils/number";
import { Grid } from "@mui/material";
import { FC, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TEmployeeInfoTwoFormProps = {
  readonly: boolean;
};

export const EmployeeInfoTwoForm: FC<TEmployeeInfoTwoFormProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control } = methods;

  const { data: residenceStatus, isLoading: isLoadingResidenceStatus, residenceStatusOptions } = useGetResidenceStatusList();

  const employeeInfoTwoResidenceStatusIdWatchField = useWatch({ control, name: "employeeInfoTwoResidenceStatusId" });

  const residencePeriodOptions = useMemo(() => {
    const founded = residenceStatus
      ? residenceStatus.find((item) => toNumber(item.id) === toNumber(employeeInfoTwoResidenceStatusIdWatchField))
      : undefined;
    if (founded) return founded.residencePeriods.map((item) => ({ label: item.residencePeriod ?? "", value: item.id, hidden: item.isHidden }));
    else return [];
  }, [residenceStatus, employeeInfoTwoResidenceStatusIdWatchField]);

  const desiredResidencePeriodOptions = useMemo(() => {
    const founded = residenceStatus
      ? residenceStatus.find((item) => toNumber(item.id) === toNumber(employeeInfoTwoResidenceStatusIdWatchField))
      : undefined;
    if (founded) return founded.residencePeriods.map((item) => ({ label: item.desiredResidencePeriod ?? "", value: item.id, hidden: item.isHidden }));
    else return [];
  }, [residenceStatus, employeeInfoTwoResidenceStatusIdWatchField]);

  return (
    <InnerBox title="外国人情報（身分事項2）" bordered p={3} required>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoTwoResidenceStatusId">
            <Select loading={isLoadingResidenceStatus} readonly options={residenceStatusOptions} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoTwoResidencePeriodId">
            <Select loading={isLoadingResidenceStatus} readonly options={residencePeriodOptions} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoTwoResidenceExpiryDate">
            <InputDatePicker readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoTwoResidenceCode">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoTwoResidenceDesiredPeriodId">
            <Select options={desiredResidencePeriodOptions} loading={isLoadingResidenceStatus} readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={12}>
          <FormField control={control} name="employeeInfoTwoResidenceChangeReason">
            <Input readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="employeeInfoTwoRasImmiFullName">
            <Input />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};
