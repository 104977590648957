import { useEmployeeInfo } from "@/api/services/main/employee";
import { dayjs } from "@/utils/dayjs";
import { validator } from "@/utils/validator";

export const useGetRetirementEmployeeSchema = (retirementEmployeeId?: number) => {
  const { data: employeeResult } = useEmployeeInfo(retirementEmployeeId ?? undefined);
  const hiringDate = employeeResult?.data.hiringDate;

  const retirementEmployeeSchema = validator.object().shape({
    retirementDate: validator
      .mixed()
      .isDayjs()
      .isSelection()
      .label("退職日（契約終了日）")
      .required()
      .test(function (value, context) {
        if (!value || !hiringDate) return true;

        if (value?.isAfter(dayjs(hiringDate))) {
          return true;
        }
        return context.createError({
          path: context.path,
          message: ({ label }) => `「${label}」は「入社日（予定日）」より後にする必要があります。`,
        });
      }),
  });

  return { retirementEmployeeSchema };
};
