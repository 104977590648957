import { Button } from "@/components/elements/Button";
import { CompanyBranchList } from "@/features/company/components/CompanyBranchList";
import { AppRoutes } from "@/routes/config";
import { text } from "@/theme/colors";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CompanyBranchPage = () => {
  const navigate = useNavigate();

  // if (isLoading) return <LoadingPage/>;
  return (
    <Box>
      <Stack gap={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="head20Semi" fontWeight={100} color={text.primary}>
            事業所管理
          </Typography>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              navigate(AppRoutes.companyBranchCreate);
            }}
          >
            新規事業所登録
          </Button>
        </Stack>
        <Stack gap={3}>
          <CompanyBranchList />
        </Stack>
      </Stack>
    </Box>
  );
};
