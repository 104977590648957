import { resetPasswordApi } from "@/api/services/main/auth";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { PASSWORD_CHANGE_SUCCESS_MESSAGE } from "@/constants/common";
import { resetPasswordSchema } from "@/features/auth/schema/resetPasswordSchema";
import { TResetPasswordForm } from "@/features/auth/types/resetPasswordForm";
import { TResetPasswordQuery } from "@/features/auth/types/resetPasswordQuery";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { createUrlWithParams, getQueryFromURL } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

const initForm: TResetPasswordForm = {
  email: "",
  newPassword: "",
  confirmNewPassword: "",
  companyCode: "",
};

export const ResetPasswordForm = () => {
  const query = getQueryFromURL<TResetPasswordQuery>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const methods = useForm<TResetPasswordForm>({
    mode: "onChange",
    defaultValues: { ...initForm, email: query?.email ?? "", companyCode: query?.companyCode ?? "" },
    resolver: yupResolver(resetPasswordSchema),
  });
  const { control } = methods;

  const onSubmit = async (data: TResetPasswordForm) => {
    const { newPassword } = data;
    if (!query) return;
    const { email, resetToken, companyCode } = query;
    try {
      if (email && resetToken) {
        await resetPasswordApi({ email, newPassword, resetToken, companyCode });
        toast.success(PASSWORD_CHANGE_SUCCESS_MESSAGE);
        const url = createUrlWithParams(AppRoutes.login, { email, companyCode });

        // Delay 2 seconds to show success message
        setTimeout(() => {
          location.href = url;
        }, 2000);
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const isRegisterRoute = pathname === AppRoutes.register;

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={resetPasswordSchema}>
      <Stack gap={3} width={400}>
        <FormField control={control} name="companyCode">
          <Input disabled size="lg" />
        </FormField>
        <FormField control={control} name="email">
          <Input disabled size="lg" />
        </FormField>
        <FormField control={control} name="newPassword">
          <Input type="password" size="lg" />
        </FormField>
        <FormField control={control} name="confirmNewPassword">
          <Input type="password" size="lg" />
        </FormField>
        <Button type="submit" variant="primary" fullWidth>
          {isRegisterRoute ? "登録" : "パスワード再設定"}
        </Button>
      </Stack>
    </Form>
  );
};
