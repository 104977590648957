import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { TEmployeeRenewVisaDocument } from "@/api/entities/employee-renew-visa-document.entity";

export const findRenewVisaDocumentStep = (
  data: TEmployeeRenewVisaDocument | undefined,
  stepKey: string,
): TEmployeeRenewVisaDocumentStep | undefined => {
  if (!data) return undefined;
  return data.steps.find((step) => step.masterDocumentStep.stepKey === stepKey);
};
