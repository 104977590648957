import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { useGetRenewVisaCompanyApplicationDocumentInfo, useGetRenewVisaEmployeeApplicationDocumentInfo } from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { StatusTag } from "@/components/elements/StatusTag";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useCheckRenewVisaHistoryPage } from "@/features/renew-visa/hook/useCheckRenewVisaHistoryPage";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

type TFormApplicationStepProps = {
  stepData: TEmployeeRenewVisaDocumentStep;
};

export const FormApplicationStep: FC<TFormApplicationStepProps> = ({ stepData }) => {
  const { renewVisaDocumentId } = useParams();

  const { id: documentStepId } = stepData;

  const { data: renewVisaDocumentStatuses } = useEnum("ERenewVisaDocumentStatus");

  const { isHistoryPage } = useCheckRenewVisaHistoryPage();

  const { checkFinishInitStep } = useInitSetup();

  const { data: companyApplicationData, isLoading: isLoadingCompanyApplication } = useGetRenewVisaCompanyApplicationDocumentInfo({
    applicationDocumentStepId: stepData.id,
  });

  const { data: employeeApplicationData, isLoading: isLoadingEmployeeApplication } = useGetRenewVisaEmployeeApplicationDocumentInfo({
    applicationDocumentStepId: stepData.id,
  });

  const isLoading = useMemo(
    () => isLoadingCompanyApplication || isLoadingEmployeeApplication,
    [isLoadingEmployeeApplication, isLoadingCompanyApplication],
  );

  const { updatedAt, status, fullName } = useMemo(() => {
    if (employeeApplicationData?.updatedAt && companyApplicationData?.updatedAt) {
      const isLatestEmployeeApplicationUpdater = dayjs(employeeApplicationData.updatedAt).isAfter(companyApplicationData.updatedAt);
      return {
        updatedAt: isLatestEmployeeApplicationUpdater ? employeeApplicationData.updatedAt : companyApplicationData.updatedAt,
        status: isLatestEmployeeApplicationUpdater ? employeeApplicationData.status : companyApplicationData.status,
        fullName: isLatestEmployeeApplicationUpdater ? employeeApplicationData.updater?.fullName : companyApplicationData.updater?.fullName,
      };
    } else if (employeeApplicationData?.updatedAt && !companyApplicationData?.updatedAt) {
      return {
        updatedAt: employeeApplicationData.updatedAt,
        status: DOCUMENT_STATUS.IN_PROGRESS,
        fullName: employeeApplicationData.updater?.fullName,
      };
    } else if (!employeeApplicationData?.updatedAt && companyApplicationData?.updatedAt) {
      return {
        updatedAt: companyApplicationData.updatedAt,
        status: DOCUMENT_STATUS.IN_PROGRESS,
        fullName: companyApplicationData.updater?.fullName,
      };
    }
    return {
      updatedAt: null,
      status: DOCUMENT_STATUS.IN_PROGRESS,
      fullName: null,
    };
  }, [employeeApplicationData, companyApplicationData]);

  if (isLoading) return <LoadingArea />;

  return (
    <Stack direction="row" justifyContent="space-between" p="16px" sx={{ border: "1px solid #E6E7E8", borderRadius: "8px" }}>
      <Stack flexDirection="row" alignItems="flex-start" gap={10}>
        <Stack gap={1}>
          <Typography variant="body14Bold">更新日時</Typography>
          <Typography variant="body14">{updatedAt ? dayjs(updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}</Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body14Bold">最終更新者</Typography>
          <Typography variant="body14">{fullName || EMPTY_STR.TEXT}</Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body14Bold">ステータス</Typography>
          <StatusTag variant="bold" status={status} title={findLabelByValue(renewVisaDocumentStatuses, status) || EMPTY_STR.TEXT} />
        </Stack>
      </Stack>
      <Button
        variant="secondary"
        size="sm"
        component={Link}
        to={
          isHistoryPage
            ? getDynamicRoute(AppRoutes.renewVisaInfoHistoryEmployeeApplication, { renewVisaDocumentId, documentStepId })
            : getDynamicRoute(AppRoutes.renewVisaPresentEmployeeApplication, { renewVisaDocumentId, documentStepId })
        }
        onClick={checkFinishInitStep}
      >
        {isHistoryPage ? "確認" : "編集"}
      </Button>
    </Stack>
  );
};
