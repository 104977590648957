import { createRenewVisaCompanyApplicationDocumentApi, updateRenewVisaCompanyApplicationDocumentApi } from "@/api/services/main/renew-visa";
import { Form } from "@/components/elements/Form";
import { FormDescription } from "@/components/elements/FormDescription";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CompanyApplicationBtnActions } from "@/features/renew-visa/components/applicationStep/formApplication/companyApplication/CompanyApplicationBtnActions";
import { CompanyInfoForm } from "@/features/renew-visa/components/applicationStep/formApplication/companyApplication/CompanyInfoForm";
import { CompanyOtherInfoForm } from "@/features/renew-visa/components/applicationStep/formApplication/companyApplication/CompanyOtherInfoForm";
import { ProgressStep } from "@/features/renew-visa/components/applicationStep/formApplication/ProgressStep";
import { useGetCompanyApplicationSchema } from "@/features/renew-visa/hook/useGetCompanyApplicationSchema";
import { TCompanyApplicationForm } from "@/features/renew-visa/types/companyApplicationForm";
import { convertCompanyApplicationFormDataToReqDto } from "@/features/renew-visa/utils/convertCompanyApplicationFormDataToReqDto";
import { AppRoutes } from "@/routes/config";
import { TUpdaterDto } from "@/types/common";
import { setErrorForm, showError } from "@/utils/error";
import { isEmpty } from "@/utils/object";
import { getFormValues } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TCompanyApplicationFormProps = {
  previousValues: TCompanyApplicationForm | undefined;
  updater?: TUpdaterDto | undefined;
  readonly?: boolean;
  isDraft?: boolean;
  isEditable?: boolean;
  setIsEditable?: (bool: boolean) => void;
};

export const CompanyApplicationForm: FC<TCompanyApplicationFormProps> = ({
  previousValues,
  updater,
  readonly,
  isDraft: isPageDraft,
  isEditable,
  setIsEditable,
}) => {
  const { renewVisaDocumentId, documentStepId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { companyApplicationSchema, companyApplicationDraftSchema } = useGetCompanyApplicationSchema();

  const methods = useForm<TCompanyApplicationForm>({
    resolver: yupResolver(companyApplicationSchema),
    defaultValues: previousValues,
    mode: "onBlur",
  });

  const saveDataForm = async (data: TCompanyApplicationForm, isDraft: boolean) => {
    if (!renewVisaDocumentId || !documentStepId) return;
    try {
      if (updater) {
        if (isEmpty(methods.formState.dirtyFields) && isPageDraft === isDraft) {
          navigate(getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId }));
          return;
        }
        const rs = await updateRenewVisaCompanyApplicationDocumentApi(renewVisaDocumentId, documentStepId, {
          ...convertCompanyApplicationFormDataToReqDto(data, isDraft),
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createRenewVisaCompanyApplicationDocumentApi(renewVisaDocumentId, documentStepId, {
          ...convertCompanyApplicationFormDataToReqDto(data, isDraft),
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_COMPANY_APPLICATION] });
      methods.unregister();
      navigate(getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId }));
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onSubmit = async (data: TCompanyApplicationForm) => {
    if (readonly) {
      navigate(getDynamicRoute(AppRoutes.renewVisaPresentCompanyApplication, { renewVisaDocumentId, documentStepId }));
      return;
    }
    await saveDataForm(data, false);
  };

  const saveDraft = async () => {
    const data = methods.getValues();
    try {
      const values = await getFormValues(data, companyApplicationDraftSchema);
      await saveDataForm(values as TCompanyApplicationForm, true);
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form onSubmit={onSubmit} methods={methods} schema={companyApplicationSchema}>
      <MenuNavigate
        contents={[
          { title: "特定技能所属機関", content: <CompanyInfoForm readonly={readonly || !isEditable} /> },
          { title: "その他質問事項", content: <CompanyOtherInfoForm readonly={readonly || !isEditable} /> },
        ]}
        contentPrefix={
          <>
            <ProgressStep isInProgessCompanyApplication />
            <FormDescription />
          </>
        }
        contentAffix={
          <CompanyApplicationBtnActions
            saveDraft={saveDraft}
            readonly={readonly}
            isEditable={!!isEditable}
            setIsEditable={(v) => setIsEditable?.(v)}
          />
        }
      />
    </Form>
  );
};
