import { TRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { LifeSupportConfirmDiscussionRecordStep } from "@/features/regular-document/components/documents/life-support/LifeSupportConfirmDiscussionRecordStep";
import { LifeSupportCreateStep } from "@/features/regular-document/components/documents/life-support/LifeSupportCreateStep";
import { LifeSupportSubmitStep } from "@/features/regular-document/components/documents/life-support/LifeSupportSubmitStep";
import { DocumentCard } from "@/components/elements/DocumentCard";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { FC, useMemo } from "react";

type TDocumentProps = {
  document: TRegularDocumentDto;
  readonly?: boolean;
};

export const LifeSupportDocument: FC<TDocumentProps> = ({ document, readonly }) => {
  const renderLifeSupportCreateStep = useMemo(() => {
    const stepData = findDocumentStep(document, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CREATE_DOCUMENT);

    if (!stepData) return null;
    return <LifeSupportCreateStep stepData={stepData} readonly={readonly} documentType={document.masterDocument.documentType} />;
  }, [readonly, document]);

  const renderLifeSupportConfirmDiscussionRecordStep = useMemo(() => {
    const stepData = findDocumentStep(document, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_CONFIRM_DISCUSSION_RECORD);

    if (!stepData) return null;
    return <LifeSupportConfirmDiscussionRecordStep stepData={stepData} readonly={readonly} document={document} />;
  }, [readonly, document]);

  const renderLifeSupportSubmitStep = useMemo(() => {
    const stepData = findDocumentStep(document, DOCUMENT_STEP_KEYS.LIFE_SUPPORT_SUBMIT);

    if (!stepData) return null;
    return <LifeSupportSubmitStep stepData={stepData} readonly={readonly} document={document} />;
  }, [readonly, document]);

  return (
    <DocumentCard title={`届出書類${document.masterDocument.documentOrder}`} subTitle={document.masterDocument.documentName}>
      {renderLifeSupportCreateStep}
      {renderLifeSupportConfirmDiscussionRecordStep}
      {renderLifeSupportSubmitStep}
    </DocumentCard>
  );
};
