import { TopPage } from "@/components/elements/TopPage";
import { DocumentList } from "@/features/anytime/components/anytime-list/DocumentList";
import { DocumentTemplates } from "@/features/anytime/components/anytime-list/DocumentTemplates";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import React from "react";

export const AnytimeListPage = () => {
  return (
    <>
      <TopPage
        title="その他の随時届出"
        description="新規で作成する場合は、以下から利用シーンもしくは「書類の様式から作成する」を選んでください。"
        backUrl={AppRoutes.home}
      />
      <Stack gap={4}>
        <DocumentTemplates />
        <DocumentList />
      </Stack>
    </>
  );
};
