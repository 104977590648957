import { TypoLink } from "@/components/elements/TypoLink";
import { AppRoutes } from "@/routes/config";

export const ENTER_COMPANY_INFO = {
  title: "会社情報の入力",
  description:
    "法人名、法人番号、支援担当者、特定産業分野などの各種申請で必要となる会社情報を入力しましょう。ここで入力した内容が各種届出や手続きの申請書作成時に転記され、かんたんになります。",
  content: <TypoLink to={AppRoutes.companyEdit}>会社情報の編集へ</TypoLink>,
};

export const ENTER_COMPANY_BRANCH_INFO = {
  title: "事業所情報の入力",
  description:
    "特定技能外国人が勤務する各種事業所についての情報を入力しましょう。事業所が本社のみの場合でも、在留期間更新で必要となる所定労働時間などの入力をお願いいたします。",
  content: <TypoLink to={AppRoutes.companyBranch}>事業所情報の編集へ</TypoLink>,
};

export const CREATE_EMPLOYEE = {
  title: "特定技能外国人の登録",
  description:
    "すでに勤務している、もしくは入社予定の特定技能外国人を登録しましょう。ここで登録した外国人情報に基づき、各種申請や手続きの作成が可能になります。",
  content: <TypoLink to={AppRoutes.employeeCreate}>特定技能外国人の登録へ</TypoLink>,
};

export const INIT_COMPLETE = {
  title: "初期設定完了",
  description: "お疲れ様でした。ご不明な点等ございましたら、気兼ねなくお問い合わせください。",
};
