import * as React from "react";

export const IconUpload = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2.27614 7 2.5 7.22386 2.5 7.5V9.5C2.5 9.63261 2.55268 9.75979 2.64645 9.85355C2.74021 9.94732 2.86739 10 3 10H10C10.1326 10 10.2598 9.94732 10.3536 9.85355C10.4473 9.75978 10.5 9.63261 10.5 9.5V7.5C10.5 7.22386 10.7239 7 11 7C11.2761 7 11.5 7.22386 11.5 7.5V9.5C11.5 9.89783 11.342 10.2794 11.0607 10.5607C10.7794 10.842 10.3978 11 10 11H3C2.60217 11 2.22064 10.842 1.93934 10.5607C1.65804 10.2794 1.5 9.89783 1.5 9.5V7.5C1.5 7.22386 1.72386 7 2 7Z"
      fill="currentColor"
    />
    <path
      d="M3.64645 4.35355C3.84171 4.54882 4.15829 4.54882 4.35355 4.35355L6 2.70711V1.5C6 1.3622 6.05574 1.23742 6.14591 1.14698L6.14645 1.14645L3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355Z"
      fill="currentColor"
    />
    <path d="M6.5 8C6.77614 8 7 7.77614 7 7.5V2.70711L6.5 2.20711L6 2.70711V7.5C6 7.77614 6.22386 8 6.5 8Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85402 1.14692L9.35355 3.64645C9.54882 3.84171 9.54882 4.15829 9.35355 4.35355C9.15829 4.54882 8.84171 4.54882 8.64645 4.35355L7 2.70711V1.5C7 1.49138 6.99978 1.48281 6.99935 1.47429C6.99286 1.34679 6.93844 1.23168 6.85402 1.14692ZM6.30859 1.03795C6.25006 1.06217 6.19519 1.09798 6.14751 1.14539C6.19334 1.09983 6.24795 1.06309 6.30859 1.03795Z"
      fill="currentColor"
    />
    <path
      d="M6.85355 1.14645L6.85249 1.14539C6.80665 1.09982 6.75203 1.06309 6.69139 1.03794C6.63026 1.01265 6.56513 1 6.5 1C6.43486 1 6.36972 1.01265 6.30859 1.03795C6.24795 1.06309 6.19334 1.09983 6.14751 1.14539L6.14645 1.14645L6.14591 1.14698C6.05574 1.23742 6 1.3622 6 1.5V2.70711L6.5 2.20711L7 2.70711V1.5C7 1.49138 6.99978 1.48281 6.99935 1.47429C6.99286 1.34679 6.93844 1.23168 6.85402 1.14692L6.85355 1.14645Z"
      fill="currentColor"
    />
  </svg>
);
