import { withAuth } from "@/hoc/withAuth";
import { AppRoutes } from "@/routes/config";
import { logout } from "@/utils/api";
import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    logout();
    window.location.replace(AppRoutes.login);
  }, []);

  return null;
};

export default withAuth(Logout);
