import { EModifyContractTerminateReasonForCompany, EModifyContractTerminateReasonForEmployee } from "@/features/anytime/constants/enums";
import { EModifyContractTerminateReason } from "@/features/retirement/constants/enums";
import { companySchema } from "@/features/retirement/schema/common/companySchema";
import { companySupporterSchema } from "@/features/retirement/schema/common/companySupporterSchema";
import { validator } from "@/utils/validator";

export const retirementEmploymentContractSchema = validator
  .object()
  .shape({
    // B'
    terminateContractDate: validator.mixed().isSelection().isDayjs().label("終了年月日").notRequired(),
    terminateContractReason: validator.string().trim().emptyToNull().label("終了の事由").isSelection().required(),

    terminateContractReasonByCompany: validator
      .string()
      .emptyToNull()
      .isSelection()
      .label("特定技能所属機関の都合による終了の場合の詳細")
      .notRequired()
      .when("terminateContractReason", {
        is: EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY,
        then: (schema) => schema.required(),
      }),
    terminateContractOtherByCompany: validator
      .string()
      .emptyToNull()
      .label("その他")
      .notRequired()
      .when("terminateContractReason", {
        is: EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY,
        then: (schema) =>
          schema.when("terminateContractReasonByCompany", {
            is: EModifyContractTerminateReasonForCompany.OTHER,
            then: (schema) => schema.required(),
          }),
      }),

    terminateContractReasonByEmployee: validator
      .string()
      .emptyToNull()
      .isSelection()
      .label("外国人の都合による終了の場合の詳細")
      .notRequired()
      .when("terminateContractReason", {
        is: EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE,
        then: (schema) => schema.required(),
      }),

    terminateContractOtherReasonByEmployee: validator
      .string()
      .emptyToNull()
      .label("その他")
      .notRequired()
      .when("terminateContractReason", {
        is: EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE,
        then: (schema) =>
          schema.when("terminateContractReasonByEmployee", {
            is: EModifyContractTerminateReasonForEmployee.OTHER,
            then: (schema) => schema.required(),
          }),
      }),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);

export const retirementEmploymentContractDraftSchema = validator
  .object()
  .shape({
    // B'
    terminateContractDate: validator.mixed().isSelection().isDayjs().label("終了年月日").notRequired(),
    terminateContractReason: validator.string().trim().emptyToNull().label("終了の事由").notRequired(),
    terminateContractReasonByCompany: validator.string().trim().emptyToNull().notRequired(),
    terminateContractOtherByCompany: validator.string().trim().emptyToNull().label("05.その他の内容").notRequired(),
    terminateContractReasonByEmployee: validator.string().trim().emptyToNull().notRequired(),
    terminateContractOtherReasonByEmployee: validator.string().trim().emptyToNull().label("05.その他の内容").notRequired(),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);
