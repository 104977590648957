import { background, blue, text } from "@/theme/colors";
import { fonts, typography } from "@/theme/typography";
import { elevation, spacing } from "@/theme/variables";

export const components = {
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        padding: 0,
        margin: 0,
        boxSizing: "border-box",
      },
      html: {
        padding: 0,
        margin: 0,
        fontFamily: fonts,
      },
      body: {
        padding: 0,
        scrollBehavior: "smooth",
        overflowX: "auto",
        fontFamily: fonts,
        color: text.primary,
        background: background.white,
        margin: 0,
        minWidth: 1200,
        scrollMargin: 1,
      },
      a: {
        textDecoration: "none",
        color: text.secondary,
        textUnderlineOffset: 2,
        // ":visited": {
        //   color: text.primary,
        // },
        "&.active": {
          color: text.primary,
        },
        ":hover": {
          color: text.primary,
        },
      },
      blockquote: {
        borderLeft: "5px solid",
        borderColor: `${background.disable}!important`,
        paddingLeft: "5px",
      },
      "ul,ol li": {
        marginLeft: "1.5em",
      },
      ".MuiDateCalendar-root": {
        height: "unset!important",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: () => ({
        fontWeight: 500,
        gap: spacing.xs,
      }),
      sizeMedium: () => ({
        padding: `${spacing.xxs} ${spacing.xs}`,
        height: 26,
        fontSize: "14px",
      }),
      sizeSmall: () => ({
        padding: `${spacing.xxs} ${spacing.xs}`,
        height: 26,
        fontSize: "12px",
      }),
      icon: {
        margin: 0,
      },
      label: {
        padding: 0,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: () => ({
        ".MuiPaper-root": {
          boxShadow: elevation.lv2,
        },
        ".MuiList-root": {
          padding: 0,
        },
        ".MuiMenuItem-root": {
          padding: 8,
          color: text.primary,
          ...typography.body14,
          "&:hover": {
            background: background.secondary,
          },
          "&.Mui-disabled": {
            opacity: 1,
            background: background.disable,
            color: text.tertiary,
          },
          "&:not(.item-multiple).Mui-selected": {
            background: `${blue[80]}`,
            pointerEvent: "none",
          },
          "&.Mui-focused": {
            background: blue[95],
          },
        },
      }),
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: () => ({
        ".MuiPaper-root": {
          marginTop: "8px",
          boxShadow: elevation.lv2,
          padding: "8px",
        },
      }),
    },
  },
};
