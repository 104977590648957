import { IconAlert } from "@/assets/icons";
import { useFormField } from "@/components/elements/FormField";
import { Radio } from "@/components/elements/Radio";
import { Tooltip } from "@/components/elements/Tooltip";
import { Stack, Typography } from "@mui/material";
import { ChangeEvent, FC, Key, ReactNode } from "react";

export type TRadioOption = {
  value: Key;
  label: string;
  subLabel?: ReactNode;
  helper?: string;
  disabled?: boolean;
};

type TRadioGroupProps = {
  options: TRadioOption[];
  nonControl?: boolean;
  direction?: "row" | "column";
  value?: Key;
  onChange?: (value: Key) => void;
  disabled?: boolean;
};

export const RadioGroup: FC<TRadioGroupProps> = ({ options, nonControl = false, value, direction = "row", onChange, disabled }) => {
  const { field, fieldState } = useFormField(nonControl);

  const checkedValue = field?.value ?? value;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      onChange?.(value);
      field?.onChange(value);
      field?.onBlur();
    }
  };

  return (
    <Stack flexWrap="wrap" direction={direction} gap={direction === "row" ? 3 : 1}>
      {options.map(({ label, subLabel, value, helper, disabled: optionDisabled }) => {
        return (
          <Stack key={value} direction="row" gap={0.5} alignItems="center">
            <Radio
              error={!!fieldState?.error}
              name={field?.name}
              label={label}
              subLabel={subLabel}
              value={value}
              checked={checkedValue === value}
              onChange={handleChange}
              nonControl
              disabled={disabled || optionDisabled}
            />
            {helper && (
              <Tooltip
                title="補足"
                content={
                  <Typography variant="body14" whiteSpace="pre-line">
                    {helper}
                  </Typography>
                }
              >
                <IconAlert />
              </Tooltip>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
