import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { PAYCHECK_CHOSEN_TYPE } from "@/features/regular-document/constants/paycheckChosenType";
import { PAYROLL_STATUS } from "@/features/regular-document/constants/payrollStatus";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { validationPayroll } from "@/features/regular-document/utils/validationPayroll";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export const ButtonGroup = () => {
  const { paycheckRows, handleSaveDraft, handleSave, isDirty, readonly, isEditable, setIsEditable, resourceData, chosenType } = usePayCheck();

  const isValidDraft = useMemo(() => {
    if (chosenType === PAYCHECK_CHOSEN_TYPE.ASK_IROHANA_SUPPORT) return true;
    if (!paycheckRows.length) return false;
    return !paycheckRows.some((record) => record.status === PAYROLL_STATUS.ERROR);
  }, [paycheckRows, isDirty, chosenType]);

  const isValid = useMemo(() => {
    if (!paycheckRows.length) return false;
    return !paycheckRows.some((record) => !validationPayroll(record, resourceData));
  }, [paycheckRows, isDirty]);

  if (readonly) {
    return (
      <InnerBox bordered p={3} boxShadow={elevation.lv2}>
        <Stack direction="row" gap={3} justifyContent="space-between">
          <Button variant="outline" size="lg" component={Link} to={AppRoutes.regularPresentAcceptActivity}>
            戻る
          </Button>
          <Button variant="primary" size="lg" component={Link} to={AppRoutes.regularPresent}>
            本届出のトップに戻る
          </Button>
        </Stack>
      </InnerBox>
    );
  }

  return (
    <InnerBox bordered p={3} boxShadow={elevation.lv2}>
      <Stack direction="row" gap={3} justifyContent="space-between">
        <Button variant="outline" size="lg" component={Link} to={AppRoutes.regularPresentAcceptActivity}>
          戻る
        </Button>
        <Stack direction="row" gap={3}>
          {isEditable && (
            <>
              <Button variant="outline" size="lg" disabled={!isValidDraft} onClick={handleSaveDraft}>
                下書き保存する
              </Button>
              <Button variant="primary" size="lg" disabled={!isValid} onClick={handleSave}>
                保存する
              </Button>
            </>
          )}
          {!isEditable && (
            <>
              <Button
                variant="outline"
                size="lg"
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                再編集する
              </Button>
              <Button variant="primary" size="lg" component={Link} to={AppRoutes.regularPresent}>
                届出トップに戻る
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </InnerBox>
  );
};
