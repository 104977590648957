import { TGetSupportContractModificationDocumentDto } from "@/api/services/main/any-time/support-contract/dtos/get-support-contract-modification-document.dto";
import { TUpdateSupportContractModificationDocumentReqDto } from "@/api/services/main/any-time/support-contract/dtos/update-support-contract-modification-document.dto";
import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { ESupportOutsourcingContractModificationType } from "@/features/anytime/constants/enums";
import { TSupportContractForm } from "@/features/anytime/types/supportContractForm";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { pick } from "@/utils/object";

const intiForm = {
  renewContractDate: null,
  renewContractContent: null,

  modifyContractDate: null,
  modifyContractReason: null,
  modifyContractContent: null,

  terminateContractDate: null,
  terminateContractMajorCategoryReason: null,
  terminateContractSubcategoryReason: null,
  terminateContractOtherReason: null,
};

export const getInitValues = (company: TGetCompanyDto): TSupportContractForm => {
  return {
    employees: [],
    modificationType: ESupportOutsourcingContractModificationType.RENEW_CONTRACT,
    ...intiForm,
    companySupporterName: company?.supporters && company.supporters.length > 0 ? company.supporters[0].supporterName : null,
    companySupporterTelNumber: company?.supporters && company.supporters.length > 0 ? company.supporters[0].telNumber : null,
    companySupporterId: company?.supporters && company.supporters.length > 0 ? company.supporters[0].id : null,
    companyName: company.companyName ?? null,
    companyFuriganaName: company.companyFuriganaName ?? null,
    companySpecificIndustryId: company.specificIndustryId ?? null,
    companyIdentificationNumber: company.companyIdentificationNumber ?? null,
    companyZipCode: company.zipCode ?? null,
    companyProvince: company.province ?? null,
    companyAddress: company.fullAddress ?? null,
    companyTelNumber: company.telNumber ?? null,
  };
};

export const convertFormDataToReqDto = (data: TSupportContractForm, isDraft: boolean): TUpdateSupportContractModificationDocumentReqDto => {
  const modifyContract =
    data.modificationType === ESupportOutsourcingContractModificationType.MODIFY_CONTRACT
      ? {
          modifyContractDate: data.modifyContractDate ? dayjs(data.modifyContractDate).format(ISO_FORMAT) : null,
          modifyContractReason: data.modifyContractReason ?? null,
          modifyContractContent: data.modifyContractContent ?? null,
        }
      : {};

  const terminateContract =
    data.modificationType === ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT
      ? {
          terminateContractDate: data.terminateContractDate ? dayjs(data.terminateContractDate).format(ISO_FORMAT) : null,
          terminateContractMajorCategoryReason: data.terminateContractMajorCategoryReason ?? null,
          terminateContractSubcategoryReason: data.terminateContractSubcategoryReason ?? null,
          terminateContractOtherReason: data.terminateContractOtherReason ?? null,
        }
      : {};

  const renewContract =
    data.modificationType === ESupportOutsourcingContractModificationType.RENEW_CONTRACT
      ? {
          renewContractDate: data.renewContractDate ? data.renewContractDate.format(ISO_FORMAT) : null,
          renewContractContent: data.renewContractContent,
        }
      : {};

  return {
    employees: data.employees,
    modificationType: data.modificationType,
    ...intiForm,
    ...modifyContract,
    ...renewContract,
    ...terminateContract,
    companySupporterId: data.companySupporterId ? toNumber(data.companySupporterId) : null,
    companySupporterName: data.companySupporterName ?? null,
    companySupporterTelNumber: data.companySupporterTelNumber ?? null,
    companyName: data.companyName ?? null,
    companyFuriganaName: data.companyFuriganaName ?? null,
    companySpecificIndustryId: data.companySpecificIndustryId ?? null,
    companyIdentificationNumber: data.companyIdentificationNumber ?? null,
    companyZipCode: data.companyZipCode ?? null,
    companyProvince: data.companyProvince ?? null,
    companyAddress: data.companyAddress ?? null,
    companyTelNumber: data.companyTelNumber ?? null,
    isDraft,
  };
};

export const convertResDtoToFormData = (data: TGetSupportContractModificationDocumentDto): TSupportContractForm => {
  return {
    ...data,
    employees: data.employees.map((employee) =>
      pick(
        employee,
        "employeeId",
        "employeeFullName",
        "employeeNickName",
        "employeeGender",
        "employeeNationalityId",
        "employeeResidenceCardNumber",
        "birthday",
        "zipCode",
        "fullAddress",
        "telNumber",
      ),
    ),
    modifyContractDate: data.modifyContractDate ? dayjs(data.modifyContractDate) : null,
    terminateContractDate: data.terminateContractDate ? dayjs(data.terminateContractDate) : null,
    renewContractDate: data.renewContractDate ? dayjs(data.renewContractDate) : null,
  };
};
