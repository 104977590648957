import { useGetRenewVisaDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { DATE_FORMAT } from "@/constants/datetime";
import { RenewVisaAfterSubmitApplicationStep } from "@/features/renew-visa/components/present/RenewVisaAfterSubmitApplicationStep";
import { RenewVisaApplicationStep } from "@/features/renew-visa/components/present/RenewVisaApplicationStep";
import { RenewVisaBasicInfoStep } from "@/features/renew-visa/components/present/RenewVisaBasicInfoStep";
import { RenewVisaOnlineSubmitApplicationStep } from "@/features/renew-visa/components/present/RenewVisaOnlineSubmitApplicationStep";
import { RenewVisaResidenceCardInfoStep } from "@/features/renew-visa/components/present/RenewVisaResidenceCardInfoStep";
import { findRenewVisaDocumentStep } from "@/features/renew-visa/utils/findDocumentStep";
import { text } from "@/theme/colors";
import { DOCUMENT_STATUS, DOCUMENT_STEP_KEYS } from "@/types/enum";
import { toNumber } from "@/utils/number";
import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

export const RenewVisaPresentPage = () => {
  const { data: renewVisaDocument, isLoading: isLoadingRenewVisaDocument } = useGetRenewVisaDocumentInfo();

  const basicInfoStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_BASIC_INFORMATION);
  const applicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_CREATE_DOCUMENT);
  const onlineSubmitApplicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_SUBMIT);
  const afterSubmitApplicationStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_AFTER_SUBMIT_APPLICATION);
  const residenceCardInfoStepData = findRenewVisaDocumentStep(renewVisaDocument, DOCUMENT_STEP_KEYS.RENEW_VISA_UPDATE_RESIDENCE_CARD);

  if (isLoadingRenewVisaDocument) return <LoadingPage />;

  return (
    <Box p="24px">
      <Stack flexDirection="row" gap="8px" mb="24px">
        <Typography variant="sub16Bold" color={text.error}>
          提出締切
        </Typography>
        <Typography variant="sub16Bold" color={text.error}>
          {renewVisaDocument && dayjs(renewVisaDocument.residenceExpiryDate).subtract(1, "day").format(DATE_FORMAT)}
        </Typography>
      </Stack>
      <Stack gap="24px">
        {basicInfoStepData && <RenewVisaBasicInfoStep stepData={basicInfoStepData} readonly={false} />}
        {applicationStepData && basicInfoStepData && (
          <RenewVisaApplicationStep
            employeeId={toNumber(renewVisaDocument?.employeeId)}
            basicInfoStepData={basicInfoStepData}
            stepData={applicationStepData}
            readonly={false}
          />
        )}
        {onlineSubmitApplicationStepData && (
          <RenewVisaOnlineSubmitApplicationStep
            stepData={onlineSubmitApplicationStepData}
            readonly={applicationStepData?.status !== DOCUMENT_STATUS.COMPLETE}
          />
        )}
        {afterSubmitApplicationStepData && (
          <RenewVisaAfterSubmitApplicationStep
            stepData={afterSubmitApplicationStepData}
            readonly={onlineSubmitApplicationStepData?.status !== DOCUMENT_STATUS.COMPLETE}
          />
        )}
        {residenceCardInfoStepData && (
          <RenewVisaResidenceCardInfoStep
            employee={renewVisaDocument?.employee}
            stepData={residenceCardInfoStepData}
            readonly={
              afterSubmitApplicationStepData?.status !== DOCUMENT_STATUS.COMPLETE || residenceCardInfoStepData.status === DOCUMENT_STATUS.COMPLETE
            }
          />
        )}
      </Stack>
    </Box>
  );
};
