import { valueOf } from "@/types/common";

export const NOTIFICATION_TAB = {
  ALL: "1",
  SYSTEM: "system",
  IROHANA: "tenant",
};

export type TNotificationTab = valueOf<typeof NOTIFICATION_TAB>;

export const NOTIFICATION_TAB_LABEL = {
  [NOTIFICATION_TAB.ALL]: "すべて",
  [NOTIFICATION_TAB.SYSTEM]: "制度・手続き",
  [NOTIFICATION_TAB.IROHANA]: "irohana",
};
