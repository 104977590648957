import { TCompany } from "@/api/entities/company.entity";
import { TCompanyBranch } from "@/api/entities/companyBranch.entity";
import { useCompanyBranchList, useCompanyInfo } from "@/api/services/main/company";
import { useEmployeeList } from "@/api/services/main/employee";
import { RequireCreateEmployeeModal } from "@/components/commons/RequireCreateEmployeeModal";
import { RequireEditCompanyBranchModal } from "@/components/commons/RequireEditCompanyBranchModal";
import { RequireEditCompanyInfoModal } from "@/components/commons/RequireEditCompanyInfoModal";
import { useGetCompanySchema } from "@/features/company/hooks/useGetCompanySchema";
import { CompanyBranchSchema } from "@/features/company/schema/companyBranchSchema";
import { useVisible } from "@/hooks/useVisible";
import { FCC } from "@/types/common";
import { createContext, MouseEventHandler, useContext, useMemo } from "react";

export type InitSetupType = {
  currentStep: number;
  checkFinishInitStep: MouseEventHandler<HTMLElement>;
  isLoading: boolean;
};

export const InitSetupContext = createContext<InitSetupType | undefined>(undefined);

export const InitSetupProvider: FCC = ({ children }) => {
  const companyInfoStep = useVisible();
  const employeeCreateStep = useVisible();
  const companyBranchStep = useVisible();

  const { companySchema } = useGetCompanySchema();

  const { data: companyInfoResult, isLoading: isCompanyInfoLoading } = useCompanyInfo();
  const { data: employeeListResult, isLoading: isEmployeeListLoading } = useEmployeeList({ filter: {} });
  const { data: companyBranchListResult, isLoading: isCompanyBranchLoading } = useCompanyBranchList({});

  const isPassCompanyInfoStep = useMemo(() => {
    if (!companyInfoResult) return false;
    const compareKeys: (keyof TCompany)[] = [];
    Object.entries(companySchema.fields).forEach(([key, value]) => {
      const fieldDes = value.describe();
      if ("optional" in fieldDes && fieldDes.optional === false && "nullable" in fieldDes && fieldDes.nullable === false) {
        compareKeys.push(key as keyof TCompany);
      }
    });
    return !compareKeys.some(
      (key) => companyInfoResult.data[key] === null || companyInfoResult.data[key] === undefined || companyInfoResult.data[key] === "",
    );
  }, [companyInfoResult]);

  const isPassEmployeeCreateStep = useMemo(() => {
    if (!employeeListResult) return false;
    return employeeListResult.data.length > 0;
  }, [employeeListResult]);

  const isPassCompanyBranchStep = useMemo(() => {
    if (!companyBranchListResult || !companyBranchListResult.data.length) return false;
    if (companyBranchListResult.data.length === 1) {
      const compareKeys: (keyof TCompanyBranch)[] = [];
      Object.entries(CompanyBranchSchema.fields).forEach(([key, value]) => {
        const fieldDes = value.describe();
        if ("optional" in fieldDes && fieldDes.optional === false && "nullable" in fieldDes && fieldDes.nullable === false) {
          compareKeys.push(key as keyof TCompanyBranch);
        }
      });
      return !compareKeys.some(
        (key) =>
          companyBranchListResult.data[0][key] === null ||
          companyBranchListResult.data[0][key] === undefined ||
          companyBranchListResult.data[0][key] === "",
      );
    }
    return true;
  }, [companyBranchListResult]);

  const currentStep = useMemo(() => {
    if (!isPassCompanyInfoStep) return 1;
    if (!isPassCompanyBranchStep) return 2;
    if (!isPassEmployeeCreateStep) return 3;
    return 4;
  }, [isPassCompanyInfoStep, isPassEmployeeCreateStep, isPassCompanyBranchStep]);

  const checkFinishInitStep: MouseEventHandler<HTMLElement> = (e) => {
    if (!isPassCompanyInfoStep) {
      e.preventDefault();
      companyInfoStep.open();
      return;
    }
    if (!isPassCompanyBranchStep) {
      e.preventDefault();
      companyBranchStep.open();
      return;
    }
    if (!isPassEmployeeCreateStep) {
      e.preventDefault();
      employeeCreateStep.open();
      return;
    }
  };

  return (
    <InitSetupContext.Provider
      value={{
        currentStep,
        checkFinishInitStep,
        isLoading: isCompanyInfoLoading || isEmployeeListLoading || isCompanyBranchLoading,
      }}
    >
      {children}
      <RequireEditCompanyInfoModal open={companyInfoStep.visible} onClose={companyInfoStep.close} />
      <RequireCreateEmployeeModal open={employeeCreateStep.visible} onClose={employeeCreateStep.close} />
      <RequireEditCompanyBranchModal open={companyBranchStep.visible} onClose={companyBranchStep.close} />
    </InitSetupContext.Provider>
  );
};

export const useInitSetup = (): InitSetupType => {
  const context = useContext(InitSetupContext);
  if (!context) {
    throw new Error("useInitSetup must be used within a InitSetupProvider");
  }
  return context;
};
