import { ESupportOutsourcingContractModificationType, ETerminateContractSubcategoryReason } from "@/features/anytime/constants/enums";
import { companySchema } from "@/features/anytime/schema/common/companySchema";
import { companySupporterSchema } from "@/features/anytime/schema/common/companySupporterSchema";
import { employeeSchema } from "@/features/anytime/schema/common/employeeSchema";
import { validator } from "@/utils/validator";

export const supportContractSchema = validator
  .object()
  .shape({
    employees: validator
      .array()
      .of(employeeSchema)
      .test((value, ctx) => {
        if (Array.isArray(value) && value.length === 0) return ctx.createError({ message: "１人以上の外国人を追加してください。" });
        return true;
      })
      .required(),
    modificationType: validator.string().trim().emptyToNull().required(),

    // A
    renewContractDate: validator
      .mixed()
      .isSelection()
      .isDayjs()
      .label("締結年月日")
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.RENEW_CONTRACT,
        then: (schema) => schema.required(),
      }),
    renewContractContent: validator
      .string()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("契約の内容（全角、20文字以内）")
      .max(20)
      .fullWidthOnly()
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.RENEW_CONTRACT,
        then: (schema) => schema.required(),
      }),

    // B
    modifyContractDate: validator
      .mixed()
      .isSelection()
      .isDayjs()
      .label("変更年月日")
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.MODIFY_CONTRACT,
        then: (schema) => schema.required(),
      }),
    modifyContractReason: validator
      .string()
      .isSelection()
      .emptyToNull()
      .label("変更事項")
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.MODIFY_CONTRACT,
        then: (schema) => schema.required(),
      }),
    modifyContractContent: validator
      .string()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("変更後の内容（全角、20文字以内）")
      .max(20)
      .fullWidthOnly()
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.MODIFY_CONTRACT,
        then: (schema) => schema.required(),
      }),

    // C
    terminateContractDate: validator
      .mixed()
      .isSelection()
      .isDayjs()
      .label("終了年月日")
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT,
        then: (schema) => schema.required(),
      }),
    terminateContractMajorCategoryReason: validator
      .string()
      .isSelection()
      .emptyToNull()
      .label("終了の事由　大分類")
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT,
        then: (schema) => schema.required(),
      }),
    terminateContractSubcategoryReason: validator
      .string()
      .isSelection()
      .emptyToNull()
      .label("終了の事由　小分類")
      .notRequired()
      .when("modificationType", {
        is: ESupportOutsourcingContractModificationType.TERMINATE_CONTRACT,
        then: (schema) => schema.required(),
      }),
    terminateContractOtherReason: validator
      .string()
      .emptyToNull()
      .autoTransformToFullWidth()
      .label("その他の内容")
      .notRequired()
      .max(100)
      .fullWidthOnly()
      .when("terminateContractSubcategoryReason", {
        is: ETerminateContractSubcategoryReason.OTHER,
        then: (schema) => schema.required(),
      }),
  })
  .concat(companySchema)
  .concat(companySupporterSchema);
