import { useEmployeeList } from "@/api/services/main/employee";
import { EMPLOYEE_FILTER } from "@/features/employee/constants/employeeFilter";
import { toNumber } from "@/utils/number";

type UseGetOfferEmployeeQuantityReturn = {
  quantity: number;
};
export const useGetOfferEmployeeQuantity = (): UseGetOfferEmployeeQuantityReturn => {
  const { data } = useEmployeeList({
    filter: EMPLOYEE_FILTER.OFFER,
  });

  return {
    quantity: toNumber(data?.pagination.total),
  };
};
