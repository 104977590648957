import { useGetRenewVisaUploadFileDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { UploadApplicationForm } from "@/features/renew-visa/components/applicationStep/uploadApplication/UploadApplicationForm";
import { useCheckRenewVisaHistoryPage } from "@/features/renew-visa/hook/useCheckRenewVisaHistoryPage";
import { useGetUploadFileListWithFilterCondition } from "@/features/renew-visa/hook/useGetUploadFileListWithFilterCondition";
import { convertResToUploadApplicationFormData } from "@/features/renew-visa/utils/convertResToUploadApplicationFormData";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { getDynamicRoute } from "@/utils/url";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

type TRenewVisaHistoryUploadApplicationPageProps = {};

export const RenewVisaHistoryUploadApplicationPage: FCC<TRenewVisaHistoryUploadApplicationPageProps> = ({}) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const { data: uploadFileData, isLoading: isLoadingUploadFile } = useGetRenewVisaUploadFileDocumentInfo({
    documentStepId: documentStepId ?? "",
    enabled: !!documentStepId,
  });

  const { filterConditionArrayKeys, isLoading: isLoadingUploadFileListWithFilterCondition } = useGetUploadFileListWithFilterCondition();

  const { isHistoryPage } = useCheckRenewVisaHistoryPage();

  const isLoading = useMemo(
    () => isLoadingUploadFile || isLoadingUploadFileListWithFilterCondition,
    [isLoadingUploadFile, isLoadingUploadFileListWithFilterCondition],
  );

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <TopPage
        title="添付書類"
        isDraft={uploadFileData?.isDraft}
        backUrl={
          isHistoryPage
            ? getDynamicRoute(AppRoutes.renewVisaInfoHistoryDetail, { renewVisaDocumentId })
            : getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId })
        }
        description="対象の外国人の在留期間更新に必要な書類一覧です。適切な書類をPDFにした上でアップロードください。"
      />
      <UploadApplicationForm readonly previousValues={convertResToUploadApplicationFormData(filterConditionArrayKeys, uploadFileData)} />
    </>
  );
};
