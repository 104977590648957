import { useCompanyInfo } from "@/api/services/main/company";
import { IconUser } from "@/assets/icons";
import { Button } from "@/components/elements/Button";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { TAcceptanceActivityForm } from "@/features/regular-document/types/acceptanceActivityForm";
import { divider, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { setErrorForm, showError } from "@/utils/error";
import { Box, css, Grid, styled } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TCompanySupporterFormProps = {
  readonly?: boolean;
};

export const CompanySupporterForm: FC<TCompanySupporterFormProps> = ({ readonly }) => {
  const methods = useFormContext<TAcceptanceActivityForm>();
  const { control, setValue } = methods;
  const { refetch } = useCompanyInfo(false);

  const refreshCompanyInfo = async () => {
    try {
      const rs = await refetch();
      const companyData = rs.data?.data;
      if (!companyData || companyData.supporters.length === 0) return;
      setValue("companySupporterId", companyData.supporters[0].id, { shouldDirty: true });
      setValue("companySupporterName", companyData.supporters[0].supporterName, { shouldDirty: true });
      setValue("companySupporterTelNumber", companyData.supporters[0].telNumber, { shouldDirty: true });
      setValue("companySupporterPosition", companyData.supporters[0].companyPosition, { shouldDirty: true });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <InnerBox
      title="届出担当者・作成責任者"
      bordered
      p={2}
      rightContent={
        readonly ? undefined : (
          <Button size="sm" onClick={refreshCompanyInfo}>
            連絡担当者の情報を再取得する
          </Button>
        )
      }
    >
      <StyledUser>
        <IconUser fontSize={16} /> {methods.getValues("companySupporterName")}
      </StyledUser>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          <FormField control={control} name="companySupporterTelNumber">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companySupporterPosition">
            <Input readonly />
          </FormField>
        </Grid>
      </Grid>
    </InnerBox>
  );
};

const StyledUser = styled(Box)`
  width: fit-content;
  align-items: center;
  width: fit-content;
  padding: 4px 12px;
  border: 1px solid ${divider.middle};
  color: ${text.primary};
  ${css(typography.body14)}
  display: flex;
  align-items: center;
  gap: 8px;
`;
