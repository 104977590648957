import { state } from "@/theme/colors";
import { css, styled, Typography, TypographyProps } from "@mui/material";
import { FC, HTMLAttributeAnchorTarget } from "react";
import { Link } from "react-router-dom";

type TTypoLinkProps = {
  to: string;
  target?: HTMLAttributeAnchorTarget;
} & TypographyProps;

export const TypoLink: FC<TTypoLinkProps> = ({ to, target, ...rest }) => {
  return <StyledTypoLink component={Link} to={to} target={target} {...rest} />;
};

const StyledTypoLink = styled(Typography)<{ to: string; target?: string }>(() => TypoLinkCSS);

const TypoLinkCSS = css`
  width: fit-content;
  color: ${state.link_1};
  text-decoration: underline;
  text-underline-offset: 2px;
  &:hover {
    color: ${state.link_2};
  }
`;
