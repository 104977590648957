import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { Table } from "@/components/elements/Table";
import { useGetProvinceAndMunicipalityList } from "@/features/renew-visa/hook/useGetProvinceAndMunicipalityList";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { useFormTable } from "@/hooks/useFormTable";
import { dayjs } from "@/utils/dayjs";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useEffect } from "react";
import { useFormContext, useFormState } from "react-hook-form";

type TEmployeeDispatchingOrganizationFormTableProps = {
  readonly: boolean;
};

export const EmployeeDispatchingOrganizationFormTable: FC<TEmployeeDispatchingOrganizationFormTableProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control, setValue } = methods;
  const { isLoading: isLoadingProvincesAndMunicipalities, provinceOptions, getMunicipalityOptionsByProvince } = useGetProvinceAndMunicipalityList();

  const columns: MRT_ColumnDef<TEmployeeApplicationForm>[] = [
    {
      header: "氏名又は名称",
      Cell: ({ row }) => {
        return (
          <FormField id="organizationName" control={control} name={`dispatchingOrganizations.${row.index}.organizationName`}>
            <Input transformToFullWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "法人番号",
      Cell: ({ row }) => {
        return (
          <FormField
            id="organizationIdentificationNumber"
            control={control}
            name={`dispatchingOrganizations.${row.index}.organizationIdentificationNumber`}
          >
            <Input transformToHalfWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "雇用保険適用事業所番号",
      Cell: ({ row }) => {
        return (
          <FormField
            id="employmentInsuranceApplicableEstablishmentNumber"
            control={control}
            name={`dispatchingOrganizations.${row.index}.employmentInsuranceApplicableEstablishmentNumber`}
          >
            <Input transformToHalfWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "派遣先所在地（都道府県）",
      Cell: ({ row }) => {
        return (
          <FormField id="organizationProvince" control={control} name={`dispatchingOrganizations.${row.index}.organizationProvince`}>
            <Select
              readonly={readonly}
              onChange={() => {
                setValue(`dispatchingOrganizations.${row.index}.organizationMunicipality`, "");
              }}
              options={provinceOptions}
              loading={isLoadingProvincesAndMunicipalities}
            />
          </FormField>
        );
      },
    },
    {
      header: "派遣先所在地（市区町村）",
      Cell: ({ row }) => {
        const provinceWatchField = methods.watch(`dispatchingOrganizations.${row.index}.organizationProvince`);
        return (
          <FormField id="organizationMunicipality" control={control} name={`dispatchingOrganizations.${row.index}.organizationMunicipality`}>
            <Select
              readonly={readonly}
              options={provinceWatchField ? getMunicipalityOptionsByProvince(provinceWatchField) : []}
              loading={isLoadingProvincesAndMunicipalities}
              disabled={!provinceWatchField}
            />
          </FormField>
        );
      },
    },
    {
      header: "派遣先所在地（都道府県以外）",
      Cell: ({ row }) => {
        return (
          <FormField id="organizationAddress" control={control} name={`dispatchingOrganizations.${row.index}.organizationAddress`}>
            <Input transformToFullWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "電話番号",
      Cell: ({ row }) => {
        return (
          <FormField id="organizationTelNumber" control={control} name={`dispatchingOrganizations.${row.index}.organizationTelNumber`}>
            <Input transformToHalfWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "代表者の氏名",
      Cell: ({ row }) => {
        return (
          <FormField id="representativeName" control={control} name={`dispatchingOrganizations.${row.index}.representativeName`}>
            <Input transformToFullWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "派遣期間（始期）",
      Cell: ({ row }) => {
        return (
          <FormField id="startDate" control={control} name={`dispatchingOrganizations.${row.index}.startDate`}>
            <InputDatePicker minDate={dayjs("1900/01/01")} readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "派遣期間（終期）",
      Cell: ({ row }) => {
        return (
          <FormField id="endDate" control={control} name={`dispatchingOrganizations.${row.index}.endDate`}>
            <InputDatePicker minDate={dayjs("1900/01/01")} readonly={readonly} />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({ columns, control, name: "dispatchingOrganizations", readonly });

  table.addNewRow = undefined;

  const { errors } = useFormState({ control });

  useEffect(() => {
    const tableElement = document.getElementById("dispatching-organization-form-table-id")?.getElementsByClassName("MuiTableContainer-root")[0];
    if (tableElement) {
      const firstErrorField = errors && errors.dispatchingOrganizations ? errors.dispatchingOrganizations[0] : null;
      if (firstErrorField) {
        const firstErrorFieldName = Object.keys(firstErrorField)[0];
        const errorFieldElement = document.getElementById(`${firstErrorFieldName}`) as HTMLInputElement;
        const organizationNameElement = document.getElementById("organizationName") as HTMLInputElement;
        if (errorFieldElement && organizationNameElement) {
          tableElement.scrollTo({
            behavior: "smooth",
            left: errorFieldElement.getBoundingClientRect().left - organizationNameElement.getBoundingClientRect().left,
          });
        }
      }
    }
  }, [errors]);

  return (
    <InnerBox title="派遣先" bordered p={3}>
      <Table id="dispatching-organization-form-table-id" table={table} />
    </InnerBox>
  );
};
