import { Form } from "@/components/elements/Form";
import { MenuNavigate } from "@/components/elements/MenuNavigate";
import { TopPage } from "@/components/elements/TopPage";
import { CircumstanceExplanation } from "@/features/retirement/components/retirement-detail/accepting-worker/CircumstanceExplanation";
import { CurrentSituation } from "@/features/retirement/components/retirement-detail/accepting-worker/CurrentSituation";
import { RetirementEmployeeForm } from "@/features/retirement/components/retirement-detail/accepting-worker/forms/RetirementEmployeeForm";
import { LegalProtectionStatus } from "@/features/retirement/components/retirement-detail/accepting-worker/LegalProtectionStatus";
import { MeasuresTaken } from "@/features/retirement/components/retirement-detail/accepting-worker/MeasuresTaken";
import { SpecificSkillManagingAgency } from "@/features/retirement/components/retirement-detail/accepting-worker/SpecificSkillManagingAgency";
import { TargetForeigner } from "@/features/retirement/components/retirement-detail/accepting-worker/TargetForeigner";
import { CompanySupporterForm } from "@/features/retirement/components/retirement-detail/accepting-worker/CompanySupporterForm";
import { retirementAcceptingWorkerSchema } from "@/features/retirement/schema/retirementAcceptingWorkerSchema";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

type TRetirementAcceptingWorkerEditPageProps = {
  defaultValues?: Partial<TRetirementAcceptingWorkerForm>;
};

export const RetirementAcceptingWorkerDetailPage: FC<TRetirementAcceptingWorkerEditPageProps> = ({ defaultValues }) => {
  const { documentGroupId } = useParams();
  const methods = useForm<TRetirementAcceptingWorkerForm>({
    defaultValues,
    resolver: yupResolver(retirementAcceptingWorkerSchema),
  });

  const onSubmit = () => {};

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={retirementAcceptingWorkerSchema}>
      <TopPage title="受入れ困難に係る届出" backUrl={getDynamicRoute(AppRoutes.retirementDocument, { documentGroupId })} />
      <MenuNavigate
        contents={[
          { title: "対象の外国人", content: <RetirementEmployeeForm readonly /> },
          { title: "届出の事由", content: <TargetForeigner readonly /> },
          { title: "特定技能外国人の現状", content: <CurrentSituation readonly /> },
          { title: "受け入れ継続のための措置", content: <MeasuresTaken readonly /> },
          { title: "特定技能所属機関", content: <SpecificSkillManagingAgency readonly /> },
          { title: "受入れ困難となるに至った経緯に係る説明書（参考様式第５－１１号）", content: <CircumstanceExplanation readonly /> },
          { title: "法的保護のための案内実施の有無", content: <LegalProtectionStatus readonly /> },
          { title: "届出担当者", content: <CompanySupporterForm readonly /> },
        ]}
      />
    </Form>
  );
};
