import { Tag, TTagVariant } from "@/components/elements/Tag";
import { background, state, text } from "@/theme/colors";
import { FC, useMemo } from "react";

export type TStatusTagProps = {
  status: string;
  title: string;
  variant: TTagVariant;
};

export const StatusTag: FC<TStatusTagProps> = ({ status, title, variant }) => {
  const tagProps = useMemo(() => {
    if (["complete", "during_contract", "trial", "active", "offered", "supported"].includes(status)) {
      return { color: state.success_1, sx: { color: variant === "bold" ? text.white : text.primary } };
    }
    if (["expired_contract", "in_progress", "inactice", "working", "not_supported"].includes(status)) {
      return { color: state.warning_1, sx: { color: variant === "bold" ? text.white : text.primary } };
    }
    if (["error", "left", "no_participant"].includes(status)) {
      return { color: state.error_1, sx: { color: variant === "bold" ? text.white : text.primary } };
    }
    return { color: background.disable };
  }, [status]);

  return <Tag variant={variant} {...tagProps} title={title} />;
};
