import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { ChangesForm } from "@/features/anytime/components/anytime-detail/employment-contract/forms/ChangesForm";
import { ConclusionForm } from "@/features/anytime/components/anytime-detail/employment-contract/forms/ConclusionForm";
import { TerminationForm } from "@/features/anytime/components/anytime-detail/employment-contract/forms/TerminationForm";
import { EEmploymentContractModificationType } from "@/features/anytime/constants/enums";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { Stack } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TReasonFormProps = {
  readonly?: boolean;
};

export const ReasonForm: FC<TReasonFormProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TEmploymentContractForm>();
  const value = useWatch({ control, name: "modificationType" });
  const { data: employmentContractModificationTypeOptionsResult } = useEnum("EEmploymentContractModificationType");

  const employmentContractModificationTypeOptions = employmentContractModificationTypeOptionsResult.map((option) => {
    if (option.value === EEmploymentContractModificationType.TERMINATE_CONTRACT) {
      return { ...option, disabled: true };
    }
    return option;
  });

  return (
    <Stack gap={2}>
      <InnerBox title="届出の事由" required>
        <FormField control={control} name="modificationType" label="">
          <RadioGroup
            options={employmentContractModificationTypeOptions}
            disabled={readonly}
            onChange={(value) => {
              if (value !== EEmploymentContractModificationType.MODIFY_CONTRACT) {
                setValue("modifyContractDate", null);
                setValue("modifyContractModificationReasons", null);
                setValue("modifyContractChangeDispatchLocationReasons", null);
                setValue("modifyContractContent", null);
              }
              if (value !== EEmploymentContractModificationType.RENEW_CONTRACT) {
                setValue("renewContractDate", null);
                setValue("renewContractContent", null);
              }
            }}
          />
        </FormField>
      </InnerBox>
      {value === EEmploymentContractModificationType.MODIFY_CONTRACT && <ChangesForm readonly={readonly} />}
      {value === EEmploymentContractModificationType.TERMINATE_CONTRACT && <TerminationForm readonly={readonly} />}
      {value === EEmploymentContractModificationType.RENEW_CONTRACT && <ConclusionForm readonly={readonly} />}
    </Stack>
  );
};
