import { useGetRenewVisaDocumentList } from "@/api/services/main/renew-visa";
import { Pagination } from "@/components/elements/Pagination";
import { Table } from "@/components/elements/Table";
import { TopPage } from "@/components/elements/TopPage";
import { DEFAULT_PER_PAGE, START_PAGE } from "@/constants/pagination";
import { RenewVisaExportCsvButton } from "@/features/renew-visa/components/RenewVisaExportCsvButton";
import { useMakeRenewVisaColumns } from "@/features/renew-visa/hook/useMakeRenewVisaColumns";
import { useSelectionTable } from "@/hooks/useSelectionTable";
import { useSorting } from "@/hooks/useSorting";
import { AppRoutes } from "@/routes/config";
import { background, divider } from "@/theme/colors";
import { TApiSortsReq } from "@/types/api";
import { DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { Stack, styled } from "@mui/material";

export const RenewVisaListPage = () => {
  const { sorts } = useSorting();
  const query = new URLSearchParams(location.search);

  const { data: employeeRenewVisaDocumentList, isLoading } = useGetRenewVisaDocumentList({
    page: Number(query.get("page") || START_PAGE),
    perPage: Number(query.get("perPage") || DEFAULT_PER_PAGE),
    sorts: sorts?.length > 0 ? (sorts as TApiSortsReq[]) : ["renewVisaDocument.status|ASC", "renewVisaDocument.expectSubmitDate|ASC"],
  });

  const columns = useMakeRenewVisaColumns();

  const { table, rowSelectionKeys } = useSelectionTable({
    columns,
    rows: employeeRenewVisaDocumentList?.data ?? [],
    primaryKey: "id",
    defaultSelectionKeys: [],
    muiTableBodyRowProps: ({ row }) => ({
      sx:
        row.original.status !== DOCUMENT_STATUS.COMPLETE && dayjs(row.original.expectSubmitDate).isBefore(dayjs())
          ? { backgroundColor: `${background.error} !important`, "&:hover": `${background.error} !important` }
          : undefined,
    }),
  });

  return (
    <>
      <TopPage title="在留期間更新" backUrl={AppRoutes.home} />
      <RenewVisaListWrapper gap={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Pagination totalPage={employeeRenewVisaDocumentList?.pagination.totalPage} />
          <RenewVisaExportCsvButton selectedRenewVisaDocumentIds={rowSelectionKeys} />
        </Stack>
        <Table table={table} isLoading={isLoading} />
      </RenewVisaListWrapper>
    </>
  );
};

const RenewVisaListWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${divider.high};

  .MuiTableRow-root:not(.MuiTableRow-head):hover {
    &,
    td {
      background-color: transparent;
      &::after {
        background-color: transparent;
      }
    }
  }
`;
