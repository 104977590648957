import { TAnyTimeDocumentEmployee } from "@/api/entities/any-time-document-employee.entity";
import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { EMPTY_STR } from "@/constants/string";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

type TUseEligibleForeignNationalColumnsProps = {};

export const useEligibleForeignNationalColumns = (props: TUseEligibleForeignNationalColumnsProps) => {
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<Omit<TAnyTimeDocumentEmployee, "id">>[]>(
    () => [
      {
        header: "氏名",
        size: 120,
        Cell: ({ row }) => {
          return row.original.employeeFullName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "呼び名",
        size: 120,
        Cell: ({ row }) => {
          return row.original.employeeNickName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "性別",
        size: 120,
        Cell: ({ row }) => {
          return findLabelByValue(genderOptions, row.original.employeeGender) || EMPTY_STR.TEXT;
        },
      },
      {
        header: "生年月日",
        size: 120,
        Cell: ({ row }) => {
          return row.original.birthday || EMPTY_STR.DATE;
        },
      },
      {
        header: "国籍",
        size: 120,
        Cell: ({ row }) => {
          return findLabelByValue(nationalityOptions, row.original.employeeNationalityId) || EMPTY_STR.TEXT;
        },
      },
      {
        header: "在留カード番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.employeeResidenceCardNumber || EMPTY_STR.TEXT;
        },
      },
      {
        header: "郵便番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.zipCode ? convertNumberToZipCode(row.original.zipCode) : EMPTY_STR.TEXT;
        },
      },
      {
        header: "住所",
        size: 120,
        Cell: ({ row }) => {
          return row.original.fullAddress || EMPTY_STR.TEXT;
        },
      },
      {
        header: "電話番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.telNumber || EMPTY_STR.TEXT;
        },
      },
      {
        header: "特定産業分野",
        size: 120,
        Cell: ({ row }) => {
          return row.original.specificIndustryName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "業務区分",
        size: 120,
        Cell: ({ row }) => {
          return row.original.specificBusinessClassificationName || EMPTY_STR.TEXT;
        },
      },
    ],
    [genderOptions, nationalityOptions],
  );

  return columns;
};
