import { useGovernmentOnlineSystemAccountInfo } from "@/api/services/main/company";
import { CompanyAccountForm } from "@/features/company/components/CompanyAccountForm";
import { convertCompanyAccountResToFormDataDto } from "@/features/company/utils/convertResToFormData";

export const CompanyAccountEditPage = () => {
  const { data, isLoading } = useGovernmentOnlineSystemAccountInfo();
  const governmentOnlineSystemAccountData = data?.data ? convertCompanyAccountResToFormDataDto(data.data) : undefined;

  if (isLoading) return null;
  return <CompanyAccountForm previousValues={governmentOnlineSystemAccountData} />;
};
