import { globalApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { TApiRes } from "@/types/api";
import { TOption } from "@/types/common";
import { toNumber } from "@/utils/number";

export type TProvinceOutputDto = {
  prefectureCode: number;
  prefectureName: string;
  municipalities: TMunicipalityOutputDto[];
};

export type TMunicipalityOutputDto = {
  municipalityName: string;
  municipalityCode: number;
};

export type TGetProvincesAndMunicipalitiesResDto = TApiRes<TProvinceOutputDto[]>;

export const fetchProvinceAndMunicipalitiesApi = async (): Promise<TGetProvincesAndMunicipalitiesResDto> => {
  const rs = await globalApi.get<TGetProvincesAndMunicipalitiesResDto>(`/provinces-and-municipalities`);
  return rs.data;
};

const RENEW_VISA_MUNICIPALITY_QUERY_KEY = "RENEW_VISA_MUNICIPALITY_QUERY_KEY";

export const useGetProvinceAndMunicipalityList = () => {
  const { data, isLoading } = useQuery({
    queryKey: [RENEW_VISA_MUNICIPALITY_QUERY_KEY],
    queryFn: () => fetchProvinceAndMunicipalitiesApi(),
  });

  const provinceOptions: TOption[] = useMemo(() => {
    if (!data) return [];
    return data.data.map((item) => ({ label: item.prefectureName, value: item.prefectureCode.toString() }));
  }, [data]);

  const getMunicipalityOptionsByProvince = useCallback(
    (provinceCode: string) => {
      if (!data) return [];
      const provinceByWatch = data.data.find((item) => item.prefectureCode === toNumber(provinceCode));
      if (!provinceByWatch) return [];
      return provinceByWatch.municipalities.map((item) => ({ label: item.municipalityName, value: item.municipalityCode.toString() }));
    },
    [data],
  );

  const municipalities = useMemo(() => data?.data.flatMap((item) => item.municipalities), [data]);

  return { isLoading, provinceOptions, municipalities, getMunicipalityOptionsByProvince };
};
