import { useGetPayrollFilesApi } from "@/api/services/main/regular-document/acceptance-activity-document";
import { TGetAcceptanceActivityFileListDto } from "@/api/services/main/regular-document/acceptance-activity-document/dtos/get-acceptance-activity-file-list.dto";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { Table } from "@/components/elements/Table";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { DeletePayrollFileModal } from "@/features/regular-document/components/accept-activity/payroll-files/DeletePayrollFileModal";
import { useTable } from "@/hooks/useTable";
import { useVisible } from "@/hooks/useVisible";
import { dayjs } from "@/utils/dayjs";
import { Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo, useState } from "react";

type Props = {
  documentStepId: number;
  readonly?: boolean;
};

export const PayrollFilesTable: FC<Props> = ({ documentStepId, readonly }) => {
  const { data: payrollFilesResult, isLoading } = useGetPayrollFilesApi(documentStepId);
  const payrollFilesData = payrollFilesResult?.data;

  const [deletePayrollFileId, setDeletePayrollFileId] = useState<number>();

  const deletePayrollFileModal = useVisible();

  const columns = useMemo<MRT_ColumnDef<TGetAcceptanceActivityFileListDto>[]>(
    () =>
      (
        [
          {
            header: "ファイル名",
            Cell: ({ row }) => {
              return <FileItem filePath={row.original.filePath} downloadable hideIcon width={350} />;
            },
            muiTableBodyCellProps: {
              sx: { width: "49%" },
            },
          },
          {
            header: "アップロード日時",
            Cell: ({ row }) => <Typography variant="body14">{dayjs(row.original.createdAt).format(DATE_TIME_FORMAT)}</Typography>,
            muiTableBodyCellProps: {
              sx: { width: "49%" },
            },
          },
          {
            header: "削除",
            id: "action",
            Cell: ({ row }) => (
              <Button
                size="sm"
                onClick={() => {
                  deletePayrollFileModal.open();
                  setDeletePayrollFileId(row.original.id);
                }}
              >
                削除
              </Button>
            ),
            size: 50,
            muiTableBodyCellProps: {
              sx: { width: "2%" },
            },
          },
        ] as MRT_ColumnDef<TGetAcceptanceActivityFileListDto>[]
      ).filter((item) => {
        if (readonly && item.id === "action") return false;
        return true;
      }),
    [readonly],
  );

  const { table } = useTable({ columns, rows: payrollFilesData ?? [] });

  return (
    <>
      <Table table={table} isLoading={isLoading} />
      <DeletePayrollFileModal
        deletePayrollFileId={deletePayrollFileId}
        isOpen={deletePayrollFileModal.visible}
        onClose={() => {
          deletePayrollFileModal.close();
          setDeletePayrollFileId(undefined);
        }}
      />
    </>
  );
};
