import { useMe } from "@/api/services/main/auth";
import {
  createConversationApi,
  createMessageApi,
  setReadMessageApi,
  useConversation,
  useMessageListByConversation,
} from "@/api/services/main/conversation";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CHANNELS, EVENTS } from "@/constants/socket";
import { useSocket } from "@/features/chat/providers/SocketProvider";
import { TMessage } from "@/features/chat/types/message";
import { formatConversationDto } from "@/features/chat/utils/room";
import { isFireEvent } from "@/utils/pieces";
import { replaceStr } from "@/utils/string";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { MessageType } from "react-chat-elements";

export const useRoom = () => {
  const { socket } = useSocket();
  const { data: meResult } = useMe();
  const { data: conversationResult } = useConversation();
  const roomData = conversationResult?.data ? formatConversationDto(conversationResult?.data) : null;
  const queryClient = useQueryClient();
  const cachedDates = useRef<string[]>([]);

  const {
    dataFormatter: messages,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useMessageListByConversation({ conversationId: roomData?.id?.toString() ?? "", enabled: roomData?.id ? true : false });

  const [oldMessages, setOldMessages] = useState<MessageType[]>([]);
  const [newMessages, setNewMessages] = useState<MessageType[]>([]);

  const systemMessageDateTime = (msgObj: TMessage) => {
    const messageDate = dayjs(msgObj.createdAt).format(DATE_FORMAT);
    if (!cachedDates.current.includes(messageDate)) {
      cachedDates.current.push(messageDate);
      const msg = { type: "system" } as MessageType;
      msg.text = messageDate;
      return msg;
    }
  };

  useEffect(() => {
    if (messages) {
      cachedDates.current = [];
      setOldMessages(
        messages.toReversed().flatMap((message) => {
          const newDate = systemMessageDateTime(message);
          if (newDate) return [newDate, formatMsgObj(message)];
          return formatMsgObj(message);
        }),
      );
    }
  }, [messages]);

  useEffect(() => {
    if (socket && roomData?.id) {
      socket.emit(EVENTS.CONVERSATION.JOIN_ROOM, replaceStr(CHANNELS.CONVERSATION.ROOM, { tenantId: 1, conversationId: roomData.id }));
      socket.on(EVENTS.CONVERSATION.READ_MESSAGE, (data) => {
        if (meResult?.data.id && data.readUserId != meResult.data.id) {
          setOldMessages((prevMessages) => prevMessages.map((msg) => (msg.position === "right" ? { ...msg, status: "received" } : msg)));

          setNewMessages((prevMessages) => prevMessages.map((msg) => (msg.position === "right" ? { ...msg, status: "received" } : msg)));
        }
      });
      return () => {
        socket.emit(EVENTS.CONVERSATION.LEAVE, replaceStr(CHANNELS.CONVERSATION.ROOM, { tenantId: 1, conversationId: roomData.id }));
      };
    }
  }, [socket, roomData]);

  useEffect(() => {
    let isSetCurrentDate = false;
    const callback = (e: Event) => {
      if (isFireEvent<TMessage>(e)) {
        const { detail: receivedMessage } = e;
        if (roomData && receivedMessage.conversationId?.toString() === roomData.id?.toString()) {
          let newMessage: MessageType[] = [];
          const newDate = systemMessageDateTime(receivedMessage);
          if (newDate && !isSetCurrentDate) {
            newMessage = [newDate, formatMsgObj(receivedMessage)];
            isSetCurrentDate = true;
          } else {
            newMessage = [formatMsgObj(receivedMessage)];
          }
          setNewMessages((cur) => [...cur, ...newMessage]);
        }
      }
    };
    window.addEventListener(EVENTS.CONVERSATION.NEW_MESSAGE, callback);
    return () => {
      window.removeEventListener(EVENTS.CONVERSATION.NEW_MESSAGE, callback);
    };
  }, [meResult, roomData]);

  const formatMsgObj = useCallback(
    (msgObj: TMessage) => {
      const isMyMsg = meResult?.data.id == msgObj.creator?.id;

      const msg = { type: "text" } as MessageType;
      msg.avatar = "";
      msg.title = isMyMsg ? "" : msgObj.creator?.fullName;
      msg.text = msgObj.text;
      msg.position = isMyMsg ? "right" : "left";
      msg.statusTitle = dayjs(msgObj.createdAt).format("HH:mm");
      msg.id = msgObj.id;

      if (isMyMsg) {
        if (meResult?.data.id && msgObj.readUserIds && msgObj.readUserIds.length > 0 && !msgObj.readUserIds.includes(meResult?.data.id)) {
          msg.status = "received";
        } else {
          msg.status = "sent";
        }
      } else {
        if (meResult?.data.id && msgObj.readUserIds && msgObj.readUserIds.length > 0 && msgObj.readUserIds.includes(meResult?.data.id)) {
          msg.status = "received";
        } else {
          msg.status = "sent";
        }
      }

      msg.id = msgObj.id?.toString();
      return msg;
    },
    [meResult?.data.id],
  );

  const sendMsg = async (msg: string) => {
    if (!roomData) {
      await createConversationApi({
        message: {
          text: msg,
        },
      });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONVERSATION.FETCH_DETAIL] });
      return;
    }
    await createMessageApi(roomData.id?.toString(), { text: msg });
  };

  const readMsg = async () => {
    if (roomData) {
      await setReadMessageApi({ conversationId: roomData.id?.toString(), messageId: oldMessages[oldMessages.length - 1].id?.toString() });
    }
  };

  return { oldMessages, newMessages, roomData, hasNextPage, isFetching, fetchNextPage, sendMsg, readMsg };
};
