import { TypoLink } from "@/components/elements/TypoLink";
import { Modal } from "@/components/elements/Modal";
import { AppRoutes } from "@/routes/config";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TRequireEditCompanyInfoModalProps = {
  open: boolean;
  onClose: () => void;
};

export const RequireEditCompanyInfoModal: FC<TRequireEditCompanyInfoModalProps> = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose} enableFooter={false} width={600}>
      <Stack mb={2} gap={1}>
        <Typography>ステップバイステップを完了する必要があるので、このリンクを押して完了お願いいたします</Typography>
        <TypoLink to={AppRoutes.companyEdit} onClick={onClose}>
          会社情報の編集へ
        </TypoLink>
      </Stack>
    </Modal>
  );
};
