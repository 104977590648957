import { TCreateCompanyBranchReqDto, TCreateCompanyBranchResDto } from "@/api/services/main/company/dtos/create-company-branch.dto";
import {
  TCreateGovernmentOnlineSystemAccountReqDto,
  TCreateGovernmentOnlineSystemAccountResDto,
} from "@/api/services/main/company/dtos/create-government-online-system-account.dto";
import { TGetCompanyBranchListReqDto, TGetCompanyBranchListResDto } from "@/api/services/main/company/dtos/get-company-branch-list.dto";
import { TGetCompanyBranchResDto } from "@/api/services/main/company/dtos/get-company-branch.dto";
import { TGetCompanyResDto } from "@/api/services/main/company/dtos/get-company.dto";
import { TGetGovernmentOnlineSystemAccountResDto } from "@/api/services/main/company/dtos/get-government-online-system-account.dto";
import { TUpdateCompanyBranchReqDto, TUpdateCompanyBranchResDto } from "@/api/services/main/company/dtos/update-company-branch.dto";
import { TUpdateCompanyReqDto, TUpdateCompanyResDto } from "@/api/services/main/company/dtos/update-company.dto";
import {
  TUpdateGovernmentOnlineSystemAccountReqDto,
  TUpdateGovernmentOnlineSystemAccountResDto,
} from "@/api/services/main/company/dtos/update-government-online-system-account.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiRes } from "@/types/api";
import { TOption } from "@/types/common";
import { clientApi } from "@/utils/api";
import { getFullAddress } from "@/utils/string";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const fetchCompanyInfoApi = async (): Promise<TGetCompanyResDto> => {
  const rs = await clientApi.get<TGetCompanyResDto>("/auth/company");
  const sortedSupporters = rs.data.data.supporters.sort((a, b) => a.id - b.id);

  return {
    ...rs.data,
    data: {
      ...rs.data.data,
      supporters: sortedSupporters,
    },
  };
};

export const useCompanyInfo = (enabled = true) => {
  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL],
    queryFn: async () => {
      const rs = await fetchCompanyInfoApi();
      const { data: company } = rs;
      company.fullAddress = getFullAddress({ municipality: company.municipality, address: company.address });
      return rs;
    },
    enabled: enabled,
  });
};

export const updateCompanyInfoApi = async (payload: TUpdateCompanyReqDto): Promise<TUpdateCompanyResDto> => {
  const rs = await clientApi.put<TUpdateCompanyResDto>("/auth/company", payload);
  return rs.data;
};

export const fetchCompanyBranchListApi = async (req: TGetCompanyBranchListReqDto): Promise<TGetCompanyBranchListResDto> => {
  const url = createUrlWithParams("/auth/company/branches", req);
  const rs = await clientApi.get<TGetCompanyBranchListResDto>(url);

  return rs.data;
};

export const useCompanyBranchList = (req?: TGetCompanyBranchListReqDto) => {
  const pagReq = usePagination();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_BRANCH_LIST, req ?? pagReq],
    queryFn: () => fetchCompanyBranchListApi(req ?? pagReq),
  });

  const contractCompanyBranchOptions: TOption[] = useMemo(
    () => (data ? data?.data.map((item) => ({ label: `${item.branchName} ${item.isHeadquarters ? "（本店）" : ""}`, value: item.id })) : []),
    [data],
  );

  return { data, contractCompanyBranchOptions, ...rest };
};

export const createCompanyBranchApi = async (payload: TCreateCompanyBranchReqDto): Promise<TCreateCompanyBranchResDto> => {
  const rs = await clientApi.post<TCreateCompanyBranchResDto>("/auth/company/branches", payload);
  return rs.data;
};

export const fetchCompanyBranchInfoApi = async (id: string | number): Promise<TGetCompanyBranchResDto> => {
  const rs = await clientApi.get<TGetCompanyBranchResDto>(`/auth/company/branches/${id}`);
  return rs.data;
};

export const useCompanyBranchInfo = (branchId?: number) => {
  const { id } = useParams();

  const fetchId = branchId ?? id;
  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_BRANCH_DETAIL, fetchId],
    queryFn: () => (fetchId ? fetchCompanyBranchInfoApi(fetchId) : undefined),
    enabled: Boolean(fetchId),
  });
};

export const updateCompanyBranchInfoApi = async (id: string, payload: TUpdateCompanyBranchReqDto): Promise<TUpdateCompanyBranchResDto> => {
  const rs = await clientApi.put<TUpdateCompanyBranchResDto>(`/auth/company/branches/${id}`, payload);
  return rs.data;
};

export const deleteCompanyBranchApi = async (id: string): Promise<TApiRes<boolean>> => {
  try {
    const { data } = await clientApi.delete<TApiRes<boolean>>(`/auth/company/branches/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createGovernmentOnlineSystemAccountApi = async (
  payload: TCreateGovernmentOnlineSystemAccountReqDto,
): Promise<TCreateGovernmentOnlineSystemAccountResDto> => {
  const rs = await clientApi.post<TCreateGovernmentOnlineSystemAccountResDto>("/auth/company/account", payload);
  return rs.data;
};

export const fetchGovernmentOnlineSystemAccountInfoApi = async (): Promise<TGetGovernmentOnlineSystemAccountResDto> => {
  const rs = await clientApi.get<TGetGovernmentOnlineSystemAccountResDto>("/auth/company/account");
  return rs.data;
};

export const useGovernmentOnlineSystemAccountInfo = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_ACCOUNT_DETAIL],
    queryFn: () => fetchGovernmentOnlineSystemAccountInfoApi(),
  });
};

export const updateGovernmentOnlineSystemAccountApi = async (
  payload: TUpdateGovernmentOnlineSystemAccountReqDto,
): Promise<TUpdateGovernmentOnlineSystemAccountResDto> => {
  if (!payload.accordImmiPassword) {
    payload.accordImmiPassword = undefined;
  }
  const rs = await clientApi.put<TUpdateGovernmentOnlineSystemAccountResDto>("/auth/company/account", payload);
  return rs.data;
};
