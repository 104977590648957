import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from "@/assets/icons";
import { divider, text } from "@/theme/colors";
import { elevation } from "@/theme/variables";
import { toNumber } from "@/utils/number";
import { Pagination as MuiPagination, PaginationItem, Stack, Typography, styled } from "@mui/material";
import { FC } from "react";

type Props = {
  totalPage: number;
  page: number;
  onChangePage: (page: number) => void;
};

export const ControlledPagination: FC<Props> = ({ totalPage, page, onChangePage }) => {
  return (
    <Stack direction="row" alignItems="center" gap={2.5}>
      <Stack direction="row" gap={2}>
        <Typography variant="sub16Semi" color={text.primary}>
          <b>
            {page}/{totalPage > 0 ? totalPage : 1}
          </b>
          ページ目
        </Typography>
      </Stack>
      <StyledPagination
        page={page}
        count={totalPage}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        renderItem={(item) => {
          if (["page", "end-ellipsis", "start-ellipsis"].includes(item.type)) return null;

          return (
            <PaginationItem
              {...item}
              onClick={() => {
                onChangePage(toNumber(item.page));
              }}
              slots={{ first: IconChevronsLeft, previous: IconChevronLeft, next: IconChevronRight, last: IconChevronsRight }}
            />
          );
        }}
      />
    </Stack>
  );
};

const StyledPagination = styled(MuiPagination)`
  .MuiPaginationItem-root {
    border-radius: 6px;
    margin: 0 4px;
    box-shadow: ${elevation.sm};
    border-color: ${divider.middle};
  }
  a {
    color: ${text.primary};
  }
`;
