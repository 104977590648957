import { useGetLivingRelationshipList, useGetRegionList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { Table } from "@/components/elements/Table";
import { TEmployeeApplicationForm } from "@/features/renew-visa/types/employeeApplicationForm";
import { useFormTable } from "@/hooks/useFormTable";
import { divider } from "@/theme/colors";
import { dayjs } from "@/utils/dayjs";
import { Box, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useEffect } from "react";
import { useFormContext, useFormState } from "react-hook-form";

type TEmployeeFamilyMemberFormTableProps = {
  readonly: boolean;
};

export const EmployeeFamilyMemberFormTable: FC<TEmployeeFamilyMemberFormTableProps> = ({ readonly }) => {
  const methods = useFormContext<TEmployeeApplicationForm>();
  const { control } = methods;

  const { isLoading: isLoadingRegion, nationalityOptions } = useGetRegionList();

  const { livingRelationshipOptions, isLoading: isLoadingLivingRelationship } = useGetLivingRelationshipList();

  const columns: MRT_ColumnDef<TEmployeeApplicationForm>[] = [
    {
      header: "続柄",
      Cell: ({ row }) => {
        return (
          <FormField id="employeeRelationshipId" control={control} name={`employeeFamilyMembers.${row.index}.employeeRelationshipId`}>
            <Select options={livingRelationshipOptions} loading={isLoadingLivingRelationship} readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "氏名",
      Cell: ({ row }) => {
        return (
          <FormField id="fullName" control={control} name={`employeeFamilyMembers.${row.index}.fullName`}>
            <Input transformToFullWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "生年月日",
      Cell: ({ row }) => {
        return (
          <FormField id="birthday" control={control} name={`employeeFamilyMembers.${row.index}.birthday`}>
            <InputDatePicker minDate={dayjs("1870/01/01")} maxDate={dayjs()} readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "国籍",
      Cell: ({ row }) => {
        return (
          <FormField id="nationalityId" control={control} name={`employeeFamilyMembers.${row.index}.nationalityId`}>
            <Select options={nationalityOptions} loading={isLoadingRegion} readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "同居の有無",
      Cell: ({ row }) => {
        return (
          <FormField id="livingWithEmployee" control={control} name={`employeeFamilyMembers.${row.index}.livingWithEmployee`}>
            <Select enumName="EYesNoAnswer" readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "勤務先・通学先名称",
      Cell: ({ row }) => {
        return (
          <FormField id="workingLocation" control={control} name={`employeeFamilyMembers.${row.index}.workingLocation`}>
            <Input transformToFullWidth readonly={readonly} />
          </FormField>
        );
      },
    },
    {
      header: "在留カード番号／特別永住者証明書番号",
      Cell: ({ row }) => {
        return (
          <FormField id="residenceCode" control={control} name={`employeeFamilyMembers.${row.index}.residenceCode`}>
            <Input transformToHalfWidth readonly={readonly} />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({
    columns,
    control,
    name: "employeeFamilyMembers",
    readonly,
  });

  const { errors } = useFormState({ control });

  useEffect(() => {
    const tableElement = document.getElementById("family-members-form-table-id")?.getElementsByClassName("MuiTableContainer-root")[0];
    if (tableElement) {
      const firstErrorField = errors && errors.employeeFamilyMembers ? errors.employeeFamilyMembers[0] : null;
      if (firstErrorField) {
        const firstErrorFieldName = Object.keys(firstErrorField)[0];
        const errorFieldElement = document.getElementById(`${firstErrorFieldName}`) as HTMLInputElement;
        const employeeRelationshipElemement = document.getElementById("employeeRelationshipId") as HTMLInputElement;
        if (errorFieldElement && employeeRelationshipElemement) {
          tableElement.scrollTo({
            behavior: "smooth",
            left: errorFieldElement.getBoundingClientRect().left - employeeRelationshipElemement.getBoundingClientRect().left,
          });
        }
      }
    }
  }, [errors]);

  if (table.getRowCount() >= 6) {
    table.addNewRow = undefined;
  }

  return (
    <Box border={`1px solid ${divider.middle}`} borderRadius="8px" p="24px">
      <Stack direction="row">
        <Typography variant="sub16Semi" component="h2" mb="8px">
          在日親族及び同居者
        </Typography>
        <Input InputProps={{ sx: { opacity: 0, height: 0, width: 0 } }} name="employeeFamilyMembers" />
      </Stack>
      <Table id="family-members-form-table-id" table={table} />
    </Box>
  );
};
