import { TEmployeeRenewVisaDocument } from "@/api/entities/employee-renew-visa-document.entity";
import { useCompanyInfo } from "@/api/services/main/company";
import { useGetRenewVisaDocumentList } from "@/api/services/main/renew-visa";
import { Avatar } from "@/components/elements/Avatar";
import { StatusTag } from "@/components/elements/StatusTag";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { TaskCard } from "@/features/dashboard/components/tasks/TaskCard";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS_LABEL } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { getFullName } from "@/utils/string";
import { getDynamicRoute } from "@/utils/url";
import { Box, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const RenewVisaTaskCard = () => {
  const { data: employeeRenewVisaDocumentList, isLoading } = useGetRenewVisaDocumentList({
    page: 1,
    perPage: 4,
    sorts: ["renewVisaDocument.residenceExpiryDate|ASC"],
  });

  const { data: companyData } = useCompanyInfo();

  const supporterName = useMemo(() => {
    if (!companyData) return null;
    return companyData?.data?.supporters.length > 0 ? companyData?.data?.supporters?.[0].supporterName : null;
  }, [companyData]);

  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<TEmployeeRenewVisaDocument>[]>(
    () => [
      {
        header: "更新期限",
        Cell: ({ row }) => (row.original.residenceExpiryDate ? dayjs(row.original.residenceExpiryDate).format(DATE_FORMAT) : EMPTY_STR.TEXT),
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "ステータス",
        Cell: ({ row }) => {
          const { status } = row.original;
          if (!status) return EMPTY_STR.TEXT;
          return <StatusTag status={status} variant="bold" title={DOCUMENT_STATUS_LABEL[status]} />;
        },
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "外国人",
        Cell: ({ row }) => {
          const fullName = getFullName({
            firstName: row.original.employee.firstName,
            lastName: row.original.employee.lastName,
            middleName: row.original.employee.middleName,
          });
          return (
            <Box display="flex" alignItems="center" gap={1.75}>
              <Avatar filePath={row.original.employee.avatarFilePath} alt="avatar" width={24} height={24} />
              <Typography variant="body14"> {fullName}</Typography>
            </Box>
          );
        },
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
      {
        header: "担当者",
        Cell: ({}) => {
          return supporterName ?? EMPTY_STR.TEXT;
        },
        size: 100,
        muiTableBodyCellProps: {
          sx: { minWidth: "unset" },
        },
        muiTableHeadCellProps: {
          sx: { minWidth: "unset" },
        },
      },
    ],
    [],
  );
  const { table } = useTable({
    rows: employeeRenewVisaDocumentList?.data ?? [],
    columns,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const dynamicRoute = getDynamicRoute(AppRoutes.employeeEdit, { id: row.original.employeeId });
        navigate(dynamicRoute);
      },
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <TaskCard title="在留期間更新" seeMoreHref={AppRoutes.renewVisa}>
      <Table table={table} isLoading={isLoading} />
    </TaskCard>
  );
};
