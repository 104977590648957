import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "@/components/elements/Select";
import { Textarea } from "@/components/elements/Textarea";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { FormField } from "@/components/elements/FormField";
import { TEnumName, useEnum } from "@/api/services/global/enum";
import { findLabelByValue } from "@/utils/object";
import { useDialog } from "@/hooks/useDialog";
import { ConfirmReplaceContent } from "@/features/retirement/components/retirement-detail/accepting-worker/ConfirmReplaceContent";

type TInputCardProps = {
  title: string;
  selectName: keyof TRetirementAcceptingWorkerForm;
  inputName: keyof TRetirementAcceptingWorkerForm;
  enumSelectName: TEnumName;
  enumInputName: TEnumName;
  readonly?: boolean;
};

export const InputCard: FC<TInputCardProps> = ({ title, selectName, inputName, enumSelectName, enumInputName, readonly }) => {
  const { control, setValue, getValues } = useFormContext<TRetirementAcceptingWorkerForm>();
  const { data } = useEnum(enumInputName);
  const confirmReplace = useDialog();

  const handleChangeTemplate = () => {
    const currentSelectValue = getValues(selectName);
    const template = findLabelByValue(data, currentSelectValue);
    setValue(inputName, template);
    confirmReplace.cancel();
  };

  const handleConfirmFillData = (value: string) => {
    const currentInputValue = getValues(inputName);
    const isTemplateUnchanged = data.some((item) => item.label === currentInputValue);
    if (currentInputValue && !isTemplateUnchanged) {
      confirmReplace.show();
    } else {
      setValue(selectName, value);
      handleChangeTemplate();
    }
  };

  return (
    <Stack gap={1}>
      <Typography variant="sub16Semi" fontWeight={400}>
        {title}
      </Typography>
      <Stack>
        <FormField control={control} name={selectName}>
          <Select enumName={enumSelectName} size="md" readonly={readonly} onChange={(value) => handleConfirmFillData(value as string)} />
        </FormField>
        <Stack borderTop={readonly ? "2px solid #D9D9D9" : "none"}>
          <FormField control={control} name={inputName}>
            <Textarea readonly={readonly} />
          </FormField>
        </Stack>
      </Stack>
      <ConfirmReplaceContent
        title="リセットの確認"
        content="テンプレートの内容がリセットされますが、よろしいですか。"
        open={confirmReplace.open}
        onClose={confirmReplace.cancel}
        onOk={handleChangeTemplate}
      />
    </Stack>
  );
};
