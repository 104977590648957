import * as React from "react";

export const IconErrorOutline = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M7.33331 10.5007H8.66665V11.834H7.33331V10.5007ZM7.33331 5.16732H8.66665V9.16732H7.33331V5.16732ZM7.99331 1.83398C4.31331 1.83398 1.33331 4.82065 1.33331 8.50065C1.33331 12.1807 4.31331 15.1673 7.99331 15.1673C11.68 15.1673 14.6666 12.1807 14.6666 8.50065C14.6666 4.82065 11.68 1.83398 7.99331 1.83398ZM7.99998 13.834C5.05331 13.834 2.66665 11.4473 2.66665 8.50065C2.66665 5.55398 5.05331 3.16732 7.99998 3.16732C10.9466 3.16732 13.3333 5.55398 13.3333 8.50065C13.3333 11.4473 10.9466 13.834 7.99998 13.834Z"
      fill="currentColor"
    />
  </svg>
);
