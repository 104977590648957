import { useAnytimeSupportPlan } from "@/api/services/main/any-time/support-plan";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { AnytimeSupportPlanDetailPage } from "@/features/anytime/pages/documents/AnytimeSupportPlanDetail";
import { convertResDtoToFormData } from "@/features/anytime/utils/support-plan";

const AnytimeSupportPlanDetail = () => {
  const { data: supportPlanResult, isLoading } = useAnytimeSupportPlan();
  const supportPlanData = supportPlanResult?.data;

  if (isLoading || !supportPlanData) return <LoadingPage />;

  return <AnytimeSupportPlanDetailPage defaultValues={convertResDtoToFormData(supportPlanData)} />;
};

export default AnytimeSupportPlanDetail;
