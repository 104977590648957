import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { TGetRetirementEmploymentContractDocumentDto } from "@/api/services/main/retirement-document/employment-contract-document/dtos/get-retirement-employment-contract-document.dto";
import { TUpdateRetirementEmploymentContractDocumentReqDto } from "@/api/services/main/retirement-document/employment-contract-document/dtos/update-retirement-employment-contract-document.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { TRetirementEmploymentContractForm } from "@/features/retirement/types/retirementEmploymentContractForm";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";

const intiForm = {
  terminateContractDate: null,
  terminateContractReasonByCompany: null,
  terminateContractOtherByCompany: null,
  terminateContractReasonByEmployee: null,
  terminateContractOtherReasonByEmployee: null,
};

export const getInitValues = (data: {
  employmentContractData?: TGetRetirementEmploymentContractDocumentDto;
  companyData?: TGetCompanyDto;
}): Partial<TRetirementEmploymentContractForm> | undefined => {
  const { employmentContractData, companyData } = data;
  if (!companyData) return undefined;
  return {
    ...intiForm,
    ...employmentContractData,
    terminateContractDate: employmentContractData?.terminateContractDate ? dayjs(employmentContractData?.terminateContractDate) : undefined,
    supporterName: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].supporterName : null,
    supporterTelNumber: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].telNumber : null,
    supporterId: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].id : null,
    companyName: companyData.companyName ?? null,
    companyFuriganaName: companyData.companyFuriganaName ?? null,
    companySpecificIndustryId: companyData.specificIndustryId ?? null,
    companyIdentificationNumber: companyData.companyIdentificationNumber ?? null,
    companyZipCode: companyData.zipCode ?? null,
    companyProvince: companyData.province ?? null,
    companyAddress: companyData.fullAddress ?? null,
    companyTelNumber: companyData.telNumber ?? null,
  };
};

export const convertFormDataToReqDto = (
  data: TRetirementEmploymentContractForm,
  isDraft: boolean,
): TUpdateRetirementEmploymentContractDocumentReqDto => {
  return {
    ...intiForm,
    terminateContractDate: data?.terminateContractDate ? (data?.terminateContractDate).format(ISO_FORMAT) : null,
    terminateContractReason: data.terminateContractReason ?? null,
    terminateContractReasonByCompany: data.terminateContractReasonByCompany ?? null,
    terminateContractOtherByCompany: data.terminateContractOtherByCompany ?? null,
    terminateContractReasonByEmployee: data.terminateContractReasonByEmployee ?? null,
    terminateContractOtherReasonByEmployee: data.terminateContractOtherReasonByEmployee ?? null,
    supporterId: data.supporterId ? toNumber(data.supporterId) : null,
    supporterName: data.supporterName ?? null,
    supporterTelNumber: data.supporterTelNumber ?? null,
    companyName: data.companyName ?? null,
    companyFuriganaName: data.companyFuriganaName ?? null,
    companySpecificIndustryId: data.companySpecificIndustryId ? toNumber(data.companySpecificIndustryId) : null,
    companyIdentificationNumber: data.companyIdentificationNumber ?? null,
    companyZipCode: data.companyZipCode ?? null,
    companyProvince: data.companyProvince ?? null,
    companyAddress: data.companyAddress ?? null,
    companyTelNumber: data.companyTelNumber ?? null,
    employeeFullName: data.employeeFullName,
    employeeNickName: data.employeeNickName ?? null,
    employeeGender: data.employeeGender ?? null,
    employeeNationalityId: data.employeeNationalityId ? Number(data.employeeNationalityId) : null,
    employeeResidenceCardNumber: data.employeeResidenceCardNumber ?? null,
    specificBusinessClassificationName: data.specificBusinessClassificationName ?? null,
    specificIndustryName: data.specificIndustryName ?? null,
    birthday: data.birthday ?? null,
    zipCode: data.zipCode ?? null,
    fullAddress: data.fullAddress ?? null,
    isDraft,
  };
};

export const convertResDtoToFormData = (data: TGetRetirementEmploymentContractDocumentDto): TRetirementEmploymentContractForm => {
  return {
    ...data,
    terminateContractDate: data.terminateContractDate ? dayjs(data.terminateContractDate) : null,
  };
};
