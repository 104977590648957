import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { ETerminateContractSubcategoryReason } from "@/features/anytime/constants/enums";
import { TSupportContractForm } from "@/features/anytime/types/supportContractForm";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TConclusionForm = {
  readonly?: boolean;
};

export const ConclusionForm: FC<TConclusionForm> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TSupportContractForm>();

  const terminateContractSubcategoryReasonWatchField = useWatch({
    control,
    name: "terminateContractSubcategoryReason",
  });

  const terminateContractOtherReasonWatchField = useWatch({
    control,
    name: "terminateContractOtherReason",
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormField control={control} name="terminateContractDate">
          <InputDatePicker readonly={readonly} />
        </FormField>
      </Grid>
      <Grid item xs={8} />
      <Grid item xs={12}>
        <FormField control={control} name="terminateContractMajorCategoryReason">
          <Select enumName="ETerminateContractMajorCategoryReason" readonly={readonly} />
        </FormField>
      </Grid>
      <Grid item xs={12}>
        <FormField control={control} name="terminateContractSubcategoryReason">
          <Select
            enumName="ETerminateContractSubcategoryReason"
            readonly={readonly}
            onChange={(value) => {
              if (value !== ETerminateContractSubcategoryReason.OTHER && terminateContractOtherReasonWatchField) {
                setValue("terminateContractOtherReason", "");
              }
            }}
          />
        </FormField>
      </Grid>
      <Grid item xs={12}>
        <FormField control={control} name="terminateContractOtherReason">
          <Input readonly={readonly} disabled={terminateContractSubcategoryReasonWatchField !== ETerminateContractSubcategoryReason.OTHER} />
          <Typography variant="cap12">100文字以下</Typography>
        </FormField>
      </Grid>
    </Grid>
  );
};
