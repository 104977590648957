import { TopPage } from "@/components/elements/TopPage";
import { getDynamicRoute } from "@/utils/url";
import { AppRoutes } from "@/routes/config";
import { Stack, styled } from "@mui/material";
import { FC, useMemo } from "react";
import { Link, Outlet, matchRoutes, useLocation, useParams } from "react-router-dom";
import { TCompanyMenu } from "@/features/company/types/companyMenu";
import { companyMenu } from "@/features/company/constants/companyMenu";

type TCompanyLayoutProps = {};

export const CompanyLayout: FC<TCompanyLayoutProps> = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const renderRoutes = (menu: TCompanyMenu[]) => {
    return menu.map(({ title, url, extraUrls }) => {
      const isActive =
        matchRoutes([{ path: url }], pathname) !== null || extraUrls?.some((extraUrl) => matchRoutes([{ path: extraUrl }], pathname) !== null);

      return (
        <StyledLink isActive={isActive} key={url} className={isActive ? "active" : ""} to={getDynamicRoute(url, { id })}>
          {title}
        </StyledLink>
      );
    });
  };

  const isExtraUrlActive = useMemo(() => {
    return companyMenu.some(({ extraUrls }) => extraUrls?.some((extraUrl) => matchRoutes([{ path: extraUrl }], pathname) !== null));
  }, [pathname]);

  const backUrl = useMemo(() => {
    if (isExtraUrlActive) {
      const activeMenu = companyMenu.find(({ extraUrls }) => extraUrls?.some((extraUrl) => matchRoutes([{ path: extraUrl }], pathname) !== null));
      return activeMenu ? getDynamicRoute(activeMenu.url, { id }) : AppRoutes.company;
    }
    return AppRoutes.company;
  }, [isExtraUrlActive, pathname, id]);

  return (
    <Stack gap={2.5}>
      {isExtraUrlActive ? <TopPage title="会社情報" backUrl={backUrl} gap={0} /> : <TopPage title="会社情報" backUrl={AppRoutes.home} />}
      <Stack direction="row" gap={10}>
        {renderRoutes(companyMenu)}
      </Stack>
      <Outlet />
    </Stack>
  );
};

const options = { shouldForwardProp: (propName: string) => !["isActive"].includes(propName) };

const StyledLink = styled(
  Link,
  options,
)<{ isActive: boolean }>(({ isActive }) => ({
  textDecoration: isActive ? "underline" : "none",
}));
