import { useMe } from "@/api/services/main/auth";
import { useContactTypeList } from "@/api/services/main/contact-type";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { ContactForm } from "@/features/contact/components/ContactForm";
import { Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export const ContactPage = () => {
  const { data: meResult } = useMe();
  const { data: contactTypeListResult } = useContactTypeList();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const selectedContactType = contactTypeListResult?.data.find((contactType) => String(contactType.id) === type);

  const previousValues = {
    companyId: meResult?.data.company.id,
    createdBy: meResult?.data.id,
    email: meResult?.data.email,
    contactTypeId: type ? type : undefined,
    content: selectedContactType?.template ? selectedContactType.template : undefined,
  };

  if (!meResult) return <LoadingPage />;
  return (
    <Stack maxWidth={COMPANY_CONTAINER} gap={3}>
      <TopPage
        title="いろはなへのお問い合わせ"
        description="通訳の予約や、いろはなへの業務サポートを依頼する際、以下のフォームより必要事項をご記入後、送信してください。"
        enablePageBack={false}
      />
      <Stack gap={2}>
        <Typography variant="head20Semi" fontWeight={100}>
          お問い合わせフォーム
        </Typography>
        <ContactForm
          key={previousValues.contactTypeId}
          companyName={meResult?.data.company.companyName}
          userName={meResult?.data.fullName}
          previousValues={previousValues}
        />
      </Stack>
    </Stack>
  );
};
