import { fetchEmployeeDetailListApi } from "@/api/services/main/employee";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import {
  characterCodeOptions,
  dataFormatOptions,
  delimiterOptions,
  ECharacterCode,
  ECharacterCodeExport,
  characterCodeExportOptions,
  EDataFormat,
  EDelimiter,
  EFirstRowSpecification,
  firstRowSpecificationOptions,
} from "@/features/employee/constants/employeeExportCsv";
import { useConvertEmployeeToCsvData } from "@/features/employee/hook/useConvertEmployeeToCsvData";
import { useConvertEmployeeBulkApplicationToCsvData } from "@/features/employee/hook/useConvertEmployeeBulkApplicationToCsvData";
import { employeeExportCsvSchema } from "@/features/employee/schema/employeeExportCsvSchema";
import { TEmployeeExportCsvForm } from "@/features/employee/types/employeeExportCsvForm";
import { useVisible } from "@/hooks/useVisible";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { downloadJSONByCSV } from "@/utils/export";
import { toNumber } from "@/utils/number";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useForm } from "react-hook-form";

type TEmployeeExportCsvButtonProps = {
  selectedEmployeeIds: string[];
};

export const EmployeeExportCsvButton: FC<TEmployeeExportCsvButtonProps> = ({ selectedEmployeeIds }) => {
  const exportCsvModal = useVisible();

  const methods = useForm<TEmployeeExportCsvForm>({
    resolver: yupResolver(employeeExportCsvSchema),
    mode: "onBlur",
    defaultValues: {
      dataFormat: EDataFormat.IROHANA_STANDARD,
      firstRowSpecification: EFirstRowSpecification.JAPANESE_NAME,
      characterCode: ECharacterCodeExport.SHIFT_JIS,
      delimiter: EDelimiter.COMMA,
    },
  });

  const { convertEmployeeToCsvData } = useConvertEmployeeToCsvData();
  const { convertEmployeeBulkApplicationFormatToCsvData } = useConvertEmployeeBulkApplicationToCsvData();

  const { control } = methods;

  const onSubmit = async (data: TEmployeeExportCsvForm) => {
    try {
      const rs = await fetchEmployeeDetailListApi({ ids: selectedEmployeeIds.map((id) => toNumber(id)) });
      let csvData;
      let customHeader;
      if (data?.dataFormat === EDataFormat.IROHANA_STANDARD) {
        csvData = convertEmployeeToCsvData(rs.data);
      } else {
        csvData = convertEmployeeBulkApplicationFormatToCsvData(rs.data);
        customHeader = "申請,身分事項1,,,,,,,,,,,,,,,,,身分事項2,,,,,,その他(参考情報)";
        // format: https://docs.google.com/spreadsheets/d/11W2N-Jy20AryKyKNA9XPROO7j4GMTzP5/edit?gid=1476586763#gid=1476586763
      }

      const charset = data.characterCode === ECharacterCode.SHIFT_JIS ? "shift_jis" : "utf-8";
      downloadJSONByCSV(
        csvData,
        `employee-${dayjs().valueOf()}.csv`,
        charset,
        {
          prependHeader: data.firstRowSpecification !== EFirstRowSpecification.NONE,
          delimiter: {
            field: data.delimiter === EDelimiter.COMMA ? "," : data.delimiter === EDelimiter.TAB_CHARACTER ? "\t" : undefined,
            wrap: "",
          },
          trimFieldValues: true,
          excelBOM: data.characterCode === ECharacterCode.UTF8_UNICODE_WITH_BOM,
        },
        customHeader,
      );
      methods.reset();
      exportCsvModal.close();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <Button variant="outline" size="sm" onClick={() => exportCsvModal.open()} disabled={selectedEmployeeIds.length === 0}>
        CSVエクスポート
      </Button>

      <Modal width={760} title="CSVエクスポート" isOpen={exportCsvModal.visible} enableFooter={false} onClose={exportCsvModal.close}>
        <Typography sx={{ my: "16px" }} component="h2" variant="body14">
          特定技能外国人のデータベースをCSVファイルにエクスポートできます。
        </Typography>
        <Box sx={{ border: "1px solid #CACDCD", padding: "24px", borderRadius: "8px" }}>
          <Form methods={methods} onSubmit={onSubmit} schema={employeeExportCsvSchema}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormField control={control} label="出力件数" required={false}>
                  <Input value={selectedEmployeeIds.length} endAdornment="人" clearable={false} readonly />
                </FormField>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <FormField control={control} name="dataFormat">
                  <Select options={dataFormatOptions} />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="firstRowSpecification">
                  <Select options={firstRowSpecificationOptions} />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="characterCode">
                  <Select options={characterCodeExportOptions} />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="delimiter">
                  <Select options={delimiterOptions} />
                </FormField>
              </Grid>
            </Grid>
            <Typography sx={{ mt: "24px", mb: "32px" }} component="p" variant="body14">
              Excelでご利用される場合は、文字コードを「Shift-JIS」、区切り文字を「カンマ」としてください。
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <Button variant="outline" onClick={() => methods.reset()}>
                リセット
              </Button>
              <Button type="submit">変換してダウンロード</Button>
            </Stack>
          </Form>
        </Box>
      </Modal>
    </>
  );
};
