import { TCompanySupporter } from "@/api/entities/company-supporter.entity";
import { TRenewVisaCompanyApplicationDocument } from "@/api/entities/renew-visa-company-application-document.entity";
import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { TGetEmployeeOutputDto } from "@/api/services/main/employee/dtos/get-employee.dto";
import { TCompanyApplicationForm } from "@/features/renew-visa/types/companyApplicationForm";
import { COMPANY_SUPPORTER_ROLE, YES_NO_AND_NOT_APPLICABLE_ANSWER, YES_NO_ANSWER } from "@/types/enum";
import { toNumber } from "@/utils/number";

export const convertResToCompanyApplicationFormData = (
  employee?: TGetEmployeeOutputDto,
  companyData?: TGetCompanyDto,
  companyApplicationData?: TRenewVisaCompanyApplicationDocument,
  companyApplicationLatestCompleteData?: TRenewVisaCompanyApplicationDocument,
): TCompanyApplicationForm | undefined => {
  const supportSupervisor = companyData?.supporters?.find((supporter) => supporter.supporterRole === COMPANY_SUPPORTER_ROLE.SUPPORT_MANAGER);
  const supportPersonnel = companyData?.supporters
    ?.filter((supporter) => supporter.supporterRole === COMPANY_SUPPORTER_ROLE.SUPPORT)
    ?.reduce(
      (minSupporter, currentSupporter) => (!minSupporter || currentSupporter.id < minSupporter.id ? currentSupporter : minSupporter),
      undefined as TCompanySupporter | undefined,
    );
  if (!employee) return undefined;
  const { company, contractCompanyBranch } = employee;
  return {
    employeeId: employee.id.toString(),
    address: companyApplicationData?.address ?? company.address,
    companyName: companyApplicationData?.companyName ?? company.companyName,
    capital: companyApplicationData?.capital ?? company.capital,
    employmentInsuranceNumber: companyApplicationData?.employmentInsuranceNumber ?? contractCompanyBranch?.employmentInsuranceNumber,
    businessInsuranceNumber:
      companyApplicationData?.businessInsuranceNumber ?? contractCompanyBranch?.businessInsuranceNumber ?? company?.businessInsuranceNumber,
    mainIndustryId: companyApplicationData?.mainIndustryId?.toString() ?? company.mainIndustryId?.toString(),
    mainIndustryOther: companyApplicationData?.mainIndustryOther ?? company.mainIndustryOther,
    annualRevenue: companyApplicationData?.annualRevenue
      ? toNumber(companyApplicationData?.annualRevenue) / 10000
      : toNumber(company.annualRevenue) / 10000,
    province: companyApplicationData?.province ?? company.province,
    municipality: companyApplicationData?.municipality ?? company.municipality,
    representativeName: companyApplicationData?.representativeName ?? company.representativeName,
    branchAddress: companyApplicationData?.branchAddress
      ? companyApplicationData?.branchAddress
      : contractCompanyBranch?.address
        ? contractCompanyBranch?.address
        : "",
    branchName: companyApplicationData?.branchName ?? contractCompanyBranch?.branchName,
    branchProvince: companyApplicationData?.branchProvince
      ? companyApplicationData?.branchProvince
      : contractCompanyBranch?.province
        ? contractCompanyBranch?.province
        : "",
    branchMunicipality: companyApplicationData?.branchMunicipality
      ? companyApplicationData?.branchMunicipality
      : contractCompanyBranch?.municipality
        ? contractCompanyBranch?.municipality
        : "",
    branchTelNumber: companyApplicationData?.branchTelNumber ?? contractCompanyBranch?.telNumber,
    companyIdentificationNumber: companyApplicationData?.companyIdentificationNumber ?? company.companyIdentificationNumber,
    employeeCount: companyApplicationData?.employeeCount ?? company.employeeCount,
    telNumber: companyApplicationData?.telNumber ?? company.telNumber,
    affiliationWithOrganizedCrimeDetail: companyApplicationData?.affiliationWithOrganizedCrimeDetail ?? null,
    awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail:
      companyApplicationData?.awarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreementsDetail ?? null,
    companyHasComplianceWithHealthAndWelfarePensionInsurance:
      companyApplicationData?.companyHasComplianceWithHealthAndWelfarePensionInsurance ?? YES_NO_ANSWER.YES,
    complianceWithWorkersCompensationAndEmploymentInsurance:
      companyApplicationData?.complianceWithWorkersCompensationAndEmploymentInsurance ?? YES_NO_ANSWER.YES,
    conditionsApplyForStaffingTargetsDetail: companyApplicationData?.conditionsApplyForStaffingTargetsDetail ?? [],
    relevantIndustryWorkAtDispatchSiteDetail: companyApplicationData?.relevantIndustryWorkAtDispatchSiteDetail ?? null,
    majorityCapitalInvestmentByIndustryRelatedEntityDetail: companyApplicationData?.majorityCapitalInvestmentByIndustryRelatedEntityDetail ?? null,
    substantialInvolvementByIndustryRelatedEntityDetail: companyApplicationData?.substantialInvolvementByIndustryRelatedEntityDetail ?? null,
    agriculturalWorkInDesignatedStrategicZoneDetail: companyApplicationData?.agriculturalWorkInDesignatedStrategicZoneDetail ?? null,
    conditionsApplyForSupportPlanDelegationDetail:
      companyApplicationData?.conditionsApplyForSupportPlanDelegationDetail ??
      companyApplicationLatestCompleteData?.conditionsApplyForSupportPlanDelegationDetail ??
      [],
    conditionsApplyForSupportPlanDelegationOtherDetail:
      companyApplicationData?.conditionsApplyForSupportPlanDelegationOtherDetail ??
      companyApplicationLatestCompleteData?.conditionsApplyForSupportPlanDelegationOtherDetail ??
      "",
    criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail: companyApplicationData?.criminalPenaltiesForLawViolationsByOrgOrPersonnelDetail ?? null,
    formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail:
      companyApplicationData?.formerExecutiveOfOrgWithCanceledTechnicalTrainingCertificationDetail ?? null,
    hostCompanyComplianceWithSectionsDetail: companyApplicationData?.hostCompanyComplianceWithSectionsDetail ?? null,
    involuntaryDismissalOfWorkersInSimilarRolesDetail: companyApplicationData?.involuntaryDismissalOfWorkersInSimilarRolesDetail ?? null,
    legalRepresentativeIsEligibilityDetail: companyApplicationData?.legalRepresentativeIsEligibilityDetail ?? null,
    mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail:
      companyApplicationData?.mentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnelDetail ?? null,
    missingForeignWorkersDueToOrgIsFaultDetail: companyApplicationData?.missingForeignWorkersDueToOrgIsFaultDetail ?? null,
    neglectOfSupportPlanOccurredInPastFiveYearsDetail: companyApplicationData?.neglectOfSupportPlanOccurredInPastFiveYearsDetail ?? null,
    organizedCrimeControlOverBusinessDetail: companyApplicationData?.organizedCrimeControlOverBusinessDetail ?? null,
    penaltyClausesForBreachOfEmploymentContractDetail: companyApplicationData?.penaltyClausesForBreachOfEmploymentContractDetail ?? null,
    supportPersonnel: companyApplicationData?.supportPersonnel
      ? companyApplicationData?.supportPersonnel
      : supportPersonnel
        ? supportPersonnel.supporterName
        : null,
    supportPersonnelRole: companyApplicationData?.supportPersonnelRole
      ? companyApplicationData?.supportPersonnelRole
      : supportPersonnel
        ? supportPersonnel.companyPosition
        : null,
    supportSupervisor: companyApplicationData?.supportSupervisor
      ? companyApplicationData?.supportSupervisor
      : supportSupervisor
        ? supportSupervisor.supporterName
        : null,
    supportSupervisorRole: companyApplicationData?.supportSupervisorRole
      ? companyApplicationData?.supportSupervisorRole
      : supportSupervisor
        ? supportSupervisor.companyPosition
        : null,
    unfairActsRelatedToImmigrationLaborLawsDetail: companyApplicationData?.unfairActsRelatedToImmigrationLaborLawsDetail ?? null,
    unrehabilitatedBankruptcyOfOrgOrPersonnelDetail: companyApplicationData?.unrehabilitatedBankruptcyOfOrgOrPersonnelDetail ?? null,
    whetherCancellationOfTrainingCertification: companyApplicationData?.whetherCancellationOfTrainingCertification ?? YES_NO_ANSWER.NO,
    cancellationOfTrainingCertificationDetail: companyApplicationData?.cancellationOfTrainingCertificationDetail ?? null,
    violationsOfLaborSocialInsuranceAndTaxLawsDetail: companyApplicationData?.violationsOfLaborSocialInsuranceAndTaxLawsDetail ?? null,
    whetherAdequateSystemForContinuedEmploymentContractFulfillment:
      companyApplicationData?.whetherAdequateSystemForContinuedEmploymentContractFulfillment ?? YES_NO_ANSWER.YES,
    whetherAffiliationWithOrganizedCrime: companyApplicationData?.whetherAffiliationWithOrganizedCrime ?? YES_NO_ANSWER.NO,
    whetherTransportationToPortOrAirportIsProvided: companyApplicationData?.whetherTransportationToPortOrAirportIsProvided ?? YES_NO_ANSWER.YES,
    whetherAppointmentOfSupportPersonnelFromStaff: companyApplicationData?.whetherAppointmentOfSupportPersonnelFromStaff ?? YES_NO_ANSWER.YES,
    whetherAppointmentOfSupportSupervisorFromStaff: companyApplicationData?.whetherAppointmentOfSupportSupervisorFromStaff ?? YES_NO_ANSWER.YES,
    whetherAccompanimentForProceduresIsProvidedIfNeeded:
      companyApplicationData?.whetherAccompanimentForProceduresIsProvidedIfNeeded ?? YES_NO_ANSWER.YES,
    whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements:
      companyApplicationData?.whetherAwarenessOfSecurityDepositsOrPenaltyContractsInEmploymentAgreements ?? YES_NO_ANSWER.NO,
    whetherComplianceWithIndustrySpecificStandardsForContractFulfillment:
      companyApplicationData?.whetherComplianceWithIndustrySpecificStandardsForContractFulfillment ?? YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    whetherComplianceWithIndustryStandardsIsEnsured:
      companyApplicationData?.whetherComplianceWithIndustryStandardsIsEnsured ?? YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    whetherSupportPlanMeetsIndustryStandards:
      companyApplicationData?.whetherSupportPlanMeetsIndustryStandards ?? YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    whetherConditionsApplyForStaffingTargets: companyApplicationData?.whetherConditionsApplyForStaffingTargets ?? YES_NO_ANSWER.NO,
    whetherConditionsApplyForSupportPlanDelegation:
      companyApplicationData?.whetherConditionsApplyForSupportPlanDelegation ??
      companyApplicationLatestCompleteData?.whetherConditionsApplyForSupportPlanDelegation ??
      YES_NO_ANSWER.YES,
    whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel:
      companyApplicationData?.whetherCriminalPenaltiesForLawViolationsByOrgOrPersonnel ?? YES_NO_ANSWER.NO,
    whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification:
      companyApplicationData?.whetherFormerExecutiveOfOrgWithCanceledTechnicalTrainingCertification ?? YES_NO_ANSWER.NO,
    whetherHostCompanyComplianceWithSections: companyApplicationData?.whetherHostCompanyComplianceWithSections ?? YES_NO_ANSWER.NO,
    whetherSpecificIndustryRequirementsAreIncludedInSupportPlan:
      companyApplicationData?.whetherSpecificIndustryRequirementsAreIncludedInSupportPlan ?? YES_NO_AND_NOT_APPLICABLE_ANSWER.NOT_APPLICABLE,
    whetherInvoluntaryDismissalOfWorkersInSimilarRoles:
      companyApplicationData?.whetherInvoluntaryDismissalOfWorkersInSimilarRoles ?? YES_NO_ANSWER.NO,
    whetherLegalRepresentativeIsEligibility: companyApplicationData?.whetherLegalRepresentativeIsEligibility ?? YES_NO_AND_NOT_APPLICABLE_ANSWER.NO,
    whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel:
      companyApplicationData?.whetherMentalHealthIssuesAffectingContractPerformanceByOrgOrPersonnel ?? YES_NO_ANSWER.NO,
    whetherMissingForeignWorkersDueToOrgIsFault: companyApplicationData?.whetherMissingForeignWorkersDueToOrgIsFault ?? YES_NO_ANSWER.NO,
    whetherNeglectOfSupportPlanOccurredInPastFiveYears:
      companyApplicationData?.whetherNeglectOfSupportPlanOccurredInPastFiveYears ?? YES_NO_ANSWER.NO,
    whetherSupportPersonnelAreNeutralInImplementation: companyApplicationData?.whetherSupportPersonnelAreNeutralInImplementation ?? YES_NO_ANSWER.YES,
    whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport:
      companyApplicationData?.whetherNoCostBurdenOnWorkerForSpecifiedSkilledWorkerSupport ?? YES_NO_ANSWER.YES,
    whetherOrganizedCrimeControlOverBusiness: companyApplicationData?.whetherOrganizedCrimeControlOverBusiness ?? YES_NO_ANSWER.NO,
    whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting:
      companyApplicationData?.whetherPaymentOfWagesViaBankOrVerifiableMethodWithReporting ?? YES_NO_ANSWER.YES,
    whetherPenaltyClausesForBreachOfEmploymentContract:
      companyApplicationData?.whetherPenaltyClausesForBreachOfEmploymentContract ?? YES_NO_ANSWER.NO,
    whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage:
      companyApplicationData?.whetherInformationOnLivingInJapanIsProvidedInUnderstandableLanguage ?? YES_NO_ANSWER.YES,
    whetherOpportunitiesForJapaneseLanguageLearningAreProvided:
      companyApplicationData?.whetherOpportunitiesForJapaneseLanguageLearningAreProvided ?? YES_NO_ANSWER.YES,
    whetherRegularMeetingsWithSupervisorsAreConducted: companyApplicationData?.whetherRegularMeetingsWithSupervisorsAreConducted ?? YES_NO_ANSWER.YES,
    whetherRegularMeetingSystemWithSupervisorsIsInPlace:
      companyApplicationData?.whetherRegularMeetingSystemWithSupervisorsIsInPlace ?? YES_NO_ANSWER.YES,
    whetherRetentionOfActivityDocuments: companyApplicationData?.whetherRetentionOfActivityDocuments ?? YES_NO_ANSWER.YES,
    whetherRetentionOfSupportDocumentation: companyApplicationData?.whetherRetentionOfSupportDocumentation ?? YES_NO_ANSWER.YES,
    whetherSupportForOpeningBankAccountsAndContractsIsProvided:
      companyApplicationData?.whetherSupportForOpeningBankAccountsAndContractsIsProvided ?? YES_NO_ANSWER.YES,
    whetherJobChangeSupportIsProvidedForContractTermination:
      companyApplicationData?.whetherJobChangeSupportIsProvidedForContractTermination ?? YES_NO_ANSWER.YES,
    whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided:
      companyApplicationData?.whetherSupportForPromotingExchangeBetweenForeignersAndJapaneseIsProvided ?? YES_NO_ANSWER.YES,
    whetherSupportForSecuringAppropriateHousingIsProvided:
      companyApplicationData?.whetherSupportForSecuringAppropriateHousingIsProvided ?? YES_NO_ANSWER.YES,
    whetherSupportSystemInUnderstandingLanguageIsAvailable:
      companyApplicationData?.whetherSupportSystemInUnderstandingLanguageIsAvailable ?? YES_NO_ANSWER.YES,
    whetherSupportPlanIsCreatedInUnderstandableLanguages:
      companyApplicationData?.whetherSupportPlanIsCreatedInUnderstandableLanguages ?? YES_NO_ANSWER.YES,
    whetherSupportIsAppropriateForForeignersProperStay:
      companyApplicationData?.whetherSupportIsAppropriateForForeignersProperStay ?? YES_NO_ANSWER.YES,
    whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws:
      companyApplicationData?.whetherThereHaveBeenViolationsOfLaborSocialInsuranceAndTaxLaws ?? YES_NO_ANSWER.NO,
    whetherTimelyResponseToConsultationsOrComplaintsIsEnsured:
      companyApplicationData?.whetherTimelyResponseToConsultationsOrComplaintsIsEnsured ?? YES_NO_ANSWER.YES,
    whetherUnfairActsRelatedToImmigrationLaborLaws: companyApplicationData?.whetherUnfairActsRelatedToImmigrationLaborLaws ?? YES_NO_ANSWER.NO,
    whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel: companyApplicationData?.whetherUnrehabilitatedBankruptcyOfOrgOrPersonnel ?? YES_NO_ANSWER.NO,
    whetherWorkerIsCompensationInsuranceEnrollment: companyApplicationData?.whetherWorkerIsCompensationInsuranceEnrollment ?? YES_NO_ANSWER.YES,
    workerIsCompensationInsuranceEnrollmentDetail: companyApplicationData?.workerIsCompensationInsuranceEnrollmentDetail ?? null,
  };
};
