import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { ConvenientForCompanyForm } from "@/features/retirement/components/retirement-detail/accepting-worker/forms/convenientForCompanyForm";
import { ConvenientForEmployeeForm } from "@/features/retirement/components/retirement-detail/accepting-worker/forms/convenientForEmployeeForm";
import { ERetirementReasonType } from "@/features/retirement/constants/enums";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TTargetForeignerProps = {
  readonly?: boolean;
};

export const TargetForeigner: FC<TTargetForeignerProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TRetirementAcceptingWorkerForm>();
  const value = useWatch({ control, name: "reasonType" });
  const { data: retirementReasonTypeOptions } = useEnum("ERetirementReasonType");

  return (
    <Stack gap={2}>
      <Typography variant="head20Semi">届出の事由</Typography>
      <InnerBox title="事由" required bordered p={3}>
        <FormField control={control} name="reasonType">
          <RadioGroup
            disabled={readonly}
            options={retirementReasonTypeOptions}
            onChange={(value) => {
              if (value === ERetirementReasonType.CONVENIENT_FOR_COMPANY) {
                setValue("convenienceCategoryType", null);
                setValue("convenienceCategoryContent", null);
                setValue("convenienceDateOfOccurrence", null);
                setValue("convenienceSummary", null);
              }
              if (value === ERetirementReasonType.CONVENIENT_FOR_EMPLOYEE) {
                setValue("changeContractCategoryType", null);
                setValue("changeContractCategoryContent", null);
                setValue("changeContractDateOfOccurrence", null);
                setValue("changeContractSummary", null);
              }
            }}
          />
        </FormField>
      </InnerBox>
      {value === ERetirementReasonType.CONVENIENT_FOR_COMPANY && <ConvenientForCompanyForm readonly={readonly} />}
      {value === ERetirementReasonType.CONVENIENT_FOR_EMPLOYEE && <ConvenientForEmployeeForm readonly={readonly} />}
    </Stack>
  );
};
