import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { TSupportContractForm } from "@/features/anytime/types/supportContractForm";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type TChangesForm = {
  readonly?: boolean;
};

export const ChangesForm: FC<TChangesForm> = ({ readonly }) => {
  const { control } = useFormContext<TSupportContractForm>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormField control={control} name="renewContractDate">
          <InputDatePicker readonly={readonly} />
        </FormField>
      </Grid>
      <Grid item xs={8} />
      <Grid item xs={12}>
        <FormField control={control} name="renewContractContent">
          <Input readonly={readonly} />
        </FormField>
      </Grid>
    </Grid>
  );
};
