import { valueOf } from "@/types/common";

export const EMPLOYEE_LIST_TAB = {
  OFFER: "offer",
  WORKING_AND_LEFT: "working_and_left",
  PROCESSING_AND_RETIRE: "processing_and_retire",
};

export type TEmployeeListTab = valueOf<typeof EMPLOYEE_LIST_TAB>;

export const EMPLOYEE_LIST_TAB_LABEL = {
  [EMPLOYEE_LIST_TAB.OFFER]: "内定",
  [EMPLOYEE_LIST_TAB.WORKING_AND_LEFT]: "在籍・休職",
  [EMPLOYEE_LIST_TAB.PROCESSING_AND_RETIRE]: "退職届出中・退職",
};
