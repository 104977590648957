import { updateRetirementDateApi } from "@/api/services/main/retirement-document";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useGetRetirementEmployeeSchema } from "@/features/retirement/schema/useGetRetirementEmployeeSchema";
import { TRetirementEmployeeForm } from "@/features/retirement/types/retirementEmployeeForm";
import { convertResDtoToFormData } from "@/features/retirement/utils/retirement-employee";
import { spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { dayjs } from "@/utils/dayjs";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

type TRetirementEmployeeFormProps = {
  defaultValues: TRetirementEmployeeForm | undefined;
  retirementEmployeeId?: number | null;
  readonly?: boolean;
};

export const RetirementEmployeeForm: FCC<TRetirementEmployeeFormProps> = ({ defaultValues, retirementEmployeeId, readonly }) => {
  const { documentGroupId } = useParams();
  const queryClient = useQueryClient();
  const { retirementEmployeeSchema } = useGetRetirementEmployeeSchema(retirementEmployeeId ?? undefined);

  const methods = useForm<TRetirementEmployeeForm>({
    defaultValues: defaultValues ? defaultValues : undefined,
    resolver: yupResolver(retirementEmployeeSchema),
    mode: "onBlur",
  });
  const { control } = methods;

  const retirementDateValue = useWatch({ control, name: "retirementDate" });

  const onSubmit = async (data: TRetirementEmployeeForm) => {
    try {
      if (documentGroupId) {
        const payload = convertResDtoToFormData(data);
        const rs = await updateRetirementDateApi(documentGroupId, payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        if (retirementEmployeeId) {
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.EMPLOYEE.FETCH_DETAIL, retirementEmployeeId],
          });
        }
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={retirementEmployeeSchema}>
      <InnerBox padding={`${spacing.sm} ${spacing.md}`}>
        <Stack>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Stack gap={1}>
                <Typography variant="body14">退職日を入力ください。</Typography>
                <Stack flexDirection="row" gap={2}>
                  <FormField control={control} name="retirementDate">
                    <InputDatePicker readonly={readonly} />
                  </FormField>
                  <FormField label="提出期限（退職日から2週間後）">
                    <InputDatePicker value={retirementDateValue ? dayjs(retirementDateValue).add(14, "day") : undefined} readonly />
                  </FormField>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              {!readonly && (
                <Stack alignItems="end">
                  <Button variant="primary" size="sm" type="submit">
                    保存
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Stack>
      </InnerBox>
    </Form>
  );
};
