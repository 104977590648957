import { TCreateCompanyBranchReqDto } from "@/api/services/main/company/dtos/create-company-branch.dto";
import { TCompanyBranchForm } from "@/features/company/types/companyBranchForm";
import { toNumber } from "@/utils/number";
import { TUpdateCompanyReqDto } from "@/api/services/main/company/dtos/update-company.dto";
import { TCompanyInfoForm } from "@/features/company/types/CompanyInfoForm";
import { TCompanyAccountInfoForm } from "@/features/company/types/CompanyAccountInfoForm";
import { TCreateGovernmentOnlineSystemAccountReqDto } from "@/api/services/main/company/dtos/create-government-online-system-account.dto";

export const convertCompanyBranchFormDataToReqDto = (data: TCompanyBranchForm): TCreateCompanyBranchReqDto => {
  return {
    branchName: data.branchName,
    zipCode: data.zipCode,
    province: data.province,
    address: data.address,
    telNumber: data.telNumber,
    weeklyWorkingHours: toNumber(data.weeklyWorkingHours),
    monthWorkingHours: toNumber(data.monthWorkingHours),
    municipality: data.municipality ?? null,
    businessInsuranceNumber: data.businessInsuranceNumber ?? null,
    employmentInsuranceNumber: data.employmentInsuranceNumber ?? null,
  };
};

export const convertCompanyFormDataToReqDto = (data: TCompanyInfoForm): TUpdateCompanyReqDto => {
  return {
    companyName: data.companyName,
    companyFuriganaName: data.companyFuriganaName ?? null,
    representativeName: data.representativeName ?? null,
    representativeFuriganaName: data.representativeFuriganaName,
    representativeJobTitle: data.representativeJobTitle,
    division: data.division,
    capital: data.capital ?? null,
    companyIdentificationNumber: data.companyIdentificationNumber ?? null,
    businessInsuranceNumber: data.businessInsuranceNumber ?? null,
    employmentInsuranceNumber: data.employmentInsuranceNumber ?? null,
    zipCode: data.zipCode ?? null,
    province: data.province ?? null,
    address: data.address ?? null,
    telNumber: data.telNumber,
    annualRevenue: data.annualRevenue ?? null,
    yearsInBusiness: data.yearsInBusiness ?? null,
    municipality: data.municipality ?? undefined,
    employeeCount: data.employeeCount ?? null,
    specificIndustryId: toNumber(data.specificIndustryId),
    specificBusinessClassificationIds: data.specificBusinessClassificationIds.map((item) => toNumber(item)),
    specificIndustryTwoId: data.specificIndustryTwoId ? toNumber(data.specificIndustryTwoId) : null,
    specificBusinessClassificationTwoIds: data.specificBusinessClassificationTwoIds
      ? data.specificBusinessClassificationTwoIds.map((item) => toNumber(item))
      : null,
    specificIndustryThreeId: data.specificIndustryThreeId ? toNumber(data.specificIndustryThreeId) : null,
    specificBusinessClassificationThreeIds: data.specificBusinessClassificationThreeIds
      ? data.specificBusinessClassificationThreeIds.map((item) => toNumber(item))
      : null,
    mainIndustryId: data.mainIndustryId ? toNumber(data.mainIndustryId) : null,
    mainIndustryOther: data.mainIndustryOther ?? null,
    immigrationJurisdiction: data.immigrationJurisdiction ?? undefined,
    supporters: data.supporters ?? [],
    payrollDeadline: data.payrollDeadline ?? null,
    paymentDate: data.paymentDate ?? null,
    companyNote: data.companyNote ?? null,
  };
};

export const convertCompanyAccountFormDataToReqDto = (data: TCompanyAccountInfoForm): TCreateGovernmentOnlineSystemAccountReqDto => {
  return {
    rasImmiFullName: data.rasImmiFullName ?? null,
    rasImmiId: data.rasImmiId ?? null,
    accordImmiId: data.accordImmiId,
    accordImmiPassword: data.accordImmiPassword || "",
    accordImmiFilePath: data.accordImmiFile?.filePath ?? null,
  };
};
