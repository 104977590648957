import { TEmployeeRetirementDocumentOutputDto } from "@/api/services/main/retirement-document/dtos/get-employee-retirement-document.dto";
import { DocumentCard } from "@/components/elements/DocumentCard";
import { EmploymentContractCreateConvertRequestStep } from "@/features/retirement/components/retirement-document/documents/employment-contract/EmploymentContractCreateConvertRequestStep";
import { EmploymentContractCreateDocumentStep } from "@/features/retirement/components/retirement-document/documents/employment-contract/EmploymentContractCreateDocumentStep";
import { EmploymentContractSubmitStep } from "@/features/retirement/components/retirement-document/documents/employment-contract/EmploymentContractSubmitStep";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findRetirementDocumentStep } from "@/utils/document";
import { FC, useMemo } from "react";

type TDocumentProps = {
  document: TEmployeeRetirementDocumentOutputDto;
  readonly?: boolean;
};

export const EmploymentContractDocument: FC<TDocumentProps> = ({ document, readonly }) => {
  const renderAcceptingWorkerCreateDocumentStep = useMemo(() => {
    const stepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT);
    const convertingStepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST);
    if (!stepData || !convertingStepData) return null;
    return <EmploymentContractCreateDocumentStep document={document} stepData={stepData} readonly={readonly} />;
  }, [readonly, document]);

  const renderAcceptingWorkerCreateConvertRequestStep = useMemo(() => {
    const creatingDocumentStepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CREATE_DOCUMENT);
    const stepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_CONVERT_REQUEST);

    if (!creatingDocumentStepData || !stepData) return null;
    return (
      <EmploymentContractCreateConvertRequestStep
        document={document}
        creatingDocumentStepData={creatingDocumentStepData}
        stepData={stepData}
        readonly={readonly}
      />
    );
  }, [readonly, document]);

  const renderEmploymentContractSubmitStep = useMemo(() => {
    const stepData = findRetirementDocumentStep(document, DOCUMENT_STEP_KEYS.RETIREMENT_EMPLOYMENT_CONTRACT_SUBMIT);

    if (!stepData) return null;
    return <EmploymentContractSubmitStep document={document} stepData={stepData} readonly={readonly} />;
  }, [readonly, document]);

  return (
    <DocumentCard title={`届出書類${document.masterDocument.documentOrder}`} subTitle={document.masterDocument.documentName}>
      {renderAcceptingWorkerCreateDocumentStep}
      {renderAcceptingWorkerCreateConvertRequestStep}
      {renderEmploymentContractSubmitStep}
    </DocumentCard>
  );
};
