import { TGetHistoryRenewVisaDocumentListOutputDto } from "@/api/services/main/renew-visa/dtos/get-history-renew-visa-document-list.dto";
import { Button } from "@/components/elements/Button";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { getDynamicRoute } from "@/utils/url";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

type UseMakeHistoryRenewVisaColumnsReturn = MRT_ColumnDef<TGetHistoryRenewVisaDocumentListOutputDto>[];

export const useMakeHistoryRenewVisaColumns = (): UseMakeHistoryRenewVisaColumnsReturn => {
  const columns = useMemo<MRT_ColumnDef<TGetHistoryRenewVisaDocumentListOutputDto>[]>(
    () => [
      {
        header: "申請日",
        Cell: ({ row }) => (row.original.applicationDate ? dayjs(row.original.applicationDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
      },
      {
        header: "申請番号",
        Cell: ({ row }) => row.original.applicationNumber || EMPTY_STR.TEXT,
      },
      {
        header: "最終更新日",
        Cell: ({ row }) => (row.original.updatedAt ? dayjs(row.original.updatedAt).format(DATE_FORMAT) : EMPTY_STR.DATE),
      },
      {
        header: "最終更新者",
        Cell: ({ row }) => row.original.updater?.fullName || EMPTY_STR.TEXT,
      },
      {
        header: "確認",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button
              variant="outline"
              size="sm"
              component={Link}
              to={getDynamicRoute(AppRoutes.renewVisaInfoHistoryDetail, { renewVisaDocumentId: id })}
            >
              確認
            </Button>
          );
        },
        size: 50,
        muiTableBodyCellProps: {
          sx: { width: "10%" },
        },
      },
    ],
    [],
  );

  return columns;
};
