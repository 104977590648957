import {
  TCreateAcceptanceActivityDocumentReqDto,
  TCreateAcceptanceActivityDocumentResDto,
} from "@/api/services/main/regular-document/acceptance-activity-document/dtos/create-acceptance-activity-document.dto";
import {
  TCreateMultiAcceptanceActivityFileReqDto,
  TCreateMultiAcceptanceActivityFileResDto,
} from "@/api/services/main/regular-document/acceptance-activity-document/dtos/create-acceptance-activity-file.dto";
import { TGetAcceptanceActivityDocumentResDto } from "@/api/services/main/regular-document/acceptance-activity-document/dtos/get-acceptance-activity-document.dto";
import {
  TGetAcceptanceActivityFileListReqDto,
  TGetAcceptanceActivityFileListResDto,
} from "@/api/services/main/regular-document/acceptance-activity-document/dtos/get-acceptance-activity-file-list.dto";
import { TGetMultiEmployeePayrollResDto } from "@/api/services/main/regular-document/acceptance-activity-document/dtos/get-multi-employee-payroll.dto";
import {
  TUpdateAcceptanceActivityDocumentReqDto,
  TUpdateAcceptanceActivityDocumentResDto,
} from "@/api/services/main/regular-document/acceptance-activity-document/dtos/update-acceptance-activity-document.dto";
import {
  TUpdateMultiEmployeePayrollReqDto,
  TUpdateMultiEmployeePayrollResDto,
} from "@/api/services/main/regular-document/acceptance-activity-document/dtos/update-multi-employee-payroll.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiRes } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";

export const createAcceptanceActivityDocumentApi = async (
  documentStepId: number,
  payload: TCreateAcceptanceActivityDocumentReqDto,
): Promise<TCreateAcceptanceActivityDocumentResDto> => {
  const url = createUrlWithParams(`/regular-documents/present/acceptance-activity/documents`, { documentStepId });
  const rs = await clientApi.post<TCreateAcceptanceActivityDocumentResDto>(url, payload);
  return rs.data;
};

export const updateAcceptanceActivityDocumentApi = async (
  documentStepId: number,
  payload: TUpdateAcceptanceActivityDocumentReqDto,
): Promise<TUpdateAcceptanceActivityDocumentResDto> => {
  const rs = await clientApi.put<TUpdateAcceptanceActivityDocumentResDto>(
    `/regular-documents/present/acceptance-activity/documents/${documentStepId}`,
    payload,
  );
  return rs.data;
};

export const fetchAcceptanceActivityDocumentApi = async (documentStepId: number): Promise<TGetAcceptanceActivityDocumentResDto> => {
  const rs = await clientApi.get<TGetAcceptanceActivityDocumentResDto>(`/regular-documents/present/acceptance-activity/documents/${documentStepId}`);
  return rs.data;
};

type TUseAcceptanceActivityDocument = {
  documentStepId: number | undefined;
  enabled?: boolean;
};

export const useAcceptanceActivityDocument = ({ documentStepId, enabled = true }: TUseAcceptanceActivityDocument) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_ACCEPTANCE_ACTIVITY_DOCUMENT, documentStepId],
    queryFn: () => (documentStepId ? fetchAcceptanceActivityDocumentApi(documentStepId) : undefined),
    enabled: Boolean(documentStepId) && enabled,
  });
};

export const fetchEmployeePayrollListApi = async (documentStepId: number): Promise<TGetMultiEmployeePayrollResDto> => {
  try {
    const { data } = await clientApi.get<TGetMultiEmployeePayrollResDto>(
      `/regular-documents/present/acceptance-activity/documents/${documentStepId}/employee-payrolls`,
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useEmployeePayrollList = (documentStepId: number | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_LIST, documentStepId],
    queryFn: () => (documentStepId ? fetchEmployeePayrollListApi(documentStepId) : undefined),
    enabled: !!documentStepId,
  });
};

export const updateMultiPayrollsApi = async (
  documentStepId: number,
  payload: TUpdateMultiEmployeePayrollReqDto,
): Promise<TUpdateMultiEmployeePayrollResDto> => {
  const rs = await clientApi.put<TUpdateMultiEmployeePayrollResDto>(
    `/regular-documents/present/acceptance-activity/documents/${documentStepId}/employee-payrolls`,
    payload,
  );

  return rs.data;
};

export const deletePayrollFileApi = async (deleteId: number): Promise<TApiRes<boolean>> => {
  try {
    const { data } = await clientApi.delete<TApiRes<boolean>>(`/regular-documents/present/acceptance-activity/files/${deleteId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchPayrollFilesApi = async (requests: TGetAcceptanceActivityFileListReqDto): Promise<TGetAcceptanceActivityFileListResDto> => {
  const url = createUrlWithParams("/regular-documents/present/acceptance-activity/files", requests);
  const rs = await clientApi.get<TGetAcceptanceActivityFileListResDto>(url);
  return rs.data;
};

export const useGetPayrollFilesApi = (documentStepId: number) => {
  const request = { regularDocumentStepId: documentStepId };
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_EMPLOYEE_PAYROLL_FILE_LIST, request],
    queryFn: () => fetchPayrollFilesApi(request),
  });
};

export const uploadPayrollFilesApi = async (payload: TCreateMultiAcceptanceActivityFileReqDto): Promise<TCreateMultiAcceptanceActivityFileResDto> => {
  const rs = await clientApi.post<TCreateMultiAcceptanceActivityFileResDto>("/regular-documents/present/acceptance-activity/files", payload);
  return rs.data;
};
