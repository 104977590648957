import { TEmployeeRenewVisaDocumentStep } from "@/api/entities/employee-renew-visa-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { useGetRenewVisaUploadFileDocumentInfo } from "@/api/services/main/renew-visa";
import { Button } from "@/components/elements/Button";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { StatusTag } from "@/components/elements/StatusTag";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { FileInfo } from "@/features/renew-visa/components/applicationStep/uploadApplication/FileInfo";
import { RENEW_VISA_UPLOAD_APPLICATION_FILE } from "@/features/renew-visa/constants/uploadApplicationFile";
import { useCheckRenewVisaHistoryPage } from "@/features/renew-visa/hook/useCheckRenewVisaHistoryPage";
import { useGetUploadFileListWithFilterCondition } from "@/features/renew-visa/hook/useGetUploadFileListWithFilterCondition";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { DOCUMENT_STATUS, RENEW_VISA_DOCUMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TUploadApplicationStepProps = {
  basicInfoStepData: TEmployeeRenewVisaDocumentStep;
  stepData: TEmployeeRenewVisaDocumentStep;
};

export const UploadApplicationStep: FC<TUploadApplicationStepProps> = ({ basicInfoStepData, stepData }) => {
  const { id: documentStepId } = stepData;

  const { renewVisaDocumentId } = useParams();
  const { data: renewVisaDocumentStatuses } = useEnum("ERenewVisaDocumentStatus");
  const { data: uploadFileData } = useGetRenewVisaUploadFileDocumentInfo({
    documentStepId,
    enabled: basicInfoStepData.status === DOCUMENT_STATUS.COMPLETE,
  });

  const { uploadFileListWithFilterCondition, conditions, isLoading } = useGetUploadFileListWithFilterCondition();

  const { isHistoryPage } = useCheckRenewVisaHistoryPage();

  const { checkFinishInitStep } = useInitSetup();

  if (isLoading) return <LoadingArea />;
  return (
    <Stack p="16px" sx={{ border: "1px solid #E6E7E8", borderRadius: "8px", marginTop: "16px" }}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={10}>
        <Typography variant="body14Bold">添付書類アップロード</Typography>
        <Button
          variant="secondary"
          size="sm"
          disabled={basicInfoStepData.status === DOCUMENT_STATUS.IN_PROGRESS}
          component={Link}
          to={
            isHistoryPage
              ? getDynamicRoute(AppRoutes.renewVisaInfoHistoryUploadApplication, { renewVisaDocumentId, documentStepId })
              : getDynamicRoute(AppRoutes.renewVisaPresentUploadApplication, { renewVisaDocumentId, documentStepId })
          }
          onClick={checkFinishInitStep}
        >
          {isHistoryPage ? "確認" : "アップロード"}
        </Button>
      </Stack>
      {basicInfoStepData.status === DOCUMENT_STATUS.COMPLETE ? (
        <Stack mt={2}>
          <Stack flexDirection="row" alignItems="flex-start" gap={10}>
            <Stack gap={1}>
              <Typography variant="body14Bold">更新日時</Typography>
              <Typography variant="body14">
                {uploadFileData?.updatedAt ? dayjs(uploadFileData.updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="body14Bold">最終更新者</Typography>
              <Typography variant="body14">{uploadFileData?.updater?.fullName || EMPTY_STR.TEXT}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="body14Bold">ステータス</Typography>
              <StatusTag
                variant="bold"
                status={uploadFileData?.status ?? DOCUMENT_STATUS.IN_PROGRESS}
                title={findLabelByValue(renewVisaDocumentStatuses, uploadFileData?.status ?? DOCUMENT_STATUS.IN_PROGRESS) || EMPTY_STR.TEXT}
              />
            </Stack>
          </Stack>
          <Stack gap={3} mt={2}>
            {uploadFileListWithFilterCondition
              // If select one of A-E and is in progress and is group 6, then do not show this group
              .filter((item) => {
                if (
                  conditions.length > 0 &&
                  uploadFileData?.status === RENEW_VISA_DOCUMENT_STATUS.IN_PROGRESS &&
                  item.label === RENEW_VISA_UPLOAD_APPLICATION_FILE.GROUP_SIX.label
                ) {
                  return false;
                }
                return true;
              })
              .map((item, index) => (
                <Stack key={index} gap={1}>
                  <Typography component="h4" variant="body14Bold">
                    {item.label}
                  </Typography>
                  {item.subLabel && item.subLabel === RENEW_VISA_UPLOAD_APPLICATION_FILE.GROUP_THREE.subLabel && (
                    <Typography component="p" variant="body14" fontSize={13} whiteSpace="pre-line">
                      {item.subLabel}
                    </Typography>
                  )}
                  <Stack gap={1}>
                    {item.value
                      .filter((item) => (item.onlyShowWhenHasFile === true && !item.isUploaded ? false : true)) // if onlyShowWhenHasFile true and file not has been uploaded then not show
                      .map((item, index) => {
                        // if table 2_3 or 2_3 and do not select A-E and file path is "true" then file has been not uploaded yet
                        let isUploaded = item.isUploaded;
                        const filePath = uploadFileData ? uploadFileData[item.key] : null;
                        if (
                          conditions?.length === 0 &&
                          (item.key === "residenceApplicationsForEmployeeTable2And2FilePath" ||
                            item.key === "residenceApplicationsForEmployeeTable2And3FilePath")
                        ) {
                          isUploaded = filePath ? filePath !== "true" : false;
                        }
                        return <FileInfo key={index} title={item.presentScreenLabel} isUploaded={isUploaded} tooltipContent={item.tooltipContent} />;
                      })}
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </Stack>
      ) : (
        <Typography variant="body14" mt="16px">
          ※ステップ１ 基本情報を入力すると必要な書類が表示されます。
        </Typography>
      )}
    </Stack>
  );
};
