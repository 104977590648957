import {
  TCreateLifeSupportDocumentReqDto,
  TCreateLifeSupportDocumentResDto,
} from "@/api/services/main/regular-document/life-support-document/dtos/create-life-support-document.dto";
import { TGetLifeSupportDocumentResDto } from "@/api/services/main/regular-document/life-support-document/dtos/get-life-support-document.dto";
import {
  TUpdateLifeSupportDocumentReqDto,
  TUpdateLifeSupportDocumentResDto,
} from "@/api/services/main/regular-document/life-support-document/dtos/update-life-support-document.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";

export const createLifeSupportDocumentApi = async (
  documentStepId: number,
  resourceData: TCreateLifeSupportDocumentReqDto,
): Promise<TCreateLifeSupportDocumentResDto> => {
  const { data } = await clientApi.post<TCreateLifeSupportDocumentResDto>(
    `/regular-documents/present/life-support/documents?documentStepId=${documentStepId}`,
    resourceData,
  );
  return Promise.resolve(data);
};

export const fetchLifeSupportDocumentApi = async (documentStepId: number): Promise<TGetLifeSupportDocumentResDto> => {
  const { data } = await clientApi.get<TGetLifeSupportDocumentResDto>(`/regular-documents/present/life-support/documents/${documentStepId}`);
  return data;
};

type TUseLifeSupportDocument = {
  documentStepId: number | undefined;
  enabled?: boolean;
};

export const useLifeSupportDocument = ({ documentStepId, enabled = true }: TUseLifeSupportDocument) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REGULAR_DOCUMENTS.FETCH_LIFE_SUPPORT_DOCUMENT, documentStepId],
    queryFn: () => (documentStepId ? fetchLifeSupportDocumentApi(documentStepId) : undefined),
    enabled: Boolean(documentStepId) && enabled,
  });
};

export const updateLifeSupportDocumentApi = async (
  documentStepId: number,
  payload: TUpdateLifeSupportDocumentReqDto,
): Promise<TUpdateLifeSupportDocumentResDto> => {
  const rs = await clientApi.put<TUpdateLifeSupportDocumentResDto>(`/regular-documents/present/life-support/documents/${documentStepId}`, payload);
  return rs.data;
};
