import {
  TCreateSupportPlanModificationDocumentReqDto,
  TCreateSupportPlanModificationDocumentResDto,
} from "@/api/services/main/any-time/support-plan/dtos/create-support-plan-modification-document.dto";
import { TGetSupportPlanModificationDocumentResDto } from "@/api/services/main/any-time/support-plan/dtos/get-support-plan-modification-document.dto";
import {
  TUpdateSupportPlanModificationDocumentReqDto,
  TUpdateSupportPlanModificationDocumentResDto,
} from "@/api/services/main/any-time/support-plan/dtos/update-support-plan-modification-document.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createAnytimeSupportPlanApi = (payload: TCreateSupportPlanModificationDocumentReqDto) => {
  return clientApi.post<TCreateSupportPlanModificationDocumentResDto>("/any-time-documents/support-plan-modification-documents", payload);
};

export const updateAnytimeSupportPlanApi = (stepId: number | string, payload: TUpdateSupportPlanModificationDocumentReqDto) => {
  return clientApi.put<TUpdateSupportPlanModificationDocumentResDto>(
    `/any-time-documents/support-plan-modification-documents/steps/${stepId}`,
    payload,
  );
};

export const fetchAnytimeSupportPlanApi = async (stepId: string | number) => {
  const rs = await clientApi.get<TGetSupportPlanModificationDocumentResDto>(
    `/any-time-documents/support-plan-modification-documents/steps/${stepId}`,
  );
  return rs.data;
};

export const useAnytimeSupportPlan = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.ANYTIME_DOCUMENTS.FETCH_SUPPORT_PLAN, id],
    queryFn: () => (id ? fetchAnytimeSupportPlanApi(id) : undefined),
    enabled: Boolean(id),
  });
};
