import { PageBack } from "@/components/elements/PageBack";
import { Tag } from "@/components/elements/Tag";
import { button, text } from "@/theme/colors";
import { TRequiredOne } from "@/types/common";
import { Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

export type TTopPageProps = {
  title: string;
  subTitle?: ReactNode;
  description?: string;
  rightContent?: ReactNode;
  isDraft?: boolean;
  gap?: number;
} & TRequiredOne<{ backUrl: string; enablePageBack: boolean }>;

export const TopPage: FC<TTopPageProps> = ({ title, subTitle, description, backUrl, enablePageBack = true, rightContent, isDraft, gap }) => {
  return (
    <Stack gap={gap ?? 3} pb={3}>
      {enablePageBack && <PageBack backUrl={backUrl} />}
      <Stack direction="row" gap={3} alignItems="center">
        <Stack gap={1} pt={enablePageBack ? 3 : 0} flex={1}>
          <Stack gap={0.5}>
            {subTitle && <Typography variant="body14">{subTitle}</Typography>}
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Typography variant="head24Bold">{title}</Typography>
              {isDraft && <Tag title="下書き" variant="bold" color={button.secondary} textColor={text.white} />}
            </Stack>
          </Stack>
          {description && (
            <Typography sx={{ whiteSpace: "pre-line" }} variant="body14">
              {description}
            </Typography>
          )}
        </Stack>
        {rightContent}
      </Stack>
    </Stack>
  );
};
