import { INVALID_NUMBER_MAX, INVALID_NUMBER_MIN } from "@/constants/invalids";
import { EEmployeeCareerJapaneseCertification, EEmployeeCareerSkillCertificationOne } from "@/features/renew-visa/enums/employeeApplicationForm";
import { EMPLOYEE_CAREER_LOCATION, EMPLOYMENT_STATUS, RESIDENCE_STATUS, RETIREMENT_STATUS } from "@/types/enum";
import { replaceStr } from "@/utils/string";
import { validator } from "@/utils/validator";
import { useMemo } from "react";

export const useGetEmployeeSchema = () => {
  const employeeSchema = useMemo(() => {
    const schema = validator.object().shape({
      // info form
      managementNumber: validator.string().trim().emptyToNull().autoTransformToHalfWidth().label("管理番号").notRequired(),
      lastName: validator.string().trim().emptyToNull().autoTransformToHalfWidth().max(34).latinsOnly().label("姓").notRequired(),
      middleName: validator.string().trim().emptyToNull().autoTransformToHalfWidth().max(34).latinsOnly().label("ミドルネーム").notRequired(),
      firstName: validator.string().trim().emptyToNull().autoTransformToHalfWidth().max(34).latinsOnly().label("名").required(),
      nickName: validator.string().trim().emptyToNull().label("呼び名").notRequired(),
      nationalityId: validator.string().isSelection().trim().emptyToNull().label("国籍").required(),
      birthday: validator.mixed().isDayjs().isSelection().isBeforeAge(18).label("生年月日").required(),
      gender: validator.string().isSelection().trim().emptyToNull().label("性別").required(),
      employmentStatus: validator.string().trim().isSelection().emptyToNull().label("在籍ステータス").required(),
      hiringDate: validator
        .mixed()
        .isDayjs()
        .isSelection()
        .isBeforeDateOfField("retirementDate", { startLabel: "入社日", endLabel: "退職日" })
        .label("入社日（予定日）")
        .required(),
      skillOneStartDate: validator.mixed().isDayjs().isSelection().isPastOrToday().label("特定技能1号の起算日").notRequired(),
      blankPeriodMonthly: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .min(0, replaceStr(INVALID_NUMBER_MIN, { path: "ブランク期間（月単位）", min: 0 }))
        .label("ブランク期間（月単位）")
        .notRequired(),
      specificSkillTransitionPeriod: validator.string().trim().emptyToNull().label("特定技能経過期間").notRequired(),
      retirementStatus: validator.string().isSelection().trim().emptyToNull().label("退職ステータス").notRequired(),
      retirementDate: validator
        .mixed()
        .isDayjs()
        .isAfterDateOfField("hiringDate", { startLabel: "入社日", endLabel: "退職日" })
        .label("退職日（予定日）")
        .notRequired()
        .when("retirementStatus", {
          is: RETIREMENT_STATUS.RETIRED,
          then: () =>
            validator
              .mixed()
              .isSelection()
              .isDayjs()
              .isAfterDateOfField("hiringDate", { startLabel: "入社日", endLabel: "退職日" })
              .label("退職日（予定日）")
              .required(),
        }),
      hasRetiredBefore: validator.bool().notRequired(),
      zipCode: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .zipCode()
        .label("郵便番号")
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.WORKING,
          then: (schema) => schema.required(),
        })
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.LEFT,
          then: (schema) => schema.required(),
        })
        .notRequired(),
      province: validator
        .string()
        .trim()
        .isSelection()
        .emptyToNull()
        .label("都道府県")
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.WORKING,
          then: (schema) => schema.required(),
        })
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.LEFT,
          then: (schema) => schema.required(),
        })
        .notRequired(),
      municipality: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("市区町村")
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.WORKING,
          then: (schema) => schema.required(),
        })
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.LEFT,
          then: (schema) => schema.required(),
        })
        .notRequired(),
      address: validator
        .string()
        .trim()
        .emptyToNull()
        .max(80)
        .autoTransformToFullWidth()
        .label("都道府県・市区町村以外の住所")
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.WORKING,
          then: (schema) => schema.required(),
        })
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.LEFT,
          then: (schema) => schema.required(),
        })
        .notRequired(),
      telNumber: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .numbersOnly()
        .max(12, replaceStr(INVALID_NUMBER_MAX, { path: "電話番号", max: 12 }))
        .label("電話番号")
        .notRequired(),
      email: validator.string().trim().emptyToNull().autoTransformToHalfWidth().email().max(60).label("メールアドレス").required(),
      referralCompanyName: validator.string().trim().emptyToNull().label("紹介会社名").notRequired(),
      registeredSupportOrganizationName: validator.string().trim().emptyToNull().label("登録支援機関名").notRequired(),

      // passport
      passportPermanentAddress: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToFullWidth()
        .max(80)
        .fullWidthOnly()
        .label("本国における居住地")
        .notRequired(),
      passportCode: validator
        .string()
        .trim()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .max(9)
        .uppercaseAlphanumericCharactersOnly()
        .label("旅券番号")
        .required(),
      passportExpiryDate: validator.mixed().isSelection().isDayjs().label("旅券有効期限").required(),
      passportFile: validator.mixed().isExtendFile().label("パスポート写しデータ").notRequired(),
      // residence card
      residenceCode: validator
        .string()
        .trim()
        .emptyToNull()
        .length(12)
        .uppercaseAlphanumericCharactersOnly()
        .autoTransformToHalfWidth()
        .label("在留カード番号")
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.WORKING,
          then: (schema) => schema.required(),
        })
        .when("employmentStatus", {
          is: EMPLOYMENT_STATUS.LEFT,
          then: (schema) => schema.required(),
        })
        .notRequired(),
      residenceStatusId: validator.string().trim().isSelection().emptyToNull().label("在留資格").required(),
      residencePeriodId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("在留期間")
        .notRequired()
        .when("residenceCode", {
          is: (value: unknown) => !!value,
          then: (schema) => schema.required(),
        }),
      residenceExpiryDate: validator
        .mixed()
        .isSelection()
        .isDayjs()
        .label("在留期限（在留期間満了日）")
        .notRequired()
        .when("residenceCode", {
          is: (value: unknown) => !!value,
          then: (schema) => schema.required(),
        }),
      residenceCardFrontFile: validator.array().of(validator.mixed().isExtendFile()).label("在留カード写し表データ").notRequired(),
      residenceCardBackFile: validator.array().of(validator.mixed().isExtendFile()).label("在留カード写し裏データ").notRequired(),

      // contract
      contractEmploymentFile: validator.mixed().isExtendFile().label("雇用契約書").notRequired(),
      contractConditionFile: validator.mixed().isExtendFile().label("雇用条件書・賃金の支払").notRequired(),
      contractCompanyBranchId: validator.string().isSelection().trim().emptyToNull().label("勤務先事業所").required(),
      contractStartDate: validator
        .mixed()
        .isSelection()
        .isDayjs()
        .isBeforeDateOfField("contractEndDate", { startLabel: "雇用契約期間（始期）", endLabel: "雇用契約期間（終期）" })
        .label("雇用契約期間（始期）")
        .required(),
      contractEndDate: validator
        .mixed()
        .isSelection()
        .isDayjs()
        .isAfterDateOfField("contractStartDate", { startLabel: "雇用契約期間（始期）", endLabel: "雇用契約期間（終期）" })
        .label("雇用契約期間（終期）")
        .required(),
      contractMainOccupationId: validator.string().isSelection().trim().emptyToNull().label("主たる職種").required(),
      contractSpecificBusinessClassificationOneId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("特定産業分野・業務区分 1")
        .when("residenceStatusId", {
          is: (value: unknown) => !value,
          then: (schema) => schema.required("特定産業分野・業務区分を選択する前に、在留資格を選択してください。"),
        })
        .when("residenceStatusId", {
          is: "1",
          then: (schema) => schema.required(),
        })
        .when("residenceStatusId", {
          is: "2",
          then: (schema) => schema.required(),
        })
        .notRequired(),
      contractSpecificBusinessClassificationTwoId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("特定産業分野・業務区分 2")
        .when("residenceStatusId", {
          is: (value: unknown) => !value,
          then: (schema) => schema.required("特定産業分野・業務区分を選択する前に、在留資格を選択してください。"),
        })
        .notRequired(),
      contractSpecificBusinessClassificationThreeId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("特定産業分野・業務区分 3")
        .when("residenceStatusId", {
          is: (value: unknown) => !value,
          then: (schema) => schema.required("特定産業分野・業務区分を選択する前に、在留資格を選択してください。"),
        })
        .notRequired(),
      contractMonthlySalary: validator
        .number()
        .decimal()
        .emptyToNull()
        .autoTransformToHalfWidth()
        .integer()
        .min(0)
        .max(99999999)
        .label("月額報酬（円）")
        .notRequired(),

      // career
      careerTrainingStatus: validator.string().trim().emptyToNull().label("技能実習2号修了の有無").notRequired(),
      careerMajorId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("職種")
        .notRequired()
        .when("careerSkillCertificationOne", {
          is: (value: unknown) => value === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING,
          then: (schema) => schema.required(),
        })
        .when("careerJapaneseCertificationOne", {
          is: (value: unknown) => value === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING,
          then: (schema) => schema.required(),
        }),
      careerJobTypeId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("作業")
        .notRequired()
        .when("careerSkillCertificationOne", {
          is: (value: unknown) => value === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING,
          then: (schema) => schema.required(),
        })
        .when("careerJapaneseCertificationOne", {
          is: (value: unknown) => value === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING,
          then: (schema) => schema.required(),
        }),
      careerCompletionProof: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("良好に修了したことの証明")
        .notRequired()
        .when("careerSkillCertificationOne", {
          is: (value: unknown) => value === EEmployeeCareerSkillCertificationOne.COMPLETED_TECHNICAL_INTERN_TRAINING,
          then: (schema) => schema.required(),
        })
        .when("careerJapaneseCertificationOne", {
          is: (value: unknown) => value === EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING,
          then: (schema) => schema.required(),
        }),
      careerTrainingCompletionFile: validator.mixed().isExtendFile().label("修了の証明").notRequired(),
      careerSkillCertificationOne: validator.string().isSelection().trim().emptyToNull().label("技能水準　証明1").notRequired(),
      careerSkillExamOneId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("合格した試験名1（技能）")
        .notRequired()
        .when(["careerSkillOtherEvaluation", "careerSkillCertificationOne"], ([careerSkillOtherEvaluation, careerSkillCertificationOne], schema) => {
          return schema.test("checkCareerSkillExamOneId", "", function (value) {
            const { path, createError } = this;
            if (
              careerSkillCertificationOne === EEmployeeCareerJapaneseCertification.FIELD_SPECIFIC_OPERATION_POLICY &&
              !(careerSkillOtherEvaluation || value)
            ) {
              return createError({ path, message: () => "合格した試験名1（技能）またはその他の評価方法による証明を入力してください。" });
            } else {
              return true;
            }
          });
        }),
      careerSkillExamLocationOne: validator.string().trim().emptyToNull().label("受験地1").notRequired(),
      careerSkillExamOverseaOneNationalityId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("技能受験地1（国外）")
        .notRequired()
        .when(["careerSkillExamLocationOne", "careerSkillCertificationOne"], ([careerSkillExamLocationOne, careerSkillCertificationOne], schema) => {
          if (
            careerSkillExamLocationOne === EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN &&
            careerSkillCertificationOne !== EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
          ) {
            return schema.required();
          }
          return schema;
        }),
      careerSkillOtherEvaluation: validator.string().trim().emptyToNull().max(60).label("その他の評価方法による証明").notRequired(),
      careerSkillCompletionFile: validator.mixed().isExtendFile().label("合格証明書1").notRequired(),
      careerJapaneseCertificationOne: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("日本語能力　証明1")
        .notRequired()
        .when("residenceStatusId", {
          is: RESIDENCE_STATUS.SPECIFIED_SKILL_NO1.toString(),
          then: (schema) => schema.required(),
        }),
      careerJapaneseExamOneId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("合格した試験名1（日本語）")
        .notRequired()
        .when("careerJapaneseCertificationOne", {
          is: EEmployeeCareerJapaneseCertification.FIELD_SPECIFIC_OPERATION_POLICY,
          then: (schema) => schema.required(),
        }),
      careerJapaneseExamLocationOne: validator
        .string()
        .trim()
        .emptyToNull()
        .label("受験地1")
        .notRequired()
        .when(["residenceStatusId", "careerJapaneseCertificationOne"], ([residenceStatusId, careerJapaneseCertificationOne], schema) => {
          if (
            residenceStatusId === RESIDENCE_STATUS.SPECIFIED_SKILL_NO1.toString() &&
            careerJapaneseCertificationOne !== EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
          ) {
            return schema.required();
          }
          return schema;
        }),
      careerJapaneseExamOverseaOneNationalityId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("日本語受験地1（国外）")
        .notRequired()
        .when(
          ["careerJapaneseExamLocationOne", "careerJapaneseCertificationOne"],
          ([careerJapaneseExamLocationOne, careerJapaneseCertificationOne], schema) => {
            if (
              careerJapaneseExamLocationOne === EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN &&
              careerJapaneseCertificationOne !== EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
            ) {
              return schema.required();
            }
            return schema;
          },
        ),
      careerJanpaneseCompletionOneFile: validator.mixed().isExtendFile().label("合格証明書1").notRequired(),
      careerJapaneseCertificationTwo: validator.string().isSelection().trim().emptyToNull().label("日本語能力　証明2").notRequired(),
      careerJapaneseExamTwoId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("合格した試験名2（日本語）")
        .notRequired()
        .when("careerJapaneseCertificationTwo", {
          is: EEmployeeCareerJapaneseCertification.FIELD_SPECIFIC_OPERATION_POLICY,
          then: (schema) => schema.required(),
        }),
      careerJapaneseExamLocationTwo: validator.string().trim().emptyToNull().label("受験地2").notRequired(),
      careerJapaneseExamOverseaTwoNationalityId: validator
        .string()
        .isSelection()
        .trim()
        .emptyToNull()
        .label("日本語受験地2（国外）")
        .notRequired()
        .when(
          ["careerJapaneseExamLocationTwo", "careerJapaneseCertificationOne", "careerJapaneseCertificationTwo"],
          ([careerJapaneseExamLocationTwo, careerJapaneseCertificationOne, careerJapaneseCertificationTwo], schema) => {
            if (
              careerJapaneseExamLocationTwo === EMPLOYEE_CAREER_LOCATION.OUTSIDE_JAPAN &&
              careerJapaneseCertificationOne &&
              careerJapaneseCertificationTwo !== EEmployeeCareerJapaneseCertification.COMPLETED_TECHNICAL_INTERN_TRAINING
            ) {
              return schema.required();
            }
            return schema;
          },
        ),
      careerJanpaneseCompletionTwoFile: validator.mixed().isExtendFile().label("合格証明書2").notRequired(),
      careerConfirmationGuidanceFile: validator.mixed().isExtendFile().label("事前ガイダンスの確認書").notRequired(),
      careerConfirmationOrientationFile: validator.mixed().isExtendFile().label("生活オリエンテーションの確認書").notRequired(),
      careerDependentNote: validator.string().trim().emptyToNull().label("扶養する家族などメモ").notRequired(),
      careerDependentFamilyFile: validator.array().of(validator.mixed().isExtendFile()).label("親族関係書類（戸籍謄本など）・翻訳").notRequired(),
      careerDependentRemittanceFile: validator.array().of(validator.mixed().isExtendFile()).label("送金関係書類").notRequired(),
      note: validator.string().trim().emptyToNull().label("備考").notRequired(),
    });

    return schema;
  }, []);

  return { employeeSchema };
};
