import {
  TCreateOrUpdateRenewVisaApplicationInformationDocumentReqDto,
  TCreateOrUpdateRenewVisaApplicationInformationDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-application-information-document.dto";
import {
  TCreateOrUpdateRenewVisaBasicInformationDocumentReqDto,
  TCreateOrUpdateRenewVisaBasicInformationDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-basic-information-document.dto";
import {
  TCreateOrUpdateRenewVisaCompanyApplicationDocumentReqDto,
  TCreateOrUpdateRenewVisaCompanyApplicationDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-company-application-document.dto";
import {
  TCreateOrUpdateRenewVisaEmployeeApplicationDocumentReqDto,
  TCreateOrUpdateRenewVisaEmployeeApplicationDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-employee-application-document.dto";
import {
  TCreateOrUpdateRenewVisaResidenceCardInformationDocumentReqDto,
  TCreateOrUpdateRenewVisaResidenceCardInformationDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-residence-card-information-document.dto";
import {
  TCreateOrUpdateRenewVisaUploadFileDocumentReqDto,
  TCreateOrUpdateRenewVisaUploadFileDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-renew-visa-upload-file-document.dto";
import {
  TCreateOrUpdateRenewVisaUploadAvatarFileDocumentReqDto,
  TCreateOrUpdateRenewVisaUploadAvatarFileDocumentResDto,
} from "@/api/services/main/renew-visa/dtos/create-or-update-upload-avatar-file-document.dto";
import { TFinishSubmitDocumentReqDto, TFinishSubmitDocumentResDto } from "@/api/services/main/renew-visa/dtos/finish-submit-document.dto";
import {
  TGetHistoryRenewVisaDocumentListReqDto,
  TGetHistoryRenewVisaDocumentListResDto,
} from "@/api/services/main/renew-visa/dtos/get-history-renew-visa-document-list.dto";
import { TGetTRenewVisaApplicationInformationDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-application-information-document.dto";
import { TGetTRenewVisaBasicInformationDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-basic-information-document.dto";
import { TGetTRenewVisaCompanyApplicationDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-company-application-document.dto";
import {
  TGetRenewVisaDocumentListToExportCsvReqDto,
  TGetRenewVisaDocumentListToExportCsvResDto,
} from "@/api/services/main/renew-visa/dtos/get-renew-visa-document-list-to-export-csv.dto";
import {
  TGetRenewVisaDocumentListReqDto,
  TGetRenewVisaDocumentListResDto,
} from "@/api/services/main/renew-visa/dtos/get-renew-visa-document-list.dto";
import { TGetRenewVisaDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-document.dto";
import { TGetTRenewVisaEmployeeApplicationDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-employee-application-document.dto";
import { TGetTRenewVisaResidenceCardInformationDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-residence-card-information-document.dto";
import { TGetTRenewVisaUploadAvatarFileDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-upload-avatar-file-document.dto";
import { TGetTRenewVisaUploadFileDocumentResDto } from "@/api/services/main/renew-visa/dtos/get-renew-visa-upload-file-document.dto";
import { TUpdateExpectSubmitDateReqDto, TUpdateExpectSubmitDateResDto } from "@/api/services/main/renew-visa/dtos/update-expect-submit-date.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiPagReq } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

//renew-visa-document-history
export const fetchHistoryRenewVisaDocumentListApi = async (
  request: TGetHistoryRenewVisaDocumentListReqDto,
): Promise<TGetHistoryRenewVisaDocumentListResDto> => {
  const url = createUrlWithParams("/renew-visa-documents/history", request);
  const { data } = await clientApi.get<TGetHistoryRenewVisaDocumentListResDto>(url);
  return data;
};

type UseGetHistoryRenewVisaDocumentListProps = {
  employeeId: number;
};

export const useGetHistoryRenewVisaDocumentList = ({ employeeId }: UseGetHistoryRenewVisaDocumentListProps) => {
  const pagReq = usePagination();

  const params = { ...pagReq, employeeId };

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_HISTORY_LIST, params],
    queryFn: () => fetchHistoryRenewVisaDocumentListApi(params),
    enabled: !!employeeId,
  });

  return { data, ...rest };
};

//renew-visa-document-present

export const fetchRenewVisaDocumentListApi = async (request: TGetRenewVisaDocumentListReqDto): Promise<TGetRenewVisaDocumentListResDto> => {
  const url = createUrlWithParams("/renew-visa-documents", request);
  const { data } = await clientApi.get<TGetRenewVisaDocumentListResDto>(url);
  return data;
};

type TUseGetRenewVisaDocumentListParams = Partial<TApiPagReq>;

export const useGetRenewVisaDocumentList = (params?: TUseGetRenewVisaDocumentListParams) => {
  const pagReq = usePagination();

  const request = {
    ...pagReq,
    ...params,
  };

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_LIST, request],
    queryFn: () => fetchRenewVisaDocumentListApi(request),
  });

  return { data, ...rest };
};

export const fetchRenewVisaDocumentListToExportCsvApi = async (
  request: TGetRenewVisaDocumentListToExportCsvReqDto,
): Promise<TGetRenewVisaDocumentListToExportCsvResDto> => {
  const url = createUrlWithParams("/renew-visa-documents/export-csv", request);
  const { data } = await clientApi.get<TGetRenewVisaDocumentListToExportCsvResDto>(url);

  return data;
};

export const fetchRenewVisaDocumentInfoApi = async (id: string | number): Promise<TGetRenewVisaDocumentResDto> => {
  const rs = await clientApi.get<TGetRenewVisaDocumentResDto>(`/renew-visa-documents/${id}`);
  return rs.data;
};

export const useGetRenewVisaDocumentInfo = (id?: number) => {
  const { renewVisaDocumentId } = useParams();

  const fetchId = id ?? renewVisaDocumentId;

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_DETAIL, fetchId],
    queryFn: () => (fetchId ? fetchRenewVisaDocumentInfoApi(fetchId) : undefined),
    enabled: Boolean(fetchId),
  });

  return { data: data?.data, ...rest };
};

export const fetchLatestCompleteRenewVisaDocumentInfoApi = async (): Promise<TGetRenewVisaDocumentResDto> => {
  const rs = await clientApi.get<TGetRenewVisaDocumentResDto>(`/renew-visa-documents/latest-complete`);
  return rs.data;
};

export const useLatestCompleteGetRenewVisaDocumentInfo = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_LATEST_DETAIL],
    queryFn: () => fetchLatestCompleteRenewVisaDocumentInfoApi(),
  });

  return { data: data, ...rest };
};

export const finishSubmitDocumentStepApi = async (payload: TFinishSubmitDocumentReqDto): Promise<TFinishSubmitDocumentResDto> => {
  const { data } = await clientApi.post<TFinishSubmitDocumentResDto>("/renew-visa-documents/finish-submit-document-step", payload);
  return data;
};

export const updateExpectSubmitDateApi = async (
  id: string | number,
  payload: TUpdateExpectSubmitDateReqDto,
): Promise<TUpdateExpectSubmitDateResDto> => {
  const { data } = await clientApi.put<TUpdateExpectSubmitDateResDto>(`/renew-visa-documents/${id}/update-expect-submit-date`, payload);
  return data;
};

// basic infomation

export const createRenewVisaBasicInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaBasicInformationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaBasicInformationDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaBasicInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/basic-information`,
    payload,
  );
  return rs.data;
};

export const updateRenewVisaBasicInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaBasicInformationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaBasicInformationDocumentResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateRenewVisaBasicInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/basic-information`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaBasicInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaBasicInformationDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaBasicInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/basic-information`,
  );

  return rs.data;
};

type UseGetRenewVisaBasicInformationDocumentInfoProps = {
  documentStepId: string | number;
  enabled: boolean;
};

export const useGetRenewVisaBasicInformationDocumentInfo = ({ documentStepId, enabled }: UseGetRenewVisaBasicInformationDocumentInfoProps) => {
  const { renewVisaDocumentId } = useParams();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_BASIC_INFORMATION, renewVisaDocumentId, documentStepId],
    queryFn: () =>
      renewVisaDocumentId && documentStepId ? fetchRenewVisaBasicInformationDocumentApi(renewVisaDocumentId, documentStepId) : undefined,
    enabled: !!renewVisaDocumentId && !!documentStepId && enabled,
  });

  return { data: data?.data, ...rest };
};

//employee-application

export const createRenewVisaEmployeeApplicationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaEmployeeApplicationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaEmployeeApplicationDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaEmployeeApplicationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/employee-application`,
    payload,
  );
  return rs.data;
};

export const updateRenewVisaEmployeeApplicationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaEmployeeApplicationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaEmployeeApplicationDocumentResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateRenewVisaEmployeeApplicationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/employee-application`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaEmployeeApplicationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaEmployeeApplicationDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaEmployeeApplicationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/employee-application`,
  );
  return rs.data;
};

type TUseGetRenewVisaEmployeeApplicationDocumentInfoProps = {
  applicationDocumentStepId?: string | number;
};

export const useGetRenewVisaEmployeeApplicationDocumentInfo = (props?: TUseGetRenewVisaEmployeeApplicationDocumentInfoProps) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const docStepId = props?.applicationDocumentStepId ?? documentStepId;

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_EMPLOYEE_APPLICATION, renewVisaDocumentId, docStepId],
    queryFn: () => (renewVisaDocumentId && docStepId ? fetchRenewVisaEmployeeApplicationDocumentApi(renewVisaDocumentId, docStepId) : undefined),
    enabled: !!renewVisaDocumentId && !!docStepId,
  });

  return { data: data?.data, ...rest };
};

//company-application

export const createRenewVisaCompanyApplicationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaCompanyApplicationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaCompanyApplicationDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaCompanyApplicationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/company-application`,
    payload,
  );
  return rs.data;
};

export const updateRenewVisaCompanyApplicationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaCompanyApplicationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaCompanyApplicationDocumentResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateRenewVisaCompanyApplicationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/company-application`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaCompanyApplicationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaCompanyApplicationDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaCompanyApplicationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/company-application`,
  );
  return rs.data;
};

type TUseGetRenewVisaCompanyApplicationDocumentInfoProps = {
  renewVisaDocumentId?: string | number;
  applicationDocumentStepId?: string | number;
};

export const useGetRenewVisaCompanyApplicationDocumentInfo = (props?: TUseGetRenewVisaCompanyApplicationDocumentInfoProps) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  const docId = props?.renewVisaDocumentId ?? renewVisaDocumentId;
  const docStepId = props?.applicationDocumentStepId ?? documentStepId;

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_COMPANY_APPLICATION, docId, docStepId],
    queryFn: () => (docId && docStepId ? fetchRenewVisaCompanyApplicationDocumentApi(docId, docStepId) : undefined),
    enabled: !!docId && !!docStepId,
  });

  return { data: data?.data, ...rest };
};

//upload-file
export const createRenewVisaUploadFileDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaUploadFileDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaUploadFileDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaUploadFileDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/upload-file`,
    payload,
  );
  return rs.data;
};

export const updateRenewVisaUploadFileDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaUploadFileDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaUploadFileDocumentResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateRenewVisaUploadFileDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/upload-file`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaUploadFileDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaUploadFileDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaUploadFileDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/upload-file`,
  );
  return rs.data;
};

type UseGetRenewVisaUploadFileDocumentInfoProps = {
  enabled: boolean;
  documentStepId: string | number;
};

export const useGetRenewVisaUploadFileDocumentInfo = ({ enabled, documentStepId }: UseGetRenewVisaUploadFileDocumentInfoProps) => {
  const { renewVisaDocumentId } = useParams();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_UPLOAD_FILE, renewVisaDocumentId, documentStepId],
    queryFn: () => (renewVisaDocumentId && documentStepId ? fetchRenewVisaUploadFileDocumentApi(renewVisaDocumentId, documentStepId) : undefined),
    enabled: !!renewVisaDocumentId && !!documentStepId && enabled,
  });

  return { data: data?.data, ...rest };
};

//residence-card-information

export const createRenewVisaResidenceCardInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaResidenceCardInformationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaResidenceCardInformationDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaResidenceCardInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/residence-card-information`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaResidenceCardInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaResidenceCardInformationDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaResidenceCardInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/residence-card-information`,
  );
  return rs.data;
};

type UseGetRenewVisaResidenceCardInformationDocumentInfoProps = {
  documentStepId: string | number;
};

export const useGetRenewVisaResidenceCardInformationDocumentInfo = ({ documentStepId }: UseGetRenewVisaResidenceCardInformationDocumentInfoProps) => {
  const { renewVisaDocumentId } = useParams();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_RESIDENCE_CARD_INFORMATION, renewVisaDocumentId, documentStepId],
    queryFn: () =>
      renewVisaDocumentId && documentStepId ? fetchRenewVisaResidenceCardInformationDocumentApi(renewVisaDocumentId, documentStepId) : undefined,
    enabled: !!renewVisaDocumentId && !!documentStepId,
  });

  return { data: data?.data, ...rest };
};

//application-information

export const createRenewVisaApplicationInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaApplicationInformationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaApplicationInformationDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaApplicationInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/application-information`,
    payload,
  );
  return rs.data;
};

export const updateRenewVisaApplicationInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaApplicationInformationDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaApplicationInformationDocumentResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateRenewVisaApplicationInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/application-information`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaApplicationInformationDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaApplicationInformationDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaApplicationInformationDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/application-information`,
  );
  return rs.data;
};

type UseGetRenewVisaApplicationInformationDocumentInfoProps = {
  documentStepId: string | number;
  enabled: boolean;
};

export const useGetRenewVisaApplicationInformationDocumentInfo = ({
  documentStepId,
  enabled,
}: UseGetRenewVisaApplicationInformationDocumentInfoProps) => {
  const { renewVisaDocumentId } = useParams();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_APPLICATION_INFORMATION, renewVisaDocumentId, documentStepId],
    queryFn: () =>
      renewVisaDocumentId && documentStepId ? fetchRenewVisaApplicationInformationDocumentApi(renewVisaDocumentId, documentStepId) : undefined,
    enabled: !!renewVisaDocumentId && !!documentStepId && enabled,
  });

  return { data: data?.data, ...rest };
};

//upload-avatar-file

export const createRenewVisaUploadAvatarFileDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaUploadAvatarFileDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaUploadAvatarFileDocumentResDto> => {
  const rs = await clientApi.post<TCreateOrUpdateRenewVisaUploadAvatarFileDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/upload-avatar-file`,
    payload,
  );
  return rs.data;
};

export const updateRenewVisaUploadAvatarFileDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
  payload: TCreateOrUpdateRenewVisaUploadAvatarFileDocumentReqDto,
): Promise<TCreateOrUpdateRenewVisaUploadAvatarFileDocumentResDto> => {
  const rs = await clientApi.put<TCreateOrUpdateRenewVisaUploadAvatarFileDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/upload-avatar-file`,
    payload,
  );
  return rs.data;
};

export const fetchRenewVisaUploadAvatarFileDocumentApi = async (
  renewVisaDocumentId: string | number,
  documentStepId: string | number,
): Promise<TGetTRenewVisaUploadAvatarFileDocumentResDto> => {
  const rs = await clientApi.get<TGetTRenewVisaUploadAvatarFileDocumentResDto>(
    `/renew-visa-documents/${renewVisaDocumentId}/steps/${documentStepId}/upload-avatar-file`,
  );
  return rs.data;
};

type UseGetRenewVisaUploadAvatarFileDocumentInfoProps = {
  documentStepId: string | number;
};

export const useGetRenewVisaUploadAvatarFileDocumentInfo = ({ documentStepId }: UseGetRenewVisaUploadAvatarFileDocumentInfoProps) => {
  const { renewVisaDocumentId } = useParams();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.RENEW_VISA_DOCUMENTS.FETCH_UPLOAD_AVATAR_FILE, renewVisaDocumentId, documentStepId],
    queryFn: () =>
      renewVisaDocumentId && documentStepId ? fetchRenewVisaUploadAvatarFileDocumentApi(renewVisaDocumentId, documentStepId) : undefined,
    enabled: !!renewVisaDocumentId && !!documentStepId,
  });

  return { data: data?.data, ...rest };
};
