export enum ERenewVisaConditionsApplyForStaffingTarget {
  RELEVANT_INDUSTRY_WORK_AT_DISPATCH_SITE = "relevant_industry_work_at_dispatch_site",
  MAJORITY_CAPITAL_INVESTMENT_BY_INDUSTRY_RELATED_ENTITY = "majority_capital_investment_by_industry_related_entity",
  SUBSTANTIAL_INVOLVEMENT_BY_INDUSTRY_RELATED_ENTITY = "substantial_involvement_by_industry_related_entity",
  AGRICULTURAL_WORK_IN_DESIGNATED_STRATEGIC_ZONE = "agricultural_work_in_designated_strategic_zone",
}

export enum ERenewVisaConditionsApplyForSupportPlanDelegation {
  TRACK_RECORD_OF_PROPER_MANAGEMENT_OF_SPECIFIC_VISA_HOLDERS = "track_record_of_proper_management_of_specific_visa_holders",
  EXPERIENCE_IN_SUPPORTING_SPECIFIC_VISA_HOLDERS = "experience_in_supporting_specific_visa_holders",
  ABILITY_TO_PROPERLY_PERFORM_OTHER_SUPPORT_DUTIES = "ability_to_properly_perform_other_support_duties",
}
