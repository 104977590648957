import { useEnum } from "@/api/services/global/enum";
import { Tag } from "@/components/elements/Tag";
import { background, state, text } from "@/theme/colors";
import { findLabelByValue } from "@/utils/object";
import { FC, useMemo } from "react";

export type TRetirementStatusTagProps = {
  retirementStatus: string;
};

export const RetirementStatusTag: FC<TRetirementStatusTagProps> = ({ retirementStatus }) => {
  const { data: retirementStatusOptions } = useEnum("ERetirementStatus");

  const label = findLabelByValue(retirementStatusOptions, retirementStatus);

  const tagProps = useMemo(() => {
    if (retirementStatus === "enrollment") {
      return { color: state.success_1 };
    }
    if (retirementStatus === "processing") {
      return { color: state.warning_1 };
    }
    if (retirementStatus === "retired") {
      return { color: state.error_1 };
    }
    return { color: background.disable };
  }, [retirementStatus]);

  return <Tag variant="bold" {...tagProps} sx={{ color: text.white }} title={label} />;
};
