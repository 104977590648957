import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RequiredTag } from "@/components/elements/RequiredTag";
import { Select } from "@/components/elements/Select";
import { EModifyContractTerminateReasonForEmployee } from "@/features/anytime/constants/enums";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TConvenientForEmployeeFormProps = {
  readonly?: boolean;
};

export const ConvenientForEmployeeForm: FC<TConvenientForEmployeeFormProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TRetirementAcceptingWorkerForm>();
  const convenienceCategoryTypeValue = useWatch({ control, name: "convenienceCategoryType" });

  return (
    <InnerBox
      title="B.
特定技能外国人の都合"
      gap={2}
    >
      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="sub16Semi">事由の区分</Typography>
          <RequiredTag variant="cap12">必須</RequiredTag>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField control={control} name="convenienceCategoryType">
              <Select
                enumName="EModifyContractTerminateReasonForEmployee"
                readonly={readonly}
                onChange={(value) => {
                  if (value !== EModifyContractTerminateReasonForEmployee.OTHER) {
                    setValue("convenienceCategoryContent", null);
                  }
                }}
              />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="convenienceCategoryContent">
              <Input disabled={convenienceCategoryTypeValue !== EModifyContractTerminateReasonForEmployee.OTHER} />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <FormField control={control} name="convenienceDateOfOccurrence">
              <InputDatePicker readonly={readonly} />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="convenienceSummary">
              <Input readonly={readonly} />
              <Typography mt={1} variant="cap12">
                20文字以下
              </Typography>
            </FormField>
          </Grid>
        </Grid>
      </Stack>
    </InnerBox>
  );
};
