export const AppRouteNames = {
  // Home
  home: {
    label: "ホーム",
    value: "home",
  },

  // Login
  login: {
    label: "ログイン",
    value: "login",
  },
  logout: {
    label: "ログアウト",
    value: "logout",
  },
  recover: {
    label: "新規登録",
    value: "recover",
  },
  resetPassword: {
    label: "パスワード再設定",
    value: "reset_password",
  },
  register: {
    label: "新規登録",
    value: "register",
  },

  // Information
  information: {
    label: "お知らせ一覧",
    value: "information",
  },
  informationDetail: {
    label: "お知らせの詳細",
    value: "information_detail",
  },

  // Regular
  regular: {
    label: "定期届出",
    value: "regular",
  },

  regularPresent: {
    label: "定期届出の第X四半期",
    value: "regular_present",
  },
  regularPresentAcceptActivity: {
    label: "受入れ・活動状況に係る届出",
    value: "regular_present_accept_activity",
  },
  regularPresentAcceptActivityPayCheck: {
    label: "特定技能外国人の受入れ状況・報酬の支払状況",
    value: "regular_present_accept_activity_paycheck",
  },
  regularPresentLifeSupport: {
    label: "支援実施状況に係る届出",
    value: "regular_present_life_support",
  },
  regularPresentAcceptActivityPayrollFiles: {
    label: "賃金台帳原本のアップロード",
    value: "regular__present_accept_activity_payroll_files",
  },

  regularHistory: {
    label: "定期届出の過去提出分",
    value: "regular_history",
  },
  regularHistoryDetail: {
    label: "過去の定期届出",
    value: "regular_history_detail",
  },
  regularHistoryAcceptActivity: {
    label: "受入れ・活動状況に係る届出の確認画面",
    value: "regular_history_accept_activity",
  },
  regularHistoryAcceptActivityPayCheck: {
    label: "特定技能外国人の受入れ状況・報酬の支払状況の確認画面",
    value: "regular_history_accept_activity_paycheck",
  },
  regularHistoryLifeSupport: {
    label: "支援実施状況に係る届出の確認画面",
    value: "regular_history_life_support",
  },
  regularHistoryAcceptActivityPayrollFiles: {
    label: "賃金台帳原本のアップロードの確認画面",
    value: "regular_history_accept_activity_payroll_files",
  },

  // Renew visa
  renewVisa: {
    label: "在留期間更新",
    value: "renew_visa",
  },

  renewVisaPresent: {
    label: "今回の在留期間更新",
    value: "renew_visa_present",
  },
  renewVisaPresentEmployeeApplication: {
    label: "在留期間更新の申請作成 (外国人情報) ",
    value: "renew_visa_present_employee_application",
  },
  renewVisaPresentCompanyApplication: {
    label: "在留期間更新の申請作成 (企業情報) ",
    value: "renew_visa_present_company_application",
  },
  renewVisaPresentUploadApplication: {
    label: "添付書類",
    value: "renew_visa_present_upload_application",
  },
  renewVisaInfoHistory: {
    label: "留期間更新の過去提出分",
    value: "renew_visa_info_history",
  },
  renewVisaInfoHistoryDetail: {
    label: "過去の在留期間更新",
    value: "renew_visa_info_history_detail",
  },
  renewVisaInfoHistoryCompanyApplication: {
    label: "在留期間更新の申請作成 (企業情報) ",
    value: "renew_visa_info_history_company_application",
  },
  renewVisaInfoHistoryEmployeeApplication: {
    label: "在留期間更新の申請作成 (外国人情報) ",
    value: "renew_visa_info_history_employee_application",
  },
  renewVisaInfoHistoryUploadApplication: {
    label: "添付書類",
    value: "renew_visa_info_history_upload_application",
  },

  // Retirement
  retirement: {
    label: "退職手続き",
    value: "retirement",
  },
  retirementDocument: {
    label: "退職手続き一覧",
    value: "retirement_document",
  },
  retirementFiles: {
    label: "添付書類一覧",
    value: "retirement_files",
  },
  retirementAcceptingWorker: {
    label: "受入れ困難に係る届出",
    value: "retirement_accepting_worker",
  },
  retirementEmploymentContract: {
    label: "特定技能雇用契約に係る届出",
    value: "retirement_employment_contract",
  },

  retirementAcceptingWorkerDetail: {
    label: "受入れ困難に係る届出",
    value: "retirement_accepting_working_detail",
  },
  retirementEmploymentContractDetail: {
    label: "特定技能雇用契約に係る届出",
    value: "retirement_employment_contract_detail",
  },

  // Anytime
  anytime: {
    label: "随時届出",
    value: "anytime",
  },
  anytimeTemplates: {
    label: "随時届出一覧",
    value: "anytime_templates",
  },
  anytimeDocument: {
    label: "過去の随時届出",
    value: "anytime_document",
  },
  anytimeFiles: {
    label: "随時届出の添付ファイル一覧",
    value: "anytime_files",
  },

  anytimeEmploymentContractCreate: {
    label: "雇用契約の変更に係る届出",
    value: "anytime_employment_contract_create",
  },
  anytimeSupportPlanCreate: {
    label: "支援計画変更に係る届出",
    value: "anytime_support_plan_create",
  },
  anytimeSupportContractCreate: {
    label: "支援委託契約に係る届出",
    value: "anytime_support_contract_create",
  },

  anytimeEmploymentContractDetail: {
    label: "雇用契約の変更に係る届出の確認画面",
    value: "anytime_employment_contract_detail",
  },
  anytimeSupportPlanDetail: {
    label: "支援計画変更に係る届出の確認画面",
    value: "anytime_support_plan_detail",
  },
  anytimeSupportContractDetail: {
    label: "支援委託契約に係る届出の確認画面",
    value: "anytime_support_contract_detail",
  },

  anytimeEmploymentContractEdit: {
    label: "雇用契約の変更に係る届出の編集画面",
    value: "anytime_employment_contract_edit",
  },
  anytimeSupportPlanEdit: {
    label: "支援計画変更に係る届出の編集画面",
    value: "anytime_support_plan_edit",
  },
  anytimeSupportContractEdit: {
    label: "支援委託契約に係る届出の編集画面",
    value: "anytime_support_contract_edit",
  },

  // Contact
  contact: {
    label: "お問い合わせ",
    value: "contact",
  },

  // Employee
  employeeList: {
    label: "特定技能外国人の一覧",
    value: "employee_list",
  },
  employeeCreate: {
    label: "特定技能外国人の新規外国人登録",
    value: "employee_create",
  },
  employeeDetail: {
    label: "特定技能外国人の詳細画面",
    value: "employee_detail",
  },
  employeeUploadAvatar: {
    label: "写真のアップロード",
    value: "employee_upload_avatar",
  },
  employeeEdit: {
    label: "特定技能外国人情報の編集",
    value: "employee_edit",
  },
  employeeDocuments: {
    label: "",
    value: "employee_documents",
  },
  employeeGuideDocuments: {
    label: "",
    value: "employee_guide_documents",
  },

  // about
  company: {
    label: "会社情報",
    value: "company",
  },
  companyEdit: {
    label: "会社情報の編集画面",
    value: "company_edit",
  },
  companyBranch: {
    label: "事業所管理",
    value: "company_branch",
  },
  companyBranchCreate: {
    label: "事業所管理の作成",
    value: "company_branch_create",
  },
  companyBranchEdit: {
    label: "事業所管理の編集",
    value: "company_branch_edit",
  },
  companyAccount: {
    label: "オンライン申請のアカウント管理",
    value: "company_account",
  },
  companyAccountEdit: {
    label: "オンライン申請のアカウント管理の編集画面",
    value: "company_account_edit",
  },
  companyDocument: {
    label: "",
    value: "company_document",
  },
  companyDocumentEdit: {
    label: "",
    value: "company_document_edit",
  },

  // page not found
  pageNotFound: {
    label: "「ページが見つかりません」の画面",
    value: "page_not_found",
  },
  serverErrorPage: {
    label: "サーバーエラーページ",
    value: "server_error_page",
  },

  // Maintenance
  maintenance: {
    label: "メンテナンス",
    value: "maintenance",
  },

  //
  interview: {
    label: "面談",
    value: "interview",
  },
  lifeSupport: {
    label: "生活支援",
    value: "life_support",
  },
  inquire: {
    label: "",
    value: "inquire",
  },
  procedure: {
    label: "",
    value: "procedure",
  },
  setting: {
    label: "設定",
    value: "setting",
  },
  booking: {
    label: "",
    value: "booking",
  },
};

export enum EProvidedLegalProtection {
  ONE = "one",
  TWO = "two",
  THREE = "three",
  FOUR = "four",
  FIVE = "five",
  SIX = "six",
  SEVEN = "seven",
}
