import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { EMeasure, EPlanToContinueActivity } from "@/features/retirement/constants/enums";
import { TRetirementAcceptingWorkerForm } from "@/features/retirement/types/retirementAcceptingWorkerForm";
import { Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TMeasuresTakenProps = {
  readonly?: boolean;
};

export const MeasuresTaken: FC<TMeasuresTakenProps> = ({ readonly }) => {
  const { control, setValue } = useFormContext<TRetirementAcceptingWorkerForm>();
  const measuresTakenTypeValue = useWatch({ control, name: "measuresTakenType" });
  const planToContinueActivityValue = useWatch({ control, name: "planToContinueActivity" });

  return (
    <Stack gap={2}>
      <Typography variant="head20Semi">受け入れ継続のための措置</Typography>
      <InnerBox bordered p={3}>
        <Stack gap={2}>
          <Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography marginBottom={0.5} variant="cap12">
                活動継続の意思
              </Typography>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormField control={control} name="planToContinueActivity">
                  <Select
                    enumName="EPlanToContinueActivity"
                    readonly={readonly}
                    onChange={(value) => {
                      if (value !== EPlanToContinueActivity.OTHER) {
                        setValue("planToContinueActivityContent", null);
                      }
                    }}
                  />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="planToContinueActivityContent">
                  <Input disabled={planToContinueActivityValue !== EPlanToContinueActivity.OTHER} readonly={readonly} />
                </FormField>
              </Grid>
            </Grid>
          </Stack>
          <Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography marginBottom={0.5} variant="cap12">
                措置内容
              </Typography>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormField control={control} name="measuresTakenType">
                  <Select
                    enumName="EMeasure"
                    readonly={readonly}
                    onChange={(value) => {
                      if (value !== EMeasure.OTHER) {
                        setValue("measuresTakenContent", null);
                      }
                    }}
                  />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <FormField control={control} name="measuresTakenContent">
                  <Input disabled={measuresTakenTypeValue !== EMeasure.OTHER} readonly={readonly} />
                </FormField>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </InnerBox>
    </Stack>
  );
};
