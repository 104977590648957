import { InnerBox } from "@/components/elements/InnerBox";
import { text } from "@/theme/colors";
import { spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Stack, Typography } from "@mui/material";

export type TDocumentCardProps = {
  title: string;
  subTitle: string;
};

export const DocumentCard: FCC<TDocumentCardProps> = ({ title, subTitle, children }) => {
  return (
    <InnerBox padding={`${spacing.lg} ${spacing.md}`}>
      <Stack gap={4}>
        <Stack flexDirection="row" gap={2}>
          <Typography color={text.accent} variant="head20Bold">
            {title}
          </Typography>
          <Typography variant="head20Bold">{subTitle}</Typography>
        </Stack>
        <Stack gap={3}>{children}</Stack>
      </Stack>
    </InnerBox>
  );
};
