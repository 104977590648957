import { validator } from "@/utils/validator";

export const employeeSchema = validator.object().shape({
  employeeId: validator.number().decimal().emptyToNull().required(),
  employeeFullName: validator.string().trim().emptyToNull().required(),
  employeeGender: validator.string().trim().emptyToNull().notRequired(),
  employeeNationality: validator.string().trim().emptyToNull().notRequired(),
  employeeResidenceCardNumber: validator.string().trim().emptyToNull().notRequired(),
  birthday: validator.string().isSelection().trim().emptyToNull().notRequired(),
  zipCode: validator.string().trim().emptyToNull().notRequired(),
  fullAddress: validator.string().trim().emptyToNull().notRequired(),
  telNumber: validator.string().trim().emptyToNull().notRequired(),
});
