import { IconAlert, IconCheck } from "@/assets/icons";
import { Tooltip } from "@/components/elements/Tooltip";
import { background, divider, state, text } from "@/theme/colors";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TFileInfoProps = {
  title: string;
  subTitle?: string;
  titleVariant?: "body14" | "sub16Semi";
  tooltipContent?: string;
  isUploaded: boolean;
  showCheckIcon?: boolean;
  required?: boolean;
};

export const FileInfo: FC<TFileInfoProps> = ({
  title,
  tooltipContent,
  titleVariant = "body14",
  isUploaded,
  subTitle,
  showCheckIcon = true,
  required,
}) => {
  return (
    <Stack flexDirection="column" justifyContent="center">
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {showCheckIcon && (
          <Stack
            alignItems="center"
            justifyContent="center"
            width={16}
            height={16}
            borderRadius={16}
            bgcolor={isUploaded ? state.success_1 : divider.middle}
          >
            <IconCheck color={background.white} />
          </Stack>
        )}
        <Stack direction="row">
          <Typography variant={titleVariant} component="p" whiteSpace="pre-line">
            {title}
          </Typography>
          {required && (
            <Typography ml={0.5} color={text.error}>
              *
            </Typography>
          )}
        </Stack>
        {tooltipContent && (
          <Tooltip
            content={
              <Typography variant="body14" component="p" whiteSpace="pre-line">
                {tooltipContent}
              </Typography>
            }
          >
            <IconAlert />
          </Tooltip>
        )}
      </Stack>
      {subTitle && (
        <Typography component="p" ml={3} variant="body14" mt={1} whiteSpace="pre-line">
          {subTitle}
        </Typography>
      )}
    </Stack>
  );
};
