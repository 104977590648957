import { TAnyTimeDocumentStep } from "@/api/entities/any-time-document-step.entity";
import { useEnum } from "@/api/services/global/enum";
import { TAnyTimeDocumentOutputDto } from "@/api/services/main/any-time/dtos/get-any-time-document";
import { Button } from "@/components/elements/Button";
import { ConvertDocumentStatusTag } from "@/components/elements/ConvertDocumentStatusTag";
import { StepCard } from "@/components/elements/StepCard";
import { DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useInitSetup } from "@/providers/InitSetupProvider";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TAnytimeDocumentUploadStepProps = {
  document: TAnyTimeDocumentOutputDto;
  stepData: TAnyTimeDocumentStep;
  readonly?: boolean;
};

export const AnytimeDocumentUploadStep: FC<TAnytimeDocumentUploadStepProps> = ({ document, stepData, readonly = false }) => {
  const { id } = useParams();
  const { updatedAt, updater, status } = stepData;
  const { checkFinishInitStep } = useInitSetup();
  const { data: documentStatuses } = useEnum("EDocumentStatus");

  return (
    <StepCard
      title="ステップ2"
      subTitle="添付書類のアップロード"
      buttons={
        readonly ? (
          <Button variant="primary" size="sm" component={Link} to={getDynamicRoute(AppRoutes.anytimeFiles, { id }, "readonly=true")}>
            確認
          </Button>
        ) : (
          <Button variant="primary" size="sm" component={Link} to={getDynamicRoute(AppRoutes.anytimeFiles, { id })} onClick={checkFinishInitStep}>
            アップロード
          </Button>
        )
      }
    >
      <Stack flexDirection="row" alignItems="flex-start" gap={10}>
        <Stack gap={1}>
          <Typography variant="body14Bold">更新日時</Typography>
          <Typography variant="body14">{updatedAt && updater ? dayjs(updatedAt).format(DATE_FORMAT) : EMPTY_STR.DATE}</Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body14Bold">最終更新者</Typography>
          <Typography variant="body14">{updater?.fullName || EMPTY_STR.TEXT}</Typography>
        </Stack>
        {status && (
          <Stack gap={1}>
            <Typography variant="body14Bold">ステータス</Typography>
            <ConvertDocumentStatusTag status={status} title={findLabelByValue(documentStatuses, status) || EMPTY_STR.TEXT} />
          </Stack>
        )}
      </Stack>
    </StepCard>
  );
};
