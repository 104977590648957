import { TGetContactTypeListReqDto, TGetContactTypeListResDto } from "@/api/services/main/contact-type/dtos/get-contact-type-list.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";

export const fetchContactTypeListApi = async (req: TGetContactTypeListReqDto): Promise<TGetContactTypeListResDto> => {
  const url = createUrlWithParams("/contact-types", req);
  const rs = await clientApi.get<TGetContactTypeListResDto>(url);

  return rs.data;
};

export const useContactTypeList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.CONTACT_TYPE.FETCH_LIST, pagReq],
    queryFn: () => fetchContactTypeListApi(pagReq),
  });
};
