import { useGetRenewVisaUploadFileDocumentInfo } from "@/api/services/main/renew-visa";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { UploadApplicationForm } from "@/features/renew-visa/components/applicationStep/uploadApplication/UploadApplicationForm";
import { useGetUploadFileListWithFilterCondition } from "@/features/renew-visa/hook/useGetUploadFileListWithFilterCondition";
import { convertResToUploadApplicationFormData } from "@/features/renew-visa/utils/convertResToUploadApplicationFormData";
import { AppRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { DOCUMENT_STATUS } from "@/types/enum";
import { getDynamicRoute } from "@/utils/url";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

type TRenewVisaPresentUploadApplicationPageProps = {};

export const RenewVisaPresentUploadApplicationPage: FCC<TRenewVisaPresentUploadApplicationPageProps> = ({}) => {
  const { renewVisaDocumentId, documentStepId } = useParams();
  const { data: uploadFileData, isLoading: isLoadingUploadFile } = useGetRenewVisaUploadFileDocumentInfo({
    documentStepId: documentStepId ?? "",
    enabled: !!documentStepId,
  });

  const [isEditable, setIsEditable] = useState(false);

  const { filterConditionArrayKeys, isLoading: isLoadingUploadFileListWithFilterCondition } = useGetUploadFileListWithFilterCondition();

  useEffect(() => {
    if (!uploadFileData) {
      setIsEditable(true);
    } else {
      if (uploadFileData.status === DOCUMENT_STATUS.IN_PROGRESS) {
        setIsEditable(true);
      } else if (uploadFileData.status === DOCUMENT_STATUS.COMPLETE) {
        setIsEditable(false);
      }
    }
  }, [uploadFileData]);

  const isLoading = useMemo(
    () => isLoadingUploadFile || isLoadingUploadFileListWithFilterCondition,
    [isLoadingUploadFile, isLoadingUploadFileListWithFilterCondition],
  );

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <TopPage
        title="添付書類"
        isDraft={uploadFileData?.isDraft}
        backUrl={getDynamicRoute(AppRoutes.renewVisaPresent, { renewVisaDocumentId })}
        description="対象の外国人の在留期間更新に必要な書類一覧です。適切な書類をPDFにした上でアップロードください。"
      />
      <UploadApplicationForm
        status={uploadFileData?.status}
        previousValues={convertResToUploadApplicationFormData(filterConditionArrayKeys, uploadFileData)}
        updater={uploadFileData?.updater}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
      />
    </>
  );
};
