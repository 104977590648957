import { AppRoutes } from "@/routes/config";
import { Navigate, RouteObject } from "react-router-dom";

import { CompanyLayoutWithAuth } from "@/components/layout/CompanyLayout/CompanyLayout";
import { LoginLayout } from "@/components/layout/LoginLayout/LoginLayout";
import { CompanyLayout } from "@/features/company/layout/CompanyLayout";
import { EmployeeDetailLayout } from "@/features/employee/layout/EmployeeDetailLayout";
import { RegularReportLayout } from "@/features/regular-document/layout/RegularReportLayout";

import Login from "@/pages/auth/Login";
import Logout from "@/pages/auth/Logout";
import Recover from "@/pages/auth/Recover";
import Register from "@/pages/auth/Register";
import ResetPassword from "@/pages/auth/ResetPassword";

import Dashboard from "@/pages/dashboard/Dashboard";

import Company from "@/pages/company/Company";
import CompanyEdit from "@/pages/company/CompanyEdit";

import EmployeeCreate from "@/pages/employee/EmployeeCreate";
import EmployeeDetail from "@/pages/employee/EmployeeDetail";
import EmployeeDocuments from "@/pages/employee/EmployeeDocuments";
import EmployeeEdit from "@/pages/employee/EmployeeEdit";
import EmployeeGuideDocuments from "@/pages/employee/EmployeeGuideDocuments";
import EmployeeList from "@/pages/employee/EmployeeList";
import EmployeeUploadAvatarPage from "@/pages/employee/EmployeeUploadAvatar";

import RegularHistoryAcceptActivity from "@/pages/regular-document/acceptance-activity-document/RegularHistoryAcceptanceActivity";
import RegularHistoryAcceptActivityPayCheck from "@/pages/regular-document/acceptance-activity-document/RegularHistoryAcceptanceActivityPayCheck";
import RegularHistoryAcceptActivityPayrollFiles from "@/pages/regular-document/acceptance-activity-document/RegularHistoryAcceptanceActivityPayrollFiles";
import RegularPresentAcceptActivity from "@/pages/regular-document/acceptance-activity-document/RegularPresentAcceptanceActivity";
import RegularPresentAcceptActivityPayCheck from "@/pages/regular-document/acceptance-activity-document/RegularPresentAcceptanceActivityPayCheck";
import RegularPresentAcceptActivityPayrollFiles from "@/pages/regular-document/acceptance-activity-document/RegularPresentAcceptanceActivityPayrollFiles";

import RegularHistoryLifeSupport from "@/pages/regular-document/life-support-document/RegularHistoryLifeSupport";
import RegularPresentLifeSupport from "@/pages/regular-document/life-support-document/RegularPresentLifeSupport";

import RegularHistory from "@/pages/regular-document/RegularHistory";
import RegularHistoryDetail from "@/pages/regular-document/RegularHistoryDetail";
import RegularPresent from "@/pages/regular-document/RegularPresent";

import CompanyAccount from "@/pages/company/CompanyAccount";
import CompanyAccountEdit from "@/pages/company/CompanyAccountEdit";
import CompanyBranch from "@/pages/company/CompanyBranch";
import CompanyBranchCreate from "@/pages/company/CompanyBranchCreate";
import CompanyBranchEdit from "@/pages/company/CompanyBranchEdit";

import FeatureNotReadyPage from "@/pages/FeatureNotReadyPage";
import PageNotFound from "@/pages/PageNotFound";
import ServerErrorPage from "@/pages/ServerErrorPage";

import AnytimeDocument from "@/pages/anytime/AnytimeDocument";
import AnytimeList from "@/pages/anytime/AnytimeList";
import AnytimeTemplates from "@/pages/anytime/AnytimeTemplates";
import AnytimeEmploymentContractCreate from "@/pages/anytime/documents/AnytimeEmploymentContractCreate";
import AnytimeEmploymentContractEdit from "@/pages/anytime/documents/AnytimeEmploymentContractEdit";
import AnytimeSupportContractCreate from "@/pages/anytime/documents/AnytimeSupportContractCreate";
import AnytimeSupportContractEdit from "@/pages/anytime/documents/AnytimeSupportContractEdit";
import AnytimeSupportPlanCreate from "@/pages/anytime/documents/AnytimeSupportPlanCreate";
import AnytimeSupportPlanEdit from "@/pages/anytime/documents/AnytimeSupportPlanEdit";

import Contact from "@/pages/contact/Contact";

import { MaintenancePage } from "@/features/maintenance/pages/Maintenance";
import { RenewVisaLayout } from "@/features/renew-visa/layout/RenewVisaLayout";
import { RetirementDocumentPage } from "@/features/retirement/pages/RetirementDocument";
import AnytimeFiles from "@/pages/anytime/AnytimeFiles";
import AnytimeEmploymentContractDetail from "@/pages/anytime/documents/AnytimeEmploymentContractDetail";
import AnytimeSupportContractDetail from "@/pages/anytime/documents/AnytimeSupportContractDetail";
import AnytimeSupportPlanDetail from "@/pages/anytime/documents/AnytimeSupportPlanDetail";
import InformationDetail from "@/pages/information/InformationDetail";
import InformationList from "@/pages/information/InformationList";
import RenewVisaHistoryCompanyApplication from "@/pages/renew-visa/RenewVisaHistoryCompanyApplication";
import RenewVisaHistoryDetail from "@/pages/renew-visa/RenewVisaHistoryDetail";
import RenewVisaHistoryEmployeeApplication from "@/pages/renew-visa/RenewVisaHistoryEmployeeApplication";
import RenewVisaHistoryList from "@/pages/renew-visa/RenewVisaHistoryList";
import RenewVisaHistoryUploadApplication from "@/pages/renew-visa/RenewVisaHistoryUploadApplication";
import RenewVisaList from "@/pages/renew-visa/RenewVisaList";
import RenewVisaPresent from "@/pages/renew-visa/RenewVisaPresent";
import RenewVisaPresentCompanyApplication from "@/pages/renew-visa/RenewVisaPresentCompanyApplication";
import RenewVisaPresentEmployeeApplication from "@/pages/renew-visa/RenewVisaPresentEmployeeApplication";
import RenewVisaPresentUploadApplication from "@/pages/renew-visa/RenewVisaPresentUploadApplication";
import Retirement from "@/pages/retirement/Retirement";
import RetirementFiles from "@/pages/retirement/RetirementFiles";
import RetirementAcceptingWorker from "@/pages/retirement/documents/RetirementAcceptingWorker";
import RetirementEmploymentContract from "@/pages/retirement/documents/RetirementEmploymentContract";
import { AppRouteNames } from "@/routes/constants";
import RetirementAcceptingWorkerDetail from "@/pages/retirement/documents/RetirementAcceptingWorkerDetail";
import RetirementEmploymentContractDetail from "@/pages/retirement/documents/RetirementEmploymentContractDetail";

export const routes: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [
      {
        path: AppRoutes.recover,
        element: <Recover />,
      },
      {
        path: AppRoutes.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: AppRoutes.register,
        element: <Register />,
      },
      {
        path: AppRoutes.login,
        element: <Login />,
      },
    ],
  },
  {
    element: <CompanyLayoutWithAuth />,
    children: [
      {
        path: AppRoutes.home,
        element: <Dashboard />,
        id: AppRouteNames.home.value,
      },
      { path: AppRoutes.information, element: <InformationList />, id: AppRouteNames.information.value },
      { path: AppRoutes.informationDetail, element: <InformationDetail />, id: AppRouteNames.informationDetail.value },

      {
        element: <RegularReportLayout />,
        children: [
          { path: AppRoutes.regular, element: <Navigate to={AppRoutes.regularPresent} />, id: AppRouteNames.regular.value },
          { path: AppRoutes.regularPresent, element: <RegularPresent />, id: AppRouteNames.regularPresent.value },
          { path: AppRoutes.regularHistory, element: <RegularHistory />, id: AppRouteNames.regularHistory.value },
        ],
      },
      { path: AppRoutes.regularHistoryDetail, element: <RegularHistoryDetail />, id: AppRouteNames.regularHistoryDetail.value },
      {
        path: AppRoutes.regularPresentAcceptActivity,
        element: <RegularPresentAcceptActivity />,
        id: AppRouteNames.regularPresentAcceptActivity.value,
      },
      {
        path: AppRoutes.regularPresentAcceptActivityPayCheck,
        element: <RegularPresentAcceptActivityPayCheck />,
        id: AppRouteNames.regularPresentAcceptActivityPayCheck.value,
      },
      {
        path: AppRoutes.regularPresentLifeSupport,
        element: <RegularPresentLifeSupport />,
        id: AppRouteNames.regularPresentLifeSupport.value,
      },
      {
        path: AppRoutes.regularPresentAcceptActivityPayrollFiles,
        element: <RegularPresentAcceptActivityPayrollFiles />,
        id: AppRouteNames.regularPresentAcceptActivityPayrollFiles.value,
      },
      {
        path: AppRoutes.regularHistoryAcceptActivity,
        element: <RegularHistoryAcceptActivity />,
        id: AppRouteNames.regularHistoryAcceptActivity.value,
      },
      {
        path: AppRoutes.regularHistoryAcceptActivityPayCheck,
        element: <RegularHistoryAcceptActivityPayCheck />,
        id: AppRouteNames.regularHistoryAcceptActivityPayCheck.value,
      },
      {
        path: AppRoutes.regularHistoryLifeSupport,
        element: <RegularHistoryLifeSupport />,
        id: AppRouteNames.regularHistoryLifeSupport.value,
      },
      {
        path: AppRoutes.regularHistoryAcceptActivityPayrollFiles,
        element: <RegularHistoryAcceptActivityPayrollFiles />,
        id: AppRouteNames.regularHistoryAcceptActivityPayrollFiles.value,
      },

      //renew-visa
      { path: AppRoutes.renewVisa, element: <RenewVisaList />, id: AppRouteNames.renewVisa.value },
      {
        element: <RenewVisaLayout />,
        children: [
          { path: AppRoutes.renewVisaPresent, element: <RenewVisaPresent />, id: AppRouteNames.renewVisaPresent.value },
          { path: AppRoutes.renewVisaInfoHistory, element: <RenewVisaHistoryList />, id: AppRouteNames.renewVisaInfoHistory.value },
        ],
      },
      {
        path: AppRoutes.renewVisaPresentEmployeeApplication,
        element: <RenewVisaPresentEmployeeApplication />,
        id: AppRouteNames.renewVisaPresentEmployeeApplication.value,
      },
      {
        path: AppRoutes.renewVisaPresentCompanyApplication,
        element: <RenewVisaPresentCompanyApplication />,
        id: AppRouteNames.renewVisaPresentCompanyApplication.value,
      },
      {
        path: AppRoutes.renewVisaPresentUploadApplication,
        element: <RenewVisaPresentUploadApplication />,
        id: AppRouteNames.renewVisaPresentUploadApplication.value,
      },
      { path: AppRoutes.renewVisaInfoHistoryDetail, element: <RenewVisaHistoryDetail />, id: AppRouteNames.renewVisaInfoHistoryDetail.value },
      {
        path: AppRoutes.renewVisaInfoHistoryEmployeeApplication,
        element: <RenewVisaHistoryEmployeeApplication />,
        id: AppRouteNames.renewVisaInfoHistoryEmployeeApplication.value,
      },
      {
        path: AppRoutes.renewVisaInfoHistoryCompanyApplication,
        element: <RenewVisaHistoryCompanyApplication />,
        id: AppRouteNames.renewVisaInfoHistoryCompanyApplication.value,
      },
      {
        path: AppRoutes.renewVisaInfoHistoryUploadApplication,
        element: <RenewVisaHistoryUploadApplication />,
        id: AppRouteNames.renewVisaInfoHistoryUploadApplication.value,
      },

      { path: AppRoutes.retirement, element: <Retirement />, id: AppRouteNames.retirement.value },
      { path: AppRoutes.retirementDocument, element: <RetirementDocumentPage />, id: AppRouteNames.retirementDocument.value },
      { path: AppRoutes.retirementFiles, element: <RetirementFiles />, id: AppRouteNames.retirementFiles.value },

      {
        path: AppRoutes.retirementAcceptingWorker,
        element: <RetirementAcceptingWorker />,
        id: AppRouteNames.retirementAcceptingWorker.value,
      },
      {
        path: AppRoutes.retirementEmploymentContract,
        element: <RetirementEmploymentContract />,
        id: AppRouteNames.retirementEmploymentContract.value,
      },

      {
        path: AppRoutes.retirementAcceptingWorkerDetail,
        element: <RetirementAcceptingWorkerDetail />,
        id: AppRouteNames.retirementAcceptingWorkerDetail.value,
      },
      {
        path: AppRoutes.retirementEmploymentContractDetail,
        element: <RetirementEmploymentContractDetail />,
        id: AppRouteNames.retirementEmploymentContractDetail.value,
      },

      { path: AppRoutes.anytime, element: <AnytimeList />, id: AppRouteNames.anytime.value },
      { path: AppRoutes.anytimeTemplates, element: <AnytimeTemplates />, id: AppRouteNames.anytimeTemplates.value },
      { path: AppRoutes.anytimeDocument, element: <AnytimeDocument />, id: AppRouteNames.anytimeDocument.value },
      { path: AppRoutes.anytimeFiles, element: <AnytimeFiles />, id: AppRouteNames.anytimeFiles.value },

      {
        path: AppRoutes.anytimeEmploymentContractDetail,
        element: <AnytimeEmploymentContractDetail />,
        id: AppRouteNames.anytimeEmploymentContractDetail.value,
      },
      {
        path: AppRoutes.anytimeEmploymentContractEdit,
        element: <AnytimeEmploymentContractEdit />,
        id: AppRouteNames.anytimeEmploymentContractEdit.value,
      },
      {
        path: AppRoutes.anytimeEmploymentContractCreate,
        element: <AnytimeEmploymentContractCreate />,
        id: AppRouteNames.anytimeEmploymentContractCreate.value,
      },

      {
        path: AppRoutes.anytimeSupportPlanDetail,
        element: <AnytimeSupportPlanDetail />,
        id: AppRouteNames.anytimeSupportPlanDetail.value,
      },
      { path: AppRoutes.anytimeSupportPlanEdit, element: <AnytimeSupportPlanEdit />, id: AppRouteNames.anytimeSupportPlanEdit.value },
      { path: AppRoutes.anytimeSupportPlanCreate, element: <AnytimeSupportPlanCreate />, id: AppRouteNames.anytimeSupportPlanCreate.value },

      {
        path: AppRoutes.anytimeSupportContractDetail,
        element: <AnytimeSupportContractDetail />,
        id: AppRouteNames.anytimeSupportContractDetail.value,
      },
      {
        path: AppRoutes.anytimeSupportContractEdit,
        element: <AnytimeSupportContractEdit />,
        id: AppRouteNames.anytimeSupportContractEdit.value,
      },
      {
        path: AppRoutes.anytimeSupportContractCreate,
        element: <AnytimeSupportContractCreate />,
        id: AppRouteNames.anytimeSupportContractCreate.value,
      },

      { path: AppRoutes.contact, element: <Contact />, id: AppRouteNames.contact.value },

      { path: AppRoutes.employeeList, element: <EmployeeList />, id: AppRouteNames.employeeList.value },
      { path: AppRoutes.employeeCreate, element: <EmployeeCreate />, id: AppRouteNames.employeeCreate.value },
      { path: AppRoutes.employeeEdit, element: <EmployeeEdit />, id: AppRouteNames.employeeEdit.value },
      {
        element: <EmployeeDetailLayout />,
        children: [
          { path: AppRoutes.employeeDetail, element: <EmployeeDetail />, id: AppRouteNames.employeeDetail.value },
          { path: AppRoutes.employeeDocuments, element: <EmployeeDocuments />, id: AppRouteNames.employeeDocuments.value },
          { path: AppRoutes.employeeGuideDocuments, element: <EmployeeGuideDocuments />, id: AppRouteNames.employeeGuideDocuments.value },
        ],
      },

      {
        element: <CompanyLayout />,
        children: [
          { path: AppRoutes.company, element: <Company />, id: AppRouteNames.company.value },
          { path: AppRoutes.companyEdit, element: <CompanyEdit />, id: AppRouteNames.companyEdit.value },
          { path: AppRoutes.companyBranch, element: <CompanyBranch />, id: AppRouteNames.companyBranch.value },
          { path: AppRoutes.companyBranchCreate, element: <CompanyBranchCreate />, id: AppRouteNames.companyBranchCreate.value },
          { path: AppRoutes.companyBranchEdit, element: <CompanyBranchEdit />, id: AppRouteNames.companyBranchEdit.value },
          { path: AppRoutes.companyAccount, element: <CompanyAccount />, id: AppRouteNames.companyAccount.value },
          { path: AppRoutes.companyAccountEdit, element: <CompanyAccountEdit />, id: AppRouteNames.companyAccountEdit.value },
        ],
      },
      { path: AppRoutes.companyDocument, element: <FeatureNotReadyPage />, id: AppRouteNames.companyDocument.value },
      { path: AppRoutes.companyDocumentEdit, element: <FeatureNotReadyPage />, id: AppRouteNames.companyDocumentEdit.value },

      //
      { path: AppRoutes.interview, element: <FeatureNotReadyPage />, id: AppRouteNames.interview.value },
      { path: AppRoutes.lifeSupport, element: <FeatureNotReadyPage />, id: AppRouteNames.lifeSupport.value },
      { path: AppRoutes.procedure, element: <FeatureNotReadyPage />, id: AppRouteNames.procedure.value },
      { path: AppRoutes.inquire, element: <FeatureNotReadyPage />, id: AppRouteNames.inquire.value },
      { path: AppRoutes.setting, element: <FeatureNotReadyPage />, id: AppRouteNames.setting.value },
      { path: AppRoutes.booking, element: <FeatureNotReadyPage />, id: AppRouteNames.booking.value },
    ],
  },
  {
    path: AppRoutes.logout,
    element: <Logout />,
  },
  { path: AppRoutes.serverErrorPage, element: <ServerErrorPage /> },
  { path: AppRoutes.maintenance, element: <MaintenancePage /> },
  { path: AppRoutes.employeeUploadAvatar, element: <EmployeeUploadAvatarPage /> },
  { path: "*", element: <PageNotFound /> },
];

const flattenRoutes = (routes: RouteObject[]): RouteObject[] => {
  const flatRoutes: RouteObject[] = [];

  const extractChildren = (route: RouteObject) => {
    if (route.children) {
      route.children.forEach((child) => {
        extractChildren(child); // Recursively extract children
      });
    } else {
      flatRoutes.push(route); // Push the route if there are no children
    }
  };

  routes.forEach((route) => extractChildren(route));

  return flatRoutes;
};

export const flattenedRoutes = flattenRoutes(routes);
