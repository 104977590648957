import * as React from "react";

export const IconUser = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M13.4136 11.9312C13.1189 11.2331 12.6911 10.5989 12.1542 10.0641C11.619 9.52767 10.9849 9.10001 10.2871 8.80469C10.2808 8.80156 10.2746 8.8 10.2683 8.79688C11.2417 8.09375 11.8746 6.94844 11.8746 5.65625C11.8746 3.51562 10.1402 1.78125 7.99955 1.78125C5.85893 1.78125 4.12455 3.51562 4.12455 5.65625C4.12455 6.94844 4.75736 8.09375 5.7308 8.79844C5.72455 8.80156 5.7183 8.80312 5.71205 8.80625C5.01205 9.10156 4.38393 9.525 3.84486 10.0656C3.30847 10.6009 2.88081 11.235 2.58549 11.9328C2.29537 12.616 2.1389 13.3485 2.12455 14.0906C2.12413 14.1073 2.12706 14.1239 2.13315 14.1394C2.13925 14.1549 2.14839 14.1691 2.16004 14.181C2.17169 14.193 2.18561 14.2025 2.20098 14.2089C2.21636 14.2154 2.23287 14.2188 2.24955 14.2188H3.18705C3.2558 14.2188 3.31049 14.1641 3.31205 14.0969C3.3433 12.8906 3.82768 11.7609 4.68393 10.9047C5.56986 10.0188 6.74643 9.53125 7.99955 9.53125C9.25268 9.53125 10.4292 10.0188 11.3152 10.9047C12.1714 11.7609 12.6558 12.8906 12.6871 14.0969C12.6886 14.1656 12.7433 14.2188 12.8121 14.2188H13.7496C13.7662 14.2188 13.7827 14.2154 13.7981 14.2089C13.8135 14.2025 13.8274 14.193 13.8391 14.181C13.8507 14.1691 13.8599 14.1549 13.8659 14.1394C13.872 14.1239 13.875 14.1073 13.8746 14.0906C13.8589 13.3438 13.7042 12.6172 13.4136 11.9312ZM7.99955 8.34375C7.28236 8.34375 6.60736 8.06406 6.09955 7.55625C5.59174 7.04844 5.31205 6.37344 5.31205 5.65625C5.31205 4.93906 5.59174 4.26406 6.09955 3.75625C6.60736 3.24844 7.28236 2.96875 7.99955 2.96875C8.71674 2.96875 9.39174 3.24844 9.89955 3.75625C10.4074 4.26406 10.6871 4.93906 10.6871 5.65625C10.6871 6.37344 10.4074 7.04844 9.89955 7.55625C9.39174 8.06406 8.71674 8.34375 7.99955 8.34375Z"
      fill="currentColor"
    />
  </svg>
);
