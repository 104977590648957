import { extension, notifications, planningRetire, regular, scheduleJoin } from "@/features/dashboard/mocks";
import { FCC } from "@/types/common";
import { createContext, useContext } from "react";

const mockValue = {
  regular,
  extension,
  scheduleJoin,
  planningRetire,
  notifications,
};

export type DashboardType = typeof mockValue;

export const DashboardContext = createContext<DashboardType | undefined>(undefined);

export const DashboardProvider: FCC = ({ children }) => {
  return <DashboardContext.Provider value={mockValue}>{children}</DashboardContext.Provider>;
};

export const useDashboard = (): DashboardType => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};
