import { useEnum } from "@/api/services/global/enum";
import { FormField } from "@/components/elements/FormField";
import { InnerBox } from "@/components/elements/InnerBox";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RadioGroup } from "@/components/elements/RadioGroup";
import {
  EModifyContractTerminateReason,
  EModifyContractTerminateReasonForCompany,
  EModifyContractTerminateReasonForEmployee,
} from "@/features/anytime/constants/enums";
import { TEmploymentContractForm } from "@/features/anytime/types/employmentContractForm";
import { Grid } from "@mui/material";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

type TTerminationFormProps = {
  readonly?: boolean;
};

export const TerminationForm: FC<TTerminationFormProps> = ({ readonly }) => {
  const { control } = useFormContext<TEmploymentContractForm>();
  const { data: reasonForCompanyOptions } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: reasonForEmployeesOptions } = useEnum("EModifyContractTerminateReasonForEmployee");
  const { data: modifyContractTerminateReasonOptions } = useEnum("EModifyContractTerminateReason");

  const terminateContractReason = useWatch({ control, name: "terminateContractReason" });
  const terminateContractReasonByCompany = useWatch({ control, name: "terminateContractReasonByCompany" });
  const terminateContractReasonByEmployee = useWatch({ control, name: "terminateContractReasonByEmployee" });

  return (
    <InnerBox title="契約の終了" required gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="terminateContractDate">
            <InputDatePicker readonly={readonly} />
          </FormField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <FormField control={control} name="terminateContractReason" largeLabel>
            <RadioGroup options={modifyContractTerminateReasonOptions} disabled={readonly} />
          </FormField>
        </Grid>
        {terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY && (
          <>
            <Grid item xs={12}>
              <FormField control={control} name="terminateContractReasonByCompany" largeLabel>
                <RadioGroup options={reasonForCompanyOptions} disabled={readonly} />
              </FormField>
            </Grid>
            {terminateContractReasonByCompany === EModifyContractTerminateReasonForCompany.OTHER && (
              <Grid item xs={12}>
                <FormField control={control} name="terminateContractOtherByCompany" label="">
                  <Input readonly={readonly} />
                </FormField>
              </Grid>
            )}
          </>
        )}
        {terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE && (
          <>
            <Grid item xs={12}>
              <FormField control={control} name="terminateContractReasonByEmployee" largeLabel>
                <RadioGroup
                  options={reasonForEmployeesOptions.map((option, i) => {
                    if (i === 3) {
                      return { ...option, helper: `外国人の責めに帰すべき重大な理由に\nよる解雇の場合に選択。` };
                    }
                    return option;
                  })}
                  disabled={readonly}
                />
              </FormField>
            </Grid>
            {terminateContractReasonByEmployee === EModifyContractTerminateReasonForEmployee.OTHER && (
              <Grid item xs={12}>
                <FormField control={control} name="terminateContractOtherReasonByEmployee" label="">
                  <Input readonly={readonly} />
                </FormField>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </InnerBox>
  );
};
