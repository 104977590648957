import { Notification } from "@/components/commons/notification/Notification";
import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { AppRoutes } from "@/routes/config";
import React from "react";

export const InformationListPage = () => {
  return (
    <>
      <TopPage title="お知らせ" backUrl={AppRoutes.home} />
      <InnerBox p={0}>
        <Notification />
      </InnerBox>
    </>
  );
};
