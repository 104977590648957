import { text } from "@/theme/colors";
import { FCC } from "@/types/common";
import { Box, CircularProgress } from "@mui/material";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import Scroller from "react-infinite-scroller";

export type TInfiniteScrollProps = {
  loadMore: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult | void>;
  hasMore: boolean;
};

export const InfiniteScroll: FCC<TInfiniteScrollProps> = ({ children, hasMore, loadMore }) => {
  return (
    <Scroller
      loadMore={() => loadMore()}
      hasMore={hasMore}
      loader={
        <Box textAlign="center">
          <CircularProgress size={20} sx={{ color: text.disable }} />
        </Box>
      }
    >
      {children}
    </Scroller>
  );
};
