import { Stack, styled, Typography } from "@mui/material";
import { primary } from "@/theme/colors";

export const RoomHead = () => {
  return (
    <StyledRoomHead>
      <Typography sx={{ marginBottom: "4px" }} variant="sub16Bold">
        Irohana
      </Typography>
    </StyledRoomHead>
  );
};

const StyledRoomHead = styled(Stack)`
  padding: 16px;
  background-color: ${primary.main};
  color: white;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
