import { text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { Typography, TypographyProps } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

export type TRequiredTagProps = {
  title?: string;
} & TypographyProps;

export const RequiredTag: FC<TRequiredTagProps> = ({ title = "必須", ...rest }) => {
  return (
    <StyledRequiredTag variant="cap12" {...rest}>
      {title}
    </StyledRequiredTag>
  );
};

const StyledRequiredTag = styled(Typography)`
  display: block;
  height: 20px;
  width: 40px;
  text-align: center;
  border: 1px solid ${text.error};
  color: ${text.error};
  border-radius: ${rounded.xs};
`;
