import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { AppRoutes } from "@/routes/config";
import { elevation } from "@/theme/variables";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

type TEmployeeApplicationBtnActionsProps = {
  saveDraft: () => Promise<void>;
  readonly?: boolean;
  isEditable: boolean;
  setIsEditable: (v: boolean) => void;
};

export const EmployeeApplicationBtnActions: FC<TEmployeeApplicationBtnActionsProps> = ({ saveDraft, readonly, isEditable, setIsEditable }) => {
  const { renewVisaDocumentId, documentStepId } = useParams();

  return (
    <InnerBox bordered p={3} boxShadow={elevation.lv2}>
      <Stack direction="row" gap={2} justifyContent={"center"}>
        {!readonly && isEditable && (
          <>
            <Button variant="outline" size="lg" onClick={saveDraft}>
              下書き保存する
            </Button>
            <Button type="submit" variant="primary" size="lg">
              次へ
            </Button>
          </>
        )}

        {!readonly && !isEditable && (
          <>
            <Button variant="outline" size="lg" onClick={() => setIsEditable(true)}>
              再編集する
            </Button>
            <Button
              variant="primary"
              size="lg"
              component={Link}
              to={getDynamicRoute(AppRoutes.renewVisaPresentCompanyApplication, { renewVisaDocumentId, documentStepId })}
            >
              次へ
            </Button>
          </>
        )}

        {readonly && (
          <Button
            variant="primary"
            size="lg"
            component={Link}
            to={getDynamicRoute(AppRoutes.renewVisaInfoHistoryCompanyApplication, { renewVisaDocumentId, documentStepId })}
          >
            次へ
          </Button>
        )}
      </Stack>
    </InnerBox>
  );
};
