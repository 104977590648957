import { fetchMaintenanceInfoApi } from "@/api/services/global/maintenance";
import { TGetMaintenanceDto } from "@/api/services/global/maintenance/dtos/get-maintenance.dto";
import { LoadingScreen } from "@/components/elements/Loading/Loading";
import { AppRoutes, LoginRoutes } from "@/routes/config";
import { FCC } from "@/types/common";
import { MAINTENANCE_MODE } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { createContext, useContext, useEffect, useState } from "react";

type TMaintenanceContext = {
  data: TGetMaintenanceDto | undefined;
  isLoading: boolean;
};

const MaintenanceContext = createContext<TMaintenanceContext>({} as TMaintenanceContext);

export const MaintenanceProvider: FCC = ({ children }) => {
  const [data, setData] = useState<TGetMaintenanceDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMaintenanceInfo = async () => {
    try {
      if (window.location.pathname.startsWith(AppRoutes.serverErrorPage) || window.location.pathname.startsWith(AppRoutes.pageNotFound)) return;
      setIsLoading(true);
      const rs = await fetchMaintenanceInfoApi();
      const { data } = rs;
      setData(data);
      if (data?.maintenanceMode && data?.maintenanceMode !== MAINTENANCE_MODE.SYSTEM_NORMAL_OPERATION) {
        if (data?.modeStartDateTime && dayjs().isBefore(dayjs(data.modeStartDateTime))) {
          return;
        }
        if (data?.modeEndDateTime && dayjs().isAfter(dayjs(data.modeEndDateTime))) {
          return;
        }
        if (data?.maintenanceMode === MAINTENANCE_MODE.MAINTENANCE_UNUSABLE) {
          if (!window.location.pathname.startsWith(AppRoutes.maintenance)) {
            window.location.replace(AppRoutes.maintenance);
          }
        } else if (data?.maintenanceMode === MAINTENANCE_MODE.MAINTENANCE_READABLE) {
          if (
            window.location.pathname !== AppRoutes.home &&
            window.location.pathname !== AppRoutes.contact &&
            !LoginRoutes.some((path: string) => window.location.pathname.startsWith(path))
          ) {
            if (!window.location.pathname.startsWith(AppRoutes.maintenance)) {
              window.location.replace(AppRoutes.maintenance);
            }
          }
        } else {
          if (window.location.pathname === AppRoutes.maintenance) {
            window.location.replace(AppRoutes.home);
          }
        }
      } else {
        if (window.location.pathname.startsWith(AppRoutes.maintenance)) {
          window.location.replace(AppRoutes.home);
        }
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaintenanceInfo();
  }, []);

  const contextValue = {
    data,
    isLoading,
  };

  if (isLoading) return <LoadingScreen />;
  return <MaintenanceContext.Provider value={contextValue}>{children}</MaintenanceContext.Provider>;
};

export const MaintenanceConsumer = MaintenanceContext.Consumer;

export const useMaintenance = () => {
  const context = useContext(MaintenanceContext);
  if (context === undefined) {
    throw new Error("useMaintenance must be used within MaintenanceProvider");
  }
  return context;
};
