import { TGetCompanyDto } from "@/api/services/main/company/dtos/get-company.dto";
import { TGetAcceptanceActivityDocumentDto } from "@/api/services/main/regular-document/acceptance-activity-document/dtos/get-acceptance-activity-document.dto";
import { TGetPresentRegularDocumentDto } from "@/api/services/main/regular-document/dtos/get-present-regular-document.dto";
import { TAcceptanceActivityForm } from "@/features/regular-document/types/acceptanceActivityForm";
import { DOCUMENT_STEP_KEYS } from "@/types/enum";
import { findDocumentStep } from "@/utils/document";
import { toNumber } from "@/utils/number";
import { pick } from "@/utils/object";

export const getPreviousValues = (data: {
  documentData?: TGetPresentRegularDocumentDto;
  acceptanceActivityData?: TGetAcceptanceActivityDocumentDto;
  companyData?: TGetCompanyDto;
}): Partial<TAcceptanceActivityForm> | undefined => {
  const { companyData, documentData, acceptanceActivityData } = data;
  const stepData = findDocumentStep(documentData?.regularDocuments, DOCUMENT_STEP_KEYS.ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT);
  const stepId = stepData?.id;

  if (!documentData) return undefined;
  if (!stepId || !acceptanceActivityData) {
    if (!companyData) return { year: documentData.year, quarter: documentData.quarter };

    return {
      year: documentData.year,
      quarter: documentData.quarter,
      companyName: companyData.companyName,
      companyFuriganaName: companyData.companyFuriganaName ?? "",
      companySpecificIndustryId: toNumber(companyData.specificIndustryId),
      companyIdentificationNumber: companyData.companyIdentificationNumber ?? "",
      companyZipCode: companyData.zipCode ?? "",
      companyProvince: companyData.province ?? "",
      companyAddress: companyData.fullAddress ?? "",
      companyTelNumber: companyData.telNumber ?? "",
      companySupporterName: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].supporterName : null,
      companySupporterTelNumber: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].telNumber : null,
      companySupporterId: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].id : null,
      companySupporterPosition: companyData?.supporters && companyData.supporters.length > 0 ? companyData.supporters[0].companyPosition : null,
    };
  }

  return {
    ...pick(
      acceptanceActivityData,
      "year",
      "quarter",
      "companyName",
      "companyFuriganaName",
      "companyIdentificationNumber",
      "companyZipCode",
      "companyProvince",
      "companyAddress",
      "companyTelNumber",
      "specificSkillTotalRegisteredEmployeeCount",
      "specificSkillTotalNewEmployeeCount",
      "specificSkillTotalVoluntaryResignedEmployeeCount",
      "specificSkillTotalLayoffEmployeeCount",
      "specificSkillTotalMissingEmployeeCount",
      "specificSkillJapanRegisteredEmployeeCount",
      "specificSkillJapanNewEmployeeCount",
      "specificSkillJapanVoluntaryResignedEmployeeCount",
      "specificSkillJapanVoluntaryLayoffEmployeeCount",
      "specificSkillForeignRegisteredEmployeeCount",
      "specificSkillForeignNewEmployeeCount",
      "specificSkillForeignVoluntaryResignedEmployeeCount",
      "specificSkillForeignVoluntaryLayoffEmployeeCount",
      "specificSkillTotalRegisteredEmployeeTwoCount",
      "specificSkillTotalNewEmployeeTwoCount",
      "specificSkillTotalVoluntaryResignedEmployeeTwoCount",
      "specificSkillTotalLayoffEmployeeTwoCount",
      "specificSkillTotalMissingEmployeeTwoCount",
      "specificSkillJapanRegisteredEmployeeTwoCount",
      "specificSkillJapanNewEmployeeTwoCount",
      "specificSkillJapanVoluntaryResignedEmployeeTwoCount",
      "specificSkillJapanVoluntaryLayoffEmployeeTwoCount",
      "specificSkillForeignRegisteredEmployeeTwoCount",
      "specificSkillForeignNewEmployeeTwoCount",
      "specificSkillForeignVoluntaryResignedEmployeeTwoCount",
      "specificSkillForeignVoluntaryLayoffEmployeeTwoCount",
      "nonSpecificSkillRegisteredEmployeeCount",
      "nonSpecificSkillNewEmployeeCount",
      "acceptanceActivityImplementationCost",
      "acceptanceActivityPrepareCost",
      "companySupporterId",
      "companySupporterPosition",
      "companySupporterTelNumber",
      "companySupporterName",
      "acceptedPeopleCount",
      "newlyAcceptedPeopleCount",
      "specifiedSkillOnePeopleCount",
      "specifiedSkillTwoPeopleCount",
    ),
    companySpecificIndustryId: toNumber(acceptanceActivityData.companySpecificIndustryId),
  };
};
